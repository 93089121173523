import * as React from "react";
import { useImperativeHandle } from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import List from "@mui/material/List";
import { ListItem } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";

interface State {
  fArticle: FArticleInterface | undefined;
  edit: boolean;
  setFArticle: Function;
  createFrom?: FArticleInterface;
}

interface FormState {
  arPublie: InputInterface;
  arSommeil: InputInterface;
}

const FArticleStateComponent = React.memo(
  React.forwardRef(
    ({ fArticle, edit, setFArticle, createFrom }: State, ref) => {
      const { t } = useTranslation();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const getDefaultValues = React.useCallback((): FormState => {
        return {
          arPublie: {
            value: (createFrom ?? fArticle)?.arPublie === 1 ?? false,
            error: "",
          },
          arSommeil: {
            value: (createFrom ?? fArticle)?.arSommeil === 1 ?? false,
            error: "",
          },
        };
      }, [createFrom, fArticle]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          arPublie: values.arPublie.value ? 1 : 0,
          arSommeil: values.arSommeil.value ? 1 : 0,
        };
      }, [values.arPublie.value, values.arSommeil.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            ...getValue(),
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChangeCheckbox = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.checked, error: "" },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [fArticle, createFrom]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {isAdmin && (fArticle || createFrom) && (
            <>
              {thisEdit || edit ? (
                <>
                  <List>
                    <ListItem disablePadding>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.arPublie.value}
                              onChange={handleChangeCheckbox("arPublie")}
                            />
                          }
                          label={t("word.published")}
                        />
                      </FormGroup>
                    </ListItem>
                    <ListItem disablePadding>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.arSommeil.value}
                              onChange={handleChangeCheckbox("arSommeil")}
                            />
                          }
                          label={t("word.sleep")}
                        />
                      </FormGroup>
                    </ListItem>
                  </List>
                  {!edit && (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-evenly" }}
                    >
                      <Tooltip title={t("word.cancel")}>
                        <IconButton onClick={handleThisEdit} disabled={loading}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("word.save")}>
                        <LoadingButton
                          variant="text"
                          color="inherit"
                          sx={{
                            borderRadius: "50%",
                            minWidth: "auto",
                            padding: "8px",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                          loading={loading}
                          onClick={save}
                        >
                          <SaveIcon />
                        </LoadingButton>
                      </Tooltip>
                    </Box>
                  )}
                </>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={handleThisEdit}>
                    <EditIcon />
                  </IconButton>
                  <List>
                    <ListItem disablePadding>
                      <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {t("word.published")}
                        {(createFrom ?? fArticle)?.arPublie === 1 ? (
                          <DoneIcon color="success" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </Typography>
                    </ListItem>
                    <ListItem disablePadding>
                      <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {t("word.sleep")}
                        {(createFrom ?? fArticle)?.arSommeil === 1 ? (
                          <DoneIcon color="success" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleStateComponent;
