import * as React from "react";
import { useImperativeHandle, useRef } from "react";
import { FArticleInterface } from "../../../../interfaces/FArticleInterface";
import FArticleFormSellingsComponent from "./FArticleFormSellingsComponent";
import { Grid } from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

interface State {
  initFArticle: FArticleInterface | undefined;
  edit: boolean;
  setFArticle: Function;
  refFArticleAfter: any;
}

const FArticleFormComponent = React.memo(
  React.forwardRef(
    ({ initFArticle, edit, setFArticle, refFArticleAfter }: State, ref) => {
      const refSelling: any = useRef();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return { fArticleSellings: refSelling.current.getValue() };
        },
      }));

      return (
        <>
          {(isAdmin || edit) && (
            <Grid item xs={12} sx={{ paddingLeft: "0!important" }}>
              <FArticleFormSellingsComponent
                refFArticleAfter={refFArticleAfter}
                initFArticle={initFArticle}
                ref={refSelling}
                edit={edit}
                setFArticle={setFArticle}
              />
            </Grid>
          )}
        </>
      );
    }
  )
);

export default FArticleFormComponent;
