import React, { useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { TextField } from "@mui/material";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { ArrivageInterface } from "../../../interfaces/ArrivageInterface";
import {
  FArticleInterface,
  TechnicalSheetInterface,
} from "../../../interfaces/FArticleInterface";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import { generateTechnicalSheet } from "../../../helpers/FArticleHelper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CheckIcon from "@mui/icons-material/Check";

interface State {
  fArticle?: FArticleInterface;
  arPoidsnet?: string;
  disabled?: boolean;
  arRef?: string;
  setArrivage?: Function;
  focusMainSearch?: Function;
  createFrom?: FArticleInterface;
  edit?: boolean;
  color?: any;
  setFArticle?: Function;
  temporaryIcecat?: any;
}

interface LabelValue {
  label: string;
  valueLabel: string;
  value: number;
}

interface FormState {
  arPoidsnet: InputInterface;
}

const clone = require("clone");

const UpdateWeightComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        arPoidsnet,
        disabled,
        arRef,
        setArrivage,
        focusMainSearch,
        createFrom,
        edit,
        color,
        setFArticle,
        temporaryIcecat,
      }: State,
      ref
    ) => {
      const theme = useTheme();
      const { t } = useTranslation();
      const [loading, setLoading] = React.useState(false);
      const [thisEdit, setThisEdit] = React.useState(false);
      const getDefaultValues = React.useCallback((): FormState => {
        let thisArPoidsnet = Number(arPoidsnet ?? 0).toString();
        if (temporaryIcecat) {
          const temp: any = {
            ficheTechnique: temporaryIcecat,
          };
          const technicalSheet = generateTechnicalSheet(temp, 1);
          if (technicalSheet?.weight) {
            thisArPoidsnet = technicalSheet.weight.toString();
          }
        }
        return {
          arPoidsnet: { value: thisArPoidsnet, error: "" },
        };
      }, [arPoidsnet, temporaryIcecat]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const getPoidsFields = React.useCallback((): LabelValue[] => {
        let technicalSheet: TechnicalSheetInterface | undefined;
        const result: LabelValue[] = [];
        if (temporaryIcecat) {
          const temp: any = {
            ficheTechnique: temporaryIcecat,
          };
          technicalSheet = generateTechnicalSheet(temp, 1);
        } else if (fArticle) {
          technicalSheet = generateTechnicalSheet(fArticle, 1);
        }
        if (technicalSheet?.groups) {
          for (const group of technicalSheet.groups) {
            for (const prop of group.props) {
              if (prop.name.toLowerCase().includes("poids")) {
                result.push({
                  label: prop.name,
                  value: Number(prop.valueNumeric),
                  valueLabel: prop.value,
                });
              }
            }
          }
        }
        return result;
      }, [fArticle, temporaryIcecat]);
      const [poidsFields, setPoidsFields] = React.useState<LabelValue[]>(
        getPoidsFields()
      );
      const arPoidsnetRef: any = useRef();
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );

      const handleEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
        setTimeout(() => {
          const inputDom = arPoidsnetRef?.current?.querySelector("input");
          if (inputDom) {
            inputDom.focus();
            inputDom.select();
          }
        });
      }, []);

      const selectWeight = React.useCallback((value: number) => {
        setValues((v) => {
          return {
            ...v,
            arPoidsnet: { ...v.arPoidsnet, value: value, error: "" },
          };
        });
      }, []);

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      const getValue = React.useCallback(() => {
        return {
          arPoidsnet: values.arPoidsnet.value.toString(),
        };
      }, [values]);

      const save = React.useCallback(async () => {
        if (Number(values.arPoidsnet.value) === Number(arPoidsnet)) {
          setThisEdit(false);
          if (focusMainSearch) {
            focusMainSearch();
          }
          return;
        }
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: arRef,
            ...getValue(),
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          if (setFArticle) {
            toastr.success(
              t("word.success"),
              t("sentence.notification.farticle_updated")
            );
            setFArticle(response.content);
          }
          if (setArrivage) {
            setArrivage((a: ArrivageInterface) => {
              const f = a.data.fDocenteteLigneArrivages?.find(
                (f) => f.arRef === arRef
              );
              if (f) {
                f.arPoidsnet = response.content.arPoidsnet;
                return clone(a);
              }
              return a;
            });
          }
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
        setThisEdit(false);
        if (focusMainSearch) {
          focusMainSearch();
        }
      }, [
        arPoidsnet,
        arRef,
        focusMainSearch,
        getValue,
        setArrivage,
        setFArticle,
        t,
        token,
        values.arPoidsnet.value,
      ]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setPoidsFields(getPoidsFields());
        setValues(getDefaultValues());
      }, [arPoidsnet, thisEdit, fArticle, temporaryIcecat]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {(arRef || createFrom) && isAdmin && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {((edit !== undefined && !(edit || thisEdit)) ||
                (setArrivage && !thisEdit)) && (
                <Box>
                  <IconButton
                    color={color}
                    onClick={handleEdit}
                    disabled={disabled}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
              {edit || thisEdit ? (
                <>
                  <Box>
                    <TextField
                      sx={{ width: "100%", marginTop: 1 }}
                      fullWidth={true}
                      ref={arPoidsnetRef}
                      autoComplete="off"
                      error={!!values.arPoidsnet.error}
                      helperText={t(values.arPoidsnet.error ?? "")}
                      type="number"
                      value={values.arPoidsnet.value}
                      onChange={handleChange("arPoidsnet")}
                      label={t("field.arPoidsnet")}
                    />
                    {!edit && (
                      <>
                        <Tooltip title={t("word.cancel")}>
                          <IconButton
                            color={color}
                            onClick={handleEdit}
                            disabled={loading}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("word.save")} placement="right">
                          <LoadingButton
                            variant="text"
                            color={color}
                            disabled={disabled}
                            sx={{
                              borderRadius: "50%",
                              minWidth: "auto",
                              padding: "12px",
                              ...(!color && {
                                color: "rgba(0, 0, 0, 0.54)",
                              }),
                            }}
                            loading={loading}
                            onClick={save}
                          >
                            <SaveIcon />
                          </LoadingButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                  {poidsFields && poidsFields.length > 0 && (
                    <Table size="small">
                      <TableBody>
                        {poidsFields.map((poidsField, indexPoidsField) => (
                          <TableRow key={indexPoidsField}>
                            <TableCell component="th" scope="row">
                              {poidsField.label}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {poidsField.valueLabel}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <CheckIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  selectWeight(poidsField.value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      ...(Number(arPoidsnet) === 0 && {
                        color: theme.palette.error.main,
                        fontWeight: "bold",
                      }),
                    }}
                  >
                    {t("word.weight") +
                      ": " +
                      Number(arPoidsnet).toLocaleString() +
                      " g"}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </>
      );
    }
  )
);

export default UpdateWeightComponent;
