import React, { forwardRef, useImperativeHandle } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import { ALL_SELLING } from "../../../utils/FArticleUtils";

interface State {
  data: any;
}

const SearchProductFArticleSellingComponent = React.memo(
  forwardRef(({ data }: State, ref) => {
    const getValue = React.useCallback(() => {
      return data !== undefined && data?.fArticle?.fArticleSellingType
        ? data.fArticle.fArticleSellingType.toString()
        : "";
    }, [data]);

    const [value, setValue] = React.useState<string>(getValue());
    const { t } = useTranslation();

    const onClick = React.useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        const newValue = (event.target as HTMLInputElement).value;
        if (value === newValue) {
          setValue("");
        } else {
          setValue(newValue);
        }
      },
      [value]
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        if (value === "") {
          return null;
        }
        return value;
      },
    }));

    React.useEffect(() => {
      setValue(getValue());
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormControl>
        <FormLabel id={"farticle-selling-radio-buttons-group-label"}>
          {t("word.fArticleSelling.title")}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby={"farticle-selling-radio-buttons-group-label"}
          name={"farticle-selling-radio-buttons-group"}
          value={value}
        >
          {ALL_SELLING.map((sellingType, indexSellingType) => (
            <FormControlLabel
              key={indexSellingType}
              value={sellingType}
              control={<Radio onClick={onClick} />}
              label={t("word.fArticleSelling.type." + sellingType)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  })
);

export default SearchProductFArticleSellingComponent;
