import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Document, Page } from "react-pdf";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface State {
  base64: any;
  nbPagePreview: number;
}

interface State2 {
  base64: any;
  pageNumber: number;
  numPages: number | null;
  previousPage: any;
  nextPage: any;
  nbPagePreview: number;
}

const PagingComponent: React.FC<State2> = React.memo(
  ({ base64, pageNumber, numPages, previousPage, nextPage, nbPagePreview }) => {
    return (
      <>
        {base64 && numPages && numPages > nbPagePreview && (
          <Box sx={{ textAlign: "center", zIndex: 1 }}>
            <p>
              {pageNumber || (numPages ? 1 : "--")}
              {" / "}
              {numPages || "--"}
            </p>
            <IconButton onClick={previousPage}>
              <ArrowBackIcon />
            </IconButton>
            {numPages && (
              <IconButton onClick={nextPage}>
                <ArrowForwardIcon />
              </IconButton>
            )}
          </Box>
        )}
      </>
    );
  }
);

const PdfDisplayComponent: React.FC<State> = React.memo(
  ({ base64, nbPagePreview }) => {
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const getThisNbPagePreview = React.useCallback(() => {
      if (isSmall) {
        return 1;
      }
      let number = Number(nbPagePreview);
      if (number <= 0) {
        number = 1;
      }
      return number;
    }, [isSmall, nbPagePreview]);
    const [thisNbPagePreview, setThisNbPagePreview] = React.useState(
      getThisNbPagePreview()
    );

    const previousPage = React.useCallback(() => {
      setPageNumber((x) => {
        let result = x - thisNbPagePreview;
        if (result < 1) {
          result = 1;
        }
        return result;
      });
    }, [thisNbPagePreview]);

    const handleResize = React.useCallback(() => {
      setThisNbPagePreview(getThisNbPagePreview());
    }, [getThisNbPagePreview]);

    const nextPage = React.useCallback(() => {
      if (numPages) {
        setPageNumber((x) => {
          let result = x + thisNbPagePreview;
          if (result > numPages) {
            result = numPages;
          }
          return result;
        });
      }
    }, [thisNbPagePreview, numPages]);

    const onDocumentLoadSuccess = React.useCallback(
      ({ numPages: nextNumPages }: any) => {
        setNumPages(nextNumPages);
      },
      []
    );

    useEffect(() => {
      handleResize();
    }, [isSmall, nbPagePreview]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {base64 && (
          <Box
            className="react-pdf"
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              border: "dashed 1px grey",
            }}
          >
            <PagingComponent
              nbPagePreview={nbPagePreview}
              base64={base64}
              pageNumber={pageNumber}
              numPages={numPages}
              previousPage={previousPage}
              nextPage={nextPage}
            />
            <Document file={base64} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(thisNbPagePreview), (el, index) => (
                <Box
                  key={pageNumber + index}
                  sx={{ width: 100 / thisNbPagePreview + "%" }}
                >
                  {numPages && pageNumber + index <= numPages && (
                    <Page pageNumber={pageNumber + index} />
                  )}
                </Box>
              ))}
            </Document>
            <PagingComponent
              nbPagePreview={nbPagePreview}
              base64={base64}
              pageNumber={pageNumber}
              numPages={numPages}
              previousPage={previousPage}
              nextPage={nextPage}
            />
          </Box>
        )}
      </>
    );
  }
);

export default PdfDisplayComponent;
