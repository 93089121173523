import * as React from "react";
import { useImperativeHandle } from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ALL_SUIVISTOCK } from "../../../utils/FDoceneteteUtils";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

interface State {
  fArticle: FArticleInterface | undefined;
  edit: boolean;
  setFArticle: Function;
  createFrom?: FArticleInterface;
}

interface FormState {
  arSuivistock: InputInterface;
}

const FArticleSuiviStockComponent = React.memo(
  React.forwardRef(
    ({ fArticle, edit, setFArticle, createFrom }: State, ref) => {
      const { t } = useTranslation();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const getDefaultValues = React.useCallback((): FormState => {
        return {
          arSuivistock: {
            value: (createFrom ?? fArticle)?.arSuivistock,
            error: "",
          },
        };
      }, [createFrom, fArticle]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          arSuivistock: Number(values.arSuivistock.value),
        };
      }, [values.arSuivistock.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            ...getValue(),
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChangeSelect = React.useCallback(
        (prop: keyof FormState) => (event: SelectChangeEvent) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: {
                ...v[prop],
                value: event.target.value as string,
                error: "",
              },
            };
          });
        },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [fArticle, createFrom]); // eslint-disable-line react-hooks/exhaustive-deps

      const canChangeArSuivistock =
        !!createFrom || fArticle?.canChangeArSuivistock === true;

      return (
        <>
          {isAdmin && (fArticle || createFrom) && (
            <>
              {thisEdit || edit ? (
                <>
                  <FormControl size="small">
                    <Select
                      value={values.arSuivistock.value}
                      onChange={handleChangeSelect("arSuivistock")}
                      disabled={!canChangeArSuivistock}
                    >
                      {ALL_SUIVISTOCK.map((v) => (
                        <MenuItem value={v.toString()} key={v}>
                          {t("word.arSuivistock." + v)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {!edit && (
                    <Box sx={{ display: "flex" }}>
                      <Tooltip title={t("word.cancel")}>
                        <IconButton onClick={handleThisEdit} disabled={loading}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("word.save")}>
                        <LoadingButton
                          variant="text"
                          color="inherit"
                          sx={{
                            borderRadius: "50%",
                            minWidth: "auto",
                            padding: "8px",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                          loading={loading}
                          onClick={save}
                        >
                          <SaveIcon />
                        </LoadingButton>
                      </Tooltip>
                    </Box>
                  )}
                </>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {canChangeArSuivistock && (
                    <IconButton onClick={handleThisEdit}>
                      <EditIcon />
                    </IconButton>
                  )}
                  <Typography>
                    {t("word.arSuivistock.label") + ": "}
                    {fArticle?.arSuivistock && (
                      <>{t("word.arSuivistock." + fArticle?.arSuivistock)}</>
                    )}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleSuiviStockComponent;
