import React, { useImperativeHandle, useRef } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FilterValueAssoValueInterface } from "../../../../../../interfaces/FilterInterface";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import Typography from "@mui/material/Typography";
import FilterValueAssoFilterValueFormComponent from "./FilterValueAssoFilterValueFormComponent";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  CONDITION_OR,
  CONDITIONS_FILTER,
} from "../../../../../../utils/FilterUtils";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import FilterValueAssoFilterFormComponent from "./FilterValueAssoFilterFormComponent";

interface State {
  filterValueAssoValue: FilterValueAssoValueInterface | undefined;
  index: number;
  deleteFilterValueAssoValue: Function;
}

interface FormState {
  fromCondition: InputInterface;
  toCondition: InputInterface;
}

const FilterValueAssoValueFormComponent = React.memo(
  React.forwardRef(
    (
      { filterValueAssoValue, index, deleteFilterValueAssoValue }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const fromValueRef: any = useRef();
      const toValueRef: any = useRef();
      const filterRef: any = useRef();
      const getDefaultValue = React.useCallback((): FormState => {
        return {
          fromCondition: {
            value: filterValueAssoValue?.fromCondition ?? CONDITION_OR,
            error: "",
          },
          toCondition: {
            value: filterValueAssoValue?.toCondition ?? CONDITION_OR,
            error: "",
          },
        };
      }, [
        filterValueAssoValue?.fromCondition,
        filterValueAssoValue?.toCondition,
      ]);
      const [values, setValues] = React.useState<FormState>(getDefaultValue());

      const handleChangeSelect = React.useCallback(
        (prop: keyof FormState) => (event: SelectChangeEvent) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: {
                ...v[prop],
                value: event.target.value as string,
                error: "",
              },
            };
          });
        },
        []
      );

      const getValue = React.useCallback(() => {
        const fromValue = fromValueRef.current.getValue();
        const toValue = toValueRef.current.getValue();
        const commonFiltersValue = filterRef.current.getValue();
        if (!fromValue || !toValue || !commonFiltersValue) {
          return undefined;
        }
        return {
          fromCondition: values.fromCondition.value,
          toCondition: values.toCondition.value,
          filterValuesFrom: fromValue,
          filterValuesTo: toValue,
          ...commonFiltersValue,
        };
      }, [values.fromCondition.value, values.toCondition.value]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setValues(getDefaultValue());
      }, [filterValueAssoValue]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <Grid item xs={12} sx={{ display: "flex" }}>
          <Box sx={{ width: "100%" }}>
            {[
              { direction: "from", ref: fromValueRef },
              { direction: "to", ref: toValueRef },
            ].map((data: any, index) => {
              const ucFirstDirection =
                data.direction.charAt(0).toUpperCase() +
                data.direction.slice(1);
              return (
                <Grid item xs={12} key={index}>
                  <Typography>{t("word." + data.direction)}</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={11}>
                      <FilterValueAssoFilterValueFormComponent
                        ref={data.ref}
                        filterValueAssoFilters={
                          filterValueAssoValue
                            ? // @ts-ignore
                              filterValueAssoValue[
                                "filterValues" + ucFirstDirection
                              ]
                            : undefined
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <FormControl fullWidth required>
                        <InputLabel id={"condition-" + data.direction}>
                          {t("field.condition")}
                        </InputLabel>
                        <Select
                          labelId={"condition-" + data.direction}
                          // @ts-ignore
                          value={values[data.direction + "Condition"].value}
                          label={t("field.condition")}
                          onChange={handleChangeSelect(
                            // @ts-ignore
                            data.direction + "Condition"
                          )}
                        >
                          {CONDITIONS_FILTER.map((condition, indexType) => (
                            <MenuItem value={condition} key={indexType}>
                              {t("word.filter.condition." + condition)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12} sx={{ marginTop: 3 }}>
              <FilterValueAssoFilterFormComponent
                ref={filterRef}
                filterValueAssoValue={filterValueAssoValue}
              />
            </Grid>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => deleteFilterValueAssoValue(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Grid>
      );
    }
  )
);

export default FilterValueAssoValueFormComponent;
