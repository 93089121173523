import * as React from "react";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { requestApi } from "../../../helpers/RequestApi";
import { FDOCENTETES_URL } from "../../../utils/UrlsUtils";
import { DELETE } from "../../../utils/MethodUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { DialogProps, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { addHours } from "date-fns";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { set } from "../../../app/globalSlice";
import {
  SAGE_DO_TYPE_VENTE_DE,
  SAGE_DO_TYPE_VENTE_FA,
} from "../../../utils/DoceneteteUtils";
import Box from "@mui/material/Box";

interface State {
  fDocentete: FDocenteteInterface;
}

const DeleteFDocenteteComponent: React.FC<State> = React.memo(
  ({ fDocentete }) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();

    const deleteFDocentete = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: DELETE,
        path: FDOCENTETES_URL + "/" + fDocentete.doPiece,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 204) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.fdocentete_deleted")
        );
        dispatch(set({ refreshPage: true }));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
        setLoading(false);
      }
    }, [dispatch, fDocentete.doPiece, t, token]);

    const handleDialog = React.useCallback(() => {
      setOpenDialog((x) => {
        if (loading) {
          return x;
        }
        return !x;
      });
    }, [loading]);

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          fullWidth={true}
          onClose={handleDialog}
          open={openDialog}
        >
          <DialogContent>
            <Typography>
              {t("sentence.askDeleteFDocentete").replace(
                "%doPiece%",
                fDocentete.doPiece
              )}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <LoadingButton disabled={loading} onClick={handleDialog}>
              {t("word.no")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={deleteFDocentete}
              color="error"
            >
              {t("word.yes")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {fDocentete.doType < SAGE_DO_TYPE_VENTE_FA && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LoadingButton
              variant="contained"
              onClick={handleDialog}
              color="error"
              sx={{ mt: 1 }}
            >
              {t("word.delete")}
            </LoadingButton>
            {fDocentete.doType !== SAGE_DO_TYPE_VENTE_DE && (
              <Tooltip
                title={
                  t("sentence.infoDeleteFDocentete") +
                  " (" +
                  addHours(
                    new Date(fDocentete.cbcreation),
                    1
                  ).toLocaleDateString(i18n.language, {
                    hour: "numeric",
                    minute: "numeric",
                    timeZone: "America/Cuiaba",
                  }) +
                  ")"
                }
              >
                <InfoIcon />
              </Tooltip>
            )}
          </Box>
        )}
      </>
    );
  }
);

export default DeleteFDocenteteComponent;
