const sageNameValidator = (
  sageName: string | null,
  canBeEmpty: boolean = false
) => {
  sageName = sageName?.trim() ?? "";
  if (sageName.length === 0) {
    if (canBeEmpty) {
      return "";
    }
    return "error.not_empty";
  }
  if (sageName.length > 35) {
    return "error.sageName.maxLength35";
  }
  return "";
};
export default sageNameValidator;
