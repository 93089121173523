import React from "react";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import SliderFArticleComponent from "../../slider/SliderFArticleComponent";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  addConditionalSearchFArticle,
  deleteUnwantedParamSearchFArticle,
  getTextsDisplaySearchFArticle,
} from "../../../helpers/FArticleHelper";
import { STORAGE_PRODUCT_PREVIEW_DISPLAY } from "../../../utils/StorageUtils";

const clone = require("clone");

interface State {
  getValue: Function;
  fArticle: FArticleSmallInterface | undefined;
}

const SearchProductPreviewComponent: React.FC<State> = React.memo(
  ({ getValue, fArticle }) => {
    const [loading, setLoading] = React.useState(false);
    const [nbProductsDisplay, setNbProducts] = React.useState(undefined);
    const [fArticles, setFArticles] = React.useState<
      FArticleSmallInterface[] | undefined
    >([]);
    const { t } = useTranslation();
    const [texts, setTexts] = React.useState<any[]>([]);
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const preview = React.useCallback(async () => {
      setLoading(true);
      setFArticles(undefined);
      const value = getValue();
      setNbProducts(value.nbProductsDisplay);
      let thisValue = addConditionalSearchFArticle(clone(value), fArticle);
      const thisTexts = thisValue?.texts;
      thisValue = deleteUnwantedParamSearchFArticle(thisValue);
      const response = await requestApi({
        method: GET,
        path:
          FARTICLE_URL +
          "?itemsPerPage=20&indexable=1&" +
          new URLSearchParams(thisValue).toString(),
        allowError: false,
        paginate: true,
        token: token,
      });
      if (response.statusCode === 200) {
        const thisFArticles = response.content.fArticles;
        setFArticles(thisFArticles);
        setTexts(getTextsDisplaySearchFArticle(thisTexts, thisFArticles));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        setFArticles([]);
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setLoading(false);
    }, [fArticle, getValue, t, token]);

    return (
      <>
        <Box sx={{ textAlign: "center" }}>
          <LoadingButton variant="outlined" onClick={preview} loading={loading}>
            <RefreshIcon />
          </LoadingButton>
        </Box>
        {fArticles &&
          texts.map((text, indexText) => (
            <div
              key={indexText}
              dangerouslySetInnerHTML={{ __html: text.text }}
            />
          ))}
        <SliderFArticleComponent
          fArticles={fArticles}
          initNbProductsDisplay={nbProductsDisplay}
          displayStorage={STORAGE_PRODUCT_PREVIEW_DISPLAY}
        />
      </>
    );
  }
);

export default SearchProductPreviewComponent;
