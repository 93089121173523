import React from "react";
import ContentComponent from "../../components/content/ContentComponent";
import { DialogContent, Grid, TextField } from "@mui/material";
import { InputInterface } from "../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import { requestApi } from "../../helpers/RequestApi";
import { POST } from "../../utils/MethodUtils";
import { EDIT_USER_URL, MAIL_CONFIRMATION_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set, setUser } from "../../app/globalSlice";
import Divider from "@mui/material/Divider";
import mailValidator from "../../helpers/validator/MailValidator";
import getErrorApi from "../../helpers/GetErrorApi";

interface State {
  close: any;
  field: string;
}

interface FormState {
  mail: InputInterface;
}

const EditMailFormComponent: React.FC<State> = React.memo(
  ({ close, field }) => {
    const [values, setValues] = React.useState<FormState>({
      mail: { value: "", error: "" },
    });
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const submit = React.useCallback(async () => {
      setLoading(true);
      const mailValue = values.mail.value.trim();
      if (mailValue !== "" || field === "ctEmail") {
        const mailError = mailValidator(mailValue);
        if (mailError) {
          const newValue: FormState = { ...values };
          if (mailError) {
            newValue.mail.error = mailError;
          }
          setValues(newValue);
          setLoading(false);
          return;
        }
      }
      if (field === "ctEmail") {
        const response = await requestApi({
          method: POST,
          path: MAIL_CONFIRMATION_URL,
          allowError: false,
          body: {
            userIdentifier: user?.userIdentifier,
            newMail: mailValue,
          },
          token: token,
        });
        if (response.statusCode === 201) {
          dispatch(set({ mailConfirmation: response.content }));
          toastr.success(
            t("word.success"),
            t("sentence.notification.mail_verification")
          );
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      } else {
        const response = await requestApi({
          method: POST,
          path: EDIT_USER_URL,
          allowError: false,
          body: {
            userIdentifier: user?.userIdentifier,
            [field]: mailValue,
          },
          token: token,
        });
        if (response.statusCode === 200) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.mail_updated")
          );
          dispatch(setUser(response.content));
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }
      setLoading(false);
      close();
    }, [close, dispatch, field, t, token, user, values]);

    return (
      <>
        <DialogContent>
          {field === "ctEmail" ? (
            <ContentComponent
              contentName="editMail"
              saveLocalStorage={true}
              width={320}
            />
          ) : (
            <ContentComponent
              contentName="editMailNoConfirm"
              saveLocalStorage={true}
              width={320}
            />
          )}
          <Divider sx={{ marginBottom: 2 }} />
          <Grid container spacing={1} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                error={!!values.mail.error}
                helperText={t(values.mail.error ?? "")}
                sx={{ width: "100%" }}
                required={field === "ctEmail"}
                type="text"
                value={values.mail.value}
                onChange={handleChange("mail")}
                label={t("field.mail")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <LoadingButton onClick={close} disabled={loading}>
            {t("word.cancel")}
          </LoadingButton>
          <LoadingButton variant="contained" onClick={submit} loading={loading}>
            {t("word.save")}
          </LoadingButton>
        </DialogActions>
      </>
    );
  }
);

export default EditMailFormComponent;
