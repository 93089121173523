import React from "react";
import { CircularProgress, TextField } from "@mui/material";
import { InputInterface } from "../../../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import { objectToQuery, requestApi } from "../../../../helpers/RequestApi";
import { GET } from "../../../../utils/MethodUtils";
import { F_LOTSERIE_URL } from "../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import Box from "@mui/material/Box";
import { FLotserieInterface } from "../../../../interfaces/FLotserieInterface";
import SavSerialNumbersComponent from "../SavSerialNumbersComponent";

interface FormState {
  search: InputInterface;
}

interface State {
  handleDrawerOpen: Function;
  asAdmin: boolean;
}

const SavFilterlsNoserieComponent: React.FC<State> = React.memo(
  ({ handleDrawerOpen, asAdmin }) => {
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const refreshPage = useAppSelector(
      (state: RootState) => state.globalState.refreshPage
    );
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        search: {
          value: "",
          error: "",
        },
      };
    }, []);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const [isSearching, setIsSearching] = React.useState<boolean>(false);
    const [fLotseries, setFLotseries] = React.useState<FLotserieInterface[]>(
      []
    );

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const searchFArticles = React.useCallback(async () => {
      let search = values.search.value.trim();
      if (search.length < 4) {
        setFLotseries([]);
        return;
      }
      setIsSearching(true);
      const response = await requestApi({
        method: GET,
        path:
          F_LOTSERIE_URL +
          objectToQuery({
            search: search,
            "exists[fDocligne]": true,
            ...(user && { doTiers: user.userIdentifier }),
          }),
        allowError: false,
        paginate: false,
        ...(token && { token: token }),
      });
      if (response.statusCode === 200) {
        setFLotseries(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setIsSearching(false);
    }, [t, token, user, values.search.value]);

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchFArticles();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [values.search.value]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValues((v) => {
        return {
          ...v,
          search: { ...v.search, value: "", error: "" },
        };
      });
    }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <TextField
          fullWidth={true}
          autoComplete="off"
          error={!!values.search.error}
          helperText={t(values.search.error ?? "")}
          sx={{ width: "100%" }}
          type="text"
          value={values.search.value}
          onChange={handleChange("search")}
          label={t("field.lsNoserieRef")}
          placeholder={t("field.lsNoserieRef")}
        />
        {isSearching && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        )}
        <SavSerialNumbersComponent
          search={values.search.value}
          fLotseries={fLotseries}
          handleDrawerOpen={handleDrawerOpen}
          asAdmin={asAdmin}
        />
      </>
    );
  }
);

export default SavFilterlsNoserieComponent;
