import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { dataURItoBlob } from "../../helpers/ResizeImage";
import { InputInterface } from "../../interfaces/InputInterface";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const Input = styled("input")({
  display: "none",
});

interface State {
  data: any;
}

interface FormState {
  autoPlay: InputInterface;
  controls: InputInterface;
  loop: InputInterface;
  muted: InputInterface;
  url: InputInterface;
  width: InputInterface;
  height: InputInterface;
  widthValue: InputInterface;
  heightValue: InputInterface;
  textAlign: InputInterface;
  video: InputInterface;
}

const VideoContentComponent = React.memo(
  forwardRef(({ data }: State, ref) => {
    const { t } = useTranslation();
    const inputFileRef: any = useRef();
    const [file, setFile] = React.useState<File | undefined>(undefined);
    const videoRef: any = useRef();
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        autoPlay: { value: data?.autoPlay ?? false, error: "" },
        controls: { value: data?.controls ?? true, error: "" },
        loop: { value: data?.loop ?? false, error: "" },
        muted: { value: data?.muted ?? false, error: "" },
        url: { value: data?.url ?? "", error: "" },
        width: { value: data?.width ?? false, error: "" },
        height: { value: data?.height ?? false, error: "" },
        widthValue: {
          value:
            data?.width && data?.widthValue
              ? data.widthValue
              : videoRef?.current?.videoWidth ?? "",
          error: "",
        },
        heightValue: {
          value:
            data?.height && data?.heightValue
              ? data.heightValue
              : videoRef?.current?.videoWidth ?? "",
          error: "",
        },
        textAlign: { value: data?.textAlign ?? "left", error: "" },
        video: {
          value: data?.video
            ? process.env.REACT_APP_API_URL + "/DATA" + data.video
            : undefined,
          error: "",
        },
      };
    }, [data]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const addFile = React.useCallback(async (thisFile: File) => {
      setFile(thisFile);
      const reader = new FileReader();
      reader.onload = () => {
        const blob = dataURItoBlob(reader.result as any);
        setValues((x) => {
          x.video.value = window.URL.createObjectURL(blob);
          return { ...x };
        });
      };
      reader.readAsDataURL(thisFile);
    }, []);

    const onChange = React.useCallback(
      (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        addFile(files[0]);
      },
      [addFile]
    );

    const handleCheck = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: {
                ...v[prop],
                value: event.target.checked,
                error: "",
              },
            };
          });
        },
      []
    );

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      []
    );

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        let r: any = {};
        for (const key in values) {
          // @ts-ignore
          r[key] = values[key].value;
        }
        if (r.video) {
          r.video = r.video.replace(
            process.env.REACT_APP_API_URL + "/DATA",
            ""
          );
        }
        if (file) {
          r.video = file;
        }
        return r;
      },
    }));

    useEffect(() => {
      setValues(getDefaultValues());
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (file) {
        setValues((x) => {
          x.widthValue.value = videoRef?.current?.videoWidth ?? "";
          x.heightValue.value = videoRef?.current?.videoHeight ?? "";
          return { ...x };
        });
      }
    }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ marginY: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <label>
            <Input
              accept="video/*"
              type="file"
              onChange={onChange}
              ref={inputFileRef}
            />
            <Button variant="contained" component="span">
              {t("word.selectVideo")}
            </Button>
          </label>
        </Stack>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.autoPlay.value}
                onChange={handleCheck("autoPlay")}
              />
            }
            label={t("word.autoPlay")}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.controls.value}
                onChange={handleCheck("controls")}
              />
            }
            label={t("word.controls")}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.loop.value}
                onChange={handleCheck("loop")}
              />
            }
            label={t("word.loop")}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.muted.value}
                onChange={handleCheck("muted")}
              />
            }
            label={t("word.muted")}
          />
        </FormGroup>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            marginBottom: 1,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={values.width.value}
                onChange={handleCheck("width")}
              />
            }
            label={t("word.width")}
          />
          {values.width.value && (
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.widthValue.error}
              helperText={t(values.widthValue.error ?? "")}
              sx={{ width: "100%" }}
              type="number"
              value={values.widthValue.value}
              onChange={handleChange("widthValue")}
              label={t("word.width")}
            />
          )}
        </FormGroup>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            marginBottom: 1,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={values.height.value}
                onChange={handleCheck("height")}
              />
            }
            label={t("word.height")}
          />
          {values.height.value && (
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.heightValue.error}
              helperText={t(values.heightValue.error ?? "")}
              sx={{ height: "100%" }}
              type="number"
              value={values.heightValue.value}
              onChange={handleChange("heightValue")}
              label={t("field.height")}
            />
          )}
        </FormGroup>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="align-video">{t("field.textAlign")}</InputLabel>
              <Select
                labelId="align-video"
                value={values.textAlign.value}
                label={t("field.textAlign")}
                onChange={handleChangeSelect("textAlign")}
              >
                <MenuItem value={"left"}>{t("word.left")}</MenuItem>
                <MenuItem value={"center"}>{t("word.center")}</MenuItem>
                <MenuItem value={"right"}>{t("word.right")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.url.error}
              helperText={t(values.url.error ?? "")}
              sx={{ height: "100%" }}
              type="url"
              value={values.url.value}
              onChange={handleChange("url")}
              label={t("field.url")}
            />
          </Grid>
        </Grid>
        {values.video.value && (
          <Box sx={{ textAlign: values.textAlign.value, marginTop: 1 }}>
            <video
              style={{
                maxWidth: "100%",
              }}
              ref={videoRef}
              {...(values.autoPlay.value && { autoPlay: true })}
              {...(values.controls.value && { controls: true })}
              {...(values.loop.value && { loop: true })}
              {...(values.muted.value && { muted: true })}
              {...(values.width.value &&
                values.widthValue.value && {
                  width: values.widthValue.value + "px",
                })}
              {...(values.height.value &&
                values.heightValue.value && {
                  height: values.heightValue.value + "px",
                })}
              src={values.video.value}
            ></video>
          </Box>
        )}
      </Box>
    );
  })
);

export default VideoContentComponent;
