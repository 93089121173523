import React, { SyntheticEvent } from "react";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { ArrivageInterface } from "../../../../interfaces/ArrivageInterface";
import { useAppSelector } from "../../../../app/hooks";
import { InputInterface } from "../../../../interfaces/InputInterface";
import { RootState } from "../../../../app/store";
import { objectToQuery, requestApi } from "../../../../helpers/RequestApi";
import { GET, PATCH, POST } from "../../../../utils/MethodUtils";
import {
  ARRIVAGE_URL,
  FCOMPTET_URL,
  FDOCENTETES_URL,
} from "../../../../utils/UrlsUtils";
import getErrorApi from "../../../../helpers/GetErrorApi";
import { LoadingButton } from "@mui/lab";
import notEmptyValidator from "../../../../helpers/validator/NotEmptyValidator";
import { SAGE_DO_TYPE_ACHAT_CC } from "../../../../utils/DoceneteteUtils";

interface OptionBonCommandeInterface {
  doPiece: string;
  doRef: string;
  groupage: string;
  commentaires: string;
}

interface OptionFComptetInterface {
  ctNum: string;
  ctIntitule: string;
}

interface State {
  arrivage?: ArrivageInterface | null;
  successFunction: Function;
}

interface FormState {
  name: InputInterface;
  mandatoryDocuments: InputInterface;
  supplier: InputInterface;
}

const ArrivageFormComponent: React.FC<State> = React.memo(
  ({ arrivage, successFunction }) => {
    const { t } = useTranslation();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const [loading, setLoading] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultValue = React.useCallback((): FormState => {
      return {
        name: {
          value: arrivage?.name ?? "",
          error: "",
        },
        mandatoryDocuments: {
          value:
            arrivage?.mandatoryDocuments?.map(
              (d): OptionBonCommandeInterface => {
                return {
                  doPiece: d,
                  doRef: "",
                  commentaires: "",
                  groupage: "",
                };
              }
            ) ?? [],
          error: "",
        },
        supplier: {
          value: arrivage?.supplier
            ? {
                ctNum: arrivage.supplier,
                ctIntitule: "",
              }
            : null,
          error: "",
        },
      };
    }, [arrivage]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());
    const [optionBonCommandes, setOptionBonCommandes] = React.useState<
      OptionBonCommandeInterface[] | undefined
    >(undefined);
    const [optionFComptets, setOptionFComptets] = React.useState<
      OptionFComptetInterface[] | undefined
    >(undefined);
    const [loadingOption, setLoadingOption] = React.useState<boolean>(true);
    const [loadingOption2, setLoadingOption2] = React.useState<boolean>(true);

    const save = React.useCallback(async () => {
      const nameError = notEmptyValidator(values.name.value);
      if (nameError) {
        const newValue: FormState = { ...values };
        if (nameError) {
          newValue.name.error = nameError;
        }
        setValues(newValue);
        return undefined;
      }
      setLoading(true);
      let path = ARRIVAGE_URL;
      if (arrivage?.id) {
        path += "/" + arrivage?.id;
      }
      const response = await requestApi({
        method: arrivage?.id ? PATCH : POST,
        path: path,
        allowError: true,
        token: token,
        body: {
          name: values.name.value ?? null,
          mandatoryDocuments: values.mandatoryDocuments.value.map(
            (d: OptionBonCommandeInterface) => d.doPiece
          ),
          supplier: values.supplier.value?.ctNum ?? null,
        },
      });
      if (response.statusCode === 201) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.arrivage_created")
        );
        successFunction(response.content);
      } else if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.arrivage_updated")
        );
        successFunction(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [arrivage?.id, successFunction, t, token, values]);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const getAllBonCommande = React.useCallback(async () => {
      setLoadingOption(true);
      const response = await requestApi({
        method: GET,
        path: FDOCENTETES_URL + "/doType/" + SAGE_DO_TYPE_ACHAT_CC + "/doPiece",
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        setOptionBonCommandes(response.content);
        setValues((v) => {
          for (const mandatoryDocument of v.mandatoryDocuments.value) {
            const option: OptionBonCommandeInterface = response.content.find(
              (o: OptionBonCommandeInterface) =>
                o.doPiece === mandatoryDocument.doPiece
            );
            if (option) {
              mandatoryDocument.doRef = option.doRef;
              mandatoryDocument.groupage = option.groupage;
              mandatoryDocument.commentaires = option.commentaires;
            }
          }
          return { ...v };
        });
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingOption(false);
    }, [t, token]);

    const getSuppliers = React.useCallback(async () => {
      setLoadingOption2(true);
      const param: any = {
        ctType: 1,
        itemsPerPage: 1000,
      };
      const response = await requestApi({
        method: GET,
        path: FCOMPTET_URL + objectToQuery(param),
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        setOptionFComptets(response.content);
        setValues((v) => {
          if (values.supplier.value?.ctNum) {
            const option: OptionFComptetInterface = response.content.find(
              (o: OptionFComptetInterface) =>
                o.ctNum === values.supplier.value.ctNum
            );
            if (option) {
              v.supplier.value.ctIntitule = option.ctIntitule;
            }
          }
          return { ...v };
        });
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingOption2(false);
    }, [t, token, values.supplier.value?.ctNum]);

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [user, arrivage]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (optionBonCommandes === undefined) {
        getAllBonCommande();
      }
    }, [optionBonCommandes, arrivage?.mandatoryDocuments]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (optionFComptets === undefined) {
        getSuppliers();
      }
    }, [optionFComptets, arrivage?.supplier]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.name.error}
              helperText={t(values.name.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.name.value}
              onChange={handleChange("name")}
              label={t("field.name")}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={optionBonCommandes ?? []}
              getOptionLabel={(option: OptionBonCommandeInterface) => {
                let result = option.doPiece;
                if (option.doRef) {
                  result += " [" + option.doRef + "]";
                }
                if (option.groupage) {
                  result += " [" + option.groupage + "]";
                }
                if (option.commentaires) {
                  result += " [" + option.commentaires + "]";
                }
                return result;
              }}
              isOptionEqualToValue={(
                option: OptionBonCommandeInterface,
                value: OptionBonCommandeInterface
              ) => option.doPiece === value.doPiece}
              disableCloseOnSelect
              value={values.mandatoryDocuments.value}
              onChange={handleChangeAutocomplete("mandatoryDocuments")}
              loading={loadingOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("word.fdocentete.doType." + SAGE_DO_TYPE_ACHAT_CC)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingOption ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={optionFComptets ?? []}
              getOptionLabel={(option: OptionFComptetInterface) => {
                let result = option.ctNum;
                if (option.ctIntitule) {
                  result += " [" + option.ctIntitule + "]";
                }
                return result;
              }}
              isOptionEqualToValue={(
                option: OptionFComptetInterface,
                value: OptionFComptetInterface
              ) => option.ctNum === value.ctNum}
              value={values.supplier.value}
              onChange={handleChangeAutocomplete("supplier")}
              loading={loadingOption2}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("word.supplier")}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingOption2 ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right", width: "100%" }}>
            <LoadingButton variant="contained" loading={loading} onClick={save}>
              {t("word.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default ArrivageFormComponent;
