import { requestApi, RequestApiParamInterface } from "./RequestApi";
import { GET } from "../utils/MethodUtils";
import { initApp, resetApp, set } from "../app/globalSlice";
import { store } from "../app/store";
import { LOGIN_ME_URL } from "../utils/UrlsUtils";
import {
  STORAGE_CART,
  STORAGE_STATE,
  STORAGE_TOKEN,
} from "../utils/StorageUtils";
import { toastr } from "react-redux-toastr";
import i18n from "../languagues/i18n";

export interface BeforeInitInterface {
  function: Function;
  params: any[];
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const initAppHelper = async (
  token: string | null | undefined,
  beforeInit?: BeforeInitInterface,
  preventReset?: boolean,
  leaveImpersonate?: boolean,
  cartId?: string | null
): Promise<boolean> => {
  if (cartId === undefined) {
    cartId = localStorage.getItem(STORAGE_CART);
  }
  let param = "";
  if (cartId !== null && cartId !== undefined) {
    param = "?cart=" + cartId;
  }
  const params: RequestApiParamInterface = {
    method: GET,
    path: LOGIN_ME_URL + param,
    allowError: false,
    leaveImpersonate: !!leaveImpersonate,
    redirect401: false,
    timeout: 30_000,
  };
  if (typeof token === "string") {
    params.token = token;
  }
  const response = await requestApi(params);
  let result = false;
  if (response.statusCode === 200) {
    if (typeof token === "string") {
      response.content.user.token = token;
    }
    let wait = 0;
    if (beforeInit !== undefined) {
      wait = 100;
      beforeInit.function(...beforeInit.params);
    }
    setTimeout(() => {
      const oldToken = localStorage.getItem(STORAGE_TOKEN);
      if (response.content.user && oldToken !== response.content.user.token) {
        toastr.success(
          i18n.t("word.success"),
          i18n.t("sentence.connectedAs") +
            " " +
            response.content.user.ctIntitule
        );
      }
      store.dispatch(initApp(response.content));
    }, wait);
    return true;
  } else {
    localStorage.removeItem(STORAGE_TOKEN);
    localStorage.removeItem(STORAGE_STATE);
    store.dispatch(set({ token: undefined, init: false }));
    await sleep(10000);
    result = await initAppHelper(undefined, beforeInit, undefined, true);
  }
  if (!preventReset) {
    store.dispatch(resetApp());
  }
  return result;
};
