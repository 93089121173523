import * as React from "react";
import { forwardRef } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import { DialogProps, Grid, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import SingleImageQWantComponent, {
  QWantImageInterface,
} from "./SingleImageQWantComponent";
import { CropperImageInterface } from "../FArticleImageEditComponent";
import Typography from "@mui/material/Typography";
import { FArticleInterface } from "../../../../interfaces/FArticleInterface";

interface State {
  initKeywords?: (string | undefined | null)[];
  setNewImages?: Function;
  customAddImage?: Function;
  singleImage?: boolean;
  createFrom?: FArticleInterface;
  getCreateFromCodebarreRefCons?: Function;
}

export interface SearchInterface {
  keywords: string;
  size?: string;
  ref: any;
}

const ImageQWantComponent = React.memo(
  forwardRef(
    (
      {
        initKeywords,
        setNewImages,
        customAddImage,
        singleImage,
        createFrom,
        getCreateFromCodebarreRefCons,
      }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xl");
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
      const [open, setOpen] = React.useState(false);
      const getKeywords = React.useCallback(() => {
        if (createFrom && getCreateFromCodebarreRefCons) {
          return getCreateFromCodebarreRefCons();
        }
        return initKeywords ?? [];
      }, [createFrom, getCreateFromCodebarreRefCons, initKeywords]);
      const [keywords, setKeywords] = React.useState<
        (string | undefined | null)[]
      >(getKeywords());
      const getSearchs = React.useCallback((): SearchInterface[] => {
        const result: SearchInterface[] = [];
        if (keywords) {
          for (const keyword of keywords) {
            if (typeof keyword === "string") {
              result.push({
                keywords: keyword,
                size: "large",
                ref: React.createRef(),
              });
            }
          }
        }
        if (result.length === 0) {
          result.push({
            keywords: "",
            size: "",
            ref: React.createRef(),
          });
        }
        return result;
      }, [keywords]);
      const [searchs, setSearchs] = React.useState<
        SearchInterface[] | undefined
      >(undefined);

      const handleClose = React.useCallback(() => {
        setOpen(false);
      }, []);

      const handleOpen = React.useCallback(() => {
        setKeywords(getKeywords());
        setOpen(true);
      }, [getKeywords]);

      const addImages = React.useCallback(() => {
        const images: any[] | undefined = searchs?.map((search) =>
          search.ref.current.getValue()
        );
        if (images) {
          let result: QWantImageInterface[] = [];
          for (const thisImages of images) {
            result = [...result, ...thisImages];
          }
          if (customAddImage) {
            customAddImage(result);
          } else if (setNewImages !== undefined) {
            setNewImages((x: (CropperImageInterface | undefined)[]) => {
              for (let image of result) {
                x.splice(x.length - 1, 0, {
                  ref: React.createRef(),
                  url: image.media,
                });
              }
              return [...x];
            });
          }
        }
        handleClose();
      }, [customAddImage, handleClose, searchs, setNewImages]);

      React.useEffect(() => {
        setSearchs(getSearchs());
      }, [keywords]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setKeywords(getKeywords());
      }, [initKeywords]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          <Dialog
            maxWidth={maxWidth}
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={handleClose}
            open={open}
          >
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>{t("sentence.qwant.images")}</Typography>
                </Grid>
                {searchs &&
                  searchs.map((search, indexSearch) => (
                    <Grid
                      item
                      xs={12}
                      md={Math.floor(12 / searchs.length)}
                      key={indexSearch}
                    >
                      <SingleImageQWantComponent
                        initSearch={search}
                        ref={search.ref}
                        singleImage={singleImage}
                        addImages={addImages}
                      />
                    </Grid>
                  ))}
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button onClick={handleClose}>{t("word.cancel")}</Button>
              {!singleImage && (
                <LoadingButton variant="contained" onClick={addImages}>
                  {t("word.add")}
                </LoadingButton>
              )}
            </DialogActions>
          </Dialog>
          <Button
            variant="contained"
            onClick={handleOpen}
            sx={{ marginTop: 1 }}
          >
            {t("word.addFromInternet")}
          </Button>
        </>
      );
    }
  )
);

export default ImageQWantComponent;
