import React, { useEffect } from "react";
import Layout from "../../components/common/Layout";
import LoginComponent from "../../components/common/user/LoginComponent";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import PaymentsComponent from "../../components/common/payment/PaymentsComponent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanelComponent from "../../components/common/TabPanelComponent";
import { getTitle, searchParamToObject } from "../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreditFDocenteteComponent from "../../components/common/payment/CreditFDocenteteComponent";

const AdminPaymentScreen: React.FC = React.memo(() => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const getValue = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return searchParamsObject.tab ? Number(searchParamsObject.tab) : 0;
  }, [searchParams]);
  const [value, setValue] = React.useState(getValue());
  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      // @ts-ignore
      setSearchParams({
        tab: newValue,
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    document.title = getTitle("payments");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue(getValue());
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          variant={!isSmall ? "standard" : "scrollable"}
          centered={!isSmall}
        >
          <Tab label={t("word.payments")} />
          <Tab label={t("word.avoirs")} />
        </Tabs>
        <TabPanelComponent
          sx={{ marginTop: 1 }}
          value={value}
          index={0}
          removeComponentOnHidden={true}
        >
          <Container maxWidth="xl" sx={{ paddingY: 2 }}>
            <PaymentsComponent />
          </Container>
        </TabPanelComponent>
        <TabPanelComponent
          sx={{ marginTop: 1 }}
          value={value}
          index={1}
          removeComponentOnHidden={true}
        >
          <Container maxWidth="xl" sx={{ paddingY: 2 }}>
            <CreditFDocenteteComponent />
          </Container>
        </TabPanelComponent>
      </LoginComponent>
    </Layout>
  );
});

export default AdminPaymentScreen;
