import * as React from "react";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid, TextField, useTheme } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { objectToQuery, requestApi } from "../../../../helpers/RequestApi";
import { GET } from "../../../../utils/MethodUtils";
import { QWANT_IMAGE } from "../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { SearchInterface } from "./ImageQWantComponent";
import Typography from "@mui/material/Typography";

interface State {
  initSearch: SearchInterface;
  singleImage?: boolean;
  addImages?: Function;
}

export interface QWantImageInterface {
  media: string;
  media_preview: string;
  width: number;
  title: string;
  selected?: boolean;
}

const SingleImageQWantComponent = React.memo(
  forwardRef(({ initSearch, singleImage, addImages }: State, ref) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [search, setSearch] = React.useState<SearchInterface>(initSearch);
    const [searchTyping, setSearchTyping] = React.useState<string>(
      initSearch.keywords
    );
    const [searching, setSearching] = React.useState<boolean>(false);
    const [images, setImages] = React.useState<QWantImageInterface[]>([]);

    const handleChange = React.useCallback(
      (prop: keyof SearchInterface) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchTyping(event.target.value);
        },
      []
    );

    const handleChangeSelect = React.useCallback(
      (prop: keyof SearchInterface) => (event: SelectChangeEvent) => {
        setSearch((s) => {
          if (!s) {
            return s;
          }
          s[prop] = event.target.value as string;
          return { ...s };
        });
      },
      []
    );

    const searchImages = React.useCallback(async () => {
      if (search.keywords === "") {
        return;
      }
      setSearching(true);
      const thisSearch = { ...search };
      if (!thisSearch.size) {
        delete thisSearch.size;
      }
      const response = await requestApi({
        method: GET,
        path: QWANT_IMAGE + objectToQuery(thisSearch),
        allowError: false,
      });
      if (
        response.statusCode === 200 &&
        response.content.status === "success"
      ) {
        setImages(response.content.data.result.items);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setSearching(false);
    }, [search, t]);

    const selectImage = React.useCallback((index: number) => {
      setImages((i) => {
        i[index].selected = !i[index].selected;
        return [...i];
      });
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return images.filter((image) => image.selected);
      },
    }));

    React.useEffect(() => {
      setSearch(initSearch);
    }, [initSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setSearchTyping(search?.keywords);
      searchImages();
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (
        images.filter((i) => i.selected).length > 0 &&
        singleImage &&
        addImages
      ) {
        addImages();
      }
    }, [images]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        setSearch((s) => {
          if (s.keywords !== searchTyping) {
            s.keywords = searchTyping;
            return { ...s };
          }
          return s;
        });
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [searchTyping]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box>
        <Box sx={{ display: "flex" }}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            sx={{ width: "100%" }}
            required
            type="text"
            value={searchTyping}
            onChange={handleChange("keywords")}
            label={t("word.search")}
          />
          <FormControl fullWidth required>
            <Select
              value={search?.size}
              displayEmpty
              onChange={handleChangeSelect("size")}
            >
              <MenuItem value="">
                <em>{t("word.all")}</em>
              </MenuItem>
              <MenuItem value="large">{t("word.large")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {searching ? (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {images.length === 0 && (
              <Typography sx={{ textAlign: "center", marginTop: 1 }}>
                {t("word.noResult")}
              </Typography>
            )}
            <Grid container spacing={1}>
              {images.map((image, indexImage) => (
                <Grid item xs={12} md={3} key={indexImage}>
                  <img
                    src={image.media_preview}
                    loading="lazy"
                    onClick={() => selectImage(indexImage)}
                    style={{
                      maxWidth: "100%",
                      margin: "auto",
                      display: "block",
                      border: "1px dashed gray",
                      padding: "2px",
                      cursor: "pointer",
                      ...(image.selected && {
                        border: "3px solid " + theme.palette.primary.main,
                        padding: 0,
                      }),
                    }}
                    alt={image.title}
                  />
                  <Typography sx={{ textAlign: "center" }}>
                    [{image.width}]
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
    );
  })
);

export default SingleImageQWantComponent;
