export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_USER = "ROLE_USER";

export const TARIF_PARTENAIRE = 2;
export const TARIF_REMISE = 1;
export const TARIF_GC = 3;
export const TARIF_PUBLIC = 4;

export const CT_CONTROLENC_AUTOMATIQUE = 0;
export const CT_CONTROLENC_SELON_CODE_RISQUE = 1;
export const CT_CONTROLENC_COMPTE_BLOQUE = 2;

export const EN_CPTE_BONPOURACCORD = "En Cpte BonpourAccord";
export const EN_CPTE_AVEC_BDC = "En Cpte avec BdC";
export const EN_CPTE_BDC_A_LIVRAI = "En Cpte +BdC à Livrai";
export const EN_CPTE_EN_REF_BDC = "En Cpte en Réf Bdc";
export const EN_CPTE_CHEQUE_LIVRAI = "En Cpte Chèque livrai";
export const EN_CPTE_BPACCORD_SIGNE = "En Cpte BPAccord signé";
export const COMPTANT_SS_BDC = "Comptant ss BdC";
export const BPA_AVEC_2_SIGNATURES = "BPA AVEC 2 SIGNATURES";
export const EN_CPTE_SANS_BDC = "En Cpte sans BdC";
export const EN_CPTE_BPACCORD = "En Cpte BPAccord";
export const EN_CPTE_BPA_SIGNATU = "En cpte BPA +2 signatu";
export const LIVRAISON_AVOIR_BPA = "Livraison avoir+BPA";
export const COMPTANT = "Comptant ";
export const LIVRAISON_BPA_DOC_VIR = "Livraison BPA+doc Vir";
export const EN_CPTE_AVEC_MDPASSE = "En Cpte avec MdPasse";

export const USER_BON_DE_COMMANDE = [
  EN_CPTE_BONPOURACCORD,
  EN_CPTE_AVEC_BDC,
  EN_CPTE_BDC_A_LIVRAI,
  EN_CPTE_EN_REF_BDC,
  // EN_CPTE_CHEQUE_LIVRAI,
  EN_CPTE_BPACCORD_SIGNE,
  // COMPTANT_SS_BDC,
  BPA_AVEC_2_SIGNATURES,
  // EN_CPTE_SANS_BDC,
  EN_CPTE_BPACCORD,
  EN_CPTE_BPA_SIGNATU,
  LIVRAISON_AVOIR_BPA,
  // COMPTANT,
  LIVRAISON_BPA_DOC_VIR,
  EN_CPTE_AVEC_MDPASSE,
];

export const USER_EN_COMPTE = [
  EN_CPTE_BONPOURACCORD,
  EN_CPTE_AVEC_BDC,
  EN_CPTE_BDC_A_LIVRAI,
  // EN_CPTE_EN_REF_BDC,
  // EN_CPTE_CHEQUE_LIVRAI,
  EN_CPTE_BPACCORD_SIGNE,
  // COMPTANT_SS_BDC,
  BPA_AVEC_2_SIGNATURES,
  EN_CPTE_SANS_BDC,
  EN_CPTE_BPACCORD,
  EN_CPTE_BPA_SIGNATU,
  // LIVRAISON_AVOIR_BPA,
  // COMPTANT,
  // LIVRAISON_BPA_DOC_VIR,
  EN_CPTE_AVEC_MDPASSE,
];

export const GUADELOUPE = "Guadeloupe";
export const MARTINIQUE = "Martinique";
export const GUYANE = "Guyane";
export const ALL_COUNTRIES_DELIVERY = [GUADELOUPE, MARTINIQUE, GUYANE];

export const COUNTRY_CODE_MAP: any = {
  [GUADELOUPE]: "GP",
  [MARTINIQUE]: "MQ",
  [GUYANE]: "GF",
};
