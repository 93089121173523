import React from "react";
import ContentComponent from "../../components/content/ContentComponent";
import { DialogContent, Grid, TextField } from "@mui/material";
import { InputInterface } from "../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import { requestApi } from "../../helpers/RequestApi";
import { POST } from "../../utils/MethodUtils";
import { EDIT_USER_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import sageNameValidator from "../../helpers/validator/SageNameValidator";
import { setUser } from "../../app/globalSlice";
import Divider from "@mui/material/Divider";
import getErrorApi from "../../helpers/GetErrorApi";

interface State {
  close: any;
}

interface FormState {
  contact: InputInterface;
}

const EditNameFormComponent: React.FC<State> = React.memo(({ close }) => {
  const [values, setValues] = React.useState<FormState>({
    contact: { value: "", error: "" },
  });
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const submit = React.useCallback(async () => {
    setLoading(true);
    const contactError = sageNameValidator(values.contact.value);
    if (contactError) {
      const newValue: FormState = { ...values };
      if (contactError) {
        newValue.contact.error = contactError;
      }
      setValues(newValue);
      setLoading(false);
      return;
    }
    const response = await requestApi({
      method: POST,
      path: EDIT_USER_URL,
      allowError: false,
      body: {
        userIdentifier: user?.userIdentifier,
        ctIntitule: values.contact.value,
      },
      token: token,
    });
    if (response.statusCode === 200) {
      toastr.success(
        t("word.success"),
        t("sentence.notification.name_updated")
      );
      dispatch(setUser(response.content));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
    close();
  }, [close, dispatch, t, token, user?.userIdentifier, values]);

  return (
    <>
      <DialogContent>
        <ContentComponent
          contentName="editName"
          saveLocalStorage={true}
          width={320}
        />
        <Divider sx={{ marginBottom: 2 }} />
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              error={!!values.contact.error}
              helperText={t(values.contact.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.contact.value}
              onChange={handleChange("contact")}
              label={t("field.lastname") + " " + t("field.firstname")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <LoadingButton onClick={close} disabled={loading}>
          {t("word.cancel")}
        </LoadingButton>
        <LoadingButton variant="contained" onClick={submit} loading={loading}>
          {t("word.save")}
        </LoadingButton>
      </DialogActions>
    </>
  );
});

export default EditNameFormComponent;
