import React, { forwardRef, useRef } from "react";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";
import FormCategoryComponent from "./FormCategoryComponent";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { DialogProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { jsonToFormData, requestApi } from "../../../helpers/RequestApi";
import { DELETE, GET, PATCH, POST } from "../../../utils/MethodUtils";
import {
  CATEGORY_CHILDREN_URL,
  CATEGORY_IMAGE_URL,
  CATEGORY_URL,
  UPDATE_ENTITY_CONTENT_URL,
} from "../../../utils/UrlsUtils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { set } from "../../../app/globalSlice";
import Typography from "@mui/material/Typography";
import { CATEGORY_CLASSNAME } from "../../../utils/ClassnameUtils";

interface State {
  category: CategoryInterface | undefined;
  setCategory?: Function;
  openDialog: boolean;
  handleClose: any;
}

interface FormState {
  image: File | null | undefined;
  name: string;
  parent: string | null;
  sizes?: null;
  extension?: null;
  filename?: null;
  content?: any;
}

const DialogFormCategoryComponent = React.memo(
  forwardRef(
    ({ category, setCategory, openDialog, handleClose }: State, ref) => {
      const formRef: any = useRef();
      const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
      const { t } = useTranslation();
      const [loading, setLoading] = React.useState(false);
      const [categoryDelete, setCategoryDelete] = React.useState<
        CategoryInterface | undefined
      >(undefined);
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const dispatch = useAppDispatch();

      const onSaveSuccess = React.useCallback(() => {
        dispatch(set({ refreshPage: true }));
        handleClose();
        toastr.success(
          t("word.success"),
          t("sentence.notification.category_updated")
        );
        toastr.info(
          t("word.informations"),
          t("sentence.notification.cache_update")
        );
      }, [dispatch, handleClose, t]);

      const closeDeleteDialog = React.useCallback(() => {
        if (loading) {
          return;
        }
        setCategoryDelete(undefined);
      }, [loading]);

      const onDeleteSuccess = React.useCallback(() => {
        dispatch(set({ refreshPage: true }));
        handleClose();
        closeDeleteDialog();
        toastr.success(
          t("word.success"),
          t("sentence.notification.category_deleted")
        );
      }, [closeDeleteDialog, dispatch, handleClose, t]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const form: FormState = await formRef.current.getValue();
        let newContent: any = undefined;
        if (form.hasOwnProperty("content") && form.content != null) {
          newContent = form.content;
          delete form.content;
        }

        const image: File | null | undefined = form.image;
        if (form.image === null) {
          form.sizes = null;
          form.extension = null;
          form.filename = null;
        }
        delete form.image;
        let hasError = false;
        let categoryId = category?.id;
        let url = "";
        if (category) {
          url = "/" + categoryId;
        }
        let response = await requestApi({
          method: category ? PATCH : POST,
          path: CATEGORY_URL + url,
          allowError: true,
          token: token,
          body: form,
        });
        if (response.statusCode === 200 || response.statusCode === 201) {
          categoryId = response.content.id;
          if (setCategory) {
            setCategory(response.content);
          }
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          hasError = true;
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        if (hasError) {
          setLoading(false);
          return;
        }
        if (newContent !== undefined) {
          newContent.id = response.content.id;
          newContent.classname = CATEGORY_CLASSNAME;
          const formData = jsonToFormData(newContent);
          response = await requestApi({
            method: POST,
            path: UPDATE_ENTITY_CONTENT_URL,
            allowError: true,
            token: token,
            body: formData,
            formData: true,
          });
        }
        if (image) {
          const categoryApi = new FormData();
          categoryApi.append("categoryFile", CATEGORY_URL + "/" + categoryId);
          categoryApi.append("file", image);
          response = await requestApi({
            method: POST,
            path: CATEGORY_IMAGE_URL,
            allowError: true,
            token: token,
            body: categoryApi,
            formData: true,
          });
          if (response.statusCode !== 201) {
            for (let message of getErrorApi(response.content)) {
              toastr.error(t("word.error"), t(message));
            }
          } else {
            onSaveSuccess();
          }
        } else {
          onSaveSuccess();
        }
        setLoading(false);
      }, [category, onSaveSuccess, setCategory, t, token]);

      const deleteCategory = React.useCallback(async () => {
        setLoading(true);
        const response = await requestApi({
          method: DELETE,
          path: CATEGORY_URL + "/" + categoryDelete?.id,
          allowError: false,
          token: token,
        });
        if (response.statusCode === 204) {
          onDeleteSuccess();
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [categoryDelete?.id, onDeleteSuccess, t, token]);

      const loadCategoryToDeleteChildren = React.useCallback(async () => {
        if (!categoryDelete || categoryDelete.children !== undefined) {
          return;
        }
        const response = await requestApi({
          method: GET,
          path: CATEGORY_CHILDREN_URL.replace(
            "{id}",
            String(categoryDelete.id)
          ),
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          setCategoryDelete((x) => {
            if (!x) {
              return x;
            }
            return {
              ...x,
              children: response.content,
            };
          });
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      }, [categoryDelete, t, token]);

      React.useEffect(() => {
        loadCategoryToDeleteChildren();
      }, [categoryDelete]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          <Dialog
            maxWidth={maxWidth}
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={closeDeleteDialog}
            open={!!categoryDelete}
          >
            <DialogContent>
              <Typography>
                {t("sentence.deleteCategory.question").replace(
                  "%categoryName%",
                  categoryDelete?.name ?? ""
                )}
              </Typography>
              {categoryDelete?.children &&
                categoryDelete.children.length > 0 && (
                  <Typography>
                    {t("sentence.deleteCategory.questionChildren").replace(
                      "%nbChildren%",
                      String(categoryDelete.children.length ?? "")
                    )}
                  </Typography>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <LoadingButton disabled={loading} onClick={closeDeleteDialog}>
                {t("word.cancel")}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                loading={loading || categoryDelete?.children === undefined}
                onClick={deleteCategory}
                color="error"
              >
                {t("word.delete")}
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog
            maxWidth={maxWidth}
            fullWidth={true}
            fullScreen={fullScreen}
            onClose={handleClose}
            open={openDialog}
          >
            <DialogContent>
              <FormCategoryComponent category={category} ref={formRef} />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <LoadingButton disabled={loading} onClick={handleClose}>
                {t("word.cancel")}
              </LoadingButton>
              <LoadingButton
                disabled={loading}
                onClick={() => {
                  setCategoryDelete(category);
                }}
                color="error"
              >
                {t("word.delete")}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={save}
              >
                {t("word.save")}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  )
);

export default DialogFormCategoryComponent;
