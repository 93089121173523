import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import {
  FilterValueAssoFilterInterface,
  FilterValueAssoValueInterface,
} from "../../../../../../interfaces/FilterInterface";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import { useAppSelector } from "../../../../../../app/hooks";
import { RootState } from "../../../../../../app/store";
import {
  objectToQuery,
  requestApi,
} from "../../../../../../helpers/RequestApi";
import { GET } from "../../../../../../utils/MethodUtils";
import { FILTER_URL } from "../../../../../../utils/UrlsUtils";
import getErrorApi from "../../../../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  CONDITION_OR,
  CONDITIONS_FILTER,
} from "../../../../../../utils/FilterUtils";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

interface State {
  onChange?: Function;
  filterValueAssoValue: FilterValueAssoValueInterface | undefined;
}

interface FormState {
  commonFilters: InputInterface;
  commonFiltersCondition: InputInterface;
}

const FilterValueAssoFilterFormComponent = React.memo(
  React.forwardRef(({ filterValueAssoValue, onChange }: State, ref) => {
    const { t } = useTranslation();
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        commonFilters: {
          value: filterValueAssoValue?.commonFilters ?? [],
          error: "",
        },
        commonFiltersCondition: {
          value: filterValueAssoValue?.commonFiltersCondition ?? CONDITION_OR,
          error: "",
        },
      };
    }, [filterValueAssoValue]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [filters, setFilters] = React.useState<
      FilterValueAssoFilterInterface[]
    >(values.commonFilters.value);
    const [searchFilterValue, setSearchFilterValue] =
      React.useState<string>("");
    const [isTyping, setIsTyping] = React.useState<boolean>(false);
    const [isSearching, setIsSearching] = React.useState<boolean>(false);
    const getHasFilter = React.useCallback((): boolean => {
      return (filterValueAssoValue?.commonFilters?.length ?? 0) > 0;
    }, [filterValueAssoValue?.commonFilters?.length]);
    const [hasFilter, setHasFilter] = React.useState<boolean>(getHasFilter());

    const handleSearchFilters = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: string) => {
        setSearchFilterValue(value);
        setFilters([]);
        setIsTyping(true);
      },
      []
    );

    const searchFilters = React.useCallback(async () => {
      setIsTyping(false);
      setIsSearching(true);
      const search = searchFilterValue.trim();
      if (search !== "") {
        const response = await requestApi({
          method: GET,
          path:
            FILTER_URL +
            "/filter-value-asso-group" +
            objectToQuery({
              search: search,
            }),
          allowError: false,
          paginate: false,
          token: token,
        });
        if (response.statusCode === 200) {
          response.content.sort(
            (
              a: FilterValueAssoFilterInterface,
              b: FilterValueAssoFilterInterface
            ) => a.id - b.id
          );
          setFilters(response.content);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }
      setIsSearching(false);
    }, [searchFilterValue, t, token]);

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      if (!hasFilter) {
        return {
          commonFilters: [],
          commonFiltersCondition: null,
        };
      }
      if (values.commonFilters.value.length === 0) {
        setValues((v) => {
          v.commonFilters.error = t("error.field.empty");
          return { ...v };
        });
        return undefined;
      }
      return {
        commonFilters: values.commonFilters.value.map(
          (v: FilterValueAssoFilterInterface) => {
            return FILTER_URL + "/" + v.id;
          }
        ),
        commonFiltersCondition: values.commonFiltersCondition.value,
      };
    }, [
      hasFilter,
      t,
      values.commonFilters.value,
      values.commonFiltersCondition.value,
    ]);

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      if (onChange) {
        onChange();
      }
    }, [JSON.stringify(values.commonFilters.value)]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValues(getDefaultValues());
      setHasFilter(getHasFilter());
    }, [filterValueAssoValue]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchFilters();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [searchFilterValue]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12} md={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasFilter}
                    onChange={() => {
                      setHasFilter((x) => !x);
                    }}
                  />
                }
                label={t("word.commonFilters")}
              />
            </FormGroup>
          </Grid>
          {hasFilter && (
            <>
              <Grid item xs={12} md={9}>
                <Autocomplete
                  multiple={true}
                  className="autocomplete-height-adjust"
                  loadingText={t("word.loading") + "..."}
                  inputValue={searchFilterValue}
                  onInputChange={handleSearchFilters}
                  groupBy={(option: FilterValueAssoFilterInterface) =>
                    "(" +
                    option.filterCategories
                      .map(
                        (filterCategory) =>
                          "[" +
                          filterCategory.category.id.toString() +
                          "] " +
                          filterCategory.category.name
                      )
                      .join(" | ") +
                    ")"
                  }
                  getOptionLabel={(option: FilterValueAssoFilterInterface) => {
                    return (
                      "(" +
                      option.filterCategories
                        .map(
                          (filterCategory) =>
                            "[" +
                            filterCategory.category.id.toString() +
                            "] " +
                            filterCategory.category.name
                        )
                        .join(" | ") +
                      ") | [" +
                      option.id +
                      "] " +
                      option.name
                    );
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} style={{ display: "block" }}>
                        {"[" + option.id + "] " + option.name}
                      </li>
                    );
                  }}
                  isOptionEqualToValue={(
                    option: FilterValueAssoFilterInterface,
                    value: FilterValueAssoFilterInterface
                  ) => option.id === value.id}
                  noOptionsText={
                    isSearching ? (
                      <Box sx={{ textAlign: "center" }}>
                        <CircularProgress />
                      </Box>
                    ) : searchFilterValue !== "" && !isTyping ? (
                      t("sentence.no_option")
                    ) : (
                      t("word.searchFilterValue.label")
                    )
                  }
                  options={filters}
                  onChange={handleChangeAutocomplete("commonFilters")}
                  value={values.commonFilters.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      error={!!values.commonFilters.error}
                      helperText={t(values.commonFilters.error ?? "")}
                      label={t("word.filters")}
                      placeholder={t("word.filters")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <FormControl fullWidth required>
                  <InputLabel id={"condition-filter"}>
                    {t("field.condition")}
                  </InputLabel>
                  <Select
                    labelId={"condition-filter"}
                    value={values.commonFiltersCondition.value}
                    label={t("field.condition")}
                    onChange={handleChangeSelect("commonFiltersCondition")}
                  >
                    {CONDITIONS_FILTER.map((condition, indexType) => (
                      <MenuItem value={condition} key={indexType}>
                        {t("word.filter.condition." + condition)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  })
);

export default FilterValueAssoFilterFormComponent;
