import React from "react";
import {
  ArrivageInterface,
  IgnoredDocumentInterface,
} from "../../../interfaces/ArrivageInterface";
import { Chip } from "@mui/material";

interface State {
  arrivage: ArrivageInterface;
  updateIgnoredDocument: Function;
  running: boolean;
}

interface State2 {
  arrivage: ArrivageInterface;
  ignoredDocument: IgnoredDocumentInterface;
  updateIgnoredDocument: Function;
  running: boolean;
}

const SingleEntreeStockIgnoredDocumentsComponent: React.FC<State2> = React.memo(
  ({ arrivage, ignoredDocument, updateIgnoredDocument, running }) => {
    return (
      <Chip
        label={ignoredDocument.doPiece}
        disabled={running}
        onDelete={
          running
            ? undefined
            : () => {
                updateIgnoredDocument([
                  ...arrivage.ignoredDocuments.filter(
                    (ignoredDocument2) =>
                      !(
                        ignoredDocument2.doPiece === ignoredDocument.doPiece &&
                        ignoredDocument2.doType === ignoredDocument.doType
                      )
                  ),
                ]);
              }
        }
      />
    );
  }
);

const EntreeStockIgnoredDocumentsComponent: React.FC<State> = React.memo(
  ({ arrivage, updateIgnoredDocument, running }) => {
    return (
      <>
        {arrivage.ignoredDocuments.map(
          (ignoredDocument, indexIgnoredDocument) => (
            <SingleEntreeStockIgnoredDocumentsComponent
              key={indexIgnoredDocument}
              arrivage={arrivage}
              ignoredDocument={ignoredDocument}
              updateIgnoredDocument={updateIgnoredDocument}
              running={running}
            />
          )
        )}
      </>
    );
  }
);

export default EntreeStockIgnoredDocumentsComponent;
