import React from "react";
import {
  Accordion,
  AccordionDetails,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import HideImageIcon from "@mui/icons-material/HideImage";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { useAppSelector } from "../../../../app/hooks";
import { CategoryInterface } from "../../../../interfaces/CategoryInterface";
import { RootState } from "../../../../app/store";
import { CATEGORY_PAGE } from "../../../../utils/RouteUtils";
import { getUrlCategoryImage } from "../../../../helpers/FileHelper";
import { IMAGE_SIZE_SMALL } from "../../../../utils/FileUtils";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    position: "relative",
    right: "42px",
    marginLeft: "0 !important",
  },
}));

interface State2 {
  showFormCategory: Function;
}

interface ColumnCategory {
  categories: (CategoryInterface | undefined)[];
}

const CategoryMediumComponent: React.FC<State2> = React.memo(
  ({ showFormCategory }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [defaultExpanded] = React.useState<boolean>(!isSmall);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const categories = useAppSelector(
      (state: RootState) => state.globalState.categories
    );
    const getCategoriesColumn = React.useCallback((): ColumnCategory[] => {
      let result: ColumnCategory[] = [{ categories: [] }];
      if (!categories) {
        return result;
      }
      let nbColumns = 3;
      if (isSmall) {
        nbColumns = 1;
      }
      let index = 0;
      for (const category of categories.filter(
        (category) =>
          category.children &&
          category.parent === null &&
          category.enabled &&
          category.children.length > 0
      )) {
        if (result[index].categories.length >= nbColumns) {
          result.push({ categories: [] });
          index++;
        }
        result[index].categories.push(category);
      }
      for (let i = 0; i < nbColumns - result[index].categories.length; i++) {
        result[index].categories.push(undefined);
      }
      return result;
    }, [categories, isSmall]);
    const [categoryColumns, setCategoriesColumn] = React.useState<
      ColumnCategory[]
    >(getCategoriesColumn());

    React.useEffect(() => {
      setCategoriesColumn(getCategoriesColumn());
    }, [categories, isSmall]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {categoryColumns.map((categoryColumn, indexCategoryColumn) => (
          <Box sx={{ display: "flex", marginY: 2 }} key={indexCategoryColumn}>
            {categoryColumn.categories.map(
              (parentCategory, parentCategoryIndex) => (
                <Box sx={{ flex: 1, paddingX: 0.5 }} key={parentCategoryIndex}>
                  {parentCategory && (
                    <Accordion
                      key={parentCategoryIndex}
                      defaultExpanded={defaultExpanded}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        sx={{ backgroundColor: theme.palette.primary.main }}
                      >
                        {isAdmin ? (
                          <LightTooltip
                            title={
                              <IconButton
                                onClick={showFormCategory(parentCategory)}
                              >
                                <EditIcon htmlColor="white" />
                              </IconButton>
                            }
                            placement="right"
                          >
                            <Typography
                              sx={{ color: "white", paddingRight: 4 }}
                            >
                              {isAdmin &&
                                "[" +
                                  parentCategory.id +
                                  "] " +
                                  parentCategory.name}
                            </Typography>
                          </LightTooltip>
                        ) : (
                          <Typography sx={{ color: "white" }}>
                            {parentCategory.name}
                          </Typography>
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        {/*@ts-ignore*/}
                        {[...parentCategory.children]
                          .filter((c) => c.enabled)
                          .sort((a, b) => a.sort - b.sort)
                          .map((childCategory, childCategoryIndex) => (
                            <Box
                              key={childCategoryIndex}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              {childCategory.sizes !== null ? (
                                <Link
                                  to={CATEGORY_PAGE + "/" + childCategory.slug}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <img
                                    src={
                                      (process.env.REACT_APP_API_URL ?? "") +
                                      getUrlCategoryImage(
                                        childCategory,
                                        IMAGE_SIZE_SMALL
                                      )
                                    }
                                    style={{
                                      maxWidth: "3em",
                                      maxHeight: "3em",
                                    }}
                                    alt={
                                      "[" +
                                      childCategory.id +
                                      "] " +
                                      childCategory.name
                                    }
                                    loading="lazy"
                                  />
                                </Link>
                              ) : (
                                <HideImageIcon
                                  sx={{
                                    width: "50%",
                                    height: "50%",
                                    maxWidth: "2em",
                                    maxHeight: "2em",
                                  }}
                                />
                              )}
                              <Link
                                to={CATEGORY_PAGE + "/" + childCategory.slug}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                {isAdmin ? (
                                  <LightTooltip
                                    title={
                                      <IconButton
                                        onClick={showFormCategory(
                                          childCategory
                                        )}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    }
                                    placement="right"
                                  >
                                    <Typography sx={{ paddingRight: 4 }}>
                                      {"[" +
                                        childCategory.id +
                                        "] " +
                                        childCategory.name}
                                    </Typography>
                                  </LightTooltip>
                                ) : (
                                  <Typography>{childCategory.name}</Typography>
                                )}
                              </Link>
                            </Box>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Box>
              )
            )}
          </Box>
        ))}
      </>
    );
  }
);

export default CategoryMediumComponent;
