import * as React from "react";
import { useRef } from "react";
import { MailTemplateInterface } from "../../../interfaces/MailInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import CkEditorComponent from "../../content/CkEditorComponent";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { MAIL_TEMPLATE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";

interface State {
  mailTemplate: MailTemplateInterface;
}

const MailTemplateFormComponent: React.FC<State> = React.memo(
  ({ mailTemplate }) => {
    const { t } = useTranslation();
    const refContent: any = useRef();
    const [loading, setLoading] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const save = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: PATCH,
        path: MAIL_TEMPLATE_URL + "/" + mailTemplate.id,
        allowError: true,
        token: token,
        body: {
          content: await refContent.current.getValue(),
        },
      });
      if (response.statusCode === 200) {
        toastr.success(t("word.success"), t("sentence.mailValidated"));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [mailTemplate.id, t, token]);

    return (
      <>
        <CkEditorComponent ref={refContent} text={mailTemplate.content} />
        <Box sx={{ marginTop: 2, textAlign: "right" }}>
          <LoadingButton variant="contained" loading={loading} onClick={save}>
            {t("word.save")}
          </LoadingButton>
        </Box>
      </>
    );
  }
);

export default MailTemplateFormComponent;
