import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import {
  Autocomplete,
  Checkbox,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { InputInterface } from "../../../interfaces/InputInterface";
import {
  FFamilleInterface,
  FFamilleMappingInterface,
} from "../../../interfaces/ConfiguratorEnergyInterface";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface FormState {
  id: number | undefined;
  name: string | undefined;
  fFamille: InputInterface;
  mapped: InputInterface;
}

interface State {
  index: number;
}

const FFamilleMappingFormComponent = React.memo(
  React.forwardRef(({ index }: State, ref) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const fFamilleMappings = useAppSelector(
      (state: RootState) => state.globalState.fFamilleMappings
    );
    const fFamilles = useAppSelector(
      (state: RootState) => state.globalState.fFamilles
    );
    const getFFamilleMapping = React.useCallback(():
      | FFamilleMappingInterface
      | undefined => {
      if (fFamilleMappings !== undefined) {
        return fFamilleMappings[index];
      }
      return undefined;
    }, [fFamilleMappings, index]);
    const [fFamilleMapping, setFFamilleMapping] = React.useState<
      FFamilleMappingInterface | undefined
    >(getFFamilleMapping());
    const getThisFFamilleMapping = React.useCallback((): FormState => {
      return {
        id: fFamilleMapping?.id,
        name: fFamilleMapping?.name,
        fFamille: {
          value:
            fFamilles?.find(
              (fFamille) =>
                fFamille.faCodefamille === fFamilleMapping?.fFamilleId
            ) ?? null,
          error: "",
        },
        mapped: { value: fFamilleMapping?.mapped, error: "" },
      };
    }, [
      fFamilleMapping?.fFamilleId,
      fFamilleMapping?.mapped,
      fFamilleMapping?.id,
      fFamilleMapping?.name,
      fFamilles,
    ]);
    const [values, setValues] = React.useState<FormState>(
      getThisFFamilleMapping()
    );
    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );
    const { t } = useTranslation();

    const handleCheckbox = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            const result = {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
            if (prop === "mapped") {
              result.fFamille.value = null;
              result.fFamille.error = null;
            }
            return result;
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        if (values.fFamille.value === null && values.mapped.value === false) {
          setValues((v) => {
            v.fFamille.error = "error.fFamille";
            return { ...v };
          });
          return undefined;
        }
        return {
          fFamilleMappingId: fFamilleMapping?.id,
          fFamille: values.fFamille.value?.faCodefamille,
          mapped: values.mapped.value,
        };
      },
    }));

    React.useEffect(() => {
      setFFamilleMapping(getFFamilleMapping());
    }, [fFamilleMappings, index]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValues(getThisFFamilleMapping());
    }, [fFamilleMapping]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <StyledTableRow>
        <StyledTableCell>{values.name}</StyledTableCell>
        <StyledTableCell sx={{ ...(!isSmall && { minWidth: 500 }) }}>
          <Autocomplete
            fullWidth
            disabled={values.mapped.value}
            options={fFamilles ?? []}
            getOptionLabel={(fFamille: FFamilleInterface) => {
              return (
                "[" + fFamille.faCodefamille + "] " + fFamille.faIntitule ?? ""
              );
            }}
            isOptionEqualToValue={(
              option: FFamilleInterface,
              value: FFamilleInterface
            ) => {
              return option.faCodefamille === value.faCodefamille;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.faCodefamille}>
                  {"[" + option.faCodefamille + "] " + option.faIntitule}
                </li>
              );
            }}
            onChange={handleChangeAutocomplete("fFamille")}
            value={values.fFamille.value}
            defaultValue={values.fFamille.value}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                error={!!values.fFamille.error}
                helperText={t(values.fFamille.error ?? "")}
                label={t("word.family")}
                placeholder={t("word.family")}
              />
            )}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Checkbox
            checked={values.mapped.value}
            onChange={handleCheckbox("mapped")}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  })
);

export default FFamilleMappingFormComponent;
