import React, { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { CAN_SEARCH_SAGE_DO_TYPE } from "../../../../utils/DoceneteteUtils";
import {
  paramsHasChanged,
  searchParamToObject,
} from "../../../../helpers/SearchParamHelper";
import { CountDoTypeInterface } from "../ConsoleComptoirComponent";
import { PORT_LIVRAISON } from "../../../../utils/FDoceneteteUtils";
import ConsoleComptoirFastFilterButtonComponent from "./ConsoleComptoirFastFilterButtonComponent";
import ConsoleComptoirFilterDoTypeComponent from "./ConsoleComptoirFilterDoTypeComponent";
import ConsoleComptoirFilterCustomComponent from "./ConsoleComptoirFilterCustomComponent";
import ConsoleComptoirFilterSearchComponent from "./ConsoleComptoirFilterSearchComponent";
import ConsoleComptoirFilterDateComponent from "./ConsoleComptoirFilterDateComponent";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";

const clone = require("clone");

interface State {
  countDoType?: CountDoTypeInterface[];
}

const ConsoleComptoirFilterComponent: React.FC<State> = React.memo(
  ({ countDoType }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const refDoType: any = useRef();
    const refCustom: any = useRef();
    const refSearch: any = useRef();
    const refDates: any = useRef();

    const resetFilter = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      const itemsPerPage = searchParamsObject.itemsPerPage;
      const orders: any = {};
      for (const key in searchParamsObject) {
        if (!key.startsWith("order")) {
          continue;
        }
        orders[key] = searchParamsObject[key];
      }
      setSearchParams({
        itemsPerPage: itemsPerPage,
        ...orders,
      });
    }, [searchParams, setSearchParams]);

    const updateSearchParams = React.useCallback(() => {
      const doTypes = CAN_SEARCH_SAGE_DO_TYPE.filter(
        (doType, indexDoType) =>
          refDoType.current.getCheckedDoType()[indexDoType]
      ).map((doType) => doType.toString());
      let searchParamsObject = searchParamToObject(searchParams);
      const current = clone(searchParamsObject);
      delete searchParamsObject["doType[]"];
      delete searchParamsObject.doExpedit;
      delete searchParamsObject.paid;
      delete searchParamsObject.chorus;
      delete searchParamsObject.notPointage;
      delete searchParamsObject.isUserBonDeCommande;
      delete searchParamsObject.validation;
      delete searchParamsObject.hasMedias;
      delete searchParamsObject.search;
      delete searchParamsObject["doDate[after]"];
      delete searchParamsObject["doDate[before]"];
      if (doTypes.length > 0) {
        searchParamsObject["doType[]"] = doTypes;
      }
      if (refCustom.current.getCheckedPaid()) {
        searchParamsObject.paid = "1";
      }
      if (refCustom.current.getCheckedDelivery()) {
        searchParamsObject.doExpedit = PORT_LIVRAISON.toString();
      }
      if (refCustom.current.getCheckedChorus()) {
        searchParamsObject.chorus = "1";
      }
      if (refCustom.current.getCheckedChorusPointage()) {
        searchParamsObject.notPointage = "1";
      }
      if (refCustom.current.getCheckedIsUserBonDeCommande()) {
        searchParamsObject.isUserBonDeCommande = "1";
      }
      if (refCustom.current.getCheckedValidation()) {
        searchParamsObject.validation = "1";
      }
      if (refCustom.current.getCheckedHasMedias()) {
        searchParamsObject.hasMedias = "1";
      }
      if (refSearch.current.getValue()) {
        searchParamsObject.search = refSearch.current.getValue();
      }
      if (refDates.current.getValue()) {
        searchParamsObject = {
          ...searchParamsObject,
          ...refDates.current.getValue(),
        };
      }
      if (paramsHasChanged(current, searchParamsObject)) {
        searchParamsObject.page = 1;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    }, [searchParams, setSearchParams]);

    return (
      <>
        <ConsoleComptoirFilterDoTypeComponent
          updateSearchParams={updateSearchParams}
          countDoType={countDoType}
          ref={refDoType}
        />
        <ConsoleComptoirFilterCustomComponent
          updateSearchParams={updateSearchParams}
          ref={refCustom}
        />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <ConsoleComptoirFilterSearchComponent
              updateSearchParams={updateSearchParams}
              ref={refSearch}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ConsoleComptoirFilterDateComponent
              updateSearchParams={updateSearchParams}
              ref={refDates}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 1 }} />
        <ConsoleComptoirFastFilterButtonComponent resetFilter={resetFilter} />
      </>
    );
  }
);

export default ConsoleComptoirFilterComponent;
