export const TYPE_CHECKBOX = "A";
export const TYPE_RADIO = "B";
export const TYPE_NUMERIC = "C";

export const CONDITION_AND = "A";
export const CONDITION_OR = "B";

export const TYPES_FILTER = [TYPE_CHECKBOX, TYPE_RADIO, TYPE_NUMERIC];

export const CONDITIONS_FILTER = [CONDITION_AND, CONDITION_OR];
