import React, { SyntheticEvent, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import {
  FilterFilterInterface,
  FilterValueAssoFilterCategoryInterface,
} from "../../../../../../interfaces/FilterInterface";
import Box from "@mui/material/Box";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import {
  objectToQuery,
  requestApi,
} from "../../../../../../helpers/RequestApi";
import { GET } from "../../../../../../utils/MethodUtils";
import { FILTER_VALUE_URL } from "../../../../../../utils/UrlsUtils";
import getErrorApi from "../../../../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { useAppSelector } from "../../../../../../app/hooks";
import { RootState } from "../../../../../../app/store";
import { LoadingButton } from "@mui/lab";

interface State {
  filter: FilterFilterInterface | null | undefined;
  categoryIds: number[];
}

export interface FilterConditionalFilterInterface {
  id: number;
  name: string;
  filterCategories: FilterValueAssoFilterCategoryInterface[];
}

export interface FilterValueConditionalFilterInterface {
  id: number | null;
  name: string;
  filter: FilterConditionalFilterInterface;
}

const ConditionalFilterFormComponent = React.memo(
  React.forwardRef(({ filter, categoryIds }: State, ref) => {
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [loading, setLoading] = React.useState(false);
    const [searchFArticleValue, setSearchFArticleValue] =
      React.useState<string>("");
    const [isSearching, setIsSearching] = React.useState<boolean>(false);
    const [isTyping, setIsTyping] = React.useState<boolean>(false);
    const [optionsFArticleValues, setOptionsFArticleValues] = React.useState<
      FilterValueConditionalFilterInterface[]
    >([]);
    const getOldFilterValues = React.useCallback((): number[] => {
      // @ts-ignore
      return (
        filter?.conditionalFilters[0]?.filterValues
          .map((filterValue) => filterValue.id)
          .filter((x) => x) ?? []
      );
    }, [filter?.conditionalFilters]);
    const [oldFilterValues, setOldFilterValues] = React.useState<number[]>(
      getOldFilterValues()
    );
    const getValues =
      React.useCallback((): FilterValueConditionalFilterInterface[] => {
        if (!filter) {
          return [];
        }
        if (filter.conditionalFilters.length > 0) {
          return filter.conditionalFilters[0].filterValues
            .map((x) => {
              let result: any = {
                id: x.id,
                name: x.name,
              };
              if (x.filter) {
                result.filter = {
                  id: x.filter.id,
                  name: x.filter.name,
                  filterCategories: x.filter.filterCategories,
                };
              }
              return result;
            })
            .sort(
              (
                a: FilterValueConditionalFilterInterface,
                b: FilterValueConditionalFilterInterface
              ) => a.filter.id - b.filter.id
            );
        }
        return [];
      }, [filter]);
    const [values, setValues] = React.useState<
      FilterValueConditionalFilterInterface[]
    >(getValues());

    const handleSearchFArticleValue = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: string) => {
        setSearchFArticleValue(value);
        setOptionsFArticleValues([]);
        setIsTyping(true);
      },
      []
    );

    const addAll = React.useCallback(async () => {
      setLoading(true);
      let page = 1;
      let result: FilterValueConditionalFilterInterface[] = [];
      let response;
      if (categoryIds.length > 0) {
        do {
          const param: any = {
            page: page,
            "categoryIds[]": categoryIds,
          };
          if (filter) {
            param.noFilter = filter.id;
          }
          response = await requestApi({
            method: GET,
            path: FILTER_VALUE_URL + objectToQuery(param),
            allowError: false,
            paginate: false,
            token: token,
            timeout: 30_000,
          });
          result = [...result, ...response.content];
          page++;
        } while (response.content.length > 0);
      }
      result.sort(
        (
          a: FilterValueConditionalFilterInterface,
          b: FilterValueConditionalFilterInterface
        ) => a.filter.id - b.filter.id
      );
      setValues(
        result.map((x) => {
          let result: any = {
            id: x.id,
            name: x.name,
          };
          if (x.filter) {
            result.filter = {
              id: x.filter.id,
              name: x.filter.name,
              filterCategories: x.filter.filterCategories,
            };
          }
          return result;
        })
      );
      setLoading(false);
    }, [categoryIds, filter, token]);

    const searchFArticleValues = React.useCallback(async () => {
      const search = searchFArticleValue.trim();
      setIsTyping(false);
      setIsSearching(true);
      const param: any = {
        identifier: search,
      };
      if (categoryIds.length > 0) {
        param["categoryIds[]"] = categoryIds;
      }
      if (filter) {
        param.noFilter = filter.id;
      }
      const response = await requestApi({
        method: GET,
        path: FILTER_VALUE_URL + objectToQuery(param),
        allowError: false,
        paginate: false,
        token: token,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        response.content.sort(
          (
            a: FilterValueConditionalFilterInterface,
            b: FilterValueConditionalFilterInterface
          ) => a.filter.id - b.filter.id
        );
        setOptionsFArticleValues(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setIsSearching(false);
    }, [categoryIds, filter, searchFArticleValue, t, token]);

    const handleChangeAutocomplete = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: any) => {
        setValues(value);
      },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return values.map((x) => FILTER_VALUE_URL + "/" + x.id);
      },
    }));

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchFArticleValues();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [searchFArticleValue]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValues(getValues());
      setOldFilterValues(getOldFilterValues());
    }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Box sx={{ marginBottom: 2 }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={addAll}
            disabled={categoryIds.length === 0}
          >
            {t("word.addAll")}
          </LoadingButton>
        </Box>
        <Autocomplete
          multiple
          loadingText={t("word.loading") + "..."}
          inputValue={searchFArticleValue}
          onInputChange={handleSearchFArticleValue}
          groupBy={(option: FilterValueConditionalFilterInterface) =>
            "(" +
            option.filter.filterCategories
              .map(
                (filterCategory) =>
                  "[" +
                  filterCategory.category.id.toString() +
                  "] " +
                  filterCategory.category.name
              )
              .join(" | ") +
            ") | " +
            "[" +
            option.filter.id.toString() +
            "] " +
            option.filter.name
          }
          noOptionsText={
            isSearching ? (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Box>
            ) : searchFArticleValue !== "" && !isTyping ? (
              t("sentence.no_option")
            ) : (
              t("word.searchFArticleValue.label")
            )
          }
          isOptionEqualToValue={(
            option: FilterValueConditionalFilterInterface,
            value: FilterValueConditionalFilterInterface
          ) => option.id === value.id}
          options={optionsFArticleValues}
          onChange={handleChangeAutocomplete}
          value={values}
          getOptionLabel={(option: FilterValueConditionalFilterInterface) => {
            let prefix = "";
            if (!oldFilterValues.includes(option.id ?? 0)) {
              prefix = "🆕 ";
            }
            return (
              prefix +
              "(" +
              option.filter.filterCategories
                .map(
                  (filterCategory) =>
                    "[" +
                    filterCategory.category.id.toString() +
                    "] " +
                    filterCategory.category.name
                )
                .join(" | ") +
              ") | " +
              "[" +
              option.filter.id +
              "] " +
              option.filter.name +
              " | [" +
              option.id +
              "] " +
              option.name
            );
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} style={{ display: "block" }}>
                {"[" + option.id + "] " + option.name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              label={t("field.fArticleValues")}
              placeholder={t("field.fArticleValues")}
            />
          )}
        />
      </>
    );
  })
);

export default ConditionalFilterFormComponent;
