import React, { forwardRef, useImperativeHandle } from "react";
import {
  Autocomplete,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { CONDITION_AND } from "../../../utils/FilterUtils";

interface State {
  apply: Function;
  setNbSelected: Function;
  index: number;
  filterInited: Function;
}

const TagFilterComponent = React.memo(
  forwardRef(({ apply, setNbSelected, index, filterInited }: State, ref) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const tags = useAppSelector((state: RootState) => state.globalState.tags);
    const [searchParams] = useSearchParams();
    const getValue = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      if (searchParamsObject.hasOwnProperty("tags[]") && tags) {
        const tagId = JSON.parse(searchParamsObject["tags[]"]).values[0];
        return tags.find((t) => t.id === tagId)?.text ?? "";
      }
      return "";
    }, [searchParams, tags]);
    const [value, setValue] = React.useState<string | null>(getValue());

    React.useEffect(() => {
      setNbSelected(!value ? 0 : 1);
      if (!isSmall) {
        apply();
      }
      filterInited(index);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValue(getValue());
    }, [searchParams, tags]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        if (value !== "" && value !== null) {
          return {
            "tags[]": JSON.stringify({
              values: tags?.filter((t) => t.text === value)?.map((t) => t.id),
              condition: CONDITION_AND,
            }),
          };
        }
        return {};
      },
    }));

    return (
      <>
        {tags && (
          <Autocomplete
            fullWidth
            value={value}
            onChange={(event: any, newValue: string | null) => {
              setValue(newValue);
            }}
            options={tags.map((tag) => tag.text)}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </>
    );
  })
);

export default TagFilterComponent;
