import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { ACCOUNTING_SITUATION_URL } from "../../../utils/UrlsUtils";
import { submitFormPayment } from "../../../helpers/PaymentHelper";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { priceFormat } from "../../../utils/FormatUtils";
import { FEcritureRecsInterface } from "../../../interfaces/FEcritureRecInterface";

interface State {
  amount: number;
  checked: string[] | undefined;
  fEcriturecs: FEcritureRecsInterface[] | undefined;
}

const RegularizeSituationComponent: React.FC<State> = React.memo(
  ({ amount, checked, fEcriturecs }) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const isAllChecked = React.useCallback(() => {
      return (
        checked &&
        checked.length ===
          fEcriturecs?.filter((fEcriturec) => fEcriturec.checkable).length
      );
    }, [checked, fEcriturecs]);

    const regularize = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: POST,
        path: ACCOUNTING_SITUATION_URL,
        allowError: true,
        token: token,
        body: {
          userIdentifier: user?.userIdentifier,
          fEcriturecIds: isAllChecked()
            ? null
            : checked?.map((x) => x.replace("-bis", "")),
          expectedAmount: amount,
        },
      });
      if (response.statusCode === 201) {
        const success = submitFormPayment(response.content.form);
        if (!success) {
          setLoading(false);
        }
        return;
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [amount, checked, isAllChecked, t, token, user?.userIdentifier]);

    return (
      <LoadingButton variant="contained" loading={loading} onClick={regularize}>
        {t("word.regularizeSituation") +
          " " +
          priceFormat(amount, i18n.language, "EUR")}
      </LoadingButton>
    );
  }
);

export default RegularizeSituationComponent;
