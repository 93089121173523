import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { CategoryInterface } from "../../../../interfaces/CategoryInterface";
import Typography from "@mui/material/Typography";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  getCardsFilterCategory,
  Item,
  updateCardResult,
} from "../../../../helpers/CardFormat";
import { CartDndCategoryComponent } from "../CartDndCategoryComponent";
import { Checkbox, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import { TYPE_NUMERIC } from "../../../../utils/FilterUtils";

interface State {
  category: CategoryInterface | null | undefined;
}

const FormFilterCategoryComponent = React.memo(
  forwardRef(({ category }: State, ref) => {
    const { t } = useTranslation();
    const [cards, setCards] = React.useState<Item[]>(
      getCardsFilterCategory(category?.filterCategories)
    );

    const onDragEnd = React.useCallback((result: DropResult) => {
      if (result.destination === null || result.destination === undefined) {
        return;
      }
      setCards((thisCards) => {
        const updateCartMenuResult = updateCardResult(thisCards, result);
        if (updateCartMenuResult) {
          return updateCartMenuResult.cards;
        }
        return thisCards;
      });
    }, []);

    const handleChange = React.useCallback(
      (id: number, prop: string) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setCards((x) => {
            const thisCard = x.find((y) => y.id === id);
            if (thisCard) {
              // @ts-ignore
              thisCard[prop] = event.target.checked;
            }
            return [...x];
          });
        },
      []
    );

    const renderCards = React.useCallback(
      (thisCards: Item[], depth: number, path: string = ".") => (
        <Droppable droppableId={path} type={path}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {thisCards.map((card, index) => (
                <Draggable
                  key={card.id}
                  draggableId={card.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <CartDndCategoryComponent
                        allCards={cards}
                        setCards={setCards}
                        identifier={card.id.toString()}
                        depth={depth}
                        path={path + index + "."}
                        forceHasChildren={depth === 1}
                        text={
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ flex: 1 }}>
                              <Typography component="span">
                                {card.text}
                              </Typography>
                            </Box>
                            <Box sx={{ flex: 1, textAlign: "center" }}>
                              <Checkbox
                                checked={card.opened}
                                onChange={handleChange(card.id, "opened")}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </Box>
                            <Box sx={{ flex: 1, textAlign: "center" }}>
                              {card.custom && (
                                <Checkbox
                                  checked={card.active}
                                  onChange={handleChange(card.id, "active")}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              )}
                            </Box>
                            <Box sx={{ flex: 1, textAlign: "center" }}>
                              {!card.custom && (
                                <Checkbox
                                  checked={card.reference}
                                  onChange={handleChange(card.id, "reference")}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              )}
                            </Box>
                            <Box sx={{ flex: 1, textAlign: "center" }}>
                              {card.type === TYPE_NUMERIC && (
                                <Checkbox
                                  checked={card.showRestrictedValue}
                                  onChange={handleChange(
                                    card.id,
                                    "showRestrictedValue"
                                  )}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              )}
                            </Box>
                          </Box>
                        }
                        children={card.children}
                        provided={provided}
                        renderCards={renderCards}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ),
      [cards, handleChange]
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return cards.map((card, indexCard) => {
          return {
            oldId: card.id,
            sort: indexCard + 1,
            opened: card.opened,
            reference: card.reference,
            active: card.active,
            showRestrictedValue: card.showRestrictedValue,
          };
        });
      },
    }));

    React.useEffect(() => {
      setCards(getCardsFilterCategory(category?.filterCategories));
    }, [category]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {cards.length > 0 && (
          <Box sx={{ paddingLeft: 4, display: "flex" }}>
            <Box sx={{ paddingX: 1, flex: 1 }} />
            <Box sx={{ textAlign: "center", paddingX: 1, flex: 1 }}>
              <Typography component="span">{t("word.open")}</Typography>
            </Box>
            <Box sx={{ textAlign: "center", paddingX: 1, flex: 1 }}>
              <Typography component="span">{t("word.active")}</Typography>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                paddingX: 1,
                display: "flex",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <Typography component="span">{t("word.reference")}</Typography>
              <Tooltip title={t("sentence.reference.tooltip")}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                paddingX: 1,
                display: "flex",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <Typography component="span">
                {t("word.showRestrictedValue.tooltip")}
              </Typography>
              <Tooltip title={t("sentence.showRestrictedValue.tooltip")}>
                <InfoIcon />
              </Tooltip>
            </Box>
          </Box>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          {renderCards(cards, 0)}
        </DragDropContext>
      </>
    );
  })
);

export default FormFilterCategoryComponent;
