import React from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { Card, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { getUrlFArticleImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_SMALL } from "../../../utils/FileUtils";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

interface State {
  fArticles: FArticleInterface[] | undefined;
  choose: Function;
}

const FArticleNotSureArrivageComponent: React.FC<State> = React.memo(
  ({ fArticles, choose }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      <>
        {fArticles && fArticles.length > 0 && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                {t("word.chooseProduct")}
              </Typography>
            </Grid>
            {fArticles.map((fArticle, indexFArticle) => (
              <Grid item xs={12} md={2} key={indexFArticle}>
                <Card
                  variant="outlined"
                  sx={{ cursor: "pointer" }}
                  onClick={() => choose(fArticle)}
                >
                  {fArticle?.fArticleProp?.images &&
                    fArticle?.fArticleProp?.images.length > 0 && (
                      <img
                        src={
                          (process.env.REACT_APP_API_URL ?? "") +
                          getUrlFArticleImage(
                            fArticle?.arRef,
                            fArticle.fArticleProp?.images[0],
                            IMAGE_SIZE_SMALL
                          )
                        }
                        style={{
                          maxWidth: "100%",
                        }}
                        alt={"[" + fArticle?.arRef + "] " + fArticle?.arDesign}
                      />
                    )}
                  <Box>
                    <CopyClipboardComponent
                      className="RobotoMono"
                      component="span"
                      text={fArticle.arRef}
                      sx={{
                        wordBreak: "initial",
                        color: theme.palette.success.main,
                      }}
                    />
                  </Box>
                  <Box>
                    <CopyClipboardComponent
                      className="RobotoMono"
                      component="span"
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                      text={fArticle.constructeurRef}
                    />
                  </Box>
                  <Box>
                    <CopyClipboardComponent
                      className="RobotoMono"
                      component="span"
                      text={fArticle.arCodebarre}
                    />
                  </Box>
                  <Typography>{fArticle.arDesign}</Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </>
    );
  }
);

export default FArticleNotSureArrivageComponent;
