import React from "react";
import { useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";
import { getDiff } from "json-difference";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

interface State {
  resetFilter: any;
}

interface State2 {
  trans: string;
  params: any;
}

const ConsoleComptoirFastFilterButtonComponentSingle: React.FC<State2> =
  React.memo(({ trans, params }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const getIsSame = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      delete searchParamsObject.page;
      delete searchParamsObject.itemsPerPage;
      for (const key in searchParamsObject) {
        if (!key.startsWith("order")) {
          continue;
        }
        delete searchParamsObject[key];
      }
      const diff = getDiff(params, searchParamsObject);
      return (
        diff.added.length === 0 &&
        diff.removed.length === 0 &&
        diff.edited.length === 0
      );
    }, [params, searchParams]);
    const [isSame, setIsSame] = React.useState<boolean>(getIsSame());

    const onClick = React.useCallback(() => {
      if (isSame) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      const itemsPerPage = searchParamsObject.itemsPerPage;
      const orders: any = {};
      for (const key in searchParamsObject) {
        if (!key.startsWith("order")) {
          continue;
        }
        orders[key] = searchParamsObject[key];
      }
      setSearchParams({
        ...params,
        itemsPerPage: itemsPerPage,
        ...orders,
      });
    }, [isSame, params, searchParams, setSearchParams]);

    React.useEffect(() => {
      setIsSame(getIsSame());
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Button variant={isSame ? "contained" : "outlined"} onClick={onClick}>
          {trans}
        </Button>
      </>
    );
  });

const ConsoleComptoirFastFilterButtonComponent: React.FC<State> = React.memo(
  ({ resetFilter }) => {
    const { t } = useTranslation();

    return (
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <ConsoleComptoirFastFilterButtonComponentSingle
          trans={t("word.fastSelection.sendPoste")}
          params={{
            "doType[]": ["2"],
            // paid: "1",
            doExpedit: "3",
          }}
        />
        <ConsoleComptoirFastFilterButtonComponentSingle
          trans={t("word.fastSelection.bonCommandeToValidate")}
          params={{
            "doType[]": ["1", "2"],
            isUserBonDeCommande: "1",
            validation: "1",
            hasMedias: "1",
          }}
        />
        <ConsoleComptoirFastFilterButtonComponentSingle
          trans={t("word.fastSelection.invoiceToPoint")}
          params={{
            "doType[]": ["7"],
            notPointage: "1",
          }}
        />
        <Button variant={"outlined"} onClick={resetFilter}>
          <ClearIcon />
        </Button>
      </Box>
    );
  }
);

export default ConsoleComptoirFastFilterButtonComponent;
