import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Typography from "@mui/material/Typography";
import {
  getAcompteFArticle,
  getAcompteOrder,
  getOrderWithCart,
  getRequireAcompteOrder,
} from "../../../helpers/CartHelper";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getUrlFArticleImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_LOGO, IMAGE_SIZE_SMALL } from "../../../utils/FileUtils";
import { Link } from "react-router-dom";
import { ABOUT_PAGE, PRODUCT_PAGE } from "../../../utils/RouteUtils";
import HideImageIcon from "@mui/icons-material/HideImage";
import { priceFormat } from "../../../utils/FormatUtils";
import FArticleQuantityComponent from "../fArticle/FArticleQuantityComponent";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import {
  BadgeProps,
  Button,
  Checkbox,
  FormGroup,
  Grid,
  TableFooter,
  Tooltip,
} from "@mui/material";
import CartFArticleStatusComponent from "../cart/CartFArticleStatusComponent";
import { getQuantityDispo } from "../../../helpers/FArticleHelper";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import {
  OrderFArticleInterface,
  OrderInterface,
} from "../../../interfaces/OrderInterface";
import DialogOrderComponent from "./DialogOrderComponent";
import ValidateMailComponent from "../../../screens/account/ValidateMailComponent";
import {
  isUserBonDeCommande,
  userIsBlocked,
} from "../../../helpers/UserHelper";
import { LoadingButton } from "@mui/lab";
import { OpenDialogOrderInterface } from "../../../interfaces/FDocenteteInterface";
import { ADMINISTRATION } from "../../../utils/RegisterUtils";
import LoginComponent from "../user/LoginComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { STORAGE_ORDER_DISPLAY } from "../../../utils/StorageUtils";
import SliderFArticleComponent from "../../slider/SliderFArticleComponent";
import ContentComponent from "../../content/ContentComponent";
import AddProductByRefComponent from "./AddProductByRefComponent";
import { DEFAULT_PERCENT_ACOMPTE } from "../../../utils/FArticleUtils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: -6,
  },
}));

const OrderComponent: React.FC = React.memo(() => {
  const [openDialog, setOpenDialog] = React.useState<OpenDialogOrderInterface>({
    open: false,
    devis: false,
  });
  const theme = useTheme();
  const cart = useAppSelector((state: RootState) => state.globalState.cart);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const userProp = useAppSelector(
    (state: RootState) => state.globalState.userProp
  );
  const cartUpsellings = useAppSelector(
    (state: RootState) => state.globalState.cartUpsellings
  );
  const mailConfirmation = useAppSelector(
    (state: RootState) => state.globalState.mailConfirmation
  );
  const [agreeCgv, setAgreeCgv] = React.useState(false);
  const [userBonDeCommande, setUserBonDeCommande] = React.useState(
    isUserBonDeCommande(user)
  );
  const [order, setOrder] = React.useState<OrderInterface | undefined>(
    getOrderWithCart(cart, undefined)
  );
  const handleClickOpen = React.useCallback(
    (devis: boolean) => (e: any) => {
      setOpenDialog({
        open: true,
        devis: devis,
      });
    },
    []
  );
  const getTotalHt = React.useCallback(() => {
    return (
      order?.orderFArticles.reduce(
        (total: number, orderFArticle: OrderFArticleInterface) => {
          return (
            total +
            orderFArticle.fArticle.priceDto.priceHt * orderFArticle.quantity
          );
        },
        0
      ) ?? 0
    );
  }, [order?.orderFArticles]);
  const getHasMinPrice = React.useCallback(() => {
    return getTotalHt() >= 1000;
  }, [getTotalHt]);
  const [hasMinPrice, setHasMinPrice] = React.useState<boolean>(
    getHasMinPrice()
  );
  const [requireAcompte, setRequireAcompte] = React.useState(
    getRequireAcompteOrder(order, user, userProp, false)
  );
  const { t, i18n } = useTranslation();

  const handleChangeCgv = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAgreeCgv(event.target.checked);
    },
    []
  );

  const setQuantity = React.useCallback((quantity: number, index: number) => {
    // @ts-ignore
    setOrder((x) => {
      if (x !== undefined) {
        x.orderFArticles[index].quantity = quantity;
      }
      return { ...x };
    });
  }, []);

  const removeFArticle = React.useCallback((index: number) => {
    // @ts-ignore
    setOrder((x) => {
      if (x !== undefined) {
        x.orderFArticles.splice(index, 1);
      }
      return { ...x };
    });
  }, []);

  const getTotalTtc = React.useCallback(() => {
    return (
      order?.orderFArticles.reduce(
        (total: number, orderFArticle: OrderFArticleInterface) => {
          return (
            total +
            orderFArticle.fArticle.priceDto.priceTtc * orderFArticle.quantity
          );
        },
        0
      ) ?? 0
    );
  }, [order?.orderFArticles]);

  const onCartUpdate = React.useCallback(() => {
    setOrder(getOrderWithCart(cart, undefined));
  }, [cart]);

  const onOrderUpdate = React.useCallback(() => {
    setRequireAcompte(getRequireAcompteOrder(order, user, userProp, false));
    setHasMinPrice(getHasMinPrice());
  }, [getHasMinPrice, order, user, userProp]);

  const isConnected = React.useCallback(() => {
    return user !== null && user !== undefined;
  }, [user]);

  const isAdministrationNotYetTransform = React.useCallback(() => {
    return isConnected() && user?.type === ADMINISTRATION;
  }, [isConnected, user?.type]);

  const canOrder = React.useCallback(() => {
    return (
      cart &&
      hasMinPrice &&
      isConnected() &&
      !isAdministrationNotYetTransform() &&
      !userIsBlocked(user) &&
      agreeCgv &&
      cart.cartFArticles.length > 0 &&
      (!mailConfirmation || !mailConfirmation.required)
    );
  }, [
    agreeCgv,
    cart,
    hasMinPrice,
    isAdministrationNotYetTransform,
    isConnected,
    mailConfirmation,
    user,
  ]);

  React.useEffect(() => {
    onCartUpdate();
  }, [cart]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onOrderUpdate();
  }, [order]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setUserBonDeCommande(isUserBonDeCommande(user));
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoginComponent redirect={null}>
      <ContentComponent contentName="order" saveLocalStorage={true} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>{t("field.arDesign")}</StyledTableCell>
              <StyledTableCell>{t("word.wHt")}</StyledTableCell>
              <StyledTableCell>{t("word.wTtc")}</StyledTableCell>
              <StyledTableCell>{t("word.quantity")}</StyledTableCell>
              <StyledTableCell>{t("word.totalHt")}</StyledTableCell>
              <StyledTableCell>{t("word.totalTtc")}</StyledTableCell>
              {requireAcompte && (
                <StyledTableCell>{t("word.acompte")}</StyledTableCell>
              )}
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {order?.orderFArticles.map((orderFArticle, indexOrderFArticle) => {
              const quantityDispo = getQuantityDispo(orderFArticle.fArticle);
              const acompte = getAcompteFArticle(
                orderFArticle.fArticle,
                orderFArticle.quantity,
                user,
                userProp
              );
              return (
                <StyledTableRow key={orderFArticle.fArticle.arRef}>
                  <StyledTableCell sx={{ textAlign: "center" }}>
                    {orderFArticle.fArticle.fArticleProp?.images !== null &&
                    orderFArticle.fArticle.fArticleProp?.images.length > 0 ? (
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          textAlign: "center",
                        }}
                        to={
                          PRODUCT_PAGE +
                          "/" +
                          orderFArticle.fArticle.fArticleProp?.slug
                        }
                        state={orderFArticle.fArticle}
                      >
                        <img
                          src={
                            (process.env.REACT_APP_API_URL ?? "") +
                            getUrlFArticleImage(
                              orderFArticle.fArticle.arRef,
                              orderFArticle.fArticle.fArticleProp?.images[0],
                              IMAGE_SIZE_SMALL
                            )
                          }
                          style={{
                            margin: "auto",
                            width: IMAGE_SIZE_SMALL / 2,
                          }}
                          alt={
                            "[" +
                            orderFArticle.fArticle.arRef +
                            "] " +
                            orderFArticle.fArticle.arDesign
                          }
                          loading="lazy"
                        />
                      </Link>
                    ) : (
                      <HideImageIcon
                        sx={{
                          width: IMAGE_SIZE_LOGO,
                          height: IMAGE_SIZE_LOGO,
                        }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        textAlign: "center",
                      }}
                      to={
                        PRODUCT_PAGE +
                        "/" +
                        orderFArticle.fArticle.fArticleProp?.slug
                      }
                      state={orderFArticle.fArticle}
                    >
                      {orderFArticle.fArticle.arDesign}
                    </Link>
                    <Box>
                      <CopyClipboardComponent
                        className="RobotoMono"
                        component="span"
                        sx={{
                          color: theme.palette.success.main,
                        }}
                        text={orderFArticle.fArticle.arRef}
                      />
                    </Box>
                    {orderFArticle.quantity > quantityDispo && (
                      <CartFArticleStatusComponent
                        quantity={orderFArticle.quantity}
                        quantityDispo={quantityDispo}
                        fArticle={orderFArticle.fArticle}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {priceFormat(
                      orderFArticle.fArticle.priceDto.priceHt,
                      i18n.language,
                      "EUR"
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {priceFormat(
                      orderFArticle.fArticle.priceDto.priceTtc,
                      i18n.language,
                      "EUR"
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <FArticleQuantityComponent
                      ref={orderFArticle.quantityRef}
                      fArticle={orderFArticle.fArticle}
                      setQuantity={setQuantity}
                      index={indexOrderFArticle}
                      defaultValue={orderFArticle.quantity}
                      maxWithCart={false}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {priceFormat(
                      orderFArticle.fArticle.priceDto.priceHt *
                        orderFArticle.quantity,
                      i18n.language,
                      "EUR"
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {priceFormat(
                      orderFArticle.fArticle.priceDto.priceTtc *
                        orderFArticle.quantity,
                      i18n.language,
                      "EUR"
                    )}
                  </StyledTableCell>
                  {requireAcompte && (
                    <StyledTableCell>
                      {acompte > 0 ? (
                        <StyledBadge
                          badgeContent={
                            <Tooltip
                              disableFocusListener
                              title={t("sentence.acompte").replace(
                                "%percent%",
                                (
                                  userProp?.percentAcompte ??
                                  DEFAULT_PERCENT_ACOMPTE * 10
                                ).toString()
                              )}
                            >
                              <InfoIcon />
                            </Tooltip>
                          }
                        >
                          {priceFormat(acompte, i18n.language, "EUR")}
                        </StyledBadge>
                      ) : (
                        priceFormat(acompte, i18n.language, "EUR")
                      )}
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    <IconButton
                      onClick={() => removeFArticle(indexOrderFArticle)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell>
                <Typography component="p" variant="h6">
                  {priceFormat(getTotalHt(), i18n.language, "EUR")}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography component="p" variant="h6">
                  {priceFormat(getTotalTtc(), i18n.language, "EUR")}
                </Typography>
              </StyledTableCell>
              {requireAcompte && (
                <StyledTableCell>
                  <Typography component="p" variant="h6">
                    {priceFormat(
                      getAcompteOrder(order, user, userProp, false),
                      i18n.language,
                      "EUR"
                    )}
                  </Typography>
                </StyledTableCell>
              )}
              <StyledTableCell />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <AddProductByRefComponent />
        </Grid>
      </Grid>
      {cartUpsellings && cartUpsellings.length > 0 && (
        <>
          <Typography>{t("sentence.upsellingAfterOrder")}</Typography>
          <SliderFArticleComponent
            fArticles={cartUpsellings}
            openNewTab={true}
            displayStorage={STORAGE_ORDER_DISPLAY}
          />
        </>
      )}
      <Box sx={{ marginTop: 2, textAlign: "right" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeCgv}
                onChange={handleChangeCgv}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            sx={{ justifyContent: "flex-end" }}
            label={
              <Link to={ABOUT_PAGE + "?tab=cgv"} target="_blank">
                {t("sentence.agreeCvg")}
              </Link>
            }
          />
        </FormGroup>
        {userIsBlocked(user) && (
          <Typography color="red">
            <div
              dangerouslySetInnerHTML={{
                __html: t("sentence.accountBlocked", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </Typography>
        )}
        {!isConnected() && (
          <Typography color="red">{t("sentence.needConnect")}</Typography>
        )}
        {isAdministrationNotYetTransform() && (
          <Typography color="red">
            {t("sentence.administrationNotYetTransform")}
          </Typography>
        )}
        {!hasMinPrice && (
          <Typography color="red">{t("sentence.minPriceToOrder")}</Typography>
        )}
        {(!mailConfirmation || mailConfirmation.required) && (
          <ValidateMailComponent />
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{ flex: 1, ...(!userBonDeCommande && { textAlign: "left" }) }}
          >
            {userBonDeCommande && (
              <Typography sx={{ whiteSpace: "pre-wrap" }}>
                {t("sentence.transformDevisExplain")}
              </Typography>
            )}
            <LoadingButton
              sx={{ marginTop: 2 }}
              disabled={!canOrder()}
              variant="contained"
              size="large"
              onClick={handleClickOpen(true)}
            >
              {t("sentence.transformToDevis")}
            </LoadingButton>
          </Box>
          {!userBonDeCommande && (
            <Box sx={{ flex: 1 }}>
              <Button
                sx={{ marginTop: 2 }}
                disabled={!canOrder()}
                variant="contained"
                size="large"
                onClick={handleClickOpen(false)}
              >
                {t("word.order")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <DialogOrderComponent
        order={order}
        openDialog={openDialog.open}
        devis={openDialog.devis}
        setOpenDialog={setOpenDialog}
      />
    </LoginComponent>
  );
});

export default OrderComponent;
