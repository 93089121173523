import React, { useImperativeHandle } from "react";
import {
  CircularProgress,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FArticleSmallInterface } from "../../interfaces/FArticleInterface";
import Slider from "react-slick";
import { groupArray } from "../../helpers/GroupHelper";
import { FArticleSmallComponent } from "../common/fArticle/FArticleSmallComponent";
import {
  DEFAULT_NB_PRODUCT_SLIDER,
  getInline,
  getStyleInline,
} from "../../utils/FArticleUtils";
import { GroupFArticleInterface } from "../../screens/ProductsScreen";
import { STORAGE_DEFAULT_ARTICLE_DISPLAY } from "../../utils/StorageUtils";
import Box from "@mui/material/Box";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { useTranslation } from "react-i18next";

interface State {
  fArticles: FArticleSmallInterface[] | undefined;
  initNbProductsDisplay?: number;
  openNewTab?: boolean;
  initInline?: boolean;
  displayStorage?: string;
  hideDisplayStorage?: boolean;
  canLoadMore?: boolean;
  loadMore?: Function;
}

interface DisplayInterface {
  inline: boolean;
  nbColumn: number;
}

const SliderFArticleComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticles,
        initNbProductsDisplay,
        openNewTab,
        initInline,
        displayStorage,
        hideDisplayStorage,
        canLoadMore,
        loadMore,
      }: State,
      ref
    ) => {
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const { t } = useTranslation();
      const [isLoadingMore, setIsLoadingMore] = React.useState<boolean>(false);
      const thisGetInline = React.useCallback(() => {
        if (initInline !== undefined) {
          return initInline;
        }
        return getInline() !== "0";
      }, [initInline]);
      const [thisDisplayStorage] = React.useState<string>(
        displayStorage ?? STORAGE_DEFAULT_ARTICLE_DISPLAY
      );
      const thisGetNbGroup = React.useCallback(() => {
        const defaultNbProduct = DEFAULT_NB_PRODUCT_SLIDER;
        return isSmall
          ? 1
          : initNbProductsDisplay === undefined
          ? defaultNbProduct
          : initNbProductsDisplay > 0
          ? initNbProductsDisplay
          : defaultNbProduct;
      }, [isSmall, initNbProductsDisplay]);
      const [displayStorageData, setDisplayStorageData] =
        React.useState<DisplayInterface>(() => {
          const dataString: string | null =
            localStorage.getItem(thisDisplayStorage);
          let data: DisplayInterface;
          if (dataString !== null) {
            data = JSON.parse(dataString);
          } else {
            data = {
              inline: thisGetInline(),
              nbColumn: thisGetNbGroup(),
            };
          }
          return data;
        });
      const [styleInline] = React.useState<string>(getStyleInline());
      const [groupFArticles, setGroupFArticles] = React.useState<
        GroupFArticleInterface[] | undefined
      >(undefined);

      const switchColumn = React.useCallback(() => {
        setDisplayStorageData((x: DisplayInterface) => {
          x.inline = false;
          return { ...x };
        });
      }, []);

      const switchLine = React.useCallback(() => {
        setDisplayStorageData((x: DisplayInterface) => {
          x.inline = true;
          return { ...x };
        });
      }, []);

      const afterChange = React.useCallback(
        (currentSlide: number) => {
          if (
            loadMore &&
            canLoadMore &&
            groupFArticles &&
            currentSlide === groupFArticles.length
          ) {
            setIsLoadingMore(true);
          }
        },
        [canLoadMore, groupFArticles, loadMore]
      );

      useImperativeHandle(ref, () => ({
        setIsLoadingMore(v: boolean) {
          setIsLoadingMore(v);
        },
      }));

      React.useEffect(() => {
        setDisplayStorageData((x: DisplayInterface) => {
          x.nbColumn = thisGetNbGroup();
          return { ...x };
        });
      }, [isSmall, initNbProductsDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setGroupFArticles(groupArray(fArticles, displayStorageData.nbColumn));
      }, [fArticles, displayStorageData.nbColumn]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        localStorage.setItem(
          thisDisplayStorage,
          JSON.stringify(displayStorageData)
        );
      }, [displayStorageData]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (loadMore && isLoadingMore) {
          loadMore();
        }
      }, [isLoadingMore]); // eslint-disable-line react-hooks/exhaustive-deps

      return groupFArticles === undefined ||
        displayStorageData.nbColumn === undefined ? (
        <Skeleton
          variant="rectangular"
          height={150}
          sx={{ marginTop: 2, marginBottom: 2 }}
        />
      ) : (
        <>
          {groupFArticles.length > 0 && !hideDisplayStorage && (
            <Box sx={{ textAlign: "right" }}>
              <ToggleButtonGroup
                size="small"
                color="primary"
                value={displayStorageData.inline}
                exclusive
                onChange={(event, value) => {
                  if (value) {
                    switchLine();
                  } else {
                    switchColumn();
                  }
                }}
              >
                <ToggleButton value={true}>{t("word.line")}</ToggleButton>
                <ToggleButton value={false}>{t("word.column")}</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          )}
          <Slider
            dots={true}
            infinite={true}
            afterChange={afterChange}
            speed={100}
            className={displayStorageData.inline ? "flex-wrap-slider" : ""}
          >
            {groupFArticles.map(
              (groupFArticle: GroupFArticleInterface, indexGroupFArticle) => (
                <FArticleSmallComponent
                  key={indexGroupFArticle}
                  nbColumn={
                    displayStorageData.inline ? 1 : displayStorageData.nbColumn
                  }
                  inline={displayStorageData.inline}
                  fArticles={groupFArticle.fArticles}
                  defaultStyleInline={styleInline}
                  openNewTab={openNewTab}
                />
              )
            )}
            {canLoadMore && (
              <Box sx={{ display: "flex!important", alignItems: "center" }}>
                <CircularProgress size={50} />
              </Box>
            )}
          </Slider>
        </>
      );
    }
  )
);

export default SliderFArticleComponent;
