import React from "react";
import { useTranslation } from "react-i18next";
import { ContactInterface } from "../../../../interfaces/ContactInterface";
import { LoadingButton } from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { requestApi } from "../../../../helpers/RequestApi";
import { PATCH } from "../../../../utils/MethodUtils";
import { CONTACT_URL } from "../../../../utils/UrlsUtils";
import { set } from "../../../../app/globalSlice";
import getErrorApi from "../../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

interface State {
  contact?: ContactInterface | null;
  handleClose: any;
}

const FeedbackMarkUnreadComponent: React.FC<State> = React.memo(
  ({ contact, handleClose }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();

    const markUnread = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: PATCH,
        path: CONTACT_URL + "/" + contact?.id,
        allowError: false,
        token: token,
        body: {
          newMessageAdmin: 1,
        },
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.contact_unread")
        );
        dispatch(set({ refreshPage: true }));
        handleClose();
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [contact?.id, dispatch, handleClose, t, token]);

    return (
      <>
        <DialogContent>
          <DialogContentText>{t("sentence.markUnread")}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={handleClose}>{t("word.no")}</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={markUnread}
          >
            {t("word.yes")}
          </LoadingButton>
        </DialogActions>
      </>
    );
  }
);

export default FeedbackMarkUnreadComponent;
