import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import CartFArticleComponent from "./CartFArticleComponent";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextareaAutosize,
  useTheme,
} from "@mui/material";
import { priceFormat } from "../../../utils/FormatUtils";
import { getCartAmount, getOrderWithCart } from "../../../helpers/CartHelper";
import { Link } from "react-router-dom";
import { ORDER_PAGE } from "../../../utils/RouteUtils";
import { set, setCart } from "../../../app/globalSlice";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH, POST } from "../../../utils/MethodUtils";
import { CART_PRODUCTS_URL, CART_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { LoadingButton } from "@mui/lab";
import DialogOrderComponent from "../order/DialogOrderComponent";
import { OpenDialogOrderInterface } from "../../../interfaces/FDocenteteInterface";
import { OrderInterface } from "../../../interfaces/OrderInterface";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

interface ImportProductResultInterface {
  word: string;
  found: boolean;
}

const CartComponent: React.FC = React.memo(() => {
  const cart = useAppSelector((state: RootState) => state.globalState.cart);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const openCart = useAppSelector(
    (state: RootState) => state.globalState.openCart
  );
  const isAdmin = useAppSelector(
    (state: RootState) => state.globalState.isAdmin
  );
  const editingFDocentete = useAppSelector(
    (state: RootState) => state.globalState.editingFDocentete
  );
  const [openDialog, setOpenDialog] = React.useState<OpenDialogOrderInterface>({
    open: false,
    devis: false,
  });
  const [thisCart, setThisCart] = React.useState(cart);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [loadingProducts, setLoadingProducts] = React.useState(false);
  const [searchProducts, setSearchProducts] = React.useState("");
  const theme = useTheme();
  const [order, setOrder] = React.useState<OrderInterface | undefined>(
    getOrderWithCart(cart, undefined)
  );
  const [importProductResult, setImportProductResult] = React.useState<
    ImportProductResultInterface[]
  >([]);
  const onCartUpdate = React.useCallback(() => {
    setOrder(getOrderWithCart(cart, undefined));
  }, [cart]);

  const updateCartApi = React.useCallback(async () => {
    if (!thisCart) {
      return;
    }
    const response = await requestApi({
      method: PATCH,
      path: CART_URL + "/" + thisCart.id,
      allowError: true,
      token: token,
      body: {
        cartFArticles: thisCart.cartFArticles.map((cartFArticle) => {
          return {
            quantity: cartFArticle.quantity,
            oldId: cartFArticle.id,
          };
        }),
      },
    });
    if (response.statusCode === 200) {
      dispatch(setCart(response.content));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
  }, [dispatch, t, thisCart, token]);

  const updateCart = React.useCallback(async () => {
    if (!thisCart || JSON.stringify(cart) === JSON.stringify(thisCart)) {
      return;
    }
    dispatch(setCart(thisCart));
    await updateCartApi();
  }, [cart, dispatch, thisCart, updateCartApi]);

  const cancelUpdateFDocentete = React.useCallback(async () => {
    if (!cart?.id) {
      return;
    }
    setLoading(true);
    const response = await requestApi({
      method: PATCH,
      path: CART_URL + "/" + cart.id,
      allowError: true,
      token: token,
      body: {
        cartFArticles: [],
      },
    });
    if (response.statusCode === 200) {
      dispatch(setCart(response.content));
      setTimeout(() => {
        dispatch(
          set({
            openCart: false,
            editingFDocentete: null,
          })
        );
      }, 200);
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [cart?.id, dispatch, t, token]);

  const updateFDocentete = React.useCallback(async () => {
    setLoading(true);
    await updateCart();
    setOpenDialog({
      open: true,
      devis: true,
    });
    setLoading(false);
  }, [updateCart]);

  const closeCart = React.useCallback(() => {
    dispatch(set({ openCart: false }));
    updateCart();
  }, [dispatch, updateCart]);

  const loadProducts = React.useCallback(async () => {
    setLoadingProducts(true);
    const response = await requestApi({
      method: POST,
      path: CART_PRODUCTS_URL,
      allowError: true,
      token: token,
      timeout: 30_000,
      body: {
        stringProducts: searchProducts,
      },
    });
    if (response.statusCode === 201) {
      dispatch(setCart(response.content));
      setSearchProducts("");
      setImportProductResult(response.content.importProductResult);
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoadingProducts(false);
  }, [dispatch, searchProducts, t, token]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSearchProducts(event.target.value);
    },
    []
  );

  React.useEffect(() => {
    setThisCart(cart);
  }, [cart]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    return () => {
      updateCart();
    };
  }, [openCart]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onCartUpdate();
  }, [cart]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isAdmin && (
        <>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              sx={{
                backgroundColor: theme.palette.primary.main,
                justifyContent: "flex-start",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  marginTop: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("word.loadProducts")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ textAlign: "right", marginBottom: 0.5 }}>
                <LoadingButton
                  variant="contained"
                  loading={loadingProducts}
                  onClick={loadProducts}
                >
                  {t("word.load")}
                </LoadingButton>
              </Box>
              <TextareaAutosize
                minRows={4}
                value={searchProducts}
                style={{ width: "100%" }}
                onChange={handleChange}
              />
              <Box sx={{ textAlign: "right" }}>
                <LoadingButton
                  variant="contained"
                  loading={loadingProducts}
                  onClick={loadProducts}
                >
                  {t("word.load")}
                </LoadingButton>
              </Box>
            </AccordionDetails>
          </Accordion>
          {importProductResult.length > 0 && (
            <Card variant="outlined">
              <CardContent>
                {importProductResult.map((r, i) => (
                  <Typography key={i}>
                    <Typography
                      component="span"
                      sx={{
                        backgroundColor: r.found
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                      }}
                    >
                      {r.word}
                    </Typography>
                  </Typography>
                ))}
                <Box sx={{ textAlign: "right" }}>
                  <LoadingButton
                    onClick={() => {
                      setImportProductResult([]);
                    }}
                  >
                    {t("word.close")}
                  </LoadingButton>
                </Box>
              </CardContent>
            </Card>
          )}
        </>
      )}
      <DialogOrderComponent
        order={order}
        openDialog={openDialog.open}
        devis={openDialog.devis}
        setOpenDialog={setOpenDialog}
      />
      {editingFDocentete && (
        <>
          <Typography component="p" variant="h5" sx={{ textAlign: "center" }}>
            {t("word.editingDevis") + ": " + editingFDocentete}
          </Typography>
          <Divider />
        </>
      )}
      {thisCart !== undefined &&
        (thisCart.cartFArticles.length === 0 ? (
          <>
            <Typography>{t("sentence.cartNoProduct")}</Typography>
            {editingFDocentete && (
              <LoadingButton
                variant="outlined"
                loading={loading}
                onClick={cancelUpdateFDocentete}
              >
                {t("word.cancelEditDevis")}
              </LoadingButton>
            )}
          </>
        ) : (
          <>
            {thisCart.cartFArticles.map((cartFArticle, indexCartFArticle) => (
              <Box key={indexCartFArticle}>
                <CartFArticleComponent
                  cartFArticle={cartFArticle}
                  setThisCart={setThisCart}
                />
                <Divider sx={{ marginY: 2 }} />
              </Box>
            ))}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography component="span" variant="h6" sx={{ color: "gray" }}>
                {t("word.totalTtc")}
              </Typography>
              <Typography component="span" variant="h6" sx={{ color: "gray" }}>
                {priceFormat(getCartAmount(thisCart), i18n.language, "EUR")}
              </Typography>
            </Box>
            <Divider sx={{ marginY: 2 }} />
            <Box sx={{ textAlign: "right" }}>
              {editingFDocentete ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <LoadingButton
                    variant="outlined"
                    loading={loading}
                    onClick={cancelUpdateFDocentete}
                  >
                    {t("word.cancelEditDevis")}
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    onClick={updateFDocentete}
                  >
                    {t("word.doEditDevis") + ": " + editingFDocentete}
                  </LoadingButton>
                </Box>
              ) : (
                <Link
                  onClick={closeCart}
                  to={ORDER_PAGE}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button variant="contained">{t("word.validateCart")}</Button>
                </Link>
              )}
            </Box>
          </>
        ))}
    </>
  );
});

export default CartComponent;
