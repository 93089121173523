import React from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import { Container } from "@mui/material";
import RegisterComponent from "../components/common/register/RegisterComponent";
import ContentComponent from "../components/content/ContentComponent";
import { getConfigurationValue } from "../helpers/ConfigurationHelper";
import { CAN_CREATE_ACCOUNT } from "../utils/ConfigurationUtils";
import { getTitle } from "../helpers/SearchParamHelper";

const RegisterScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);

  const load = React.useCallback(
    (force: boolean = false) => {
      setInit(true);
      dispatch(set({ refreshPage: false }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    document.title = getTitle("register");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      {getConfigurationValue(CAN_CREATE_ACCOUNT) ? (
        <ContentComponent contentName="register" saveLocalStorage={true} />
      ) : (
        <ContentComponent contentName="registerClose" saveLocalStorage={true} />
      )}
      <Container maxWidth="md">
        <RegisterComponent />
      </Container>
      <ContentComponent contentName="registerAfter" saveLocalStorage={true} />
    </Layout>
  );
});

export default RegisterScreen;
