import * as React from "react";
import { Fragment, SyntheticEvent, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import {
  ExpeditionGrilleInterface,
  ExpeditionInterface,
} from "../../../interfaces/CartInterface";
import Typography from "@mui/material/Typography";
import { InputInterface } from "../../../interfaces/InputInterface";
import {
  Autocomplete,
  CircularProgress,
  DialogProps,
  Grid,
  TextField,
} from "@mui/material";
import ExpeditionGrilleFormComponent from "./ExpeditionGrilleFormComponent";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { DELETE, GET, PATCH, POST } from "../../../utils/MethodUtils";
import { EXPEDITION_URL, FARTICLE_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { priceFormat } from "../../../utils/FormatUtils";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  addUpdateExpedition,
  deleteExpedition,
} from "../../../app/globalSlice";
import {
  ALL_COUNTRIES_DELIVERY,
  COUNTRY_CODE_MAP,
} from "../../../utils/UserUtils";
import TabPanelComponent from "../TabPanelComponent";

interface State {
  expedition: ExpeditionInterface | undefined;
  removeExpedition: Function;
  indexExpeditionForm: number;
}

interface ExpeditionGrilleFormInterface {
  expeditionGrille: ExpeditionGrilleInterface | undefined;
  ref: any;
}

interface FormState {
  name: InputInterface;
  fArticleId: InputInterface;
  expeditionGrilleForms: (ExpeditionGrilleFormInterface | undefined)[];
}

const ExpeditionFormComponent = React.memo(
  React.forwardRef(
    ({ expedition, removeExpedition, indexExpeditionForm }: State, ref) => {
      const { t, i18n } = useTranslation();
      const user = useAppSelector((state: RootState) => state.globalState.user);
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const getDefaultValues = React.useCallback((): FormState => {
        return {
          name: { value: expedition?.name ?? "", error: "" },
          fArticleId: { value: expedition?.fArticleId ?? null, error: "" },
          expeditionGrilleForms:
            expedition?.expeditionGrilles.map((expeditionGrille) => {
              return {
                expeditionGrille: expeditionGrille,
                ref: React.createRef(),
              };
            }) ?? [],
        };
      }, [
        expedition?.expeditionGrilles,
        expedition?.fArticleId,
        expedition?.name,
      ]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
      const [edit, setEdit] = React.useState<boolean>(!expedition);
      const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
      const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
      const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false);
      const [deleteDialog, setDeleteDialog] = React.useState<boolean>(false);
      const [optionsFArticles, setOptionsFArticles] = React.useState<string[]>(
        values.fArticleId.value ? [values.fArticleId.value] : []
      );
      const [searchFArticle, setSearchFArticle] = React.useState<string>(
        values.fArticleId.value ?? ""
      );
      const [isTyping, setIsTyping] = React.useState<boolean>(false);
      const [isSearching, setIsSearching] = React.useState<boolean>(false);
      const [selectedCountry, setSelectedCountry] = React.useState<number>(0);
      const handleChangeSelectedCountry = React.useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
          setSelectedCountry(newValue);
        },
        []
      );
      const getColumns = React.useCallback((): GridColDef[] => {
        return [
          {
            field: "weight",
            headerName: t("word.weight"),
            flex: 1,
            headerClassName: "background-nove",
            renderCell: (params: GridRenderCellParams) =>
              params.row.weight.toLocaleString() +
              " g | " +
              (params.row.weight / 1000).toLocaleString() +
              " kg",
          },
          {
            field: "countryCode",
            headerName: t("field.country"),
            flex: 1,
            headerClassName: "background-nove",
            renderCell: (params: GridRenderCellParams) =>
              t("word.country." + params.row.countryCode?.toLowerCase()),
          },
          {
            field: "price",
            headerName: t("word.price"),
            flex: 1,
            headerClassName: "background-nove",
            renderCell: (params: GridRenderCellParams) =>
              priceFormat(params.row.price, i18n.language, "EUR"),
          },
        ];
      }, [i18n.language, t]);
      const [columns] = React.useState<GridColDef[]>(getColumns());
      const dispatch = useAppDispatch();

      const handleSearchFArticle = React.useCallback(
        (event: SyntheticEvent<Element, Event>, value: string) => {
          setSearchFArticle(value);
          setOptionsFArticles([]);
          setIsTyping(true);
        },
        []
      );

      const searchFArticles = React.useCallback(async () => {
        setIsTyping(false);
        if (!user) {
          return;
        }
        setIsSearching(true);
        const param: any = {
          "searchArRefs[]": [searchFArticle.trim().toUpperCase()],
        };
        const response = await requestApi({
          method: GET,
          path: FARTICLE_URL + objectToQuery(param),
          allowError: false,
          paginate: false,
          token: token,
        });
        if (response.statusCode === 200) {
          setOptionsFArticles(
            response.content.fArticles.map(
              (x: FArticleSmallInterface) => x.arRef
            )
          );
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setIsSearching(false);
      }, [searchFArticle, t, token, user]);

      const handleChangeAutocomplete = React.useCallback(
        (prop: keyof FormState) =>
          (event: SyntheticEvent<Element, Event>, value: any) => {
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        []
      );

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      const getValue = React.useCallback(() => {
        return {
          name: values.name.value,
          expeditionGrilles: values.expeditionGrilleForms
            .filter((expeditionGrilleForm) => expeditionGrilleForm)
            .map((expeditionGrilleForm) =>
              expeditionGrilleForm?.ref.current.getValue()
            ),
        };
      }, [values.expeditionGrilleForms, values.name.value]);

      const openDeleteDialog = React.useCallback(() => {
        setDeleteDialog(true);
      }, []);

      const closeDeleteDialog = React.useCallback(() => {
        setDeleteDialog(false);
      }, []);

      const save = React.useCallback(async () => {
        setLoadingSave(true);
        let url = EXPEDITION_URL;
        let method = POST;
        if (expedition) {
          method = PATCH;
          url += "/" + expedition.id;
        }
        const response = await requestApi({
          method: method,
          path: url,
          allowError: false,
          body: {
            name: values.name.value,
            fArticleId: values.fArticleId.value,
            expeditionGrilles: values.expeditionGrilleForms
              .map((expeditionGrilleForm) => {
                const newExpeditionGrille: ExpeditionGrilleInterface =
                  expeditionGrilleForm?.ref.current.getValue();
                if (newExpeditionGrille) {
                  return {
                    ...newExpeditionGrille,
                    countryCode:
                      COUNTRY_CODE_MAP[newExpeditionGrille.countryCode],
                    weight: Math.round(Number(newExpeditionGrille.weight)),
                    price: Math.round(Number(newExpeditionGrille.price) * 100),
                  };
                }
                return undefined;
              })
              .filter((expeditionGrilleForm) => expeditionGrilleForm),
          },
          token: token,
        });
        if (response.statusCode === 200 || response.statusCode === 201) {
          dispatch(addUpdateExpedition(response.content));
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoadingSave(false);
        setEdit(false);
      }, [
        dispatch,
        expedition,
        t,
        token,
        values.expeditionGrilleForms,
        values.fArticleId.value,
        values.name.value,
      ]);

      const thisDeleteExpedition = React.useCallback(async () => {
        setLoadingDelete(true);
        if (!expedition) {
          removeExpedition(indexExpeditionForm);
          setLoadingDelete(false);
          setDeleteDialog(false);
          return;
        }
        const response = await requestApi({
          method: DELETE,
          path: EXPEDITION_URL + "/" + expedition.id,
          allowError: false,
          token: token,
        });
        if (response.statusCode === 204) {
          dispatch(deleteExpedition(expedition.id));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoadingDelete(false);
        setDeleteDialog(false);
      }, [
        dispatch,
        expedition,
        indexExpeditionForm,
        removeExpedition,
        t,
        token,
      ]);

      const addExpeditionGrilleForm = React.useCallback(() => {
        setValues((x) => {
          x.expeditionGrilleForms.push({
            expeditionGrille: {
              countryCode:
                COUNTRY_CODE_MAP[ALL_COUNTRIES_DELIVERY[selectedCountry]],
              weight: 0,
              price: 0,
            },
            ref: React.createRef(),
          });
          return { ...x };
        });
      }, [selectedCountry]);

      const changeEdit = React.useCallback(() => {
        setEdit((x) => !x);
      }, []);

      const removeExpeditionGrilleForm = React.useCallback(
        (index: number) => () => {
          setValues((x) => {
            x.expeditionGrilleForms = x.expeditionGrilleForms.map(
              (expeditionGrilleForm, indexExpeditionGrilleForm) => {
                if (indexExpeditionGrilleForm !== index) {
                  return expeditionGrilleForm;
                }
                return undefined;
              }
            );
            return { ...x };
          });
        },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [expedition]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        const timeoutTyping = setTimeout(() => {
          searchFArticles();
        }, 500);
        return () => clearTimeout(timeoutTyping);
      }, [searchFArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {!edit && expedition?.expeditionGrilles.length === 0 && (
            <Typography sx={{ textAlign: "center" }}>
              {t("sentence.expeditionFree")}
            </Typography>
          )}
          <Grid container spacing={1}>
            {edit ? (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    autoComplete="off"
                    error={!!values.name.error}
                    helperText={t(values.name.error ?? "")}
                    sx={{ width: "100%" }}
                    required
                    type="text"
                    value={values.name.value}
                    onChange={handleChange("name")}
                    label={t("field.name")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    loadingText={t("word.loading") + "..."}
                    inputValue={searchFArticle}
                    onInputChange={handleSearchFArticle}
                    noOptionsText={
                      isSearching ? (
                        <Box sx={{ textAlign: "center" }}>
                          <CircularProgress />
                        </Box>
                      ) : searchFArticle !== "" && !isTyping ? (
                        t("sentence.no_option")
                      ) : (
                        t("word.sav.searchFArticle.label")
                      )
                    }
                    options={optionsFArticles}
                    onChange={handleChangeAutocomplete("fArticleId")}
                    value={values.fArticleId.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        error={!!values.fArticleId.error}
                        helperText={t(values.fArticleId.error ?? "")}
                        label={t("field.fArticleId")}
                        placeholder={t("field.fArticleId")}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : (
              <>
                {expedition?.fArticleId && (
                  <Grid item xs={12}>
                    <Typography className="RobotoMono">
                      {t("word.refNove")}: {expedition.fArticleId}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
            {edit ? (
              <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={selectedCountry}
                    onChange={handleChangeSelectedCountry}
                    scrollButtons="auto"
                    variant={!isSmall ? "standard" : "scrollable"}
                    centered={!isSmall}
                  >
                    {ALL_COUNTRIES_DELIVERY.map(
                      (countryCode, indexCountryCode) => (
                        <Tab
                          label={t(
                            "word.country." +
                              COUNTRY_CODE_MAP[countryCode]?.toLowerCase()
                          )}
                          key={indexCountryCode}
                        />
                      )
                    )}
                  </Tabs>
                </Box>
                {ALL_COUNTRIES_DELIVERY.map((countryCode, indexCountryCode) => {
                  return (
                    <TabPanelComponent
                      sx={{ marginTop: 1 }}
                      value={selectedCountry}
                      index={indexCountryCode}
                      key={indexCountryCode}
                      alwaysShow={true}
                    >
                      {values.expeditionGrilleForms.map(
                        (expeditionGrilleForm, indexExpeditionGrilleForm) =>
                          expeditionGrilleForm ? (
                            <Fragment key={indexExpeditionGrilleForm}>
                              {expeditionGrilleForm?.expeditionGrille
                                ?.countryCode ===
                                COUNTRY_CODE_MAP[countryCode] && (
                                <Grid item xs={12}>
                                  {indexExpeditionGrilleForm > 0 && (
                                    <Divider sx={{ marginY: 1 }} />
                                  )}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <ExpeditionGrilleFormComponent
                                      // @ts-ignore
                                      expeditionGrille={
                                        expeditionGrilleForm.expeditionGrille
                                      }
                                      countryCode={countryCode}
                                      // @ts-ignore
                                      ref={expeditionGrilleForm.ref}
                                    />
                                    <IconButton
                                      onClick={removeExpeditionGrilleForm(
                                        indexExpeditionGrilleForm
                                      )}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )}
                            </Fragment>
                          ) : (
                            <Box key={indexExpeditionGrilleForm} />
                          )
                      )}
                    </TabPanelComponent>
                  );
                })}
              </Grid>
            ) : (
              <Grid item xs={12}>
                {expedition?.expeditionGrilles &&
                  expedition.expeditionGrilles.length > 0 && (
                    <>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={selectedCountry}
                          onChange={handleChangeSelectedCountry}
                          scrollButtons="auto"
                          variant={!isSmall ? "standard" : "scrollable"}
                          centered={!isSmall}
                        >
                          {ALL_COUNTRIES_DELIVERY.map(
                            (countryCode, indexCountryCode) => (
                              <Tab
                                label={t(
                                  "word.country." +
                                    COUNTRY_CODE_MAP[countryCode]?.toLowerCase()
                                )}
                                key={indexCountryCode}
                              />
                            )
                          )}
                        </Tabs>
                      </Box>
                      {ALL_COUNTRIES_DELIVERY.map(
                        (countryCode, indexCountryCode) => {
                          return (
                            <TabPanelComponent
                              sx={{ marginTop: 1 }}
                              value={selectedCountry}
                              index={indexCountryCode}
                              key={indexCountryCode}
                            >
                              <DataGrid
                                initialState={{
                                  sorting: {
                                    sortModel: [
                                      { field: "weight", sort: "asc" },
                                    ],
                                  },
                                }}
                                rows={expedition.expeditionGrilles.filter(
                                  (expeditionGrille) =>
                                    expeditionGrille.countryCode ===
                                    COUNTRY_CODE_MAP[countryCode]
                                )}
                                getRowId={(row) => row.id}
                                autoHeight={true}
                                hideFooter={true}
                                columns={columns}
                                disableExtendRowFullWidth={true}
                                localeText={getLocaleDataGrid(i18n.language)}
                              />
                            </TabPanelComponent>
                          );
                        }
                      )}
                    </>
                  )}
              </Grid>
            )}
            <Box
              sx={{
                display: "flex",
                ...(!edit
                  ? { flexDirection: "row-reverse" }
                  : { flexDirection: "row" }),
                width: "100%",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              {edit ? (
                <>
                  {expedition && (
                    <Button onClick={changeEdit}>{t("word.cancel")}</Button>
                  )}
                  <Button
                    onClick={openDeleteDialog}
                    disabled={loadingSave}
                    color="error"
                  >
                    {t("word.delete")}
                  </Button>
                  <IconButton onClick={addExpeditionGrilleForm}>
                    <AddIcon />
                  </IconButton>
                  <LoadingButton
                    variant="contained"
                    onClick={save}
                    loading={loadingSave}
                  >
                    {t("word.save")}
                  </LoadingButton>
                </>
              ) : (
                <IconButton onClick={changeEdit}>
                  <EditIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
          <Dialog
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            onClose={closeDeleteDialog}
            open={deleteDialog}
          >
            <DialogContent>
              <Typography>{t("sentence.deleteExpedition")}</Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <LoadingButton
                disabled={loadingDelete}
                onClick={closeDeleteDialog}
              >
                {t("word.no")}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                loading={loadingDelete}
                onClick={thisDeleteExpedition}
              >
                {t("word.yes")}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  )
);

export default ExpeditionFormComponent;
