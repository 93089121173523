import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { set } from "../../../app/globalSlice";
import {
  getTitle,
  searchParamToObject,
} from "../../../helpers/SearchParamHelper";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { DELETE, GET, PATCH } from "../../../utils/MethodUtils";
import { CREDIT_FDOCENTETES_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useSearchParams } from "react-router-dom";
import { PaymentHistoryInterface } from "../../../interfaces/PaymentHistoryInterface";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { CreditFdocenteteInterface } from "../../../interfaces/FEcritureRecInterface";
import CopyClipboardComponent from "../CopyClipboardComponent";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { priceFormat } from "../../../utils/FormatUtils";
import FilterSearchComponent from "../filter/FilterSearchComponent";
import ImpersonateButtonComponent from "../user/ImpersonateButtonComponent";
import { DialogContent, DialogProps, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import getErrorApi from "../../../helpers/GetErrorApi";

export interface State {
  creditFDocentete: CreditFdocenteteInterface;
}

const PointageComponent: React.FC<State> = React.memo(
  ({ creditFDocentete }) => {
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [realIsPointage, setRealIsPointage] = React.useState<boolean>(
      !!creditFDocentete.pointage
    );
    const [loading, setLoading] = React.useState<boolean>(false);
    const { t } = useTranslation();

    const handleChange = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: PATCH,
        path: CREDIT_FDOCENTETES_URL + "/" + creditFDocentete.id,
        allowError: false,
        token: token,
        body: {
          pointage: !realIsPointage,
        },
      });
      if (response.statusCode === 200) {
        setRealIsPointage(response.content.pointage);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [creditFDocentete.id, realIsPointage, t, token]);

    return (
      <>
        <LoadingButton
          variant="text"
          color="inherit"
          sx={{
            borderRadius: "50%",
            minWidth: "auto",
            padding: "12px",
          }}
          loading={loading}
          onClick={handleChange}
        >
          {realIsPointage ? (
            <DoneIcon
              color={
                !!creditFDocentete.pointage !== realIsPointage
                  ? "info"
                  : "success"
              }
            />
          ) : (
            <CloseIcon
              color={
                !!creditFDocentete.pointage !== realIsPointage
                  ? "info"
                  : "error"
              }
            />
          )}
        </LoadingButton>
      </>
    );
  }
);

const CreditFDocenteteComponent: React.FC = React.memo(() => {
  const { t, i18n } = useTranslation();
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const theme = useTheme();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const [loading, setLoading] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(50);
  const [creditFDocentetes, setCreditFDocentetes] = React.useState<
    PaymentHistoryInterface[] | undefined
  >(undefined);
  const [creditFDocenteteDelete, setCreditFDocenteteDelete] = React.useState<
    CreditFdocenteteInterface | undefined
  >(undefined);
  const [totalItems, setTotalItems] = React.useState(0);
  const handleDrawerDeleteOpen = React.useCallback(
    (thisCreditFDocenteteDelete: CreditFdocenteteInterface | undefined) => {
      setCreditFDocenteteDelete(thisCreditFDocenteteDelete);
    },
    []
  );
  const handleDrawerDeleteClose = React.useCallback(() => {
    setCreditFDocenteteDelete(undefined);
  }, []);
  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "fDocenteteId",
        headerName: t("column.fDocenteteId"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.fDocenteteId ?? ""}
          />
        ),
      },
      {
        field: "userIdentifier",
        headerName: t("column.userIdentifier"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.userIdentifier ?? ""}
          />
        ),
      },
      {
        field: "amount",
        headerName: t("column.amount"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>{priceFormat(params.row.amount, i18n.language, "EUR")}</>
        ),
      },
      {
        field: "done",
        headerName: t("column.inCompta"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.row.done ? (
              <DoneIcon color="success" />
            ) : (
              <CloseIcon color="error" />
            )}
          </>
        ),
      },
      {
        field: "pointage",
        headerName: t("column.pointage"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return <PointageComponent creditFDocentete={params.row} />;
        },
      },
      {
        field: "created",
        headerName: t("column.created"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.row.created).toLocaleString(i18n.language, {
            dateStyle: "long",
            timeStyle: "medium",
            timeZone: "America/Cuiaba",
          }),
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        headerClassName: "background-nove",
        filterable: false,
        align: "center",
        flex: 0,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <ImpersonateButtonComponent
              userIdentifier={params.row.userIdentifier}
            />
            <IconButton onClick={() => handleDrawerDeleteOpen(params.row)}>
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    ];
  }, [handleDrawerDeleteOpen, i18n.language, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (creditFDocentetes !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (
        !searchParamsObject.hasOwnProperty("page") ||
        searchParamsObject.page === "undefined"
      ) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: CREDIT_FDOCENTETES_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"].map(
            (o: CreditFdocenteteInterface) => o.id
          )
        );
        const thisCreditFDocentetes = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setTotalItems(response.content["hydra:totalItems"]);
        setCreditFDocentetes(thisCreditFDocentetes);
        dispatch(set({ nbErrorPayments: response.content.nbErrorPayments }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      creditFDocentetes,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setCreditFDocentetes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setCreditFDocentetes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const confirmDelete = React.useCallback(async () => {
    if (!creditFDocenteteDelete) {
      return;
    }
    setLoading(true);
    const response = await requestApi({
      method: DELETE,
      path: CREDIT_FDOCENTETES_URL + "/" + creditFDocenteteDelete.id,
      allowError: false,
      token: token,
    });
    if (response.statusCode === 204) {
      dispatch(set({ refreshPage: true }));
      handleDrawerDeleteClose();
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [creditFDocenteteDelete, dispatch, handleDrawerDeleteClose, t, token]);

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  React.useEffect(() => {
    document.title = getTitle("payments");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        onClose={handleDrawerDeleteClose}
        open={!!creditFDocenteteDelete}
      >
        <DialogContent>
          <DialogContentText>
            {t("word.deleteCreditFDocentete.question")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <LoadingButton disabled={loading} onClick={handleDrawerDeleteClose}>
            {t("word.no")}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={confirmDelete}
          >
            {t("word.yes")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <FilterSearchComponent init={init} placeholder="word.search" />
      {init && searchParamsObject.itemsPerPage && searchParamsObject.page && (
        // https://mui.com/x/react-data-grid/components/#pagination
        <DataGrid
          initialState={{
            sorting: {
              sortModel: getSortModel(),
            },
          }}
          loading={refreshPage}
          getRowHeight={() => "auto"}
          rows={refreshPage ? [] : creditFDocentetes ?? []}
          onSortModelChange={onSortModelChange}
          sortingMode="server"
          page={Number(searchParamsObject.page) - 1}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={Number(searchParamsObject.itemsPerPage)}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          rowCount={totalItems}
          columns={columns}
          autoHeight={true}
          disableExtendRowFullWidth={true}
          localeText={getLocaleDataGrid(i18n.language)}
        />
      )}
    </>
  );
});

export default CreditFDocenteteComponent;
