import React from "react";
import { UserInterface } from "../../interfaces/UserInterface";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface State {
  user: UserInterface | undefined;
}

const FReglementtComponent: React.FC<State> = React.memo(({ user }) => {
  const { t } = useTranslation();

  return (
    <>
      {user?.fReglementts && user.fReglementts.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ textTransform: "capitalize" }}>
                  {t("word.day")}
                </StyledTableCell>
                <StyledTableCell>{t("word.condition")}</StyledTableCell>
                <StyledTableCell>{t("word.the")}</StyledTableCell>
                <StyledTableCell>{t("word.paymentChoice")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user.fReglementts.map((fReglementt, indexFReglementt) => (
                <StyledTableRow key={indexFReglementt}>
                  <StyledTableCell>{fReglementt.rtNbjour}</StyledTableCell>
                  <StyledTableCell sx={{ textTransform: "capitalize" }}>
                    {t("word.rtCondition." + fReglementt.rtCondition)}
                  </StyledTableCell>
                  <StyledTableCell>{fReglementt.rtJourtb01}</StyledTableCell>
                  <StyledTableCell>
                    {fReglementt.pReglement.rIntitule}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
});

export default FReglementtComponent;
