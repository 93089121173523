import React, { useImperativeHandle } from "react";
import { Checkbox } from "@mui/material";

const FilterFormForceCreateFilterArticleComponent = React.memo(
  React.forwardRef((props, ref) => {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };

    useImperativeHandle(ref, () => ({
      getValue() {
        return checked;
      },
    }));

    return <Checkbox checked={checked} onChange={handleChange} />;
  })
);

export default FilterFormForceCreateFilterArticleComponent;
