import React, { forwardRef, useImperativeHandle } from "react";
import { InputInterface } from "../../interfaces/InputInterface";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import MapDisplayComponent from "../common/MapDisplayComponent";

interface State {
  data: any;
}

interface FormState {
  query: InputInterface;
  height: InputInterface;
}

const MapContentComponent = React.memo(
  forwardRef(({ data }: State, ref) => {
    const { t } = useTranslation();
    const [previewValue, setPreviewValue] = React.useState("");
    const getValues = React.useCallback(() => {
      return {
        query: { value: data?.query ?? "nove guadeloupe", error: "" },
        height: { value: data?.height ?? 500, error: "" },
      };
    }, [data?.height, data?.query]);
    const [values, setValues] = React.useState<FormState>(getValues());

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const preview = React.useCallback(() => {
      setPreviewValue(values.query.value);
    }, [values.query.value]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          query: values.query.value,
          height: values.height.value,
        };
      },
    }));

    React.useEffect(() => {
      setValues(getValues());
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ marginY: 2 }}>
        <Grid container spacing={1}>
          <Grid item sm={12} md={6} sx={{ width: "100%" }}>
            <TextField
              autoComplete="off"
              error={!!values.query.error}
              helperText={t(values.query.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.query.value}
              onChange={handleChange("query")}
              label={t("word.search")}
            />
          </Grid>
          <Grid item sm={12} md={6} sx={{ width: "100%" }}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.height.error}
              helperText={t(values.height.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="number"
              value={values.height.value}
              onChange={handleChange("height")}
              label={t("field.height")}
            />
          </Grid>
        </Grid>
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <LoadingButton variant="outlined" onClick={preview}>
            <RefreshIcon />
          </LoadingButton>
        </Box>
        {previewValue !== "" && (
          <MapDisplayComponent
            query={previewValue}
            height={values.height.value}
          />
        )}
      </Box>
    );
  })
);

export default MapContentComponent;
