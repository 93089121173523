import React from "react";
import Layout from "../components/common/Layout";
import { getTitle } from "../helpers/SearchParamHelper";
import NewsComponent from "../components/common/news/NewsComponent";

const NewsScreen: React.FC = React.memo(() => {
  React.useEffect(() => {
    document.title = getTitle("news");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout sx={{ overflow: "hidden" }}>
      <NewsComponent />
    </Layout>
  );
});

export default NewsScreen;
