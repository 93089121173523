import i18n from "../languagues/i18n";

const clone = require("clone");

export const searchParamToObject = (searchParams: any): any => {
  const result: any = {};
  for (const [key, value] of searchParams) {
    if (key.endsWith("[]")) {
      if (!result.hasOwnProperty(key)) {
        result[key] = [];
      }
      result[key].push(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};

export const getTitle = (title: string): string => {
  return i18n.t("title." + title);
};

export const paramsHasChanged = (
  currentSearchParams: any,
  newSearchParams: any
) => {
  const cloneCurrentSearchParams: any = clone(currentSearchParams);
  const cloneNewSearchParams: any = clone(newSearchParams);
  delete cloneCurrentSearchParams.page;
  delete cloneNewSearchParams.page;
  return (
    new URLSearchParams(cloneCurrentSearchParams).toString() !==
    new URLSearchParams(cloneNewSearchParams).toString()
  );
};
