import * as React from "react";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { CategoryInterface } from "../../../../interfaces/CategoryInterface";
import CropperComponent from "../../file/CropperComponent";
import { IMAGE_SIZE_SMALL } from "../../../../utils/FileUtils";
import { getUrlCategoryImage } from "../../../../helpers/FileHelper";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";

interface State {
  category: CategoryInterface | undefined;
}

const FormImageCategoryComponent = React.memo(
  forwardRef(({ category }: State, ref) => {
    const [showOriginalImage, setShowOriginalImage] =
      React.useState<boolean>(true);
    const cropperRef: any = useRef();

    const imageAdded = React.useCallback(() => {
      setShowOriginalImage(false);
    }, []);

    useImperativeHandle(ref, () => ({
      async getValue() {
        const value = await cropperRef.current.getValue();
        if (!value && !showOriginalImage) {
          return null;
        }
        return value;
      },
    }));

    return (
      <>
        <CropperComponent
          ref={cropperRef}
          minCropBox={IMAGE_SIZE_SMALL}
          imageAdded={imageAdded}
        />
        {category !== undefined &&
          category.sizes !== null &&
          showOriginalImage && (
            <Box sx={{ textAlign: "center" }}>
              <img
                src={
                  (process.env.REACT_APP_API_URL ?? "") +
                  getUrlCategoryImage(category, IMAGE_SIZE_SMALL)
                }
                style={{
                  maxWidth: "100%",
                  margin: "auto",
                  minWidth: "8em",
                  minHeight: "8em",
                  border: "dashed 1px grey",
                }}
                alt={"[" + category?.id + "] " + category?.name}
                loading="lazy"
              />
              <a
                href={
                  (process.env.REACT_APP_API_URL ?? "") +
                  getUrlCategoryImage(category, IMAGE_SIZE_SMALL)
                }
                target="_blank"
                download={"category_" + category?.id + "_" + category?.name}
                rel="noreferrer"
              >
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </a>
            </Box>
          )}
      </>
    );
  })
);

export default FormImageCategoryComponent;
