import React, { useImperativeHandle } from "react";
import {
  FilterFilterValueFormInterface,
  FilterFilterValueInterface,
} from "../../../../../../interfaces/FilterInterface";
import { InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import notEmptyValidator from "../../../../../../helpers/validator/NotEmptyValidator";
import { FormStateFilterForm } from "./FilterFormComponent";

interface State {
  filterValue: FilterFilterValueInterface;
  setValues: Function;
  currentFocus: string | undefined;
  setCurrentFocus: Function;
}

const FilterFormNameComponent = React.memo(
  React.forwardRef(
    ({ filterValue, setValues, currentFocus, setCurrentFocus }: State, ref) => {
      const [name, setName] = React.useState(
        filterValue.tempName ?? filterValue.name
      );
      const [error, setError] = React.useState("");
      const [edit, setEdit] = React.useState(
        name.trim() === "" || name !== filterValue.name
      );
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues((x: FormStateFilterForm) => {
          const thisFilterValue = x.filterValues.value.find(
            (y: FilterFilterValueFormInterface) => y.refName === ref
          );
          thisFilterValue.tempName = event.target.value;
          return { ...x };
        });
        setName(event.target.value);
        setError("");
      };
      const { t } = useTranslation();

      const handleEdit = React.useCallback(() => {
        setName(filterValue.name);
        setEdit((x) => !x);
      }, [filterValue.name]);

      const onFocus = React.useCallback(() => {
        setCurrentFocus(filterValue.draggableId);
      }, [filterValue.draggableId, setCurrentFocus]);

      const onBlur = React.useCallback(() => {
        setCurrentFocus(undefined);
      }, [setCurrentFocus]);

      useImperativeHandle(ref, () => ({
        getValue() {
          let result = edit ? name : filterValue.name;
          result = result.trim();
          const thisError = notEmptyValidator(result);
          if (thisError !== "") {
            setError(thisError);
          }
          return result;
        },
        setError(value: string) {
          setError(value);
        },
      }));

      React.useEffect(() => {
        if (error !== "") {
          setEdit(true);
        }
      }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {edit ? (
            <TextField
              fullWidth={true}
              autoFocus={currentFocus === filterValue.draggableId}
              onBlur={onBlur}
              onFocus={onFocus}
              autoComplete="off"
              error={!!error}
              helperText={t(error)}
              required
              type="text"
              value={name}
              onChange={handleChange}
              label={t("field.name")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleEdit}>
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{filterValue.name}</Typography>
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </Box>
          )}
        </>
      );
    }
  )
);

export default FilterFormNameComponent;
