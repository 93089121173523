import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { InputInterface } from "../../interfaces/InputInterface";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import PdfDisplayComponent from "../common/PdfDisplayComponent";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import { urltoFile } from "../../helpers/FileHelper";

interface State {
  data: any;
}

interface FormState {
  nbPagePreview: InputInterface;
}

const Input = styled("input")({
  display: "none",
});

const PdfContentComponent = React.memo(
  forwardRef(({ data }: State, ref) => {
    const { t } = useTranslation();
    const [values, setValues] = React.useState<FormState>({
      nbPagePreview: { value: data?.nbPagePreview ?? 2, error: "" },
    });
    const inputFileRef: any = useRef();
    const getBase64 = React.useCallback(() => {
      if (data && data.file) {
        return process.env.REACT_APP_API_URL + "/DATA" + data.file;
      }
      return undefined;
    }, [data]);
    const [base64, setBase64] = React.useState(getBase64());

    const onChange = React.useCallback((e: any) => {
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setBase64(reader.result as any);
      };
      reader.readAsDataURL(files[0]);
    }, []);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const addNbPagePreview = React.useCallback(() => {
      setValues((v) => {
        return {
          ...v,
          nbPagePreview: {
            ...v.nbPagePreview,
            value: Number(v.nbPagePreview.value) + 1,
            error: "",
          },
        };
      });
    }, []);

    const removeNbPagePreview = React.useCallback(() => {
      setValues((v) => {
        if (v.nbPagePreview.value === 1) {
          return v;
        }
        return {
          ...v,
          nbPagePreview: {
            ...v.nbPagePreview,
            value: Number(v.nbPagePreview.value) - 1,
            error: "",
          },
        };
      });
    }, []);

    const getFile = React.useCallback(async () => {
      if (!base64) {
        return undefined;
      }
      if (getBase64()?.includes(base64)) {
        return data.file;
      }
      const file = await urltoFile(base64, "file.pdf", "application/pdf");
      if (!file) {
        return undefined;
      }
      return file;
    }, [base64, data?.file, getBase64]);

    const getValue = React.useCallback(async () => {
      let number = Number(values.nbPagePreview.value);
      if (number <= 0) {
        number = 1;
      }
      const file = await getFile();
      if (!file) {
        return undefined;
      }
      return {
        nbPagePreview: number,
        file: file,
      };
    }, [getFile, values.nbPagePreview.value]);

    useImperativeHandle(ref, () => ({
      async getValue() {
        return await getValue();
      },
    }));

    React.useEffect(() => {
      setBase64(getBase64());
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" required>
              <InputLabel>{t("word.nbPages")}</InputLabel>
              <OutlinedInput
                error={!!values.nbPagePreview.error}
                type="number"
                className="center-input"
                value={values.nbPagePreview.value}
                onChange={handleChange("nbPagePreview")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("field.action.add_nbPagePreview")}
                      onClick={addNbPagePreview}
                      edge="end"
                    >
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                }
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      aria-label={t("field.action.remove_nbPagePreview")}
                      onClick={removeNbPagePreview}
                      edge="end"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label={t("word.nbPages")}
              />
              {!!values.nbPagePreview.error && (
                <FormHelperText error>
                  {t(values.nbPagePreview.error ?? "")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ justifyContent: "center" }}
        >
          <label>
            <Input
              accept="application/pdf"
              type="file"
              onChange={onChange}
              ref={inputFileRef}
            />
            <Button
              variant="contained"
              sx={{ marginBottom: 1 }}
              component="span"
            >
              {base64 ? t("word.change") : <AddIcon />}
            </Button>
          </label>
        </Stack>
        <PdfDisplayComponent
          base64={base64}
          nbPagePreview={values.nbPagePreview.value}
        />
      </>
    );
  })
);

export default PdfContentComponent;
