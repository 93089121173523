import React from "react";
import { List, ListItem, ListItemButton, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { FLivraisonInterface } from "../../interfaces/UserInterface";
import LivraisonsFormComponent from "../../components/common/user/livraison/LivraisonsFormComponent";
import Typography from "@mui/material/Typography";
import { isFLivraisonValid } from "../../helpers/FDocenteteHelper";

const CardAccountLivraisonComponent: React.FC = React.memo(() => {
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const { t } = useTranslation();

  const [fLivraisonForm, setFLivraisonForm] = React.useState<
    FLivraisonInterface | undefined
  >(undefined);

  const editLivraison = React.useCallback((fLivraison: FLivraisonInterface) => {
    setFLivraisonForm(fLivraison);
  }, []);

  const onAddressChanged = React.useCallback(
    (fLivraison?: FLivraisonInterface) => {
      setFLivraisonForm(undefined);
    },
    []
  );

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <List>
            {user?.fLivraisons?.map((fLivraison, indexFlivraison) => {
              const validLivraisonText = isFLivraisonValid(fLivraison);
              return (
                <ListItem disablePadding key={indexFlivraison}>
                  <ListItemButton sx={{ display: "initial" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {fLivraison.liIntitule ?? ""}
                      {fLivraison.liPrincipal === 1 && (
                        <Typography
                          variant="caption"
                          component="span"
                          color="primary"
                        >
                          [{t("word.byDefault")}]
                        </Typography>
                      )}
                      {validLivraisonText && (
                        <Typography
                          variant="caption"
                          component="span"
                          color="error"
                          sx={{ display: "block" }}
                        >
                          {validLivraisonText}
                        </Typography>
                      )}
                    </Typography>
                    <Typography>
                      {(fLivraison.liAdresse ?? "") +
                        " " +
                        (fLivraison.liComplement ?? "")}
                    </Typography>
                    <Typography>
                      {(fLivraison.liCoderegion ?? "") +
                        " " +
                        (fLivraison.liVille ?? "") +
                        " " +
                        (fLivraison.liCodepostal ?? "")}
                    </Typography>
                    <Typography>{fLivraison.liPays ?? ""}</Typography>
                    <Typography>
                      {(fLivraison.liTelephone ?? "") +
                        " " +
                        (fLivraison.liEmail ?? "")}
                    </Typography>
                  </ListItemButton>
                  <Tooltip title={t("word.edit.word")}>
                    <IconButton
                      size="large"
                      onClick={() => editLivraison(fLivraison)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              );
            })}
          </List>
          <LivraisonsFormComponent
            onAddressChanged={onAddressChanged}
            fLivraison={fLivraisonForm}
            setFLivraisonForm={setFLivraisonForm}
          />
        </CardContent>
      </Card>
    </>
  );
});

export default CardAccountLivraisonComponent;
