import React from "react";
import laughGif from "../../assets/laugh.gif";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const FloComponent: React.FC = React.memo(() => {
  const showFloComponent = useAppSelector(
    (state: RootState) => state.globalState.showFloComponent
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (showFloComponent) {
      setTimeout(() => {
        dispatch(set({ showFloComponent: false }));
      }, 2300);
    }
  }, [showFloComponent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {showFloComponent && (
        <Box
          sx={{
            position: "absolute",
            margin: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 3,
            width: 245,
            height: 232,
          }}
        >
          <img src={laughGif} alt="laugh" />
          <Typography
            variant="h5"
            component="p"
            sx={{
              position: "relative",
              color: "white",
              bottom: 70,
              textAlign: "center",
            }}
          >
            Le document est ouvert
          </Typography>
        </Box>
      )}
    </>
  );
});

export default FloComponent;
