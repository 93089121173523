import {
  STORAGE_INLINE_FARTICLE_CATEGORY,
  STORAGE_NB_GROUP,
  STYLE_INLINE,
} from "./StorageUtils";

export const TXT_STOCK_AUTO = 0;
export const TXT_STOCK_DISPO = 1; // Disponible
export const TXT_STOCK_REAPPRO = 2; // En cours de réapprovisionnement
export const TXT_STOCK_COMMANDE = 3; // Sur commande uniquement
export const TXT_STOCK_RUPTURE = 4; // Dans la limite du stock disponible
export const TXT_STOCK_DELAI_1 = 5; // Disponible sous 24/48H dans la limite du stock fournisseur.
export const TXT_STOCK_DELAI_2 = 6; // Disponible sous 5 / 7 jours dans la limite du stock fournisseur.
export const TXT_STOCK_DELAI_3 = 7; // Disponible sous 15 jours dans la limite du stock fournisseur.
export const TXT_STOCK_DELAI_4 = 8; // Disponible sous 4 semaines dans la limite du stock fournisseur.

export const TXT_STOCK_IDENTIFIER: any = {
  [TXT_STOCK_AUTO]: "TXT_STOCK_AUTO",
  [TXT_STOCK_DISPO]: "TXT_STOCK_DISPO",
  [TXT_STOCK_REAPPRO]: "TXT_STOCK_REAPPRO",
  [TXT_STOCK_COMMANDE]: "TXT_STOCK_COMMANDE",
  [TXT_STOCK_RUPTURE]: "TXT_STOCK_RUPTURE",
  [TXT_STOCK_DELAI_1]: "TXT_STOCK_DELAI_1",
  [TXT_STOCK_DELAI_2]: "TXT_STOCK_DELAI_2",
  [TXT_STOCK_DELAI_3]: "TXT_STOCK_DELAI_3",
  [TXT_STOCK_DELAI_4]: "TXT_STOCK_DELAI_4",
};

export const CAN_BUY_MORE_THAN_DISPO = [
  TXT_STOCK_AUTO,
  TXT_STOCK_DISPO,
  TXT_STOCK_REAPPRO,
  TXT_STOCK_COMMANDE,
  TXT_STOCK_DELAI_1,
  TXT_STOCK_DELAI_2,
  TXT_STOCK_DELAI_3,
  TXT_STOCK_DELAI_4,
];

export const FAMILLE_SERVICE = "SE";

export const STYLE_SUPER_COMPACT_INLINE = "A";
export const STYLE_COMPACT_INLINE = "B";
export const STYLE_STRETCH_INLINE = "C";
export const DEFAULT_STYLE_INLINE = STYLE_SUPER_COMPACT_INLINE;

export const AR_REF_LIVRAISON = ["PORT", "COLISSIMO", "LIVRAISON"];

export const ACOMPTE_STOCK_MANUEL = [TXT_STOCK_COMMANDE];

export const DEFAULT_NB_PRODUCT_SLIDER = 4;

export const SELLING_CROSS = "A";
export const SELLING_UP = "B";
export const SELLING_CONSUMABLE = "C";
export const SELLING_LICENSE = "D";
export const SELLING_ALTERNATIVE = "E";
export const SELLING_ENCRE = "F";

export const ALL_SELLING = [
  SELLING_CROSS,
  SELLING_UP,
  SELLING_CONSUMABLE,
  SELLING_LICENSE,
  SELLING_ALTERNATIVE,
  SELLING_ENCRE,
];

export const SELLING_DISPLAY_PRODUCT = ALL_SELLING.filter(
  (x) => x !== SELLING_UP
);

export const SELLING_TEXT = "A";
export const SELLING_FILTER_VALUE = "B";

export const getNbGroup = () => {
  const defaultValue = 4;
  let result = Number(localStorage.getItem(STORAGE_NB_GROUP) ?? defaultValue);
  if (result <= 0) {
    result = defaultValue;
  }
  return result;
};

export const getInline = () => {
  return localStorage.getItem(STORAGE_INLINE_FARTICLE_CATEGORY);
};

export const getStyleInline = () => {
  return localStorage.getItem(STYLE_INLINE) ?? DEFAULT_STYLE_INLINE;
};

export const TYPE_LINK = "A";

export const DEFAULT_PERCENT_ACOMPTE = 0.3;

export const ELASTIC_STOCK_AVAILABLE = 1;
export const ELASTIC_STOCK_AVAILABLE_NOW = 2;
export const ELASTIC_STOCK_ARRIVAL = 3;
export const ELASTIC_STOCK_NOT_AVAILABLE = 4;

export const ELASTIC_STOCK_ALL = [
  ELASTIC_STOCK_AVAILABLE,
  ELASTIC_STOCK_ARRIVAL,
  ELASTIC_STOCK_AVAILABLE_NOW,
  ELASTIC_STOCK_NOT_AVAILABLE,
];
