import React, { forwardRef, SyntheticEvent, useImperativeHandle } from "react";
import {
  Autocomplete,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { CONDITION_AND } from "../../../utils/FilterUtils";
import { FiscalCodeInterface } from "../../../interfaces/FiscalCodeInterface";

interface State {
  apply: Function;
  setNbSelected: Function;
  index: number;
  filterInited: Function;
}

const FiscalCodeFilterComponent = React.memo(
  forwardRef(({ apply, setNbSelected, index, filterInited }: State, ref) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const fiscalCodes = useAppSelector(
      (state: RootState) => state.globalState.fiscalCodes
    );
    const [searchParams] = useSearchParams();
    const getValue = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      if (searchParamsObject.hasOwnProperty("fiscalCodes[]") && fiscalCodes) {
        const fiscalCodeCode = JSON.parse(searchParamsObject["fiscalCodes[]"])
          .values[0];
        return fiscalCodes.find((t) => t.code === fiscalCodeCode) ?? null;
      }
      return null;
    }, [searchParams, fiscalCodes]);
    const [value, setValue] = React.useState<FiscalCodeInterface | null>(
      getValue()
    );
    const handleChangeAutocomplete = React.useCallback(
      (
        event: SyntheticEvent<Element, Event> | null,
        value: FiscalCodeInterface | null
      ) => {
        setValue(value);
      },
      []
    );

    React.useEffect(() => {
      setNbSelected(!value ? 0 : 1);
      if (!isSmall) {
        apply();
      }
      filterInited(index);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValue(getValue());
    }, [searchParams, fiscalCodes]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        if (value) {
          return {
            "fiscalCodes[]": JSON.stringify({
              values: fiscalCodes
                ?.filter((t) => t.code === value.code)
                ?.map((t) => t.code),
              condition: CONDITION_AND,
            }),
          };
        }
        return {};
      },
    }));

    return (
      <>
        {fiscalCodes && (
          <Autocomplete
            options={fiscalCodes ?? []}
            getOptionLabel={(option: string | FiscalCodeInterface) => {
              if (typeof option === "string") {
                return option;
              }
              return "[" + option.nb + "]" + option.code + ": " + option.name;
            }}
            isOptionEqualToValue={(
              option: FiscalCodeInterface,
              value: FiscalCodeInterface
            ) => option.code === value.code}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.code}>
                  <span style={{ color: theme.palette.primary.main }}>
                    [{option.nb}]
                  </span>
                  {option.code + ": " + option.name}
                </li>
              );
            }}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </>
    );
  })
);

export default FiscalCodeFilterComponent;
