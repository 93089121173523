import React from "react";
import Layout from "../components/common/Layout";
import { getTitle } from "../helpers/SearchParamHelper";
import TagComponent from "../components/common/tag/TagComponent";

const TagScreen: React.FC = React.memo(() => {
  React.useEffect(() => {
    document.title = getTitle("tag");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <TagComponent />
    </Layout>
  );
});

export default TagScreen;
