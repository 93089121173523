import * as React from "react";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import SearchComponent from "../SearchComponent";
import { Accordion, useTheme } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import MailTemplateFormComponent from "./MailTemplateFormComponent";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { MAIL_TEMPLATE_URL, MAIL_URL } from "../../../utils/UrlsUtils";
import { set } from "../../../app/globalSlice";
import { toastr } from "react-redux-toastr";

const MailTemplateComponent: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const mailTemplates = useAppSelector(
    (state: RootState) => state.globalState.mailTemplates
  );
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<number[]>([]);
  const [alreadyExpanded, setAlreadyExpanded] = React.useState<number[]>([]);
  const handleChange = React.useCallback(
    (prop: number) =>
      (event: SyntheticEvent<Element, Event>, expanded: boolean) => {
        if (expanded) {
          setExpanded((x) => {
            return [...x, prop];
          });
          setAlreadyExpanded((x) => {
            return [...x, prop];
          });
        } else {
          setExpanded((x) => {
            x = x.filter((y) => y !== prop);
            return x.filter((y) => y !== prop);
          });
        }
      },
    []
  );

  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const mails = useAppSelector((state: RootState) => state.globalState.mails);
  const isAdmin = useAppSelector(
    (state: RootState) => state.globalState.isAdmin
  );

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!isAdmin) {
        return;
      }
      if (!(mails !== undefined && !force)) {
        const response = await requestApi({
          method: GET,
          path: MAIL_URL,
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          dispatch(set({ mails: response.content }));
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      }
      if (!(mailTemplates !== undefined && !force)) {
        const response = await requestApi({
          method: GET,
          path: MAIL_TEMPLATE_URL,
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          dispatch(set({ mailTemplates: response.content }));
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      }

      dispatch(set({ refreshPage: false }));
    },
    [dispatch, isAdmin, mailTemplates, mails, t, token]
  );

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage, isAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!mailTemplates ? (
        <SearchComponent nbColumn={1} nbLines={1} />
      ) : (
        <>
          {mailTemplates.map((mailTemplate, indexMailTemplate) => (
            <Accordion
              key={indexMailTemplate}
              expanded={expanded.includes(indexMailTemplate)}
              onChange={handleChange(indexMailTemplate)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                sx={{ backgroundColor: theme.palette.primary.main }}
              >
                <Typography component="p" variant="h6" sx={{ color: "white" }}>
                  {t("word." + mailTemplate.identifier)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {(expanded.includes(indexMailTemplate) ||
                  alreadyExpanded.includes(indexMailTemplate)) && (
                  <MailTemplateFormComponent mailTemplate={mailTemplate} />
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </>
  );
});

export default MailTemplateComponent;
