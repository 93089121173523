import React, { forwardRef, useImperativeHandle } from "react";
import { InputInterface } from "../../../../interfaces/InputInterface";
import { CategoryInterface } from "../../../../interfaces/CategoryInterface";
import { useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface State {
  category: CategoryInterface | undefined;
}

interface FormState {
  enabled: InputInterface;
}

const FormEnabledCategoryComponent = React.memo(
  forwardRef(({ category }: State, ref) => {
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        enabled: { value: category?.enabled ?? true, error: "" },
      };
    }, [category?.enabled]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const { t } = useTranslation();

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return values.enabled.value;
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [category]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.enabled.value}
              onChange={handleChange("enabled")}
            />
          }
          label={t("word.active")}
        />
      </FormGroup>
    );
  })
);

export default FormEnabledCategoryComponent;
