import * as React from "react";
import { useImperativeHandle, useRef } from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { CATEGORY_PAGE } from "../../../utils/RouteUtils";
import { formatCategoryBreadcrumb } from "../../../helpers/CategoryFormat";
import {
  CategoryInterface,
  FilterValueInterface,
} from "../../../interfaces/CategoryInterface";
import { Skeleton, useMediaQuery, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EditFArticleBreadcrumbComponent from "./EditFArticleBreadcrumbComponent";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface State {
  fArticle: FArticleInterface | undefined;
  edit: boolean;
  setFArticle: Function;
  createFrom?: FArticleInterface;
}

const FArticleBreadcrumbComponent = React.memo(
  React.forwardRef(
    ({ fArticle, edit, setFArticle, createFrom }: State, ref) => {
      const editRef: any = useRef();
      const getCategoryGroups = React.useCallback(() => {
        return formatCategoryBreadcrumb(createFrom ?? fArticle);
      }, [createFrom, fArticle]);
      const [categoryGroups, setCategoryGroups] = React.useState<
        (CategoryInterface | FilterValueInterface)[][]
      >(getCategoryGroups());
      const [thisEdit, setThisEdit] = React.useState<boolean>(false);
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const [loading, setLoading] = React.useState(false);
      const { t } = useTranslation();
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return { categoryFArticleIds: editRef.current.getValue() };
      }, []);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            fArticleProp: getValue(),
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setCategoryGroups(getCategoryGroups());
      }, [fArticle, edit, thisEdit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (!edit) {
          setThisEdit(false);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {fArticle?.fArticleProp === undefined && !createFrom ? (
            <Skeleton variant="rectangular" height={100} width="100%" />
          ) : (
            <>
              {edit || thisEdit ? (
                <>
                  {!edit && (
                    <Box sx={{ textAlign: "center" }}>
                      <Tooltip title={t("word.cancel")} placement="left">
                        <IconButton onClick={handleThisEdit} disabled={loading}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("word.save")} placement="right">
                        <LoadingButton
                          variant="text"
                          color="inherit"
                          sx={{
                            borderRadius: "50%",
                            minWidth: "auto",
                            padding: "8px",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                          loading={loading}
                          onClick={save}
                        >
                          <SaveIcon />
                        </LoadingButton>
                      </Tooltip>
                    </Box>
                  )}
                  <EditFArticleBreadcrumbComponent
                    ref={editRef}
                    categoryGroups={categoryGroups}
                  />
                </>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {isAdmin && (
                    <IconButton onClick={handleThisEdit}>
                      <EditIcon />
                    </IconButton>
                  )}
                  <Box>
                    {categoryGroups.map((categoryGroup, indexCategoryGroup) => (
                      <Box key={indexCategoryGroup}>
                        <Breadcrumbs className="breadcrumbs">
                          <Link
                            to={CATEGORY_PAGE}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <MenuBookIcon />
                          </Link>
                          {categoryGroup[0] && (
                            <Link
                              to={
                                CATEGORY_PAGE +
                                "/" +
                                (categoryGroup[1]
                                  ? // @ts-ignore
                                    categoryGroup[1].slug
                                  : // @ts-ignore
                                    categoryGroup[0].slug)
                              }
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {categoryGroup[0].name}
                            </Link>
                          )}
                          {categoryGroup[1] && (
                            <Link
                              // @ts-ignore
                              to={CATEGORY_PAGE + "/" + categoryGroup[1].slug}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {categoryGroup[1].name}
                            </Link>
                          )}
                          {categoryGroup[2] && (
                            <Link
                              // @ts-ignore
                              to={
                                CATEGORY_PAGE +
                                "/" +
                                // @ts-ignore
                                categoryGroup[1].slug +
                                objectToQuery({
                                  "filterValues[]": [
                                    JSON.stringify({
                                      condition:
                                        // @ts-ignore
                                        categoryGroup[2].filter.condition,
                                      // @ts-ignore
                                      values: [categoryGroup[2].id],
                                    }),
                                  ],
                                })
                              }
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {categoryGroup[2].name}
                            </Link>
                          )}
                        </Breadcrumbs>
                        {isSmall && <Divider sx={{ marginY: 0 }} />}
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleBreadcrumbComponent;
