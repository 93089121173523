import React, { useImperativeHandle } from "react";
import {
  FilterFilterInterface,
  FilterFilterValueFormInterface,
  FilterFilterValueInterface,
} from "../../../../../../interfaces/FilterInterface";
import { FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { FormStateFilterForm } from "./FilterFormComponent";

interface State {
  filter?: FilterFilterInterface | null;
  filterValue: FilterFilterValueInterface;
  setValues: Function;
}

const FilterFormParentComponent = React.memo(
  React.forwardRef(({ filter, filterValue, setValues }: State, ref) => {
    const [parent, setParent] = React.useState(
      filterValue.parent?.id.toString() ?? ""
    );
    const handleChangeSelect = React.useCallback(
      (event: SelectChangeEvent) => {
        setValues((x: FormStateFilterForm) => {
          const thisFilterValue = x.filterValues.value.find(
            (y: FilterFilterValueFormInterface) => y.refParent === ref
          );
          if (event.target.value !== "") {
            thisFilterValue.parent = {
              id: Number(event.target.value),
            };
          } else {
            thisFilterValue.parent = null;
          }
          return { ...x };
        });
        setParent(event.target.value as string);
      },
      [ref, setValues]
    );
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      getValue() {
        if (parent === "") {
          return null;
        }
        return parent;
      },
    }));

    return (
      <>
        <FormControl fullWidth>
          <Select labelId="parent" value={parent} onChange={handleChangeSelect}>
            <MenuItem value="">
              <em>{t("word.none")}</em>
            </MenuItem>
            {filter?.filterValues
              .filter((f) => f.id !== filterValue.id)
              .map((f, indexF) => (
                <MenuItem value={f.id?.toString()} key={indexF}>
                  {"[" + f.id + "] " + f.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </>
    );
  })
);

export default FilterFormParentComponent;
