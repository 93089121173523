import React from "react";

interface State {
  query: string;
  height?: number;
}

// https://developers.google.com/maps/documentation/embed/embedding-map
// https://developers.google.com/maps/documentation/embed/get-api-key
// https://console.cloud.google.com/google/maps-apis/api-list?project=nove971
// https://mapsplatform.google.com/intl/fr/pricing/
// todo sécuriser l'API
const MapDisplayComponent: React.FC<State> = React.memo(({ query, height }) => {
  query = query?.trim().replace(" ", "-") ?? "guadeloupe";
  const iframe =
    `<iframe
      width="100%"
      height="` +
    (height ? height : 500) +
    `px"
      style="border:0"
      referrerPolicy="no-referrer-when-downgrade"
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCvVdpOPVonQrKMeFMXjtHQ4h913oYEOxU&q=` +
    query +
    `"
      allowFullScreen
    ></iframe>`;

  return <>{query && <div dangerouslySetInnerHTML={{ __html: iframe }} />}</>;
});

export default MapDisplayComponent;
