import * as React from "react";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { CART_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set, setCart } from "../../../app/globalSlice";
import getErrorApi from "../../../helpers/GetErrorApi";

const clone = require("clone");

interface State {
  fDocentete: FDocenteteInterface;
}

const FDocenteteOrderAgainComponent: React.FC<State> = React.memo(
  ({ fDocentete }) => {
    const cart = useAppSelector((state: RootState) => state.globalState.cart);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);

    const updateCartApi = React.useCallback(async () => {
      if (!cart) {
        return;
      }
      const newCartFArticles: any[] = clone(cart.cartFArticles);
      if (fDocentete?.fDoclignesPaginate) {
        for (const fDocligne of fDocentete.fDoclignesPaginate) {
          let cartFArticle: any = newCartFArticles.find(
            (c) => c.fArticle?.arRef === fDocligne.arRef
          );
          if (!cartFArticle) {
            cartFArticle = {
              quantity: 0,
              fArticleId: fDocligne.arRef,
            };
            newCartFArticles.push(cartFArticle);
          }
          cartFArticle.quantity += Number(fDocligne.dlQte);
        }
      }
      const body: any = {
        cartFArticles: newCartFArticles.map((cartFArticle) => {
          if (cartFArticle.id) {
            return {
              quantity: cartFArticle.quantity,
              oldId: cartFArticle.id,
            };
          }
          return {
            quantity: cartFArticle.quantity,
            fArticleId: cartFArticle.fArticleId,
          };
        }),
      };
      setLoading(true);
      const response = await requestApi({
        method: PATCH,
        path: CART_URL + "/" + cart.id,
        allowError: true,
        token: token,
        body: body,
      });
      if (response.statusCode === 200) {
        dispatch(setCart(response.content));
        dispatch(set({ openCart: true }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [cart, dispatch, fDocentete.fDoclignesPaginate, t, token]);

    return (
      <>
        <LoadingButton
          variant="contained"
          sx={{ mt: 1 }}
          loading={loading}
          onClick={updateCartApi}
        >
          {t("word.orderAgain")}
        </LoadingButton>
      </>
    );
  }
);

export default FDocenteteOrderAgainComponent;
