import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleInterface,
  FArticleSmallInterface,
} from "../../../interfaces/FArticleInterface";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { TextField, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { defaultFontSize } from "../category/ConfigureViewProductsComponent";
import { DEFAULT_PERCENT_ACOMPTE } from "../../../utils/FArticleUtils";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  inline?: boolean;
  edit?: boolean;
  setFArticle?: Function;
  fontSize?: number;
  createFrom?: FArticleInterface;
}

interface FormState {
  percentAcompte: InputInterface;
  hasAcompte: InputInterface;
}

export const PercentAcompteFArticleComponent = React.memo(
  React.forwardRef(
    (
      { fArticle, inline, edit, setFArticle, fontSize, createFrom }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const getDefaultValue = React.useCallback((): FormState => {
        return {
          percentAcompte: {
            value:
              (createFrom ?? fArticle)?.fArticleProp?.percentAcompte ??
              DEFAULT_PERCENT_ACOMPTE * 100,
            error: "",
          },
          hasAcompte: {
            value: !!(createFrom ?? fArticle)?.fArticleProp?.hasAcompte ?? true,
            error: "",
          },
        };
      }, [createFrom, fArticle]);
      const [values, setValues] = React.useState<FormState>(getDefaultValue());
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback((): any => {
        return {
          hasAcompte: values.hasAcompte.value,
          percentAcompte: parseInt(values.percentAcompte.value),
        };
      }, [values.hasAcompte.value, values.percentAcompte.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            fArticleProp: {
              ...getValue(),
            },
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          if (setFArticle) {
            setFArticle(response.content);
          }
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChangeCheckbox = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.checked, error: "" },
              };
            });
          },
        []
      );

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValue());
      }, [
        fArticle?.fArticleProp?.percentAcompte,
        createFrom?.fArticleProp?.percentAcompte,
        thisEdit,
        getDefaultValue,
      ]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {(fArticle || createFrom) && isAdmin && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {edit !== undefined && !(edit || thisEdit) && (
                <Box>
                  <IconButton onClick={handleThisEdit}>
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
              <Box
                sx={{
                  flex: 1,
                  ...(inline && {
                    display: "flex",
                    justifyContent: "space-around",
                  }),
                }}
              >
                {edit || thisEdit ? (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.hasAcompte.value}
                          onChange={handleChangeCheckbox("hasAcompte")}
                        />
                      }
                      label={t("word.hasAcompte")}
                    />
                    <TextField
                      fullWidth={true}
                      autoComplete="off"
                      error={!!values.percentAcompte.error}
                      helperText={t(values.percentAcompte.error ?? "")}
                      sx={{ width: "100%", marginTop: 1 }}
                      required
                      type="number"
                      value={values.percentAcompte.value}
                      onChange={handleChange("percentAcompte")}
                      label={t("word.percentAcompte")}
                    />
                    {!edit && (
                      <>
                        <Tooltip title={t("word.cancel")}>
                          <IconButton
                            onClick={handleThisEdit}
                            disabled={loading}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("word.save")}>
                          <LoadingButton
                            variant="text"
                            color="inherit"
                            sx={{
                              borderRadius: "50%",
                              minWidth: "auto",
                              padding: "8px",
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                            loading={loading}
                            onClick={save}
                          >
                            <SaveIcon />
                          </LoadingButton>
                        </Tooltip>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                        display: "flex",
                      }}
                    >
                      {(createFrom ?? fArticle)?.fArticleProp?.hasAcompte ? (
                        <DoneIcon color="success" />
                      ) : (
                        <CloseIcon color="error" />
                      )}
                      {t("word.percentAcompte") +
                        ": " +
                        ((createFrom ?? fArticle)?.fArticleProp
                          ?.percentAcompte ?? DEFAULT_PERCENT_ACOMPTE * 100) +
                        "%"}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          )}
        </>
      );
    }
  )
);
