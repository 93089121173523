import * as React from "react";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.body2,
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

interface State {
  content: React.ReactNode;
  sxRoot?: SxProps<Theme>;
  sxDivider?: SxProps<Theme>;
}

const DividerWithContent: React.FC<State> = React.memo(
  ({ content, sxRoot, sxDivider }) => {
    return (
      <Root sx={sxRoot}>
        <Divider sx={sxDivider}>{content}</Divider>
      </Root>
    );
  }
);

export default DividerWithContent;
