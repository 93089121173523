import React from "react";
import { FormControlLabel } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { ALL_STATE, ALL_STATE_PRODUCT } from "../../../../utils/SavUtils";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";

interface State {
  init: boolean;
}

const SavFilterStateComponent: React.FC<State> = React.memo(({ init }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [stateChecked, setStateChecked] = React.useState<string[]>(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (searchParamsObject.hasOwnProperty("state[]")) {
      return searchParamsObject["state[]"];
    }
    return [];
  });
  const { t } = useTranslation();

  const handleChangeCheckbox = React.useCallback(
    (state: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setStateChecked((x) => {
        if (event.target.checked) {
          x = [...x, state];
        } else {
          x = x.filter((y) => y !== state);
        }
        return x;
      });
    },
    []
  );

  React.useEffect(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    let savState = [];
    let productState = [];
    if (stateChecked.length > 0) {
      savState = stateChecked.filter((v) => ALL_STATE.includes(v));
      productState = stateChecked.filter((v) => ALL_STATE_PRODUCT.includes(v));
      if (savState.length > 0) {
        searchParamsObject["state[]"] = savState;
      }
      if (productState.length > 0) {
        searchParamsObject["stateProduct[]"] = productState;
      }
    }
    if (searchParamsObject.hasOwnProperty("state[]") && savState.length === 0) {
      delete searchParamsObject["state[]"];
    }
    if (
      searchParamsObject.hasOwnProperty("stateProduct[]") &&
      productState.length === 0
    ) {
      delete searchParamsObject["stateProduct[]"];
    }
    if (init) {
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    }
  }, [stateChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormGroup sx={{ flexDirection: "row" }}>
      {[...ALL_STATE, ...ALL_STATE_PRODUCT].map((state, indexState) => (
        <FormControlLabel
          key={indexState}
          control={
            <Checkbox
              checked={stateChecked.includes(state)}
              onChange={handleChangeCheckbox(state)}
            />
          }
          label={t("word.sav.state." + state)}
        />
      ))}
    </FormGroup>
  );
});

export default SavFilterStateComponent;
