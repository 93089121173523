import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { TagInterface } from "../../../interfaces/TagInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL, TAG_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  edit?: boolean;
  setFArticle?: Function;
}

interface FormState {
  tags: InputInterface;
}

const FArticleCustomTagComponent = React.memo(
  React.forwardRef(({ fArticle, edit, setFArticle }: State, ref) => {
    const { t } = useTranslation();
    const [thisEdit, setThisEdit] = React.useState<boolean>(false);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const getValues = React.useCallback(() => {
      return {
        tags: { value: fArticle?.fArticleProp?.tags ?? [], error: "" },
      };
    }, [fArticle?.fArticleProp?.tags]);
    const [values, setValues] = React.useState<FormState>(getValues());
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [searchTag, setSearchTag] = React.useState<string>("");
    const [optionsTags, setOptionsTags] = React.useState<TagInterface[]>([]);
    const [isTyping, setIsTyping] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const [isSearching, setIsSearching] = React.useState<boolean>(false);

    const handleSearchTag = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: string) => {
        setSearchTag(value);
        setOptionsTags([]);
        setIsTyping(true);
      },
      []
    );

    const handleThisEdit = React.useCallback(() => {
      setThisEdit((x) => !x);
    }, []);

    const getValue = React.useCallback(() => {
      return {
        tags: values.tags.value.map(
          (thisTag: TagInterface) => TAG_URL + "/" + thisTag.id
        ),
      };
    }, [values.tags.value]);

    const save = React.useCallback(async () => {
      setLoading(true);
      const fArticleApi = new FormData();
      fArticleApi.append(
        "json",
        JSON.stringify({
          arRef: fArticle?.arRef,
          fArticleProp: getValue(),
        })
      );
      const response = await requestApi({
        method: POST,
        path: FARTICLE_URL,
        allowError: true,
        timeout: 30_000,
        token: token,
        body: fArticleApi,
        formData: true,
      });
      if (response.statusCode === 201) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.tag_updated")
        );
        if (setFArticle) {
          setFArticle(response.content);
        }
        setThisEdit(false);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [fArticle?.arRef, getValue, setFArticle, t, token]);

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const searchTags = React.useCallback(async () => {
      if (!edit && !thisEdit) {
        return;
      }
      setIsTyping(false);
      setIsSearching(true);
      const param: any = {
        text: searchTag,
      };
      const response = await requestApi({
        method: GET,
        path: TAG_URL + objectToQuery(param),
        allowError: false,
        paginate: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setOptionsTags(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setIsSearching(false);
    }, [edit, searchTag, t, thisEdit, token]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchTags();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [searchTag, edit, thisEdit]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValues(getValues());
    }, [fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {edit || thisEdit ? (
          <>
            <Autocomplete
              multiple
              loadingText={t("word.loading") + "..."}
              inputValue={searchTag}
              onInputChange={handleSearchTag}
              noOptionsText={
                isSearching ? (
                  <Box sx={{ textAlign: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : searchTag !== "" && !isTyping ? (
                  t("sentence.no_option")
                ) : (
                  t("word.sav.searchTag.label")
                )
              }
              getOptionDisabled={(option) => !!option.custom}
              options={optionsTags}
              getOptionLabel={(thisTag: TagInterface) => {
                return thisTag.text ?? "";
              }}
              isOptionEqualToValue={(
                option: TagInterface,
                value: TagInterface
              ) => {
                return option.id === value.id;
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.text}
                  </li>
                );
              }}
              onChange={handleChangeAutocomplete("tags")}
              value={values.tags.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="off"
                  error={!!values.tags.error}
                  helperText={t(values.tags.error ?? "")}
                  label={t("title.tag")}
                  placeholder={t("title.tag")}
                />
              )}
            />
            {!edit && (
              <Box sx={{ textAlign: "center" }}>
                <Tooltip title={t("word.cancel")} placement="left">
                  <IconButton onClick={handleThisEdit} disabled={loading}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("word.save")} placement="right">
                  <LoadingButton
                    variant="text"
                    color="inherit"
                    sx={{
                      borderRadius: "50%",
                      minWidth: "auto",
                      padding: "8px",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                    loading={loading}
                    onClick={save}
                  >
                    <SaveIcon />
                  </LoadingButton>
                </Tooltip>
              </Box>
            )}
          </>
        ) : (
          <>
            {setFArticle && isAdmin && (
              <IconButton onClick={handleThisEdit}>
                <EditIcon />
              </IconButton>
            )}
            {fArticle?.fArticleProp?.hasOption && (
              <Chip
                color="info"
                label={t("word.hasOption")}
                size="small"
                sx={{ cursor: "pointer" }}
              />
            )}
            {fArticle?.fArticleProp?.new && (
              <Chip
                color="success"
                label={t("word.new")}
                size="small"
                sx={{ cursor: "pointer" }}
              />
            )}
            {fArticle?.priceDto?.fArtClientAcRemise !== undefined &&
              fArticle?.priceDto?.fArtClientAcRemise !== null &&
              fArticle.priceDto.fArtClientAcRemise > 0 && (
                <Chip
                  color="error"
                  label={t("word.promotion")}
                  size="small"
                  sx={{ cursor: "pointer" }}
                />
              )}
            {fArticle?.fArticleProp?.tags?.map((tag, indexTag) => (
              <Chip
                key={indexTag}
                label={tag.text}
                size="small"
                sx={{
                  cursor: "pointer",
                  backgroundColor: tag.hex,
                  color: tag.textColorBlack ? "black" : "white",
                }}
              />
            ))}
          </>
        )}
      </>
    );
  })
);

export default FArticleCustomTagComponent;
