import React, { useImperativeHandle, useRef } from "react";
import Layout from "../../components/common/Layout";
import LoginComponent from "../../components/common/user/LoginComponent";
import { getTitle } from "../../helpers/SearchParamHelper";
import EntreeStockArrivageComponent from "../../components/common/entreeStock/EntreeStockArrivageComponent";
import { useParams } from "react-router-dom";
import { ArrivageInterface } from "../../interfaces/ArrivageInterface";

const EntreeStockScreen = React.memo(
  React.forwardRef((props, ref) => {
    const refArrivage: any = useRef();
    const params = useParams();
    const mainRef: any = useRef();

    const focusMainSearch = React.useCallback((e: any) => {
      refArrivage.current?.focusMainSearch(e);
    }, []);

    useImperativeHandle(ref, () => ({
      setArrivage(arrivage: ArrivageInterface) {
        refArrivage.current?.setArrivage(arrivage);
      },
      arrivageDone(data: any) {
        if (data.arrivageId.toString() === params.id?.toString()) {
          refArrivage.current?.arrivageDone(data);
        }
      },
    }));

    React.useEffect(() => {
      document.title = getTitle("entreeStock");
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Layout
        onClickMain={focusMainSearch}
        sx={{ overflow: "hidden" }}
        mainRef={mainRef}
      >
        <LoginComponent redirect={null} requireAdmin={true}>
          <EntreeStockArrivageComponent
            ref={refArrivage}
            arrivageId={params.id}
            mainRef={mainRef}
          />
        </LoginComponent>
      </Layout>
    );
  })
);

export default EntreeStockScreen;
