import * as React from "react";
import { DialogProps } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

interface State {
  openDialog: boolean;
  setOpenDialog: Function;
  onYes: Function;
  children: React.ReactNode;
}

const DialogConfirmComponent: React.FC<State> = React.memo(
  ({ openDialog, setOpenDialog, onYes, children }) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [loading, setLoading] = React.useState(false);
    const handleClose = React.useCallback(() => {
      if (loading) {
        return;
      }
      setOpenDialog(false);
    }, [loading, setOpenDialog]);
    const { t } = useTranslation();

    const confirm = React.useCallback(() => {
      setLoading(true);
      onYes();
    }, [onYes]);

    const cancel = React.useCallback(() => {
      setOpenDialog(false);
    }, [setOpenDialog]);

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleClose}
          open={openDialog}
        >
          <DialogContent>{children}</DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <LoadingButton disabled={loading} onClick={cancel}>
              {t("word.no")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={confirm}
            >
              {t("word.yes")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default DialogConfirmComponent;
