import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { TIME_RESEND_MAIL } from "../../utils/StorageUtils";
import { requestApi } from "../../helpers/RequestApi";
import { GET } from "../../utils/MethodUtils";
import { MAIL_CONFIRMATION_URL, USER_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { Grid, TextField } from "@mui/material";
import { InputInterface } from "../../interfaces/InputInterface";
import { set, setUser } from "../../app/globalSlice";
import { formatCkEditor } from "../../helpers/CkEditorHelper";

interface FormState {
  code: InputInterface;
}

const ValidateMailComponent: React.FC = React.memo(() => {
  const mailConfirmation = useAppSelector(
    (state: RootState) => state.globalState.mailConfirmation
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const [loading, setLoading] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [timeResendMail, setTimeResendMail] = React.useState<number>(() => {
    let time = localStorage.getItem(TIME_RESEND_MAIL);
    if (time) {
      return parseInt(time);
    }
    return Date.now();
  });
  const [displayTime, setDisplayTime] = React.useState<number>(() => {
    return Math.round((timeResendMail - Date.now()) / 1000);
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getDefaultValues = React.useCallback((): FormState => {
    return {
      code: {
        value: "",
        error: "",
      },
    };
  }, []);
  const [values, setValues] = React.useState<FormState>(getDefaultValues());

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const resendMail = React.useCallback(async () => {
    setLoading(true);
    const newTime = Date.now() + 60_000;
    setTimeResendMail(newTime);
    localStorage.setItem(TIME_RESEND_MAIL, newTime.toString());
    const response = await requestApi({
      method: GET,
      path: MAIL_CONFIRMATION_URL + "/" + mailConfirmation?.id + "/resend",
      allowError: true,
      token: token,
    });
    if (response.statusCode === 200) {
      toastr.success(t("word.success"), t("sentence.notification.mail_sent"));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
    setLoading(false);
  }, [mailConfirmation?.id, t, token]);

  const updateTime = React.useCallback(() => {
    setDisplayTime(Math.round((timeResendMail - Date.now()) / 1000));
  }, [timeResendMail]);

  const save = React.useCallback(async () => {
    setLoadingSave(true);
    const response = await requestApi({
      method: GET,
      path:
        USER_URL +
        "/" +
        user?.userIdentifier +
        "/activate/" +
        values.code.value +
        "?redirect=false",
      allowError: true,
      token: token,
    });
    if (response.statusCode === 200) {
      if (user && mailConfirmation) {
        dispatch(
          setUser({
            ...user,
            ctEmail: mailConfirmation.newMail,
          })
        );
      }
      dispatch(set({ mailConfirmation: undefined }));
      toastr.success(t("word.success"), t("sentence.mailValidated"));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
    setLoadingSave(false);
  }, [dispatch, mailConfirmation, t, token, user, values.code.value]);

  React.useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timeResendMail]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setValues(getDefaultValues());
    if (mailConfirmation && mailConfirmation.required) {
      const newUser: any = { ...user };
      newUser.ctEmail = mailConfirmation.newMail;
      dispatch(set({ user: newUser }));
    }
  }, [mailConfirmation?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {mailConfirmation && (
        <>
          {mailConfirmation.message ? (
            <div
              dangerouslySetInnerHTML={{
                __html: formatCkEditor(mailConfirmation.message),
              }}
            />
          ) : (
            <Typography sx={{ wordBreak: "break-word" }}>
              {t("sentence.mailConfirmationNeeded").replace(
                "{mail}",
                mailConfirmation.newMail
              )}
            </Typography>
          )}
          <LoadingButton
            sx={{ wordBreak: "break-word", paddingX: 1, paddingY: 0 }}
            disabled={displayTime > 0}
            variant="contained"
            loading={loading}
            onClick={resendMail}
          >
            {displayTime > 0
              ? t("word.cannotResendMail").replace(
                  "{s}",
                  displayTime.toString()
                )
              : t("word.resendMail")}
          </LoadingButton>
          <Grid
            container
            spacing={1}
            sx={{ alignItems: "center", marginTop: 1 }}
          >
            <Grid item xs={12} md={10}>
              <TextField
                fullWidth={true}
                autoComplete="off"
                error={!!values.code.error}
                helperText={t(values.code.error ?? "")}
                sx={{ width: "100%" }}
                required
                type="text"
                value={values.code.value}
                onChange={handleChange("code")}
                label={t("field.codeMail")}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <LoadingButton
                variant="contained"
                loading={loadingSave}
                onClick={save}
              >
                {t("word.validate")}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Typography>{t("sentence.explainEditMail")}</Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
});

export default ValidateMailComponent;
