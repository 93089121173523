import * as React from "react";
import { useImperativeHandle } from "react";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import MuiInput from "@mui/material/Input";
import Box from "@mui/material/Box";

const Input = styled(MuiInput)`
  width: 62px;
`;

interface State {
  minAvoir?: number;
  defaultAvoir?: number;
  maxAvoir: number;
  setParentValue?: Function;
  maxWidth?: number;
}

const FDocenteteSelectAvoirComponent = React.memo(
  React.forwardRef(
    (
      { maxAvoir, minAvoir, defaultAvoir, setParentValue, maxWidth }: State,
      ref
    ) => {
      const accountingSituation = useAppSelector(
        (state: RootState) => state.globalState.accountingSituation
      );
      const getDefaultValue = React.useCallback(() => {
        return ((defaultAvoir ?? maxAvoir) / 100).toString();
      }, [defaultAvoir, maxAvoir]);
      const [value, setValue] = React.useState<string>(getDefaultValue());

      const handleSliderChange = (
        event: Event,
        newValue: number | number[]
      ) => {
        setValue(Number(newValue).toString());
      };

      const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        setValue(event.target.value);
      };

      const handleBlur = () => {
        if (Number(value) < (minAvoir ? minAvoir / 100 : 0)) {
          setValue((minAvoir ? minAvoir / 100 : 0).toString());
        } else if (Number(value) > maxAvoir / 100) {
          setValue((maxAvoir / 100).toString());
        } else {
          setValue((Math.round(Number(value) * 100) / 100).toString());
        }
      };

      const getValue = () => {
        if (isNaN(Number(value))) {
          return value;
        }
        if (Number(value) > maxAvoir / 100) {
          return (maxAvoir / 100).toString();
        }
        return value;
      };

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setValue(getValue());
      }, [accountingSituation?.sumCreditFdocentetes]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (setParentValue) {
          if ((minAvoir ?? 0) > Number(value) * 100) {
            setParentValue(minAvoir ? minAvoir : 0);
          } else if (maxAvoir > Number(value) * 100) {
            setParentValue(Math.round(parseFloat(value) * 100));
          } else {
            setParentValue(maxAvoir);
          }
        }
      }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          <Box
            sx={{
              ...(maxWidth && {
                maxWidth: maxWidth + "px",
              }),
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  step={0.01}
                  min={minAvoir ? minAvoir / 100 : 0}
                  max={maxAvoir / 100}
                  value={
                    isNaN(Number(value))
                      ? minAvoir
                        ? minAvoir / 100
                        : 0
                      : Number(value)
                  }
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                />
              </Grid>
              <Grid item>
                <Input
                  value={value}
                  size="small"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 0.01,
                    min: minAvoir ? minAvoir / 100 : 0,
                    max: maxAvoir / 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      );
    }
  )
);

export default FDocenteteSelectAvoirComponent;
