import React, { useImperativeHandle, useRef } from "react";
import { FormControl, Grid, InputLabel, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import { SavInterface } from "../../../interfaces/SavInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  ALL_ORIGIN,
  ORIGIN_NOVE,
  ORIGIN_OUTSIDE,
} from "../../../utils/SavUtils";
import SavOriginNoveFormComponent from "./SavOriginNoveFormComponent";
import SavOutsideNoveFormComponent from "./SavOutsideNoveFormComponent";
import { UserInterface } from "../../../interfaces/UserInterface";

interface State {
  sav?: SavInterface | null;
  userForm: UserInterface | undefined;
  setSav: Function;
}

interface FormState {
  origin: InputInterface;
}

const SavFormProductComponent = React.memo(
  React.forwardRef(({ sav, setSav, userForm }: State, ref) => {
    const { t } = useTranslation();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const originRef: any = useRef();
    const getDefaultValue = React.useCallback((): FormState => {
      return {
        origin: {
          value: sav ? sav.origin : ORIGIN_NOVE,
          error: "",
        },
      };
    }, [sav]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        const result = originRef.current.getValue();
        if (!result) {
          return undefined;
        }
        return { ...result, ...{ origin: values.origin.value } };
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [user, sav]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="product-origin">{t("field.origin")}</InputLabel>
            <Select
              disabled={!!sav?.id}
              labelId="product-origin"
              value={values.origin.value}
              label={t("field.origin")}
              onChange={handleChangeSelect("origin")}
            >
              {ALL_ORIGIN.map((origin, indexOrigin) => (
                <MenuItem value={origin} key={indexOrigin}>
                  {t("word.sav.origin." + origin)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {values.origin.value === ORIGIN_NOVE ? (
          <SavOriginNoveFormComponent
            setSav={setSav}
            sav={sav}
            ref={originRef}
            userForm={userForm}
          />
        ) : (
          values.origin.value === ORIGIN_OUTSIDE && (
            <SavOutsideNoveFormComponent sav={sav} ref={originRef} />
          )
        )}
      </>
    );
  })
);

export default SavFormProductComponent;
