import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { objectToQuery, requestApi } from "../../../../helpers/RequestApi";
import { GET } from "../../../../utils/MethodUtils";
import { FDOCENTETES_URL } from "../../../../utils/UrlsUtils";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import {
  SAGE_DO_TYPE_VENTE_BC,
  SAGE_DO_TYPE_VENTE_BL,
  SAGE_DO_TYPE_VENTE_PL,
} from "../../../../utils/DoceneteteUtils";
import getErrorApi from "../../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { CircularProgress, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FArticleSmallComponent } from "../../fArticle/FArticleSmallComponent";
import i18n from "../../../../languagues/i18n";
import { OrderInterface } from "../../../../interfaces/OrderInterface";
import { SmallFDocenteteInterface } from "../../../../interfaces/FDocenteteInterface";
import { FAMILLE_SERVICE } from "../../../../utils/FArticleUtils";
import { set } from "../../../../app/globalSlice";

interface Props {
  order: OrderInterface;
  handleNext: any;
}

const StepperDoublonOrderComponent: React.FC<Props> = React.memo(
  ({ order, handleNext }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const [docentetes, setDocentetes] = React.useState<
      SmallFDocenteteInterface[] | undefined
    >(undefined);
    const dispatch = useAppDispatch();

    const getDoublon = React.useCallback(async () => {
      const param: any = {
        "doType[]": [
          SAGE_DO_TYPE_VENTE_BC,
          SAGE_DO_TYPE_VENTE_BL,
          SAGE_DO_TYPE_VENTE_PL,
        ],
        "fDoclignes.ctNum": user?.userIdentifier,
        "fDoclignes.fArticle[]": order.orderFArticles
          .filter(
            (orderFArticle) =>
              orderFArticle.fArticle.faCodefamille !== FAMILLE_SERVICE
          )
          .map((orderFArticle) => orderFArticle.fArticle.arRef),
      };
      const response = await requestApi({
        method: GET,
        path: FDOCENTETES_URL + objectToQuery(param),
        allowError: false,
        paginate: true,
        token: token,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        const newDoublons = response.content.fDocentetes;
        if (newDoublons.length === 0) {
          handleNext();
        }
        setDocentetes(newDoublons);
        dispatch(
          set({ accountingSituation: response.content.accountingSituation })
        );
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [
      dispatch,
      handleNext,
      order.orderFArticles,
      t,
      token,
      user?.userIdentifier,
    ]);

    React.useEffect(() => {
      getDoublon();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {docentetes === undefined ? (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {docentetes.length === 0 ? (
              <Typography>{t("sentence.noDoublon")}</Typography>
            ) : (
              <>
                <Typography>
                  {t("sentence.doublon")
                    .replace("%quantity%", docentetes.length.toString())
                    .replaceAll("%s%", docentetes.length === 1 ? "" : "s")}
                </Typography>
                {docentetes.map((docentete, indexDocentete) => {
                  const fDoclignes =
                    docentete.fDoclignesPaginate?.filter(
                      (fDocligne) =>
                        order.orderFArticles.find(
                          (orderFArticle) =>
                            orderFArticle.fArticle.arRef === fDocligne.arRef
                        ) !== undefined
                    ) ?? [];
                  return (
                    <Accordion key={indexDocentete}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        sx={{ backgroundColor: theme.palette.primary.main }}
                      >
                        <Typography sx={{ color: "white" }}>
                          {docentete.doPiece +
                            ": " +
                            new Date(docentete.doDate).toLocaleString(
                              i18n.language,
                              {
                                dateStyle: "long",
                                timeZone: "Etc/UTC",
                              }
                            ) +
                            " (" +
                            fDoclignes.length +
                            " " +
                            t("word.similarProducts")
                              .replaceAll(
                                "%s%",
                                fDoclignes.length > 1 ? "s" : ""
                              )
                              .toLowerCase() +
                            ")"}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {t("word.similarProducts").replaceAll(
                            "%s%",
                            fDoclignes.length > 1 ? "s" : ""
                          )}
                          :
                        </Typography>
                        {fDoclignes.map((fDocligne, indexFDocligne) => {
                          const fArticle = order.orderFArticles.find(
                            (orderFArticle) =>
                              orderFArticle.fArticle.arRef === fDocligne.arRef
                          )?.fArticle;
                          if (fArticle) {
                            return (
                              <FArticleSmallComponent
                                key={indexFDocligne}
                                fArticles={[fArticle]}
                                nbColumn={1}
                                inline={true}
                                showAddCart={false}
                                slider={false}
                              />
                            );
                          }
                          return <Box key={indexFDocligne} />;
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </>
            )}
            <Box>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
              >
                {t("order.step.notDoublon")}
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  }
);

export default StepperDoublonOrderComponent;
