import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { ContactInterface } from "../../../interfaces/ContactInterface";
import { useTranslation } from "react-i18next";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { INVOICE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { Box, Container } from "@mui/material";
import LoginComponent from "../../../components/common/user/LoginComponent";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { Link, useSearchParams } from "react-router-dom";
import { InvoiceInterface } from "../../../interfaces/EdiInterface";
import IconButton from "@mui/material/IconButton";
import { ADMIN_INVOICE_PAGE } from "../../../utils/RouteUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const EdiComponent: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const componentRef = useRef();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [initId, setInitId] = React.useState(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return searchParamsObject.id;
  });
  const [defaultItemsPerPage] = React.useState(50);
  const [invoices, setInvoices] = React.useState<
    InvoiceInterface[] | undefined
  >(undefined);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const [totalItems, setTotalItems] = React.useState(0);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);

  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "userIdentifier",
        headerName: t("column.userIdentifier"),
        flex: 0,
        headerClassName: "background-nove",
      },
      {
        field: "invoiceId",
        headerName: t("column.invoiceId"),
        flex: 0,
        headerClassName: "background-nove",
      },
      {
        field: "errors",
        headerName: t("word.error"),
        flex: 1,
        headerClassName: "background-nove",
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.row.errors && params.row.errors.length > 0 && (
              <ul>
                {params.row.errors.map((error: string, indexError: number) => (
                  <li key={indexError}>{error}</li>
                ))}
              </ul>
            )}
          </>
        ),
      },
      {
        field: "isNew",
        headerName: t("column.isNew"),
        flex: 0,
        headerClassName: "background-nove",
        renderCell: (params: GridRenderCellParams) =>
          params.row.isNew ? (
            <DoneIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          ),
      },
      {
        field: "created",
        headerName: t("column.created"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.row.created).toLocaleString(i18n.language, {
            dateStyle: "long",
            timeStyle: "medium",
            timeZone: "America/Cuiaba",
          }),
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <Link
              to={ADMIN_INVOICE_PAGE + "/" + params.row.id}
              onClick={() => {
                const newSearchParams = searchParamToObject(searchParams);
                newSearchParams.id = params.row.id;
                setSearchParams(newSearchParams, {
                  replace: true,
                });
              }}
            >
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </>
        ),
      },
    ];
  }, [i18n.language, searchParams, setSearchParams, t]);
  const [columns, setColumns] = React.useState<GridColDef[]>(getColumns());

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (invoices !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        setTimeout(() => {
          dispatch(set({ refreshPage: true }));
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: INVOICE_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content.invoices.map((o: ContactInterface) => o.id)
        );
        const newInvoices = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content.invoices,
        ];
        setInvoices(newInvoices);
        setTotalItems(response.content.totalItems);
        dispatch(set({ newEdi: response.content.newEdi }));
        if (initId) {
          setSelectionModel([initId]);
          setInitId(undefined);
        }
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      initId,
      invoices,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setInvoices(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setInvoices(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [init, refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Box ref={componentRef}>
          <Container sx={{ marginY: 2 }}>
            {searchParamsObject.itemsPerPage && searchParamsObject.page && (
              // https://mui.com/x/react-data-grid/components/#pagination
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: getSortModel(),
                  },
                }}
                loading={refreshPage}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                rows={refreshPage ? [] : invoices ?? []}
                onSortModelChange={onSortModelChange}
                sortingMode="server"
                page={Number(searchParamsObject.page) - 1}
                rowsPerPageOptions={[10, 25, 50]}
                pageSize={Number(searchParamsObject.itemsPerPage)}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                rowCount={totalItems}
                columns={columns}
                autoHeight={true}
                disableExtendRowFullWidth={true}
                localeText={getLocaleDataGrid(i18n.language)}
              />
            )}
          </Container>
        </Box>
      </LoginComponent>
    </>
  );
});

export default EdiComponent;
