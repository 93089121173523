import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleInterface,
  FArticlePropFilterFilterValueFormInterface,
  FArticlePropFilterFormInterface,
} from "../../../../../../interfaces/FArticleInterface";
import {
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  TextField,
  useTheme,
} from "@mui/material";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import Box from "@mui/material/Box";
import FormFArticlePropFilterValueComponent from "./FormFArticlePropFilterValueComponent";
import {
  TYPE_CHECKBOX,
  TYPE_NUMERIC,
  TYPE_RADIO,
} from "../../../../../../utils/FilterUtils";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

interface State {
  fArticle: FArticleInterface;
  fArticlePropFormFilter: FArticlePropFilterFormInterface;
}

interface State2 {
  filterValues: FArticlePropFilterFilterValueFormInterface[];
  type: string;
  fArticle: FArticleInterface;
  unCheckParent: Function;
  parent?: FArticlePropFilterFilterValueFormInterface;
}

interface FormState {
  relevant: InputInterface;
  filterValueNumeric: InputInterface;
}

const FormFArticlePropFilterDisplayComponent: React.FC<State2> = React.memo(
  ({ filterValues, type, fArticle, unCheckParent, parent }) => {
    return (
      <Box sx={{ marginLeft: 1, paddingLeft: 1, borderLeft: "gray solid 1px" }}>
        {filterValues.map((filterValue, indexFilterValue) => (
          <React.Fragment key={indexFilterValue}>
            <FormFArticlePropFilterValueComponent
              ref={filterValue.ref}
              unCheckParent={unCheckParent}
              fArticle={fArticle}
              filterValue={filterValue}
              type={type}
              parent={parent}
            />
            {filterValue.children.length > 0 && (
              <FormFArticlePropFilterDisplayComponent
                fArticle={fArticle}
                filterValues={filterValue.children}
                type={type}
                unCheckParent={unCheckParent}
                parent={filterValue}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    );
  }
);

const FormFArticlePropFilterComponent = React.memo(
  React.forwardRef(({ fArticlePropFormFilter, fArticle }: State, ref) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        filterValueNumeric: {
          value: fArticlePropFormFilter.filterValueNumeric ?? "",
          error: "",
        },
        relevant: { value: fArticlePropFormFilter.relevant, error: "" },
      };
    }, [
      fArticlePropFormFilter.filterValueNumeric,
      fArticlePropFormFilter.relevant,
    ]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const getCategoryIds = React.useCallback((): number[] => {
      return fArticle.categories.map((c) => c.id);
    }, [fArticle.categories]);
    const [categoryIds, setCategoryIds] = React.useState<number[]>(
      getCategoryIds()
    );

    const handleChangeChecked = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.checked) {
            for (const filterValue of fArticlePropFormFilter.filterValues) {
              filterValue.ref.current.unCheck();
            }
          }
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      [fArticlePropFormFilter.filterValues]
    );

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const unCheck = React.useCallback(() => {
      setValues((v) => {
        v.relevant.value = false;
        return { ...v };
      });
    }, []);

    const getFilterValueIds = React.useCallback(
      (
        filterValues: FArticlePropFilterFilterValueFormInterface[],
        result: any[] = []
      ): any[] => {
        for (const filterValue of filterValues) {
          result.push(filterValue.ref.current.getValue());
          if (filterValue.children.length > 0) {
            getFilterValueIds(filterValue.children, result);
          }
        }
        return result.filter((x) => x);
      },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          id: fArticlePropFormFilter.id,
          relevant: values.relevant.value,
          filterValueNumeric: values.filterValueNumeric.value
            ? parseFloat(values.filterValueNumeric.value)
            : null,
          filterValues: getFilterValueIds(fArticlePropFormFilter.filterValues),
        };
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [fArticlePropFormFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (values.relevant.value) {
        setValues((v) => {
          return {
            ...v,
            filterValueNumeric: {
              ...v.filterValueNumeric,
              value: "",
              error: "",
            },
          };
        });
      }
    }, [values.relevant.value]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (values.filterValueNumeric.value !== "") {
        setValues((v) => {
          return {
            ...v,
            relevant: {
              ...v.relevant,
              value: false,
              error: "",
            },
          };
        });
      }
    }, [values.filterValueNumeric.value]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setCategoryIds(getCategoryIds());
    }, [fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Card
        variant="outlined"
        sx={{
          marginTop: 1,
          ...(fArticlePropFormFilter.new && {
            backgroundColor: theme.palette.primary.light,
          }),
        }}
      >
        <CardContent
          sx={{
            padding: "0px !important",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  {...(fArticlePropFormFilter.type === TYPE_RADIO && {
                    icon: <RadioButtonUncheckedIcon />,
                    checkedIcon: <RadioButtonCheckedIcon />,
                  })}
                  sx={{ paddingY: 0 }}
                  checked={values.relevant.value}
                  onChange={handleChangeChecked("relevant")}
                />
              }
              label={
                <>
                  {fArticlePropFormFilter.filterCategories
                    .filter((filterCategory) =>
                      categoryIds.includes(filterCategory.category.id)
                    )
                    .map((filterCategory, indexFilterCategory) => (
                      <Chip
                        size="small"
                        key={indexFilterCategory}
                        label={
                          "[" +
                          filterCategory.category.id +
                          "] " +
                          filterCategory.category.name
                        }
                      />
                    ))}
                  <br />
                  <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {fArticlePropFormFilter.name}
                  </Typography>
                  {fArticlePropFormFilter.type === TYPE_CHECKBOX && (
                    <Typography
                      component="span"
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    >
                      [
                      {t(
                        "word.filter.condition." +
                          fArticlePropFormFilter.condition
                      )}
                      ]
                    </Typography>
                  )}
                </>
              }
            />
            {fArticlePropFormFilter.type === TYPE_NUMERIC ? (
              <TextField
                sx={{ width: "100%", marginTop: 1 }}
                fullWidth={true}
                autoComplete="off"
                error={!!values.filterValueNumeric.error}
                helperText={t(values.filterValueNumeric.error ?? "")}
                type="number"
                value={values.filterValueNumeric.value}
                onChange={handleChange("filterValueNumeric")}
                label={t("field.value")}
              />
            ) : (
              <FormFArticlePropFilterDisplayComponent
                fArticle={fArticle}
                filterValues={fArticlePropFormFilter.filterValues}
                type={fArticlePropFormFilter.type}
                unCheckParent={unCheck}
              />
            )}
          </FormGroup>
        </CardContent>
      </Card>
    );
  })
);

export default FormFArticlePropFilterComponent;
