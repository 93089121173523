import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, DialogProps } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import StepperOrderComponent from "./StepperOrderComponent";
import {
  ArrayPostOrderInterface,
  OrderInterface,
} from "../../../interfaces/OrderInterface";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ORDER_URL } from "../../../utils/UrlsUtils";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { addOrder, set } from "../../../app/globalSlice";
import { ORDERS_PAGE } from "../../../utils/RouteUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useNavigate } from "react-router-dom";
import getErrorApi from "../../../helpers/GetErrorApi";
import {
  FDocenteteInterface,
  OpenDialogOrderInterface,
} from "../../../interfaces/FDocenteteInterface";
import {
  fDocenteteGetLivraison,
  getFormDataForArrayPostOrder,
} from "../../../helpers/FDocenteteHelper";
import { submitFormPayment } from "../../../helpers/PaymentHelper";

interface State {
  order: OrderInterface | undefined;
  postOrderId?: number;
  fDocentete?: FDocenteteInterface;
  openDialog: boolean;
  devis: boolean;
  setOpenDialog: Function;
  withBonCommande?: boolean;
}

const DialogOrderComponent: React.FC<State> = React.memo(
  ({
    order,
    openDialog,
    setOpenDialog,
    fDocentete,
    postOrderId,
    devis,
    withBonCommande,
  }) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [finish, setFinish] = React.useState(false);
    const handleClose = React.useCallback(() => {
      if (!finish) {
        setOpenDialog((x: OpenDialogOrderInterface) => {
          x.open = false;
          return { ...x };
        });
      }
    }, [finish, setOpenDialog]);
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const createUpdateOrder = React.useCallback(
      async (arrayPostOrder: ArrayPostOrderInterface) => {
        setFinish(true);
        const orderApi = getFormDataForArrayPostOrder(
          arrayPostOrder,
          user,
          isAdmin,
          fDocentete,
          !!devis
        );
        if (postOrderId) {
          orderApi.append("oldPostOrderId", postOrderId.toString());
        }
        const response = await requestApi({
          method: POST,
          path: ORDER_URL,
          allowError: true,
          token: token,
          body: orderApi,
          formData: true,
          timeout: 30_000,
        });
        if (response.statusCode === 201) {
          dispatch(addOrder(response.content));
          for (const postOrder of response.content.order.postOrders) {
            if (postOrder.form) {
              submitFormPayment(postOrder.form);
              return;
            }
          }
          setTimeout(() => {
            dispatch(
              set({
                openCart: false,
                editingFDocentete: null,
                postOrders: response.content.postOrders,
              })
            );
          }, 200);
          navigate(ORDERS_PAGE, {
            state: {
              ...{ order: response.content.order },
              ...(devis
                ? {
                    newDevis: true,
                  }
                : { newOrder: true }),
            },
          });
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      },
      [
        devis,
        dispatch,
        fDocentete,
        isAdmin,
        navigate,
        postOrderId,
        t,
        token,
        user,
      ]
    );

    const onFinish = React.useCallback(
      async (arrayPostOrder: ArrayPostOrderInterface) => {
        await createUpdateOrder(arrayPostOrder);
      },
      [createUpdateOrder]
    );

    return (
      <>
        {order !== undefined && (
          <Dialog
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            onClose={handleClose}
            open={openDialog}
          >
            <DialogContent>
              <StepperOrderComponent
                devis={!!devis}
                initOrder={order}
                onFinish={onFinish}
                withBonCommande={!!withBonCommande}
                fDocenteteLigneLivraison={fDocenteteGetLivraison(fDocentete)}
              />
              {finish && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {devis ? (
                    <Typography>{t("sentence.creatingDevis")}</Typography>
                  ) : (
                    <Typography>{t("sentence.creatingOrder")}</Typography>
                  )}
                  <CircularProgress />
                </Box>
              )}
            </DialogContent>
            {!finish && (
              <DialogActions>
                <Button onClick={handleClose}>{t("word.cancel")}</Button>
              </DialogActions>
            )}
          </Dialog>
        )}
      </>
    );
  }
);

export default DialogOrderComponent;
