import React from "react";
import { useTranslation } from "react-i18next";
import {
  PaymentHistoryInterface,
  PaymentHistoryLogInterface,
} from "../../../interfaces/PaymentHistoryInterface";
import Typography from "@mui/material/Typography";
import {
  ALL_STATUS,
  STATUS_FAILED,
  STATUS_PENDING,
  STATUS_SUCCESS,
  TYPE_OUTPUT,
  TYPE_RESPONSE,
} from "../../../utils/PaymentHistoryUtils";
import { Chip, FormControl, InputLabel, Select } from "@mui/material";
import { requestApi } from "../../../helpers/RequestApi";
import { GET, PATCH, POST } from "../../../utils/MethodUtils";
import {
  COMMANDE_CONFIRMATION_BANQUE_URL,
  PAYMENT_HISTORY_URL,
} from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import SearchComponent from "../SearchComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import getErrorApi from "../../../helpers/GetErrorApi";

interface State {
  paymentHistory: PaymentHistoryInterface | undefined;
  setPaymentHistory: Function;
  reloadGrid: Function;
}

const PaymentHistoryComponent: React.FC<State> = React.memo(
  ({ paymentHistory, setPaymentHistory, reloadGrid }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [paymentHistoryLogs, setPaymentHistoryLogs] = React.useState<
      PaymentHistoryLogInterface[] | undefined
    >(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loading2, setLoading2] = React.useState<boolean>(false);
    const [editStatus, setEditStatus] = React.useState<boolean>(false);
    const [newStatus, setNewStatus] = React.useState<string>(
      paymentHistory?.status ?? ""
    );

    const load = React.useCallback(async () => {
      if (!paymentHistory) {
        return;
      }
      setPaymentHistoryLogs(undefined);
      const response = await requestApi({
        method: GET,
        path: PAYMENT_HISTORY_URL + "/" + paymentHistory.id,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setPaymentHistoryLogs([...response.content.paymentHistoryLogs]);
        delete response.content.paymentHistoryLogs;
        setPaymentHistory(response.content);
        reloadGrid(true);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
    }, [paymentHistory, reloadGrid, setPaymentHistory, t, token]);

    const resend = React.useCallback(async () => {
      setLoading(true);
      if (paymentHistory && paymentHistoryLogs) {
        const dataToSend = paymentHistoryLogs.find(
          (paymentHistoryLog) => paymentHistoryLog.type === TYPE_RESPONSE
        );
        if (dataToSend) {
          const formData = new FormData();
          for (const key in dataToSend.value) {
            formData.append(key, dataToSend.value[key]);
          }
          const response = await requestApi({
            method: POST,
            path: COMMANDE_CONFIRMATION_BANQUE_URL,
            allowError: true,
            token: token,
            body: formData,
            formData: true,
          });
          load();
          if (response.statusCode !== 200) {
            toastr.error(t("word.error"), t("error.tryAgain"));
          }
        }
      }
      setLoading(false);
    }, [load, paymentHistory, paymentHistoryLogs, t, token]);

    const handleChangeSelect = React.useCallback((event: SelectChangeEvent) => {
      setNewStatus(event.target.value as string);
    }, []);

    const showEditStatus = React.useCallback(() => {
      setEditStatus((x) => !x);
    }, []);

    const save = React.useCallback(async () => {
      if (!paymentHistory) {
        return;
      }
      setLoading2(true);
      const response = await requestApi({
        method: PATCH,
        path: PAYMENT_HISTORY_URL + "/" + paymentHistory.id,
        allowError: true,
        token: token,
        body: {
          status: newStatus,
        },
      });
      if (response.statusCode === 200) {
        await load();
        setEditStatus(false);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading2(false);
    }, [load, newStatus, paymentHistory, t, token]);

    React.useEffect(() => {
      load();
    }, [paymentHistory?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setNewStatus(paymentHistory?.status ?? "");
    }, [paymentHistory?.status]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {paymentHistory && (
          <>
            <Typography>{t("word.id") + ": " + paymentHistory.id}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography component="span">
                {t("word.status") + ": "}
              </Typography>
              {editStatus ? (
                <>
                  <FormControl>
                    <InputLabel id="status">{t("word.status")}</InputLabel>
                    <Select
                      labelId="status"
                      value={newStatus}
                      label={t("word.status")}
                      onChange={handleChangeSelect}
                    >
                      {ALL_STATUS.map((status, indexStatus) => (
                        <MenuItem value={status} key={indexStatus}>
                          {t("word.paymentHistory.status." + status)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={showEditStatus} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading2}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Chip
                    label={t(
                      "word.paymentHistory.status." + paymentHistory.status
                    )}
                    variant="outlined"
                    color={
                      paymentHistory.status === STATUS_PENDING
                        ? "primary"
                        : paymentHistory.status === STATUS_SUCCESS
                        ? "success"
                        : "error"
                    }
                  />
                  <IconButton onClick={showEditStatus}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </Box>
            <Typography>
              {t("word.userIdentifier") + ": "}
              <CopyClipboardComponent
                className="RobotoMono"
                component="span"
                text={paymentHistory.userIdentifier}
              />
            </Typography>
            <Typography>
              {t("word.reference") + ": "}
              <CopyClipboardComponent
                className="RobotoMono"
                component="span"
                text={paymentHistory.reference}
              />
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <LoadingButton
                variant="contained"
                disabled={paymentHistory.status !== STATUS_FAILED}
                onClick={resend}
                loading={loading}
              >
                {t("word.resend")}
              </LoadingButton>
            </Box>
            {paymentHistoryLogs ? (
              paymentHistoryLogs.map(
                (paymentHistoryLog, indexPaymentHistoryLog) => (
                  <Card key={indexPaymentHistoryLog} sx={{ marginTop: 1 }}>
                    <CardContent
                      sx={{
                        ...(paymentHistoryLog.type === TYPE_OUTPUT && {
                          backgroundColor: theme.palette.primary.main,
                        }),
                        ...(paymentHistoryLog.type === TYPE_RESPONSE && {
                          backgroundColor: theme.palette.info.main,
                        }),
                      }}
                    >
                      <pre>
                        {JSON.stringify(paymentHistoryLog.value, null, 2)}
                      </pre>
                      <Box sx={{ textAlign: "right" }}>
                        <Typography
                          variant="caption"
                          sx={{
                            ...(paymentHistoryLog.type === TYPE_OUTPUT && {
                              color: "white",
                            }),
                          }}
                        >
                          {new Date(paymentHistoryLog.created).toLocaleString(
                            i18n.language,
                            {
                              dateStyle: "long",
                              timeStyle: "medium",
                              timeZone: "Etc/UTC",
                            }
                          )}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                )
              )
            ) : (
              <SearchComponent nbColumn={1} nbLines={2} />
            )}
          </>
        )}
      </>
    );
  }
);

export default PaymentHistoryComponent;
