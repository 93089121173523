import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Pagination, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface State {
  nbPage: number | undefined;
  page: number;
  handleChangePage: any;
}

const CustomPaginationComponent: React.FC<State> = React.memo(
  ({ nbPage, page, handleChangePage }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();

    return (
      <Stack
        spacing={2}
        sx={{
          marginBottom: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box>
          {!isSmall && (
            <Typography variant="caption">
              {t("word.useArrow.first")}
              <IconButton sx={{ padding: 0 }}>
                <ArrowBackIcon />
              </IconButton>
              <IconButton sx={{ padding: 0 }}>
                <ArrowForwardIcon />
              </IconButton>
              {t("word.useArrow.second")}
            </Typography>
          )}
        </Box>
        <Pagination
          count={nbPage}
          page={page}
          onChange={handleChangePage}
          boundaryCount={1}
          siblingCount={isSmall ? 1 : 3}
          color="primary"
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
          }}
        />
      </Stack>
    );
  }
);

export default CustomPaginationComponent;
