import React, { useImperativeHandle } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { Card, TextField } from "@mui/material";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";

interface State {
  provided: DraggableProvided;
  data: any;
  tabKey: number;
  tabValue: number;
  setTabValue: Function;
  removeTab: Function;
  singleTab?: boolean;
}

interface FormState {
  name: InputInterface;
}

const ContentTabComponent = React.memo(
  React.forwardRef(
    (
      {
        provided,
        data,
        tabKey,
        tabValue,
        setTabValue,
        removeTab,
        singleTab,
      }: State,
      ref
    ) => {
      const theme = useTheme();
      const { t } = useTranslation();
      const getValues = React.useCallback(() => {
        return {
          name: { value: data?.name ?? "", error: "" },
        };
      }, [data?.name]);
      const [values, setValues] = React.useState<FormState>(getValues());

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      const thisRemoveTab = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          removeTab(tabKey);
        },
        [removeTab, tabKey]
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          if (singleTab === true) {
            return "";
          }
          return {
            name: values.name.value,
          };
        },
      }));

      React.useEffect(() => {
        setValues(getValues());
      }, [data?.name]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {singleTab !== true && (
            <Card
              variant="outlined"
              sx={{
                paddingY: 1,
                paddingRight: 0.5,
                marginX: 0.5,
                ...(tabValue === tabKey && {
                  backgroundColor: theme.palette.primary.light,
                }),
              }}
              onClick={() => setTabValue(tabKey)}
            >
              <Box sx={{ textAlign: "center" }}>
                <IconButton onClick={thisRemoveTab}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    display: "flex",
                    cursor: "move",
                    marginRight: 1,
                  }}
                  {...provided.dragHandleProps}
                >
                  <MenuIcon />
                </span>
                <TextField
                  fullWidth={true}
                  autoComplete="off"
                  error={!!values.name.error}
                  helperText={t(values.name.error ?? "")}
                  sx={{ width: "100%" }}
                  type="text"
                  value={values.name.value}
                  onChange={handleChange("name")}
                  label={t("field.name")}
                />
              </Box>
            </Card>
          )}
        </>
      );
    }
  )
);

export default ContentTabComponent;
