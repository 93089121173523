import * as React from "react";
import {
  FArticleInterface,
  TechnicalSheetInterface,
} from "../../../interfaces/FArticleInterface";
import Typography from "@mui/material/Typography";
import { generateTechnicalSheet } from "../../../helpers/FArticleHelper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";

interface State {
  fArticle: FArticleInterface | undefined;
  setDescription: Function;
  temporaryIcecat?: any;
}

const FArticleTechnicalSheetComponent: React.FC<State> = React.memo(
  ({ fArticle, setDescription, temporaryIcecat }) => {
    const nbColumn = 2;
    const getTechnicalSheet = React.useCallback(():
      | TechnicalSheetInterface
      | undefined => {
      if (temporaryIcecat) {
        let testFArticle: any = {
          ficheTechnique: temporaryIcecat,
        };
        return generateTechnicalSheet(testFArticle, 2);
      }
      return generateTechnicalSheet(fArticle, 2);
    }, [fArticle, temporaryIcecat]);
    const [technicalSheet, setTechnicalSheet] = React.useState<
      TechnicalSheetInterface | undefined
    >(getTechnicalSheet());
    const theme = useTheme();
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [defaultExpanded] = React.useState<boolean>(!isSmall);
    const { t } = useTranslation();

    const importInDescription = React.useCallback(() => {
      setDescription(technicalSheet?.description);
    }, [setDescription, technicalSheet?.description]);

    React.useEffect(() => {
      setTechnicalSheet(getTechnicalSheet());
    }, [fArticle, temporaryIcecat]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {technicalSheet === undefined ? (
          <Typography>{t("sentence.technicalSheet")}</Typography>
        ) : (
          <>
            {isAdmin && technicalSheet?.description && (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: technicalSheet?.description ?? "",
                  }}
                />
                <Box sx={{ textAlign: "right" }}>
                  <LoadingButton
                    variant="contained"
                    onClick={importInDescription}
                  >
                    {t("word.importDescription")}
                  </LoadingButton>
                </Box>
              </>
            )}
            <Grid container spacing={1} sx={{ marginTop: 2 }}>
              {technicalSheet?.display.map((display, indexDisplay) => (
                <Grid
                  key={indexDisplay}
                  item
                  xs={12}
                  md={12 / nbColumn}
                  sx={{ paddingTop: "0!important" }}
                >
                  {display.groups.map((group, indexGroup) => (
                    <Accordion
                      key={indexGroup}
                      defaultExpanded={defaultExpanded}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        sx={{ backgroundColor: theme.palette.primary.main }}
                      >
                        <Typography sx={{ color: "white" }}>
                          {group.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0 }}>
                        <TableContainer component={Paper}>
                          <Table size="small">
                            <TableBody>
                              {group.props.map((prop, indexProp) => (
                                <TableRow
                                  key={indexProp}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {prop.name}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {prop.value}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </>
    );
  }
);

export default FArticleTechnicalSheetComponent;
