import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  ALL_EXERCISE,
  CURRENT_EXERCISE,
} from "../../../../utils/AccountingSituationUtils";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";

const FiscalYearFormComponent: React.FC = React.memo(() => {
  const defaultValue = CURRENT_EXERCISE;
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const getValue = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (searchParamsObject.hasOwnProperty("fiscal")) {
      return searchParamsObject.fiscal;
    }
    return defaultValue;
  }, [defaultValue, searchParams]);
  const [value, setValue] = React.useState(getValue());
  const handleChangeSelect = React.useCallback(
    (event: SelectChangeEvent) => {
      const searchParamsObject = searchParamToObject(searchParams);
      setValue(event.target.value as string);
      searchParamsObject.fiscal = event.target.value as string;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [searchParams, setSearchParams]
  );

  const init = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (!searchParamsObject.hasOwnProperty("fiscal")) {
      searchParamsObject.fiscal = defaultValue;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    }
  }, [defaultValue, searchParams, setSearchParams]);

  React.useEffect(() => {
    init();
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormControl fullWidth>
      <InputLabel id="fiscal">{t("field.fiscal")}</InputLabel>
      <Select
        labelId="fiscal"
        value={value}
        label={t("field.fiscal")}
        onChange={handleChangeSelect}
      >
        {ALL_EXERCISE.map((writing, indexWriting) => (
          <MenuItem value={writing} key={indexWriting}>
            {t("word.accountingSituation.fiscal." + writing)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default FiscalYearFormComponent;
