import { enUS, fr } from "date-fns/esm/locale";
import { enUS as gEnUS, frFR } from "@mui/x-data-grid";

export const getLocaleDateFns = (locale: string): any => {
  const dateLocales = { fr: fr, en: enUS };
  // @ts-ignore
  return dateLocales[locale];
};

export const getLocaleDataGrid = (locale: string): any => {
  const dateLocales = {
    fr: frFR.components.MuiDataGrid.defaultProps.localeText,
    en: gEnUS.components.MuiDataGrid.defaultProps.localeText,
  };
  // @ts-ignore
  return dateLocales[locale];
};
