import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const en = require("./en.json");
const fr = require("./fr.json");

i18next.use(initReactI18next).init({
  lng: "fr",
  resources: {
    // if a new resource is added here => Ctrl + Maj + F `const dateLocales`
    en: en,
    fr: fr,
  },
  react: {
    useSuspense: false,
  },
});

export default i18next;
