import React, { useImperativeHandle } from "react";
import {
  ContentInterface,
  PutContentInterface,
} from "../../interfaces/ContentInterface";
import { Container, Skeleton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { jsonToFormData, requestApi } from "../../helpers/RequestApi";
import { GET, POST } from "../../utils/MethodUtils";
import { CONTENT_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import ContentDisplayComponent from "./ContentDisplayComponent";
import Typography from "@mui/material/Typography";
import { STORAGE_CONTENT } from "../../utils/StorageUtils";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ContentTabRowsComponent from "./Tab/ContentTabRowsComponent";
import ContentTabComponent from "./Tab/ContentTabComponent";
import Divider from "@mui/material/Divider";
import { swapCard } from "../../helpers/CardFormat";
import { FArticleSmallInterface } from "../../interfaces/FArticleInterface";
import { contentHasContent } from "../../helpers/CkEditorHelper";
import { set } from "../../app/globalSlice";

interface State {
  contentName?: string;
  initContent?: ContentInterface | null;
  defaultMessage?: string | React.ReactNode;
  saveLocalStorage?: boolean;
  width?: number | string;
  preventChangeUrlTab?: boolean;
  fArticle?: FArticleSmallInterface;
  showEdit?: boolean;
  showSaveCancel?: boolean;
  edit?: boolean;
  availableModules?: string[];
  singleTab?: boolean;
  customSave?: Function;
  resetFArticleInitContent?: Function;
  noPadding?: boolean;
}

interface TabRefInterface {
  ref: any;
  refRow: any;
  key: number;
  data: any;
}

const ContentComponent = React.memo(
  React.forwardRef(
    (
      {
        contentName,
        initContent,
        defaultMessage,
        saveLocalStorage,
        width,
        preventChangeUrlTab,
        fArticle,
        showEdit,
        showSaveCancel,
        edit,
        availableModules,
        singleTab,
        customSave,
        resetFArticleInitContent,
        noPadding,
      }: State,
      ref
    ) => {
      const [loading, setLoading] = React.useState(false);
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [tabValue, setTabValue] = React.useState(0);
      const getLocalStorageContent = React.useCallback(():
        | ContentInterface
        | null
        | undefined => {
        if (initContent !== undefined) {
          return initContent;
        }
        const localStorageName = STORAGE_CONTENT + contentName;
        let localStorageContent = null;
        if (saveLocalStorage) {
          localStorageContent = localStorage.getItem(localStorageName);
          if (localStorageContent) {
            try {
              return JSON.parse(localStorageContent);
            } catch (e) {
              // nothing
            }
          }
        }
        return undefined;
      }, [contentName, initContent, saveLocalStorage]);
      const getInitContent = React.useCallback(() => {
        return initContent ?? getLocalStorageContent();
      }, [getLocalStorageContent, initContent]);
      const [content, setContent] = React.useState<
        ContentInterface | null | undefined
      >(getInitContent());
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const dismissContent = useAppSelector(
        (state: RootState) => state.globalState.dismissContent
      );
      const [tabsRef, setTabsRef] = React.useState<(TabRefInterface | null)[]>(
        []
      );
      const { t } = useTranslation();
      const dispatch = useAppDispatch();

      const addTab = React.useCallback(() => {
        setTabsRef((x) => {
          const newKey = x.length;
          setTabValue(newKey);
          return [
            ...x,
            {
              ref: React.createRef(),
              refRow: React.createRef(),
              data: undefined,
              key: newKey,
            },
          ];
        });
      }, []);

      const removeTab = React.useCallback((tabKey: number) => {
        setTabsRef((x) => {
          const index = x.findIndex((y) => y?.key === tabKey);
          if (index === -1) {
            return [...x];
          }
          x[index] = null;
          const newTab = x.find((y) => (y?.key ?? -1) > index);
          let newIndex = 0;
          if (newTab) {
            newIndex = newTab.key;
          }
          setTabValue(newIndex);
          return [...x];
        });
      }, []);

      const getValue = React.useCallback(async (): Promise<
        PutContentInterface | undefined
      > => {
        const value: any[] = [];
        // @ts-ignore
        for (const [indexTabRef, tabRef] of tabsRef.entries()) {
          if (tabRef === null) {
            continue;
          }
          const refValue = await tabRef?.ref.current.getValue();
          if (refValue === undefined) {
            return undefined;
          }
          const refRowValue = await tabRef?.refRow.current.getValue();
          if (refRowValue === undefined) {
            return undefined;
          }
          value.push({
            ...refValue,
            ...refRowValue,
            sort: indexTabRef,
          });
        }
        return {
          content: {
            tabs: value,
          },
        };
      }, [tabsRef]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const newContent: PutContentInterface | undefined = await getValue();
        if (newContent === undefined) {
          setLoading(false);
          return;
        }
        if (content?.id) {
          newContent.id = content?.id;
        }
        const formData = jsonToFormData(newContent);
        if (customSave) {
          await customSave(formData);
        } else {
          const response = await requestApi({
            method: POST,
            path: CONTENT_URL,
            allowError: true,
            token: token,
            body: formData,
            formData: true,
          });
          if (response !== null) {
            if (response.statusCode === 200 || response.statusCode === 201) {
              setContent(response.content);
              const localStorageName = STORAGE_CONTENT + contentName;
              if (saveLocalStorage) {
                localStorage.setItem(
                  localStorageName,
                  JSON.stringify(response.content)
                );
              } else {
                localStorage.removeItem(localStorageName);
              }
              setThisEdit(false);
            }
          } else {
            toastr.error(t("word.error"), t("error.tryAgain"));
          }
        }
        setLoading(false);
      }, [
        getValue,
        content?.id,
        customSave,
        token,
        contentName,
        saveLocalStorage,
        t,
      ]);

      const updateContent = React.useCallback(
        async (force: boolean = false) => {
          if (contentName === undefined) {
            return;
          }
          setContent(getLocalStorageContent());
          const response = await requestApi({
            method: GET,
            path: CONTENT_URL + "?name=" + contentName,
            allowError: false,
            token: token,
          });
          if (response.statusCode === 200) {
            const newContent = response.content[0];
            const stringContent = JSON.stringify(newContent);
            const localStorageName = STORAGE_CONTENT + contentName;
            if (force || stringContent !== JSON.stringify(content)) {
              setContent(newContent);
            }
            if (saveLocalStorage) {
              localStorage.setItem(localStorageName, stringContent);
            } else {
              localStorage.removeItem(localStorageName);
            }
          } else if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"));
          } else {
            toastr.error(t("word.error"), t("error.tryAgain"));
          }
        },
        [
          content,
          contentName,
          getLocalStorageContent,
          saveLocalStorage,
          t,
          token,
        ]
      );

      const initTabs = React.useCallback(() => {
        if (!thisEdit) {
          setTabsRef([]);
          return;
        }
        if (content === undefined || content === null) {
          if (thisEdit && tabsRef.filter((x) => x).length === 0) {
            addTab();
          }
          return;
        }
        let tabs: any = null;
        if (content.hasOwnProperty("content")) {
          tabs = content?.content?.tabs;
        } else {
          // @ts-ignore
          tabs = content?.tabs;
        }
        if (!contentHasContent(content)) {
          addTab();
          return;
        }
        setTabsRef(() =>
          tabs.map((tab: any, indexRow: number) => {
            return {
              ref: React.createRef(),
              refRow: React.createRef(),
              data: tab,
              key: indexRow,
            };
          })
        );
      }, [addTab, content, tabsRef, thisEdit]);

      const cancel = React.useCallback(() => {
        setThisEdit(false);
        if (resetFArticleInitContent) {
          resetFArticleInitContent();
        }
      }, [resetFArticleInitContent]);

      const editAction = React.useCallback(() => {
        if (contentName) {
          const newDismissContent = dismissContent
            ?.filter((d) => d.contentName !== contentName)
            .map((d) => {
              return { ...d };
            });
          dispatch(set({ dismissContent: newDismissContent }));
        }
        setThisEdit(true);
      }, [contentName, dismissContent, dispatch]);

      const onDragEnd = React.useCallback((result: DropResult) => {
        if (
          result.destination === null ||
          result.destination === undefined ||
          result.source === null ||
          result.source === undefined
        ) {
          return;
        }
        setTabsRef((thisRowRef) => {
          thisRowRef = swapCard(thisRowRef, result);
          return thisRowRef;
        });
      }, []);

      useImperativeHandle(ref, () => ({
        setEdit(value?: boolean) {
          if (!value) {
            setThisEdit((x) => !x);
            return;
          }
          setThisEdit(value);
        },
        async getValue() {
          return await getValue();
        },
        updateContent() {
          updateContent(true);
        },
      }));

      React.useEffect(() => {
        updateContent();
      }, [contentName]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        initTabs();
      }, [content, thisEdit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setContent(getInitContent());
      }, [initContent]); // eslint-disable-line react-hooks/exhaustive-deps

      return content === undefined ? (
        <Skeleton
          variant="rectangular"
          height={150}
          width={width}
          sx={{ marginTop: 2, marginBottom: 2, minWidth: "100%" }}
        />
      ) : thisEdit && isAdmin ? (
        <>
          {singleTab !== true && (
            <Container sx={{ display: "flex", marginY: 1 }}>
              <Box sx={{ textAlign: "center", flex: 1 }}>
                <LoadingButton variant="contained" onClick={addTab}>
                  {t("action.add.tab")}
                </LoadingButton>
              </Box>
            </Container>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Box sx={{ overflow: "auto" }}>
              <Droppable droppableId="tab" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "max-content",
                    }}
                  >
                    {tabsRef.map(
                      (tabRef, indexTabRef) =>
                        tabRef !== null && (
                          <Draggable
                            key={tabRef.key}
                            draggableId={tabRef.key.toString()}
                            index={indexTabRef}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <ContentTabComponent
                                  data={tabRef.data}
                                  key={tabRef.key}
                                  ref={tabRef.ref}
                                  tabKey={tabRef.key}
                                  tabValue={tabValue}
                                  setTabValue={setTabValue}
                                  provided={provided}
                                  removeTab={removeTab}
                                  singleTab={singleTab}
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Box>
          </DragDropContext>
          <Divider sx={{ marginY: 2 }} />
          {tabsRef.map(
            (tabRef) =>
              tabRef !== null && (
                <Box
                  key={tabRef.key}
                  sx={{ ...(tabRef.key !== tabValue && { display: "none" }) }}
                >
                  <ContentTabRowsComponent
                    ref={tabRef?.refRow}
                    index={tabRef.key}
                    data={tabRef?.data}
                    fArticle={fArticle}
                    availableModules={availableModules}
                  />
                </Box>
              )
          )}
          {showSaveCancel !== false && (
            <>
              <Divider sx={{ marginY: 2 }} />
              <Container sx={{ display: "flex", marginY: 1 }}>
                <Box sx={{ textAlign: "left", flex: 1 }}>
                  <LoadingButton onClick={cancel}>
                    {t("word.cancel")}
                  </LoadingButton>
                </Box>
                <Box sx={{ textAlign: "right", flex: 1 }}>
                  <LoadingButton
                    variant="contained"
                    onClick={save}
                    loading={loading}
                  >
                    {t("word.save")}
                  </LoadingButton>
                </Box>
              </Container>
            </>
          )}
        </>
      ) : (
        <>
          <ContentDisplayComponent
            content={content}
            preventChangeUrlTab={preventChangeUrlTab}
            fArticle={fArticle}
            contentName={contentName}
            setTabValue={setTabValue}
            noPadding={noPadding}
          />
          {!contentHasContent(content) &&
            defaultMessage !== undefined &&
            (typeof defaultMessage === "string" ? (
              <Typography>{defaultMessage}</Typography>
            ) : (
              defaultMessage
            ))}
          {isAdmin && showEdit !== false && (
            <Container sx={{ textAlign: "right", flex: 1, marginY: 1 }}>
              <LoadingButton variant="contained" onClick={editAction}>
                {t("word.edit.word")}
              </LoadingButton>
            </Container>
          )}
        </>
      );
    }
  )
);

export default ContentComponent;
