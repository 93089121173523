import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { InputInterface } from "../../../interfaces/InputInterface";
import { ArrivageFDocenteteInterface } from "../../../interfaces/ArrivageInterface";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import { FDOCENTETES_URL } from "../../../utils/UrlsUtils";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface State {
  fDocentete: ArrivageFDocenteteInterface;
  disabled?: boolean;
  focusMainSearch: Function;
}

interface FormState {
  groupage: InputInterface;
  commentaires: InputInterface;
  doRef: InputInterface;
}

interface DataState {
  groupage: string;
  commentaires: string;
  doRef: string;
}

const UpdateFDocenteteArrivageComponent: React.FC<State> = React.memo(
  ({ fDocentete, disabled, focusMainSearch }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        groupage: { value: fDocentete.groupage, error: "" },
        commentaires: { value: fDocentete.commentaires, error: "" },
        doRef: { value: fDocentete.doRef, error: "" },
      };
    }, [fDocentete.commentaires, fDocentete.doRef, fDocentete.groupage]);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const getFDocenteteValue = React.useCallback((): DataState => {
      return {
        groupage: fDocentete.groupage,
        commentaires: fDocentete.commentaires,
        doRef: fDocentete.doRef,
      };
    }, [fDocentete.commentaires, fDocentete.doRef, fDocentete.groupage]);
    const [fDocenteteValue, setFDocenteteValue] = React.useState<DataState>(
      getFDocenteteValue()
    );

    const handleEdit = React.useCallback(() => {
      setEdit((x) => !x);
    }, []);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const save = React.useCallback(async () => {
      if (
        fDocenteteValue.groupage === values.groupage.value &&
        fDocenteteValue.commentaires === values.commentaires.value &&
        fDocenteteValue.doRef === values.doRef.value
      ) {
        setValues(getDefaultValues());
        setEdit(false);
        focusMainSearch();
        return;
      }
      setLoading(true);
      const response = await requestApi({
        method: PATCH,
        path: FDOCENTETES_URL + "/" + fDocentete.doPiece,
        allowError: true,
        token: token,
        body: {
          doCoord01: values.groupage.value,
          commentaires: values.commentaires.value,
          doRef: values.doRef.value,
        },
      });
      if (response.statusCode === 200) {
        setFDocenteteValue((x) => {
          return {
            ...x,
            groupage: values.groupage.value,
            commentaires: values.commentaires.value,
            doRef: values.doRef.value,
          };
        });
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setEdit(false);
      setLoading(false);
      focusMainSearch();
    }, [
      fDocentete.doPiece,
      fDocenteteValue.commentaires,
      fDocenteteValue.doRef,
      fDocenteteValue.groupage,
      focusMainSearch,
      getDefaultValues,
      t,
      token,
      values.commentaires.value,
      values.doRef.value,
      values.groupage.value,
    ]);

    React.useEffect(() => {
      setFDocenteteValue(getFDocenteteValue());
    }, [fDocentete.commentaires, fDocentete.doRef, fDocentete.groupage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box>
        {edit ? (
          <Box>
            {Object.keys(values).map((prop) => (
              <TextField
                key={prop}
                sx={{ width: "100%", marginTop: 1 }}
                fullWidth={true}
                autoComplete="off"
                // @ts-ignore
                error={!!values[prop].error}
                // @ts-ignore
                helperText={t(values[prop].error ?? "")}
                type="text"
                // @ts-ignore
                value={values[prop].value}
                // @ts-ignore
                onChange={handleChange(prop)}
                label={t("word." + prop)}
              />
            ))}
            <Box sx={{ textAlign: "center" }}>
              <LoadingButton
                variant="text"
                color="inherit"
                disabled={disabled}
                sx={{
                  borderRadius: "50%",
                  minWidth: "auto",
                  padding: "12px",
                }}
                loading={loading}
                onClick={save}
              >
                <SaveIcon />
              </LoadingButton>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              onClick={handleEdit}
              disabled={disabled}
            >
              <EditIcon />
            </IconButton>
            <Box>
              {Object.keys(values).map((prop) => (
                <Typography key={prop}>
                  {/*@ts-ignore*/}
                  {t("word." + prop) + ": " + fDocenteteValue[prop]}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);

export default UpdateFDocenteteArrivageComponent;
