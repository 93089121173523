import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { DriverInterface } from "../../../interfaces/FArticleInterface";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import ContentDisplayComponent from "../../content/ContentDisplayComponent";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";

interface State {
  category?: CategoryInterface;
}

const FArticleHeaderComponent: React.FC<State> = React.memo(({ category }) => {
  const [searchParams] = useSearchParams();
  const drivers = useAppSelector(
    (state: RootState) => state.globalState.drivers
  );
  const getBrand = React.useCallback((): DriverInterface | undefined => {
    const params = searchParamToObject(searchParams);
    if (params.hasOwnProperty("brands[]") && params["brands[]"].length > 0) {
      return drivers?.find((x) => x.name === params["brands[]"][0]);
    }
    return undefined;
  }, [drivers, searchParams]);
  const [brand, setBrand] = React.useState<DriverInterface | undefined>(
    getBrand()
  );

  React.useEffect(() => {
    setBrand(getBrand());
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {brand ? (
        <ContentDisplayComponent content={brand.content} />
      ) : (
        category && <ContentDisplayComponent content={category.content} />
      )}
    </>
  );
});

export default FArticleHeaderComponent;
