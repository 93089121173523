import React, { useImperativeHandle } from "react";
import {
  FilterFilterImageInterface,
  FilterFilterValueFormInterface,
  FilterFilterValueInterface,
} from "../../../../../../interfaces/FilterInterface";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import FilterFormImageDialogComponent from "./FilterFormImageDialogComponent";
import { getUrlFilterImageImage } from "../../../../../../helpers/FileHelper";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import { FILTER_IMAGE_URL } from "../../../../../../utils/UrlsUtils";
import { FormStateFilterForm } from "./FilterFormComponent";

interface State {
  filterValue: FilterFilterValueInterface;
  setValues: Function;
}

const FilterFormImageComponent = React.memo(
  React.forwardRef(({ filterValue, setValues }: State, ref) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [filterImage, setFilterImage] =
      React.useState<FilterFilterImageInterface | null>(
        filterValue.filterImage
      );

    const handleClose = React.useCallback(() => {
      setOpenDialog(false);
    }, []);

    const handleOpen = React.useCallback(() => {
      setOpenDialog(true);
    }, []);

    const resetFilterImage = React.useCallback(() => {
      setValues((x: FormStateFilterForm) => {
        const thisFilterValue = x.filterValues.value.find(
          (y: FilterFilterValueFormInterface) => y.refImage === ref
        );
        thisFilterValue.filterImage = null;
        return { ...x };
      });
      setFilterImage(null);
    }, [ref, setValues]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return filterImage ? FILTER_IMAGE_URL + "/" + filterImage.id : null;
      },
    }));

    return (
      <>
        <FilterFormImageDialogComponent
          filterValue={filterValue}
          open={openDialog}
          handleClose={handleClose}
          setFilterImage={setFilterImage}
          setValues={setValues}
          refImage={ref}
        />
        {filterImage ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={getUrlFilterImageImage(filterImage)}
              alt={filterImage.name}
              loading="lazy"
              style={{ maxHeight: 40, cursor: "pointer" }}
              onClick={handleOpen}
            />
            <DeleteIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={resetFilterImage}
            />
          </Box>
        ) : (
          <IconButton onClick={handleOpen}>
            <AddIcon />
          </IconButton>
        )}
      </>
    );
  })
);

export default FilterFormImageComponent;
