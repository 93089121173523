export const FARTICLE_IMAGE_DIR = "/DATA/fArticle/images";
export const CONSTRUCTOR_IMAGE_DIR = "/DATA/constructor";
export const LOGO_IMAGE_DIR = "/DATA/logos";
export const FARTICLE_SIZE_DIR = "/sizes";
export const CATEGORY_IMAGE_DIR = "/DATA/categories";
export const FILTER_IMAGE_DIR = "/DATA/filterImage";

export const IMAGE_SIZE_LOGO = 60;
export const IMAGE_SIZE_SMALL = 280;
export const IMAGE_SIZE_PHONE = 420;
export const IMAGE_SIZE_NORMAL = 742;
export const IMAGE_SIZE_FULL = 1200;

export const SIZES_FARTICLES = [
  IMAGE_SIZE_LOGO,
  IMAGE_SIZE_SMALL,
  IMAGE_SIZE_PHONE,
  IMAGE_SIZE_NORMAL,
  IMAGE_SIZE_FULL,
];
