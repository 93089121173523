import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleInterface,
  FArticlePropFilterFilterValueFormInterface,
  FArticlePropFilterFilterValueInterface,
  FArticlePropFilterFormInterface,
} from "../../../../../../interfaces/FArticleInterface";
import Box from "@mui/material/Box";
import FormFArticlePropFilterComponent from "./FormFArticlePropFilterComponent";
import { filterIsEqual } from "../../../../../../helpers/FilterHelper";

interface State {
  fArticle: FArticleInterface | undefined;
  nbColumns?: number;
}

interface ColumnFArticlePropFilterInterface {
  fArticlePropFormFilters: FArticlePropFilterFormInterface[];
  size: number;
}

const FilterAdminProductFilterValueComponent = React.memo(
  React.forwardRef(({ fArticle, nbColumns }: State, ref) => {
    const [thisNbColumns, setThisNbColumns] = React.useState<number>(
      nbColumns ?? 2
    );

    const getRefToFilterValues = React.useCallback(
      (
        filterValues: FArticlePropFilterFilterValueInterface[]
      ): FArticlePropFilterFilterValueFormInterface[] => {
        return filterValues.map((x) => {
          return {
            ...x,
            ref: React.createRef(),
            children: getRefToFilterValues(x.children),
          };
        });
      },
      []
    );

    const getFilterGroups =
      React.useCallback((): ColumnFArticlePropFilterInterface[] => {
        if (!fArticle?.fArticleProp?.filters) {
          return [];
        }
        let result: ColumnFArticlePropFilterInterface[] = Array.from(
          Array(thisNbColumns)
        ).map(() => {
          return {
            size: 0,
            fArticlePropFormFilters: [],
          };
        });
        const newFilterIds = fArticle.fArticleProp?.filterArticles?.map(
          (x) => x.filterId
        );
        for (const filter of fArticle.fArticleProp?.filters) {
          if (filter.hasValue) {
            continue;
          }
          filter.hasValue =
            fArticle.fArticleProp?.filterRelevants?.find(
              (filterRelevant) => filterRelevant.filterId === filter.id
            ) !== undefined;
          if (filter.hasValue) {
            continue;
          }
          filter.hasValue =
            fArticle.fArticleProp?.filterValueArticles?.find(
              (filterValueArticle) =>
                filterIsEqual(filterValueArticle.filter, filter.id)
            ) !== undefined;
          if (filter.hasValue) {
            continue;
          }
          filter.hasValue =
            fArticle.fArticleProp?.filterValueNumerics.find(
              (filterValueNumeric) =>
                filterIsEqual(filterValueNumeric.filter, filter.id)
            ) !== undefined;
        }
        fArticle.fArticleProp?.filters.sort((a, b) => {
          if (newFilterIds?.includes(a.id) && !newFilterIds?.includes(b.id)) {
            return -1;
          }
          if (newFilterIds?.includes(b.id) && !newFilterIds?.includes(a.id)) {
            return 1;
          }
          if (!a.hasValue && b.hasValue) {
            return -1;
          }
          if (!b.hasValue && a.hasValue) {
            return 1;
          }
          return 0;
        });
        for (const filter of fArticle.fArticleProp?.filters) {
          let index = 0;
          let i = 0;
          let minSize = result[index].size;
          for (const columnFArticlePropFilter of result) {
            if (columnFArticlePropFilter.size < minSize) {
              index = i;
              minSize = columnFArticlePropFilter.size;
            }
            i++;
          }
          const filterValueNumeric =
            fArticle.fArticleProp?.filterValueNumerics.find(
              (filterValueNumeric) =>
                filterIsEqual(filterValueNumeric.filter, filter.id)
            );
          result[index].fArticlePropFormFilters.push({
            ...filter,
            filterValues: getRefToFilterValues(filter.filterValues),
            ref: React.createRef(),
            filterValueNumeric: filterValueNumeric
              ? filterValueNumeric.value
              : null,
            relevant:
              fArticle.fArticleProp?.filterRelevants?.find(
                (x) => x.filterId === filter.id
              ) !== undefined,
            new: !!newFilterIds?.includes(filter.id) || !filter.hasValue,
          });
          result[index].size += filter.filterValues.length;
        }
        result = result.filter((x) => x.fArticlePropFormFilters.length > 0);
        return result;
      }, [
        fArticle?.fArticleProp?.filterArticles,
        fArticle?.fArticleProp?.filterRelevants,
        fArticle?.fArticleProp?.filterValueArticles,
        fArticle?.fArticleProp?.filterValueNumerics,
        fArticle?.fArticleProp?.filters,
        getRefToFilterValues,
        thisNbColumns,
      ]);

    const [filterGroups, setFilterGroups] = React.useState<
      ColumnFArticlePropFilterInterface[]
    >(getFilterGroups());

    useImperativeHandle(ref, () => ({
      getValue() {
        const fArticlePropFormFilters: FArticlePropFilterFormInterface[] =
          [].concat.apply(
            [],
            // @ts-ignore
            filterGroups.map((x) => x.fArticlePropFormFilters)
          );
        return fArticlePropFormFilters.map((x) => x.ref.current.getValue());
      },
    }));

    React.useEffect(() => {
      setThisNbColumns(nbColumns ?? 2);
    }, [nbColumns, fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setFilterGroups(getFilterGroups());
    }, [fArticle, thisNbColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {fArticle && (
          <Box sx={{ display: "flex" }}>
            {filterGroups.map((filterGroup, indexFilterGroup) => (
              <Box key={indexFilterGroup} sx={{ flex: 1, paddingX: 0.5 }}>
                {filterGroup.fArticlePropFormFilters.map(
                  (fArticlePropFormFilter, indexFArticlePropFormFilter) => (
                    <FormFArticlePropFilterComponent
                      ref={fArticlePropFormFilter.ref}
                      fArticle={fArticle}
                      fArticlePropFormFilter={fArticlePropFormFilter}
                      key={indexFArticlePropFormFilter}
                    />
                  )
                )}
              </Box>
            ))}
          </Box>
        )}
      </>
    );
  })
);

export default FilterAdminProductFilterValueComponent;
