import React from "react";
import Layout from "../../components/common/Layout";
import LoginComponent from "../../components/common/user/LoginComponent";
import { Container } from "@mui/material";
import ConsoleComptoirComponent from "../../components/common/console/ConsoleComptoirComponent";
import { getTitle } from "../../helpers/SearchParamHelper";

const ConsoleScreen: React.FC = React.memo(() => {
  React.useEffect(() => {
    document.title = getTitle("console");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Container maxWidth="xl" sx={{ marginY: 2 }}>
          <ConsoleComptoirComponent />
        </Container>
      </LoginComponent>
    </Layout>
  );
});

export default ConsoleScreen;
