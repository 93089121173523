import React, { useRef } from "react";
import Aside from "./Aside";
import AppBarDashboard from "./AppBarDashboard";
import { useMediaQuery, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import DrawerComponent from "./DrawerComponent";
import { useContainerDimensions } from "./useContainerDimensions";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import CartComponent from "./cart/CartComponent";
import { COLLAPSED, COOKIE_TOKEN } from "../../utils/StorageUtils";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useWindowDimensions from "./useWindowDimensions";
import FloComponent from "./FloComponent";

const minWidth = 500;

interface State {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  mainRef?: any;
  onClickMain?: any;
}

const Layout: React.FC<State> = React.memo(
  ({ children, sx, mainRef, onClickMain }) => {
    const [collapsed, setCollapsed] = React.useState(
      localStorage.getItem(COLLAPSED) === "true" ?? false
    );
    const toggled = useAppSelector(
      (state: RootState) => state.globalState.toggled ?? false
    );
    const openCart = useAppSelector(
      (state: RootState) => state.globalState.openCart
    );
    const hasAcceptCookie = useAppSelector(
      (state: RootState) => state.globalState.hasAcceptCookie
    );
    const componentRef = useRef();
    const { width } = useContainerDimensions(componentRef);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { height } = useWindowDimensions();

    const handleToggleSidebar = React.useCallback(() => {
      if (isMobile) {
        dispatch(set({ toggled: !toggled }));
      } else {
        setCollapsed((x) => {
          localStorage.setItem(COLLAPSED, (!x).toString());
          return !x;
        });
      }
    }, [dispatch, isMobile, toggled]);

    const closeCartDrawer = React.useCallback(() => {
      dispatch(set({ openCart: false }));
    }, [dispatch]);

    const openCartDrawer = React.useCallback(() => {
      dispatch(set({ openCart: true }));
    }, [dispatch]);

    const acceptCookie = React.useCallback(() => {
      localStorage.setItem(COOKIE_TOKEN, "true");
      dispatch(set({ hasAcceptCookie: true }));
    }, [dispatch]);

    React.useEffect(() => {
      if (isMobile) {
        dispatch(set({ toggled: false }));
      }
    }, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box
        className={`container-dashboard ${toggled ? "toggled" : ""}`}
        sx={{ height: height }}
        ref={componentRef}
      >
        <AppBarDashboard
          handleToggleSidebar={handleToggleSidebar}
          openCartDrawer={openCartDrawer}
        />
        <div className="d-flex flex-grow h-0 position-relative">
          <Aside
            collapsed={collapsed && !isMobile}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
          />
          <Box
            ref={mainRef}
            component="main"
            sx={{
              width: "100%",
              overflow: "auto",
              ...sx,
            }}
            {...(onClickMain && { onClick: onClickMain })}
          >
            <FloComponent />
            {children}
          </Box>
        </div>
        <DrawerComponent
          open={openCart ?? false}
          onClose={closeCartDrawer}
          handleDrawerClose={closeCartDrawer}
          drawerwidth={width < minWidth ? width : minWidth}
          content={<CartComponent />}
        />
        {!hasAcceptCookie && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "black",
              padding: 2,
            }}
          >
            <Typography sx={{ color: "white" }}>
              {t("sentence.cookie")}
            </Typography>
            <Button
              variant="contained"
              onClick={acceptCookie}
              sx={{ marginLeft: 2, textTransform: "uppercase" }}
            >
              {t("word.ok")}
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);

export default Layout;
