import React from "react";
import Layout from "../../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import LoginComponent from "../../components/common/user/LoginComponent";
import { Container } from "@mui/material";
import ConfigurationGroupComponent from "../../components/common/configuration/ConfigurationGroupComponent";
import { getTitle } from "../../helpers/SearchParamHelper";

const AdminConfigurationScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);

  const load = React.useCallback(
    (force: boolean = false) => {
      setInit(true);
      dispatch(set({ refreshPage: false }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    document.title = getTitle("configuration");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Container maxWidth="xl" sx={{ marginY: 2 }}>
          <ConfigurationGroupComponent />
        </Container>
      </LoginComponent>
    </Layout>
  );
});

export default AdminConfigurationScreen;
