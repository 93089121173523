import { RootState, store } from "../app/store";
import { TYPE_BOOL, TYPE_NUM } from "../utils/ConfigurationUtils";

export const getConfigurationValue = (
  identifier: string
): string | number | boolean | undefined => {
  const state: RootState = store.getState();
  if (!state.globalState.configurations) {
    return undefined;
  }
  const configuration = state.globalState.configurations.find(
    (x) => x.identifier === identifier
  );
  if (configuration) {
    if (configuration.type === TYPE_BOOL) {
      return configuration.value === "1";
    }
    if (configuration.type === TYPE_NUM) {
      return Number(configuration.value);
    }
    return configuration.value;
  }
  return undefined;
};
