import React, { SyntheticEvent, useRef } from "react";
import { Autocomplete, Grid, TextField, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import {
  FilterFilterCategoryInterface,
  FilterFilterInterface,
  FilterFilterValueFormInterface,
} from "../../../../../../interfaces/FilterInterface";
import { RootState } from "../../../../../../app/store";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import { CATEGORY_URL, FILTER_URL } from "../../../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { LoadingButton } from "@mui/lab";
import notEmptyValidator from "../../../../../../helpers/validator/NotEmptyValidator";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import FilterFormImageComponent from "./FilterFormImageComponent";
import FilterFormVisibleComponent from "./FilterFormVisibleComponent";
import { createFilterValuesApi } from "../../../../../../helpers/FilterHelper";
import { PATCH, POST } from "../../../../../../utils/MethodUtils";
import { requestApi } from "../../../../../../helpers/RequestApi";
import FilterFormNameComponent from "./FilterFormNameComponent";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { set } from "../../../../../../app/globalSlice";
import FilterFormForceCreateFilterArticleComponent from "./FilterFormForceCreateFilterArticleComponent";
import getErrorApi from "../../../../../../helpers/GetErrorApi";
import { swapCard } from "../../../../../../helpers/CardFormat";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  CONDITION_AND,
  CONDITIONS_FILTER,
  TYPE_CHECKBOX,
  TYPE_NUMERIC,
  TYPES_FILTER,
} from "../../../../../../utils/FilterUtils";
import FilterFormParentComponent from "./FilterFormParentComponent";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import ConditionalFilterFormComponent from "./ConditionalFilterFormComponent";
import FilterFormHoverComponent from "./FilterFormHoverComponent";
import FormKeywordComponent from "./FormKeywordComponent";

const clone = require("clone");

interface State {
  filter?: FilterFilterInterface | null;
  successFunction: Function;
}

interface FormState {
  name: InputInterface;
  defaultActive: InputInterface;
  defaultOpened: InputInterface;
  defaultReference: InputInterface;
  onlyAdmin: InputInterface;
  filterCategories: InputInterface;
  filterValues: InputInterface;
  type: InputInterface;
  condition: InputInterface;
  defaultShowRestrictedValue: InputInterface;
}

export interface FormStateFilterForm extends FormState {}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const FilterFormComponent: React.FC<State> = React.memo(
  ({ filter, successFunction }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const [loading, setLoading] = React.useState(false);
    const [currentFocus, setCurrentFocus] = React.useState<string | undefined>(
      undefined
    );
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const categories = useAppSelector(
      (state: RootState) => state.globalState.categories
    );
    const refConditionalFilter: any = useRef();
    const dispatch = useAppDispatch();
    const [thisCategories] = React.useState<FilterFilterCategoryInterface[]>(
      categories
        ?.map((c) => c.children)
        .reduce((a, b) => {
          // @ts-ignore
          return a.concat(b);
        }, [])
        ?.map((c) => {
          return {
            category: {
              id: c.id,
              name: c.name,
              parent: c.parent?.toString(),
              slug: c.slug,
            },
            sort: 9999,
            opened: false,
          };
        }) ?? []
    );
    const getDefaultValue = React.useCallback((): FormState => {
      const newFilterValues: FilterFilterValueFormInterface[] =
        filter?.filterValues
          ? [...filter.filterValues].map((filterValue) => {
              return {
                ...filterValue,
                nbParent: 0,
                draggableId: (Math.random() + 1)
                  .toString(36)
                  .substring(7)
                  .toString(),
                refName: React.createRef(),
                refParent: React.createRef(),
                refImage: React.createRef(),
                refVisible: React.createRef(),
                refHover: React.createRef(),
                refKeywords: React.createRef(),
                refForceCreateFilterArticle: React.createRef(),
              };
            })
          : [];
      return {
        name: { value: filter?.name ?? "", error: "" },
        defaultActive: { value: filter?.defaultActive ?? true, error: "" },
        defaultReference: {
          value: filter?.defaultReference ?? false,
          error: "",
        },
        onlyAdmin: { value: filter?.onlyAdmin ?? false, error: "" },
        defaultOpened: { value: filter?.defaultOpened ?? false, error: "" },
        filterCategories: {
          value: filter?.filterCategories ? [...filter.filterCategories] : [],
          error: "",
        },
        filterValues: {
          value: newFilterValues,
          error: "",
        },
        type: { value: filter?.type ?? TYPE_CHECKBOX, error: "" },
        condition: { value: filter?.condition ?? CONDITION_AND, error: "" },
        defaultShowRestrictedValue: {
          value: filter?.defaultShowRestrictedValue ?? false,
          error: "",
        },
      };
    }, [filter]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());
    const getForceCreateFilterArticle = React.useCallback(() => {
      return (
        !filter?.id ||
        !!values.filterValues.value.find((x: any) => x.id === null)
      );
    }, [filter?.id, values.filterValues.value]);
    const [forceCreateFilterArticle, setForceCreateFilterArticle] =
      React.useState<boolean>(getForceCreateFilterArticle());

    const save = React.useCallback(async () => {
      setLoading(true);
      const nameError = notEmptyValidator(values.name.value);
      let filterValues: any[] = [];
      if (values.type.value !== TYPE_NUMERIC) {
        filterValues = createFilterValuesApi(values.filterValues.value);
      }
      const allNames = filterValues.map((x) => x.name);
      const duplicates = allNames.filter(
        (item, index) => allNames.indexOf(item) !== index
      );
      const hasValidNames = allNames.filter((x) => x === "").length === 0;
      let filterCategoriesError = notEmptyValidator(
        values.filterCategories.value
      );
      let filterValuesError = "";
      if (values.type.value !== TYPE_NUMERIC) {
        filterValuesError = notEmptyValidator(values.filterValues.value);
      }
      if (filter?.custom) {
        filterCategoriesError = "";
        filterValuesError = "";
      }
      if (
        nameError ||
        filterCategoriesError ||
        filterValuesError ||
        !hasValidNames ||
        duplicates.length > 0
      ) {
        const newValue: FormState = { ...values };
        if (nameError) {
          newValue.name.error = nameError;
        }
        if (filterCategoriesError) {
          newValue.filterCategories.error = filterCategoriesError;
        }
        if (filterValuesError) {
          newValue.filterValues.error = filterValuesError;
        }
        for (const f of values.filterValues.value) {
          if (duplicates.includes(f.refName.current.getValue())) {
            f.refName.current.setError(t("error.not_duplicate"));
          }
        }
        setValues(newValue);
        setLoading(false);
        return;
      }
      let url = FILTER_URL;
      if (filter) {
        url += "/" + filter.id;
      }
      let biggestSort = 1;
      if (filter) {
        biggestSort = Math.max(
          ...filter.filterCategories.map(
            (filterCategory) => filterCategory.sort
          )
        );
      }
      const response = await requestApi({
        method: filter ? PATCH : POST,
        path: url,
        allowError: true,
        token: token,
        body: {
          name: values.name.value,
          defaultActive: values.defaultActive.value,
          defaultReference: values.defaultReference.value,
          onlyAdmin: values.onlyAdmin.value,
          defaultOpened: values.defaultOpened.value,
          type: values.type.value,
          defaultShowRestrictedValue: values.defaultShowRestrictedValue.value,
          condition: values.condition.value,
          ...(!filter?.custom && {
            filterValues:
              values.type.value === TYPE_NUMERIC ? [] : filterValues,
            filterCategories: values.filterCategories.value.map(
              (
                filterCategory: FilterFilterCategoryInterface,
                indexFilterCategory: number
              ) => {
                let currentSort = indexFilterCategory + 1;
                if (filter) {
                  const thisFilterCategory = filter.filterCategories.find(
                    (x) => x.id === filterCategory.id
                  );
                  if (thisFilterCategory) {
                    currentSort = thisFilterCategory.sort;
                  } else {
                    biggestSort++;
                    currentSort = biggestSort;
                  }
                }
                return {
                  category: CATEGORY_URL + "/" + filterCategory.category.id,
                  sort: currentSort,
                };
              }
            ),
          }),
          conditionalFilters: [
            {
              filterValues: refConditionalFilter.current.getValue(),
            },
          ],
        },
      });
      if (response.statusCode === 200 || response.statusCode === 201) {
        dispatch(set({ nbFilterArticle: response.content.nbFilterArticle }));
        successFunction();
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [dispatch, filter, successFunction, t, token, values]);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const addValueInputValue = React.useCallback(() => {
      const draggableId = (Math.random() + 1)
        .toString(36)
        .substring(7)
        .toString();
      setValues((v) => {
        const newFilterValue: FilterFilterValueFormInterface = {
          id: null,
          name: "",
          tempName: "",
          draggableId: draggableId,
          parent: null,
          filterImage: null,
          show: true,
          hover: false,
          keywords: null,
          refName: React.createRef(),
          nbParent: 0,
          refParent: React.createRef(),
          refImage: React.createRef(),
          refVisible: React.createRef(),
          refHover: React.createRef(),
          refKeywords: React.createRef(),
          refForceCreateFilterArticle: React.createRef(),
        };
        v.filterValues.value.push({ ...newFilterValue });
        v.filterValues.error = "";
        return { ...v };
      });
      setCurrentFocus(draggableId);
    }, []);

    const removeValueInputValue = React.useCallback(
      (index: number) => (e: any) => {
        setValues((v) => {
          v.filterValues.value.splice(index, 1);
          return { ...v };
        });
      },
      []
    );

    const onDragEnd = React.useCallback((result: DropResult) => {
      if (
        result.destination === null ||
        result.destination === undefined ||
        result.source === null ||
        result.source === undefined
      ) {
        return;
      }
      setValues((v) => {
        v.filterValues.value = swapCard(v.filterValues.value, result);
        return { ...v };
      });
    }, []);

    const handleChangeCheckbox = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      []
    );

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      []
    );

    const afterChangeValues = React.useCallback((): FormState => {
      const newValues: FormState = clone(values);
      let hasChangeParent = true;
      let nbParentChanged = false;
      while (hasChangeParent) {
        hasChangeParent = false;
        for (const filterValue of newValues.filterValues.value) {
          if (filterValue.parent) {
            const filterValueParent = newValues.filterValues.value.find(
              (x: FilterFilterValueFormInterface) =>
                x.id?.toString() === filterValue.parent.id.toString()
            );
            if (filterValue.nbParent !== filterValueParent.nbParent + 1) {
              hasChangeParent = true;
              nbParentChanged = true;
              filterValue.nbParent = filterValueParent.nbParent + 1;
            }
          } else if (filterValue.nbParent !== 0) {
            nbParentChanged = true;
            hasChangeParent = true;
            filterValue.nbParent = 0;
          }
        }
      }
      let hasChangeSort = true;
      let sortChanged = false;
      while (hasChangeSort) {
        hasChangeSort = false;
        for (const [
          index,
          filterValue,
        ] of newValues.filterValues.value.entries()) {
          const allParentIds = [];
          let i = 1;
          while (newValues.filterValues.value[index - i]?.parent?.id) {
            allParentIds.push(
              newValues.filterValues.value[index - i]?.parent?.id
            );
            i++;
          }
          if (
            filterValue.parent === null ||
            (index !== 0 &&
              [
                ...allParentIds,
                newValues.filterValues.value[index - 1].id,
              ].includes(filterValue.parent.id))
          ) {
            continue;
          }
          const newIndex =
            newValues.filterValues.value.findIndex(
              (x: FilterFilterValueFormInterface) =>
                x.id?.toString() === filterValue.parent.id.toString()
            ) + 1;
          if (newIndex === index) {
            continue;
          }
          hasChangeSort = true;
          sortChanged = true;
          const element = newValues.filterValues.value[index];
          newValues.filterValues.value.splice(index, 1);
          newValues.filterValues.value.splice(newIndex, 0, element);
        }
      }
      if (nbParentChanged || sortChanged) {
        return newValues;
      }
      return values;
    }, [values]);

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [user, filter]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setForceCreateFilterArticle(getForceCreateFilterArticle());
      setValues(afterChangeValues());
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item sm={12} sx={{ width: "100%" }}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.name.error}
              helperText={t(values.name.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.name.value}
              onChange={handleChange("name")}
              label={t("field.name")}
            />
          </Grid>
          <Grid item sm={12} md={6} sx={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.defaultActive.value}
                    onChange={handleChangeCheckbox("defaultActive")}
                  />
                }
                label={t("word.defaultActive")}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={12} md={6} sx={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.defaultOpened.value}
                    onChange={handleChangeCheckbox("defaultOpened")}
                  />
                }
                label={t("word.defaultOpened")}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={12} md={6} sx={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.onlyAdmin.value}
                    onChange={handleChangeCheckbox("onlyAdmin")}
                  />
                }
                label={t("word.onlyAdmin")}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={12} md={6} sx={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.defaultReference.value}
                    onChange={handleChangeCheckbox("defaultReference")}
                  />
                }
                label={t("word.defaultReference")}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            sx={{ width: "100%", ...(filter?.custom && { display: "none" }) }}
          >
            <FormControl fullWidth required>
              <InputLabel id="type-label">{t("field.type")}</InputLabel>
              <Select
                labelId="type-label"
                value={values.type.value}
                label={t("field.type")}
                onChange={handleChangeSelect("type")}
              >
                {TYPES_FILTER.map((type, indexType) => (
                  <MenuItem value={type} key={indexType}>
                    {t("word.filter.type." + type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            sx={{ width: "100%", ...(filter?.custom && { display: "none" }) }}
          >
            {values.type.value === TYPE_NUMERIC ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.defaultShowRestrictedValue.value}
                      onChange={handleChangeCheckbox(
                        "defaultShowRestrictedValue"
                      )}
                    />
                  }
                  label={t("word.defaultShowRestrictedValue")}
                />
              </FormGroup>
            ) : (
              <FormControl fullWidth required>
                <InputLabel id="condition-label">
                  {t("field.condition")}
                </InputLabel>
                <Select
                  disabled={values.type.value !== TYPE_CHECKBOX}
                  labelId="condition-label"
                  value={values.condition.value}
                  label={t("field.condition")}
                  onChange={handleChangeSelect("condition")}
                >
                  {CONDITIONS_FILTER.map((condition, indexType) => (
                    <MenuItem value={condition} key={indexType}>
                      {t("word.filter.condition." + condition)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          {!filter?.custom && (
            <>
              <Grid item sm={12} sx={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  fullWidth
                  disableCloseOnSelect={true}
                  options={thisCategories ?? []}
                  getOptionLabel={(
                    filterCategory: FilterFilterCategoryInterface
                  ) => {
                    return (
                      "[" +
                        filterCategory.category.id +
                        "] " +
                        filterCategory.category.name ?? ""
                    );
                  }}
                  isOptionEqualToValue={(
                    option: FilterFilterCategoryInterface,
                    value: FilterFilterCategoryInterface
                  ) => {
                    return option.category.id === value.category.id;
                  }}
                  groupBy={(option) =>
                    categories?.find(
                      (c) =>
                        CATEGORY_URL + "/" + c.id === option.category.parent
                    )?.name ?? ""
                  }
                  renderOption={(props, option) => {
                    return (
                      <li
                        {...props}
                        key={option.category.id}
                        style={{ display: "block" }}
                      >
                        <span style={{ color: theme.palette.primary.main }}>
                          {"[" + option.category.id + "] "}
                        </span>
                        {option.category.name}
                      </li>
                    );
                  }}
                  onChange={handleChangeAutocomplete("filterCategories")}
                  value={values.filterCategories.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      autoComplete="off"
                      error={!!values.filterCategories.error}
                      helperText={t(values.filterCategories.error ?? "")}
                      label={t("word.categories")}
                      placeholder={t("word.categories")}
                    />
                  )}
                />
              </Grid>
              {values.type.value !== TYPE_NUMERIC && (
                <Grid item sm={12} sx={{ width: "100%" }}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="content">
                      {(provided) => (
                        <div ref={provided.innerRef}>
                          <TableContainer
                            component={Paper}
                            sx={{ boxShadow: "none" }}
                          >
                            <Table size="small" sx={{ height: "1px" }}>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell />
                                  <StyledTableCell sx={{ paddingRight: 0 }}>
                                    {t("column.id")}
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ paddingRight: 0 }}>
                                    {t("column.value")}
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ paddingRight: 0 }}>
                                    {t("column.parent")}
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ paddingRight: 0 }}>
                                    {t("word.image")}
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ paddingRight: 0 }}>
                                    <Box sx={{ display: "flex" }}>
                                      {t("word.hover")}
                                      <Tooltip
                                        title={t("sentence.filterValuesHover")}
                                        placement="top"
                                      >
                                        <InfoIcon sx={{ color: "white" }} />
                                      </Tooltip>
                                    </Box>
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ paddingRight: 0 }}>
                                    <Box sx={{ display: "flex" }}>
                                      {t("word.visible")}
                                      <Tooltip
                                        title={t(
                                          "sentence.filterValuesVisible"
                                        )}
                                        placement="top"
                                      >
                                        <InfoIcon sx={{ color: "white" }} />
                                      </Tooltip>
                                    </Box>
                                  </StyledTableCell>
                                  <StyledTableCell sx={{ paddingRight: 0 }}>
                                    {t("word.keywords")}
                                  </StyledTableCell>
                                  <StyledTableCell />
                                  {forceCreateFilterArticle && (
                                    <StyledTableCell sx={{ paddingRight: 0 }}>
                                      <Box sx={{ display: "flex" }}>
                                        {t("word.forceCreateFilterArticle")}
                                        <Tooltip
                                          title={t(
                                            "sentence.forceCreateFilterArticle"
                                          )}
                                          placement="top"
                                        >
                                          <InfoIcon sx={{ color: "white" }} />
                                        </Tooltip>
                                      </Box>
                                    </StyledTableCell>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.filterValues.value.map(
                                  (
                                    filterValue: FilterFilterValueFormInterface,
                                    indexFilterValue: number
                                  ) => (
                                    <Draggable
                                      key={filterValue.draggableId}
                                      draggableId={filterValue.draggableId}
                                      index={indexFilterValue}
                                    >
                                      {(provided) => (
                                        <TableRow
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          key={indexFilterValue}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell
                                            sx={{ padding: 0, border: 0 }}
                                            component="th"
                                            scope="row"
                                          >
                                            <Box
                                              sx={{
                                                ...(filterValue.nbParent >
                                                  0 && {
                                                  height: "100%",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  marginLeft:
                                                    12 * filterValue.nbParent +
                                                    "px",
                                                  borderLeft: "gray solid 1px",
                                                }),
                                              }}
                                            >
                                              <span
                                                style={{
                                                  display: "flex",
                                                  cursor: "move",
                                                  marginRight: 1,
                                                }}
                                                {...provided.dragHandleProps}
                                              >
                                                <MenuIcon />
                                              </span>
                                            </Box>
                                          </TableCell>
                                          <TableCell
                                            sx={{ paddingX: 0 }}
                                            component="th"
                                            scope="row"
                                          >
                                            {filterValue.id}
                                          </TableCell>
                                          <TableCell
                                            sx={{ paddingX: 0 }}
                                            component="th"
                                            scope="row"
                                          >
                                            <FilterFormNameComponent
                                              filterValue={filterValue}
                                              ref={filterValue.refName}
                                              setValues={setValues}
                                              currentFocus={currentFocus}
                                              setCurrentFocus={setCurrentFocus}
                                            />
                                          </TableCell>
                                          <TableCell
                                            sx={{ paddingX: 0 }}
                                            component="th"
                                            scope="row"
                                          >
                                            <FilterFormParentComponent
                                              filter={filter}
                                              filterValue={filterValue}
                                              ref={filterValue.refParent}
                                              setValues={setValues}
                                            />
                                          </TableCell>
                                          <TableCell
                                            sx={{ paddingX: 0 }}
                                            component="th"
                                            scope="row"
                                          >
                                            <FilterFormImageComponent
                                              ref={filterValue.refImage}
                                              filterValue={filterValue}
                                              setValues={setValues}
                                            />
                                          </TableCell>
                                          <TableCell
                                            sx={{ paddingX: 0 }}
                                            component="th"
                                            scope="row"
                                          >
                                            <FilterFormHoverComponent
                                              ref={filterValue.refHover}
                                              filterValue={filterValue}
                                              setValues={setValues}
                                            />
                                          </TableCell>
                                          <TableCell
                                            sx={{ paddingX: 0 }}
                                            component="th"
                                            scope="row"
                                          >
                                            <FilterFormVisibleComponent
                                              ref={filterValue.refVisible}
                                              filterValue={filterValue}
                                              setValues={setValues}
                                            />
                                          </TableCell>
                                          <TableCell
                                            sx={{ paddingX: 0 }}
                                            component="th"
                                            scope="row"
                                          >
                                            <FormKeywordComponent
                                              initKeywords={
                                                filterValue.keywords
                                              }
                                              ref={filterValue.refKeywords}
                                              setParentValues={setValues}
                                            />
                                          </TableCell>
                                          <TableCell
                                            sx={{ paddingX: 0 }}
                                            component="th"
                                            scope="row"
                                          >
                                            <IconButton
                                              onClick={removeValueInputValue(
                                                indexFilterValue
                                              )}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </TableCell>
                                          {forceCreateFilterArticle && (
                                            <TableCell
                                              sx={{ paddingX: 0 }}
                                              component="th"
                                              scope="row"
                                            >
                                              {!filterValue.id && (
                                                <FilterFormForceCreateFilterArticleComponent
                                                  ref={
                                                    filterValue.refForceCreateFilterArticle
                                                  }
                                                />
                                              )}
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <Box sx={{ textAlign: "center" }}>
                    <IconButton onClick={addValueInputValue}>
                      <AddIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                </Grid>
              )}
              <Grid item sm={12} sx={{ width: "100%" }}>
                <ConditionalFilterFormComponent
                  ref={refConditionalFilter}
                  filter={filter}
                  categoryIds={
                    values.filterCategories.value?.map(
                      (filterCategory: FilterFilterCategoryInterface) =>
                        filterCategory.category.id
                    ) ?? []
                  }
                />
              </Grid>
            </>
          )}
          <Grid item sm={12} sx={{ textAlign: "right", width: "100%" }}>
            <LoadingButton variant="contained" loading={loading} onClick={save}>
              {t("word.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default FilterFormComponent;
