import React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

interface State {
  data: any;
}

const VideoDomComponent: React.FC<State> = React.memo(({ data }) => {
  return (
    <video
      style={{
        maxWidth: "100%",
      }}
      // muted because of https://stackoverflow.com/a/17994667/6824121
      {...(data.autoPlay && { autoPlay: true, muted: true })}
      {...(data.controls && { controls: true })}
      {...(data.loop && { loop: true })}
      {...(data.muted && { muted: true })}
      {...(data.width &&
        data.widthValue && {
          width: data.widthValue + "px",
        })}
      {...(data.height &&
        data.heightValue && {
          height: data.heightValue + "px",
        })}
      src={process.env.REACT_APP_API_URL + "/DATA" + data.video}
    ></video>
  );
});

const VideoDisplayComponent: React.FC<State> = React.memo(({ data }) => {
  const getThisUrl = React.useCallback(() => {
    if (!data?.url) {
      return false;
    }
    const parseUrl1 = new URL(data.url);
    const parseUrl2 = new URL(window.location.toString());
    const isLocalUrl = parseUrl1.origin === parseUrl2.origin;
    return {
      localUrl: isLocalUrl,
      url: isLocalUrl ? parseUrl1.pathname + parseUrl1.search : data.url,
    };
  }, [data?.url]);
  const [thisUrl, setThisUrl] = React.useState<any>(getThisUrl());

  React.useEffect(() => {
    setThisUrl(getThisUrl());
  }, [data?.url]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ textAlign: data.textAlign }}>
      {data?.url ? (
        <>
          {thisUrl.localUrl ? (
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={thisUrl.url}
            >
              <VideoDomComponent data={data} />
            </Link>
          ) : (
            <a href={thisUrl.url} target="_blank" rel="noreferrer">
              <VideoDomComponent data={data} />
            </a>
          )}
        </>
      ) : (
        <VideoDomComponent data={data} />
      )}
    </Box>
  );
});

export default VideoDisplayComponent;
