import React, { forwardRef, useImperativeHandle, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputInterface } from "../../../../interfaces/InputInterface";
import {
  ALL_SEARCH_PRODUCT,
  SEARCH_PRODUCT_AT_LEAST_ONE,
} from "../../../../utils/ContentUtils";
import CkEditorComponent from "../../CkEditorComponent";

interface State {
  data: any;
}

interface FormState {
  type: InputInterface;
}

const SearchProductTextSingleComponent = React.memo(
  forwardRef(({ data }: State, ref) => {
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        type: { value: data?.type ?? SEARCH_PRODUCT_AT_LEAST_ONE, error: "" },
      };
    }, [data?.type]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const { t } = useTranslation();
    const ckEditorRef: any = useRef();

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          type: values.type.value,
          text: ckEditorRef.current.getValue(),
        };
      },
    }));

    return (
      <>
        {data !== undefined && (
          <>
            <FormControl fullWidth required>
              <Select
                value={values.type.value}
                onChange={handleChangeSelect("type")}
              >
                {ALL_SEARCH_PRODUCT.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {t("word.searchProduct." + value)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CkEditorComponent ref={ckEditorRef} text={data?.text ?? ""} />
          </>
        )}
      </>
    );
  })
);

export default SearchProductTextSingleComponent;
