import React, { useRef } from "react";
import { TextField } from "@mui/material";

interface State {
  currentFocus: string | undefined | null;
  onChange: Function;
  running: boolean;
}

const SearchEntreeStockComponent: React.FC<State> = React.memo(
  ({ currentFocus, onChange, running }) => {
    const [newSearch, setNewSearch] = React.useState<any>({
      text: "",
      select: true,
    });
    const [search, setSearch] = React.useState<string>("");
    const searchRef: any = useRef();

    React.useEffect(() => {
      let timeoutTyping = setTimeout(() => {});
      const regexCodeBarre = /^(\d{13})?$/;
      const codeBarre = search.replace(newSearch.text, "");
      if (
        search !== newSearch.text &&
        search.startsWith(newSearch.text) &&
        regexCodeBarre.test(codeBarre) &&
        newSearch.text !== ""
      ) {
        setSearch(codeBarre);
        return;
      } else {
        timeoutTyping = setTimeout(() => {
          setNewSearch({
            text: search,
            select: regexCodeBarre.test(search),
          });
        }, 500);
      }
      return () => clearTimeout(timeoutTyping);
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (
        (currentFocus === undefined || currentFocus === null) &&
        searchRef.current
      ) {
        searchRef.current.focus();
        searchRef.current.select();
      }
    }, [currentFocus, searchRef?.current]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      searchRef.current.focus();
      if (newSearch.select) {
        searchRef.current.select();
      }
      onChange(newSearch.text);
    }, [newSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (!running) {
        searchRef.current.focus();
        searchRef.current.select();
      }
    }, [running]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <TextField
        autoFocus={currentFocus === undefined || currentFocus === null}
        inputRef={searchRef}
        disabled={running}
        fullWidth={true}
        autoComplete="off"
        type="text"
        inputProps={{
          style: {
            padding: 2,
            backgroundColor: "white",
          },
        }}
        value={search}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(event.target.value)
        }
      />
    );
  }
);

export default SearchEntreeStockComponent;
