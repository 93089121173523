import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import { FormStateFilterForm } from "./FilterFormComponent";
import { FilterFilterValueFormInterface } from "../../../../../../interfaces/FilterInterface";

interface State {
  initKeywords: string[] | null | undefined;
  setParentValues?: Function;
}

interface FormState {
  keywords: InputInterface;
}

const FormKeywordComponent = React.memo(
  React.forwardRef(({ initKeywords, setParentValues }: State, ref) => {
    const { t } = useTranslation();
    const [valueInputValue, setValueInputValue] = React.useState("");
    const handleValueInputValueChange = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: string) => {
        try {
          const json = JSON.parse(value);
          if (typeof json[Symbol.iterator] === "function") {
            setValues((v) => {
              v.keywords.value = [...v.keywords.value, ...json];
              v.keywords.error = "";
              return { ...v };
            });
          } else {
            setValueInputValue(value);
          }
        } catch (e) {
          setValueInputValue(value);
        }
      },
      []
    );
    const getDefaultValues = React.useCallback((): FormState => {
      let keywords = initKeywords ?? [];
      if (keywords) {
        keywords = [...keywords];
      }
      return {
        keywords: {
          value: keywords,
          error: "",
        },
      };
    }, [initKeywords]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      return {
        keywords:
          values.keywords.value.length > 0
            ? values.keywords.value
                .map((x: string) => x.trim())
                .filter((x: string) => x !== "")
            : null,
      };
    }, [values.keywords.value]);

    const addValueInputValue = React.useCallback(() => {
      const newValue = valueInputValue.trim();
      setValues((v) => {
        v.keywords.value.push(newValue);
        v.keywords.error = "";
        return { ...v };
      });
      setValueInputValue("");
    }, [valueInputValue]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (setParentValues) {
        return;
      }
      setValues(getDefaultValues());
    }, [initKeywords]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (!setParentValues) {
        return;
      }
      setParentValues((x: FormStateFilterForm) => {
        const thisFilterValue = x.filterValues.value.find(
          (y: FilterFilterValueFormInterface) => y.refKeywords === ref
        );
        thisFilterValue.keywords = values.keywords.value;
        return { ...x };
      });
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Autocomplete
        multiple
        fullWidth
        open={false}
        popupIcon={""}
        options={[]}
        inputValue={valueInputValue}
        onInputChange={handleValueInputValueChange}
        onChange={handleChangeAutocomplete("keywords")}
        value={values.keywords.value}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                addValueInputValue();
              }
            }}
            required
            autoComplete="off"
            error={!!values.keywords.error}
            helperText={t(values.keywords.error ?? "")}
            label={t("word.keywords")}
            placeholder={t("word.keywords")}
          />
        )}
      />
    );
  })
);

export default FormKeywordComponent;
