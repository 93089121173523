import {
  FLivraisonInterface,
  UserInterface,
} from "../interfaces/UserInterface";
import {
  ALL_COUNTRIES_DELIVERY,
  CT_CONTROLENC_COMPTE_BLOQUE,
  USER_BON_DE_COMMANDE,
  USER_EN_COMPTE,
} from "../utils/UserUtils";
import { RootState, store } from "../app/store";
import { FPaysInterface } from "../interfaces/FPaysInterface";

export const isUserBonDeCommande = (
  user: UserInterface | undefined
): boolean => {
  if (user === undefined || user.modeDeCommande === undefined) {
    return false;
  }
  return USER_BON_DE_COMMANDE.includes(user.modeDeCommande);
};

export const isUserEnCompte = (user: UserInterface | undefined): boolean => {
  if (user === undefined || user.modeDeCommande === undefined) {
    return false;
  }
  return USER_EN_COMPTE.includes(user.modeDeCommande);
};

export const getMainLivraison = (): FLivraisonInterface | undefined => {
  const state: RootState = store.getState();
  const livraisons = state.globalState.user?.fLivraisons;
  if (!livraisons || livraisons.length === 0) {
    return undefined;
  }
  let result = livraisons.find((l) => l.liPrincipal === 1);
  if (result) {
    return result;
  }
  return livraisons[0];
};

export const getThisPays = (): FPaysInterface[] => {
  const state: RootState = store.getState();
  const fPays = state.globalState.fPays;
  return (
    fPays?.filter((fPays) => {
      return ALL_COUNTRIES_DELIVERY.includes(fPays.paIntitule);
    }) ?? []
  );
};

export const userIsBlocked = (user: UserInterface | undefined): boolean => {
  return user?.ctControlenc === CT_CONTROLENC_COMPTE_BLOQUE;
};
