import * as React from "react";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { styled } from "@mui/material/styles";
import FFamilleMappingFormComponent from "./FFamilleMappingFormComponent";
import { FFamilleMappingInterface } from "../../../interfaces/ConfiguratorEnergyInterface";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { UPDATE_FFAMILLE_MAPPINGS_URL } from "../../../utils/UrlsUtils";
import { set } from "../../../app/globalSlice";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

interface FormInterface {
  fFamilleMapping: FFamilleMappingInterface;
  ref: any;
}

const FFamilleMappingComponent: React.FC = React.memo(() => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const fFamilleMappings = useAppSelector(
    (state: RootState) => state.globalState.fFamilleMappings
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const getNbMappingToDo = React.useCallback((): number => {
    return (
      fFamilleMappings?.filter(
        (x) => x.fFamilleId === null && x.mapped === false
      )?.length ?? 0
    );
  }, [fFamilleMappings]);
  const [nbMappingToDo, setNbMappingToDo] = React.useState<number>(
    getNbMappingToDo()
  );
  const getThisFFamilleMappings = React.useCallback((): FormInterface[] => {
    return (
      fFamilleMappings?.map((fFamilleMapping) => {
        return {
          fFamilleMapping: fFamilleMapping,
          ref: React.createRef(),
        };
      }) ?? []
    );
  }, [fFamilleMappings]);
  const [thisFFamilleMappings, setThisFFamilleMappings] = React.useState<
    FormInterface[]
  >(getThisFFamilleMappings());
  const submit = React.useCallback(async () => {
    setLoading(true);
    const form = thisFFamilleMappings.map((thisFFamilleMapping) =>
      thisFFamilleMapping.ref.current.getValue()
    );
    const hasErrors = form.filter((x) => x === undefined).length > 0;
    if (hasErrors) {
      setLoading(false);
      return;
    }
    const response = await requestApi({
      method: POST,
      path: UPDATE_FFAMILLE_MAPPINGS_URL,
      allowError: true,
      token: token,
      body: form,
    });
    if (response.statusCode === 200) {
      toastr.success(
        t("word.success"),
        t("sentence.notification.fFamilleMapping_created")
      );
      dispatch(set({ fFamilleMappings: response.content }));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [dispatch, t, thisFFamilleMappings, token]);

  React.useEffect(() => {
    setThisFFamilleMappings(getThisFFamilleMappings());
    setNbMappingToDo(getNbMappingToDo());
  }, [fFamilleMappings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Badge badgeContent={nbMappingToDo} color="info">
          <Typography sx={{ color: "white" }}>
            {t("word.fFamilleMappings")}
          </Typography>
        </Badge>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("word.dlhCategory")}</StyledTableCell>
                <StyledTableCell>{t("word.family")}</StyledTableCell>
                <StyledTableCell>{t("word.activateMapping")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {thisFFamilleMappings?.map(
                (thisFFamilleMapping, indexThisFFamilleMapping) => (
                  <FFamilleMappingFormComponent
                    index={indexThisFFamilleMapping}
                    key={thisFFamilleMapping.fFamilleMapping.id}
                    ref={thisFFamilleMapping.ref}
                  />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ marginTop: 2, textAlign: "right" }}>
          <LoadingButton variant="contained" loading={loading} onClick={submit}>
            {t("word.save")}
          </LoadingButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

export default FFamilleMappingComponent;
