import { GroupFArticleInterface } from "../screens/ProductsScreen";
import {
  CategoryInterface,
  FilterInterface,
  FilterValueInterface,
} from "../interfaces/CategoryInterface";
import { FArticleSmallInterface } from "../interfaces/FArticleInterface";
import { BucketInterface } from "../interfaces/BucketInterface";
import { CATEGORY_URL } from "../utils/UrlsUtils";
import { RootState, store } from "../app/store";

interface GetChildrenFilterValueInterface {
  depth: number;
  filterValue: FilterValueInterface;
}

export const shuffleArray = (array: any[]): any[] => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const groupArrayGetFilter = (
  filterId: number,
  category: CategoryInterface
): FilterInterface | undefined => {
  return category.filterCategories?.find((x) => x.filter.id === filterId)
    ?.filter;
};

const getFArticleFilterValues = (
  groupFArticle: GroupFArticleInterface
): number[] => {
  let fArticleFilterValues: number[] = [];
  for (const fArticle of groupFArticle.fArticles) {
    fArticleFilterValues = [
      ...fArticleFilterValues,
      // @ts-ignore
      ...fArticle.fArticleProp.filterValueArticles?.map(
        (x) => x.filterValue?.id
      ),
    ];
  }
  return fArticleFilterValues;
};

const getChildrenFilterValueGetAllFilterValues = (
  groupFArticle: GroupFArticleInterface
): number[] => {
  const result = [];
  for (const fArticle of groupFArticle.fArticles) {
    if (!fArticle.fArticleProp.filterValueArticles) {
      continue;
    }
    for (const filterValueArticle of fArticle.fArticleProp
      .filterValueArticles) {
      const filterValueId = filterValueArticle.filterValue?.id;
      if (!filterValueId) {
        continue;
      }
      result.push(filterValueId);
    }
  }
  // @ts-ignore
  return [...new Set(result)];
};

const _getChildrenFilterValue = (
  parentFilterValue: FilterValueInterface,
  allFilterValueIdArticles: number[],
  r: GetChildrenFilterValueInterface[],
  depth: number = 0
): void => {
  if (parentFilterValue.children) {
    for (const child of parentFilterValue.children) {
      if (allFilterValueIdArticles.includes(child.id)) {
        r.push({
          depth: depth,
          filterValue: child,
        });
      }
      if (child.children && child.children.length > 0) {
        _getChildrenFilterValue(child, allFilterValueIdArticles, r, depth + 1);
      }
    }
  }
};

export const getAggrCategories = (
  buckets: BucketInterface[] | undefined
): CategoryInterface[] => {
  const state: RootState = store.getState();
  const allCategories = state.globalState.categories;
  const result: CategoryInterface[] = [];
  if (!buckets || !allCategories) {
    return result;
  }
  for (const bucket of buckets) {
    for (const c1 of allCategories) {
      if (c1.id === bucket.key) {
        result.push({
          ...c1,
          children: [],
          doc_count: bucket.doc_count,
        });
        break;
      }
      if (c1.children) {
        for (const c2 of c1.children) {
          if (c2.id === bucket.key) {
            let oldC1 = result.find((c) => {
              return (
                typeof c2.parent === "string" &&
                c.id.toString() ===
                  // @ts-ignore
                  c2.parent?.replace(CATEGORY_URL + "/", "")
              );
            });
            if (!oldC1) {
              oldC1 = allCategories.find((c) => {
                return (
                  typeof c2.parent === "string" &&
                  c.id.toString() ===
                    // @ts-ignore
                    c2.parent?.replace(CATEGORY_URL + "/", "")
                );
              });
              if (oldC1) {
                oldC1 = {
                  ...oldC1,
                  children: [],
                  doc_count: 0,
                };
                result.push(oldC1);
              }
            }
            oldC1?.children?.push({
              ...c2,
              doc_count: bucket.doc_count,
            });
          }
        }
      }
    }
  }
  return result;
};

const getChildrenFilterValue = (
  parentFilterValues: FilterValueInterface[],
  groupFArticle: GroupFArticleInterface
): FilterValueInterface[] => {
  const allFilterValueIdArticles =
    getChildrenFilterValueGetAllFilterValues(groupFArticle);
  let result: GetChildrenFilterValueInterface[] = [];
  for (const parentFilterValue of parentFilterValues) {
    const r: GetChildrenFilterValueInterface[] = [];
    _getChildrenFilterValue(parentFilterValue, allFilterValueIdArticles, r);
    if (r.length > 0) {
      result = [...result, ...r];
    }
  }
  result.sort((a, b) => {
    return a.depth - b.depth;
  });
  return Object.values(
    result
      .map((r) => r.filterValue)
      .reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
  );
};

export const groupArray = (
  array: FArticleSmallInterface[] | undefined,
  nbGroup: number | undefined,
  category: CategoryInterface | undefined = undefined,
  searchParamsObject: any = undefined,
  aggrCategoryIds: any = undefined
): GroupFArticleInterface[] | undefined => {
  if (array === undefined || nbGroup === undefined) {
    return undefined;
  }
  const result: any[] = [];
  let currentArray: any[] = [];
  for (let i = 0; i < array.length; i++) {
    if (i > 0 && i % nbGroup === 0) {
      result.push(currentArray);
      currentArray = [];
    }
    currentArray.push(array[i]);
  }
  if (currentArray.length > 0) {
    result.push(currentArray);
  }
  const groupFArticles: GroupFArticleInterface[] = [];
  for (const fArticles of result) {
    groupFArticles.push({
      title: "",
      subTitle: "",
      fArticles: fArticles,
    });
  }
  if (groupFArticles && groupFArticles.length > 0 && searchParamsObject) {
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (
        !key.startsWith("order[sortFilters.") &&
        key !== "order[categories]"
      ) {
        continue;
      }
      let previousTitle = "";
      let previousSubTitle = "";
      for (const groupFArticle of groupFArticles) {
        if (category) {
          const filterId = Number(
            key.replace("order[sortFilters.", "").replace("]", "")
          );
          const filter = groupArrayGetFilter(filterId, category);
          if (!filter) {
            break;
          }
          let fArticleFilterValues: number[] =
            getFArticleFilterValues(groupFArticle);
          if (!fArticleFilterValues || fArticleFilterValues.length === 0) {
            continue;
          }
          const filterValueOrders = filter.filterValues
            .map((x) => x.id)
            .filter((x) => fArticleFilterValues.includes(x));
          if (!filterValueOrders || filterValueOrders.length === 0) {
            continue;
          }
          fArticleFilterValues.slice().sort((a, b) => {
            const indexA = filterValueOrders.indexOf(a);
            const indexB = filterValueOrders.indexOf(b);
            if (indexA === -1) {
              return 1;
            }
            if (indexB === -1) {
              return -1;
            }
            return indexA - indexB;
          });
          const parentFilterValues = filter.filterValues.filter((filterValue) =>
            fArticleFilterValues.includes(filterValue.id)
          );
          const childrenFilterValue = getChildrenFilterValue(
            parentFilterValues,
            groupFArticle
          );
          // region title
          const title = [
            ...Array.from(new Set(parentFilterValues.map((x) => x.name))),
          ].join(" / ");
          if (previousTitle !== title) {
            groupFArticle.title = title;
          }
          previousTitle = title;
          // endregion
          // region subTitle
          const subTitle = [
            ...Array.from(new Set(childrenFilterValue.map((x) => x.name))),
          ].join(" / ");
          if (previousSubTitle !== subTitle) {
            groupFArticle.subTitle = subTitle;
          }
          previousSubTitle = subTitle;
          // endregion
        } else if (aggrCategoryIds?.filterValuesStats?.buckets) {
          const aggrCategories = getAggrCategories(
            aggrCategoryIds.filterValuesStats.buckets
          );
          const parentCategoryFound: string[] = [];
          const childCategoryFound: string[] = [];
          for (const fArticle of groupFArticle.fArticles) {
            // @ts-ignore
            const categoryIds: number[] = fArticle.categories.map((c: string) =>
              Number(c.replace(CATEGORY_URL + "/", ""))
            );
            for (const aggrCategory of aggrCategories) {
              if (categoryIds.includes(aggrCategory.id)) {
                parentCategoryFound.push(aggrCategory.name);
              }
              if (!aggrCategory.children) {
                continue;
              }
              for (const childCategory of aggrCategory.children) {
                if (categoryIds.includes(childCategory.id)) {
                  childCategoryFound.push(childCategory.name);
                }
              }
            }
          }
          // region title
          let title = "";
          const sameTitle = parentCategoryFound.find(
            (p) => p === previousTitle
          );
          if (sameTitle) {
            title = previousTitle;
          } else {
            if (parentCategoryFound.length > 0) {
              title = parentCategoryFound[0];
            }
          }
          if (previousTitle !== title) {
            groupFArticle.title = title;
          }
          previousTitle = title;
          // endregion
          // region subTitle
          let subTitle = "";
          const sameSubTitle = childCategoryFound.find(
            (p) => p === previousSubTitle
          );
          if (sameSubTitle) {
            subTitle = previousSubTitle;
          } else {
            if (childCategoryFound.length > 0) {
              subTitle = childCategoryFound[0];
            }
          }
          if (previousSubTitle !== subTitle) {
            groupFArticle.subTitle = subTitle;
          }
          previousSubTitle = subTitle;
          // endregion
        }
      }
    }
  }
  return groupFArticles;
};

export const filterArrayUniqueKey = (array: any[], key: string): any[] => {
  // @ts-ignore
  return [...new Map(array.map((item) => [item[key], item])).values()];
};
