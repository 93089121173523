import * as React from "react";
import { useRef } from "react";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { priceFormat } from "../../../utils/FormatUtils";
import {
  displayPay,
  getRemainingAmountAPayer,
  paiementDone,
} from "../../../helpers/FDocenteteHelper";
import { requestApi } from "../../../helpers/RequestApi";
import { useTheme } from "@mui/material/styles";
import { GET, POST } from "../../../utils/MethodUtils";
import {
  CREDIT_FDOCENTETES_URL,
  FDOCENTETE_FORM_URL,
} from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Chip, DialogProps } from "@mui/material";
import { submitFormPayment } from "../../../helpers/PaymentHelper";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { set } from "../../../app/globalSlice";
import getErrorApi from "../../../helpers/GetErrorApi";
import FDocenteteSelectAvoirComponent from "./FDocenteteSelectAvoirComponent";
import Box from "@mui/material/Box";
import { getConfigurationValue } from "../../../helpers/ConfigurationHelper";
import { ALLOW_USE_AVOIR } from "../../../utils/ConfigurationUtils";
import { SAGE_DO_TYPE_VENTE_FA } from "../../../utils/DoceneteteUtils";
import Typography from "@mui/material/Typography";

interface State {
  fDocentete: FDocenteteInterface;
}

const FDocentetePayComponent: React.FC<State> = React.memo(({ fDocentete }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const configurations = useAppSelector(
    (state: RootState) => state.globalState.configurations
  );
  const accountingSituation = useAppSelector(
    (state: RootState) => state.globalState.accountingSituation
  );
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openDialog, setOpenDialog] = React.useState(false);
  const getCanUseAvoir = React.useCallback(() => {
    return (
      getConfigurationValue(ALLOW_USE_AVOIR) &&
      fDocentete.doType < SAGE_DO_TYPE_VENTE_FA // can't use avoir on facture
    );
  }, [fDocentete.doType]);
  const [canUseAvoir, setCanUseAvoir] = React.useState(getCanUseAvoir());
  const [remainingAmountAPayer, setRemainingAmountAPayer] = React.useState(
    getRemainingAmountAPayer(fDocentete)
  );
  const getMaxAvoir = React.useCallback(() => {
    if (!accountingSituation) {
      return 0;
    }
    return Math.min(
      accountingSituation.sumCreditFdocentetes,
      remainingAmountAPayer
    );
  }, [accountingSituation, remainingAmountAPayer]);
  const [maxAvoir, setMaxAvoir] = React.useState(getMaxAvoir());
  const selectAvoirRef: any = useRef();
  const [value, setValue] = React.useState<number>(maxAvoir);

  const onPay = React.useCallback(async () => {
    setLoading(true);
    const response = await requestApi({
      method: GET,
      path:
        FDOCENTETE_FORM_URL.replace("{doPiece}", fDocentete.doPiece) +
        "?amount=" +
        remainingAmountAPayer,
      allowError: true,
      token: token,
    });
    if (response.statusCode === 200) {
      const success = submitFormPayment(response.content.form);
      if (!success) {
        setLoading(false);
      }
      return;
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
    setLoading(false);
  }, [fDocentete.doPiece, remainingAmountAPayer, t, token]);

  const handleDialog = React.useCallback(() => {
    setOpenDialog((x) => {
      if (loading) {
        return x;
      }
      return !x;
    });
  }, [loading]);

  const useAvoir = React.useCallback(async () => {
    if (!user?.userIdentifier) {
      return;
    }
    setLoading(true);
    const response = await requestApi({
      method: POST,
      path: CREDIT_FDOCENTETES_URL,
      allowError: false,
      token: token,
      body: {
        userIdentifier: user.userIdentifier,
        fDocenteteId: fDocentete.doPiece,
        amount: value,
      },
    });
    if (response.statusCode === 200) {
      dispatch(
        set({ accountingSituation: response.content.accountingSituation })
      );
      handleDialog();
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [
    dispatch,
    fDocentete.doPiece,
    handleDialog,
    t,
    token,
    user?.userIdentifier,
    value,
  ]);

  React.useEffect(() => {
    setRemainingAmountAPayer(getRemainingAmountAPayer(fDocentete));
  }, [fDocentete, accountingSituation?.sumCreditFdocentetes]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setMaxAvoir(getMaxAvoir());
  }, [accountingSituation?.sumCreditFdocentetes, remainingAmountAPayer]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setCanUseAvoir(getCanUseAvoir());
  }, [configurations]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {displayPay(fDocentete, user) &&
        (!paiementDone(fDocentete) ? (
          <>
            <LoadingButton
              variant="contained"
              onClick={onPay}
              loading={loading}
              sx={{ marginBottom: 1 }}
            >
              {t(
                Math.round(parseFloat(fDocentete.doNetapayer) * 100) ===
                  remainingAmountAPayer
                  ? "word.pay"
                  : "word.payRemaining"
              ) +
                " " +
                priceFormat(remainingAmountAPayer, i18n.language, "EUR")}
            </LoadingButton>
            {accountingSituation?.sumCreditFdocentetes !== undefined &&
              accountingSituation.sumCreditFdocentetes > 0 && (
                <>
                  <Dialog
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={true}
                    onClose={handleDialog}
                    open={openDialog}
                  >
                    <DialogContent>
                      <Typography>{t("sentence.selectAmountAvoir")}</Typography>
                      <FDocenteteSelectAvoirComponent
                        maxAvoir={maxAvoir}
                        setParentValue={setValue}
                        ref={selectAvoirRef}
                      />
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "space-between" }}>
                      <LoadingButton disabled={loading} onClick={handleDialog}>
                        {t("word.cancel")}
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        loading={loading}
                        onClick={useAvoir}
                        disabled={isNaN(value) || value === 0}
                      >
                        {t("sentence.useMyAvoir") +
                          " " +
                          priceFormat(value, i18n.language, "EUR")}
                      </LoadingButton>
                    </DialogActions>
                  </Dialog>
                  {canUseAvoir && (
                    <Box sx={{ marginTop: 1 }}>
                      <LoadingButton variant="contained" onClick={handleDialog}>
                        {t("sentence.useMyAvoir")}
                      </LoadingButton>
                    </Box>
                  )}
                </>
              )}
          </>
        ) : (
          <Chip label={t("word.paid")} variant="outlined" color="success" />
        ))}
    </>
  );
});

export default FDocentetePayComponent;
