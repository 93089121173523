import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FDOCENTETES_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { Box, Container } from "@mui/material";
import LoginComponent from "../../../components/common/user/LoginComponent";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import {
  paramsHasChanged,
  searchParamToObject,
} from "../../../helpers/SearchParamHelper";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { ENTERING_INVOICE_PAGE } from "../../../utils/RouteUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import CopyClipboardComponent from "../CopyClipboardComponent";
import EnteringInvoiceFilterComponent from "./EnteringInvoiceFilterComponent";

const clone = require("clone");

const EnteringInvoiceComponent: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const componentRef = useRef();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(50);
  const [fDocentetes, setFDocentetes] = React.useState<
    FDocenteteInterface[] | undefined
  >(undefined);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const [totalItems, setTotalItems] = React.useState(0);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const refSearch: any = useRef();

  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "doPiece",
        headerName: t("column.doPiece"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.doPiece ?? ""}
          />
        ),
      },
      {
        field: "doType",
        headerName: t("column.doType"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>{t("word.fdocentete.doType." + params.row.doType)}</>
        ),
      },
      {
        field: "doTiers",
        headerName: t("column.doTiers"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.doTiers?.ctNum ?? ""}
          />
        ),
      },
      {
        field: "doDate",
        headerName: t("column.doDate"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.row.doDate).toLocaleString(i18n.language, {
            dateStyle: "long",
            timeZone: "Etc/UTC",
          }),
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <Link to={ENTERING_INVOICE_PAGE + "/" + params.row.doPiece}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </>
        ),
      },
    ];
  }, [i18n.language, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (fDocentetes !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        setTimeout(() => {
          dispatch(set({ refreshPage: true }));
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path:
          FDOCENTETES_URL +
          objectToQuery({
            ...searchParamsObject,
            doDomaine: 1,
          }),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = 0;
        const newFDocentetes = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              doPiece: maxId.toString(),
            };
          }),
          ...response.content.fDocentetes,
        ];
        setFDocentetes(newFDocentetes);
        setTotalItems(response.content.totalItems);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      fDocentetes,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setFDocentetes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const updateSearchParams = React.useCallback(() => {
    let searchParamsObject = searchParamToObject(searchParams);
    const current = clone(searchParamsObject);
    delete searchParamsObject.doDomaine;
    delete searchParamsObject.search;
    if (refSearch.current.getValue()) {
      searchParamsObject.search = refSearch.current.getValue();
    }
    if (paramsHasChanged(current, searchParamsObject)) {
      searchParamsObject.page = 1;
    }
    setSearchParams(searchParamsObject, {
      replace: true,
    });
  }, [searchParams, setSearchParams]);

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setFDocentetes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [init, refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Box ref={componentRef}>
          <EnteringInvoiceFilterComponent
            updateSearchParams={updateSearchParams}
            ref={refSearch}
          />
          <Container sx={{ marginY: 2 }}>
            {searchParamsObject.itemsPerPage && searchParamsObject.page && (
              // https://mui.com/x/react-data-grid/components/#pagination
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: getSortModel(),
                  },
                }}
                getRowId={(row) => row.doPiece}
                loading={refreshPage}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                rows={refreshPage ? [] : fDocentetes ?? []}
                onSortModelChange={onSortModelChange}
                sortingMode="server"
                page={Number(searchParamsObject.page) - 1}
                rowsPerPageOptions={[10, 25, 50]}
                pageSize={Number(searchParamsObject.itemsPerPage)}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                rowCount={totalItems}
                columns={columns}
                autoHeight={true}
                disableExtendRowFullWidth={true}
                localeText={getLocaleDataGrid(i18n.language)}
              />
            )}
          </Container>
        </Box>
      </LoginComponent>
    </>
  );
});

export default EnteringInvoiceComponent;
