import * as React from "react";
import {
  FArticleInterface,
  FArticleSmallInterface,
} from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { set } from "../../../app/globalSlice";
import { STORAGE_FARTICLES_COMPARE } from "../../../utils/StorageUtils";
import { filterArrayUniqueKey } from "../../../helpers/GroupHelper";

interface State {
  fArticle: FArticleSmallInterface | undefined;
}

const FArticleAddCompareComponent = React.memo(
  React.forwardRef(({ fArticle }: State, ref) => {
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useAppDispatch();

    const save = React.useCallback(
      async (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        // @ts-ignore
        let thisFArticle: FArticleInterface = { ...fArticle };
        if (!thisFArticle.loaded) {
          const response = await requestApi({
            method: GET,
            path: FARTICLE_URL + "/" + fArticle?.arRef,
            allowError: true,
            timeout: 30_000,
            token: token,
          });
          if (response.statusCode === 200) {
            thisFArticle = response.content;
          } else {
            for (let message of getErrorApi(response.content)) {
              toastr.error(t("word.error"), t(message));
            }
          }
        }
        if (thisFArticle.loaded) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_added_compare")
          );
          const thisFArticlesToCompare: FArticleInterface[] = JSON.parse(
            localStorage.getItem(STORAGE_FARTICLES_COMPARE) ?? "[]"
          );
          const newFArticlesToCompare = filterArrayUniqueKey(
            [...[...(thisFArticlesToCompare ?? [])], thisFArticle],
            "arRef"
          );
          dispatch(
            set({
              addedCompare: true,
              fArticlesToCompare: newFArticlesToCompare,
            })
          );
          localStorage.setItem(
            STORAGE_FARTICLES_COMPARE,
            JSON.stringify(newFArticlesToCompare)
          );
        }
        setLoading(false);
      },
      [dispatch, fArticle, t, token]
    );

    return (
      <>
        <LoadingButton variant="outlined" onClick={save} loading={loading}>
          {t("word.compare")}
        </LoadingButton>
      </>
    );
  })
);

export default FArticleAddCompareComponent;
