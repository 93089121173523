import React from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import { useLocation, useParams } from "react-router-dom";
import FArticleComponent from "../components/common/fArticle/FArticleComponent";
import {
  FArticleInterface,
  FournisseurArticleInterface,
} from "../interfaces/FArticleInterface";
import { requestApi } from "../helpers/RequestApi";
import { GET } from "../utils/MethodUtils";
import { FARTICLE_URL } from "../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { getTitle } from "../helpers/SearchParamHelper";
import { FArticleSmallComponent } from "../components/common/fArticle/FArticleSmallComponent";
import { STYLE_SUPER_COMPACT_INLINE } from "../utils/FArticleUtils";
import LoginComponent from "../components/common/user/LoginComponent";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { updateLoginCookie } from "../helpers/FArticleHelper";
import CircularProgress from "@mui/material/CircularProgress";
import { ListProductsIcecatFournisseurComponent } from "../components/common/fArticle/ListProductsIcecatFournisseurComponent";

const ProductDuplicationScreen: React.FC = React.memo(() => {
  const params = useParams();
  const { state }: any = useLocation();
  const [fArticle, setFArticle] = React.useState<FArticleInterface | undefined>(
    state ?? undefined
  );
  const [temporaryIcecat, setTemporaryIcecat] = React.useState<any | undefined>(
    undefined
  );
  const [fournisseurProduct, setFournisseurProduct] = React.useState<
    FournisseurArticleInterface | undefined
  >(undefined);
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const loginCookieIcecat = useAppSelector(
    (state: RootState) => state.globalState.loginCookieIcecat
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const { t } = useTranslation();

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (force) {
        setFArticle(undefined);
      }
      if (!force || fArticle) {
        const response = await requestApi({
          method: GET,
          path: FARTICLE_URL + "/" + params.arRef,
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          setFArticle(response.content);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
        dispatch(set({ refreshPage: false }));
      }
    },
    [dispatch, fArticle, params.arRef, t, token]
  );

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let title = getTitle("product");
    if (fArticle?.arDesign) {
      title = fArticle.arDesign;
    }
    document.title = title + " Informatique Guadeloupe NOVE";
  }, [fArticle?.arDesign]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && !refreshPage) {
      setFArticle(undefined);
      load(true);
    }
  }, [JSON.stringify(params)]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    updateLoginCookie();
  }, [loginCookieIcecat]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout sx={{ overflowY: "scroll!important" }}>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Grid container spacing={1} sx={{ padding: 1 }}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {t("word.duplicateProduct")}
            </Typography>
            {fArticle !== undefined && (
              <>
                <FArticleSmallComponent
                  fArticles={[fArticle]}
                  nbColumn={1}
                  inline={true}
                  defaultStyleInline={STYLE_SUPER_COMPACT_INLINE}
                  showAddCart={false}
                />
              </>
            )}
            <Box sx={{ marginTop: 1 }}>
              {!loginCookieIcecat ? (
                <Typography sx={{ textAlign: "center" }}>
                  {t("word.icecatAuth") + " ..."}
                  <CircularProgress />
                </Typography>
              ) : (
                <ListProductsIcecatFournisseurComponent
                  setTemporaryIcecat={setTemporaryIcecat}
                  setFournisseurProduct={setFournisseurProduct}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <FArticleComponent
          initFArticle={undefined}
          createFrom={fArticle}
          temporaryIcecat={temporaryIcecat}
          fournisseurProduct={fournisseurProduct}
        />
      </LoginComponent>
    </Layout>
  );
});

export default ProductDuplicationScreen;
