import React, { forwardRef, useImperativeHandle } from "react";
import SearchProductTextSingleComponent from "./SearchProductTextSingleComponent";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";

interface State {
  data: any;
}

const SearchProductTextComponent = React.memo(
  forwardRef(({ data }: State, ref) => {
    const getTextsRef = React.useCallback(() => {
      return (
        data?.texts?.map((x: any) => {
          return {
            data: x,
            ref: React.createRef(),
          };
        }) ?? []
      );
    }, [data?.texts]);

    const [textsRef, setTextsRef] = React.useState<any[]>(getTextsRef());

    const addText = React.useCallback(() => {
      setTextsRef((x) => {
        x.push({
          data: null,
          ref: React.createRef(),
        });
        return [...x];
      });
    }, []);

    const removeText = React.useCallback((index: number) => {
      setTextsRef((x) => {
        x[index] = undefined;
        return [...x];
      });
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return textsRef.filter((x) => x).map((x) => x.ref.current.getValue());
      },
    }));

    React.useEffect(() => {
      setTextsRef(getTextsRef());
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {textsRef.map((textRef, indexTextRef) => (
          <Box key={indexTextRef}>
            {textRef && (
              <>
                <Box
                  key={indexTextRef}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box sx={{ flex: 1, marginBottom: 2 }}>
                    <SearchProductTextSingleComponent
                      data={textRef?.data}
                      ref={textRef?.ref}
                    />
                  </Box>
                  <IconButton onClick={() => removeText(indexTextRef)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ marginBottom: 2 }} />
              </>
            )}
          </Box>
        ))}
        <Box sx={{ textAlign: "center" }}>
          <IconButton onClick={addText}>
            <AddIcon />
          </IconButton>
        </Box>
      </>
    );
  })
);

export default SearchProductTextComponent;
