import React, { ChangeEvent, forwardRef, useImperativeHandle } from "react";
import { Chip, useMediaQuery, useTheme } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { BucketInterface } from "../../../interfaces/BucketInterface";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import { CATEGORY_PAGE } from "../../../utils/RouteUtils";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { customRayonSearchChanged } from "../../../helpers/FilterHelper";
import SearchComponent from "../SearchComponent";
import { objectToQuery } from "../../../helpers/RequestApi";
import { getAggrCategories } from "../../../helpers/GroupHelper";

interface State {
  apply: Function;
  setNbSelected: Function;
  index: number;
  filterInited: Function;
  aggregations: any;
}

const RayonFilterComponent = React.memo(
  forwardRef(
    (
      { apply, setNbSelected, index, filterInited, aggregations }: State,
      ref
    ) => {
      const theme = useTheme();
      const allCategories = useAppSelector(
        (state: RootState) => state.globalState.categories
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const [searchParams] = useSearchParams();
      const [oldSearchParams, setOldSearchParams] = React.useState<any>({});
      const getChecked = React.useCallback(() => {
        const searchParamsObject = searchParamToObject(searchParams);
        if (searchParamsObject.hasOwnProperty("categories[]")) {
          return searchParamsObject["categories[]"].map((x: string) =>
            parseInt(x)
          );
        }
        return [];
      }, [searchParams]);
      const [checked, setChecked] = React.useState<number[]>(getChecked());
      const [aggrCategories, setAggrCategories] = React.useState<
        CategoryInterface[] | undefined
      >(undefined);

      const _getAggrCategories = React.useCallback(
        (buckets: BucketInterface[] | undefined) => {
          if (!buckets || !allCategories) {
            setAggrCategories(undefined);
            return;
          }
          return getAggrCategories(buckets);
        },
        [allCategories]
      );

      const handleChecked = React.useCallback(
        (categoryId: number) => (event: ChangeEvent<HTMLInputElement>) => {
          setChecked((x) => {
            x = x.filter((y) => y !== categoryId);
            if (event.target.checked) {
              x.push(categoryId);
            }
            return [...x];
          });
        },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          let r: any = {
            aggs: "categories",
          };
          if (checked.length > 0) {
            r["categories[]"] = checked;
          }
          return r;
        },
        resetCustomRayon() {
          setChecked([]);
          setAggrCategories(undefined);
        },
      }));

      React.useEffect(() => {
        setNbSelected(checked.length);
        if (!isSmall) {
          apply();
        }
        filterInited(index);
      }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        const searchParamsObject = searchParamToObject(searchParams);
        const newAggrCategories = _getAggrCategories(
          aggregations?.filterValuesStats?.buckets
        );
        if (customRayonSearchChanged(searchParamsObject, oldSearchParams)) {
          setOldSearchParams(searchParamsObject);
          setAggrCategories(newAggrCategories);
        } else if (
          aggrCategories === undefined ||
          (newAggrCategories &&
            newAggrCategories?.length > aggrCategories.length)
        ) {
          setAggrCategories(newAggrCategories);
        }
      }, [aggregations?.filterValuesStats?.buckets]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setChecked(getChecked());
      }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

      const searchParamsObject = searchParamToObject(searchParams);
      delete searchParamsObject["categories[]"];
      delete searchParamsObject["aggs"];
      delete searchParamsObject["page"];
      delete searchParamsObject["keywords"];

      return (
        <>
          {aggrCategories ? (
            aggrCategories?.map((aggrCategory, indexAggrCategory) => (
              <Box key={indexAggrCategory}>
                <Chip
                  label={aggrCategory.name}
                  variant="outlined"
                  size="small"
                  color="primary"
                />
                <Box
                  sx={{
                    marginLeft: 1,
                    marginBottom:
                      indexAggrCategory === aggrCategories.length - 1 ? 0 : 0.5,
                    borderLeft: theme.palette.primary.main + " solid 1px",
                  }}
                >
                  {aggrCategory.children?.map(
                    (aggrCategory2, indexAggrCategory2) => (
                      <FormGroup
                        sx={{ position: "relative", width: "100%" }}
                        key={indexAggrCategory2}
                      >
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              sx={{ ...(!isSmall && { paddingY: 0 }) }}
                              checked={checked.includes(aggrCategory2?.id ?? 0)}
                              onChange={handleChecked(aggrCategory2.id)}
                            />
                          }
                          label={
                            <Typography
                              component="span"
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <>
                                <Typography component="span">
                                  {aggrCategory2?.name}
                                  {isAdmin && (
                                    <>
                                      {aggrCategory2.keywords &&
                                        aggrCategory2.keywords.map(
                                          (keyword, indexKeyword) => (
                                            <Chip
                                              size="small"
                                              variant="outlined"
                                              color="primary"
                                              key={indexKeyword}
                                              label={keyword}
                                            />
                                          )
                                        )}
                                    </>
                                  )}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                    }}
                                    to={
                                      CATEGORY_PAGE +
                                      "/" +
                                      aggrCategory2.slug +
                                      objectToQuery(searchParamsObject)
                                    }
                                  >
                                    <IconButton>
                                      <SubdirectoryArrowLeftIcon />
                                    </IconButton>
                                  </Link>
                                  <Typography
                                    component="span"
                                    sx={{ color: theme.palette.primary.main }}
                                  >
                                    [{aggrCategory2.doc_count}]
                                  </Typography>
                                </Typography>
                              </>
                            </Typography>
                          }
                        />
                      </FormGroup>
                    )
                  )}
                </Box>
              </Box>
            ))
          ) : (
            <SearchComponent nbColumn={1} nbLines={3} height={50} />
          )}
        </>
      );
    }
  )
);

export default RayonFilterComponent;
