import * as React from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { GET } from "../../../utils/MethodUtils";
import { requestApi } from "../../../helpers/RequestApi";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { useLocation, useParams } from "react-router-dom";
import LoginComponent from "../user/LoginComponent";
import DialogFilterAdminProductFilterValueComponent from "../fArticle/DialogFilterAdminProductFilterValueComponent";
import { getTitle } from "../../../helpers/SearchParamHelper";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";

const BrowserFilterAdminProductFilterValueComponent: React.FC = React.memo(
  () => {
    const params = useParams();
    const { state }: any = useLocation();
    const [fArticle, setFArticle] = React.useState<
      FArticleInterface | undefined
    >(state ?? { arRef: params.arRef });
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const { t } = useTranslation();

    const afterFarticleFullyLoad = React.useCallback(async () => {
      if (!fArticle) {
        return;
      }
      if (!fArticle.loaded) {
        const response = await requestApi({
          method: GET,
          path: FARTICLE_URL + "/" + fArticle.arRef,
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          setFArticle(response.content);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        }
      }
    }, [fArticle, t, token]);

    React.useEffect(() => {
      document.title = getTitle("filterValues");
      afterFarticleFullyLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <LoginComponent redirect={null} requireAdmin={true}>
        <DialogFilterAdminProductFilterValueComponent
          fArticle={fArticle}
          setFArticle={setFArticle}
        />
      </LoginComponent>
    );
  }
);

export default BrowserFilterAdminProductFilterValueComponent;
