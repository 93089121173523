import React from "react";
import Layout from "../../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import LoginComponent from "../../components/common/user/LoginComponent";
import { useMediaQuery, useTheme } from "@mui/material";
import { getTitle, searchParamToObject } from "../../helpers/SearchParamHelper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanelComponent from "../../components/common/TabPanelComponent";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CronComponent from "../../components/common/cron/CronComponent";
import LogComponent from "../../components/common/log/LogComponent";

const AdminCronScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const getValue = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return searchParamsObject.tab ? Number(searchParamsObject.tab) : 0;
  }, [searchParams]);
  const [value, setValue] = React.useState(getValue());

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      const newSearchParams = searchParamToObject(searchParams);
      newSearchParams.tab = newValue.toString();
      setSearchParams(newSearchParams, {
        replace: true,
      });
    },
    [searchParams, setSearchParams]
  );

  const load = React.useCallback(
    (force: boolean = false) => {
      setInit(true);
      dispatch(set({ refreshPage: false }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    document.title = getTitle("crons");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          variant={!isSmall ? "standard" : "scrollable"}
          centered={!isSmall}
        >
          <Tab label={t("word.tasks")} />
          <Tab label={t("word.logs")} />
        </Tabs>
        <TabPanelComponent value={value} index={0}>
          <CronComponent />
        </TabPanelComponent>
        <TabPanelComponent value={value} index={1}>
          <LogComponent />
        </TabPanelComponent>
      </LoginComponent>
    </Layout>
  );
});

export default AdminCronScreen;
