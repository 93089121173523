import * as React from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { LOG_URL, LOGS_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const LogComponent: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const [logs, setLogs] = React.useState<string[]>([]);
  const [downloading, setDownloading] = React.useState<number | undefined>(
    undefined
  );

  const load = React.useCallback(async () => {
    const response = await requestApi({
      method: GET,
      path: LOGS_URL,
      allowError: true,
      token: token,
    });
    if (response.statusCode === 200) {
      setLogs(response.content);
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
  }, [t, token]);

  const download = React.useCallback(
    async (index: number) => {
      setDownloading(index);
      try {
        const res = await fetch(
          (process.env.REACT_APP_API_URL ?? "") +
            LOG_URL +
            "?file=" +
            encodeURI(logs[index]),
          {
            method: GET,
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = "log";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        // nothing
      }
      setDownloading(undefined);
    },
    [logs, token]
  );

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="xl" sx={{ marginY: 2 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("word.name")}</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log, indexLog) => (
              <StyledTableRow key={indexLog}>
                <StyledTableCell>{log}</StyledTableCell>
                <StyledTableCell>
                  <LoadingButton
                    variant="text"
                    color="inherit"
                    sx={{
                      borderRadius: "50%",
                      minWidth: "auto",
                      padding: "12px",
                    }}
                    loading={downloading === indexLog}
                    onClick={() => {
                      download(indexLog);
                    }}
                  >
                    <DownloadIcon />
                  </LoadingButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
});

export default LogComponent;
