import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleInterface,
  FArticleSmallInterface,
  FArtstockInterface,
} from "../../../interfaces/FArticleInterface";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { Grid, TextField, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL, FDEPOTS_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { defaultFontSize } from "../category/ConfigureViewProductsComponent";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  inline?: boolean;
  edit?: boolean;
  setFArticle?: Function;
  fontSize?: number;
  createFrom?: FArticleInterface;
}

interface FormState {
  asQtemini: InputInterface;
  asQtemaxi: InputInterface;
}

export interface PutFArtstocks {
  fArtstocks: PutFArtstock[];
}

interface PutFArtstock {
  fDepot: string;
  asQtemini: string;
  asQtemaxi: string;
}

export const StockMinMaxFArticleComponent = React.memo(
  React.forwardRef(
    (
      { fArticle, inline, edit, setFArticle, fontSize, createFrom }: State,
      ref
    ) => {
      const { t, i18n } = useTranslation();
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const getFArtStock = React.useCallback(():
        | FArtstockInterface
        | undefined => {
        if (
          (createFrom || fArticle) &&
          (createFrom ?? fArticle)?.fArtstocks &&
          ((createFrom ?? fArticle)?.fArtstocks.length ?? 0) > 0
        ) {
          return (createFrom ?? fArticle)?.fArtstocks.find(
            (x) => x.fDepot.deNo === 1
          );
        }
        return undefined;
      }, [createFrom, fArticle]);
      const [fArtStock, setFArtStock] = React.useState<
        FArtstockInterface | undefined
      >(getFArtStock());
      const getDefaultValue = React.useCallback((): FormState => {
        return {
          asQtemini: {
            value: Number(fArtStock?.asQtemini ?? 0).toString(),
            error: "",
          },
          asQtemaxi: {
            value: Number(fArtStock?.asQtemaxi ?? 0).toString(),
            error: "",
          },
        };
      }, [fArtStock?.asQtemaxi, fArtStock?.asQtemini]);
      const [values, setValues] = React.useState<FormState>(getDefaultValue());
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback((): PutFArtstocks => {
        return {
          fArtstocks: [
            {
              fDepot: FDEPOTS_URL + "/1",
              asQtemini: values.asQtemini.value,
              asQtemaxi: values.asQtemaxi.value,
            },
          ],
        };
      }, [values.asQtemaxi.value, values.asQtemini.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            ...getValue(),
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          if (setFArticle) {
            setFArticle(response.content);
          }
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setFArtStock(getFArtStock());
      }, [fArticle, createFrom]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValue());
      }, [fArtStock?.asQtemaxi, fArtStock?.asQtemini]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {(createFrom || fArticle) && isAdmin && (
            <Box sx={{ display: "flex" }}>
              {edit !== undefined && !(edit || thisEdit) && (
                <Box>
                  <IconButton onClick={handleThisEdit}>
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
              <Box
                sx={{
                  flex: 1,
                  ...(inline && {
                    display: "flex",
                    justifyContent: "space-around",
                  }),
                }}
              >
                {edit || thisEdit ? (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth={true}
                          autoComplete="off"
                          error={!!values.asQtemini.error}
                          helperText={t(values.asQtemini.error ?? "")}
                          sx={{ width: "100%" }}
                          required
                          type="number"
                          value={values.asQtemini.value}
                          onChange={handleChange("asQtemini")}
                          label={t("word.stock.min")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth={true}
                          autoComplete="off"
                          error={!!values.asQtemaxi.error}
                          helperText={t(values.asQtemaxi.error ?? "")}
                          sx={{ width: "100%" }}
                          required
                          type="number"
                          value={values.asQtemaxi.value}
                          onChange={handleChange("asQtemaxi")}
                          label={t("word.stock.max")}
                        />
                      </Grid>
                    </Grid>
                    {!edit && (
                      <>
                        <Tooltip title={t("word.cancel")}>
                          <IconButton
                            onClick={handleThisEdit}
                            disabled={loading}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("word.save")}>
                          <LoadingButton
                            variant="text"
                            color="inherit"
                            sx={{
                              borderRadius: "50%",
                              minWidth: "auto",
                              padding: "8px",
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                            loading={loading}
                            onClick={save}
                          >
                            <SaveIcon />
                          </LoadingButton>
                        </Tooltip>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                      }}
                    >
                      {t("word.stock.min") +
                        ": " +
                        Number(fArtStock?.asQtemini)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                      }}
                    >
                      {t("word.stock.max") +
                        ": " +
                        Number(fArtStock?.asQtemaxi)}
                    </Typography>
                    {fArticle?.cbcreation && (
                      <Typography
                        sx={{
                          fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                        }}
                      >
                        {t("word.createdOn") + ": "}
                        {new Date(fArticle.cbcreation).toLocaleString(
                          i18n.language,
                          {
                            dateStyle: "long",
                            timeZone: "Etc/UTC",
                          }
                        )}
                      </Typography>
                    )}
                    {fArticle?.cbmodification && (
                      <Typography
                        sx={{
                          fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                        }}
                      >
                        {t("word.modifiedOn") + ": "}
                        {new Date(fArticle.cbmodification).toLocaleString(
                          i18n.language,
                          {
                            dateStyle: "long",
                            timeZone: "Etc/UTC",
                          }
                        )}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
          )}
        </>
      );
    }
  )
);
