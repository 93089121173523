import {
  FArticleInterface,
  FArticlePropFilterInterface,
} from "../interfaces/FArticleInterface";
import {
  CategoryInterface,
  FilterValueInterface,
} from "../interfaces/CategoryInterface";
import { RootState, store } from "../app/store";
import { CATEGORY_URL } from "../utils/UrlsUtils";
import { filterIsEqual } from "./FilterHelper";

export const getAllCategoryGroup = (
  fArticle: FArticleInterface | undefined
): CategoryInterface[][] => {
  let result: CategoryInterface[][] = [];
  if (
    fArticle?.fArticleProp === undefined ||
    fArticle.categories === undefined
  ) {
    return result;
  }
  for (let category of fArticle.categories) {
    const categoryGroup: CategoryInterface[] = [];
    while (category !== null && category !== undefined) {
      categoryGroup.push({ ...category, parent: null });
      if (category.parent === null) {
        break;
      }
      category = category.parent;
    }
    result.push(categoryGroup.reverse());
  }
  let identifiers: string[] = result.map((categories) =>
    categories.reduce((identifier, category) => {
      return identifier + "-" + category.id;
    }, "")
  );
  let length = identifiers.length;
  do {
    const indexes: number[] = [];
    for (
      let currentIndex = 0;
      currentIndex < identifiers.length;
      currentIndex++
    ) {
      const identifier = identifiers[currentIndex];
      const index = identifiers.findIndex(
        (identifier2, indexIdentifier2) =>
          identifier.startsWith(identifier2) &&
          currentIndex !== indexIdentifier2
      );
      if (index !== -1) {
        indexes.push(index);
      }
    }
    identifiers = identifiers.filter((x, index) => !indexes.includes(index));
    result = result.filter((x, index) => !indexes.includes(index));
    length = identifiers.length;
  } while (identifiers.length !== length);
  result = result.filter((x) => x.length > 0);
  let indexToRemove: number[] = [];
  // @ts-ignore
  for (const [index1, thisCategories1] of result.entries()) {
    // @ts-ignore
    for (const [index2, thisCategories2] of result.entries()) {
      if (index1 === index2) {
        continue;
      }
      if (thisCategories1.length > thisCategories2.length) {
        let remove2 = true;
        for (const [index, value] of thisCategories2.entries()) {
          if (thisCategories2[index].id !== thisCategories1[index].id) {
            remove2 = false;
          }
        }
        if (remove2) {
          indexToRemove.push(index2);
        }
      }
    }
  }
  indexToRemove = indexToRemove.sort().reverse();
  for (const index of indexToRemove) {
    result.splice(index, 1);
  }
  return result;
};

export const formatCategoryBreadcrumb = (
  fArticle: FArticleInterface | undefined
): (CategoryInterface | FilterValueInterface)[][] => {
  let result: (CategoryInterface | FilterValueInterface)[][] = [];
  let categoryGroups = getAllCategoryGroup(fArticle);
  for (const categoryGroup of categoryGroups) {
    if (categoryGroup.length === 3) {
      categoryGroup.pop();
    }
  }
  categoryGroups = categoryGroups.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => t[0].id === value[0].id && t[1]?.id === value[1]?.id
      )
  );
  for (const categoryGroup of categoryGroups) {
    if (fArticle?.fArticleProp.filters) {
      let filterReferences: FArticlePropFilterInterface[] = [];
      const childCategory = categoryGroup[1];
      if (childCategory) {
        for (const filter of fArticle.fArticleProp.filters) {
          if (
            filter.filterCategories.find(
              (filterCategory) =>
                filterCategory.reference &&
                filterCategory.category.id === childCategory.id
            )
          ) {
            filterReferences.push(filter);
          }
        }
      }
      if (
        filterReferences.length === 0 ||
        !fArticle?.fArticleProp.filterValueArticles
      ) {
        result.push(categoryGroup);
      } else {
        let added = false;
        for (const filterReference of filterReferences) {
          for (const filterValueArticle of fArticle.fArticleProp
            .filterValueArticles) {
            if (
              filterIsEqual(filterValueArticle.filter, filterReference.id) &&
              filterValueArticle.filterValue
            ) {
              added = true;
              result.push([...categoryGroup, filterValueArticle.filterValue]);
            }
          }
        }
        if (!added) {
          result.push(categoryGroup);
        }
      }
    }
  }
  result = result.filter((group) => {
    return !result.find((group2) => {
      if (group.length >= group2.length) {
        return false;
      }
      let thisResult = true;
      for (let i = 0; i < group2.length; i++) {
        thisResult = thisResult && group2[i]?.id === group[i]?.id;
      }
      return thisResult;
    });
  });
  result = result.filter(
    (value: any[], index, self) =>
      index ===
      self.findIndex((t: any[]) => {
        return value.map((v) => v.id).join() === t.map((v) => v.id).join();
      })
  );
  return result;
};

export const findCategoryParent = (
  category: CategoryInterface | undefined
): CategoryInterface | null => {
  if (!category) {
    return null;
  }
  if (category?.parent && typeof category.parent !== "string") {
    return category.parent;
  }
  const state: RootState = store.getState();
  return (
    state.globalState.categories?.find((c) => {
      if (typeof category.parent === "string") {
        return CATEGORY_URL + "/" + c.id === category.parent;
      }
      return c.id === category.parent?.id;
    }) ?? null
  );
};
