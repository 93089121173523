import * as React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import LoginComponent from "../../components/common/user/LoginComponent";
import { Container } from "@mui/material";
import Layout from "../../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import { CartDndCategoryComponent } from "../../components/common/category/CartDndCategoryComponent";
import {
  getCardsCategory,
  Item,
  updateCardResult,
} from "../../helpers/CardFormat";
import { requestApi } from "../../helpers/RequestApi";
import { GET, PATCH } from "../../utils/MethodUtils";
import { CATEGORY_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import getErrorApi from "../../helpers/GetErrorApi";
import DialogFormCategoryComponent from "../../components/common/category/DialogFormCategoryComponent";
import { CategoryInterface } from "../../interfaces/CategoryInterface";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { getTitle } from "../../helpers/SearchParamHelper";

const clone = require("clone");

// https://codesandbox.io/s/j4yvnr7n83?file=/src/questions.js:2468-2476
const MenuMenuScreen: React.FC = React.memo(() => {
  const [init, setInit] = React.useState(false);
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const theme = useTheme();
  const categories = useAppSelector(
    (state: RootState) => state.globalState.categories
  );
  const [editCategory, setEditCategory] = React.useState<
    CategoryInterface | undefined
  >(undefined);
  const [createCategory, setCreateCategory] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const dispatch = useAppDispatch();

  const switchCreateMode = React.useCallback(() => {
    setCreateCategory(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setEditCategory(undefined);
    setCreateCategory(false);
  }, []);

  const [cards, setCards] = React.useState<Item[]>(
    getCardsCategory(categories ? [...categories] : [])
  );

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (categories === undefined && !force) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const response = await requestApi({
        method: GET,
        path: CATEGORY_URL + "?exists[parent]=false",
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        dispatch(set({ categories: response.content }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [categories, dispatch, t, token]
  );

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      if (result.destination === null || result.destination === undefined) {
        return;
      }
      setCards((thisCards) => {
        const updateCartMenuResult = updateCardResult(thisCards, result);
        if (updateCartMenuResult) {
          requestApi({
            method: PATCH,
            path: CATEGORY_URL + "/" + updateCartMenuResult.request?.id,
            allowError: true,
            token: token,
            body: {
              sort: updateCartMenuResult.request?.sort,
            },
          })
            .then((response) => {
              if (response.statusCode === 200) {
                toastr.success(
                  t("word.success"),
                  t("sentence.notification.order_updated")
                );
                load(true);
              } else if (response.statusCode === 401) {
                toastr.info(t("word.info"), t("error.reconnect"));
              } else {
                for (let message of getErrorApi(response.content)) {
                  toastr.error(t("word.error"), t(message));
                }
              }
            })
            .catch(() => {
              toastr.error(t("word.error"), t("error.tryAgain"));
            });
          return updateCartMenuResult.cards;
        }
        return thisCards;
      });
    },
    [load, t, token]
  );

  const showFormCategory = React.useCallback(
    (
        category: CategoryInterface | undefined,
        parentCategory: CategoryInterface | null
      ) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const thisCategory = clone(category);
        if (thisCategory && parentCategory) {
          thisCategory.parent = parentCategory;
        }
        setEditCategory(thisCategory);
      },
    []
  );

  const renderCards = React.useCallback(
    (
      thisCards: Item[],
      depth: number,
      path: string = ".",
      parentCategory: CategoryInterface | null = null
    ) => (
      <Droppable droppableId={path} type={path}>
        {(provided) => (
          <div ref={provided.innerRef}>
            {thisCards.map((card, index) => (
              <Draggable
                key={card.id}
                draggableId={card.id.toString()}
                index={index}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <CartDndCategoryComponent
                      allCards={cards}
                      setCards={setCards}
                      identifier={card.id.toString()}
                      depth={depth}
                      path={path + index + "."}
                      forceHasChildren={depth === 1}
                      text={
                        <>
                          <span style={{ color: theme.palette.primary.main }}>
                            {"[" + card.id.toString() + "] "}
                          </span>
                          <Badge
                            color={card.enabled ? "success" : "error"}
                            badgeContent=" "
                            variant="dot"
                          >
                            {card.text}
                          </Badge>
                          <IconButton
                            onClick={showFormCategory(
                              card?.category,
                              parentCategory
                            )}
                          >
                            <EditIcon />
                          </IconButton>
                        </>
                      }
                      parentCategory={card.category}
                      children={card.children}
                      provided={provided}
                      renderCards={renderCards}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    ),
    [cards, showFormCategory, theme.palette.primary.main]
  );

  React.useEffect(() => {
    document.title = getTitle("categories");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setCards(getCardsCategory(categories ? [...categories] : []));
  }, [categories]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Container maxWidth="xl" sx={{ marginY: 2, marginBottom: 10 }}>
          <DragDropContext onDragEnd={onDragEnd}>
            {renderCards(cards, 0)}
          </DragDropContext>
          <DialogFormCategoryComponent
            category={editCategory}
            setCategory={setEditCategory}
            openDialog={!!editCategory || createCategory}
            handleClose={handleClose}
          />
          <SpeedDial
            ariaLabel="Category menu"
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
          >
            <SpeedDialAction
              icon={createCategory ? <CancelIcon /> : <AddIcon />}
              onClick={switchCreateMode}
              tooltipTitle={
                createCategory ? t("word.cancel") : t("word.create.category")
              }
            />
          </SpeedDial>
        </Container>
      </LoginComponent>
    </Layout>
  );
});

export default MenuMenuScreen;
