import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { SavInterface } from "../../../interfaces/SavInterface";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { useContainerDimensions } from "../useContainerDimensions";
import DrawerComponent from "../../../components/common/DrawerComponent";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { SAV_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { Box, Container } from "@mui/material";
import ContentComponent from "../../../components/content/ContentComponent";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LoadingButton } from "@mui/lab";
import SavFormComponent from "../../../screens/sav/SavFormComponent";
import SavFilterStateComponent from "./filter/SavFilterStateComponent";
import FilterSearchComponent from "../filter/FilterSearchComponent";
import SavFilterSerialNumberComponent from "./filter/SavFilterSerialNumberComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";
import LoginComponent from "../user/LoginComponent";

const minWidth = 900;

interface State {
  asAdmin: boolean;
}

const SavComponent: React.FC<State> = React.memo(({ asAdmin }) => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [canApplyFilter, setCanApplyFilter] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(asAdmin ? 50 : 10);
  const [savs, setSavs] = React.useState<SavInterface[] | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [totalItems, setTotalItems] = React.useState(0);
  const [sav, setSav] = React.useState<SavInterface | null | undefined>(
    undefined
  );
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const isAdmin = useAppSelector(
    (state: RootState) => state.globalState.isAdmin
  );
  const handleDrawerOpen = React.useCallback((sav: SavInterface | null) => {
    setSav(sav);
    setOpen(true);
  }, []);
  const getColumns = React.useCallback((): GridColDef[] => {
    let columns: GridColDef[] = [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "date",
        headerName: t("column.date"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.row.date).toLocaleDateString(i18n.language, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            timeZone: "America/Cuiaba",
          }),
      },
      {
        field: "state",
        headerName: t("column.state"),
        flex: 1,
        headerClassName: "background-nove",
        renderCell: (params: GridRenderCellParams) =>
          t("word.sav.state." + params.row.state),
      },
      {
        field: "stateProduct",
        headerName: t("column.stateProduct"),
        flex: 1,
        headerClassName: "background-nove",
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.row.stateProduct &&
              t("word.sav.state." + params.row.stateProduct)}
          </>
        ),
      },
      {
        field: "fDocenteteId",
        headerName: t("column.bill"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.fDocenteteId ?? ""}
          />
        ),
      },
      {
        field: "fArticleId",
        headerName: t("column.fArticleId"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.fArticleId ?? ""}
          />
        ),
      },
      {
        field: "fArticleSerialNumber",
        headerName: t("column.fArticleSerialNumber"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.fArticleSerialNumber ?? ""}
          />
        ),
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <IconButton onClick={() => handleDrawerOpen(params.row)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
    ];

    if (asAdmin) {
      const index = 1;
      columns = [
        // part of the array before the specified index
        ...columns.slice(0, index),
        // inserted item
        {
          field: "userIdentifier",
          headerName: t("column.userIdentifier"),
          flex: 0,
          headerClassName: "background-nove",
          filterable: false,
          renderCell: (params: GridRenderCellParams) => (
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={params.row.userIdentifier ?? ""}
            />
          ),
        },
        // part of the array after the specified index
        ...columns.slice(index),
      ];
    }
    return columns;
  }, [asAdmin, handleDrawerOpen, i18n.language, t]);
  const [columns, setColumns] = React.useState<GridColDef[]>(getColumns());
  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (savs !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (
        !searchParamsObject.hasOwnProperty("page") ||
        searchParamsObject.page === "undefined"
      ) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!asAdmin && !searchParamsObject.hasOwnProperty("userIdentifier")) {
        searchParamsObject.userIdentifier = user?.userIdentifier;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: SAV_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content.savs.map((o: SavInterface) => o.id)
        );
        const newSavs = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content.savs,
        ];
        setSavs(newSavs);
        setTotalItems(response.content.totalItems);
        dispatch(set({ nbWaitingSav: response.content.nbWaitingSav }));
        dispatch(set({ nbWaitingSavUser: response.content.nbWaitingSavUser }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setCanApplyFilter(true);
      dispatch(set({ refreshPage: false }));
    },
    [
      asAdmin,
      defaultItemsPerPage,
      dispatch,
      savs,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const handleDrawerClose = React.useCallback(() => {
    setSav(undefined);
    setOpen(false);
  }, []);

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setSavs(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setSavs(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  const onSavCreatedUpdated = React.useCallback(
    (sav: SavInterface) => {
      handleDrawerOpen(sav);
      dispatch(set({ refreshPage: true }));
    },
    [dispatch, handleDrawerOpen]
  );

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setColumns(getColumns());
  }, [asAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <DrawerComponent
        autoScrollBottom={!!isAdmin}
        open={open}
        handleDrawerClose={handleDrawerClose}
        onClose={handleDrawerClose}
        drawerwidth={width < minWidth ? width : minWidth}
        content={
          <SavFormComponent
            successFunction={onSavCreatedUpdated}
            sav={sav}
            asAdmin={asAdmin}
            setSav={setSav}
          />
        }
      />
      <Box ref={componentRef}>
        {!asAdmin && (
          <ContentComponent contentName="sav" saveLocalStorage={true} />
        )}
        <Container sx={{ marginY: 2 }}>
          {asAdmin && (
            <>
              <SavFilterStateComponent init={canApplyFilter} />
              <FilterSearchComponent
                init={canApplyFilter}
                placeholder="word.searchSavPlaceholder"
              />
            </>
          )}
          <LoginComponent redirect={null}>
            <Box sx={{ marginTop: 1 }}>
              <SavFilterSerialNumberComponent
                handleDrawerOpen={handleDrawerOpen}
                asAdmin={asAdmin}
              />
            </Box>
            <Box sx={{ marginBottom: 1, marginTop: 1 }}>
              <LoadingButton
                variant="contained"
                onClick={() => handleDrawerOpen(null)}
              >
                {t("word.sav.create")}
              </LoadingButton>
            </Box>
            {init &&
              searchParamsObject.itemsPerPage &&
              searchParamsObject.page && (
                // https://mui.com/x/react-data-grid/components/#pagination
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: getSortModel(),
                    },
                  }}
                  loading={refreshPage}
                  rows={refreshPage ? [] : savs ?? []}
                  onSortModelChange={onSortModelChange}
                  sortingMode="server"
                  page={Number(searchParamsObject.page) - 1}
                  rowsPerPageOptions={[10, 25, 50]}
                  pageSize={Number(searchParamsObject.itemsPerPage)}
                  onPageSizeChange={onPageSizeChange}
                  onPageChange={onPageChange}
                  rowCount={totalItems}
                  columns={columns}
                  autoHeight={true}
                  disableExtendRowFullWidth={true}
                  localeText={getLocaleDataGrid(i18n.language)}
                />
              )}
          </LoginComponent>
        </Container>
      </Box>
    </>
  );
});

export default SavComponent;
