import * as React from "react";
import Box from "@mui/material/Box";
import { Menu } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuItem from "@mui/material/MenuItem";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useTranslation } from "react-i18next";
import { NotificationInterface } from "../../interfaces/NotificationInterface";
import { requestApi } from "../../helpers/RequestApi";
import { PATCH } from "../../utils/MethodUtils";
import { NOTIFICATION_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { updateNotification } from "../../app/globalSlice";
import { useNavigate } from "react-router-dom";
import getErrorApi from "../../helpers/GetErrorApi";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const NotificationComponent: React.FC = React.memo(() => {
  const theme = useTheme();
  const notifications = useAppSelector(
    (state: RootState) => state.globalState.notifications
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const nbNewNotifications = useAppSelector(
    (state: RootState) => state.globalState.nbNewNotifications
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const { t, i18n } = useTranslation();
  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clickNotification = React.useCallback(
    async (notification: NotificationInterface) => {
      handleClose();
      if (notification.url) {
        navigate(notification.url);
      }
      if (notification.new) {
        const response = await requestApi({
          method: PATCH,
          path: NOTIFICATION_URL + "/" + notification.id,
          allowError: true,
          token: token,
          body: {
            new: false,
          },
        });
        if (response.statusCode === 200) {
          dispatch(updateNotification(response.content));
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }
    },
    [dispatch, handleClose, navigate, t, token]
  );

  return (
    <Box>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        onClick={handleClick}
      >
        <Badge badgeContent={nbNewNotifications ?? 0} color="primary">
          <NotificationsIcon fontSize={isSmall ? "inherit" : "large"} />
        </Badge>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {notifications?.map((notification, indexNotification) => {
          let trans = t(notification.trans);
          if (notification.param) {
            for (const [key, value] of Object.entries(notification.param)) {
              // @ts-ignore
              trans = trans.replace("%" + key + "%", value);
            }
          }
          return (
            <MenuItem
              key={indexNotification}
              onClick={() => clickNotification(notification)}
              sx={{ flexDirection: "column", alignItems: "flex-end" }}
            >
              <Badge
                color="primary"
                variant="dot"
                invisible={!notification.new}
              >
                {trans}
              </Badge>
              <Typography variant="caption">
                {new Date(notification.created).toLocaleString(i18n.language, {
                  dateStyle: "long",
                  timeStyle: "long",
                })}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
});

export default NotificationComponent;
