import * as React from "react";
import { CSSProperties } from "react";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";

interface State {
  fArticle: FArticleSmallInterface;
  openNewTab: boolean | undefined;
  beforeNavigate?: Function;
  style?: CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const FArticleLinkComponent: React.FC<State> = React.memo(
  ({ fArticle, openNewTab, beforeNavigate, children, style, className }) => {
    return (
      <Link
        {...(openNewTab && { target: "_blank" })}
        style={
          !!style
            ? style
            : {
                textDecoration: "none",
                color: "inherit",
              }
        }
        className={className}
        to={PRODUCT_PAGE + "/" + fArticle.fArticleProp?.slug}
        state={fArticle}
        onClick={(e) => {
          if (beforeNavigate) {
            beforeNavigate(fArticle, e.target);
          }
        }}
      >
        {!!children && children}
      </Link>
    );
  }
);

export default FArticleLinkComponent;
