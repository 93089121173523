import * as React from "react";
import { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Skeleton, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { grey } from "@mui/material/colors";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { generateTechnicalSheet } from "../../../helpers/FArticleHelper";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import { FournisseurArticleInterface } from "../../../interfaces/FArticleInterface";

interface State {
  arRef: string | undefined;
  arCodebarre: string | undefined;
  edit: boolean;
  setFArticle: Function;
  label?: string;
  sx?: SxProps<Theme>;
  temporaryIcecat?: any;
  fournisseurProduct?: FournisseurArticleInterface;
}

interface FormState {
  arCodebarre: InputInterface;
}

const FArticleArCodebarreComponent = React.memo(
  React.forwardRef(
    (
      {
        arRef,
        arCodebarre,
        edit,
        setFArticle,
        label,
        sx,
        temporaryIcecat,
        fournisseurProduct,
      }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      let availableArCodebarres: string[] = [];
      if (temporaryIcecat) {
        const temp: any = {
          ficheTechnique: temporaryIcecat,
        };
        const technicalSheet = generateTechnicalSheet(temp, 1);
        if (technicalSheet?.arCodebarre) {
          availableArCodebarres = technicalSheet.arCodebarre;
        }
      }
      if (fournisseurProduct?.eanUpc) {
        availableArCodebarres.push(fournisseurProduct.eanUpc);
      }
      // @ts-ignore
      availableArCodebarres = [...new Set(availableArCodebarres)];
      const getDefaultValues = React.useCallback((): FormState => {
        let thisArCodeBarre = arCodebarre ?? "";
        if (availableArCodebarres.length === 1) {
          thisArCodeBarre = availableArCodebarres[0];
        }
        return {
          arCodebarre: { value: thisArCodeBarre, error: "" },
        };
      }, [arCodebarre, availableArCodebarres]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const [isUnique, setIsUnique] = React.useState<boolean | undefined>(
        undefined
      );
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          arCodebarre: values.arCodebarre.value,
        };
      }, [values.arCodebarre.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        const thisValues = getValue();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: arRef,
            ...thisValues,
            fArticleProp: {
              ...thisValues,
            },
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [arRef, getValue, setFArticle, t, token]);

      const getIsUnique = React.useCallback(async () => {
        setIsUnique(undefined);
        if (values.arCodebarre.value.length !== 13 || !(thisEdit || edit)) {
          return;
        }
        const response = await requestApi({
          method: GET,
          path:
            FARTICLE_URL +
            objectToQuery({
              keywords: values.arCodebarre.value,
            }),
          allowError: false,
          paginate: false,
          token: token,
          timeout: 30_000,
        });
        let isUnique = true;
        if (response.statusCode === 200) {
          if (arRef === undefined) {
            isUnique = response.content.fArticles.length === 0;
          } else {
            for (const fArticle of response.content.fArticles) {
              if (
                fArticle.arCodebarre === values.arCodebarre.value &&
                arRef !== fArticle.arRef
              ) {
                isUnique = false;
                break;
              }
            }
          }
          setIsUnique(isUnique);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }, [arRef, edit, t, thisEdit, token, values.arCodebarre.value]);

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: {
                  ...v[prop],
                  value: event.target.value.toUpperCase(),
                  error: "",
                },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [arCodebarre, temporaryIcecat, fournisseurProduct]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        const timeoutTyping = setTimeout(() => {
          getIsUnique();
        }, 500);
        return () => clearTimeout(timeoutTyping);
      }, [values.arCodebarre.value, thisEdit, edit]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {thisEdit || edit ? (
            <>
              <TextField
                autoComplete="off"
                error={!!values.arCodebarre.error}
                helperText={t(values.arCodebarre.error ?? "")}
                sx={{ width: "100%", marginTop: 1 }}
                required
                type="text"
                value={values.arCodebarre.value}
                onChange={handleChange("arCodebarre")}
                label={t("word.barCode")}
              />
              {isUnique === undefined ? (
                <>
                  <Skeleton variant="text" />
                </>
              ) : isUnique ? (
                <Typography>{t("word.arCodebarre.isUnique")}</Typography>
              ) : (
                <Typography color="error">
                  {t("word.arCodebarre.isNotUnique")}
                </Typography>
              )}
              {availableArCodebarres?.map((thisArCodebarre) => (
                <Box
                  key={thisArCodebarre}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography>{thisArCodebarre}</Typography>
                  <IconButton
                    onClick={() => {
                      setValues((v: any) => {
                        return {
                          ...v,
                          arCodebarre: {
                            ...v["arCodebarre"],
                            value: thisArCodebarre,
                            error: "",
                          },
                        };
                      });
                    }}
                  >
                    <DoneIcon />
                  </IconButton>
                </Box>
              ))}
              {!edit && (
                <>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={handleThisEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              <Typography sx={{ width: "100%" }}>
                {label !== undefined ? (
                  label
                ) : (
                  <span style={{ color: grey[500] }}>
                    {t("word.barCode") + ": "}
                  </span>
                )}
                {arCodebarre === undefined ? (
                  <Skeleton sx={{ flex: 1 }} variant="text" />
                ) : (
                  <CopyClipboardComponent
                    className="RobotoMono"
                    component="span"
                    text={arCodebarre}
                    sx={sx}
                  />
                )}
                {isAdmin && (
                  <IconButton onClick={handleThisEdit}>
                    <EditIcon />
                  </IconButton>
                )}
              </Typography>
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleArCodebarreComponent;
