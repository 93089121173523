import React, { useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Tooltip } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";

interface State {
  changeInfinitScroll: Function;
}

const ConfigureViewOrdersComponent = React.memo(
  React.forwardRef(({ changeInfinitScroll }: State, ref) => {
    const { t } = useTranslation();
    const infinitScrollOrders = useAppSelector(
      (state: RootState) => state.globalState.infinitScrollOrders
    );
    const [show, setShow] = React.useState(false);

    useImperativeHandle(ref, () => ({
      toggleShow() {
        setShow((x) => !x);
      },
    }));

    return (
      <>
        {show && (
          <Card variant="outlined">
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 1,
                  flexWrap: "wrap",
                }}
              >
                <Typography sx={{ marginRight: 2 }}>
                  {t("word.displayType") + ":"}
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={infinitScrollOrders}
                  exclusive
                  onChange={(event, value) => {
                    changeInfinitScroll(value);
                  }}
                >
                  <ToggleButton value={true}>
                    {t("word.paging.infinite")}
                    <Tooltip title={t("sentence.paging.infinite")}>
                      <InfoIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={false}>
                    {t("word.paging.page")}
                    <Tooltip title={t("sentence.paging.page")}>
                      <InfoIcon />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </CardContent>
          </Card>
        )}
      </>
    );
  })
);

export default ConfigureViewOrdersComponent;
