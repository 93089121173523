import React, { useImperativeHandle } from "react";
import {
  FilterFilterImageInterface,
  FilterFilterImageInterfaceFull,
} from "../../../../../interfaces/FilterInterface";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { CircularProgress, DialogProps } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { RootState } from "../../../../../app/store";
import { getUrlFilterImageImage } from "../../../../../helpers/FileHelper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import { requestApi } from "../../../../../helpers/RequestApi";
import { DELETE, GET } from "../../../../../utils/MethodUtils";
import { FILTER_IMAGE_URL } from "../../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import getErrorApi from "../../../../../helpers/GetErrorApi";
import { removeFilterImage } from "../../../../../app/globalSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DeleteFilterImageDialogComponent = React.memo(
  React.forwardRef((props, ref) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [filterImage, setFilterImage] =
      React.useState<FilterFilterImageInterfaceFull | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);

    const handleClose = React.useCallback(() => {
      setOpenDialog(false);
      setFilterImage(null);
    }, []);

    const handleOpen = React.useCallback(
      (thisFilterImage: FilterFilterImageInterface | null) => {
        setOpenDialog(true);
        setFilterImage(thisFilterImage);
      },
      []
    );

    const getFilterImage = React.useCallback(async () => {
      if (!filterImage) {
        return;
      }
      setDisabled(true);
      setLoading(true);
      const response = await requestApi({
        method: GET,
        path: FILTER_IMAGE_URL + "/" + filterImage.id,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setFilterImage(response.content);
        setDisabled(response.content.filterValues.length > 0);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setLoading(false);
    }, [filterImage, t, token]);

    const deleteFilterImage = React.useCallback(async () => {
      if (!filterImage) {
        return;
      }
      setLoading(true);
      const response = await requestApi({
        method: DELETE,
        path: FILTER_IMAGE_URL + "/" + filterImage.id,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 204) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.image_deleted")
        );
        dispatch(removeFilterImage(filterImage?.id));
        handleClose();
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [dispatch, filterImage, handleClose, t, token]);

    useImperativeHandle(ref, () => ({
      handleOpen(thisFilterImage: FilterFilterImageInterface | null) {
        handleOpen(thisFilterImage);
      },
    }));

    React.useEffect(() => {
      getFilterImage();
    }, [filterImage?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Dialog
          fullWidth
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleClose}
          open={openDialog}
        >
          <DialogContent>
            {filterImage && (
              <>
                <Box sx={{ textAlign: "center" }}>
                  <img
                    src={getUrlFilterImageImage(filterImage)}
                    alt={filterImage.name}
                    loading="lazy"
                    style={{ maxHeight: 40 }}
                  />
                  <Typography>{filterImage.name}</Typography>
                  {loading && <CircularProgress />}
                </Box>
                {filterImage?.filterValues &&
                  filterImage.filterValues.length > 0 && (
                    <>
                      <Typography>
                        {t("sentence.firstDeleteFilterImage")}
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                {t("word.filterId")}
                              </StyledTableCell>
                              <StyledTableCell>
                                {t("word.filterName")}
                              </StyledTableCell>
                              <StyledTableCell>
                                {t("word.value")}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filterImage.filterValues.map(
                              (filterValue, indexFilterValue) => (
                                <StyledTableRow key={indexFilterValue}>
                                  <StyledTableCell>
                                    {filterValue.filter.id}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {filterValue.filter.name}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {filterValue.name}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <LoadingButton onClick={handleClose} disabled={loading}>
              {t("word.cancel")}
            </LoadingButton>
            <LoadingButton
              disabled={disabled}
              variant="contained"
              onClick={deleteFilterImage}
              loading={loading}
              color="error"
            >
              {t("word.delete")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    );
  })
);

export default DeleteFilterImageDialogComponent;
