import React, { SyntheticEvent, useImperativeHandle } from "react";
import { Autocomplete, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SavInterface } from "../../../interfaces/SavInterface";
import {
  FDocenteteInterface,
  FDocenteteLignePaginateInterface,
} from "../../../interfaces/FDocenteteInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";

interface State {
  sav?: SavInterface | null;
  fDocentete: FDocenteteInterface | null;
  setDisplaySerialNumber: Function;
}

interface FormState {
  fDoclignePaginate: InputInterface;
}

const SavFArticleNoveFormComponent = React.memo(
  React.forwardRef(
    ({ sav, fDocentete, setDisplaySerialNumber }: State, ref) => {
      const theme = useTheme();
      const { t } = useTranslation();
      const getFDocenteteLignePaginates =
        React.useCallback((): FDocenteteLignePaginateInterface[] => {
          if (fDocentete === null) {
            return [];
          }
          return fDocentete.fDoclignesPaginate ?? [];
        }, [fDocentete]);
      const [fDocenteteLignePaginates, setFDocenteteLignePaginates] =
        React.useState<FDocenteteLignePaginateInterface[]>(
          getFDocenteteLignePaginates()
        );

      const getDefaultValue = React.useCallback((): FormState => {
        const fDocenteteLignePaginates = getFDocenteteLignePaginates();
        return {
          fDoclignePaginate: {
            value: sav?.fDoclignePaginate
              ? sav?.fDoclignePaginate
              : sav && sav.dlDesign !== ""
              ? { dlDesign: sav.dlDesign, lsNoserie: sav.fArticleSerialNumber }
              : fDocenteteLignePaginates.length === 1
              ? fDocenteteLignePaginates[0]
              : null,
            error: "",
          },
        };
      }, [getFDocenteteLignePaginates, sav]);
      const [values, setValues] = React.useState<FormState>(getDefaultValue());

      const handleChangeAutocomplete = React.useCallback(
        (prop: keyof FormState) =>
          (event: SyntheticEvent<Element, Event>, value: any) => {
            setDisplaySerialNumber(value?.lsNoserie ?? "");
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        [setDisplaySerialNumber]
      );

      const getValue = React.useCallback(() => {
        const fDoclignePaginateError = notEmptyValidator(
          values.fDoclignePaginate.value
        );
        if (fDoclignePaginateError) {
          const newValue: FormState = { ...values };
          if (fDoclignePaginateError) {
            newValue.fDoclignePaginate.error = fDoclignePaginateError;
          }
          setValues(newValue);
          return undefined;
        }
        return {
          fArticleId: values.fDoclignePaginate.value.arRef,
          fArticleSerialNumber: values.fDoclignePaginate.value.lsNoserie,
        };
      }, [values]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setFDocenteteLignePaginates(getFDocenteteLignePaginates());
        setValues(getDefaultValue());
      }, [sav, fDocentete]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          <Autocomplete
            disabled={fDocentete === null || !!sav?.id}
            options={fDocenteteLignePaginates}
            getOptionLabel={(
              fDoclignePaginate: FDocenteteLignePaginateInterface
            ) => {
              let dlDesign = fDoclignePaginate.dlDesign;
              if (fDoclignePaginate.lsNoserie) {
                dlDesign += " [" + fDoclignePaginate.lsNoserie + "]";
              }
              return dlDesign;
            }}
            isOptionEqualToValue={(
              option: FDocenteteLignePaginateInterface,
              value: FDocenteteLignePaginateInterface
            ) => option.lineIndex === value.lineIndex}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  // @ts-ignore
                  key={props["data-option-index"]}
                  style={{ display: "block" }}
                >
                  {option.dlDesign}
                  {option.lsNoserie && (
                    <span style={{ color: theme.palette.primary.main }}>
                      {" SN: [" + option.lsNoserie + "]"}
                    </span>
                  )}
                </li>
              );
            }}
            onChange={handleChangeAutocomplete("fDoclignePaginate")}
            value={values.fDoclignePaginate.value}
            defaultValue={values.fDoclignePaginate.value}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                required
                error={!!values.fDoclignePaginate.error}
                helperText={t(values.fDoclignePaginate.error ?? "")}
                label={t("field.fDoclignePaginate")}
                placeholder={t("field.fDoclignePaginate")}
              />
            )}
          />
        </>
      );
    }
  )
);

export default SavFArticleNoveFormComponent;
