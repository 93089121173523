import * as React from "react";
import { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { ExpeditionGrilleInterface } from "../../../interfaces/CartInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import {
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import EuroIcon from "@mui/icons-material/Euro";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { COUNTRY_CODE_MAP } from "../../../utils/UserUtils";

interface State {
  expeditionGrille: ExpeditionGrilleInterface | undefined;
  countryCode: string;
}

interface FormState {
  price: InputInterface;
  weight: InputInterface;
}

const ExpeditionGrilleFormComponent = React.memo(
  React.forwardRef(({ expeditionGrille, countryCode }: State, ref) => {
    const { t } = useTranslation();
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        price: {
          value: !expeditionGrille ? "" : expeditionGrille.price / 100,
          error: "",
        },
        weight: {
          value: !expeditionGrille ? "" : expeditionGrille.weight,
          error: "",
        },
      };
    }, [expeditionGrille]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          price: values.price.value,
          weight: values.weight.value,
          countryCode: countryCode,
        };
      },
    }));

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            error={!!values.weight.error}
            helperText={t(values.weight.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="number"
            value={values.weight.value}
            onChange={handleChange("weight")}
            label={t("field.weight")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>
                    {"< " + values.weight.value / 1000 + "kg"}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            error={!!values.price.error}
            helperText={t(values.price.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="number"
            value={values.price.value}
            onChange={handleChange("price")}
            label={t("field.price")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EuroIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>
              {t("word.country." + COUNTRY_CODE_MAP[countryCode].toLowerCase())}
            </InputLabel>
            <Select
              disabled={true}
              value={countryCode}
              label={t(
                "word.country." + COUNTRY_CODE_MAP[countryCode].toLowerCase()
              )}
            >
              <MenuItem value={countryCode}>
                {t(
                  "word.country." + COUNTRY_CODE_MAP[countryCode].toLowerCase()
                )}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  })
);

export default ExpeditionGrilleFormComponent;
