import React, { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import { PORT_LIVRAISON } from "../../../../utils/FDoceneteteUtils";
import { InputInterface } from "../../../../interfaces/InputInterface";

interface State {
  updateSearchParams: Function;
}

interface FormState {
  checkedPaid: InputInterface;
  checkedDelivery: InputInterface;
  checkedChorus: InputInterface;
  checkedChorusPointage: InputInterface;
  checkedIsUserBonDeCommande: InputInterface;
  checkedValidation: InputInterface;
  checkedHasMedias: InputInterface;
}

const ConsoleComptoirFilterCustomComponent = React.memo(
  React.forwardRef(({ updateSearchParams }: State, ref) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const getCheckedPaid = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.paid === "1";
    }, [searchParams]);
    const getCheckedDelivery = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.doExpedit === PORT_LIVRAISON.toString();
    }, [searchParams]);
    const getCheckedChorus = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.chorus === "1";
    }, [searchParams]);
    const getCheckedChorusPointage = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.notPointage === "1";
    }, [searchParams]);
    const getCheckedIsUserBonDeCommande = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.isUserBonDeCommande === "1";
    }, [searchParams]);
    const getCheckedValidation = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.validation === "1";
    }, [searchParams]);
    const getCheckedHasMedias = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.hasMedias === "1";
    }, [searchParams]);

    const getDefaultValues = React.useCallback((): FormState => {
      return {
        checkedPaid: {
          value: getCheckedPaid(),
          error: "",
        },
        checkedDelivery: {
          value: getCheckedDelivery(),
          error: "",
        },
        checkedChorus: {
          value: getCheckedChorus(),
          error: "",
        },
        checkedChorusPointage: {
          value: getCheckedChorusPointage(),
          error: "",
        },
        checkedIsUserBonDeCommande: {
          value: getCheckedIsUserBonDeCommande(),
          error: "",
        },
        checkedValidation: {
          value: getCheckedValidation(),
          error: "",
        },
        checkedHasMedias: {
          value: getCheckedHasMedias(),
          error: "",
        },
      };
    }, [
      getCheckedChorus,
      getCheckedChorusPointage,
      getCheckedDelivery,
      getCheckedHasMedias,
      getCheckedIsUserBonDeCommande,
      getCheckedPaid,
      getCheckedValidation,
    ]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChangeCheckbox = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      []
    );

    const updateChecked = React.useCallback(() => {
      setValues(getDefaultValues());
    }, [getDefaultValues]);

    useImperativeHandle(ref, () => ({
      getCheckedPaid() {
        return values.checkedPaid.value;
      },
      getCheckedDelivery() {
        return values.checkedDelivery.value;
      },
      getCheckedChorus() {
        return values.checkedChorus.value;
      },
      getCheckedChorusPointage() {
        return values.checkedChorusPointage.value;
      },
      getCheckedIsUserBonDeCommande() {
        return values.checkedIsUserBonDeCommande.value;
      },
      getCheckedValidation() {
        return values.checkedValidation.value;
      },
      getCheckedHasMedias() {
        return values.checkedHasMedias.value;
      },
    }));

    React.useEffect(() => {
      updateChecked();
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      updateSearchParams();
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <FormGroup sx={{ flexDirection: "row" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkedPaid.value}
                onChange={handleChangeCheckbox("checkedPaid")}
              />
            }
            label={t("word.paid")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkedDelivery.value}
                onChange={handleChangeCheckbox("checkedDelivery")}
              />
            }
            label={t("word.delivery")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkedChorus.value}
                onChange={handleChangeCheckbox("checkedChorus")}
              />
            }
            label={t("word.chorus")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkedChorusPointage.value}
                onChange={handleChangeCheckbox("checkedChorusPointage")}
              />
            }
            label={t("word.notPointage")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkedIsUserBonDeCommande.value}
                onChange={handleChangeCheckbox("checkedIsUserBonDeCommande")}
              />
            }
            label={t("word.documentVenteBonDeCommande")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkedValidation.value}
                onChange={handleChangeCheckbox("checkedValidation")}
              />
            }
            label={t("word.canChangeBonCommande")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkedHasMedias.value}
                onChange={handleChangeCheckbox("checkedHasMedias")}
              />
            }
            label={t("word.hasMedias")}
          />
        </FormGroup>
      </>
    );
  })
);

export default ConsoleComptoirFilterCustomComponent;
