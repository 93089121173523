import React, { forwardRef, useImperativeHandle } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";

interface State {
  apply: Function;
  setNbSelected: Function;
  index: number;
  filterInited: Function;
}

const WeightFilterComponent = React.memo(
  forwardRef(({ apply, setNbSelected, index, filterInited }: State, ref) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [searchParams] = useSearchParams();
    const searchParamsObject = searchParamToObject(searchParams);
    const [value, setValue] = React.useState(
      searchParamsObject.hasWeight ?? ""
    );
    const { t } = useTranslation();

    const onClick = React.useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        const newValue = (event.target as HTMLInputElement).value;
        if (value === newValue) {
          setValue("");
        } else {
          setValue(newValue);
        }
      },
      [value]
    );

    React.useEffect(() => {
      setNbSelected(value === "" ? 0 : 1);
      if (!isSmall) {
        apply();
      }
      filterInited(index);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        if (value !== "") {
          return { hasWeight: value };
        }
        return {};
      },
    }));

    return (
      <FormControl>
        <RadioGroup
          aria-labelledby={"stock-radio-buttons-group-label"}
          name={"stock-radio-buttons-group"}
          value={value}
        >
          <FormControlLabel
            value="0"
            control={<Radio onClick={onClick} sx={{ paddingY: 0 }} />}
            label={t("word.no")}
          />
        </RadioGroup>
      </FormControl>
    );
  })
);

export default WeightFilterComponent;
