import React from "react";
import Layout from "../components/common/Layout";
import LoginComponent from "../components/common/user/LoginComponent";
import { useLocation } from "react-router-dom";
import { LOGIN_PAGE } from "../utils/RouteUtils";
import { getTitle } from "../helpers/SearchParamHelper";

const LoginScreen: React.FC = React.memo(() => {
  const { state }: any = useLocation();

  React.useEffect(() => {
    document.title = getTitle("login");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent
        redirect={
          !!state && state.prevPath !== LOGIN_PAGE ? state.prevPath : null
        }
      />
    </Layout>
  );
});

export default LoginScreen;
