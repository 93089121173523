import React from "react";
import { Chip, Container, DialogProps, useTheme } from "@mui/material";
import LoginComponent from "../user/LoginComponent";
import { set } from "../../../app/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { TagInterface } from "../../../interfaces/TagInterface";
import DrawerComponent from "../DrawerComponent";
import TagFormComponent from "./TagFormComponent";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { TAG_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { RootState } from "../../../app/store";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import TagDeleteComponent from "./TagDeleteComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const minWidth = 900;

const TagComponent = React.memo(() => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [tag, setTag] = React.useState<TagInterface | null | undefined>(
    undefined
  );
  const [tags, setTags] = React.useState<TagInterface[] | undefined>(undefined);
  const theme = useTheme();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [tagDelete, setTagDelete] = React.useState<
    TagInterface | null | undefined
  >(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const [init, setInit] = React.useState(false);
  const width = window.innerWidth;
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const handleDrawerOpen = React.useCallback((thisTag: TagInterface | null) => {
    setTag(thisTag);
  }, []);

  const handleDrawerDeleteOpen = React.useCallback(
    (thisTag: TagInterface | null) => {
      setTagDelete(thisTag);
    },
    []
  );

  const handleDrawerDeleteClose = React.useCallback(() => {
    setTagDelete(undefined);
  }, []);

  const handleDrawerClose = React.useCallback(() => {
    setTag(undefined);
  }, []);
  const token = useAppSelector((state: RootState) => state.globalState.token);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("column.id"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
    },
    {
      field: "text",
      headerName: t("column.text"),
      flex: 1,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
    },
    {
      field: "hex",
      headerName: t("column.hex"),
      flex: 1,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Typography component="span">{params.row.hex}</Typography>
          <Chip sx={{ backgroundColor: params.row.hex, marginLeft: 1 }} />
        </>
      ),
    },
    {
      field: "custom",
      headerName: t("column.custom"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.custom ? (
            <DoneIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: t("column.actions"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {!params.row.custom && (
            <>
              <IconButton onClick={() => handleDrawerOpen(params.row)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDrawerDeleteOpen(params.row)}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!force && tags) {
        dispatch(set({ refreshPage: false }));
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = 50;
        hasChanged = true;
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: TAG_URL,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setTags(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [dispatch, searchParams, setSearchParams, t, tags, token]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setTags(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setTags(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <DrawerComponent
        open={tag !== undefined}
        onClose={handleDrawerClose}
        handleDrawerClose={handleDrawerClose}
        drawerwidth={width < minWidth ? width : minWidth}
        content={<TagFormComponent tag={tag} handleClose={handleDrawerClose} />}
      />
      <Dialog
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        fullWidth={true}
        onClose={handleDrawerDeleteClose}
        open={tagDelete !== undefined}
      >
        <TagDeleteComponent
          tag={tagDelete}
          handleClose={handleDrawerDeleteClose}
        />
      </Dialog>
      <Container sx={{ marginY: 2 }}>
        <LoginComponent redirect={null} requireAdmin={true}>
          <LoadingButton
            variant="contained"
            onClick={() => handleDrawerOpen(null)}
          >
            {t("word.tag.create")}
          </LoadingButton>
          {searchParamsObject.itemsPerPage && searchParamsObject.page && (
            <DataGrid
              loading={refreshPage}
              rows={refreshPage ? [] : tags ?? []}
              sortingMode="server"
              page={Number(searchParamsObject.page) - 1}
              rowsPerPageOptions={[10, 25, 50]}
              pageSize={Number(searchParamsObject.itemsPerPage)}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              rowCount={tags?.length ?? 0}
              columns={columns}
              autoHeight={true}
              disableExtendRowFullWidth={true}
              localeText={getLocaleDataGrid(i18n.language)}
            />
          )}
        </LoginComponent>
      </Container>
    </>
  );
});

export default TagComponent;
