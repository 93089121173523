import React from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import LoginIcon from "@mui/icons-material/Login";
import { initAppHelper } from "../../../helpers/InitAppHelper";
import { ORDERS_PAGE } from "../../../utils/RouteUtils";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";

interface State {
  userIdentifier: string;
}

const ImpersonateButtonComponent: React.FC<State> = React.memo(
  ({ userIdentifier }) => {
    const dispatch = useAppDispatch();

    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const handleImpersonate = React.useCallback(
      async (userIdentifier: string) => {
        if (!token) {
          return;
        }
        setLoading(true);
        dispatch(set({ impersonate: userIdentifier }));
        setTimeout(async () => {
          if (
            await initAppHelper(
              token,
              undefined,
              true,
              undefined,
              userIdentifier
            )
          ) {
            setTimeout(() => {
              dispatch(set({ impersonate: userIdentifier }));
              navigate(ORDERS_PAGE);
            });
          } else {
            toastr.error(t("word.error"), t("error.tryAgain"));
            setLoading(false);
          }
        });
      },
      [dispatch, navigate, t, token]
    );

    return (
      <Tooltip title={t("word.connectAsUser")}>
        <LoadingButton
          variant="text"
          color="inherit"
          sx={{
            borderRadius: "50%",
            minWidth: "auto",
            padding: "12px",
          }}
          loading={loading}
          onClick={() => handleImpersonate(userIdentifier)}
        >
          <LoginIcon />
        </LoadingButton>
      </Tooltip>
    );
  }
);

export default ImpersonateButtonComponent;
