export const TYPE_BUG = "A";
export const TYPE_COMPTA = "B";
export const TYPE_EXPEDITION = "C";
export const TYPE_ORDER = "D";
export const TYPE_FARTICLE = "E";
export const TYPE_PAYMENT = "F";
export const TYPE_OTHER = "G";

export const STATUS_OPEN = "A";
export const STATUS_CLOSED = "B";

export const ALL_STATUS_CONTACT = [STATUS_OPEN, STATUS_CLOSED];

export const ALL_TYPE_CONTACT = [
  TYPE_BUG,
  TYPE_COMPTA,
  TYPE_EXPEDITION,
  TYPE_ORDER,
  TYPE_FARTICLE,
  TYPE_PAYMENT,
  TYPE_OTHER,
];
