import React, { forwardRef, useImperativeHandle } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";

interface State {
  name: string;
  data: any;
}

const SearchProductBooleanComponent = React.memo(
  forwardRef(({ name, data }: State, ref) => {
    const getValue = React.useCallback(() => {
      return data !== undefined && data.hasOwnProperty(name)
        ? data[name].toString()
        : "";
    }, [data, name]);
    const [value, setValue] = React.useState<string>(getValue());
    const { t } = useTranslation();

    const onClick = React.useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        const newValue = (event.target as HTMLInputElement).value;
        if (value === newValue) {
          setValue("");
        } else {
          setValue(newValue);
        }
      },
      [value]
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        if (value === "1") {
          return 1;
        }
        if (value === "0") {
          return 0;
        }
        return null;
      },
    }));

    React.useEffect(() => {
      setValue(getValue());
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormControl>
        <FormLabel id={name + "-radio-buttons-group-label"}>
          {t("searchProduct." + name)}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby={name + "-radio-buttons-group-label"}
          name={name + "-radio-buttons-group"}
          value={value}
        >
          <FormControlLabel
            value="1"
            control={<Radio onClick={onClick} />}
            label={t("word.yes")}
          />
          <FormControlLabel
            value="0"
            control={<Radio onClick={onClick} />}
            label={t("word.no")}
          />
        </RadioGroup>
      </FormControl>
    );
  })
);

export default SearchProductBooleanComponent;
