import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { set } from "../../../app/globalSlice";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { PAYMENT_HISTORY_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useSearchParams } from "react-router-dom";
import { PaymentHistoryInterface } from "../../../interfaces/PaymentHistoryInterface";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { Chip, Grid, List, ListItem, ListItemText } from "@mui/material";
import {
  STATUS_PENDING,
  STATUS_SUCCESS,
} from "../../../utils/PaymentHistoryUtils";
import DrawerComponent from "../DrawerComponent";
import PaymentHistoryComponent from "./PaymentHistoryComponent";
import PaymentsSearchComponent from "./PaymentsSearchComponent";
import { ALL_PAYMENT_TYPES } from "../../../utils/PaymentUtils";
import CopyClipboardComponent from "../CopyClipboardComponent";
import Divider from "@mui/material/Divider";

const minWidth = 900;

const PaymentsComponent: React.FC = React.memo(() => {
  const { t, i18n } = useTranslation();
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const [defaultItemsPerPage] = React.useState(50);
  const [paymentHistory, setPaymentHistory] = React.useState<
    PaymentHistoryInterface | undefined
  >(undefined);
  const [paymentHistories, setPaymentHistories] = React.useState<
    PaymentHistoryInterface[] | undefined
  >(undefined);
  const [totalItems, setTotalItems] = React.useState(0);
  const handleDrawerOpen = React.useCallback(
    (paymentHistory: PaymentHistoryInterface | undefined) => {
      setPaymentHistory(paymentHistory);
    },
    []
  );
  const handleDrawerClose = React.useCallback(() => {
    setPaymentHistory(undefined);
  }, []);
  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "userIdentifier",
        headerName: t("column.userIdentifier"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.userIdentifier}
          />
        ),
      },
      {
        field: "reference",
        headerName: t("column.reference"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        cellClassName: "flex-wrap-wrap",
        renderCell: (params: GridRenderCellParams) => (
          <>
            <CopyClipboardComponent
              className="RobotoMono"
              component="p"
              text={params.row.reference}
            />
            {params.row?.accountingSituation?.fEcriturecIds && (
              <>
                <Divider sx={{ width: "100%" }} />
                {params.row.accountingSituation.fEcriturecIds.map(
                  (fEcriturecId: string) => (
                    <CopyClipboardComponent
                      key={fEcriturecId}
                      className="RobotoMono"
                      component="p"
                      sx={{ marginX: 0.5 }}
                      text={fEcriturecId}
                    />
                  )
                )}
              </>
            )}
          </>
        ),
      },
      {
        field: "created",
        headerName: t("column.created"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.row.created).toLocaleString(i18n.language, {
            dateStyle: "long",
            timeStyle: "medium",
            timeZone: "America/Cuiaba",
          }),
      },
      {
        field: "updated",
        headerName: t("column.updated"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.row.updated).toLocaleString(i18n.language, {
            dateStyle: "long",
            timeStyle: "medium",
            timeZone: "America/Cuiaba",
          }),
      },
      {
        field: "status",
        headerName: t("column.status"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Chip
            label={t("word.paymentHistory.status." + params.row.status)}
            variant="outlined"
            color={
              params.row.status === STATUS_PENDING
                ? "primary"
                : params.row.status === STATUS_SUCCESS
                ? "success"
                : "error"
            }
          />
        ),
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <IconButton onClick={() => handleDrawerOpen(params.row)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
    ];
  }, [handleDrawerOpen, i18n.language, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (paymentHistories !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (
        !searchParamsObject.hasOwnProperty("page") ||
        searchParamsObject.page === "undefined"
      ) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: PAYMENT_HISTORY_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content.paymentHistories.map(
            (o: PaymentHistoryInterface) => o.id
          )
        );
        const thisPaymentHistories = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content.paymentHistories,
        ];
        setTotalItems(response.content.totalItems);
        setPaymentHistories(thisPaymentHistories);
        dispatch(set({ nbErrorPayments: response.content.nbErrorPayments }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      paymentHistories,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setPaymentHistories(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setPaymentHistories(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <DrawerComponent
        open={!!paymentHistory}
        handleDrawerClose={handleDrawerClose}
        onClose={handleDrawerClose}
        drawerwidth={
          window.innerWidth < minWidth ? window.innerWidth : minWidth
        }
        content={
          <PaymentHistoryComponent
            paymentHistory={paymentHistory}
            reloadGrid={load}
            setPaymentHistory={setPaymentHistory}
          />
        }
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <PaymentsSearchComponent
            init={
              init && searchParamsObject.itemsPerPage && searchParamsObject.page
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <List>
            <ListItem disablePadding>
              <ListItemText primary={t("column.reference") + ":"} />
            </ListItem>
            {ALL_PAYMENT_TYPES.map((type, indexType) => (
              <ListItem disablePadding key={indexType}>
                <ListItemText
                  primary={
                    type + "-: " + t("word.payment.type." + type.toLowerCase())
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      {init && searchParamsObject.itemsPerPage && searchParamsObject.page && (
        // https://mui.com/x/react-data-grid/components/#pagination
        <DataGrid
          initialState={{
            sorting: {
              sortModel: getSortModel(),
            },
          }}
          loading={refreshPage}
          getRowHeight={() => "auto"}
          rows={refreshPage ? [] : paymentHistories ?? []}
          onSortModelChange={onSortModelChange}
          sortingMode="server"
          page={Number(searchParamsObject.page) - 1}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={Number(searchParamsObject.itemsPerPage)}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          rowCount={totalItems}
          columns={columns}
          autoHeight={true}
          disableExtendRowFullWidth={true}
          localeText={getLocaleDataGrid(i18n.language)}
        />
      )}
    </>
  );
});

export default PaymentsComponent;
