import React, { useImperativeHandle } from "react";
import { FComptetUser } from "../../../../../../interfaces/UserInterface";
import { TextField } from "@mui/material";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { requestApi } from "../../../../../../helpers/RequestApi";
import { PATCH } from "../../../../../../utils/MethodUtils";
import { USER_PROP_URL } from "../../../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../../../../app/hooks";
import { RootState } from "../../../../../../app/store";
import { LoadingButton } from "@mui/lab";
import { DEFAULT_PERCENT_ACOMPTE } from "../../../../../../utils/FArticleUtils";

interface State {
  fComptetUser: FComptetUser;
  setFComptetUser: Function;
}

interface FormState {
  percentAcompte: InputInterface;
}

const UserPercentAcompteComponent = React.memo(
  React.forwardRef(({ fComptetUser, setFComptetUser }: State, ref) => {
    const { t } = useTranslation();
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        percentAcompte: {
          value: fComptetUser?.percentAcompte ?? DEFAULT_PERCENT_ACOMPTE * 10,
          error: "",
        },
      };
    }, [fComptetUser?.percentAcompte]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const [loading, setLoading] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      return {
        percentAcompte: parseInt(values.percentAcompte.value),
      };
    }, [values.percentAcompte.value]);

    const save = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: PATCH,
        path: USER_PROP_URL + "/" + fComptetUser.userPropId,
        allowError: true,
        token: token,
        body: getValue(),
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.user_updated")
        );
        setFComptetUser((x: FComptetUser | undefined) => {
          if (!x) {
            return x;
          }
          x.percentAcompte = response.content.percentAcompte;
          return { ...x };
        });
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [fComptetUser.userPropId, getValue, setFComptetUser, t, token]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [fComptetUser?.percentAcompte]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ marginTop: 1, display: "flex" }}>
        <TextField
          fullWidth={true}
          autoComplete="off"
          error={!!values.percentAcompte.error}
          helperText={t(values.percentAcompte.error ?? "")}
          sx={{ width: "100%" }}
          required
          type="number"
          value={values.percentAcompte.value}
          onChange={handleChange("percentAcompte")}
          label={t("word.percentAcompte")}
        />
        <LoadingButton variant="contained" loading={loading} onClick={save}>
          {t("word.validate")}
        </LoadingButton>
      </Box>
    );
  })
);

export default UserPercentAcompteComponent;
