import * as React from "react";
import Typography from "@mui/material/Typography";
import {
  FArticleSmallInterface,
  InventoryFArticleInterface,
} from "../../../interfaces/FArticleInterface";
import Chart from "react-apexcharts";
import fr from "apexcharts/dist/locales/fr.json";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  CircularProgress,
  DialogProps,
  ListItem,
  useTheme,
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { INVENTORY_FARTICLE } from "../../../utils/UrlsUtils";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { toastr } from "react-redux-toastr";
import { addDays, addYears } from "date-fns";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { defaultFontSize } from "../category/ConfigureViewProductsComponent";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  fontSize?: number;
}

interface State2 {
  prop: string;
  value: any[];
}

interface DetailsDocumentStock {
  key: string;
  value: InventoryFArticleInterface;
}

const DetailInventoryFArticleComponent: React.FC<State2> = React.memo(
  ({ prop, value }: State2) => {
    const { t } = useTranslation();
    const [hasNegative] = React.useState<boolean>(prop.includes("Negative"));
    const [thisProp] = React.useState<string>(prop.replace("Negative", ""));

    return (
      <>
        <ListItem alignItems="flex-start">
          <Typography>
            {t("word." + thisProp)}{" "}
            {hasNegative ? `[${t("word.negative")}]` : ""}
          </Typography>
        </ListItem>
        {thisProp.startsWith("detailSage") ? (
          <ul style={{ marginLeft: 1 }}>
            {value.map((user: any, indexUser: number) => {
              return (
                <React.Fragment key={indexUser}>
                  {user.doPieces.map((doPiece: any, indexDoPiece: number) => (
                    <li key={indexDoPiece}>
                      {doPiece.doPiece + " [" + doPiece.amount + "]"}
                    </li>
                  ))}
                </React.Fragment>
              );
            })}
          </ul>
        ) : (
          <ul style={{ marginLeft: 1 }}>
            {value.map((user: any, indexUser: number) => {
              return (
                <li key={indexUser}>
                  <Typography>
                    {user.ctNum +
                      " [" +
                      user.ctIntitule +
                      "] " +
                      user.ctContact}
                  </Typography>
                  <ul>
                    {user.doPieces.map((doPiece: any, indexDoPiece: number) => (
                      <li key={indexDoPiece}>
                        {doPiece.doPiece + " [" + doPiece.amount + "]"}
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        )}
        <Divider variant="inset" component="li" sx={{ marginLeft: 0 }} />
      </>
    );
  }
);

export const InventoryFArticleComponent: React.FC<State> = React.memo(
  ({ fArticle, fontSize }) => {
    const theme = useTheme();
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { t, i18n } = useTranslation();
    const getInventoryFArticles = React.useCallback(() => {
      return fArticle?.fArticleProp?.inventoryFArticles;
    }, [fArticle?.fArticleProp?.inventoryFArticles]);
    const [inventoryFArticles, setInventoryFArticles] = React.useState<
      InventoryFArticleInterface[] | undefined
    >(getInventoryFArticles());
    const [currentInventoryFArticle, setCurrentInventoryFArticle] =
      React.useState<InventoryFArticleInterface | undefined>(undefined);
    const [fixBoundingClientRect, setFixBoundingClientRect] =
      React.useState<boolean>(false);
    const [now] = React.useState<number>(new Date().getTime());
    const [series] = React.useState<string[]>([
      "nbBuy",
      "nbSell",
      "nbBuyNegative",
      "nbSellNegative",
      "diffDocumentStock",
    ]);
    const [lastYear] = React.useState<number>(
      addYears(new Date(), -1).getTime()
    );
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const onChange = React.useCallback(
      async (event: React.SyntheticEvent, expanded: boolean) => {
        if (!expanded || inventoryFArticles) {
          return;
        }
        const response = await requestApi({
          method: GET,
          path:
            INVENTORY_FARTICLE +
            "?fArticleProp.id=" +
            fArticle?.fArticleProp?.id +
            "&order[date]=desc",
          allowError: false,
          token: token,
        });
        if (response.statusCode === 200) {
          setInventoryFArticles(response.content);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      },
      [fArticle?.fArticleProp?.id, inventoryFArticles, t, token]
    );

    const onDataPointSelection = React.useCallback(
      (dataPointIndex: number, seriesIndex: number) => {
        if (!inventoryFArticles) {
          return;
        }
        const prop = series[seriesIndex];
        let index = 0;
        let found = 0;
        for (const inventoryFArticle of inventoryFArticles) {
          // @ts-ignore
          if (inventoryFArticle[prop] > 0) {
            found++;
          }
          if (found === dataPointIndex) {
            break;
          }
          index++;
        }
        setCurrentInventoryFArticle(inventoryFArticles[index]);
      },
      [inventoryFArticles, series]
    );

    const handleClose = React.useCallback(() => {
      setCurrentInventoryFArticle(undefined);
    }, []);

    const onMounted = React.useCallback(() => {
      setFixBoundingClientRect(true);
    }, []);

    const getDetailsDocumentStock =
      React.useCallback((): DetailsDocumentStock[] => {
        const result: DetailsDocumentStock[] = [];
        if (currentInventoryFArticle) {
          for (const [key, value] of Object.entries(currentInventoryFArticle)) {
            if (!(key.startsWith("detailSage") && value)) {
              continue;
            }
            result.push({
              key: key,
              value: value,
            });
          }
        }
        return result;
      }, [currentInventoryFArticle]);

    React.useEffect(() => {
      setInventoryFArticles(getInventoryFArticles());
    }, [fArticle?.fArticleProp?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {fArticle && (
          <>
            <Dialog
              maxWidth={maxWidth}
              fullScreen={fullScreen}
              fullWidth={true}
              open={fixBoundingClientRect}
              sx={{ display: "none" }}
            />
            <Dialog
              maxWidth={maxWidth}
              fullScreen={fullScreen}
              fullWidth={true}
              onClose={handleClose}
              open={!!currentInventoryFArticle}
            >
              <DialogContent>
                {currentInventoryFArticle && (
                  <>
                    <Typography sx={{ textAlign: "center" }}>
                      {new Date(
                        currentInventoryFArticle.date
                      ).toLocaleDateString(i18n.language, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        timeZone: "Etc/UTC",
                      })}
                    </Typography>
                    <List sx={{ paddingTop: 0 }}>
                      {currentInventoryFArticle &&
                        series
                          .filter(
                            // @ts-ignore
                            (serie) => currentInventoryFArticle[serie] > 0
                          )
                          .map((serie, indexSerie) => {
                            return (
                              <Box key={indexSerie}>
                                {serie === "diffDocumentStock" ? (
                                  <>
                                    {getDetailsDocumentStock().map(
                                      ({ key, value }: any) => (
                                        <DetailInventoryFArticleComponent
                                          key={key}
                                          prop={key}
                                          value={value}
                                        />
                                      )
                                    )}
                                  </>
                                ) : (
                                  <DetailInventoryFArticleComponent
                                    prop={serie}
                                    value={
                                      // @ts-ignore
                                      currentInventoryFArticle[
                                        "detail" +
                                          serie.charAt(0).toUpperCase() +
                                          serie.slice(1)
                                      ]
                                    }
                                  />
                                )}
                              </Box>
                            );
                          })}
                    </List>
                  </>
                )}
              </DialogContent>
              <DialogActions sx={{ justifyContent: "space-between" }}>
                <LoadingButton onClick={handleClose}>
                  {t("word.cancel")}
                </LoadingButton>
              </DialogActions>
            </Dialog>
            <Accordion onChange={onChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={fArticle?.fArticleProp?.id + "-content"}
                id={fArticle?.fArticleProp?.id + "-header"}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  {fArticle?.fArticleProp?.lastBuy && (
                    <Typography
                      sx={{
                        fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                      }}
                    >
                      {t("word.lastBuy") +
                        ": " +
                        new Date(
                          fArticle?.fArticleProp?.lastBuy
                        ).toLocaleDateString(i18n.language, {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          timeZone: "America/Cuiaba",
                        })}
                    </Typography>
                  )}
                  {fArticle?.fArticleProp?.lastSell && (
                    <Typography
                      sx={{
                        fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                      }}
                    >
                      {t("word.lastSell") +
                        ": " +
                        new Date(
                          fArticle?.fArticleProp?.lastSell
                        ).toLocaleDateString(i18n.language, {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          timeZone: "America/Cuiaba",
                        })}
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                {inventoryFArticles ? (
                  <>
                    {inventoryFArticles.length > 0 ? (
                      <Chart
                        options={{
                          chart: {
                            id: fArticle.arRef,
                            locales: [fr],
                            defaultLocale: "fr",
                            animations: {
                              enabled: false,
                            },
                            events: {
                              mounted: () => {
                                onMounted();
                              },
                              dataPointSelection: (
                                event,
                                chartContext,
                                config
                              ) => {
                                onDataPointSelection(
                                  config.dataPointIndex,
                                  config.seriesIndex
                                );
                              },
                            },
                          },
                          colors: [
                            "#5cb85c",
                            "#d32f2f",
                            "#58abc3",
                            "#9e9e9e",
                            "#f0ad4e",
                            "#605ca8",
                          ],
                          stroke: {
                            width: 1,
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          xaxis: {
                            // https://apexcharts.com/javascript-chart-demos/area-charts/datetime-x-axis/
                            type: "datetime",
                            ...(new Date(
                              inventoryFArticles[
                                inventoryFArticles.length - 1
                              ].date
                            ).getTime() > lastYear && {
                              min: lastYear,
                            }),
                          },
                          tooltip: {
                            x: {
                              format: "dd MMM yyyy",
                            },
                          },
                        }}
                        series={[
                          ...series.map((prop: string) => {
                            return {
                              name:
                                t("word." + prop) +
                                " (" +
                                inventoryFArticles?.filter(
                                  (inventoryFArticle) => {
                                    // @ts-ignore
                                    return inventoryFArticle[prop] > 0;
                                  }
                                ).length +
                                ")",
                              type: "bar",
                              data: [
                                [
                                  addDays(
                                    new Date(
                                      // @ts-ignore
                                      inventoryFArticles[0].date
                                    ),
                                    -1
                                  ).getTime(),
                                  null,
                                ],
                                ...(inventoryFArticles
                                  ?.filter((inventoryFArticle) => {
                                    // @ts-ignore
                                    return inventoryFArticle[prop] > 0;
                                  })
                                  .map((inventoryFArticle) => [
                                    new Date(inventoryFArticle.date).getTime(),
                                    // @ts-ignore
                                    inventoryFArticle[prop],
                                  ]) ?? []),
                                [addDays(new Date(now), 1).getTime(), null],
                              ],
                            };
                          }),
                          {
                            name: `${t("word.stock.label")}`,
                            type: "line",
                            data: [
                              [
                                addDays(
                                  new Date(
                                    // @ts-ignore
                                    inventoryFArticles[0].date
                                  ),
                                  -1
                                ).getTime(),
                                null,
                              ],
                              ...(inventoryFArticles.map(
                                (inventoryFArticle) => [
                                  new Date(inventoryFArticle.date).getTime(),
                                  inventoryFArticle.stock,
                                ]
                              ) ?? []),
                              [addDays(new Date(now), 1).getTime(), null],
                            ],
                          },
                        ]}
                        height={300}
                      />
                    ) : (
                      <Typography>{t("sentence.noDetails")}</Typography>
                    )}
                  </>
                ) : (
                  <Box sx={{ textAlign: "center" }}>
                    <CircularProgress />
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </>
    );
  }
);
