import React from "react";
import {
  ContactInterface,
  ContactMessageInterface,
} from "../../../interfaces/ContactInterface";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { CONTACT_MESSAGES_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import FeedbackMessageComponent from "./FeedbackMessageComponent";
import Typography from "@mui/material/Typography";
import DividerWithContent from "../DividerWithContent";
import { LoadingButton } from "@mui/lab";
import { DrawerContainerId } from "../DrawerComponent";

const clone = require("clone");

interface State {
  contact: ContactInterface | null | undefined;
  asAdmin: boolean;
}

const itemsPerPage = 20;

const FeedbackMessagesComponent: React.FC<State> = React.memo(
  ({ contact, asAdmin }) => {
    const [loading, setLoading] = React.useState(true);
    const [canLoadPrevious, setCanLoadPrevious] = React.useState(false);
    const [contactMessages, setContactMessages] = React.useState<
      ContactMessageInterface[]
    >([]);
    const newContactMessage = useAppSelector(
      (state: RootState) => state.globalState.newContactMessage
    );
    const contactMessageIdToDelete = useAppSelector(
      (state: RootState) => state.globalState.contactMessageIdToDelete
    );
    const updateContactMessage = useAppSelector(
      (state: RootState) => state.globalState.updateContactMessage
    );
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const load = React.useCallback(async () => {
      if (!contact) {
        return;
      }
      setLoading(true);
      const response = await requestApi({
        method: GET,
        path:
          CONTACT_MESSAGES_URL.replace("{id}", contact.id.toString() ?? "") +
          objectToQuery({
            "notId[]": contactMessages.map((x) => x.id),
            itemsPerPage: itemsPerPage,
          }),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        const hasMessage = contactMessages.length > 1;
        setCanLoadPrevious(
          response.content["hydra:totalItems"] >= itemsPerPage
        );
        setContactMessages((x) => {
          const newValue: ContactMessageInterface[] = [
            ...response.content["hydra:member"]
              .reverse()
              .filter(
                (y: ContactMessageInterface) =>
                  x.find((z) => z.id === y.id) === undefined
              ),
            ...x,
          ];
          newValue.sort((a, b) => a.id - b.id);
          return newValue;
        });
        if (!hasMessage && response.content["hydra:totalItems"] > 0) {
          setTimeout(() => {
            const objDiv = document.getElementById(DrawerContainerId);
            if (objDiv) {
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          });
        }
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setLoading(false);
    }, [contact, contactMessages, t, token]);

    const onNewContactMessage = React.useCallback(() => {
      let contactMessage = newContactMessage?.contactMessage;
      if (contactMessage === undefined) {
        // @ts-ignore
        contactMessage = newContactMessage;
      }
      if (
        !contactMessage ||
        contactMessage.contact?.id !== contact?.id ||
        contactMessages.map((x) => x.id).includes(contactMessage.id)
      ) {
        return;
      }
      setContactMessages((x) => {
        // @ts-ignore
        x.push(contactMessage);
        return [...x];
      });
    }, [contact, contactMessages, newContactMessage]);

    const onDeleteContactMessage = React.useCallback(() => {
      setContactMessages((x) => {
        return x.filter((y) => y.id !== contactMessageIdToDelete);
      });
    }, [contactMessageIdToDelete]);

    const onUpdateContactMessage = React.useCallback(() => {
      if (!updateContactMessage) {
        return;
      }
      setContactMessages((x) => {
        const newContactMessages: ContactMessageInterface[] = clone(x);
        const thisContactMessage = newContactMessages.find(
          (y) => y.id === updateContactMessage.id
        );
        if (thisContactMessage) {
          thisContactMessage.content = updateContactMessage.content;
          return [...newContactMessages];
        }
        return x;
      });
    }, [updateContactMessage]);

    React.useEffect(() => {
      load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      onNewContactMessage();
    }, [newContactMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      onDeleteContactMessage();
    }, [contactMessageIdToDelete]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      onUpdateContactMessage();
    }, [updateContactMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Typography>{t("word.messages")}</Typography>
        {canLoadPrevious && (
          <DividerWithContent
            sxRoot={{ marginBottom: 2 }}
            content={
              <LoadingButton variant="text" loading={loading} onClick={load}>
                {t("word.loadPreviousMessages")}
              </LoadingButton>
            }
          />
        )}
        {contact &&
          contactMessages.map((contactMessage) => (
            <FeedbackMessageComponent
              contactMessage={contactMessage}
              asAdmin={asAdmin}
              key={contactMessage.id}
            />
          ))}
      </>
    );
  }
);

export default FeedbackMessagesComponent;
