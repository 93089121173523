import React from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import { requestApi } from "../helpers/RequestApi";
import { GET } from "../utils/MethodUtils";
import { CATEGORY_URL } from "../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { Container, useMediaQuery } from "@mui/material";
import { CategoryInterface } from "../interfaces/CategoryInterface";
import DialogFormCategoryComponent from "../components/common/category/DialogFormCategoryComponent";
import SearchComponent from "../components/common/SearchComponent";
import CategoryBigComponent from "../components/common/category/view/CategoryBigComponent";
import IconButton from "@mui/material/IconButton";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import Box from "@mui/material/Box";
import { STORAGE_CATEGORY_DISPLAY } from "../utils/StorageUtils";
import {
  DISPLAY_BIG,
  DISPLAY_MEDIUM,
  DISPLAY_SMALL,
} from "../utils/CategoryUtils";
import { useTheme } from "@mui/material/styles";
import CategoryMediumComponent from "../components/common/category/view/CategoryMediumComponent";
import CategorySmallComponent from "../components/common/category/view/CategorySmallComponent";
import ContentComponent from "../components/content/ContentComponent";
import { getTitle } from "../helpers/SearchParamHelper";

const CategoryScreen: React.FC = React.memo(() => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [editCategory, setEditCategory] = React.useState<
    CategoryInterface | undefined
  >(undefined);
  const getDisplayCategory = React.useCallback((): string => {
    return localStorage.getItem(STORAGE_CATEGORY_DISPLAY) ?? DISPLAY_MEDIUM;
  }, []);
  const [displayCategory, setDisplayCategory] = React.useState<string>(
    getDisplayCategory()
  );
  const changeDisplayCategory = React.useCallback((display: string) => {
    setDisplayCategory(display);
    localStorage.setItem(STORAGE_CATEGORY_DISPLAY, display);
  }, []);
  const { t } = useTranslation();
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const categories = useAppSelector(
    (state: RootState) => state.globalState.categories
  );

  const load = React.useCallback(
    async (force: boolean = false) => {
      if (!force && categories) {
        dispatch(set({ refreshPage: false }));
      }
      setInit(true);
      if (categories === undefined && !force) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const response = await requestApi({
        method: GET,
        path: CATEGORY_URL + "?exists[parent]=false&enabled=true",
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        dispatch(set({ categories: response.content }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [categories, dispatch, t, token]
  );

  const handleClose = React.useCallback(() => {
    setEditCategory(undefined);
  }, []);

  const showFormCategory = React.useCallback(
    (category: CategoryInterface) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setEditCategory(category);
      },
    []
  );

  React.useEffect(() => {
    document.title = getTitle("catalog");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Container sx={{ marginY: 1 }} maxWidth="xl">
        {refreshPage ? (
          <SearchComponent nbColumn={1} nbLines={4} />
        ) : (
          <>
            <ContentComponent contentName="catalog" saveLocalStorage={true} />
            <Box sx={{ textAlign: "center" }}>
              <IconButton
                className={
                  displayCategory === DISPLAY_BIG ||
                  (isSmall && displayCategory === DISPLAY_MEDIUM)
                    ? "icon-button-hover"
                    : ""
                }
                color="inherit"
                sx={{
                  ...((displayCategory === DISPLAY_BIG ||
                    (isSmall && displayCategory === DISPLAY_MEDIUM)) && {
                    backgroundColor: theme.palette.primary.main,
                  }),
                }}
                onClick={() => changeDisplayCategory(DISPLAY_BIG)}
              >
                <ViewAgendaIcon
                  sx={{
                    ...((displayCategory === DISPLAY_BIG ||
                      (isSmall && displayCategory === DISPLAY_MEDIUM)) && {
                      color: theme.palette.primary.contrastText,
                    }),
                  }}
                />
              </IconButton>
              {!isSmall && (
                <IconButton
                  color="inherit"
                  className={
                    displayCategory === DISPLAY_MEDIUM
                      ? "icon-button-hover"
                      : ""
                  }
                  sx={{
                    ...(displayCategory === DISPLAY_MEDIUM && {
                      backgroundColor: theme.palette.primary.main,
                    }),
                  }}
                  onClick={() => changeDisplayCategory(DISPLAY_MEDIUM)}
                >
                  <CalendarViewWeekIcon
                    sx={{
                      ...(displayCategory === DISPLAY_MEDIUM && {
                        color: theme.palette.primary.contrastText,
                      }),
                    }}
                  />
                </IconButton>
              )}
              <IconButton
                color="inherit"
                className={
                  displayCategory === DISPLAY_SMALL ? "icon-button-hover" : ""
                }
                sx={{
                  ...(displayCategory === DISPLAY_SMALL && {
                    backgroundColor: theme.palette.primary.main,
                  }),
                }}
                onClick={() => changeDisplayCategory(DISPLAY_SMALL)}
              >
                <CalendarViewMonthIcon
                  sx={{
                    ...(displayCategory === DISPLAY_SMALL && {
                      color: theme.palette.primary.contrastText,
                    }),
                  }}
                />
              </IconButton>
            </Box>
            {displayCategory === DISPLAY_BIG && (
              <CategoryBigComponent showFormCategory={showFormCategory} />
            )}
            {displayCategory === DISPLAY_MEDIUM && (
              <CategoryMediumComponent showFormCategory={showFormCategory} />
            )}
            {displayCategory === DISPLAY_SMALL && (
              <CategorySmallComponent showFormCategory={showFormCategory} />
            )}
          </>
        )}
      </Container>
      <DialogFormCategoryComponent
        category={editCategory}
        setCategory={setEditCategory}
        openDialog={!!editCategory}
        handleClose={handleClose}
      />
    </Layout>
  );
});

export default CategoryScreen;
