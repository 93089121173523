import React, { forwardRef, useImperativeHandle } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

interface State {
  apply: Function;
  setNbSelected: Function;
  index: number;
  filterInited: Function;
}

const IcecatFilterComponent = React.memo(
  forwardRef(({ apply, setNbSelected, index, filterInited }: State, ref) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [searchParams] = useSearchParams();
    const searchParamsObject = searchParamToObject(searchParams);
    const [value, setValue] = React.useState(() => {
      if (searchParamsObject.hasOwnProperty("icecat[]")) {
        return searchParamsObject["icecat[]"];
      }
      return [];
    });
    const { t } = useTranslation();

    const handleChangeCheckbox = React.useCallback(
      (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((v: any) => {
          if (v.includes(prop)) {
            v = v.filter((y: string) => y !== prop);
          } else {
            v.push(prop);
          }
          return [...v];
        });
      },
      []
    );

    React.useEffect(() => {
      setNbSelected(value.length === 0 ? 0 : 1);
      if (!isSmall) {
        apply();
      }
      filterInited(index);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        if (value.length > 0) {
          return { "icecat[]": value };
        }
        return {};
      },
    }));

    return (
      <FormGroup sx={{ flexDirection: "row" }}>
        {["notExists", "asked", "never"].map((s) => (
          <FormControlLabel
            key={s}
            control={
              <Checkbox
                checked={value.includes(s)}
                onChange={handleChangeCheckbox(s)}
              />
            }
            label={t("word.icecat." + s)}
          />
        ))}
      </FormGroup>
    );
  })
);

export default IcecatFilterComponent;
