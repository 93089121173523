import React, { forwardRef, useImperativeHandle } from "react";
import {
  Autocomplete,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";

const clone = require("clone");

interface State {
  apply: Function;
  setNbSelected: Function;
  brands: string[] | null;
  index: number;
  filterInited: Function;
}

const BrandFilterComponent = React.memo(
  forwardRef(
    ({ apply, setNbSelected, brands, index, filterInited }: State, ref) => {
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const [searchParams] = useSearchParams();
      const searchParamsObject = searchParamToObject(searchParams);
      const getThisBrands = React.useCallback((): string[] | null => {
        const result: string[] | null = clone(brands);
        result?.sort();
        return result;
      }, [brands]);
      const [thisBrands, setThisBrands] = React.useState<string[] | null>(
        getThisBrands()
      );

      const isAutocomplete = React.useCallback(() => {
        return thisBrands && thisBrands.length > 1;
      }, [thisBrands]);
      const getValue = React.useCallback(() => {
        if (searchParamsObject.hasOwnProperty("brands[]")) {
          return searchParamsObject["brands[]"][0] ?? "";
        }
        if (isAutocomplete()) {
          return null;
        }
        return "";
      }, [isAutocomplete, searchParamsObject]);
      const [value, setValue] = React.useState<string | null>(getValue());

      const onClick = React.useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
          const newValue = (event.target as HTMLInputElement).value;
          if (value === newValue) {
            setValue("");
          } else {
            setValue(newValue);
          }
        },
        [value]
      );

      React.useEffect(() => {
        if (isAutocomplete()) {
          setNbSelected(value === null ? 0 : 1);
        } else {
          setNbSelected(value === "" ? 0 : 1);
        }
        if (!isSmall) {
          apply();
        }
        filterInited(index);
      }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValue(getValue());
      }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setThisBrands(getThisBrands());
      }, [brands]); // eslint-disable-line react-hooks/exhaustive-deps

      useImperativeHandle(ref, () => ({
        getValue() {
          if (value !== "" && value !== null) {
            return {
              "brands[]": [value],
            };
          }
          return {};
        },
      }));

      return (
        <>
          {thisBrands &&
            (isAutocomplete() ? (
              <Autocomplete
                fullWidth
                value={value}
                onChange={(event: any, newValue: string | null) => {
                  setValue(newValue);
                }}
                options={thisBrands}
                renderInput={(params) => <TextField {...params} />}
              />
            ) : (
              <FormControl>
                <RadioGroup
                  aria-labelledby={"brand-radio-buttons-group-label"}
                  name={"brand-radio-buttons-group"}
                  value={value}
                >
                  {thisBrands.map((brand, indexBrand) => (
                    <FormControlLabel
                      key={indexBrand}
                      value={brand}
                      control={<Radio onClick={onClick} sx={{ paddingY: 0 }} />}
                      label={brand}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ))}
        </>
      );
    }
  )
);

export default BrandFilterComponent;
