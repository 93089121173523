import * as React from "react";
import { useRef } from "react";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField } from "@mui/material";
import { InputInterface } from "../../../interfaces/InputInterface";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { FDOCENTETES_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import sage69Validator from "../../../helpers/validator/Sage69Validator";

interface State {
  fDocentete: FDocenteteInterface;
}

interface FormState {
  adresse7: InputInterface;
}

const FDocenteteRefComponent: React.FC<State> = React.memo(({ fDocentete }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const inputRef: any = useRef();
  const [openCopied, setOpenCopied] = React.useState<boolean>(false);
  const [initAdresse7, setInitAdresse7] = React.useState(
    fDocentete?.adresse7 ?? ""
  );
  const getDefaultValues = React.useCallback((): FormState => {
    return {
      adresse7: {
        value: initAdresse7,
        error: "",
      },
    };
  }, [initAdresse7]);
  const [values, setValues] = React.useState<FormState>(getDefaultValues());
  const token = useAppSelector((state: RootState) => state.globalState.token);

  const copy = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      navigator.clipboard.writeText(fDocentete?.adresse7 ?? "");
      setOpenCopied(true);
      setTimeout(() => {
        setOpenCopied(false);
      }, 500);
    },
    [fDocentete?.adresse7]
  );

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const handleEdit = React.useCallback(() => {
    setEdit((x) => {
      if (!x) {
        setTimeout(() => {
          inputRef.current.focus();
          inputRef.current.select();
        });
      }
      return !x;
    });
  }, []);

  const save = React.useCallback(async () => {
    setLoading(true);
    let newValue = values.adresse7.value.trim();
    if (!newValue) {
      newValue = null;
    } else if (newValue !== "") {
      const refError = sage69Validator(newValue);
      if (refError) {
        const newValue: FormState = { ...values };
        if (refError) {
          newValue.adresse7.error = refError;
        }
        setValues(newValue);
        setLoading(false);
        return;
      }
    }
    const response = await requestApi({
      method: PATCH,
      path: FDOCENTETES_URL + "/" + fDocentete.doPiece,
      allowError: false,
      token: token,
      body: {
        adresse7: newValue,
      },
    });
    if (response.statusCode === 200) {
      toastr.success(t("word.success"), t("sentence.notification.ref_updated"));
      setInitAdresse7(newValue ?? "");
      setTimeout(() => {
        setEdit(false);
      });
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [fDocentete.doPiece, t, token, values]);

  React.useEffect(() => {
    setValues(getDefaultValues());
  }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TextField
        fullWidth={true}
        disabled={!edit}
        inputRef={inputRef}
        autoComplete="off"
        error={!!values.adresse7.error}
        helperText={t(values.adresse7.error ?? "")}
        sx={{ width: "100%", maxWidth: "400px" }}
        value={values.adresse7.value}
        onChange={handleChange("adresse7")}
        label={t("word.yourRef")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!edit ? (
                <>
                  <Tooltip title={t("word.edit.word")}>
                    <IconButton onClick={handleEdit}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={handleEdit}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </>
              )}
              <Tooltip
                title={t("word.copied")}
                open={openCopied}
                sx={{ ...(edit && { display: "none" }) }}
              >
                <IconButton onClick={copy}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
});

export default FDocenteteRefComponent;
