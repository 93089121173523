import * as React from "react";
import Box from "@mui/material/Box";
import { Skeleton, useMediaQuery, useTheme } from "@mui/material";

interface State {
  nbColumn: number;
  nbLines: number;
  height?: number;
  width?: string | number;
}

const SearchComponent: React.FC<State> = React.memo(
  ({ nbColumn, nbLines, height, width }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    return (
      <>
        {Array.from(Array(isSmall ? nbColumn : nbLines)).map(
          (line, indexLine) => (
            <Box sx={{ display: "flex", marginBottom: 1 }} key={indexLine}>
              {Array.from(Array(isSmall ? 1 : nbColumn)).map(
                (column, indexColumn) => (
                  <Skeleton
                    key={indexColumn}
                    variant="rectangular"
                    height={height ?? 250}
                    width={width ?? "100%"}
                    sx={{ marginX: 1 }}
                  />
                )
              )}
            </Box>
          )
        )}
      </>
    );
  }
);

export default SearchComponent;
