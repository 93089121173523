import React, { Fragment, useEffect } from "react";
import HideImageIcon from "@mui/icons-material/HideImage";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { shuffleArray } from "../../helpers/GroupHelper";

interface State {
  data: any;
}

const CarouselDisplayComponent: React.FC<State> = React.memo(({ data }) => {
  const getImages = React.useCallback((): any[] => {
    if (!data?.images) {
      return data?.images;
    }
    return shuffleArray(data.images);
  }, [data?.images]);
  const [images, setImages] = React.useState<any[] | undefined>(getImages());

  useEffect(() => {
    setImages(getImages());
  }, [data?.images]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Slider
      initialSlide={0}
      dots={true}
      infinite={true}
      speed={100}
      autoplay={data?.autoSwap ?? false}
      autoplaySpeed={
        data?.autoSwap ? parseInt(String(data?.autoSwapTime * 1000)) : undefined
      }
      dotsClass="slick-dots slick-dots-image"
    >
      {images && images.length > 0 ? (
        images.map((image: any, indexImage: number) => {
          const imageComponent = (
            <img
              src={process.env.REACT_APP_API_URL + "/DATA" + image.file}
              alt={image.file}
              loading="lazy"
            />
          );
          let hasUrl = false;
          let internUrl = null;
          let thisUrl = image.link;
          if (image?.link) {
            try {
              const url = new URL(image?.link);
              hasUrl = true;
              if (window.location.hostname === url.hostname) {
                internUrl = true;
                thisUrl = url.pathname + url.search;
              }
            } catch (_) {
              // nothing
            }
          }
          return (
            <Fragment key={indexImage}>
              {hasUrl ? (
                <>
                  {internUrl ? (
                    <Link to={thisUrl}>{imageComponent}</Link>
                  ) : (
                    <a href={thisUrl} target="_blank" rel="noreferrer">
                      {imageComponent}
                    </a>
                  )}
                </>
              ) : (
                imageComponent
              )}
            </Fragment>
          );
        })
      ) : (
        <HideImageIcon
          sx={{
            width: "50%",
            height: "50%",
          }}
        />
      )}
    </Slider>
  );
});

export default CarouselDisplayComponent;
