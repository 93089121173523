import React from "react";
import { Container, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import Layout from "../components/common/Layout";
import { requestApi } from "../helpers/RequestApi";
import { GET, PATCH } from "../utils/MethodUtils";
import { LOST_PASSWORD_URL } from "../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTitle, searchParamToObject } from "../helpers/SearchParamHelper";
import { LostPasswordInterface } from "../interfaces/UserInterface";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import FormHelperText from "@mui/material/FormHelperText";
import { InputInterface } from "../interfaces/InputInterface";
import { LoadingButton } from "@mui/lab";
import sameValidator from "../helpers/validator/SameValidator";
import getErrorApi from "../helpers/GetErrorApi";
import { initAppHelper } from "../helpers/InitAppHelper";
import { HOME_PAGE } from "../utils/RouteUtils";
import sagCtNumValidator from "../helpers/validator/SagCtNumValidator";

interface FormState {
  newPassword: InputInterface;
  newPassword2: InputInterface;
  showPassword: boolean;
}

const ResetPasswordScreen: React.FC = React.memo(() => {
  const [values, setValues] = React.useState<FormState>({
    newPassword: { value: "", error: "" },
    newPassword2: { value: "", error: "" },
    showPassword: false,
  });
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [lostPassword, setLostPassword] = React.useState<
    LostPasswordInterface | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (lostPassword !== undefined && !force) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      const response = await requestApi({
        method: GET,
        path: LOST_PASSWORD_URL + "/" + searchParamsObject.code,
        allowError: true,
      });
      if (response.statusCode === 200) {
        setLostPassword(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [dispatch, lostPassword, searchParams, t]
  );

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          // @ts-ignore
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const handleClickShowPassword = React.useCallback(() => {
    setValues((v) => {
      return {
        ...v,
        showPassword: !values.showPassword,
      };
    });
  }, [values]);

  const handleMouseDownPassword = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  const submit = React.useCallback(async () => {
    setLoading(true);
    const sameError = sameValidator(
      values.newPassword.value,
      values.newPassword2.value
    );
    const newPasswordError = sagCtNumValidator(values.newPassword.value);
    if (sameError || newPasswordError) {
      const newValue: FormState = { ...values };
      if (sameError) {
        newValue.newPassword.error = sameError;
        newValue.newPassword2.error = sameError;
      } else if (newPasswordError) {
        newValue.newPassword.error = newPasswordError;
        newValue.newPassword2.error = newPasswordError;
      }
      setValues(newValue);
      setLoading(false);
      return;
    }
    const response = await requestApi({
      method: PATCH,
      path: LOST_PASSWORD_URL + "/" + lostPassword?.code,
      allowError: false,
      body: {
        newPassword: values.newPassword.value,
      },
    });
    if (response.statusCode === 200) {
      toastr.success(
        t("word.success"),
        t("sentence.notification.password_updated")
      );
      await initAppHelper(response.content.token);
      navigate(HOME_PAGE);
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [lostPassword?.code, navigate, t, values]);

  React.useEffect(() => {
    document.title = getTitle("resetPassword");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ margin: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined" required>
              <InputLabel>{t("field.newPassword")}</InputLabel>
              <OutlinedInput
                error={!!values.newPassword.error}
                type={values.showPassword ? "text" : "password"}
                value={values.newPassword.value}
                onChange={handleChange("newPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("field.action.toggle_password")}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("field.newPassword")}
              />
              {!!values.newPassword.error && (
                <FormHelperText error>
                  {t(values.newPassword.error ?? "")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined" required>
              <InputLabel>{t("field.newPassword2")}</InputLabel>
              <OutlinedInput
                error={!!values.newPassword2.error}
                type={values.showPassword ? "text" : "password"}
                value={values.newPassword2.value}
                onChange={handleChange("newPassword2")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("field.action.toggle_password")}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("field.newPassword2")}
              />
              {!!values.newPassword2.error && (
                <FormHelperText error>
                  {t(values.newPassword2.error ?? "")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              onClick={submit}
              loading={loading}
            >
              {t("word.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
});

export default ResetPasswordScreen;
