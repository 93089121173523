import * as React from "react";
import LoginComponent from "../../components/common/user/LoginComponent";
import { Container, Grid, TextField } from "@mui/material";
import Layout from "../../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import { objectToQuery, requestApi } from "../../helpers/RequestApi";
import { GET } from "../../utils/MethodUtils";
import { FAVORIS_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { getTitle, searchParamToObject } from "../../helpers/SearchParamHelper";
import { useTranslation } from "react-i18next";
import ContentComponent from "../../components/content/ContentComponent";
import SearchComponent from "../../components/common/SearchComponent";
import { FArticleSmallInterface } from "../../interfaces/FArticleInterface";
import {
  getInline,
  getNbGroup,
  getStyleInline,
} from "../../utils/FArticleUtils";
import { FArticleSmallComponent } from "../../components/common/fArticle/FArticleSmallComponent";
import Box from "@mui/material/Box";
import { FavorisInterface } from "../../interfaces/FavorisInterface";
import { useSearchParams } from "react-router-dom";

const itemsPerPage = 20;

const FavorisScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [init, setInit] = React.useState(false);
  const [totalItems, setTotalItems] = React.useState<number | undefined>(
    undefined
  );
  const [groupFArticles, setGroupFArticles] = React.useState<
    FArticleSmallInterface[][] | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const { t } = useTranslation();
  const [page, setPage] = React.useState<number>(1);
  const [nbGroup] = React.useState<number>(getNbGroup());
  const [styleInline] = React.useState<string>(getStyleInline());
  const [inline] = React.useState<boolean>(getInline() !== "0");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = React.useState<string>(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return searchParamsObject.userIdentifier ?? user?.userIdentifier ?? "";
  });
  const isAdmin = useAppSelector(
    (state: RootState) => state.globalState.isAdmin
  );

  const handleChangeText = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (groupFArticles !== undefined && !force) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      setLoading(true);
      const searchParamsObject = searchParamToObject(searchParams);
      const response = await requestApi({
        method: GET,
        path:
          FAVORIS_URL +
          objectToQuery({
            ...searchParamsObject,
            itemsPerPage: itemsPerPage,
            page: page,
          }),
        allowError: false,
        token: token,
      });
      setLoading(false);
      if (response.statusCode === 200) {
        setTotalItems(response.content.totalItems);
        setTimeout(() => {
          setGroupFArticles((x) => {
            if (x === undefined) {
              x = [];
            }
            let allFArticles: FArticleSmallInterface[] = [];
            for (const group of x) {
              allFArticles = [...allFArticles, ...group];
            }
            for (const fArticle of response.content.favoris
              .filter((f: FavorisInterface) => f.fArticle)
              .map((f: FavorisInterface) => f.fArticle)) {
              allFArticles.push(fArticle);
            }
            x = [];
            if (inline) {
              for (const fArticle of allFArticles) {
                x.push([fArticle]);
              }
            } else {
              const chunkSize = nbGroup;
              for (let i = 0; i < allFArticles.length; i += chunkSize) {
                x.push(allFArticles.slice(i, i + chunkSize));
                // do whatever
              }
            }
            return [...x];
          });
        });
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [groupFArticles, searchParams, page, token, dispatch, inline, nbGroup, t]
  );

  const onScrollProducts = React.useCallback(
    (e: any) => {
      if (!groupFArticles || !totalItems) {
        return;
      }
      const position = e.target?.getBoundingClientRect();
      if (!position) {
        return;
      }
      const isBottom =
        e.target.offsetHeight + e.target.scrollTop >=
        e.target.scrollHeight - 2000;
      if (isBottom) {
        let allFArticles: FArticleSmallInterface[] = [];
        for (const group of groupFArticles) {
          allFArticles = [...allFArticles, ...group];
        }
        if (allFArticles.length >= totalItems) {
          return;
        }
        const newPage = Math.ceil(allFArticles.length / itemsPerPage) + 1;
        if (newPage !== page) {
          setPage(newPage);
        }
      }
    },
    [groupFArticles, page, totalItems]
  );

  React.useEffect(() => {
    document.title = getTitle("favoris");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      setGroupFArticles(undefined);
      if (page === 1) {
        load(true);
      } else {
        setPage(1);
      }
    }
  }, [refreshPage, page]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && page > 1) {
      load(true);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const timeoutTyping = setTimeout(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      delete searchParamsObject.userIdentifier;
      if (search) {
        searchParamsObject.userIdentifier = search;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    }, 500);
    return () => clearTimeout(timeoutTyping);
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (refreshPage) {
      load(true);
    } else {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout sx={{ overflow: "hidden" }}>
      <LoginComponent redirect={null}>
        <Box
          id="scroll-container"
          onScroll={onScrollProducts}
          sx={{
            overflow: "auto",
            overflowX: "hidden",
            overflowY: "scroll", // avoid tremors
            maxHeight: "100%",
          }}
        >
          <ContentComponent contentName="favoris" saveLocalStorage={true} />
          <Container maxWidth="xl">
            {isAdmin && (
              <Grid container spacing={1} sx={{ marginBottom: 2 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type="text"
                    value={search}
                    onChange={handleChangeText}
                    sx={{ width: "100%" }}
                    required
                    label={t("column.userIdentifier")}
                  />
                </Grid>
              </Grid>
            )}
            {groupFArticles ? (
              <>
                {groupFArticles.map((groupFArticle, indexGroupFArticle) => (
                  <FArticleSmallComponent
                    key={indexGroupFArticle}
                    nbColumn={inline ? 1 : nbGroup}
                    inline={inline}
                    fArticles={groupFArticle}
                    defaultStyleInline={styleInline}
                  />
                ))}
                {loading && (
                  <SearchComponent
                    nbColumn={inline ? 1 : nbGroup ?? 4}
                    nbLines={1}
                    height={200}
                  />
                )}
              </>
            ) : (
              <SearchComponent
                nbColumn={inline ? 1 : nbGroup ?? 4}
                nbLines={1}
                height={200}
              />
            )}
          </Container>
        </Box>
      </LoginComponent>
    </Layout>
  );
});

export default FavorisScreen;
