const sage69Validator = (sageName: string | null, canBeEmpty = false) => {
  sageName = sageName?.trim() ?? "";
  if (!canBeEmpty && sageName.length === 0) {
    return "error.not_empty";
  }
  if (sageName.length > 69) {
    return "error.sageName.maxLength69";
  }
  return "";
};
export default sage69Validator;
