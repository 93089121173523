import React from "react";
import Layout from "../components/common/Layout";
import { getTitle } from "../helpers/SearchParamHelper";
import { Container } from "@mui/material";
import LoginComponent from "../components/common/user/LoginComponent";
import { useParams } from "react-router-dom";
import EnteringInvoiceFDocenteteComponent from "../components/common/edi/EnteringInvoiceFDocenteteComponent";

const EnteringInvoiceScreen: React.FC = React.memo(() => {
  const params = useParams();

  React.useEffect(() => {
    document.title = getTitle("enteringInvoices");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ marginY: 2 }}>
        <LoginComponent redirect={null}>
          <EnteringInvoiceFDocenteteComponent doPiece={params.id} />
        </LoginComponent>
      </Container>
    </Layout>
  );
});

export default EnteringInvoiceScreen;
