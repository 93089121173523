import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";
import FormNameCategoryComponent from "./form/FormNameCategoryComponent";
import FormParentCategoryComponent from "./form/FormParentCategoryComponent";
import { Grid } from "@mui/material";
import FormImageCategoryComponent from "./form/FormImageCategoryComponent";
import { findCategoryParent } from "../../../helpers/CategoryFormat";
import FormFilterCategoryComponent from "./form/FormFilterCategoryComponent";
import FormEnabledCategoryComponent from "./form/FormEnabledCategoryComponent";
import ContentComponent from "../../content/ContentComponent";
import FormKeywordComponent from "../filter/admin/form/filterForm/FormKeywordComponent";

interface State {
  category: CategoryInterface | undefined;
}

const FormCategoryComponent = React.memo(
  forwardRef(({ category }: State, ref) => {
    const imageRef: any = useRef();
    const nameRef: any = useRef();
    const enabledRef: any = useRef();
    const parentRef: any = useRef();
    const keywordsRef: any = useRef();
    const filterCategoriesRef: any = useRef();
    const [parent, setParent] = React.useState<CategoryInterface | null>(
      findCategoryParent(category)
    );
    const refContent: any = useRef();

    useImperativeHandle(ref, () => ({
      async getValue() {
        let content = null;
        const newContentValue = await refContent.current?.getValue();
        if (newContentValue) {
          content = newContentValue;
        }
        return {
          image: await imageRef.current.getValue(),
          name: nameRef.current.getValue(),
          enabled: enabledRef.current.getValue(),
          parent: parentRef.current.getValue(),
          keywords: keywordsRef.current.getValue().keywords,
          filterCategories: filterCategoriesRef.current.getValue(),
          content: content,
        };
      },
    }));

    React.useEffect(() => {
      setParent(findCategoryParent(category));
    }, [category]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormImageCategoryComponent category={category} ref={imageRef} />
        </Grid>
        <Grid item xs={12}>
          <FormNameCategoryComponent category={category} ref={nameRef} />
        </Grid>
        <Grid item xs={12}>
          <FormEnabledCategoryComponent category={category} ref={enabledRef} />
        </Grid>
        <Grid item xs={12}>
          <FormParentCategoryComponent
            parent={parent}
            category={category}
            ref={parentRef}
          />
        </Grid>
        <Grid item xs={12}>
          <FormKeywordComponent
            initKeywords={category?.keywords}
            ref={keywordsRef}
          />
        </Grid>
        {parent?.parent === null && (
          <Grid item xs={12}>
            <ContentComponent
              initContent={category?.content}
              preventChangeUrlTab={true}
              showEdit={false}
              edit={true}
              ref={refContent}
              showSaveCancel={false}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormFilterCategoryComponent
            category={category}
            ref={filterCategoriesRef}
          />
        </Grid>
      </Grid>
    );
  })
);

export default FormCategoryComponent;
