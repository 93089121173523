import React from "react";
import { DialogProps, Tooltip, useTheme } from "@mui/material";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import useMediaQuery from "@mui/material/useMediaQuery";
import EditNameFormComponent from "./EditNameFormComponent";
import EditPhoneFormComponent from "./EditPhoneFormComponent";
import ValidateMailComponent from "./ValidateMailComponent";
import EditMailFormComponent from "./EditMailFormComponent";
import EditPasswordFormComponent from "./EditPasswordFormComponent";
import FReglementtComponent from "./FReglementtComponent";
import { isUserEnCompte } from "../../helpers/UserHelper";
import Dialog from "@mui/material/Dialog";

interface FieldInterface {
  type: string;
  content: React.ReactNode;
  value: string | React.ReactNode;
  component: React.ReactNode;
  editable: boolean;
}

interface State {
  showMailValidated: boolean;
}

const CardAccountComponent: React.FC<State> = React.memo(
  ({ showMailValidated }) => {
    const theme = useTheme();
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const { t } = useTranslation();
    const [formComponent, setFormComponent] = React.useState<
      React.ReactNode | undefined
    >(undefined);
    const [openDialogForm, setOpenDialogForm] = React.useState(false);
    const mailConfirmation = useAppSelector(
      (state: RootState) => state.globalState.mailConfirmation
    );

    const handleClose = React.useCallback(() => {
      setFormComponent(undefined);
      setOpenDialogForm(false);
    }, []);

    const getFields = React.useCallback((): FieldInterface[] => {
      return [
        {
          type: "name",
          content: <Typography>{t("word.name")}:</Typography>,
          value: user?.ctIntitule ?? "",
          component: <EditNameFormComponent close={handleClose} />,
          editable: true,
        },
        {
          type: "phoneNumber",
          content: <Typography>{t("word.phoneNumber")}:</Typography>,
          value: user?.ctTelephone ?? "",
          component: <EditPhoneFormComponent close={handleClose} />,
          editable: true,
        },
        {
          type: "email",
          content: (
            <>
              <Typography>{t("word.email")}:</Typography>
              {!showMailValidated && <ValidateMailComponent />}
            </>
          ),
          value: user?.ctEmail ?? "",
          component: (
            <EditMailFormComponent close={handleClose} field="ctEmail" />
          ),
          editable: true,
        },
        {
          type: "email",
          content: (
            <>
              <Typography>{t("field.ctEmailCompta")}:</Typography>
            </>
          ),
          value: user?.emailCompta ?? "",
          component: (
            <EditMailFormComponent close={handleClose} field="emailCompta" />
          ),
          editable: true,
        },
        {
          type: "password",
          content: <Typography>{t("word.password")}:</Typography>,
          value: "********",
          component: <EditPasswordFormComponent close={handleClose} />,
          editable: true,
        },
        {
          type: "fReglementts",
          content: <Typography>{t("word.fReglementts")}:</Typography>,
          value: <FReglementtComponent user={user} />,
          component: undefined,
          editable: false,
        },
      ];
    }, [handleClose, showMailValidated, t, user]);

    const [fields, setFields] = React.useState<FieldInterface[]>(getFields());

    const handleOpen = React.useCallback(
      (index: number) => {
        setFormComponent(fields[index].component);
        setOpenDialogForm(true);
      },
      [fields]
    );

    React.useEffect(() => {
      setFields(getFields());
    }, [user, mailConfirmation, showMailValidated]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleClose}
          open={openDialogForm}
        >
          {formComponent}
        </Dialog>
        <Card variant="outlined">
          <CardContent>
            <List>
              {fields.map((field, indexField) => (
                <Box key={indexField}>
                  <Box
                    sx={{
                      paddingY: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      {field.content}
                      {typeof field.value === "string" ? (
                        <Typography
                          variant="h6"
                          component="p"
                          sx={{
                            wordBreak: "break-word",
                            color: theme.palette.primary.main,
                          }}
                        >
                          {field.value}
                        </Typography>
                      ) : (
                        field.value
                      )}
                    </Box>
                    <Box>
                      <>
                        {field.editable &&
                          (field.type !== "password" && isUserEnCompte(user) ? (
                            // todo rajouter cette validation en back
                            <Tooltip title={t("sentence.sendMailToModify")}>
                              <IconButton size="large">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <IconButton onClick={() => handleOpen(indexField)}>
                              <EditIcon />
                            </IconButton>
                          ))}
                      </>
                    </Box>
                  </Box>
                  {indexField !== fields.length - 1 && <Divider />}
                </Box>
              ))}
            </List>
          </CardContent>
        </Card>
      </>
    );
  }
);

export default CardAccountComponent;
