const siretValidator = (siret: string | null) => {
  const re = /\d{14}/;
  siret = siret?.trim() ?? "";
  if (siret.length === 0) {
    return "error.not_empty";
  }
  if (siret.length !== 14) {
    return "error.field.siret";
  }
  if (!re.test(siret)) {
    return "error.siret";
  }
  return "";
};
export default siretValidator;
