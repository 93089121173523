import React, { forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import ColumnComponent from "./ColumnComponent";
import { useTranslation } from "react-i18next";
import { CONTAINER, CONTAINER_FLUID } from "../../utils/ContentUtils";
import { Container } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MenuIcon from "@mui/icons-material/Menu";
import { DraggableProvided } from "react-beautiful-dnd";
import { FArticleSmallInterface } from "../../interfaces/FArticleInterface";

interface State {
  columns?: any[];
  index: number;
  removeRow: Function;
  data: any;
  provided: DraggableProvided;
  fArticle: FArticleSmallInterface | undefined;
  availableModules?: string[];
}

interface FormState {
  container: string;
}

const RowComponent = React.memo(
  forwardRef(
    (
      {
        columns,
        index,
        removeRow,
        data,
        provided,
        fArticle,
        availableModules,
      }: State,
      ref
    ) => {
      const [values, setValues] = React.useState<FormState>({
        container: CONTAINER,
      });
      const [columnsRef, setColumnsRef] = React.useState<any[]>([]);
      const { t } = useTranslation();

      const addColumn = React.useCallback(() => {
        setColumnsRef((x) => [...x, React.createRef()]);
      }, []);

      const removeColumn = React.useCallback((index: number) => {
        setColumnsRef((x) => {
          x[index] = null;
          return [...x];
        });
      }, []);

      const changeContainer = React.useCallback(
        (container: string) => {
          setValues((values: FormState) => {
            values.container = container;
            return { ...values };
          });
        },
        [setValues]
      );

      const thisRemoveRow = React.useCallback(() => {
        removeRow(index);
      }, [index, removeRow]);

      const initColumns = React.useCallback(() => {
        if (!data) {
          addColumn();
          return;
        }
        setColumnsRef((x) => data.columns.map(() => React.createRef()));
        setValues({ ...data, columns: null });
      }, [addColumn, data]);

      useImperativeHandle(ref, () => ({
        async getValue() {
          const value: any[] = [];
          for (const columnRef of columnsRef) {
            if (columnRef) {
              value.push(await columnRef.current.getValue());
            }
          }
          if (value.findIndex((x) => x === undefined) >= 0) {
            return undefined;
          }
          return {
            ...values,
            columns: value,
          };
        },
      }));

      React.useEffect(() => {
        initColumns();
      }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <Container
          sx={{
            marginTop: 1,
            ...(values.container === CONTAINER_FLUID && {
              maxWidth: "100%!important",
            }),
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flex: 1 }}>
              <Box>
                <LoadingButton
                  variant={
                    values.container === CONTAINER ? "contained" : "outlined"
                  }
                  onClick={() => changeContainer(CONTAINER)}
                >
                  {t("word.container")}
                </LoadingButton>
              </Box>
              <Box sx={{ marginLeft: 1 }}>
                <LoadingButton
                  variant={
                    values.container === CONTAINER_FLUID
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => changeContainer(CONTAINER_FLUID)}
                >
                  {t("word.containerFluid")}
                </LoadingButton>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{ display: "flex", cursor: "move", marginRight: 1 }}
                {...provided.dragHandleProps}
              >
                <MenuIcon />
              </span>
              <LoadingButton
                variant="outlined"
                onClick={thisRemoveRow}
                sx={{ marginLeft: 1 }}
              >
                <DeleteOutlineIcon />
              </LoadingButton>
            </Box>
            <Box sx={{ flex: 1, textAlign: "right" }}>
              <LoadingButton variant="contained" onClick={addColumn}>
                {t("action.add.column")}
              </LoadingButton>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid black",
              display: "flex",
              justifyContent: "center",
              paddingX: 1,
            }}
          >
            {columnsRef.map(
              (columnRef, indexColumnRef) =>
                columnRef !== null && (
                  <ColumnComponent
                    availableModules={availableModules}
                    data={data?.columns[indexColumnRef] ?? null}
                    ref={columnsRef[indexColumnRef]}
                    key={indexColumnRef}
                    index={indexColumnRef}
                    removeColumn={removeColumn}
                    fArticle={fArticle}
                  />
                )
            )}
          </Box>
        </Container>
      );
    }
  )
);

export default RowComponent;
