import React from "react";
import { useTranslation } from "react-i18next";
import { FilterValueAssoInterface } from "../../../../../../interfaces/FilterInterface";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../../../../helpers/RequestApi";
import { DELETE } from "../../../../../../utils/MethodUtils";
import { FILTER_VALUE_ASSO_URL } from "../../../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../../../../app/hooks";
import { RootState } from "../../../../../../app/store";

interface State {
  filterValueAsso: FilterValueAssoInterface | undefined;
  close: any;
  successFunction: any;
}

const FilterValueAssoDeleteComponent: React.FC<State> = React.memo(
  ({ filterValueAsso, close, successFunction }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const save = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: DELETE,
        path:
          FILTER_VALUE_ASSO_URL +
          (filterValueAsso ? "/" + filterValueAsso.id : ""),
        allowError: false,
        token: token,
      });
      if (response.statusCode === 204) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.filter_value_asso_deleted")
        );
        successFunction();
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [filterValueAsso, successFunction, t, token]);

    return (
      <>
        {filterValueAsso && (
          <>
            <Typography>
              {t("word.deleteFilterValueAsso.question").replace(
                "%name%",
                filterValueAsso.name
              )}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <LoadingButton disabled={loading} onClick={close}>
                {t("word.cancel")}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={save}
                color="error"
              >
                {t("word.delete")}
              </LoadingButton>
            </Box>
          </>
        )}
      </>
    );
  }
);

export default FilterValueAssoDeleteComponent;
