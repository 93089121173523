import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { priceFormat } from "../../../../utils/FormatUtils";
import { DialogProps, Grid } from "@mui/material";
import FDocenteteSelectAvoirComponent from "../FDocenteteSelectAvoirComponent";
import { ArrayPostOrderInterface } from "../../../../interfaces/OrderInterface";
import { SAGE_DO_TYPE_VENTE_BC } from "../../../../utils/DoceneteteUtils";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { isUserEnCompte } from "../../../../helpers/UserHelper";

interface Props {
  handleNext: any;
  handleBack: any;
  setArrayPostOrder: Function;
  arrayPostOrder: ArrayPostOrderInterface;
  acompteOrder: number;
  totalOrder: number;
  setAcompteToPayGlobal: Function;
}

const StepperAcompteOrderComponent = React.memo(
  (
    {
      handleNext,
      handleBack,
      setArrayPostOrder,
      arrayPostOrder,
      acompteOrder,
      totalOrder,
      setAcompteToPayGlobal,
    }: Props,
    ref
  ) => {
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const { t, i18n } = useTranslation();
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const accountingSituation = useAppSelector(
      (state: RootState) => state.globalState.accountingSituation
    );
    const [openDialog, setOpenDialog] = React.useState(false);
    const getMaxAvoir = React.useCallback(() => {
      if (!accountingSituation) {
        return 0;
      }
      return Math.min(accountingSituation.sumCreditFdocentetes, acompteOrder);
    }, [accountingSituation, acompteOrder]);
    const [maxAvoir, setMaxAvoir] = React.useState(getMaxAvoir());
    const [acompte, setAcompte] = React.useState(getMaxAvoir());
    const [acompteToPay, setAcompteToPay] = React.useState(0);
    const [value, setValue] = React.useState<number>(maxAvoir);
    const getCanUseAvoir = React.useCallback(() => {
      return (
        accountingSituation !== undefined &&
        accountingSituation.sumCreditFdocentetes > 0
      );
    }, [accountingSituation]);
    const [canUseAvoir, setCanUseAvoir] = React.useState(getCanUseAvoir());
    const [checked, setChecked] = React.useState(false);
    const selectAvoirRef: any = useRef();
    const selectAmountRef: any = useRef();

    const handleCheck = React.useCallback(() => {
      setChecked((x) => !x);
    }, []);

    const handleDialog = React.useCallback(() => {
      setOpenDialog((x) => !x);
      setAcompteToPay(0);
    }, []);

    const thisHandleNext = React.useCallback(() => {
      setArrayPostOrder((x: ArrayPostOrderInterface) => {
        const bc = x.postOrders.find((y) => y.doType === SAGE_DO_TYPE_VENTE_BC);
        if (!bc) {
          return { ...x };
        }
        if (!checked) {
          bc.avoir = 0;
        } else {
          bc.avoir = value;
        }
        return { ...x };
      });
      if (acompte > 0) {
        handleDialog();
      } else {
        handleNext();
      }
    }, [acompte, checked, handleDialog, handleNext, setArrayPostOrder, value]);

    React.useEffect(() => {
      setCanUseAvoir(getCanUseAvoir());
    }, [accountingSituation?.sumCreditFdocentetes]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setMaxAvoir(getMaxAvoir());
    }, [accountingSituation?.sumCreditFdocentetes, acompteOrder]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      let thisAcompte = acompteOrder;
      if (checked) {
        thisAcompte = acompteOrder - value;
      }
      setAcompte(thisAcompte);
    }, [acompteOrder, checked, value]);

    React.useEffect(() => {
      if (checked && maxAvoir === value) {
        setAcompteToPayGlobal(0);
      } else {
        setAcompteToPayGlobal(acompteToPay);
      }
    }, [acompteToPay, maxAvoir, checked, value]); // eslint-disable-line react-hooks/exhaustive-deps

    let hasLicenceSurCommande =
      arrayPostOrder !== undefined &&
      arrayPostOrder.postOrders?.find((x) => {
        return (
          x.doType === SAGE_DO_TYPE_VENTE_BC &&
          x.orderFArticles.find(
            (y) =>
              y.fArticle.faCodefamille === "LO" &&
              Number(y.fArticle.stockManuel) === 3
          ) !== undefined
        );
      }) !== undefined;

    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              {isUserEnCompte(user)
                ? t("order.step.acompte.descriptionEnCompte")
                : t("order.step.acompte.description")}
            </Typography>
            {hasLicenceSurCommande && (
              <Typography sx={{ marginTop: 1 }}>
                {t("order.step.acompte.licence")}
              </Typography>
            )}
          </Grid>
          <Dialog
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            fullWidth={true}
            onClose={handleDialog}
            open={openDialog}
          >
            <DialogContent
              sx={{
                textAlign: "center",
              }}
            >
              {t("sentence.goingToPayAcompte").replace(
                "%amount%",
                priceFormat(acompte, i18n.language, "EUR")
              )}
              <FDocenteteSelectAvoirComponent
                minAvoir={acompte}
                defaultAvoir={acompte}
                maxAvoir={acompte + totalOrder - acompteOrder}
                ref={selectAmountRef}
                setParentValue={setAcompteToPay}
              />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <LoadingButton onClick={handleDialog}>
                {t("word.cancel")}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  setOpenDialog((x) => !x);
                  handleNext();
                }}
              >
                {t("word.payAcompte") +
                  " " +
                  priceFormat(acompteToPay, i18n.language, "EUR")}
              </LoadingButton>
            </DialogActions>
          </Dialog>
          {canUseAvoir && (
            <Grid item xs={12}>
              <Typography>
                {t("sentence.accountAvoir").replace(
                  "%amount%",
                  priceFormat(
                    accountingSituation!.sumCreditFdocentetes,
                    i18n.language,
                    "EUR"
                  )
                )}
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleCheck} />}
                label={t("sentence.useMyAvoir")}
              />
              {checked && (
                <>
                  <Typography>{t("sentence.selectAmountAvoir")}</Typography>
                  <FDocenteteSelectAvoirComponent
                    defaultAvoir={maxAvoir}
                    maxAvoir={Math.min(
                      accountingSituation?.sumCreditFdocentetes ?? 0,
                      totalOrder
                    )}
                    setParentValue={setValue}
                    ref={selectAvoirRef}
                    maxWidth={500}
                  />
                  <Typography>
                    {t("sentence.newAmountAccount").replace(
                      "%amount%",
                      priceFormat(
                        accountingSituation!.sumCreditFdocentetes - value,
                        i18n.language,
                        "EUR"
                      )
                    )}
                  </Typography>
                  {acompteOrder - value > 0 && (
                    <Typography>
                      {priceFormat(acompteOrder - value, i18n.language, "EUR")}
                      {" " + t("word.acompteRequired")}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          )}
        </Grid>
        <Box>
          <Button
            variant="contained"
            onClick={thisHandleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("word.next")}
          </Button>
          <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            {t("word.back")}
          </Button>
        </Box>
      </>
    );
  }
);
export default StepperAcompteOrderComponent;
