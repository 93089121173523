import * as React from "react";
import { useTranslation } from "react-i18next";
import { FilterSearchIcecatComponent } from "./IcecatListProductsComponent";
import { Grid, InputAdornment, TextField, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { GET_ICECAT_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { FilterSearchFournisseurComponent } from "./FilterSearchFournisseurComponent";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "@mui/icons-material/Info";

interface State {
  setTemporaryIcecat: Function;
  setFournisseurProduct: Function;
}

export const ListProductsIcecatFournisseurComponent: React.FC<State> =
  React.memo(({ setTemporaryIcecat, setFournisseurProduct }) => {
    const { t } = useTranslation();
    const [search, setSearch] = React.useState<string>("");
    const [loadingIcecat, setLoadingIcecat] = React.useState<boolean>(false);
    const [searchRefFournisseur, setSearchRefFournisseur] =
      React.useState<boolean>(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const handleChangeText = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
      },
      []
    );

    const updateIcecat = React.useCallback(
      async (icecatId: number | null = null) => {
        setLoadingIcecat(true);
        const response = await requestApi({
          method: GET,
          path: GET_ICECAT_URL + "/" + icecatId,
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          setTemporaryIcecat(response.content?.data);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoadingIcecat(false);
      },
      [setTemporaryIcecat, t, token]
    );

    const clearSearch = React.useCallback(() => {
      setSearch("");
    }, []);

    return (
      <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            autoComplete="off"
            sx={{ width: "100%" }}
            type="text"
            value={search}
            onChange={handleChangeText}
            label={t("word.search")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Checkbox
            checked={searchRefFournisseur}
            onChange={() => {
              setSearchRefFournisseur((x) => !x);
            }}
          />
          <Tooltip title={t("sentence.searchWithRefFournisseur")}>
            <InfoIcon />
          </Tooltip>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            {loadingIcecat && (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Box>
            )}
            <FilterSearchIcecatComponent
              initSearch={search}
              updateIcecat={updateIcecat}
              currentIcecatId={undefined}
              hideSearch={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FilterSearchFournisseurComponent
              initSearch={search}
              setFournisseurProduct={setFournisseurProduct}
              refFournisseur={searchRefFournisseur}
            />
          </Grid>
        </Grid>
      </>
    );
  });
