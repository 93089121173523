const sameValidator = (password1: string | null, password2: string | null) => {
  if (!password1 && !password2) {
    return "error.not_empty";
  }
  if (password1 !== password2) {
    return "error.same";
  }
  return "";
};
export default sameValidator;
