import React, { useImperativeHandle, useRef } from "react";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import {
  FormHelperText,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { SiretResponseInterface } from "../../../interfaces/SiretInterface";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ADMINISTRATION } from "../../../utils/RegisterUtils";
import sameValidator from "../../../helpers/validator/SameValidator";
import mailValidator from "../../../helpers/validator/MailValidator";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  COUNTRY_CODE_MAP,
  GUADELOUPE,
  GUYANE,
  MARTINIQUE,
} from "../../../utils/UserUtils";
import sageNameValidator from "../../../helpers/validator/SageNameValidator";
import sagCtNumValidator from "../../../helpers/validator/SagCtNumValidator";
import sagePostalValidator from "../../../helpers/validator/SagePostalValidator";
import sage69Validator from "../../../helpers/validator/Sage69Validator";
import PhoneInputComponent from "../user/PhoneInputComponent";

interface FormState2 {
  ctCompany: InputInterface;
  ctAdresse: InputInterface;
  ctComplement: InputInterface;
  ctCodepostal: InputInterface;
  ctVille: InputInterface;
  ctPays: InputInterface;
}

interface FormState extends FormState2 {
  ctContact: InputInterface;
  ctEmail: InputInterface;
  ctEmailCompta: InputInterface;
  motDePasse: InputInterface;
  confirmMotDePasse: InputInterface;
  showPassword: boolean;
}

interface State {
  initSiret: SiretResponseInterface | null;
  type: string;
}

const RegisterFormComponent = React.memo(
  React.forwardRef(({ initSiret, type }: State, ref) => {
    const { t } = useTranslation();
    const refCtTelephone: any = useRef();
    const fPays = useAppSelector((state: RootState) => state.globalState.fPays);
    const getPays = React.useCallback((postalCode: string | null) => {
      if (!postalCode) {
        return "";
      }
      if (postalCode.startsWith("971")) {
        return COUNTRY_CODE_MAP[GUADELOUPE];
      }
      if (postalCode.startsWith("972")) {
        return COUNTRY_CODE_MAP[MARTINIQUE];
      }
      if (postalCode.startsWith("973")) {
        return COUNTRY_CODE_MAP[GUYANE];
      }
    }, []);
    const getSiretValues = React.useCallback((): FormState2 => {
      return {
        ctCompany: {
          value:
            initSiret?.etablissement.uniteLegale.denominationUniteLegale ?? "",
          error: "",
        },
        ctAdresse: {
          value: initSiret
            ? (
                (initSiret?.etablissement.adresseEtablissement
                  .numeroVoieEtablissement ?? " ") +
                " " +
                (initSiret?.etablissement.adresseEtablissement
                  .typeVoieEtablissement ?? " ") +
                " " +
                (initSiret?.etablissement.adresseEtablissement
                  .libelleVoieEtablissement ?? " ")
              )
                .trim()
                .replace("  ", "")
            : "",
          error: "",
        },
        ctComplement: {
          value: initSiret
            ? (
                initSiret?.etablissement.adresseEtablissement
                  .complementAdresseEtablissement ?? " "
              )
                .trim()
                .replace("  ", "")
            : "",
          error: "",
        },
        ctCodepostal: {
          value:
            initSiret?.etablissement.adresseEtablissement
              .codePostalEtablissement ?? "",
          error: "",
        },
        ctVille: {
          value:
            initSiret?.etablissement.adresseEtablissement
              .libelleCommuneEtablissement ?? "",
          error: "",
        },
        ctPays: {
          value: getPays(
            initSiret?.etablissement.adresseEtablissement
              .codePostalEtablissement ?? null
          ),
          error: "",
        },
      };
    }, [getPays, initSiret]);
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        ctContact: { value: "", error: "" },
        ctEmail: { value: "", error: "" },
        ctEmailCompta: { value: "", error: "" },
        motDePasse: { value: "", error: "" },
        confirmMotDePasse: { value: "", error: "" },
        showPassword: false,
        ...getSiretValues(),
      };
    }, [getSiretValues]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const getResetValues = React.useCallback((): FormState => {
      return {
        ...values,
        ...getSiretValues(),
      };
    }, [getSiretValues, values]);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              // @ts-ignore
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      []
    );

    const handleClickShowPassword = React.useCallback(() => {
      setValues((v) => {
        return {
          ...v,
          showPassword: !values.showPassword,
        };
      });
    }, [values]);

    const handleMouseDownPassword = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      },
      []
    );

    const getValue = React.useCallback(() => {
      const ctAdresseError = sageNameValidator(values.ctAdresse.value);
      const ctComplementError = sageNameValidator(
        values.ctComplement.value,
        true
      );
      const ctCodepostalError = sagePostalValidator(values.ctCodepostal.value);
      const ctVilleError = sageNameValidator(values.ctVille.value);
      const ctPaysError = sageNameValidator(values.ctPays.value);
      const ctContactError = sageNameValidator(values.ctContact.value);
      const ctEmailError = mailValidator((values.ctEmail.value ?? "").trim());
      const motDePasseError = sagCtNumValidator(values.motDePasse.value);
      const ctCompanyError = sage69Validator(
        values.ctCompany.value,
        !initSiret
      );
      let ctEmailComptaError = "";
      if (type === ADMINISTRATION) {
        ctEmailComptaError = mailValidator(values.ctEmailCompta.value);
      }
      const ctTelephoneValue = refCtTelephone.current.getValue();
      const samePasswordError = sameValidator(
        values.motDePasse.value,
        values.confirmMotDePasse.value
      );
      if (
        ctCompanyError ||
        ctAdresseError ||
        (ctComplementError && values.ctComplement.value.trim() !== "") ||
        ctCodepostalError ||
        ctVilleError ||
        ctPaysError ||
        ctContactError ||
        ctEmailError ||
        ctEmailComptaError ||
        ctTelephoneValue === undefined ||
        samePasswordError ||
        motDePasseError
      ) {
        const newValue: FormState = { ...values };
        if (ctCompanyError) {
          newValue.ctCompany.error = ctCompanyError;
        }
        if (ctAdresseError) {
          newValue.ctAdresse.error = ctAdresseError;
        }
        if (ctComplementError) {
          newValue.ctComplement.error = ctComplementError;
        }
        if (ctCodepostalError) {
          newValue.ctCodepostal.error = ctCodepostalError;
        }
        if (ctVilleError) {
          newValue.ctVille.error = ctVilleError;
        }
        if (ctPaysError) {
          newValue.ctPays.error = ctPaysError;
        }
        if (ctContactError) {
          newValue.ctContact.error = ctContactError;
        }
        if (ctEmailError) {
          newValue.ctEmail.error = ctEmailError;
        }
        if (motDePasseError) {
          newValue.motDePasse.error = motDePasseError;
        }
        if (ctEmailComptaError) {
          newValue.ctEmailCompta.error = ctEmailComptaError;
        }
        if (samePasswordError) {
          newValue.motDePasse.error = samePasswordError;
          newValue.confirmMotDePasse.error = samePasswordError;
        }
        setValues(newValue);
        return undefined;
      }
      return {
        ctContact: values.ctContact.value,
        ctCompany: !!values.ctCompany.value ? values.ctCompany.value : null,
        ctAdresse: values.ctAdresse.value,
        ctComplement: !!values.ctComplement.value
          ? values.ctComplement.value
          : null,
        ctCodepostal: values.ctCodepostal.value,
        ctVille: values.ctVille.value,
        ctPays: values.ctPays.value,
        ctEmail: values.ctEmail.value.trim(),
        ...(values.ctEmailCompta.value && {
          emailCompta: values.ctEmailCompta.value,
        }),
        ctTelephone: ctTelephoneValue,
        motDePasse: values.motDePasse.value,
      };
    }, [initSiret, type, values]);

    React.useEffect(() => {
      setValues(getResetValues());
    }, [initSiret]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    return (
      <>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            error={!!values.ctAdresse.error}
            helperText={t(values.ctAdresse.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="text"
            value={values.ctAdresse.value}
            onChange={handleChange("ctAdresse")}
            label={t("field.ctAdresse")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            error={!!values.ctComplement.error}
            helperText={t(values.ctComplement.error ?? "")}
            sx={{ width: "100%" }}
            type="text"
            value={values.ctComplement.value}
            onChange={handleChange("ctComplement")}
            label={t("field.ctComplement")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            error={!!values.ctCompany.error}
            helperText={t(values.ctCompany.error ?? "")}
            sx={{ width: "100%" }}
            required={!!initSiret}
            type="text"
            value={values.ctCompany.value}
            onChange={handleChange("ctCompany")}
            label={t("field.ctCompany")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            error={!!values.ctCodepostal.error}
            helperText={t(values.ctCodepostal.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="text"
            value={values.ctCodepostal.value}
            onChange={handleChange("ctCodepostal")}
            label={t("field.ctCodepostal")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            error={!!values.ctVille.error}
            helperText={t(values.ctVille.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="text"
            value={values.ctVille.value}
            onChange={handleChange("ctVille")}
            label={t("field.ctVille")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required error={!!values.ctPays.error}>
            <InputLabel id="ctPays-label">{t("field.ctPays")}</InputLabel>
            <Select
              labelId="ctPays-label"
              value={values.ctPays.value}
              label={t("field.ctPays")}
              onChange={handleChangeSelect("ctPays")}
            >
              {fPays?.map((country, indexCountry) => (
                <MenuItem value={country.paCode} key={indexCountry}>
                  {country.paIntitule}
                </MenuItem>
              ))}
            </Select>
            {!!values.ctPays.error && (
              <FormHelperText error>
                {t(values.ctPays.error ?? "")}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            error={!!values.ctContact.error}
            helperText={t(values.ctContact.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="text"
            value={values.ctContact.value}
            onChange={handleChange("ctContact")}
            label={t("field.ctContact")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth={true}
            autoComplete="off"
            error={!!values.ctEmail.error}
            helperText={t(values.ctEmail.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="text"
            value={values.ctEmail.value}
            onChange={handleChange("ctEmail")}
            label={t("field.ctEmail")}
          />
        </Grid>
        {type === ADMINISTRATION && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.ctEmailCompta.error}
              helperText={t(values.ctEmailCompta.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.ctEmailCompta.value}
              onChange={handleChange("ctEmailCompta")}
              label={t("field.ctEmailCompta")}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <PhoneInputComponent ref={refCtTelephone} initCtTelephone={""} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ width: "100%" }} variant="outlined" required>
            <InputLabel>{t("field.password")}</InputLabel>
            <OutlinedInput
              error={!!values.motDePasse.error}
              type={values.showPassword ? "text" : "password"}
              value={values.motDePasse.value}
              onChange={handleChange("motDePasse")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t("field.action.toggle_password")}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("field.password")}
            />
            {!!values.motDePasse.error && (
              <FormHelperText error>
                {t(values.motDePasse.error ?? "")}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ width: "100%" }} variant="outlined" required>
            <InputLabel>{t("field.password")}</InputLabel>
            <OutlinedInput
              error={!!values.confirmMotDePasse.error}
              type={values.showPassword ? "text" : "password"}
              value={values.confirmMotDePasse.value}
              onChange={handleChange("confirmMotDePasse")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t("field.action.toggle_password")}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("field.password")}
            />
            {!!values.confirmMotDePasse.error && (
              <FormHelperText error>
                {t(values.confirmMotDePasse.error ?? "")}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </>
    );
  })
);

export default RegisterFormComponent;
