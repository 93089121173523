import React, { useRef } from "react";
import { FArticleSmallInterface } from "../../interfaces/FArticleInterface";
import { objectToQuery, requestApi } from "../../helpers/RequestApi";
import { GET } from "../../utils/MethodUtils";
import { FARTICLE_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import SliderFArticleComponent from "../slider/SliderFArticleComponent";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  addConditionalSearchFArticle,
  deleteUnwantedParamSearchFArticle,
  getTextsDisplaySearchFArticle,
} from "../../helpers/FArticleHelper";
import { SUFFIX_DISPLAY } from "../../utils/StorageUtils";

const clone = require("clone");

interface State {
  data: any;
  fArticle: FArticleSmallInterface | undefined;
  contentName?: string;
}

const ContentDisplayFArticleComponent: React.FC<State> = React.memo(
  ({ data, fArticle, contentName }) => {
    const [fArticles, setFArticles] = React.useState<
      FArticleSmallInterface[] | undefined
    >(undefined);
    const [texts, setTexts] = React.useState<any[]>([]);
    const [nbProductsDisplay, setNbProducts] = React.useState<
      number | undefined
    >(undefined);
    const [total, setTotal] = React.useState<number>(0);
    const [page, setPage] = React.useState<number>(1);
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const sliderRef: any = useRef();

    const loadMore = React.useCallback(() => {
      setPage((x) => x + 1);
    }, []);

    const load = React.useCallback(async () => {
      setNbProducts(data.nbProductsDisplay);
      let thisValue = addConditionalSearchFArticle(clone(data), fArticle);
      const thisTexts = thisValue?.texts;
      thisValue = deleteUnwantedParamSearchFArticle(thisValue);
      thisValue.itemsPerPage = 20;
      thisValue.indexable = 1;
      thisValue.page = page;
      thisValue.order = JSON.stringify({
        stock: "asc",
      });
      // todo remove after 25 mars 2024
      if (thisValue.hasOwnProperty("stock")) {
        if (thisValue.stock === "availableNow") {
          thisValue.stock = "2";
        } else if (thisValue.stock === "arrival") {
          thisValue.stock = "3";
        } else if (thisValue.stock === "notAvailable") {
          thisValue.stock = "4";
        } else {
          thisValue.stock = "1";
        }
      }
      const response = await requestApi({
        method: GET,
        path: FARTICLE_URL + objectToQuery(thisValue),
        allowError: false,
        paginate: true,
        token: token,
      });
      if (response.statusCode === 200) {
        const thisFArticles = response.content.fArticles;
        setFArticles((x) => {
          if (!x) {
            x = [];
          }
          x = [...x, ...thisFArticles];
          return [...x];
        });
        setTexts(getTextsDisplaySearchFArticle(thisTexts, thisFArticles));
        setTotal(response.content.total);
        setTimeout(() => {
          if (sliderRef?.current) {
            sliderRef.current.setIsLoadingMore(false);
          }
        });
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        setFArticles([]);
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
    }, [data, fArticle, page, t, token]);

    React.useEffect(() => {
      setPage(1);
    }, [JSON.stringify(data ?? {})]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      load();
    }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {texts.map((text, indexText) => (
          <div
            key={indexText}
            dangerouslySetInnerHTML={{ __html: text.text }}
          />
        ))}
        <SliderFArticleComponent
          ref={sliderRef}
          fArticles={fArticles}
          canLoadMore={fArticles && total > fArticles.length}
          loadMore={loadMore}
          initNbProductsDisplay={nbProductsDisplay}
          {...(contentName && {
            displayStorage: contentName + SUFFIX_DISPLAY,
          })}
        />
      </>
    );
  }
);

export default ContentDisplayFArticleComponent;
