import React from "react";
import { STORAGE_STATE, STORAGE_TOKEN } from "../utils/StorageUtils";
import { ReactComponent as LogoNoveSvg } from "../assets/logoNove.svg";
import Box from "@mui/material/Box";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { initAppHelper } from "../helpers/InitAppHelper";
import { useAppDispatch } from "../app/hooks";
import { initApp } from "../app/globalSlice";
import { BROWSER_PREFIX } from "../utils/RouteUtils";
import { getTitle } from "../helpers/SearchParamHelper";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const LoadingScreen: React.FC = React.memo(() => {
  const [progress, setProgress] = React.useState(10);
  const dispatch = useAppDispatch();

  const beforeInit = React.useCallback((timer: any) => {
    clearInterval(timer);
    setProgress(100);
  }, []);

  const loadData = React.useCallback(async () => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 50 : prevProgress + 1
      );
    }, 60);
    const token = localStorage.getItem(STORAGE_TOKEN);
    const previousState = localStorage.getItem(STORAGE_STATE);
    if (previousState) {
      dispatch(initApp(JSON.parse(previousState)));
    }
    if (!window.location.pathname.startsWith(BROWSER_PREFIX)) {
      await initAppHelper(token, { function: beforeInit, params: [timer] });
    }
  }, [beforeInit, dispatch]);

  React.useEffect(() => {
    console.log("Init app ...");
    document.title = getTitle("loading");
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        height: "100vh",
      }}
    >
      <LogoNoveSvg style={{ maxWidth: "250px" }} />
      <Divider />
      <CircularProgressWithLabel value={progress} />
    </Box>
  );
});

export default LoadingScreen;
