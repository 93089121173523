export const TYPE_NB_FILTER_ARTICLE = "A";
export const TYPE_MANUAL_CRON = "B";
export const TYPE_CONTACT_MESSAGE = "C";
export const TYPE_DELETE_CONTACT_MESSAGE = "D";
export const TYPE_UPDATE_CONTACT_MESSAGE = "E";
export const TYPE_UPDATE_CONTACT = "F";
export const TYPE_NOTIFICATION = "G";
export const MAIL_ACTIVATED = "H";
export const TYPE_MESSAGE = "I";
export const TYPE_CREDIT_FDOCENTETE = "J";
export const TYPE_ARRIVAGE = "K";
export const TYPE_ARRIVAGE_DONE = "L";
