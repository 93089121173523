import React, { forwardRef, useImperativeHandle } from "react";
import {
  CategoryInterface,
  FilterCategoryInterface,
} from "../../../interfaces/CategoryInterface";
import FilterCategoryComponent from "./FilterCategoryComponent";
import SearchComponent from "../SearchComponent";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { Link, useSearchParams } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { STORAGE_INDEXABLE } from "../../../utils/StorageUtils";
import { getFilterValueIdAndFilterValueChildIds } from "../../../helpers/FilterHelper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { FILTER_PAGE } from "../../../utils/RouteUtils";
import { objectToQuery } from "../../../helpers/RequestApi";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DialogFormCategoryComponent from "./DialogFormCategoryComponent";
import ContentComponent from "../../content/ContentComponent";

interface State {
  category: CategoryInterface | undefined;
  apply: Function;
  hasCategory: boolean | undefined;
  setInitFilters: Function;
  aggregations: any;
}

interface getFilterCategoryRefInterface {
  ref: any;
  filterCategory: FilterCategoryInterface;
  init?: boolean;
}

const FilterProductsComponent = React.memo(
  forwardRef(
    (
      { category, apply, hasCategory, setInitFilters, aggregations }: State,
      ref
    ) => {
      const theme = useTheme();
      const customFilters = useAppSelector(
        (state: RootState) => state.globalState.customFilters
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const [searchParams] = useSearchParams();
      const { t } = useTranslation();
      const searchParamsObject = searchParamToObject(searchParams);
      const [indexable, setIndexable] = React.useState<string>(
        searchParamsObject.indexable ??
          localStorage.getItem(STORAGE_INDEXABLE) ??
          "1"
      );
      const [editCategory, setEditCategory] = React.useState<boolean>(false);
      const [
        filterValueIdAndFilterValueChildIds,
        setFilterValueIdAndFilterValueChildIds,
      ] = React.useState<any>(getFilterValueIdAndFilterValueChildIds(category));
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));

      const getFilterCategoriesRef = React.useCallback(():
        | getFilterCategoryRefInterface[]
        | undefined => {
        if (hasCategory === undefined) {
          return undefined;
        }
        if (!hasCategory) {
          // @ts-ignore
          return (
            customFilters
              ?.filter((customFilter) => {
                return !(!isAdmin && customFilter.onlyAdmin);
              })
              .map((customFilter, indexCustomFilter) => {
                return {
                  ref: React.createRef(),
                  filterCategory: {
                    id: indexCustomFilter,
                    filter: customFilter,
                    opened: true,
                    reference: customFilter.defaultReference,
                    active: customFilter.defaultActive,
                  },
                };
              }) ?? undefined
          );
        }
        return (
          category?.filterCategories
            ?.filter((filterCategory) => filterCategory.active)
            ?.map((filterCategory) => {
              return {
                ref: React.createRef(),
                filterCategory: filterCategory,
              };
            }) ?? undefined
        );
      }, [category?.filterCategories, customFilters, hasCategory, isAdmin]);

      const [filterCategoriesRef, setFilterCategoriesRef] = React.useState<
        getFilterCategoryRefInterface[] | undefined
      >(getFilterCategoriesRef());

      const applyHidden = React.useCallback(() => {
        if (filterCategoriesRef) {
          for (const filterCategoryRef of filterCategoriesRef) {
            filterCategoryRef?.ref?.current?.applyHidden();
          }
        }
      }, [filterCategoriesRef]);

      const handleChangeIndexable = React.useCallback(
        (event: SelectChangeEvent) => {
          const value = event.target.value as string;
          localStorage.setItem(STORAGE_INDEXABLE, value);
          setIndexable(value);
        },
        []
      );

      const filterInited = React.useCallback((index: number) => {
        setFilterCategoriesRef((x) => {
          if (!x) {
            return x;
          }
          x[index].init = true;
          return [...x];
        });
      }, []);

      const showFormCategory = React.useCallback(() => {
        setEditCategory(true);
      }, []);

      const handleClose = React.useCallback(() => {
        setEditCategory(false);
      }, []);

      const getValue = React.useCallback(() => {
        let result: any = {};
        if (filterCategoriesRef === undefined) {
          return result;
        }
        let filterValues: number[] = [];
        let filterValueNumerics: number[] = [];
        for (const filterCategoryRef of filterCategoriesRef) {
          if (!filterCategoryRef.ref.current) {
            continue;
          }
          const filterCategoryValue = filterCategoryRef.ref.current.getValue();
          result = {
            ...result,
            ...filterCategoryValue,
            ...(isAdmin && indexable !== "" && { indexable: indexable }),
          };
          if (filterCategoryValue?.hasOwnProperty("filterValues[]")) {
            filterValues = [
              ...filterValues,
              ...filterCategoryValue["filterValues[]"],
            ];
          }
          if (filterCategoryValue?.hasOwnProperty("filterValueNumerics[]")) {
            filterValueNumerics = [
              ...filterValueNumerics,
              filterCategoryValue["filterValueNumerics[]"],
            ];
          }
        }
        result["filterValues[]"] = filterValues;
        if (result["filterValues[]"].length === 0) {
          delete result["filterValues[]"];
        }
        result["filterValueNumerics[]"] = filterValueNumerics;
        if (result["filterValueNumerics[]"].length === 0) {
          delete result["filterValueNumerics[]"];
        }
        return result;
      }, [filterCategoriesRef, indexable, isAdmin]);

      const customApply = React.useCallback(() => {
        applyHidden();
        setTimeout(() => {
          apply();
        });
      }, [apply, applyHidden]);

      React.useEffect(() => {
        setFilterCategoriesRef(getFilterCategoriesRef());
      }, [category?.filterCategories, customFilters, hasCategory]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (!isSmall) {
          apply();
        }
      }, [indexable]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (
          filterCategoriesRef &&
          filterCategoriesRef?.length ===
            filterCategoriesRef?.filter((x) => x.init).length
        ) {
          setInitFilters(true);
          setTimeout(() => {
            apply();
          });
        }
      }, [filterCategoriesRef]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setFilterValueIdAndFilterValueChildIds(
          getFilterValueIdAndFilterValueChildIds(category)
        );
      }, [category?.id]); // eslint-disable-line react-hooks/exhaustive-deps

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
        resetCustomRayon() {
          const rayonFilter = filterCategoriesRef?.find(
            (x) => x.filterCategory.filter.slug === "rayon"
          );
          if (rayonFilter) {
            rayonFilter.ref.current.resetCustomRayon();
          }
        },
      }));

      return (
        <>
          {isAdmin && (
            <>
              <FormControl fullWidth required>
                <Select
                  value={indexable}
                  displayEmpty
                  onChange={handleChangeIndexable}
                >
                  <MenuItem value="">
                    <em>{t("word.all")}</em>
                  </MenuItem>
                  <MenuItem value="1">{t("word.indexed")}</MenuItem>
                  <MenuItem value="0">{t("word.unindexed")}</MenuItem>
                </Select>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {category && (
                  <>
                    <DialogFormCategoryComponent
                      category={category}
                      openDialog={editCategory}
                      handleClose={handleClose}
                    />
                    <Link
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={
                        FILTER_PAGE +
                        objectToQuery({ tab: 0, category: category?.id })
                      }
                    >
                      <IconButton>
                        <FilterAltIcon />
                      </IconButton>
                    </Link>
                    <IconButton onClick={showFormCategory}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </>
          )}
          <ContentComponent
            contentName="beforeFilters"
            saveLocalStorage={true}
            noPadding={true}
          />
          {hasCategory === undefined ||
          (hasCategory &&
            (filterCategoriesRef === undefined || category === undefined)) ? (
            <SearchComponent
              nbColumn={1}
              nbLines={4}
              height={150}
              width={290}
            />
          ) : (
            filterCategoriesRef?.map(
              (filterCategoryRef, indexFilterCategoryRef) => (
                <FilterCategoryComponent
                  key={filterCategoryRef.filterCategory.id}
                  aggregations={aggregations}
                  index={indexFilterCategoryRef}
                  filterInited={filterInited}
                  ref={filterCategoryRef.ref}
                  category={category}
                  filterCategory={filterCategoryRef.filterCategory}
                  getAllFilterValueValues={getValue}
                  apply={customApply}
                  applyHidden={applyHidden}
                  filterValueIdAndFilterValueChildIds={
                    filterValueIdAndFilterValueChildIds
                  }
                />
              )
            )
          )}
        </>
      );
    }
  )
);

export default FilterProductsComponent;
