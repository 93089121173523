import * as React from "react";
import { useEffect, useImperativeHandle } from "react";
import {
  Card,
  CardContent,
  Chip,
  DialogProps,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import { requestApi } from "../../../helpers/RequestApi";
import { DELETE, GET, PATCH } from "../../../utils/MethodUtils";
import { NEWS_URL, USER_PROP_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import ContentDisplayComponent from "../../content/ContentDisplayComponent";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import SearchComponent from "../SearchComponent";
import { LoadingButton } from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import getErrorApi from "../../../helpers/GetErrorApi";
import { NewsInterface } from "../../../interfaces/NewsInterface";

const NewsComponent = React.memo(
  React.forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const initNews = useAppSelector(
      (state: RootState) => state.globalState.news
    );
    const userProp = useAppSelector(
      (state: RootState) => state.globalState.userProp
    );
    const [news, setNews] = React.useState(initNews);
    const [page, setPage] = React.useState(1);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const [openDialog, setOpenDialog] = React.useState<NewsInterface | null>(
      null
    );
    const [loading, setLoading] = React.useState(false);

    const refreshPage = useAppSelector(
      (state: RootState) => state.globalState.refreshPage
    );
    const dispatch = useAppDispatch();
    const [init, setInit] = React.useState(false);

    const load = React.useCallback(
      async (force: boolean = false) => {
        setInit(true);
        if (page === 1 && force) {
          setNews(undefined);
        }
        const response = await requestApi({
          method: GET,
          path: NEWS_URL + "?page=" + page,
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          if (page === 1) {
            setNews(response.content);
            dispatch(set({ news: response.content }));
          } else {
            setNews((n) => {
              if (n === undefined) {
                n = [];
              }
              return [...n, ...response.content];
            });
          }
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
        dispatch(set({ refreshPage: false }));
      },
      [dispatch, page, t, token]
    );

    const updateLastNewsDate = React.useCallback(async () => {
      if (
        !userProp ||
        !news ||
        news.length === 0 ||
        userProp.lastNewsDate === news[0].date
      ) {
        return;
      }
      const response = await requestApi({
        method: PATCH,
        path: USER_PROP_URL + "/" + userProp.id,
        allowError: true,
        token: token,
        body: {
          lastNewsDate: news[0].date,
        },
      });
      if (response.statusCode === 200) {
        dispatch(set({ userProp: response.content }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [dispatch, news, t, token, userProp]);

    const handleDialog = React.useCallback(
      (value: NewsInterface | null) => {
        if (loading) {
          return;
        }
        setOpenDialog(value);
      },
      [loading]
    );

    const deleteNews = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: DELETE,
        path: NEWS_URL + "/" + openDialog?.id,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 204) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.news_deleted")
        );
        setOpenDialog(null);
        setNews((x) => x?.filter((y) => y.id !== openDialog?.id));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [openDialog?.id, t, token]);

    useImperativeHandle(ref, () => ({
      loadNextPage() {
        if (!news) {
          return;
        }
        setPage(Math.floor(news.length / 20) + 1);
      },
    }));

    React.useEffect(() => {
      load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (init && refreshPage) {
        setPage(1);
      }
    }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (init) {
        load(true);
      }
    }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      updateLastNewsDate();
    }, [news]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullWidth={true}
          fullScreen={fullScreen}
          onClose={() => handleDialog(null)}
          open={openDialog !== null}
        >
          <DialogContent>{t("sentence.deleteNews")}</DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <LoadingButton
              disabled={loading}
              onClick={() => handleDialog(null)}
            >
              {t("word.no")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={deleteNews}
              color="error"
            >
              {t("word.yes")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {news ? (
          news?.map((n, i) => (
            <Box
              key={i}
              sx={{
                paddingBottom: 2,
                display: "flex",
                flexDirection: isSmall ? "column" : "row",
                alignItems: "flex-start",
                borderLeft: theme.palette.primary.main + " solid 1px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "6px",
                  top: "15px",
                  borderTop: "1px solid gray",
                  left: 0,
                }}
              ></Box>
              <Box sx={{ marginLeft: 1 }}>
                {isAdmin && n.id}
                <Chip
                  label={new Date(n.date).toLocaleDateString(i18n.language, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    timeZone: "Etc/UTC",
                  })}
                  color="primary"
                />
              </Box>
              <Card
                variant="outlined"
                key={i}
                sx={{ marginBottom: 2, marginLeft: 2 }}
              >
                <CardContent>
                  <ContentDisplayComponent content={n.content} />
                </CardContent>
                {isAdmin && (
                  <Box sx={{ textAlign: "right" }}>
                    <LoadingButton
                      variant="contained"
                      onClick={() => handleDialog(n)}
                      color="error"
                      sx={{ mt: 1 }}
                    >
                      {t("word.delete")}
                    </LoadingButton>
                  </Box>
                )}
              </Card>
            </Box>
          ))
        ) : (
          <SearchComponent nbColumn={1} nbLines={4} height={200} />
        )}
      </>
    );
  })
);

export default NewsComponent;
