import { FilterFilterValueFormInterface } from "../interfaces/FilterInterface";
import { FILTER_URL, FILTER_VALUE_URL } from "../utils/UrlsUtils";
import {
  CategoryInterface,
  FilterValueInterface,
} from "../interfaces/CategoryInterface";
import { getDiff } from "json-difference";
import {
  FArticleInterface,
  FArticlePropFilterFilterValueInterface,
} from "../interfaces/FArticleInterface";

const clone = require("clone");

const getFlatFilterValues = (
  filterValues: FArticlePropFilterFilterValueInterface[],
  result: FArticlePropFilterFilterValueInterface[] = []
) => {
  for (const filterValue of filterValues) {
    result.push(filterValue);
    if (filterValue.children.length > 0) {
      getFlatFilterValues(filterValue.children, result);
    }
  }
  return result;
};

export const getNeedToSendRequest = (
  newValue: any,
  currentFArticle: FArticleInterface | undefined,
  customFArticle: FArticleInterface | undefined
): boolean => {
  if (customFArticle) {
    return true;
  }
  if (!currentFArticle) {
    return false;
  }
  const filterArticlesLength =
    currentFArticle.fArticleProp?.filterArticles?.length;
  if (filterArticlesLength !== undefined && filterArticlesLength > 0) {
    return true;
  }
  const oldValue: any = currentFArticle.fArticleProp?.filters?.map((filter) => {
    return {
      id: filter.id,
      relevant:
        currentFArticle.fArticleProp?.filterRelevants?.find(
          (x) => x.filterId === filter.id
        ) !== undefined,
      filterValues: getFlatFilterValues(filter.filterValues)
        .filter((filterValue) => {
          return (
            currentFArticle.fArticleProp?.filterValueArticles?.find(
              (x) => x.filterValue?.id === filterValue.id
            ) !== undefined
          );
        })
        .map((filterValue) => {
          return {
            id: filterValue.id,
          };
        }),
    };
  });
  const cloneNewValue = clone(newValue);
  oldValue.sort((a: any, b: any) => a.id - b.id);
  cloneNewValue.sort((a: any, b: any) => a.id - b.id);
  const diff = getDiff(oldValue, cloneNewValue);
  return (
    diff.removed.length > 0 || diff.added.length > 0 || diff.edited.length > 0
  );
};

export const createFilterValuesApi = (
  filterValues: FilterFilterValueFormInterface[]
): any[] => {
  return filterValues.map((filterValue, indexFilterValue) => {
    let parent = filterValue.refParent.current.getValue();
    if (parent) {
      parent = FILTER_VALUE_URL + "/" + parent;
    }
    return {
      oldId: filterValue.id,
      name: filterValue.refName.current.getValue(),
      filterImage: filterValue.refImage.current.getValue(),
      parent: parent,
      show: filterValue.refVisible.current.getValue(),
      hover: filterValue.refHover.current.getValue(),
      forceCreateFilterArticle:
        filterValue.refForceCreateFilterArticle.current?.getValue() ?? false,
      sort: indexFilterValue + 1,
      keywords: filterValue.refKeywords.current.getValue()?.keywords,
    };
  });
};

const _getFilterValueIdAndFilterValueChildIds = (
  filterValue: FilterValueInterface,
  filterValueId: number,
  result: any
): any => {
  if (!result.hasOwnProperty(filterValueId)) {
    result[filterValueId] = [];
  }
  if (!result[filterValueId].includes(filterValue.id)) {
    result[filterValueId].push(filterValue.id);
  }
  if (filterValue.children) {
    for (const child of filterValue.children) {
      _getFilterValueIdAndFilterValueChildIds(child, filterValueId, result);
      _getFilterValueIdAndFilterValueChildIds(child, child.id, result);
    }
  }
};

export const getFilterValueIdAndFilterValueChildIds = (
  category: CategoryInterface | undefined
): any => {
  const result = {};
  if (!category?.filterCategories) {
    return result;
  }
  const filterCategories = category.filterCategories.filter(
    (filterCategory) => !filterCategory.filter.custom
  );
  for (const filterCategory of filterCategories) {
    for (const filterValue of filterCategory.filter.filterValues) {
      _getFilterValueIdAndFilterValueChildIds(
        filterValue,
        filterValue.id,
        result
      );
    }
  }
  return result;
};

export const getAllFilterValues = (
  filterValues: FilterValueInterface[],
  result: number[] = []
): number[] => {
  for (const filterValue of filterValues) {
    result.push(filterValue.id);
    if (filterValue.children && filterValue.children.length > 0) {
      getAllFilterValues(filterValue.children, result);
    }
  }
  return result;
};

export const customRayonSearchChanged = (
  searchParamsObject: any,
  oldSearchParams: any
): boolean => {
  const thisSearchParamsObject = { ...searchParamsObject };
  const thisOldSearchParams = { ...oldSearchParams };
  delete thisSearchParamsObject.page;
  delete thisSearchParamsObject.itemsPerPage;
  delete thisSearchParamsObject["categories[]"];
  delete thisOldSearchParams.page;
  delete thisOldSearchParams.itemsPerPage;
  delete thisOldSearchParams["categories[]"];

  const diff = getDiff(thisSearchParamsObject, thisOldSearchParams);
  return (
    diff.removed.length > 0 || diff.added.length > 0 || diff.edited.length > 0
  );
};

export const filterIsEqual = (
  objectOrString: any,
  filterId: number | string
): boolean => {
  if (typeof objectOrString === "string") {
    return objectOrString === FILTER_URL + "/" + filterId;
  }
  return objectOrString.id.toString() === filterId.toString();
};
