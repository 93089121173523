import React, { SyntheticEvent, useImperativeHandle } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SavInterface } from "../../../interfaces/SavInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";
import { CATEGORY_URL } from "../../../utils/UrlsUtils";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";

interface State {
  sav?: SavInterface | null;
}

interface FormState {
  category: InputInterface;
  brand: InputInterface;
  model: InputInterface;
  serialNumber: InputInterface;
}

const SavOutsideNoveFormComponent = React.memo(
  React.forwardRef(({ sav }: State, ref) => {
    const { t } = useTranslation();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const categories = useAppSelector(
      (state: RootState) => state.globalState.categories
    );
    const [thisCategories] = React.useState<CategoryInterface[]>(
      categories
        ?.map((c) => c.children)
        .reduce((a, b) => {
          // @ts-ignore
          return a.concat(b);
        }, []) ?? []
    );
    const getSavCategory = React.useCallback(() => {
      if (!sav || !categories) {
        return null;
      }
      for (const parent of categories) {
        const child = parent.children?.find(
          (child) => CATEGORY_URL + "/" + child.id === sav.category
        );
        if (child) {
          return child;
        }
      }
      return null;
    }, [categories, sav]);
    const getDefaultValue = React.useCallback((): FormState => {
      return {
        category: {
          value: getSavCategory(),
          error: "",
        },
        brand: {
          value: sav?.brand ?? "",
          error: "",
        },
        model: {
          value: sav?.model ?? "",
          error: "",
        },
        serialNumber: {
          value: sav?.fArticleSerialNumber ?? "",
          error: "",
        },
      };
    }, [getSavCategory, sav?.brand, sav?.fArticleSerialNumber, sav?.model]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );
    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      const categoryError = notEmptyValidator(values.category.value);
      const brandError = notEmptyValidator(values.brand.value);
      const modelError = notEmptyValidator(values.model.value);
      if (categoryError || brandError || modelError) {
        const newValue: FormState = { ...values };
        if (categoryError) {
          newValue.category.error = categoryError;
        }
        if (brandError) {
          newValue.brand.error = brandError;
        }
        if (modelError) {
          newValue.model.error = modelError;
        }
        setValues(newValue);
        return undefined;
      }
      return {
        category: CATEGORY_URL + "/" + values.category.value.id,
        brand: values.brand.value,
        model: values.model.value,
        fArticleSerialNumber:
          values.serialNumber.value === "" ? null : values.serialNumber.value,
      };
    }, [values]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [user, sav]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disabled={!!sav?.id}
            fullWidth
            options={thisCategories ?? []}
            getOptionLabel={(category: CategoryInterface) => {
              return category.name ?? "";
            }}
            groupBy={(option) =>
              categories?.find(
                (c) => CATEGORY_URL + "/" + c.id === option.parent?.toString()
              )?.name ?? ""
            }
            isOptionEqualToValue={(
              option: CategoryInterface,
              value: CategoryInterface
            ) => {
              return option.id === value.id;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            onChange={handleChangeAutocomplete("category")}
            value={values.category.value}
            defaultValue={values.category.value}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                required
                error={!!values.category.error}
                helperText={t(values.category.error ?? "")}
                label={t("word.category")}
                placeholder={t("word.category")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={!!sav?.id}
            autoComplete="off"
            error={!!values.brand.error}
            helperText={t(values.brand.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="text"
            value={values.brand.value}
            onChange={handleChange("brand")}
            label={t("field.brand")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={!!sav?.id}
            autoComplete="off"
            error={!!values.model.error}
            helperText={t(values.model.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="text"
            value={values.model.value}
            onChange={handleChange("model")}
            label={t("field.model")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={!!sav?.id}
            autoComplete="off"
            error={!!values.serialNumber.error}
            helperText={t(values.serialNumber.error ?? "")}
            sx={{ width: "100%" }}
            type="text"
            value={values.serialNumber.value}
            onChange={handleChange("serialNumber")}
            label={t("field.serialNumber")}
          />
        </Grid>
      </>
    );
  })
);

export default SavOutsideNoveFormComponent;
