import React from "react";
import { useTranslation } from "react-i18next";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import { FormControlLabel } from "@mui/material";
import { ALL_STATUS } from "../../../utils/PaymentHistoryUtils";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FilterSearchComponent from "../filter/FilterSearchComponent";
import Box from "@mui/material/Box";

interface State {
  init: boolean;
}

const PaymentsSearchComponent: React.FC<State> = React.memo(({ init }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const getStatus = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return ALL_STATUS.map((doType) => {
      return (
        searchParamsObject["status[]"] !== undefined &&
        searchParamsObject["status[]"].includes(doType.toString())
      );
    });
  }, [searchParams]);
  const [checkedStatus, setCheckedDoType] = React.useState<boolean[]>(
    getStatus()
  );

  const handleChangeStatus = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, indexFilterValue: number) => {
      setCheckedDoType((x) => {
        x[indexFilterValue] = event.target.checked;
        return [...x];
      });
    },
    []
  );

  const updateSearchParams = React.useCallback(() => {
    const status = ALL_STATUS.filter(
      (status, indexStatus) => checkedStatus[indexStatus]
    ).map((doType) => doType.toString());
    const searchParamsObject = searchParamToObject(searchParams);
    delete searchParamsObject["status[]"];
    if (checkedStatus.length > 0) {
      searchParamsObject["status[]"] = status;
    }
    setSearchParams(searchParamsObject, {
      replace: true,
    });
  }, [checkedStatus, searchParams, setSearchParams]);

  React.useEffect(() => {
    updateSearchParams();
  }, [checkedStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ marginBottom: 1 }}>
      <FormGroup sx={{ flexDirection: "row" }}>
        {ALL_STATUS.map((status, indexStatus) => {
          return (
            <FormControlLabel
              key={indexStatus}
              control={
                <Checkbox
                  checked={checkedStatus[indexStatus]}
                  onChange={(e) => handleChangeStatus(e, indexStatus)}
                />
              }
              label={t("word.paymentHistory.status." + status)}
            />
          );
        })}
      </FormGroup>
      <FilterSearchComponent
        init={init}
        placeholder="word.searchPaymentPlaceholder"
      />
    </Box>
  );
});

export default PaymentsSearchComponent;
