import * as React from "react";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import { GetPostOrdersInterface } from "../../../interfaces/OrderInterface";
import PostOrderActionComponent from "./PostOrderActionComponent";
import TablePostOrderComponent from "./TablePostOrderComponent";

interface State {
  postOrder: GetPostOrdersInterface;
  load: Function;
}

const PostOrderComponent: React.FC<State> = React.memo(
  ({ postOrder, load }) => {
    return (
      <Card variant="outlined" sx={{ marginBottom: 2 }}>
        <Grid container spacing={1} sx={{ padding: 1 }}>
          <Grid item xs={12}>
            <TablePostOrderComponent postOrder={postOrder} />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <PostOrderActionComponent postOrder={postOrder} load={load} />
          </Grid>
        </Grid>
      </Card>
    );
  }
);

export default PostOrderComponent;
