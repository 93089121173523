import React, { useRef } from "react";
import { SavInterface } from "../../interfaces/SavInterface";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import SavFormClientComponent from "./client/SavFormClientComponent";
import SavFormProductComponent from "./product/SavFormProductComponent";
import SavFormServiceComponent from "./service/SavFormServiceComponent";
import { InputInterface } from "../../interfaces/InputInterface";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import ContentComponent from "../../components/content/ContentComponent";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import CkEditorComponent from "../../components/content/CkEditorComponent";
import checkedValidator from "../../helpers/validator/CheckedValidator";
import { requestApi } from "../../helpers/RequestApi";
import { POST } from "../../utils/MethodUtils";
import { SAV_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../helpers/GetErrorApi";
import SavFormCommentsComponent from "./comments/SavFormCommentsComponent";
import SavFormNoveComponent from "./nove/SavFormNoveComponent";
import { UserInterface } from "../../interfaces/UserInterface";
import { ABOUT_PAGE } from "../../utils/RouteUtils";
import { STATE_PENDING_REQUEST } from "../../utils/SavUtils";
import { STORAGE_SAV_COMMENT } from "../../utils/StorageUtils";

interface State {
  sav?: SavInterface | null;
  successFunction: Function;
  asAdmin: boolean;
  setSav: Function;
}

interface FormState {
  dataLose: InputInterface;
  cgv: InputInterface;
}

const SavFormComponent: React.FC<State> = React.memo(
  ({ sav, setSav, successFunction, asAdmin }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const identificationRef: any = useRef();
    const productRef: any = useRef();
    const serviceRef: any = useRef();
    const reservedNoveRef: any = useRef();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const [loading, setLoading] = React.useState(false);
    const [userForm, setUserForm] = React.useState<UserInterface | undefined>(
      undefined
    );
    const ckEditorRef: any = useRef();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultValue = React.useCallback((): FormState => {
      return {
        dataLose: {
          value: sav?.dataLose ?? false,
          error: "",
        },
        cgv: {
          value: !!sav?.id,
          error: "",
        },
      };
    }, [sav]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());

    const save = React.useCallback(async () => {
      setLoading(true);
      const identificationValue = identificationRef.current.getValue();
      const productValue = productRef.current.getValue();
      const serviceValue = serviceRef.current.getValue();
      const cgvError = checkedValidator(values.cgv.value);
      if (cgvError || !identificationValue || !productValue || !serviceValue) {
        const newValue: FormState = { ...values };
        if (cgvError) {
          newValue.cgv.error = cgvError;
        }
        setValues(newValue);
        setLoading(false);
        return undefined;
      }
      const ckEditorValue = ckEditorRef.current.getValue();
      const savComments = [];
      if (ckEditorValue) {
        savComments.push({
          text: ckEditorValue,
          private: false,
        });
      }
      const reservedNoveValue = reservedNoveRef?.current?.getValue();
      const postSav = {
        userIdentifier: identificationValue.userIdentifier,
        company: identificationValue.company,
        contact: identificationValue.contact,
        phone: identificationValue.phone,
        mail: identificationValue.mail,
        fDocenteteId: productValue.fDocenteteId ?? null,
        fArticleId: productValue.fArticleId ?? null,
        fArticleSerialNumber: productValue.fArticleSerialNumber ?? null,
        reason: serviceValue.reason,
        state: reservedNoveValue?.state ?? STATE_PENDING_REQUEST,
        packageState: serviceValue.packageState,
        origin: productValue.origin,
        dataLose: values.dataLose.value,
        category: productValue.category ?? null,
        brand: productValue.brand ?? null,
        model: productValue.model ?? null,
        savComments: savComments,
      };
      const response = await requestApi({
        method: POST,
        path: SAV_URL,
        allowError: true,
        token: token,
        body: postSav,
      });
      if (response.statusCode === 201) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.sav_created")
        );
        successFunction(response.content);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [successFunction, t, token, values]);

    const handleChangeCheckbox = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      []
    );

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [user, asAdmin, sav]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          {sav?.id && (
            <Grid item xs={12}>
              <Typography
                sx={{ color: theme.palette.primary.main, textAlign: "right" }}
              >
                {t("word.sav.number") + ": " + sav.id}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="h6"
              sx={{ color: theme.palette.primary.main }}
            >
              {t("word.sav.clientIdentification")}
            </Typography>
          </Grid>
          <SavFormClientComponent
            sav={sav}
            ref={identificationRef}
            asAdmin={asAdmin}
            setUserForm={setUserForm}
          />
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="h6"
              sx={{ color: theme.palette.primary.main }}
            >
              {t("word.sav.productIdentification")}
            </Typography>
          </Grid>
          <SavFormProductComponent
            sav={sav}
            ref={productRef}
            userForm={userForm}
            setSav={setSav}
          />
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="h6"
              sx={{ color: theme.palette.primary.main }}
            >
              {t("word.sav.serviceIdentification")}
            </Typography>
          </Grid>
          <SavFormServiceComponent sav={sav} ref={serviceRef} />
          <Grid item xs={12}>
            {!sav?.id && (
              <>
                <Typography
                  component="p"
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {t("word.comment")}
                </Typography>
                <CkEditorComponent
                  ref={ckEditorRef}
                  text={""}
                  small={true}
                  idLocaleStorage={STORAGE_SAV_COMMENT + sav?.id}
                />
              </>
            )}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!!sav?.id}
                    checked={values.dataLose.value}
                    onChange={handleChangeCheckbox("dataLose")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={t("field.dataLose")}
              />
            </FormGroup>
            {values.dataLose.value && (
              <ContentComponent
                contentName="dataLose"
                saveLocalStorage={true}
              />
            )}
            <FormControl error={!!values.cgv.error}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!!sav?.id}
                      checked={values.cgv.value}
                      onChange={handleChangeCheckbox("cgv")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <Link to={ABOUT_PAGE + "?tab=cgv"} target="_blank">
                      {t("sentence.agreeCvg")}
                    </Link>
                  }
                />
                {!!values.cgv.error && (
                  <FormHelperText error>
                    {t(values.cgv.error ?? "")}
                  </FormHelperText>
                )}
              </FormGroup>
            </FormControl>
            <ContentComponent contentName="savForm" saveLocalStorage={true} />
          </Grid>
        </Grid>
        {!sav?.id ? (
          <>
            {asAdmin && (
              <Box sx={{ marginBottom: 1 }}>
                <SavFormNoveComponent
                  sav={sav}
                  asAdmin={asAdmin}
                  ref={reservedNoveRef}
                />
              </Box>
            )}
            <Box sx={{ textAlign: "right" }}>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={save}
              >
                {t("word.saveSav")}
              </LoadingButton>
            </Box>
          </>
        ) : (
          <SavFormCommentsComponent
            sav={sav}
            asAdmin={asAdmin}
            refFormSav={reservedNoveRef}
            setSav={setSav}
          />
        )}
      </>
    );
  }
);

export default SavFormComponent;
