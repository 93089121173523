import React, { forwardRef, useImperativeHandle } from "react";
import { TextField, useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";

interface State {
  apply: Function;
  setNbSelected: Function;
  index?: number;
  filterInited?: Function;
}

const ScoreFilterComponent = React.memo(
  forwardRef(({ apply, setNbSelected, index, filterInited }: State, ref) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [searchParams] = useSearchParams();
    const [minScore, setMinScore] = React.useState<string>(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.minScore ?? "";
    });

    const handleChangeText = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinScore(event.target.value);
      },
      []
    );

    const searchScore = React.useCallback(() => {
      if (!isSmall) {
        apply();
      }
    }, [apply, isSmall]);

    useImperativeHandle(ref, () => ({
      getValue() {
        if (minScore.trim() !== "") {
          return {
            minScore: minScore,
          };
        }
        return {};
      },
    }));

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchScore();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [minScore]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setNbSelected(minScore.trim() === "" ? 0 : 1);
      if (filterInited && index !== undefined) {
        filterInited(index);
      }
    }, [minScore]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      setMinScore(searchParamsObject?.minScore ?? "");
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <TextField
        fullWidth
        autoComplete="off"
        type="text"
        value={minScore}
        onChange={handleChangeText}
      />
    );
  })
);

export default ScoreFilterComponent;
