import React, { useImperativeHandle } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import phoneValidator, {
  formatPhone,
} from "../../../helpers/validator/PhoneValidator";
import { InputInterface } from "../../../interfaces/InputInterface";
import FormHelperText from "@mui/material/FormHelperText";

interface FormState {
  ctTelephone: InputInterface;
}

interface State {
  initCtTelephone: string | undefined;
  disabled?: boolean | undefined;
}

// https://catamphetamine.gitlab.io/react-phone-number-input/
// https://www.npmjs.com/package/react-phone-number-input
const PhoneInputComponent = React.memo(
  React.forwardRef(({ initCtTelephone, disabled }: State, ref) => {
    const { t } = useTranslation();

    const getDefaultValues = React.useCallback((): FormState => {
      return {
        ctTelephone: { value: formatPhone(initCtTelephone ?? ""), error: "" },
      };
    }, [initCtTelephone]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const getErrors = React.useCallback(() => {
      const ctTelephoneError = phoneValidator(values.ctTelephone.value);
      if (ctTelephoneError) {
        const newValue: FormState = { ...values };
        if (ctTelephoneError) {
          newValue.ctTelephone.error = ctTelephoneError;
        }
        setValues(newValue);
        return true;
      }
      return false;
    }, [values]);

    const handleChange = React.useCallback(
      (prop: keyof FormState) => (newValue: any) => {
        setValues((v) => {
          return {
            ...v,
            // @ts-ignore
            [prop]: { ...v[prop], value: newValue ?? "", error: "" },
          };
        });
      },
      []
    );

    // formatPhoneNumberIntl(value)
    useImperativeHandle(ref, () => ({
      getValue() {
        if (getErrors()) {
          return undefined;
        }
        return formatPhoneNumberIntl(values.ctTelephone.value);
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [initCtTelephone]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      getErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let className = "phone-input";
    if (values.ctTelephone.error) {
      className += " phone-input-error";
    }

    return (
      <>
        <PhoneInput
          countries={["BE", "FR", "GF", "GP", "LU", "MQ", "BL", "GB", "US"]}
          labels={{
            BE: "Belgique",
            FR: "France",
            GF: "Guyane française",
            GP: "Guadeloupe",
            LU: "Luxembourg",
            MQ: "Martinique",
            BL: "Saint-Barthélemy",
            GB: "Royaume-Uni",
            US: "États-Unis",
          }}
          international
          countryCallingCodeEditable={false}
          defaultCountry="GP"
          placeholder={t("field.ctTelephone.label")}
          value={values.ctTelephone.value}
          onChange={handleChange("ctTelephone")}
          className={className}
          disabled={disabled}
        />
        {!!values.ctTelephone.error && (
          <FormHelperText error>
            {t(values.ctTelephone.error ?? "")}
          </FormHelperText>
        )}
      </>
    );
  })
);

export default PhoneInputComponent;
