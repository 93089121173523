import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import LivraisonFormComponent from "../../components/common/user/livraison/LivraisonFormComponent";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../helpers/RequestApi";
import { POST } from "../../utils/MethodUtils";
import { EDIT_USER_URL } from "../../utils/UrlsUtils";
import { setUser } from "../../app/globalSlice";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../helpers/GetErrorApi";

const CardBillingAddressComponent: React.FC = React.memo(() => {
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const formRef: any = useRef();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const dispatch = useAppDispatch();

  const save = React.useCallback(async () => {
    const value = formRef.current.getValue();
    if (!value) {
      return;
    }
    const newValue: any = {};
    for (const key in value) {
      let newKey = key;
      if (key.startsWith("li")) {
        newKey = "ct" + key.substring(2);
      }
      newValue[newKey] = value[key];
    }
    delete newValue.ctPrincipal;
    setLoading(true);
    const response = await requestApi({
      method: POST,
      path: EDIT_USER_URL,
      allowError: false,
      body: {
        userIdentifier: user?.userIdentifier,
        ...newValue,
      },
      token: token,
    });
    if (response.statusCode === 200) {
      dispatch(setUser(response.content));
      toastr.success(
        t("word.success"),
        t("sentence.notification.user_updated")
      );
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [dispatch, t, token, user?.userIdentifier]);

  console.log(user);
  return (
    <>
      {user && (
        <>
          <LivraisonFormComponent
            ref={formRef}
            fLivraison={{
              liIntitule: user.ctIntitule,
              liContact: user.ctContact,
              liAdresse: user.ctAdresse,
              liEmail: user.ctEmail,
              liComplement: user.ctComplement,
              liCodepostal: user.ctCodepostal,
              liVille: user.ctVille,
              liPays: user.ctPays,
              liCoderegion: user.ctCoderegion,
              liTelephone: user.ctTelephone,
              liNo: 0,
              liPrincipal: 1,
            }}
            hideActionButton={true}
          />
          <Grid item xs={12} sx={{ textAlign: "right", mt: 2 }}>
            <LoadingButton variant="contained" loading={loading} onClick={save}>
              {t("word.save")}
            </LoadingButton>
          </Grid>
        </>
      )}
    </>
  );
});

export default CardBillingAddressComponent;
