import React, { forwardRef, useImperativeHandle } from "react";
import { InputInterface } from "../../../../interfaces/InputInterface";
import { CategoryInterface } from "../../../../interfaces/CategoryInterface";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface State {
  category: CategoryInterface | undefined;
}

interface FormState {
  name: InputInterface;
}

const FormNameCategoryComponent = React.memo(
  forwardRef(({ category }: State, ref) => {
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        name: { value: category?.name ?? "", error: "" },
      };
    }, [category?.name]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const { t } = useTranslation();

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return values.name.value;
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [category]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <TextField
        fullWidth={true}
        autoComplete="off"
        error={!!values.name.error}
        helperText={t(values.name.error ?? "")}
        sx={{ width: "100%" }}
        required
        type="text"
        value={values.name.value}
        onChange={handleChange("name")}
        label={t("field.name")}
      />
    );
  })
);

export default FormNameCategoryComponent;
