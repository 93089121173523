const numberValidator = (
  number: string | null,
  min: number | null = null,
  max: number | null = null
) => {
  if (number === null) {
    return "";
  }
  number = number.trim();
  if (number === "") {
    return "";
  }
  if (isNaN(Number(number))) {
    return "error.field.number";
  }
  if (!Number.isInteger(Number(number))) {
    return "error.field.integer";
  }
  if (max !== null && Number(number) > max) {
    return "error.field.max";
  }
  if (min !== null && Number(number) < min) {
    return "error.field.min";
  }
  return "";
};
export default numberValidator;
