import React from "react";
import ContentComponent from "../../components/content/ContentComponent";
import { DialogContent, Grid } from "@mui/material";
import { InputInterface } from "../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { requestApi } from "../../helpers/RequestApi";
import { POST } from "../../utils/MethodUtils";
import { EDIT_USER_URL } from "../../utils/UrlsUtils";
import { setUser } from "../../app/globalSlice";
import { toastr } from "react-redux-toastr";
import sameValidator from "../../helpers/validator/SameValidator";
import notEmptyValidator from "../../helpers/validator/NotEmptyValidator";
import getErrorApi from "../../helpers/GetErrorApi";
import sagCtNumValidator from "../../helpers/validator/SagCtNumValidator";

interface State {
  close: any;
}

interface FormState {
  oldPassword: InputInterface;
  newPassword: InputInterface;
  newPassword2: InputInterface;
  showPassword: boolean;
}

const EditPhoneFormComponent: React.FC<State> = React.memo(({ close }) => {
  const [values, setValues] = React.useState<FormState>({
    oldPassword: { value: "", error: "" },
    newPassword: { value: "", error: "" },
    newPassword2: { value: "", error: "" },
    showPassword: false,
  });
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          // @ts-ignore
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const handleClickShowPassword = React.useCallback(() => {
    setValues((v) => {
      return {
        ...v,
        showPassword: !values.showPassword,
      };
    });
  }, [values]);

  const handleMouseDownPassword = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  const submit = React.useCallback(async () => {
    setLoading(true);
    const oldPasswordError = notEmptyValidator(values.oldPassword.value);
    const sameError = sameValidator(
      values.newPassword.value,
      values.newPassword2.value
    );
    const newPasswordError = sagCtNumValidator(values.newPassword.value);
    if (sameError || oldPasswordError || newPasswordError) {
      const newValue: FormState = { ...values };
      if (sameError) {
        newValue.newPassword.error = sameError;
        newValue.newPassword2.error = sameError;
      }
      if (oldPasswordError) {
        newValue.oldPassword.error = oldPasswordError;
      }
      if (newPasswordError) {
        newValue.newPassword.error = newPasswordError;
      }
      setValues(newValue);
      setLoading(false);
      return;
    }
    const response = await requestApi({
      method: POST,
      path: EDIT_USER_URL,
      allowError: false,
      body: {
        userIdentifier: user?.userIdentifier,
        oldPassword: values.oldPassword.value,
        newPassword: values.newPassword.value,
      },
      token: token,
    });
    if (response.statusCode === 200) {
      dispatch(setUser(response.content));
      toastr.success(
        t("word.success"),
        t("sentence.notification.password_updated")
      );
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
    close();
  }, [close, dispatch, t, token, user?.userIdentifier, values]);

  return (
    <>
      <DialogContent>
        <ContentComponent
          contentName="editPassword"
          saveLocalStorage={true}
          width={320}
        />
        <Divider sx={{ marginBottom: 2 }} />
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined" required>
              <InputLabel>{t("field.oldPassword")}</InputLabel>
              <OutlinedInput
                error={!!values.oldPassword.error}
                type={values.showPassword ? "text" : "password"}
                value={values.oldPassword.value}
                onChange={handleChange("oldPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("field.action.toggle_password")}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("field.oldPassword")}
              />
              {!!values.oldPassword.error && (
                <FormHelperText error>
                  {t(values.oldPassword.error ?? "")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined" required>
              <InputLabel>{t("field.newPassword")}</InputLabel>
              <OutlinedInput
                error={!!values.newPassword.error}
                type={values.showPassword ? "text" : "password"}
                value={values.newPassword.value}
                onChange={handleChange("newPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("field.action.toggle_password")}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("field.newPassword")}
              />
              {!!values.newPassword.error && (
                <FormHelperText error>
                  {t(values.newPassword.error ?? "")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }} variant="outlined" required>
              <InputLabel>{t("field.newPassword2")}</InputLabel>
              <OutlinedInput
                error={!!values.newPassword2.error}
                type={values.showPassword ? "text" : "password"}
                value={values.newPassword2.value}
                onChange={handleChange("newPassword2")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t("field.action.toggle_password")}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("field.newPassword2")}
              />
              {!!values.newPassword2.error && (
                <FormHelperText error>
                  {t(values.newPassword2.error ?? "")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <LoadingButton onClick={close} disabled={loading}>
          {t("word.cancel")}
        </LoadingButton>
        <LoadingButton variant="contained" onClick={submit} loading={loading}>
          {t("word.save")}
        </LoadingButton>
      </DialogActions>
    </>
  );
});

export default EditPhoneFormComponent;
