import React, { forwardRef, useImperativeHandle } from "react";
import "cropperjs/dist/cropper.css";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FILE_CONTENT } from "../../utils/UrlsUtils";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import Box from "@mui/material/Box";

interface State {
  text: string | undefined;
  small?: boolean;
  defaultTitleSelect?: number;
  defaultAlignment?: "left" | "right" | "center" | "justify";
  onChange?: Function;
  idLocaleStorage?: string;
}

// https://ckeditor.com/docs/ckeditor5/latest/installation/getting-started/frameworks/react.html#integrating-a-build-from-the-online-builder
const CkEditorComponent = React.memo(
  forwardRef(
    (
      {
        text,
        small,
        defaultTitleSelect,
        onChange,
        defaultAlignment,
        idLocaleStorage,
      }: State,
      ref
    ) => {
      const [ckEditor, setCkEditor] = React.useState<any>(undefined);
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const stickyEditor = useAppSelector(
        (state: RootState) => state.globalState.stickyEditor
      );

      const displayData = React.useCallback(() => {
        return ckEditor.getData();
      }, [ckEditor]);

      useImperativeHandle(ref, () => ({
        getValue() {
          if (typeof idLocaleStorage === "string") {
            localStorage.setItem(idLocaleStorage, "");
          }
          return displayData().trim();
        },
        reset(data: string = "") {
          ckEditor.setData(data);
        },
      }));

      let className = "custom-ck-editor";
      if (small) {
        className += " custom-ck-editor-small";
      }
      if (stickyEditor) {
        className += " custom-ck-editor-sticky";
      }

      let thisText = text ?? "";
      if (!thisText && idLocaleStorage) {
        thisText = localStorage.getItem(idLocaleStorage) ?? "";
      }

      return (
        <Box className={className}>
          <CKEditor
            editor={Editor}
            config={{
              // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/simple-upload-adapter.html#successful-upload
              simpleUpload: {
                uploadUrl:
                  (process.env.REACT_APP_API_URL ?? "") +
                  FILE_CONTENT +
                  "/content",
                headers: {
                  Authorization: "Bearer " + token,
                },
              },
            }}
            data={thisText}
            onReady={(editor: any) => {
              setCkEditor(editor);
              if (defaultTitleSelect || defaultAlignment) {
                if (defaultTitleSelect) {
                  // @ts-ignore
                  editor.sourceElement.parentNode
                    .querySelector(".ck-heading_heading" + defaultTitleSelect)
                    ?.click();
                }
                if (defaultAlignment) {
                  let alignIndex = 1;
                  if (defaultAlignment === "right") {
                    alignIndex = 2;
                  }
                  if (defaultAlignment === "center") {
                    alignIndex = 3;
                  }
                  if (defaultAlignment === "justify") {
                    alignIndex = 4;
                  }
                  // @ts-ignore
                  editor.sourceElement.parentNode
                    .querySelector(
                      ".ck-alignment-dropdown .ck-toolbar__items button:nth-child(" +
                        alignIndex +
                        ")"
                    )
                    ?.click();
                }
                // @ts-ignore
                document.querySelector(".ck-content")?.blur();
              }
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event: any, editor: any) => {
              if (onChange) {
                onChange(event, editor);
              }
              if (!idLocaleStorage) {
                return;
              }
              const data = editor.getData();
              localStorage.setItem(idLocaleStorage, data);
            }}
            onBlur={(event: any, editor: any) => {
              // console.log("Blur.", editor);
            }}
            onFocus={(event: any, editor: any) => {
              // console.log("Focus.", editor);
            }}
          />
        </Box>
      );
    }
  )
);

export default CkEditorComponent;
