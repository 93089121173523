import React from "react";
import { useTranslation } from "react-i18next";
import { TagInterface } from "../../../interfaces/TagInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import {
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH, POST } from "../../../utils/MethodUtils";
import { TAG_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { set } from "../../../app/globalSlice";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Checkbox from "@mui/material/Checkbox";

interface State {
  tag: TagInterface | null | undefined;
  handleClose: Function;
}

interface FormState {
  text: InputInterface;
  hex: InputInterface;
  textColorBlack: InputInterface;
}

const TagFormComponent: React.FC<State> = React.memo(({ tag, handleClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [values, setValues] = React.useState<FormState>({
    text: { value: tag?.text ?? "", error: "" },
    hex: { value: tag?.hex ?? "#000000", error: "" },
    textColorBlack: { value: tag?.textColorBlack ?? false, error: "" },
  });
  const dispatch = useAppDispatch();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const [loading, setLoading] = React.useState(false);
  const [colors] = React.useState<string[]>(
    Object.entries(theme.palette)
      .map(([k, v]) => {
        if (typeof v === "object" && v !== null && v.hasOwnProperty("main")) {
          return k;
        }
        return "";
      })
      .filter((x) => x !== "")
  );

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          // @ts-ignore
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const handleChangeColor = React.useCallback(
    (color: string) => {
      setValues((v) => {
        return {
          ...v,
          // @ts-ignore
          hex: { ...v.hex, value: theme.palette[color].main, error: "" },
        };
      });
    },
    [theme.palette]
  );

  const handleChangeCheckbox = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.checked, error: "" },
        };
      });
    },
    []
  );

  const save = React.useCallback(async () => {
    setLoading(true);
    const textError = notEmptyValidator(values.text.value);
    if (textError) {
      const newValue: FormState = { ...values };
      newValue.text.error = textError;
      setValues(newValue);
      setLoading(false);
      return undefined;
    }
    let url = TAG_URL;
    if (tag) {
      url += "/" + tag.id;
    }
    const response = await requestApi({
      method: tag ? PATCH : POST,
      path: url,
      allowError: true,
      token: token,
      body: {
        text: values.text.value,
        hex: values.hex.value,
        textColorBlack: values.textColorBlack.value,
      },
    });
    if (response.statusCode === 201 || response.statusCode === 200) {
      toastr.success(
        t("word.success"),
        t(
          response.statusCode === 200
            ? "sentence.notification.tag_updated"
            : "sentence.notification.tag_created"
        )
      );
      dispatch(set({ refreshPage: true }));
      handleClose();
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [dispatch, handleClose, t, tag, token, values]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth={true}
          autoComplete="off"
          error={!!values.text.error}
          helperText={t(values.text.error ?? "")}
          sx={{ width: "100%" }}
          required
          type="text"
          value={values.text.value}
          onChange={handleChange("text")}
          label={t("field.text")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth={true}
          autoComplete="off"
          error={!!values.hex.error}
          helperText={t(values.hex.error ?? "")}
          sx={{ width: "100%" }}
          required
          type="color"
          value={values.hex.value}
          onChange={handleChange("hex")}
          label={t("field.hex")}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.textColorBlack.value}
              onChange={handleChangeCheckbox("textColorBlack")}
            />
          }
          label={t("field.textColorBlack")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {colors.map((color, indexColor) => (
          <Chip
            key={indexColor}
            label={color}
            onClick={() => handleChangeColor(color)}
            // @ts-ignore
            color={color}
            sx={{ marginLeft: 1, marginBottom: 1 }}
          />
        ))}
      </Grid>
      {values.text.value && (
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Chip
            label={values.text.value}
            size="small"
            sx={{
              cursor: "pointer",
              backgroundColor: values.hex.value,
              color: values.textColorBlack.value ? "black" : "white",
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <LoadingButton variant="contained" loading={loading} onClick={save}>
          {t("word.save")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
});

export default TagFormComponent;
