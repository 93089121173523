import {
  CartFArticleInterface,
  CartInterface,
} from "../interfaces/CartInterface";
import { getMaxQuantityCanBuy, getQuantityDispo } from "./FArticleHelper";
import { FArticleSmallInterface } from "../interfaces/FArticleInterface";
import {
  ACOMPTE_STOCK_MANUEL,
  DEFAULT_PERCENT_ACOMPTE,
  FAMILLE_SERVICE,
} from "../utils/FArticleUtils";
import React from "react";
import {
  GetPostOrdersInterface,
  OrderFArticleInterface,
  OrderInterface,
  PostOrderInterface,
} from "../interfaces/OrderInterface";
import { UserInterface, UserPropInterface } from "../interfaces/UserInterface";
import { isUserBonDeCommande, isUserEnCompte } from "./UserHelper";

export const getCartFArticleAmount = (
  cartFArticle: CartFArticleInterface,
  withQuantity: boolean
): number => {
  let amount = cartFArticle.fArticle.priceDto.priceTtc;
  for (const cartFArticleOption of cartFArticle.cartFArticleOptions) {
    amount += cartFArticleOption.fArticle.priceDto.priceTtc;
  }
  if (withQuantity) {
    return amount * cartFArticle.quantity;
  }
  return amount;
};

export const getCartAmount = (cart: CartInterface): number => {
  return cart.cartFArticles.reduce((amount, cartFArticle) => {
    return amount + getCartFArticleAmount(cartFArticle, true);
  }, 0);
};

export const getOrderAllFArticleAvailable = (
  order: OrderInterface | undefined
): boolean => {
  if (order === undefined) {
    return false;
  }
  return (
    order.orderFArticles.find((cartFArticle) => {
      return cartFArticle.quantity > getQuantityDispo(cartFArticle.fArticle);
    }) === undefined
  );
};

export const getCanChooseOrderType = (
  order: OrderInterface | undefined,
  user: UserInterface | undefined,
  isDevis: boolean
): boolean => {
  if (order === undefined || isDevis) {
    return false;
  }
  return (
    !isUserBonDeCommande(user) &&
    !getOrderAllFArticleAvailable(order) &&
    order.orderFArticles.find((cartFArticle) => {
      return getQuantityDispo(cartFArticle.fArticle) >= 1;
    }) !== undefined
  );
};

export const hasLicense = (order: OrderInterface | undefined): boolean => {
  if (order === undefined) {
    return false;
  }
  return (
    order.orderFArticles.find((x) => x.fArticle.faCodefamille === "LO") !==
    undefined
  );
};

export const getRequireAcompteOrder = (
  order: OrderInterface | undefined,
  user: UserInterface | undefined,
  userProp: UserPropInterface | undefined,
  isDevis: boolean
): boolean => {
  return getAcompteOrder(order, user, userProp, isDevis) > 0;
};

export const getAcompteFArticle = (
  fArticle: FArticleSmallInterface,
  quantity: number,
  user: UserInterface | undefined,
  userProp: UserPropInterface | undefined,
  acompteValue: number | null = null,
  realQuantity: boolean = false,
  ignoreEnCompte: boolean = false
): number => {
  let initAcompteValue = acompteValue;
  if (!ignoreEnCompte && isUserEnCompte(user)) {
    return 0;
  }
  if (isUserEnCompte(user)) {
    acompteValue = 1;
  } else {
    if (acompteValue === null) {
      acompteValue = DEFAULT_PERCENT_ACOMPTE;
      if (fArticle.fArticleProp.percentAcompte) {
        acompteValue = fArticle.fArticleProp.percentAcompte / 100;
      }
    }
  }
  if (
    initAcompteValue === null &&
    userProp?.percentAcompte !== undefined &&
    userProp.percentAcompte !== DEFAULT_PERCENT_ACOMPTE
  ) {
    acompteValue = userProp.percentAcompte / 100;
  }
  if (
    fArticle.fArticleProp.hasAcompte ||
    ACOMPTE_STOCK_MANUEL.includes(Number(fArticle.stockManuel)) ||
    realQuantity
  ) {
    if (realQuantity) {
      return Math.round(fArticle.priceDto.priceTtc * acompteValue) * quantity;
    }
    let diff = quantity - getQuantityDispo(fArticle);
    if (diff < 0) {
      return 0;
    }
    return Math.round(fArticle.priceDto.priceTtc * acompteValue) * diff;
  }
  return 0;
};

export const getAcompteOrder = (
  order: OrderInterface | undefined,
  user: UserInterface | undefined,
  userProp: UserPropInterface | undefined,
  isDevis: boolean,
  realQuantity: boolean = false,
  acompteValue: number | null = null,
  ignoreEnCompte: boolean = false
): number => {
  if (order === undefined || isDevis) {
    return 0;
  }
  return order.orderFArticles.reduce(
    (total: number, orderFArticle: OrderFArticleInterface) => {
      return (
        total +
        getAcompteFArticle(
          orderFArticle.fArticle,
          orderFArticle.quantity,
          user,
          userProp,
          acompteValue,
          realQuantity,
          ignoreEnCompte
        )
      );
    },
    0
  );
};

export const getAcomptePostOrder = (
  postOrder: GetPostOrdersInterface,
  user: UserInterface | undefined,
  userProp: UserPropInterface | undefined,
  realQuantity: boolean = false,
  ignoreEnCompte: boolean = false
): number => {
  return postOrder.orderFArticles.reduce(
    (total: number, orderFArticle: OrderFArticleInterface) => {
      return (
        total +
        getAcompteFArticle(
          orderFArticle.fArticle,
          orderFArticle.quantity,
          user,
          userProp,
          null,
          realQuantity,
          ignoreEnCompte
        )
      );
    },
    0
  );
};

const addArticleOrder = (
  fArticle: FArticleSmallInterface,
  orderFArticles: OrderFArticleInterface[],
  quantity: number
) => {
  const orderFArticle = orderFArticles.find(
    (orderFArticle) => orderFArticle.fArticle.arRef === fArticle.arRef
  );
  if (orderFArticle === undefined) {
    let quantityRef = React.createRef();
    orderFArticles.push({
      fArticle: fArticle,
      quantity: quantity,
      quantityRef: quantityRef,
    });
  } else {
    orderFArticle.quantity += quantity;
  }
};

export const getOrderWithCart = (
  cart: CartInterface | undefined,
  orderType: string | undefined
): OrderInterface | undefined => {
  if (cart === undefined) {
    return undefined;
  }
  const fArticles: OrderFArticleInterface[] = [];
  for (const cartFArticle of cart.cartFArticles) {
    const quantity = cartFArticle.quantity;
    addArticleOrder(cartFArticle.fArticle, fArticles, quantity);
    if (cartFArticle.cartFArticleOptions !== undefined) {
      for (const cartFArticleOption of cartFArticle.cartFArticleOptions) {
        addArticleOrder(cartFArticleOption.fArticle, fArticles, quantity);
      }
    }
  }
  return {
    orderType: orderType ?? "",
    orderFArticles: fArticles,
  };
};

export const getPostOrderWeight = (postOrder: PostOrderInterface): number => {
  let weight = 0;
  for (const orderFArticle of postOrder.orderFArticles) {
    weight +=
      parseFloat(orderFArticle.fArticle.arPoidsnet) * orderFArticle.quantity;
  }
  return weight;
};

export const getUnavailableArticles = (
  order: OrderInterface,
  isAdmin: boolean | undefined
) => {
  const result: string[] = [];
  for (const orderFArticle of order.orderFArticles) {
    const maxQuantity = getMaxQuantityCanBuy(
      orderFArticle.fArticle,
      isAdmin,
      undefined,
      false
    );
    if (
      orderFArticle.fArticle.faCodefamille !== FAMILLE_SERVICE &&
      maxQuantity !== undefined &&
      orderFArticle.quantity > maxQuantity
    ) {
      result.push(orderFArticle.fArticle.arRef);
    }
  }
  return result;
};
