import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import {
  FilterCategoryInterface,
  FilterValueInterface,
} from "../../../interfaces/CategoryInterface";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { TYPE_RADIO } from "../../../utils/FilterUtils";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import { getAllFilterValues } from "../../../helpers/FilterHelper";
import { styled, Tooltip } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FilterFilterImageInterface } from "../../../interfaces/FilterInterface";
import { getUrlFilterImageImage } from "../../../helpers/FileHelper";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

interface State {
  filterCategory: FilterCategoryInterface;
  apply: Function;
  applyHidden: Function;
  setNbSelected: Function;
  index: number;
  filterInited: Function;
  hidden: boolean;
  aggregations: any;
}

interface State2 {
  filterValues: FilterValueInterface[];
  type: string;
  checked: number[];
  handleChange: Function;
  isChild?: boolean;
  aggregations: any;
}

interface State3 {
  checked: number[];
  filterValueId: number;
  singleHandleChange: Function;
  name: string;
  hover: boolean;
  filterImage: FilterFilterImageInterface | null | undefined;
  type: string;
  isChild: boolean;
  aggregations: any;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
  },
}));

const SingleFilterValueComponent = React.memo(
  forwardRef(
    (
      {
        checked,
        filterValueId,
        singleHandleChange,
        name,
        type,
        isChild,
        hover,
        filterImage,
        aggregations,
      }: State3,
      ref
    ) => {
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const [open, setOpen] = React.useState(false);
      const [nbProducts, setNbProducts] = React.useState<number | null>(null);

      const handleClose = () => {
        setOpen(false);
      };

      const handleOpen = () => {
        setOpen(true);
      };

      useEffect(() => {
        if (!aggregations?.filterValuesStats?.buckets) {
          return;
        }
        setNbProducts(
          aggregations.filterValuesStats.buckets.find(
            (bucket: any) => bucket.key === filterValueId
          )?.doc_count ?? 0
        );
      }, [aggregations, filterValueId]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <FormGroup sx={{ position: "relative", width: "100%" }}>
          {isChild && (
            <Box
              sx={{
                position: "absolute",
                width: "5px",
                top: "13px",
                borderTop: "1px solid gray",
                left: "-8px",
              }}
            />
          )}
          <FormControlLabel
            sx={{ width: "100%" }}
            control={
              <Checkbox
                {...(type === TYPE_RADIO && {
                  icon: <RadioButtonUncheckedIcon />,
                  checkedIcon: <RadioButtonCheckedIcon />,
                })}
                sx={{ ...(!isSmall && { paddingY: 0 }) }}
                checked={checked.includes(filterValueId)}
                onChange={(e) => singleHandleChange(e, filterValueId)}
              />
            }
            label={
              <Typography
                component="span"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {hover && !!filterImage ? (
                  <>
                    {isSmall ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography component="span">{name}</Typography>
                        {nbProducts !== null ? (
                          <Typography
                            variant="caption"
                            sx={{ color: theme.palette.primary.main }}
                          >
                            [{nbProducts}]
                          </Typography>
                        ) : (
                          ""
                        )}
                        <img
                          src={getUrlFilterImageImage(filterImage)}
                          style={{
                            width: "100%",
                            maxWidth: "80px",
                            maxHeight: "80px",
                            margin: "auto",
                            display: "block",
                          }}
                          alt={filterImage.name}
                          loading="lazy"
                        />
                      </Box>
                    ) : (
                      <LightTooltip
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        placement="right"
                        title={
                          <img
                            src={getUrlFilterImageImage(filterImage)}
                            style={{
                              width: "100%",
                              maxWidth: "120px",
                              maxHeight: "120px",
                              margin: "auto",
                              display: "block",
                            }}
                            alt={filterImage.name}
                            loading="lazy"
                          />
                        }
                      >
                        <Badge
                          color="primary"
                          variant="dot"
                          sx={{ width: "100%" }}
                        >
                          <Typography
                            component="span"
                            sx={{
                              width: "100%",
                              ...(open && {
                                color: theme.palette.primary.main,
                                fontWeight: "bold",
                              }),
                            }}
                          >
                            {name}
                          </Typography>
                          {nbProducts !== null ? (
                            <Typography
                              variant="caption"
                              sx={{
                                color: theme.palette.primary.main,
                                ...(open && {
                                  color: theme.palette.primary.main,
                                  fontWeight: "bold",
                                }),
                              }}
                            >
                              [{nbProducts}]
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Badge>
                      </LightTooltip>
                    )}
                  </>
                ) : (
                  <>
                    <Typography component="span">{name}</Typography>
                    {nbProducts !== null ? (
                      <Typography
                        variant="caption"
                        sx={{ color: theme.palette.primary.main }}
                      >
                        [{nbProducts}]
                      </Typography>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Typography>
            }
          />
        </FormGroup>
      );
    }
  )
);

const GroupFilterValueComponent = React.memo(
  forwardRef(
    (
      {
        filterValues,
        type,
        checked,
        handleChange,
        isChild,
        aggregations,
      }: State2,
      ref
    ) => {
      const getFilterValuesIdsWithChildrenIds = React.useCallback(
        (data: FilterValueInterface[], result: number[] = []): number[] => {
          for (const f of data) {
            result.push(f.id);
            if (f.children && f.children.length > 0) {
              result = getFilterValuesIdsWithChildrenIds(f.children);
            }
          }
          return result;
        },
        []
      );
      const [expanded, setExpanded] = React.useState<boolean[]>(
        getFilterValuesIdsWithChildrenIds(filterValues).map((x) =>
          checked.includes(x)
        )
      );

      const handleChangeAccordion = (index: number) => (e: any) => {
        setExpanded((x) => {
          x[index] = !x[index];
          return [...x];
        });
      };

      const singleHandleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, filterValueId: number) => {
          const index = filterValues.findIndex((x) => x.id === filterValueId);
          if (index >= 0) {
            if (event.target.checked) {
              setExpanded((x) => {
                if (x[index] === event.target.checked) {
                  return x;
                }
                x[index] = event.target.checked;
                return [...x];
              });
            }
          }
          handleChange(event, filterValueId);
        },
        [filterValues, handleChange]
      );

      return (
        <>
          {filterValues.map(
            (filterValue, indexFilterValue) =>
              filterValue.show && (
                <React.Fragment key={indexFilterValue}>
                  {filterValue.children &&
                  filterValue.children.filter((child) => child.show).length >
                    0 ? (
                    <Accordion
                      expanded={expanded[indexFilterValue] ?? false}
                      elevation={0}
                      sx={{ margin: "0 !important" }}
                    >
                      <AccordionSummary
                        sx={{ paddingX: 0, minHeight: "auto !important" }}
                        className="margin-y-0"
                        expandIcon={
                          <ExpandMoreIcon
                            onClick={handleChangeAccordion(indexFilterValue)}
                          />
                        }
                        aria-controls={filterValue.id.toString()}
                        id={filterValue.id.toString()}
                      >
                        <SingleFilterValueComponent
                          aggregations={aggregations}
                          checked={checked}
                          filterValueId={filterValue.id}
                          singleHandleChange={singleHandleChange}
                          name={filterValue.name}
                          type={type}
                          isChild={!!isChild}
                          hover={filterValue.hover}
                          filterImage={filterValue.filterImage}
                        />
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          padding: 0,
                          marginLeft: 1,
                          paddingLeft: 1,
                          borderLeft: "gray solid 1px",
                          position: "relative",
                        }}
                      >
                        <GroupFilterValueComponent
                          filterValues={filterValue.children}
                          type={type}
                          checked={checked}
                          handleChange={handleChange}
                          isChild={true}
                          aggregations={aggregations}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <SingleFilterValueComponent
                      checked={checked}
                      filterValueId={filterValue.id}
                      singleHandleChange={singleHandleChange}
                      name={filterValue.name}
                      type={type}
                      isChild={!!isChild}
                      hover={filterValue.hover}
                      filterImage={filterValue.filterImage}
                      aggregations={aggregations}
                    />
                  )}
                </React.Fragment>
              )
          )}
        </>
      );
    }
  )
);

const FilterValueComponent = React.memo(
  forwardRef(
    (
      {
        filterCategory,
        apply,
        applyHidden,
        setNbSelected,
        index,
        filterInited,
        hidden,
        aggregations,
      }: State,
      ref
    ) => {
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const [searchParams] = useSearchParams();

      const getChecked = React.useCallback((): number[] => {
        if (hidden) {
          return [];
        }
        const searchParamsObject = searchParamToObject(searchParams);
        let values: number[] = [];
        const allFilterValues = getAllFilterValues(
          filterCategory.filter.filterValues
        );
        if (searchParamsObject.hasOwnProperty("filterValues[]")) {
          for (let filterValue of searchParamsObject["filterValues[]"]) {
            filterValue = JSON.parse(filterValue);
            filterValue.values = filterValue.values.filter((id: number) =>
              allFilterValues.includes(id)
            );
            values = [...values, ...filterValue.values];
          }
        }
        return values;
      }, [filterCategory.filter.filterValues, hidden, searchParams]);

      const [checked, setChecked] = React.useState<number[]>(getChecked());

      const getFilterValue = React.useCallback(
        (
          filterValues: FilterValueInterface[],
          filterValueId: number,
          parentIds: number[] = []
        ): [FilterValueInterface | undefined, number[]] => {
          for (const filterValue of filterValues) {
            if (filterValue.id === filterValueId) {
              return [filterValue, parentIds];
            }
            if (filterValue.children && filterValue.children.length > 0) {
              const [resultFilterValue, resultParentIds] = getFilterValue(
                filterValue.children,
                filterValueId,
                [...parentIds, filterValue.id]
              );
              if (resultFilterValue) {
                return [resultFilterValue, resultParentIds];
              }
            }
          }
          return [undefined, parentIds];
        },
        []
      );

      const getChildrenIds = React.useCallback(
        (filterValues: FilterValueInterface[]): number[] => {
          let result: number[] = [];

          for (const filterValue of filterValues) {
            result.push(filterValue.id);
            if (filterValue.children && filterValue.children.length > 0) {
              result = [...result, ...getChildrenIds(filterValue.children)];
            }
          }
          return result;
        },
        []
      );

      const getFilterValueToUncheck = React.useCallback(
        (filterValueId: number) => {
          const [filterValue, parentIds] = getFilterValue(
            filterCategory.filter.filterValues,
            filterValueId
          );
          if (!filterValue) {
            return [];
          }
          let childrenIds = filterValue.children
            ? getChildrenIds(filterValue.children)
            : [];
          return [...childrenIds, ...parentIds];
        },
        [filterCategory.filter.filterValues, getChildrenIds, getFilterValue]
      );

      const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, filterValueId: number) => {
          setChecked((x) => {
            const inArray = x.includes(filterValueId);
            if (filterCategory.filter.type === TYPE_RADIO) {
              x = [];
            }
            if (inArray) {
              x = x.filter((y) => y !== filterValueId);
            } else {
              x.push(filterValueId);
            }
            const filterValueToUncheck = getFilterValueToUncheck(filterValueId);
            return [...x].filter((x) => !filterValueToUncheck.includes(x));
          });
        },
        [filterCategory.filter.type, getFilterValueToUncheck]
      );

      const onCheckedChange = React.useCallback(() => {
        setNbSelected(checked.filter(Boolean).length);
        if (!isSmall) {
          apply();
        } else {
          applyHidden();
        }
      }, [apply, applyHidden, checked, isSmall, setNbSelected]);

      React.useEffect(() => {
        onCheckedChange();
      }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setChecked(getChecked());
      }, [hidden]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        filterInited(index);
      }, []); // eslint-disable-line react-hooks/exhaustive-deps

      useImperativeHandle(ref, () => ({
        getValue() {
          if (checked.length > 0) {
            return {
              "filterValues[]": [
                JSON.stringify({
                  condition: filterCategory.filter.condition,
                  values: checked,
                }),
              ],
            };
          }
          return {};
        },
      }));

      return (
        <>
          <GroupFilterValueComponent
            filterValues={filterCategory.filter.filterValues}
            type={filterCategory.filter.type}
            checked={checked}
            handleChange={handleChange}
            aggregations={aggregations}
          />
        </>
      );
    }
  )
);

export default FilterValueComponent;
