import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleFileInterface,
  FArticleInfoInterface,
  FArticleInterface,
} from "../../../interfaces/FArticleInterface";
import { Grid, Link, ListItem, TextField, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import urlValidator from "../../../helpers/validator/UrlValidator";
import { requestApi } from "../../../helpers/RequestApi";
import { GET, POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL, FILE_CONTENT } from "../../../utils/UrlsUtils";
import ArticleIcon from "@mui/icons-material/Article";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import { InputInterface } from "../../../interfaces/InputInterface";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import { TYPE_LINK } from "../../../utils/FArticleUtils";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";

interface State {
  fArticle: FArticleInterface | undefined;
  edit: boolean;
  setFArticle: Function;
}

interface State2 {
  fArticleInfo: FArticleInfoInterface | undefined;
  edit: boolean;
  index: number;
  remove: Function;
}

interface State3 {
  fArticleFile: FArticleFileInterface | undefined;
  edit: boolean;
  index: number;
  remove: Function;
}

interface FormStateFArticleInfo {
  label: InputInterface;
  value: InputInterface;
}

interface FormStateFArticleFile {
  label: InputInterface;
  file?: File;
}

const FArticleInfoComponent = React.memo(
  React.forwardRef(({ fArticleInfo, edit, index, remove }: State2, ref) => {
    const { t } = useTranslation();
    const getDefaultValues = React.useCallback((): FormStateFArticleInfo => {
      return {
        label: {
          value: fArticleInfo?.label ?? "",
          error: "",
        },
        value: {
          value: fArticleInfo?.value ?? "",
          error: "",
        },
      };
    }, [fArticleInfo?.label, fArticleInfo?.value]);
    const [values, setValues] = React.useState<FormStateFArticleInfo>(
      getDefaultValues()
    );

    const handleChange = React.useCallback(
      (prop: keyof FormStateFArticleInfo) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      const labelError = notEmptyValidator(values.label.value);
      const valueError = notEmptyValidator(values.value.value);
      if (labelError || valueError) {
        setValues((v) => {
          if (labelError) {
            v.label.error = labelError;
          }
          if (valueError) {
            v.value.error = valueError;
          }
          return { ...v };
        });
        return undefined;
      }
      return {
        label: values.label.value,
        value: values.value.value,
        type: TYPE_LINK,
      };
    }, [values.label.value, values.value.value]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [fArticleInfo]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {edit ? (
          <>
            {fArticleInfo && (
              <Box
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <Grid container spacing={1} sx={{ maxHeight: "inherit" }}>
                  <Grid item xs={12} md={5}>
                    <label
                      htmlFor={"label-fArticle-info"}
                      style={{ display: "none" }}
                    >
                      {t("word.label")}
                    </label>
                    <TextField
                      autoComplete="label-fArticle-info"
                      name={"label-fArticle-info"}
                      value={values.label.value}
                      sx={{ width: "100%" }}
                      type="text"
                      label={t("word.label")}
                      error={!!values.label.error}
                      helperText={t(values.label.error ?? "")}
                      onChange={handleChange("label")}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      autoComplete="off"
                      value={values.value.value}
                      sx={{ width: "100%" }}
                      type="text"
                      label={t("word.value")}
                      error={!!values.value.error}
                      helperText={t(values.value.error ?? "")}
                      onChange={handleChange("value")}
                    />
                  </Grid>
                </Grid>
                <IconButton onClick={() => remove(index)}>
                  <CancelIcon />
                </IconButton>
              </Box>
            )}
          </>
        ) : (
          <ListItem disablePadding sx={{ display: "block" }}>
            <Typography sx={{ width: "100%" }}>
              {urlValidator(fArticleInfo?.value ?? "") ? (
                <a href={fArticleInfo?.value} target="_blank" rel="noreferrer">
                  {fArticleInfo?.label}
                </a>
              ) : (
                <>
                  <Typography component="span" style={{ color: grey[500] }}>
                    {fArticleInfo?.label + ": "}
                  </Typography>
                  <Typography component="span">
                    {fArticleInfo?.value}
                  </Typography>
                </>
              )}
            </Typography>
          </ListItem>
        )}
      </>
    );
  })
);

const FArticleFileComponent = React.memo(
  React.forwardRef(({ fArticleFile, edit, index, remove }: State3, ref) => {
    const { t } = useTranslation();
    const getDefaultValues = React.useCallback((): FormStateFArticleFile => {
      return {
        label: {
          value: fArticleFile?.label,
          error: "",
        },
        file: fArticleFile?.file,
      };
    }, [fArticleFile?.file, fArticleFile?.label]);
    const [values, setValues] = React.useState<FormStateFArticleFile>(
      getDefaultValues()
    );

    const handleChange = React.useCallback(
      (prop: keyof FormStateFArticleFile) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const onClick = React.useCallback(async () => {
      await requestApi({
        method: GET,
        path: FILE_CONTENT + "/fArticle/product-sheet/" + fArticleFile?.name,
        blob: true,
      });
    }, [fArticleFile?.name]);

    const getValue = React.useCallback(() => {
      const labelError = notEmptyValidator(values.label.value);
      if (labelError) {
        setValues((v) => {
          v.label.error = labelError;
          return { ...v };
        });
        return undefined;
      }
      if (fArticleFile?.file) {
        return {
          label: values.label.value,
          file: fArticleFile?.file,
        };
      }
      return {
        oldId: fArticleFile?.id,
        label: values.label.value,
        name: fArticleFile?.name,
      };
    }, [
      fArticleFile?.file,
      fArticleFile?.id,
      fArticleFile?.name,
      values.label.value,
    ]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [fArticleFile]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <ListItem disablePadding sx={{ display: "block" }}>
        {edit ? (
          <>
            {fArticleFile && (
              <Box
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <Grid container spacing={1} sx={{ maxHeight: "inherit" }}>
                  <Grid item xs={12} md={8}>
                    <TextField
                      required={true}
                      autoComplete="off"
                      value={values.label.value}
                      sx={{ width: "100%" }}
                      type="text"
                      label={t("word.label")}
                      error={!!values.label.error}
                      helperText={t(values.label.error ?? "")}
                      onChange={handleChange("label")}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {fArticleFile?.file ? (
                      <Link
                        href={URL.createObjectURL(fArticleFile.file)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography>{fArticleFile.file?.name ?? ""}</Typography>
                      </Link>
                    ) : (
                      <Link sx={{ cursor: "pointer" }} onClick={onClick}>
                        {fArticleFile?.name}
                      </Link>
                    )}
                  </Grid>
                </Grid>
                <IconButton onClick={() => remove(index)}>
                  <CancelIcon />
                </IconButton>
              </Box>
            )}
          </>
        ) : (
          <Link sx={{ cursor: "pointer" }} onClick={onClick}>
            <ArticleIcon />
            {fArticleFile?.label}
          </Link>
        )}
      </ListItem>
    );
  })
);

const FArticleInfoFileComponent = React.memo(
  React.forwardRef(({ fArticle, edit, setFArticle }: State, ref) => {
    const { t } = useTranslation();
    const [thisEdit, setThisEdit] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [fArticleInfos, setFArticleInfos] = React.useState<
      (FArticleInfoInterface | undefined)[] | undefined
    >(
      fArticle?.fArticleProp?.fArticleInfos?.map((fArticleInfo) => {
        return {
          ...fArticleInfo,
          ref: React.createRef(),
        };
      })
    );
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const [fArticleFiles, setFArticleFiles] = React.useState<
      (FArticleFileInterface | undefined)[] | undefined
    >(
      fArticle?.fArticleProp?.fArticleFiles?.map((fArticleFile) => {
        return {
          ...fArticleFile,
          ref: React.createRef(),
        };
      })
    );

    const addFArticleInfo = React.useCallback(() => {
      setFArticleInfos((x) => {
        if (!x) {
          x = [];
        }
        x.push({
          ref: React.createRef(),
          id: undefined,
          value: "",
          label: "",
          type: "A",
        });
        return [...x];
      });
    }, []);

    const removeFArticleInfo = React.useCallback((index: number) => {
      setFArticleInfos((x) => {
        if (!x) {
          return x;
        }
        x[index] = undefined;
        return [...x];
      });
    }, []);

    const removeFArticleFile = React.useCallback((index: number) => {
      setFArticleFiles((x) => {
        if (!x) {
          return x;
        }
        x[index] = undefined;
        return [...x];
      });
    }, []);

    const addFile = React.useCallback((event: any) => {
      const files = Array.from(event.target.files);
      const [file] = files;
      setFArticleFiles((x) => {
        if (!x) {
          x = [];
        }
        x.push({
          ref: React.createRef(),
          id: undefined,
          name: "",
          label: "Fiche Produit",
          // @ts-ignore
          file: file,
        });
        return [...x];
      });
    }, []);

    const getValue = React.useCallback(() => {
      const fArticleInfoValues = (fArticleInfos ?? [])
        .filter((fArticleInfo) => fArticleInfo)
        .map((fArticleInfo) => fArticleInfo?.ref.current.getValue());
      const fArticleFileValues = (fArticleFiles ?? [])
        .filter((fArticleFile) => fArticleFile)
        .map((fArticleFile) => fArticleFile?.ref.current.getValue());
      if (
        !fArticleInfoValues ||
        !fArticleFileValues ||
        fArticleInfoValues.filter((fArticleInfoValue) => !fArticleInfoValue)
          .length > 0 ||
        fArticleFileValues.filter((fArticleFileValue) => !fArticleFileValue)
          .length > 0
      ) {
        return undefined;
      }
      return {
        fArticleInfos: fArticleInfoValues,
        fArticleFiles: fArticleFileValues,
      };
    }, [fArticleFiles, fArticleInfos]);

    const changeThisEdit = React.useCallback(() => {
      setThisEdit((x) => !x);
    }, []);

    const save = React.useCallback(async () => {
      // https://stackoverflow.com/a/76333584/6824121
      document
        .querySelector(`form[name='fArticle-infos-form'] button[type='submit']`)
        // @ts-ignore
        ?.click();
      const infoFilesValues: any = getValue();
      if (!infoFilesValues) {
        return;
      }
      setLoading(true);
      const fArticleApi = new FormData();
      for (const fArticleFile of infoFilesValues.fArticleFiles) {
        if (fArticleFile.file) {
          const name = fArticleFile.file.name
            .replace(/[^A-Za-z0-9]+/g, "")
            .toLowerCase();
          fArticleFile.name = name;
          fArticleApi.append(name, fArticleFile.file);
        }
      }
      infoFilesValues.fArticleFiles = infoFilesValues.fArticleFiles.map(
        (fArticleFile: any) => {
          if (fArticleFile.file) {
            delete fArticleFile.file;
          }
          return { ...fArticleFile };
        }
      );
      fArticleApi.append(
        "json",
        JSON.stringify({
          arRef: fArticle?.arRef,
          fArticleProp: infoFilesValues,
        })
      );
      const response = await requestApi({
        method: POST,
        path: FARTICLE_URL,
        allowError: true,
        timeout: 30_000,
        token: token,
        body: fArticleApi,
        formData: true,
      });
      if (response.statusCode === 201) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.farticle_updated")
        );
        if (setFArticle) {
          setFArticle(response.content);
        }
        setThisEdit(false);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [fArticle?.arRef, getValue, setFArticle, t, token]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setFArticleInfos(
        fArticle?.fArticleProp?.fArticleInfos?.map((fArticleInfo) => {
          return {
            ...fArticleInfo,
            ref: React.createRef(),
          };
        })
      );
    }, [fArticle?.fArticleProp?.fArticleInfos, edit]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setFArticleFiles(
        fArticle?.fArticleProp?.fArticleFiles?.map((fArticleFile) => {
          return {
            ...fArticleFile,
            ref: React.createRef(),
          };
        })
      );
    }, [fArticle?.fArticleProp?.fArticleFiles, edit]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (!edit) {
        setThisEdit(false);
      }
    }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {isAdmin && (
          <>
            {!(edit || thisEdit) ? (
              <Typography component="span">
                {t("word.fArticleInfoFile")}
                <IconButton onClick={changeThisEdit}>
                  <EditIcon />
                </IconButton>
              </Typography>
            ) : (
              <>
                {!edit && (
                  <Box sx={{ textAlign: "center" }}>
                    <Tooltip title={t("word.cancel")}>
                      <IconButton onClick={changeThisEdit} disabled={loading}>
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("word.save")}>
                      <LoadingButton
                        variant="text"
                        color="inherit"
                        sx={{
                          borderRadius: "50%",
                          minWidth: "auto",
                          padding: "8px",
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                        loading={loading}
                        onClick={save}
                      >
                        <SaveIcon />
                      </LoadingButton>
                    </Tooltip>
                  </Box>
                )}
              </>
            )}
          </>
        )}
        <Box sx={{ marginBottom: 2 }}>
          {(edit || thisEdit) && (
            <Typography>{t("word.fArticleInfos")}:</Typography>
          )}
          <form
            name="fArticle-infos-form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {fArticleInfos && (
              <>
                {fArticleInfos.map((fArticleInfo, indexFArticleInfo) => (
                  <FArticleInfoComponent
                    key={indexFArticleInfo}
                    fArticleInfo={fArticleInfo}
                    ref={fArticleInfo?.ref}
                    edit={edit || thisEdit}
                    index={indexFArticleInfo}
                    remove={removeFArticleInfo}
                  />
                ))}
              </>
            )}
            <button type="submit" style={{ display: "none" }}>
              Submit
            </button>
          </form>

          {(edit || thisEdit) && (
            <Box sx={{ textAlign: "center" }}>
              <IconButton onClick={addFArticleInfo}>
                <AddIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          {(edit || thisEdit) && (
            <Typography>{t("word.fArticleFiles")}:</Typography>
          )}
          {fArticleFiles &&
            fArticleFiles.map((fArticleFile, indexFArticleFile) => (
              <FArticleFileComponent
                key={indexFArticleFile}
                fArticleFile={fArticleFile}
                ref={fArticleFile?.ref}
                edit={edit || thisEdit}
                index={indexFArticleFile}
                remove={removeFArticleFile}
              />
            ))}
          {(edit || thisEdit) && (
            <Box sx={{ textAlign: "center" }}>
              <Button variant="contained" component="label">
                {t("word.addFile")}
                <input
                  accept="application/pdf"
                  type="file"
                  hidden
                  onChange={addFile}
                />
              </Button>
            </Box>
          )}
        </Box>
      </>
    );
  })
);

export default FArticleInfoFileComponent;
