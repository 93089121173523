import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import List from "@mui/material/List";
import BrandComponent from "./BrandComponent";
import { DriverInterface } from "../../../interfaces/FArticleInterface";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  DialogProps,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DialogContent from "@mui/material/DialogContent";
import BrandFormComponent from "./BrandFormComponent";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";

const BrandsComponent: React.FC = React.memo(() => {
  const drivers = useAppSelector(
    (state: RootState) => state.globalState.drivers
  );
  const theme = useTheme();
  const getDriverWithFArticles = React.useCallback((): DriverInterface[] => {
    const result = drivers?.filter((x) => x.nbFArticle > 0) ?? [];
    result.sort((a, b) => {
      if (a.images === null && b.images) {
        return -1;
      }
      if (b.images === null && a.images) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return result;
  }, [drivers]);
  const [driverWithFArticles, setDriverWithFArticles] = React.useState<
    DriverInterface[]
  >(getDriverWithFArticles());
  const [open, setOpen] = React.useState<boolean>(false);
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [driverNoFArticles, setDriverNoFArticles] = React.useState<
    DriverInterface[]
  >(drivers?.filter((x) => x.nbFArticle === 0) ?? []);
  const { t } = useTranslation();

  const handleOpen = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  React.useEffect(() => {
    setDriverWithFArticles(getDriverWithFArticles());
    setDriverNoFArticles(drivers?.filter((x) => x.nbFArticle === 0) ?? []);
  }, [drivers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        fullWidth={true}
        fullScreen={fullScreen}
        onClose={handleClose}
        open={open}
      >
        <DialogContent>
          <BrandFormComponent driver={undefined} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
      <Accordion defaultExpanded={false} TransitionProps={{ timeout: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              color: "white",
              marginTop: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("word.driverNoFArticles")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {driverNoFArticles.map((driver, indexDriver) => (
              <BrandComponent
                key={indexDriver}
                driver={driver}
                last={indexDriver === driverNoFArticles.length - 1}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true} TransitionProps={{ timeout: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              color: "white",
              marginTop: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("word.driverWithFArticles")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {driverWithFArticles.map((driver, indexDriver) => (
              <BrandComponent
                key={indexDriver}
                driver={driver}
                last={indexDriver === driverWithFArticles.length - 1}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <SpeedDial
        ariaLabel="Create brand"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        open={true}
      >
        <SpeedDialAction
          icon={<AddIcon />}
          onClick={handleOpen}
          tooltipTitle={t("word.createBrand")}
        />
      </SpeedDial>
    </>
  );
});

export default BrandsComponent;
