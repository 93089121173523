import React, { useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import {
  getStyleInline,
  STYLE_SUPER_COMPACT_INLINE,
} from "../../utils/FArticleUtils";

interface State {
  title: string | undefined;
  subTitle: string | undefined;
  first: boolean;
  inline: boolean;
}

const FilterReferenceHeaderComponent: React.FC<State> = React.memo(
  ({ title, subTitle, first, inline }) => {
    const ref: any = useRef();
    const [styleInline] = React.useState<string>(() => {
      return getStyleInline();
    });

    React.useEffect(() => {
      let observer: IntersectionObserver | null = null;
      if (title) {
        const el = ref.current;
        if (el) {
          observer = new IntersectionObserver(
            ([e]) => {
              e.target.classList.toggle(
                "is-pinned-radius",
                e.intersectionRatio < 1
              );
              if (first) {
                e.target.classList.toggle(
                  "is-pinned-shadow",
                  e.intersectionRatio < 1
                );
              }
            },
            { threshold: [1] }
          );
          observer.observe(el);
        }
      }
      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }, [title]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
      <>
        <Box
          ref={ref}
          sx={{
            ...(title &&
              title !== "" && {
                position: "sticky",
                top: -17,
                textAlign: "center",
                zIndex: 2,
                backgroundColor: grey[300],
                marginY:
                  inline && styleInline === STYLE_SUPER_COMPACT_INLINE ? 2 : 4,
                marginX: 1,
              }),
          }}
        >
          {title && title !== "" && (
            <Typography component="span" variant="h5" sx={{ padding: 1 }}>
              {title}
            </Typography>
          )}
        </Box>
        {subTitle && (
          <Box
            sx={{
              textAlign: "center",
              marginY: 0.5,
            }}
          >
            <Typography
              variant="caption"
              sx={{ padding: 0.5, backgroundColor: grey[300] }}
            >
              {subTitle}
            </Typography>
          </Box>
        )}
      </>
    );
  }
);

export default FilterReferenceHeaderComponent;
