import React, { ChangeEvent, useRef } from "react";
import {
  FilterFilterImageInterface,
  FilterFilterValueFormInterface,
  FilterFilterValueInterface,
} from "../../../../../../interfaces/FilterInterface";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CircularProgress,
  DialogProps,
  Grid,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { requestApi } from "../../../../../../helpers/RequestApi";
import { GET, PATCH, POST } from "../../../../../../utils/MethodUtils";
import { FILTER_IMAGE_URL } from "../../../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { RootState } from "../../../../../../app/store";
import { set, updateFilterImage } from "../../../../../../app/globalSlice";
import Box from "@mui/material/Box";
import FileInput from "../../../../file/FileInputComponent";
import { LoadingButton } from "@mui/lab";
import { getUrlFilterImageImage } from "../../../../../../helpers/FileHelper";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteFilterImageDialogComponent from "../DeleteFilterImageDialogComponent";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FormStateFilterForm } from "./FilterFormComponent";
import EditIcon from "@mui/icons-material/Edit";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import notEmptyValidator from "../../../../../../helpers/validator/NotEmptyValidator";

const slugify = require("slugify");
const stringSimilarity = require("string-similarity");

interface State {
  filterValue: FilterFilterValueInterface;
  open: boolean;
  handleClose: any;
  setFilterImage: Function;
  setValues: Function;
  refImage: any;
}

interface State2 {
  filterValue: FilterFilterValueInterface;
  filterImage: FilterFilterImageInterface;
  selectImage: Function;
  deleteFilterImageRef: any;
}

interface FormState {
  name: InputInterface;
}

const SingleFilterFormImageDialogComponent: React.FC<State2> = React.memo(
  ({ filterValue, filterImage, selectImage, deleteFilterImageRef }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [edit, setEdit] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        name: {
          value: filterImage.name,
          error: "",
        },
      };
    }, [filterImage.name]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const thisSelectImage = React.useCallback(
      (filterImage: FilterFilterImageInterface) => {
        if (edit) {
          return;
        }
        selectImage(filterImage);
      },
      [edit, selectImage]
    );

    const handleEdit = React.useCallback(() => {
      setEdit((x) => !x);
    }, []);

    const save = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: PATCH,
        path: FILTER_IMAGE_URL + "/" + filterImage.id,
        allowError: true,
        token: token,
        body: {
          name: values.name.value,
        },
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.filter_image_updated")
        );
        setEdit(false);
        dispatch(updateFilterImage(response.content));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setLoading(false);
    }, [dispatch, filterImage.id, t, token, values.name.value]);

    const openDeleteFilterImage = React.useCallback(
      (thisFilterImage: FilterFilterImageInterface) => {
        deleteFilterImageRef.current.handleOpen(thisFilterImage);
      },
      [deleteFilterImageRef]
    );

    return (
      <Grid item xs={12} md={2} sx={{ position: "relative" }}>
        <Card
          variant="outlined"
          sx={{
            textAlign: "right",
            ...(filterValue?.filterImage?.id === filterImage.id && {
              backgroundColor: theme.palette.primary.light,
            }),
          }}
        >
          <IconButton onClick={() => openDeleteFilterImage(filterImage)}>
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
          <CardContent
            sx={{
              textAlign: "center",
              paddingTop: 0,
              cursor: "pointer",
            }}
            onClick={() => thisSelectImage(filterImage)}
          >
            <img
              src={getUrlFilterImageImage(filterImage)}
              alt={filterImage.name}
              loading="lazy"
              style={{ maxHeight: 40 }}
            />
            {edit ? (
              <>
                <TextField
                  fullWidth={true}
                  autoComplete="off"
                  error={!!values.name.error}
                  helperText={t(values.name.error ?? "")}
                  sx={{ width: "100%" }}
                  required
                  type="text"
                  value={values.name.value}
                  onChange={handleChange("name")}
                  label={t("field.name")}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={handleEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </Box>
              </>
            ) : (
              <Typography sx={{ textAlign: "center" }}>
                {filterImage.name}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  }
);

const FilterFormImageDialogComponent: React.FC<State> = React.memo(
  ({ filterValue, open, handleClose, setFilterImage, setValues, refImage }) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xl");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();
    const filterImages = useAppSelector(
      (state: RootState) => state.globalState.filterImages
    );
    const deleteFilterImageRef: any = useRef();
    const [filterImageValues, setFilterImageValues] = React.useState<FormState>(
      {
        name: {
          value: "",
          error: "",
        },
      }
    );
    const [search, setSearch] = React.useState<string>("");
    const getFilterImages = React.useCallback(() => {
      if (filterImages) {
        const newFilterImages = filterImages.filter((x) => {
          if (search) {
            const nameSlug = slugify(x.name, { lower: true });
            const searchSlug = slugify(search, { lower: true });
            return (
              nameSlug.includes(searchSlug) ||
              stringSimilarity.compareTwoStrings(nameSlug, searchSlug) >= 0.7
            );
          }
          return true;
        });
        newFilterImages.sort((a, b) => a.name.localeCompare(b.name));
        return newFilterImages;
      }
      return undefined;
    }, [filterImages, search]);
    const [thisFilterImages, setThisFilterImages] = React.useState<
      FilterFilterImageInterface[] | undefined
    >(getFilterImages());
    const [loading, setLoading] = React.useState<boolean>(!thisFilterImages);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();
    const fileRef: any = useRef();
    const [hasFile, setHasFile] = React.useState<boolean>(false);
    const [loadingAddFile, setLoadingAddFile] = React.useState<boolean>(false);
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    const getImages = React.useCallback(async () => {
      if (!open || !!thisFilterImages) {
        setLoading(false);
        return;
      }
      setLoading(true);
      const response = await requestApi({
        method: GET,
        path: FILTER_IMAGE_URL,
        allowError: false,
        token: token,
      });
      if (response.statusCode !== 200) {
        toastr.error(t("word.error"), t("error.tryAgain"));
      } else {
        dispatch(set({ filterImages: response.content }));
      }
      setLoading(false);
    }, [dispatch, open, t, thisFilterImages, token]);

    const selectImage = React.useCallback(
      (filterImage: FilterFilterImageInterface) => {
        setValues((x: FormStateFilterForm) => {
          const thisFilterValue = x.filterValues.value.find(
            (y: FilterFilterValueFormInterface) => y.refImage === refImage
          );
          thisFilterValue.filterImage = filterImage;
          return { ...x };
        });
        setSearch("");
        setFilterImage(filterImage);
        handleClose();
      },
      [handleClose, refImage, setFilterImage, setValues]
    );

    const saveFile = React.useCallback(async () => {
      setLoadingAddFile(true);
      const fileImage = fileRef.current.getValue();
      if (fileImage) {
        const name = filterImageValues.name.value.trim();
        const nameError = notEmptyValidator(name);
        if (nameError) {
          const newValue: FormState = { ...filterImageValues };
          if (nameError) {
            filterImageValues.name.error = nameError;
          }
          setFilterImageValues(newValue);
          setLoadingAddFile(false);
          return undefined;
        }
        const filterImageApi = new FormData();
        filterImageApi.append("file", fileImage);
        filterImageApi.append("name", name);
        const response = await requestApi({
          method: POST,
          path: FILTER_IMAGE_URL,
          allowError: true,
          token: token,
          body: filterImageApi,
          formData: true,
        });
        fileRef.current.resetFile();
        let newFilterImages: FilterFilterImageInterface[] = [response.content];
        if (thisFilterImages) {
          newFilterImages = [...thisFilterImages, response.content];
        }
        dispatch(
          set({
            filterImages: newFilterImages,
          })
        );
        selectImage(response.content);
      }
      setLoadingAddFile(false);
    }, [dispatch, filterImageValues, selectImage, thisFilterImages, token]);

    const onSearchChange = React.useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(e.target.value as string);
      },
      []
    );

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setFilterImageValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    React.useEffect(() => {
      getImages();
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setThisFilterImages(getFilterImages());
    }, [filterImages, search]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <DeleteFilterImageDialogComponent ref={deleteFilterImageRef} />
        <Dialog
          fullWidth
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleClose}
          open={open}
        >
          {isSmall && (
            <IconButton
              sx={{ justifyContent: "flex-start" }}
              onClick={handleClose}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          <DialogContent>
            <FileInput ref={fileRef} setHasFile={setHasFile} />
            {hasFile && (
              <>
                <TextField
                  fullWidth={true}
                  autoComplete="off"
                  error={!!filterImageValues.name.error}
                  helperText={t(filterImageValues.name.error ?? "")}
                  sx={{ width: "100%" }}
                  required
                  type="text"
                  value={filterImageValues.name.value}
                  onChange={handleChange("name")}
                  label={t("field.name")}
                />
                <Box sx={{ marginY: 1, textAlign: "right" }}>
                  <LoadingButton
                    variant="contained"
                    loading={loadingAddFile}
                    onClick={saveFile}
                  >
                    {t("word.save")}
                  </LoadingButton>
                </Box>
              </>
            )}
            <Divider />
            {loading ? (
              <Box sx={{ margin: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Typography>{t("sentence.selectImage")}</Typography>
                {thisFilterImages && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth={true}
                        autoComplete="off"
                        sx={{ width: "100%" }}
                        type="text"
                        label={t("word.search")}
                        onChange={onSearchChange}
                        value={search}
                      />
                    </Grid>
                    {thisFilterImages.map((filterImage, indexFilterImage) => (
                      <SingleFilterFormImageDialogComponent
                        key={indexFilterImage}
                        filterImage={filterImage}
                        selectImage={selectImage}
                        filterValue={filterValue}
                        deleteFilterImageRef={deleteFilterImageRef}
                      />
                    ))}
                  </Grid>
                )}
              </>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }
);

export default FilterFormImageDialogComponent;
