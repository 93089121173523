import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { PdfUploadedInterface } from "../../../interfaces/PdfUploadedInterface";
import { useTranslation } from "react-i18next";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { DELETE, GET, POST } from "../../../utils/MethodUtils";
import { PDF_UPLOADED_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { Container, DialogProps, TextField } from "@mui/material";
import LoginComponent from "../../../components/common/user/LoginComponent";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileInput from "../file/FileInputComponent";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { InputInterface } from "../../../interfaces/InputInterface";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import getErrorApi from "../../../helpers/GetErrorApi";
import CopyClipboardComponent from "../CopyClipboardComponent";

interface FormState {
  name: InputInterface;
}

const PdfUploadComponent: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [init, setInit] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(50);
  const [pdfUploadeds, setPdfUploadeds] = React.useState<
    PdfUploadedInterface[] | undefined
  >(undefined);
  const [pdfUploadedDelete, setPdfUploadedDelete] = React.useState<
    PdfUploadedInterface | undefined
  >(undefined);
  const fileRef: any = useRef();
  const [hasFile, setHasFile] = React.useState<boolean>(false);
  const [totalItems, setTotalItems] = React.useState(0);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "name",
        headerName: t("column.name"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "path",
        headerName: t("column.path"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={
              process.env.REACT_APP_API_URL +
              "/DATA/pdf/" +
              params.row.id +
              "/index.html"
            }
          />
        ),
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <a
              href={
                process.env.REACT_APP_API_URL +
                "/DATA/pdf/" +
                params.row.id +
                "/index.html"
              }
              target="_blank"
              rel="noreferrer"
            >
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </a>
            <IconButton onClick={() => setPdfUploadedDelete(params.row)}>
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    ];
  }, [t]);
  const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false);
  const [columns] = React.useState<GridColDef[]>(getColumns());
  const [loadingAddFile, setLoadingAddFile] = React.useState<boolean>(false);
  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (pdfUploadeds !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: PDF_UPLOADED_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"].map(
            (o: PdfUploadedInterface) => o.id
          )
        );
        const newPdfUploadeds = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setPdfUploadeds(newPdfUploadeds);
        setTotalItems(response.content["hydra:totalItems"]);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      pdfUploadeds,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );
  const [values, setValues] = React.useState<FormState>({
    name: {
      value: "",
      error: "",
    },
  });

  const saveFile = React.useCallback(async () => {
    setLoadingAddFile(true);
    const fileImage = fileRef.current.getValue();
    if (fileImage) {
      const name = values.name.value.trim();
      const nameError = notEmptyValidator(name);
      if (nameError) {
        const newValue: FormState = { ...values };
        if (nameError) {
          values.name.error = nameError;
        }
        setValues(newValue);
        setLoadingAddFile(false);
        return undefined;
      }
      const filterImageApi = new FormData();
      filterImageApi.append("file", fileImage);
      filterImageApi.append("name", name);
      const response = await requestApi({
        method: POST,
        path: PDF_UPLOADED_URL,
        allowError: true,
        token: token,
        body: filterImageApi,
        formData: true,
      });
      if (response.statusCode === 201) {
        // nothing
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      dispatch(set({ refreshPage: true }));
    }
    setLoadingAddFile(false);
  }, [dispatch, t, token, values]);

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setPdfUploadeds(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setPdfUploadeds(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  const deletePdfUploadedDelete = React.useCallback(async () => {
    if (!pdfUploadedDelete) {
      return;
    }
    setLoadingDelete(true);
    const response = await requestApi({
      method: DELETE,
      path: PDF_UPLOADED_URL + "/" + pdfUploadedDelete.id,
      allowError: true,
      token: token,
    });
    if (response.statusCode === 204) {
      dispatch(set({ refreshPage: true }));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setPdfUploadedDelete(undefined);
    setLoadingDelete(false);
  }, [dispatch, pdfUploadedDelete, t, token]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          fullWidth={true}
          onClose={() => setPdfUploadedDelete(undefined)}
          open={pdfUploadedDelete !== undefined}
        >
          <DialogContent>
            <Typography>{t("sentence.deletePdf")}</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <LoadingButton
              disabled={loadingDelete}
              onClick={() => setPdfUploadedDelete(undefined)}
            >
              {t("word.no")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="error"
              loading={loadingDelete}
              onClick={deletePdfUploadedDelete}
            >
              {t("word.yes")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Container sx={{ marginY: 2 }}>
          {!refreshPage && (
            <FileInput
              ref={fileRef}
              accept="application/zip"
              label={t("sentence.selectZip")}
              setHasFile={setHasFile}
            />
          )}
          {hasFile && (
            <>
              <TextField
                fullWidth={true}
                autoComplete="off"
                error={!!values.name.error}
                helperText={t(values.name.error ?? "")}
                sx={{ width: "100%" }}
                required
                type="text"
                value={values.name.value}
                onChange={handleChange("name")}
                label={t("field.name")}
              />
              <Box sx={{ marginY: 1, textAlign: "right" }}>
                <LoadingButton
                  variant="contained"
                  loading={loadingAddFile}
                  onClick={saveFile}
                >
                  {t("word.save")}
                </LoadingButton>
              </Box>
            </>
          )}
          {searchParamsObject.itemsPerPage && searchParamsObject.page && (
            // https://mui.com/x/react-data-grid/components/#pagination
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: getSortModel(),
                },
              }}
              loading={refreshPage}
              rows={refreshPage ? [] : pdfUploadeds ?? []}
              onSortModelChange={onSortModelChange}
              sortingMode="server"
              page={Number(searchParamsObject.page) - 1}
              rowsPerPageOptions={[10, 25, 50]}
              pageSize={Number(searchParamsObject.itemsPerPage)}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              rowCount={totalItems}
              columns={columns}
              autoHeight={true}
              disableExtendRowFullWidth={true}
              localeText={getLocaleDataGrid(i18n.language)}
            />
          )}
        </Container>
      </LoginComponent>
    </>
  );
});

export default PdfUploadComponent;
