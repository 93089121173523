import React from "react";
import Layout from "../components/common/Layout";
import { useLocation } from "react-router-dom";
import OrdersComponent from "../components/common/order/OrdersComponent";
import { getTitle } from "../helpers/SearchParamHelper";

const OrdersScreen: React.FC = React.memo(() => {
  const { state }: any = useLocation();

  React.useEffect(() => {
    document.title = getTitle("orders");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout sx={{ display: "flex" }}>
      <OrdersComponent newOrder={state?.newOrder} newDevis={state?.newDevis} />
    </Layout>
  );
});

export default OrdersScreen;
