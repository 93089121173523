import React, { useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Chip, Container, useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getTitle, searchParamToObject } from "../../helpers/SearchParamHelper";
import Layout from "../../components/common/Layout";
import TabPanelComponent from "../../components/common/TabPanelComponent";
import FilterAdminComponent from "../../components/common/filter/admin/FilterAdminComponent";
import FilterAdminProductsComponent from "../../components/common/filter/admin/FilterAdminProductsComponent";
import LoginComponent from "../../components/common/user/LoginComponent";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useContainerDimensions } from "../../components/common/useContainerDimensions";
import FilterAdminAssoComponent from "../../components/common/filter/admin/FilterAdminAssoComponent";

const FilterScreen: React.FC = React.memo(() => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const getValue = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return searchParamsObject.tab ? Number(searchParamsObject.tab) : 0;
  }, [searchParams]);
  const [value, setValue] = React.useState(getValue());
  const getIsCustomOverflow = React.useCallback(() => {
    return [2, 3].includes(value) && !isSmall;
  }, [isSmall, value]);
  const nbFilterArticle = useAppSelector(
    (state: RootState) => state.globalState.nbFilterArticle
  );
  const nbFilterArticle2 = useAppSelector(
    (state: RootState) => state.globalState.nbFilterArticle2
  );
  const { t } = useTranslation();
  const componentMainRef = useRef();
  const componentTabRef = useRef();
  const dimensionMain = useContainerDimensions(componentMainRef);
  const dimensionTab = useContainerDimensions(componentTabRef);

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      const newSearchParams = searchParamToObject(searchParams);
      newSearchParams.tab = newValue.toString();
      setSearchParams(newSearchParams, {
        replace: true,
      });
    },
    [searchParams, setSearchParams]
  );

  React.useEffect(() => {
    document.title = getTitle("filters");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout
      mainRef={componentMainRef}
      sx={{ ...(getIsCustomOverflow() && { overflow: "hidden" }) }}
    >
      <LoginComponent redirect={null} requireAdmin={true}>
        <Container
          maxWidth="xl"
          sx={{
            ...(getIsCustomOverflow()
              ? { height: "inherit" }
              : { marginBottom: 10 }),
          }}
        >
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            ref={componentTabRef}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              scrollButtons="auto"
              variant={!isSmall ? "standard" : "scrollable"}
              centered={!isSmall}
            >
              <Tab label={t("word.filters")} />
              <Tab label={t("word.filterCustoms")} />
              <Tab
                sx={{ flexDirection: "row" }}
                label={
                  <>
                    {t("word.products")}
                    {nbFilterArticle !== undefined && nbFilterArticle > 0 && (
                      <Chip
                        label={nbFilterArticle ?? 0}
                        variant="outlined"
                        color="primary"
                        sx={{ marginLeft: 1 }}
                      />
                    )}
                  </>
                }
              />
              <Tab
                sx={{ flexDirection: "row" }}
                label={
                  <>
                    {t("word.products")}
                    {nbFilterArticle2 !== undefined && nbFilterArticle2 > 0 && (
                      <Chip
                        label={nbFilterArticle2 ?? 0}
                        variant="outlined"
                        color="primary"
                        sx={{ marginLeft: 1 }}
                      />
                    )}
                  </>
                }
              />
              <Tab label={t("word.link.label")} />
            </Tabs>
          </Box>
          <TabPanelComponent sx={{ marginTop: 1 }} value={value} index={0}>
            <FilterAdminComponent custom={false} />
          </TabPanelComponent>
          <TabPanelComponent sx={{ marginTop: 1 }} value={value} index={1}>
            <FilterAdminComponent custom={true} />
          </TabPanelComponent>
          <TabPanelComponent
            sx={{
              marginTop: 1,
              ...(getIsCustomOverflow() && {
                maxHeight: dimensionMain.height - dimensionTab.height,
              }),
            }}
            value={value}
            index={2}
          >
            <FilterAdminProductsComponent
              isCustomOverflow={getIsCustomOverflow()}
              containerHeight={dimensionMain.height - dimensionTab.height}
              onlyFilterArticle={true}
            />
          </TabPanelComponent>
          <TabPanelComponent
            sx={{
              marginTop: 1,
              ...(getIsCustomOverflow() && {
                maxHeight: dimensionMain.height - dimensionTab.height,
              }),
            }}
            value={value}
            index={3}
          >
            <FilterAdminProductsComponent
              isCustomOverflow={getIsCustomOverflow()}
              containerHeight={dimensionMain.height - dimensionTab.height}
              onlyFilterArticle={false}
            />
          </TabPanelComponent>
          <TabPanelComponent
            sx={{
              marginTop: 1,
              ...(getIsCustomOverflow() && {
                maxHeight: dimensionMain.height - dimensionTab.height,
              }),
            }}
            value={value}
            index={4}
          >
            <FilterAdminAssoComponent />
          </TabPanelComponent>
        </Container>
      </LoginComponent>
    </Layout>
  );
});

export default FilterScreen;
