import * as React from "react";
import { useRef } from "react";
import { DialogProps } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import StepperFDocenteteBonCommandeComponent from "../order/step/StepperFDocenteteBonCommandeComponent";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FDOCENTETES_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";

interface State {
  fDocentete?: FDocenteteInterface;
  openDialog: boolean;
  setOpenDialog: Function;
}

const DialogEditLinkedFileComponent: React.FC<State> = React.memo(
  ({ fDocentete, openDialog, setOpenDialog }) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const handleClose = React.useCallback(() => {
      setOpenDialog(false);
    }, [setOpenDialog]);
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [file, setFile] = React.useState(undefined);
    const bonCommandeRef: any = useRef();

    const handleNext = React.useCallback(
      async (reference: string) => {
        if (!fDocentete || !file || !reference) {
          return;
        }
        bonCommandeRef.current.setLoading(true);
        const orderApi = new FormData();
        orderApi.append("doPiece", fDocentete.doPiece);
        orderApi.append("reference", reference);
        orderApi.append("bonCommande", file);
        const response = await requestApi({
          method: POST,
          path: FDOCENTETES_URL,
          allowError: true,
          token: token,
          body: orderApi,
          formData: true,
          timeout: 30_000,
        });
        if (response.statusCode === 200) {
          setOpenDialog(false);
          toastr.success(
            t("word.success"),
            t("sentence.notification.bon_commande_updated")
          );
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      },
      [fDocentete, file, setOpenDialog, t, token]
    );

    return (
      <>
        {fDocentete !== undefined && (
          <Dialog
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            onClose={handleClose}
            open={openDialog}
          >
            <DialogContent>
              <StepperFDocenteteBonCommandeComponent
                ref={bonCommandeRef}
                setFile={setFile}
                handleNext={handleNext}
                handleBack={handleClose}
              />
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }
);

export default DialogEditLinkedFileComponent;
