import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleInterface,
  FArticleSellingInterface,
} from "../../../../interfaces/FArticleInterface";
import {
  Accordion,
  AccordionDetails,
  FormHelperText,
  TextField,
  Tooltip,
} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import FArticleFormSellingComponent from "./FArticleFormSellingComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { ALL_SELLING, SELLING_TEXT } from "../../../../utils/FArticleUtils";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { requestApi } from "../../../../helpers/RequestApi";
import { POST } from "../../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

interface State {
  initFArticle: FArticleInterface | undefined;
  edit: boolean;
  setFArticle: Function;
  refFArticleAfter: any;
}

interface FormState {
  fArticleSelling: FArticleSellingInterface;
  ref: any;
}

const FArticleFormSellingsComponent = React.memo(
  React.forwardRef(
    ({ initFArticle, setFArticle, edit, refFArticleAfter }: State, ref) => {
      const theme = useTheme();
      const { t } = useTranslation();
      const [expanded, setExpanded] = React.useState<boolean>(false);
      const [multipleFArticleSelling, setMultipleFArticleSelling] =
        React.useState<string>("");
      const [multipleTypeFArticleSelling, setMultipleTypeFArticleSelling] =
        React.useState<string>("");
      const [hasDuplicateError, setHasDuplicateError] =
        React.useState<boolean>(false);
      const [thisEdit, setThisEdit] = React.useState(false);
      const [loading, setLoading] = React.useState(false);
      const getDefaultValues = React.useCallback((): (
        | FormState
        | undefined
      )[] => {
        return (
          initFArticle?.fArticleProp?.fArticleSellings?.map(
            (fArticleSelling) => {
              return {
                fArticleSelling: fArticleSelling,
                ref: React.createRef(),
              };
            }
          ) ?? []
        );
      }, [initFArticle?.fArticleProp?.fArticleSellings]);
      const [values, setValues] = React.useState<(FormState | undefined)[]>(
        getDefaultValues()
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );

      const changeThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const addFArticleSelling = React.useCallback(() => {
        setValues((v) => {
          v.push({
            fArticleSelling: {
              identifier: "",
              type: "",
              type2: SELLING_TEXT,
              filterValues: [],
            },
            ref: React.createRef(),
          });
          return [...v];
        });
      }, []);

      const addMultipleFArticleSelling = React.useCallback(() => {
        const identifiers = multipleFArticleSelling
          .split(" ")
          .map((x) => x.replaceAll(",", "").trim())
          .filter((x) => x !== "");
        if (identifiers.length > 0) {
          setValues((v) => {
            for (const identifier of identifiers) {
              if (
                v?.find(
                  (x) =>
                    x?.fArticleSelling.identifier === identifier &&
                    x?.fArticleSelling.type === multipleTypeFArticleSelling
                )
              ) {
                continue;
              }
              v.push({
                fArticleSelling: {
                  identifier: identifier,
                  type: multipleTypeFArticleSelling,
                  type2: SELLING_TEXT,
                  filterValues: [],
                },
                ref: React.createRef(),
              });
            }
            return [...v];
          });
        }
        setMultipleFArticleSelling("");
      }, [multipleFArticleSelling, multipleTypeFArticleSelling]);

      const removeFArticleSelling = React.useCallback(
        (index: number) => (event: any) => {
          setValues((v) => {
            v[index] = undefined;
            return [...v];
          });
        },
        []
      );

      const handleChangeExpanded = React.useCallback(
        (event: React.SyntheticEvent, isExpanded: boolean) => {
          setExpanded(isExpanded);
        },
        []
      );

      const getValue = React.useCallback(() => {
        const result = values
          .filter((x) => x)
          .map((x) => {
            return x?.ref.current.getValue();
          });
        if (result.findIndex((x) => x === undefined) >= 0) {
          return undefined;
        }
        const duplicate = result.map((x) => x.identifier + "@" + x.type);
        if (duplicate.length !== new Set(duplicate).size) {
          setHasDuplicateError(true);
          return undefined;
        }
        return result;
      }, [values]);

      const save = React.useCallback(async () => {
        const data = getValue();
        if (!data) {
          return;
        }
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: initFArticle?.arRef,
            fArticleProp: { fArticleSellings: data },
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
          refFArticleAfter.current.updateContent();
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [
        getValue,
        initFArticle?.arRef,
        refFArticleAfter,
        setFArticle,
        t,
        token,
      ]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [initFArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (!edit) {
          setThisEdit(false);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (thisEdit) {
          setExpanded(true);
        }
      }, [thisEdit]); // eslint-disable-line react-hooks/exhaustive-deps

      // if !edit afficher cancel && save
      return (
        <>
          {edit || thisEdit ? (
            <Accordion expanded={expanded} onChange={handleChangeExpanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                sx={{ backgroundColor: theme.palette.primary.main }}
              >
                <Typography sx={{ color: "white" }}>
                  {t("word.fArticleSelling.title")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {hasDuplicateError && (
                  <FormHelperText error>{t("error.notSame")}</FormHelperText>
                )}
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ flex: 1 }}>
                      <TextField
                        fullWidth={true}
                        autoComplete="off"
                        sx={{ width: "100%" }}
                        value={multipleFArticleSelling}
                        label={t("field.identifiers")}
                        onChange={(e) =>
                          setMultipleFArticleSelling(e.target.value)
                        }
                      />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel id="type-label">
                          {t("field.type")}
                        </InputLabel>
                        <Select
                          labelId="type-label"
                          value={multipleTypeFArticleSelling}
                          label={t("field.type")}
                          onChange={(e) =>
                            setMultipleTypeFArticleSelling(
                              e.target.value as string
                            )
                          }
                        >
                          {ALL_SELLING.map((sellingType, indexSellingType) => (
                            <MenuItem
                              value={sellingType}
                              key={indexSellingType}
                            >
                              {t("word.fArticleSelling.type." + sellingType)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ flex: 0 }}>
                      <IconButton onClick={addMultipleFArticleSelling}>
                        <KeyboardReturnIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider sx={{ marginTop: 2 }} />
                  {values.map((v, indexV) => (
                    <Box key={indexV}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <FArticleFormSellingComponent
                          fArticleSelling={v?.fArticleSelling}
                          ref={v?.ref}
                          setHasDuplicateError={setHasDuplicateError}
                        />
                        {v && (
                          <IconButton onClick={removeFArticleSelling(indexV)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                      {v && <Divider sx={{ paddingY: 1 }} />}
                    </Box>
                  ))}
                  <Box sx={{ textAlign: "center", width: "100%" }}>
                    <IconButton onClick={addFArticleSelling}>
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                {!edit && (
                  <Box sx={{ textAlign: "right" }}>
                    <Tooltip title={t("word.cancel")}>
                      <IconButton onClick={changeThisEdit} disabled={loading}>
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("word.save")}>
                      <LoadingButton
                        variant="text"
                        color="inherit"
                        sx={{
                          borderRadius: "50%",
                          minWidth: "auto",
                          padding: "8px",
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                        loading={loading}
                        onClick={save}
                      >
                        <SaveIcon />
                      </LoadingButton>
                    </Tooltip>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ) : (
            <Typography component="span">
              {t("word.fArticleSelling.title")}
              <IconButton onClick={changeThisEdit}>
                <EditIcon />
              </IconButton>
            </Typography>
          )}
        </>
      );
    }
  )
);

export default FArticleFormSellingsComponent;
