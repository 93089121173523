import React from "react";
import SavComponent from "../components/common/sav/SavComponent";
import Layout from "../components/common/Layout";
import { getTitle } from "../helpers/SearchParamHelper";

const SavScreen: React.FC = React.memo(() => {
  React.useEffect(() => {
    document.title = getTitle("sav");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <SavComponent asAdmin={false} />
    </Layout>
  );
});

export default SavScreen;
