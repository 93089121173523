import React, { useRef } from "react";
import { Box, Container } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Link, useSearchParams } from "react-router-dom";
import { useContainerDimensions } from "../useContainerDimensions";
import { ArrivageInterface } from "../../../interfaces/ArrivageInterface";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CopyClipboardComponent from "../CopyClipboardComponent";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { set } from "../../../app/globalSlice";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { ARRIVAGE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import DrawerComponent from "../DrawerComponent";
import { LoadingButton } from "@mui/lab";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { FilterFilterInterface } from "../../../interfaces/FilterInterface";
import ArrivageFormComponent from "./form/ArrivageFormComponent";
import EditIcon from "@mui/icons-material/Edit";
import FilterSearchComponent from "../filter/FilterSearchComponent";
import { ARRIVAGE_PAGE } from "../../../utils/RouteUtils";

const minWidth = 900;

const EntreeStockNoArrivageComponent: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [canApplyFilter, setCanApplyFilter] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(50);
  const [arrivages, setArrivages] = React.useState<
    ArrivageInterface[] | undefined
  >(undefined);
  const [open, setOpen] = React.useState(false);
  const [totalItems, setTotalItems] = React.useState(0);
  const [thisArrivage, setThisArrivage] = React.useState<
    ArrivageInterface | null | undefined
  >(undefined);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const handleDrawerOpen = React.useCallback(
    (arrivage: ArrivageInterface | null) => {
      setThisArrivage(arrivage);
      setOpen(true);
    },
    []
  );
  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "name",
        headerName: t("column.name"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.row.name.split(" ").map((s: string, i: number) => (
              <CopyClipboardComponent
                key={i}
                className="RobotoMono"
                component="span"
                text={s}
                sx={{ marginLeft: 0.5 }}
              />
            ))}
          </>
        ),
      },
      {
        field: "created",
        headerName: t("column.created"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.row.created).toLocaleString(i18n.language, {
            dateStyle: "long",
            timeStyle: "medium",
            timeZone: "America/Cuiaba",
          }),
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <IconButton onClick={() => handleDrawerOpen(params.row)}>
              <EditIcon />
            </IconButton>
            <Link to={ARRIVAGE_PAGE + "/" + params.row.id}>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </>
        ),
      },
    ];
  }, [handleDrawerOpen, i18n.language, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());
  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (arrivages !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (
        !searchParamsObject.hasOwnProperty("page") ||
        searchParamsObject.page === "undefined"
      ) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: ARRIVAGE_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"].map(
            (o: FilterFilterInterface) => o.id
          )
        );
        const newArrivages = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setArrivages(newArrivages);
        setTotalItems(response.content["hydra:totalItems"]);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setCanApplyFilter(true);
      dispatch(set({ refreshPage: false }));
    },
    [
      arrivages,
      defaultItemsPerPage,
      dispatch,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const handleDrawerClose = React.useCallback(() => {
    setThisArrivage(undefined);
    setOpen(false);
  }, []);

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setArrivages(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setArrivages(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <DrawerComponent
        open={open}
        handleDrawerClose={handleDrawerClose}
        onClose={handleDrawerClose}
        drawerwidth={width < minWidth ? width : minWidth}
        content={
          <ArrivageFormComponent
            arrivage={thisArrivage}
            successFunction={() => {
              handleDrawerClose();
              dispatch(set({ refreshPage: true }));
            }}
          />
        }
      />
      <Box ref={componentRef}>
        <Container maxWidth="xl" sx={{ marginY: 2 }}>
          <FilterSearchComponent
            init={canApplyFilter}
            placeholder="column.name"
          />
          <Box sx={{ marginBottom: 1, marginTop: 1 }}>
            <LoadingButton
              variant="contained"
              onClick={() => handleDrawerOpen(null)}
            >
              {t("word.arrivage.create")}
            </LoadingButton>
          </Box>
          {init && searchParamsObject.itemsPerPage && searchParamsObject.page && (
            // https://mui.com/x/react-data-grid/components/#pagination
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: getSortModel(),
                },
              }}
              loading={refreshPage}
              rows={refreshPage ? [] : arrivages ?? []}
              onSortModelChange={onSortModelChange}
              sortingMode="server"
              page={Number(searchParamsObject.page) - 1}
              rowsPerPageOptions={[10, 25, 50]}
              pageSize={Number(searchParamsObject.itemsPerPage)}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              rowCount={totalItems}
              columns={columns}
              autoHeight={true}
              disableExtendRowFullWidth={true}
              localeText={getLocaleDataGrid(i18n.language)}
            />
          )}
        </Container>
      </Box>
    </>
  );
});

export default EntreeStockNoArrivageComponent;
