import * as React from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { GET } from "../../../utils/MethodUtils";
import { requestApi } from "../../../helpers/RequestApi";
import { FILTER_VALUE_ASSO_URL } from "../../../utils/UrlsUtils";
import { useParams } from "react-router-dom";
import FilterValueAssoFormComponent from "../filter/admin/form/filterForm/FilterValueAssoFormComponent";
import { FilterValueAssoInterface } from "../../../interfaces/FilterInterface";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import LoginComponent from "../user/LoginComponent";
import { getTitle } from "../../../helpers/SearchParamHelper";

const BrowserFilterValueAssoFormComponent: React.FC = React.memo(() => {
  const params = useParams();
  const [filterValueAsso, setFilterValueAsso] = React.useState<
    FilterValueAssoInterface | null | undefined
  >(undefined);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const { t } = useTranslation();

  const load = React.useCallback(async () => {
    if (params.id === "undefined") {
      return;
    }
    const response = await requestApi({
      method: GET,
      path: FILTER_VALUE_ASSO_URL + "/" + params.id,
      allowError: true,
      token: token,
    });
    if (response.statusCode === 200) {
      setFilterValueAsso(response.content);
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
  }, [params.id, t, token]);

  React.useEffect(() => {
    document.title = getTitle("filterValues");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="xl" sx={{ marginY: 3 }}>
      <LoginComponent redirect={null} requireAdmin={true}>
        <FilterValueAssoFormComponent filterValueAsso={filterValueAsso} />
      </LoginComponent>
    </Container>
  );
});

export default BrowserFilterValueAssoFormComponent;
