import React, { forwardRef, useImperativeHandle } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SavInterface } from "../../../interfaces/SavInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  ALL_STATE,
  ALL_STATE_PRODUCT,
  STATE_PENDING_REQUEST,
} from "../../../utils/SavUtils";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

interface State {
  sav: SavInterface | undefined | null;
  asAdmin: boolean;
}

interface FormState {
  rma: InputInterface;
  expirationDate: InputInterface;
  state: InputInterface;
  stateProduct: InputInterface;
}

const SavFormNoveComponent = React.memo(
  forwardRef(({ sav, asAdmin }: State, ref) => {
    const { t } = useTranslation();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const [canEdit] = React.useState(isAdmin && asAdmin);
    const getDefaultValue = React.useCallback((): FormState => {
      return {
        rma: {
          value: sav?.rma ?? "",
          error: "",
        },
        expirationDate: {
          value: sav?.expirationDate ? new Date(sav?.expirationDate) : null,
          error: "",
        },
        state: {
          value: sav?.state ?? STATE_PENDING_REQUEST,
          error: "",
        },
        stateProduct: {
          value: sav?.stateProduct ?? "",
          error: "",
        },
      };
    }, [sav?.expirationDate, sav?.rma, sav?.state, sav?.stateProduct]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      []
    );

    const handleChangeDate = React.useCallback(
      (prop: keyof FormState) => (newValue: Date | null) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: newValue,
              error: "",
            },
          };
        });
      },
      []
    );

    const getValue = React.useCallback(() => {
      return {
        state: values.state.value,
        stateProduct:
          values.stateProduct.value === "" ? null : values.stateProduct.value,
        rma: values.rma.value ? values.rma.value : null,
        expirationDate: values.expirationDate.value
          ? values.expirationDate.value
          : null,
      };
    }, [
      values.expirationDate.value,
      values.rma.value,
      values.state.value,
      values.stateProduct.value,
    ]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [user, asAdmin, sav]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="product-state">{t("field.state")}</InputLabel>
            <Select
              disabled={!canEdit}
              labelId="product-state"
              value={values.state.value}
              label={t("field.state")}
              onChange={handleChangeSelect("state")}
            >
              {ALL_STATE.map((state, indexState) => (
                <MenuItem value={state} key={indexState}>
                  {t("word.sav.state." + state)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="product-stateProduct">
              {t("field.stateProduct")}
            </InputLabel>
            <Select
              disabled={!canEdit}
              labelId="product-stateProduct"
              value={values.stateProduct.value}
              label={t("field.stateProduct")}
              onChange={handleChangeSelect("stateProduct")}
            >
              <MenuItem value="">
                <em>{t("word.none")}</em>
              </MenuItem>
              {ALL_STATE_PRODUCT.map((stateProduct, indexState) => (
                <MenuItem value={stateProduct} key={indexState}>
                  {t("word.sav.state." + stateProduct)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={!canEdit}
            autoComplete="off"
            error={!!values.rma.error}
            helperText={t(values.rma.error ?? "")}
            sx={{ width: "100%" }}
            type="text"
            value={values.rma.value}
            onChange={handleChange("rma")}
            label={t("field.rma")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                disabled={!canEdit}
                label={t("field.expirationDate")}
                // @ts-ignore
                format="dd/MM/yyyy"
                // @ts-ignore
                inputFormat="dd/MM/yyyy"
                value={values.expirationDate.value}
                onChange={handleChangeDate("expirationDate")}
                // @ts-ignore
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    error={!!values.expirationDate.error}
                    helperText={t(values.expirationDate.error ?? "")}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
      </Grid>
    );
  })
);
export default SavFormNoveComponent;
