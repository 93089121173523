import * as React from "react";
import {
  FArticleInterface,
  FArtstockInterface,
} from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Typography from "@mui/material/Typography";
import CopyClipboardComponent from "../CopyClipboardComponent";

interface State {
  fArticle: FArticleInterface | undefined;
}

const FArticleEmplacementComponent: React.FC<State> = React.memo(
  ({ fArticle }) => {
    const { t } = useTranslation();
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const getFArtStock = React.useCallback(():
      | FArtstockInterface
      | undefined => {
      if (fArticle && fArticle.fArtstocks && fArticle.fArtstocks.length > 0) {
        return fArticle.fArtstocks.find((x) => x.fDepot.deNo === 1);
      }
      return undefined;
    }, [fArticle]);
    const [fArtStock, setFArtStock] = React.useState<
      FArtstockInterface | undefined
    >(getFArtStock());

    React.useEffect(() => {
      setFArtStock(getFArtStock());
    }, [fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {isAdmin && fArtStock && (
          <Typography>
            {t("word.emplacement") + ": "}
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={fArtStock.cbdpNoprincipal?.dpCode ?? ""}
            />
          </Typography>
        )}
      </>
    );
  }
);

export default FArticleEmplacementComponent;
