import * as React from "react";
import {
  FArticleInterface,
  FournisseurArticleInterface,
} from "../../../interfaces/FArticleInterface";
import { Skeleton, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { FormStateFArticleSub } from "./FArticleComponent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import sage69Validator from "../../../helpers/validator/Sage69Validator";
import {
  generateTechnicalSheet,
  getPropertiesStartWith,
} from "../../../helpers/FArticleHelper";
import DoneIcon from "@mui/icons-material/Done";

interface State {
  values: FormStateFArticleSub;
  edit: boolean;
  handleChange: Function;
  fArticle: FArticleInterface | undefined;
  setValues: Function;
  setFArticle: Function;
  temporaryIcecat?: any;
  fournisseurProduct?: FournisseurArticleInterface;
}

const FArticleArDesignComponent: React.FC<State> = React.memo(
  ({
    values,
    edit,
    handleChange,
    fArticle,
    setValues,
    setFArticle,
    temporaryIcecat,
    fournisseurProduct,
  }) => {
    const [thisEdit, setThisEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const theme = useTheme();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );

    const changeThisEdit = React.useCallback(() => {
      setThisEdit((x) => !x);
    }, []);

    const save = React.useCallback(async () => {
      const arDesignValue = values.arDesign.value.trim();
      const arDesignError = sage69Validator(arDesignValue);
      if (arDesignError) {
        const newValue: FormStateFArticleSub = { ...values };
        if (arDesignError) {
          newValue.arDesign.error = arDesignError;
        }
        setValues(newValue);
        return;
      }
      setLoading(true);
      const fArticleApi = new FormData();
      fArticleApi.append(
        "json",
        JSON.stringify({
          arRef: fArticle?.arRef,
          arDesign: arDesignValue,
          fArticleProp: {
            name: arDesignValue,
          },
        })
      );
      const response = await requestApi({
        method: POST,
        path: FARTICLE_URL,
        allowError: true,
        timeout: 30_000,
        token: token,
        body: fArticleApi,
        formData: true,
      });
      if (response.statusCode === 201) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.farticle_updated")
        );
        setFArticle(response.content);
        setThisEdit(false);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [fArticle?.arRef, setFArticle, setValues, t, token, values]);

    React.useEffect(() => {
      if (!edit) {
        setThisEdit(false);
      }
    }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValues((v: FormStateFArticleSub) => {
        v.arDesign.value = fArticle?.arDesign ?? "";
        return { ...v };
      });
    }, [edit, thisEdit]); // eslint-disable-line react-hooks/exhaustive-deps

    let titles: string[];
    if (temporaryIcecat) {
      let testFArticle: any = {
        ficheTechnique: temporaryIcecat,
      };
      titles = generateTechnicalSheet(testFArticle, 1)?.titles ?? [];
    } else {
      titles = generateTechnicalSheet(fArticle, 1)?.titles ?? [];
    }
    if (fournisseurProduct) {
      for (const prop of getPropertiesStartWith(
        fournisseurProduct,
        "idArtCatalogue"
      )) {
        // @ts-ignore
        titles.push(fournisseurProduct[prop]?.designation);
      }
    }
    // @ts-ignore
    titles = [...new Set(titles)].filter((t) => t);

    return (
      <>
        {edit || thisEdit ? (
          <Box sx={{ width: "100%" }}>
            {(values.arDesign.value ?? "").trim().length > 69 && (
              <Typography>
                <Typography
                  component="span"
                  sx={{ backgroundColor: theme.palette.success.main }}
                >
                  {values.arDesign.value.substring(0, 69)}
                </Typography>
                <Typography
                  component="span"
                  sx={{ backgroundColor: theme.palette.error.main }}
                >
                  {values.arDesign.value.substring(
                    69,
                    values.arDesign.value.length
                  )}
                </Typography>
              </Typography>
            )}
            <TextField
              autoComplete="off"
              error={!!values.arDesign.error}
              helperText={t(values.arDesign.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.arDesign.value}
              onChange={handleChange("arDesign")}
              label={t("field.arDesign")}
            />
            {titles.map((title) => (
              <Box key={title} sx={{ display: "flex", alignItems: "center" }}>
                <Typography>{title}</Typography>
                <IconButton
                  onClick={() => {
                    setValues((v: any) => {
                      return {
                        ...v,
                        arDesign: { ...v["arDesign"], value: title, error: "" },
                      };
                    });
                  }}
                >
                  <DoneIcon />
                </IconButton>
              </Box>
            ))}
            {!edit && (
              <>
                <Tooltip title={t("word.cancel")}>
                  <IconButton onClick={changeThisEdit} disabled={loading}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("word.save")}>
                  <LoadingButton
                    variant="text"
                    color="inherit"
                    sx={{
                      borderRadius: "50%",
                      minWidth: "auto",
                      padding: "8px",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                    loading={loading}
                    onClick={save}
                  >
                    <SaveIcon />
                  </LoadingButton>
                </Tooltip>
              </>
            )}
          </Box>
        ) : (
          <Typography
            component="h1"
            variant="h5"
            sx={{ display: "inline-block" }}
          >
            {fArticle?.fArticleProp === undefined ? (
              <Skeleton variant="text" />
            ) : (
              <>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  to={PRODUCT_PAGE + "/" + fArticle.fArticleProp?.slug}
                  state={fArticle}
                >
                  {fArticle?.arDesign}
                  {fArticle?.fArticleProp?.indexable === false && (
                    <Typography
                      component="span"
                      sx={{ color: theme.palette.error.main }}
                    >
                      {" "}
                      [non publié]
                    </Typography>
                  )}
                </Link>
                {isAdmin && (
                  <IconButton onClick={changeThisEdit}>
                    <EditIcon />
                  </IconButton>
                )}
              </>
            )}
          </Typography>
        )}
      </>
    );
  }
);

export default FArticleArDesignComponent;
