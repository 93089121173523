import { isValidPhoneNumber } from "react-phone-number-input";

const phoneValidator = (phone: string | null) => {
  if (!phone) {
    return "error.phone.empty";
  }
  if (!isValidPhoneNumber(phone)) {
    return "error.phone.valid";
  }
  return "";
};

export const formatPhone = (phone: string | null) => {
  if (phone === null) {
    phone = "";
  }
  phone = phone.replace(/ /, "");
  if (phone.startsWith("0690") || phone.startsWith("0590")) {
    phone = "+590" + phone;
  }

  return phone;
};
export default phoneValidator;
