import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import {
  GetPostOrdersInterface,
  OrderInterface,
} from "../../../interfaces/OrderInterface";
import { priceFormat } from "../../../utils/FormatUtils";
import { getAcomptePostOrder } from "../../../helpers/CartHelper";
import DialogOrderComponent from "./DialogOrderComponent";
import { ORDER_TYPE_GLOBAL } from "../../../utils/OrderUtils";
import { Grid } from "@mui/material";
import DialogConfirmComponent from "../DialogConfirmComponent";
import Typography from "@mui/material/Typography";
import TablePostOrderComponent from "./TablePostOrderComponent";
import { requestApi } from "../../../helpers/RequestApi";
import { DELETE } from "../../../utils/MethodUtils";
import { POSTORDERS_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { OpenDialogOrderInterface } from "../../../interfaces/FDocenteteInterface";
import { set } from "../../../app/globalSlice";
import { isUserEnCompte } from "../../../helpers/UserHelper";

interface State {
  postOrder: GetPostOrdersInterface;
  load: Function;
}

const PostOrderActionComponent: React.FC<State> = React.memo(
  ({ postOrder, load }) => {
    const { t, i18n } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const userProp = useAppSelector(
      (state: RootState) => state.globalState.userProp
    );
    const nbOrderNeedPayAcompte = useAppSelector(
      (state: RootState) => state.globalState.nbOrderNeedPayAcompte
    );
    const accountingSituation = useAppSelector(
      (state: RootState) => state.globalState.accountingSituation
    );
    const postOrders = useAppSelector(
      (state: RootState) => state.globalState.postOrders
    );
    const [openDialogPay, setOpenDialogPay] =
      React.useState<OpenDialogOrderInterface>({
        open: false,
        devis: false,
      });
    const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
    const getOrder = React.useCallback(() => {
      const newOrder: OrderInterface = {
        orderType: ORDER_TYPE_GLOBAL,
        orderFArticles: postOrder.orderFArticles,
      };
      return newOrder;
    }, [postOrder.orderFArticles]);
    const [order, setOrder] = React.useState<OrderInterface>(getOrder());
    const dispatch = useAppDispatch();

    const onPay = React.useCallback(async () => {
      setOpenDialogPay((x) => {
        x.open = true;
        return { ...x };
      });
    }, []);

    const onDelete = React.useCallback(async () => {
      setOpenDialogDelete(true);
    }, []);

    const confirmDeletion = React.useCallback(async () => {
      const response = await requestApi({
        method: DELETE,
        path: POSTORDERS_URL + "/" + postOrder.id,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 204) {
        load(true);
        setOpenDialogDelete(false);
        dispatch(
          set({
            nbOrderNeedPayAcompte: nbOrderNeedPayAcompte
              ? nbOrderNeedPayAcompte - 1
              : 0,
            postOrders: postOrders?.filter((x) => x.id !== postOrder.id),
          })
        );
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [
      dispatch,
      load,
      nbOrderNeedPayAcompte,
      postOrder.id,
      postOrders,
      t,
      token,
    ]);

    React.useEffect(() => {
      setOrder(getOrder());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            <LoadingButton onClick={onDelete} color="error">
              {t("word.delete")}
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton variant="contained" onClick={onPay}>
              {t("word.payAcompte") +
                " " +
                priceFormat(
                  getAcomptePostOrder(
                    postOrder,
                    user,
                    userProp,
                    isUserEnCompte(user),
                    true
                  ),
                  i18n.language,
                  "EUR"
                )}
              {accountingSituation !== undefined &&
                accountingSituation.sumCreditFdocentetes > 0 && (
                  <>{" " + t("sentence.orUseAvoir")}</>
                )}
            </LoadingButton>
          </Grid>
        </Grid>
        <DialogOrderComponent
          order={order}
          postOrderId={postOrder.id}
          openDialog={openDialogPay.open}
          devis={openDialogPay.devis}
          setOpenDialog={setOpenDialogPay}
        />
        <DialogConfirmComponent
          openDialog={openDialogDelete}
          setOpenDialog={setOpenDialogDelete}
          onYes={confirmDeletion}
        >
          <Typography>{t("sentence.deletePostOrder")}</Typography>
          <TablePostOrderComponent postOrder={postOrder} />
        </DialogConfirmComponent>
      </>
    );
  }
);

export default PostOrderActionComponent;
