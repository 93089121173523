import * as React from "react";
import { SyntheticEvent } from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Accordion, useTheme } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import MailFormComponent from "./MailFormComponent";
import SearchComponent from "../SearchComponent";

const MailsComponent: React.FC = React.memo(() => {
  const mails = useAppSelector((state: RootState) => state.globalState.mails);
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<number[]>([]);
  const [alreadyExpanded, setAlreadyExpanded] = React.useState<number[]>([]);
  const handleChange = React.useCallback(
    (prop: number) =>
      (event: SyntheticEvent<Element, Event>, expanded: boolean) => {
        if (expanded) {
          setExpanded((x) => {
            return [...x, prop];
          });
          setAlreadyExpanded((x) => {
            // @ts-ignore
            return [...new Set([...x, prop])];
          });
        } else {
          setExpanded((x) => {
            x = x.filter((y) => y !== prop);
            return x.filter((y) => y !== prop);
          });
        }
      },
    []
  );

  return (
    <>
      {!mails ? (
        <SearchComponent nbColumn={1} nbLines={1} />
      ) : (
        <>
          {mails.map((mail, indexMail) => (
            <Accordion
              key={indexMail}
              expanded={expanded.includes(indexMail)}
              onChange={handleChange(indexMail)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                sx={{ backgroundColor: theme.palette.primary.main }}
              >
                <Typography component="p" variant="h6" sx={{ color: "white" }}>
                  {mail.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {(expanded.includes(indexMail) ||
                  alreadyExpanded.includes(indexMail)) && (
                  <MailFormComponent mail={mail} />
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </>
  );
});

export default MailsComponent;
