import React from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import { useLocation, useParams } from "react-router-dom";
import FArticleComponent from "../components/common/fArticle/FArticleComponent";
import { FArticleInterface } from "../interfaces/FArticleInterface";
import { requestApi } from "../helpers/RequestApi";
import { GET } from "../utils/MethodUtils";
import { FARTICLE_URL } from "../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { getTitle } from "../helpers/SearchParamHelper";

const ProductScreen: React.FC = React.memo(() => {
  const params = useParams();
  const { state }: any = useLocation();
  const [fArticle, setFArticle] = React.useState<FArticleInterface | undefined>(
    state ?? { arRef: params.arRef }
  );
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const { t } = useTranslation();

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!force) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      if (force) {
        setFArticle(undefined);
      }
      const response = await requestApi({
        method: GET,
        path: FARTICLE_URL + "/" + params.arRef,
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        setFArticle(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [dispatch, params.arRef, t, token]
  );

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let title = getTitle("product");
    if (fArticle?.arDesign) {
      title = fArticle.arDesign;
    }
    document.title = title + " Informatique Guadeloupe NOVE";
  }, [fArticle?.arDesign]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && !refreshPage) {
      setFArticle(undefined);
      load(true);
    }
  }, [JSON.stringify(params)]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout sx={{ overflowY: "scroll!important" }}>
      <FArticleComponent initFArticle={fArticle} />
    </Layout>
  );
});

export default ProductScreen;
