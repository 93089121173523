import React from "react";
import { FormControlLabel } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";
import { ALL_STATUS_CONTACT } from "../../../../utils/ContactUtils";

interface State {
  init: boolean;
}

const FeedbackFilterStateComponent: React.FC<State> = React.memo(({ init }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [statusChecked, setStatusChecked] = React.useState<string[]>(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (searchParamsObject.hasOwnProperty("status[]")) {
      return searchParamsObject["status[]"];
    }
    return [];
  });
  const { t } = useTranslation();

  const handleChangeCheckbox = React.useCallback(
    (status: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setStatusChecked((x) => {
        if (event.target.checked) {
          x = [...x, status];
        } else {
          x = x.filter((y) => y !== status);
        }
        return x;
      });
    },
    []
  );

  React.useEffect(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (statusChecked.length > 0) {
      searchParamsObject["status[]"] = statusChecked;
    } else if (searchParamsObject.hasOwnProperty("status[]")) {
      delete searchParamsObject["status[]"];
    }
    if (init) {
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    }
  }, [statusChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormGroup sx={{ flexDirection: "row" }}>
      {ALL_STATUS_CONTACT.map((status, indexState) => (
        <FormControlLabel
          key={indexState}
          control={
            <Checkbox
              checked={statusChecked.includes(status)}
              onChange={handleChangeCheckbox(status)}
            />
          }
          label={t("word.contact.status." + status)}
        />
      ))}
    </FormGroup>
  );
});

export default FeedbackFilterStateComponent;
