import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import ConfigurationsComponent from "./ConfigurationsComponent";
import MenuIcon from "@mui/icons-material/Menu";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, Grid, useTheme } from "@mui/material";
import { set } from "../../../app/globalSlice";
import { swapCard } from "../../../helpers/CardFormat";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { CONFIGURATION_GROUP_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { ConfigurationGroupInterface } from "../../../interfaces/ConfigurationInterface";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";

const clone = require("clone");

const ConfigurationGroupComponent: React.FC = React.memo(() => {
  const configurationGroups = useAppSelector(
    (state: RootState) => state.globalState.configurationGroups
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const getThisConfigurationGroups = React.useCallback(():
    | ConfigurationGroupInterface[]
    | undefined => {
    let result: ConfigurationGroupInterface[] | undefined =
      clone(configurationGroups);
    if (!result) {
      return result;
    }
    const searchParamsObject = searchParamToObject(searchParams);
    for (const configurationGroup of result) {
      configurationGroup.configurations =
        configurationGroup.configurations.filter((x) => {
          if (searchParamsObject.used === "used") {
            return x.used;
          } else if (searchParamsObject.used === "unused") {
            return !x.used;
          }
          return true;
        });
    }
    if (searchParamsObject.used !== "") {
      result = result.filter((x) => x.configurations.length > 0);
    }
    return result;
  }, [configurationGroups, searchParams]);
  const [thisConfigurationGroups, setThisConfigurationGroups] = React.useState<
    ConfigurationGroupInterface[] | undefined
  >(getThisConfigurationGroups());

  const handleChangeSelect = React.useCallback(
    (prop: string) => (event: SelectChangeEvent) => {
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject[prop] = event.target.value as string;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [searchParams, setSearchParams]
  );

  const onDragEnd = React.useCallback(
    async (result: DropResult) => {
      if (
        result.destination === null ||
        result.destination === undefined ||
        !thisConfigurationGroups
      ) {
        return;
      }
      const thisConfigurationGroup =
        thisConfigurationGroups[result.source.index];
      const newConfigurationGroups = swapCard(thisConfigurationGroups, result);
      dispatch(set({ configurationGroups: newConfigurationGroups }));
      const response = await requestApi({
        method: PATCH,
        path: CONFIGURATION_GROUP_URL + "/" + thisConfigurationGroup.id,
        allowError: false,
        token: token,
        body: {
          sort: result.destination.index + 1,
        },
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.configuration_group_updated")
        );
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    },
    [thisConfigurationGroups, dispatch, t, token]
  );

  React.useEffect(() => {
    setThisConfigurationGroups(getThisConfigurationGroups());
  }, [configurationGroups, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <Select
              value={searchParamsObject.used ?? ""}
              displayEmpty
              onChange={handleChangeSelect("used")}
            >
              <MenuItem value="">
                <em>{t("word.all")}</em>
              </MenuItem>
              <MenuItem value="used">{t("word.used")}</MenuItem>
              <MenuItem value="unused">{t("word.unused")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {thisConfigurationGroups && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="configurationGroups">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {thisConfigurationGroups.map(
                      (thisConfigurationGroup, thisIndexConfigurationGroup) => (
                        <Draggable
                          key={thisConfigurationGroup.id}
                          draggableId={thisConfigurationGroup.id.toString()}
                          index={thisIndexConfigurationGroup}
                        >
                          {(provided) => (
                            <Accordion
                              defaultExpanded={true}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <AccordionSummary
                                expandIcon={
                                  <ExpandMoreIcon sx={{ color: "white" }} />
                                }
                                sx={{
                                  backgroundColor: theme.palette.primary.main,
                                }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "move",
                                    marginRight: 1,
                                    color: "white",
                                  }}
                                  {...provided.dragHandleProps}
                                >
                                  <MenuIcon />
                                  {thisConfigurationGroup.name}
                                </span>
                              </AccordionSummary>
                              <AccordionDetails>
                                <ConfigurationsComponent
                                  configurationGroup={thisConfigurationGroup}
                                />
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Grid>
      </Grid>
    </>
  );
});

export default ConfigurationGroupComponent;
