import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { priceFormat } from "../../utils/FormatUtils";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

type Props = {
  price: number | undefined;
  sx?: SxProps<Theme>;
  variant?: any;
  component?: any;
};

const PriceComponent: React.FC<Props> = React.memo(
  ({ price, sx, variant, component }) => {
    const { i18n } = useTranslation();

    return (
      <Typography
        sx={{
          ...sx,
          ...{
            fontWeight: "bold",
            wordBreak: "break-word",
          },
        }}
        variant={variant}
        component={component}
      >
        {priceFormat(price, i18n.language, "EUR")}
      </Typography>
    );
  }
);

export default PriceComponent;
