import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { grey } from "@mui/material/colors";
import CopyClipboardComponent from "../CopyClipboardComponent";
import InfoIcon from "@mui/icons-material/Info";
import { FiscalCodeInterface } from "../../../interfaces/FiscalCodeInterface";
import { useTheme } from "@mui/material/styles";

interface State {
  fArticle: FArticleInterface | undefined;
  edit: boolean;
  setFArticle: Function;
  createFrom?: FArticleInterface;
}

interface FormState {
  arCodefiscal: InputInterface;
}

const FArticleCodeFiscalComponent = React.memo(
  React.forwardRef(
    ({ fArticle, edit, setFArticle, createFrom }: State, ref) => {
      const { t } = useTranslation();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const theme = useTheme();
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const fiscalCodes = useAppSelector(
        (state: RootState) => state.globalState.fiscalCodes
      );
      const getFullCode = React.useCallback(
        (code1: string, code2: string = ""): string => {
          const maxLength = Math.max(
            ...[code1, code2].map((el: string) => el.length)
          );
          return code1.padEnd(maxLength, "0");
        },
        []
      );
      const getArCodefiscal = React.useCallback(
        (code: string | undefined): FiscalCodeInterface => {
          const thisFullCode = getFullCode(code ?? "");
          if (code) {
            return {
              code: code,
              name: "",
              nb:
                fiscalCodes?.find(
                  (fc) => thisFullCode === getFullCode(fc.code, thisFullCode)
                )?.nb ?? 0,
            };
          }
          return { code: "", name: "", nb: 0 };
        },
        [fiscalCodes, getFullCode]
      );
      const getDefaultValues = React.useCallback((): FormState => {
        return {
          arCodefiscal: {
            value: createFrom?.arCodefiscal
              ? getArCodefiscal(createFrom?.arCodefiscal)
              : getArCodefiscal(fArticle?.arCodefiscal),
            error: "",
          },
        };
      }, [createFrom?.arCodefiscal, fArticle?.arCodefiscal, getArCodefiscal]);
      const getCurrentFiscalCodes =
        React.useCallback((): FiscalCodeInterface[] => {
          let result: FiscalCodeInterface[] = [];
          const arCodefiscal = createFrom?.arCodefiscal
            ? createFrom?.arCodefiscal
            : fArticle?.arCodefiscal;
          if (!arCodefiscal || !fiscalCodes) {
            return result;
          }
          for (let i = 0; i <= arCodefiscal.length; i++) {
            const thisCode = arCodefiscal.slice(0, i);
            const thisFiscalCode = fiscalCodes.find(
              (fc) => fc.code === thisCode
            );
            if (thisFiscalCode) {
              result.push(thisFiscalCode);
            }
          }
          result = [
            ...result,
            ...fiscalCodes.filter(
              (fc) =>
                fc.code.startsWith(arCodefiscal) && fc.code !== arCodefiscal
            ),
          ];
          return result;
        }, [createFrom?.arCodefiscal, fArticle?.arCodefiscal, fiscalCodes]);
      const [currentFiscalCodes, setCurrentFiscalCodes] = React.useState<
        FiscalCodeInterface[]
      >(getCurrentFiscalCodes());
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          arCodefiscal: values.arCodefiscal.value.code,
        };
      }, [values.arCodefiscal.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        const thisValues = getValue();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            ...thisValues,
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChangeAutocomplete = React.useCallback(
        (prop: keyof FormState) =>
          (event: SyntheticEvent<Element, Event> | null, value: any) => {
            if (typeof value === "string") {
              value = getArCodefiscal(value);
            }
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        [getArCodefiscal]
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [fArticle, thisEdit, edit, createFrom]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setCurrentFiscalCodes(getCurrentFiscalCodes());
      }, [fArticle?.arCodefiscal, createFrom?.arCodefiscal]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (
          values.arCodefiscal.value &&
          values.arCodefiscal.value.name !== ""
        ) {
          setValues((v) => {
            v.arCodefiscal.value = {
              ...v.arCodefiscal.value,
              name: "",
            };
            return { ...v };
          });
        }
      }, [values.arCodefiscal.value]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {thisEdit || edit ? (
            <>
              <Autocomplete
                freeSolo={true}
                options={fiscalCodes ?? []}
                sx={{
                  marginTop: 1,
                }}
                getOptionLabel={(option: string | FiscalCodeInterface) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  return option.code + option.name;
                }}
                isOptionEqualToValue={(
                  option: FiscalCodeInterface,
                  value: FiscalCodeInterface
                ) => option.code === value.code}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.code}>
                      <span style={{ color: theme.palette.primary.main }}>
                        [{option.nb}]
                      </span>
                      {option.code + ": " + option.name}
                    </li>
                  );
                }}
                onChange={handleChangeAutocomplete("arCodefiscal")}
                value={values.arCodefiscal.value}
                defaultValue={values.arCodefiscal.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => {
                      handleChangeAutocomplete("arCodefiscal")(
                        null,
                        e.target.value
                      );
                    }}
                    autoComplete="off"
                    error={!!values.arCodefiscal.error}
                    helperText={t(values.arCodefiscal.error ?? "")}
                    label={t("field.arCodefiscal")}
                    placeholder={t("field.arCodefiscal")}
                  />
                )}
              />
              {!edit && (
                <>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={handleThisEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              {(isAdmin ||
                (fArticle?.arCodefiscal &&
                  fArticle.arCodefiscal.trim() !== "")) && (
                <>
                  <Typography sx={{ width: "100%" }}>
                    <span style={{ color: grey[500] }}>
                      {t("word.arCodefiscal") + ": "}
                    </span>
                    {fArticle?.fArticleProp === undefined ? (
                      <Skeleton sx={{ flex: 1 }} variant="text" />
                    ) : (
                      <CopyClipboardComponent
                        className="RobotoMono"
                        component="span"
                        text={fArticle?.arCodefiscal ?? ""}
                      />
                    )}
                    <Tooltip
                      title={
                        <ul style={{ paddingLeft: "20px" }}>
                          {currentFiscalCodes.map((c) => {
                            return (
                              <li
                                key={c.id}
                                style={{
                                  fontSize: "1rem",
                                }}
                              >
                                <strong
                                  style={{
                                    ...(getFullCode(
                                      c.code,
                                      fArticle?.arCodefiscal ?? ""
                                    ) ===
                                      getFullCode(
                                        fArticle?.arCodefiscal ?? "",
                                        c.code
                                      ) && {
                                      color: theme.palette.success.main,
                                    }),
                                  }}
                                >
                                  {c.code}
                                </strong>
                                : {c.name}
                              </li>
                            );
                          })}
                        </ul>
                      }
                      placement="top"
                    >
                      <IconButton size="large">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    {isAdmin && (
                      <IconButton onClick={handleThisEdit}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </Typography>
                </>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleCodeFiscalComponent;
