import React, { useRef } from "react";
import { ConfigurationInterface } from "../../../interfaces/ConfigurationInterface";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  TYPE_ALL_CONTENT,
  TYPE_BOOL,
  TYPE_CONTENT,
  TYPE_NUM,
  TYPE_TEXT_INPUT,
} from "../../../utils/ConfigurationUtils";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import CkEditorComponent from "../../content/CkEditorComponent";
import { LoadingButton } from "@mui/lab";
import numberValidator from "../../../helpers/validator/NumberValidator";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { CONFIGURATION_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { updateConfiguration } from "../../../app/globalSlice";

interface State {
  configuration: ConfigurationInterface | undefined;
  closeDialog?: Function;
}

interface FormState {
  data: InputInterface;
  type: InputInterface;
}

const ConfigurationFormComponent: React.FC<State> = React.memo(
  ({ configuration, closeDialog }) => {
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const ckEditorRef: any = useRef();
    const [loading, setLoading] = React.useState(false);
    const dispatch = useAppDispatch();
    const getValue = React.useCallback((): string | boolean | undefined => {
      let value: string | boolean | undefined = configuration?.value;
      if (configuration?.type === TYPE_BOOL) {
        value = value === "1";
      }
      return value;
    }, [configuration?.type, configuration?.value]);
    const getDefaultValues = React.useCallback((): FormState => {
      const value = getValue();
      return {
        data: { value: value === undefined ? "" : value, error: "" },
        type: { value: configuration?.type ?? TYPE_TEXT_INPUT, error: "" },
      };
    }, [configuration?.type, getValue]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          if (prop === "type" && (event.target.value as string) === TYPE_BOOL) {
            v.data.value = configuration?.value === "1";
          } else {
            v.data.value = configuration?.value;
          }
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      [configuration?.value]
    );

    const handleClose = React.useCallback(() => {
      if (closeDialog) {
        closeDialog();
      }
    }, [closeDialog]);

    const save = React.useCallback(async () => {
      setLoading(true);
      let error = "";
      let value = values.data.value;
      if (values.type.value === TYPE_CONTENT) {
        value = ckEditorRef.current.getValue();
      }
      switch (values.type.value) {
        case TYPE_NUM:
          error = numberValidator(value);
          break;
        default:
          break;
      }
      if (error) {
        const newValue: FormState = { ...values };
        newValue.data.error = error;
        setValues(newValue);
        return;
      }
      if (typeof value === "boolean") {
        value = value ? "1" : "0";
      }
      const response = await requestApi({
        method: PATCH,
        path: CONFIGURATION_URL + "/" + configuration?.id,
        allowError: false,
        token: token,
        body: {
          value: value,
          type: values.type.value,
        },
      });
      if (response.statusCode === 200) {
        dispatch(updateConfiguration(response.content));
        toastr.success(
          t("word.success"),
          t("sentence.notification.configuration_updated")
        );
        if (closeDialog) {
          closeDialog();
        }
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setLoading(false);
    }, [closeDialog, configuration?.id, dispatch, t, token, values]);

    const handleChangeSwitch = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: {
                ...v[prop],
                value: event.target.checked,
                error: "",
              },
            };
          });
        },
      []
    );

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [configuration]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {configuration && (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="caption"
                component="span"
                sx={{ color: "gray" }}
              >
                [{configuration.identifier}]
              </Typography>
              <Badge
                color={configuration.used ? "success" : "error"}
                badgeContent=" "
                variant="dot"
              >
                <Typography>{configuration.title + ":"}</Typography>
              </Badge>
            </Box>
            <Typography sx={{ marginTop: 2 }} component="span">
              {t("word.description") + ":"}
            </Typography>
            <Typography sx={{ marginBottom: 2 }} component="pre">
              {configuration.description}
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: 2 }}>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel id="type">{t("field.type")}</InputLabel>
                  <Select
                    labelId="type"
                    value={values.type.value}
                    label={t("field.type")}
                    onChange={handleChangeSelect("type")}
                  >
                    {TYPE_ALL_CONTENT.map((type, indexType) => (
                      <MenuItem value={type} key={indexType}>
                        {t("word.configuration.type." + type)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {[TYPE_TEXT_INPUT, TYPE_NUM].includes(values.type.value) ? (
                  <TextField
                    fullWidth={true}
                    autoComplete="off"
                    error={!!values.data.error}
                    helperText={t(values.data.error ?? "")}
                    sx={{ width: "100%" }}
                    required
                    type="text"
                    value={values.data.value}
                    onChange={handleChange("data")}
                    label={t("field.value")}
                  />
                ) : values.type.value === TYPE_BOOL ? (
                  <>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={values.data.value}
                            onChange={handleChangeSwitch("data")}
                          />
                        }
                        label={values.data.value ? t("word.yes") : t("word.no")}
                      />
                    </FormGroup>
                  </>
                ) : values.type.value === TYPE_CONTENT ? (
                  <CkEditorComponent
                    ref={ckEditorRef}
                    text={values.data.value ?? ""}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <LoadingButton disabled={loading} onClick={handleClose}>
                  {t("word.cancel")}
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={save}
                >
                  {t("word.save")}
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
);

export default ConfigurationFormComponent;
