import * as React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import ContentComponent from "../../content/ContentComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { InputInterface } from "../../../interfaces/InputInterface";
import Box from "@mui/material/Box";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { NEWS_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";

interface FormState {
  date: InputInterface;
}

const NewsFormComponent: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const refContent: any = useRef();
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const dispatch = useAppDispatch();
  const [showContent, setShowContent] = React.useState(true);

  const getDefaultValue = React.useCallback((): FormState => {
    return {
      date: {
        value: new Date(),
        error: "",
      },
    };
  }, []);
  const [values, setValues] = React.useState<FormState>(getDefaultValue());

  const save = React.useCallback(async () => {
    setLoading(true);
    const response = await requestApi({
      method: POST,
      path: NEWS_URL,
      allowError: true,
      token: token,
      body: {
        content: await refContent.current?.getValue(),
        date: values.date.value,
      },
    });
    if (response.statusCode === 201) {
      dispatch(set({ refreshPage: true }));
      toastr.success(t("word.success"), t("sentence.notification.news_added"));
      setShowContent(false);
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [dispatch, t, token, values.date.value]);

  const handleChangeDate = React.useCallback(
    (prop: keyof FormState) => (newValue: Date | null) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: {
            ...v[prop],
            value: newValue,
            error: "",
          },
        };
      });
    },
    []
  );

  React.useEffect(() => {
    setShowContent(true);
  }, [showContent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        sx={{
          backgroundColor: theme.palette.primary.main,
          justifyContent: "flex-start",
        }}
      >
        <Typography sx={{ color: "white" }}>{t("word.createNews")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3} sx={{ maxWidth: "200px" }}>
              <DesktopDatePicker
                label={t("field.date")}
                // @ts-ignore
                format="dd/MM/yyyy"
                // @ts-ignore
                inputFormat="dd/MM/yyyy"
                value={values.date.value}
                onChange={handleChangeDate("date")}
                // @ts-ignore
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    error={!!values.date.error}
                    helperText={t(values.date.error ?? "")}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Box>
        {showContent && (
          <ContentComponent
            initContent={null}
            preventChangeUrlTab={true}
            edit={true}
            showEdit={false}
            ref={refContent}
            showSaveCancel={false}
          />
        )}
        <Box sx={{ textAlign: "right" }}>
          <LoadingButton loading={loading} variant="contained" onClick={save}>
            {t("word.save")}
          </LoadingButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

export default NewsFormComponent;
