import * as React from "react";
import { Accordion, TextField, useTheme } from "@mui/material";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { TEST_SEND_MAIL_URL } from "../../../utils/UrlsUtils";

interface FormState {
  email: InputInterface;
}

const TestSendMailComponent: React.FC = React.memo(() => {
  const theme = useTheme();
  const [values, setValues] = React.useState<FormState>({
    email: { value: "abeaujour@nove.fr", error: "" },
  });
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const save = React.useCallback(async () => {
    setLoading(true);
    const response = await requestApi({
      method: GET,
      path: TEST_SEND_MAIL_URL + "?email=" + values.email.value,
      allowError: false,
      token: token,
    });
    if (response.statusCode === 200 && response.content === true) {
      toastr.success(t("word.success"), t("sentence.notification.mail_sent"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [t, token, values.email.value]);

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Typography component="p" variant="h6" sx={{ color: "white" }}>
          {t("word.testSendMail")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.email.error}
              helperText={t(values.email.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.email.value}
              onChange={handleChange("email")}
              label={t("field.email")}
              placeholder={t("field.email")}
            />
          </Box>
          <Box>
            <LoadingButton variant="contained" loading={loading} onClick={save}>
              {t("word.send")}
            </LoadingButton>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

export default TestSendMailComponent;
