const sagCtNumValidator = (sageName: string | null) => {
  sageName = sageName?.trim() ?? "";
  if (sageName.length === 0) {
    return "error.not_empty";
  }
  if (sageName.length > 17) {
    return "error.sageName.maxLength17";
  }
  return "";
};
export default sagCtNumValidator;
