import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Typography from "@mui/material/Typography";
import {
  CategoryInterface,
  FilterCategoryInterface,
} from "../../../interfaces/CategoryInterface";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import FilterValueComponent from "./FilterValueComponent";
import StockFilterComponent from "../filter/StockFilterComponent";
import BrandFilterComponent from "../filter/BrandFilterComponent";
import RayonFilterComponent from "../filter/RayonFilterComponent";
import PriceFilterComponent from "../filter/PriceFilterComponent";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import KeywordsFilterComponent from "../filter/KeywordsFilterComponent";
import { useTranslation } from "react-i18next";
import ImageFilterComponent from "../filter/ImageFilterComponent";
import { useSearchParams } from "react-router-dom";
import CategoryFilterComponent from "../filter/CategoryFilterComponent";
import { TYPE_CHECKBOX, TYPE_NUMERIC } from "../../../utils/FilterUtils";
import WeightFilterComponent from "../filter/WeightFilterComponent";
import FilterValueNumericComponent from "./FilterValueNumericComponent";
import ScoreFilterComponent from "../filter/ScoreFilterComponent";
import TagFilterComponent from "../filter/TagFilterComponent";
import FiscalCodeFilterComponent from "../filter/FiscalCodeFilterComponent";
import IcecatFilterComponent from "../filter/IcecatFilterComponent";

interface State {
  filterCategory: FilterCategoryInterface | undefined;
  apply: Function;
  applyHidden: Function;
  category: CategoryInterface | undefined;
  index: number;
  filterInited: Function;
  getAllFilterValueValues: Function;
  filterValueIdAndFilterValueChildIds: any;
  aggregations: any;
}

const FilterCategoryComponent = React.memo(
  forwardRef(
    (
      {
        filterCategory,
        apply,
        applyHidden,
        category,
        index,
        filterInited,
        aggregations,
        filterValueIdAndFilterValueChildIds,
        getAllFilterValueValues,
      }: State,
      ref
    ) => {
      const drivers = useAppSelector(
        (state: RootState) => state.globalState.drivers
      );
      const theme = useTheme();
      const filterValueRef: any = useRef();
      const customFilterRef: any = useRef();
      const accordionRef: any = useRef();
      const [nbSelected, setNbSelected] = React.useState<number>(0);
      const [searchParams] = useSearchParams();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const showAllFilters = useAppSelector(
        (state: RootState) => state.globalState.showAllFilters
      );
      const [expanded, setExpanded] = React.useState<boolean>(
        !!filterCategory?.opened
      );
      const getHidden = React.useCallback((): boolean | null => {
        if (
          !filterCategory ||
          filterCategory?.filter.custom ||
          !filterCategory.filter.conditionalFilters ||
          filterCategory.filter.conditionalFilters.length === 0 ||
          filterCategory.filter.conditionalFilters[0].filterValues.length === 0
        ) {
          return false;
        }
        if (showAllFilters) {
          return false;
        }
        const searchParamsObject = getAllFilterValueValues();
        if (searchParamsObject.hasOwnProperty("filterValues[]")) {
          let allSelectedFilterValues: number[] = [];
          for (let filterValue of searchParamsObject["filterValues[]"]) {
            filterValue = JSON.parse(filterValue);
            allSelectedFilterValues = [
              ...allSelectedFilterValues,
              ...filterValue.values,
            ];
          }
          // @ts-ignore
          const conditionsConditionalFilters: number[][] =
            filterCategory.filter.conditionalFilters
              ?.map((conditionalFilter) =>
                conditionalFilter.filterValues
                  .map((filterValue) => filterValue.id)
                  .filter((filterValue) => !!filterValue)
              )
              .filter((conditionalFilter) => conditionalFilter.length > 0);
          let hide = true;
          for (const conditionsConditionalFilter of conditionsConditionalFilters) {
            const intersect = conditionsConditionalFilter.filter((value) => {
              return (
                allSelectedFilterValues.filter(
                  (x) =>
                    filterValueIdAndFilterValueChildIds.hasOwnProperty(value) &&
                    filterValueIdAndFilterValueChildIds[value].includes(x)
                ).length > 0
              );
            });
            // if (intersect.length === conditionsConditionalFilter.length) {
            if (intersect.length > 0) {
              hide = false;
              break;
            }
          }
          return hide;
        }
        if (Object.keys(searchParamsObject).length === 0) {
          return null;
        }
        return true;
      }, [
        showAllFilters,
        filterCategory,
        filterValueIdAndFilterValueChildIds,
        getAllFilterValueValues,
      ]);
      const [hidden, setHidden] = React.useState<boolean | null>(getHidden());
      const getShowFilter = React.useCallback((): boolean => {
        if (!filterCategory?.filter.slug) {
          return false;
        }
        if (!isAdmin && filterCategory.filter.onlyAdmin) {
          return false;
        }
        switch (filterCategory.filter.slug) {
          case "rayon":
            return !category;
          case "prix":
            return !!category && !!category?.prices;
        }
        return true;
      }, [
        category,
        filterCategory?.filter.onlyAdmin,
        filterCategory?.filter.slug,
        isAdmin,
      ]);
      const [showFilter, setShowFilter] = React.useState<boolean>(
        getShowFilter()
      );
      const { t } = useTranslation();

      const customFilter = React.useCallback(
        (slug: string): React.ReactNode => {
          switch (slug) {
            case "stock":
              return (
                <StockFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "image":
              return (
                <ImageFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "keywords":
              return (
                <KeywordsFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "marque":
              return (
                <BrandFilterComponent
                  ref={customFilterRef}
                  brands={
                    category
                      ? category.brands
                      : drivers
                          ?.filter((x) => x.nbFArticle > 0)
                          ?.map((driver) => driver.name) ?? []
                  }
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "rayon":
              return (
                <RayonFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                  aggregations={aggregations}
                />
              );
            case "prix":
              return (
                <PriceFilterComponent
                  ref={customFilterRef}
                  initPrices={
                    category?.prices ?? {
                      price2: 0,
                      price1: 0,
                      price3: 0,
                      price4: 0,
                    }
                  }
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "category":
              return (
                <CategoryFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "weight":
              return (
                <WeightFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "score":
              return (
                <ScoreFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "tag":
              return (
                <TagFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "fiscalCode":
              return (
                <FiscalCodeFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            case "icecat":
              return (
                <IcecatFilterComponent
                  ref={customFilterRef}
                  setNbSelected={setNbSelected}
                  apply={apply}
                  index={index}
                  filterInited={filterInited}
                />
              );
            default:
              console.log("no customFilter defined for " + slug);
              return <></>;
          }
        },
        [aggregations, apply, category, drivers, filterInited, index]
      );

      const handleChangeAccordion = React.useCallback((): void => {
        setExpanded((x) => !x);
      }, []);

      const thisApplyHidden = React.useCallback((): void => {
        const newHidden = getHidden();
        if (!newHidden && hidden) {
          setExpanded(true);
          setTimeout(() => {
            accordionRef.current?.scrollIntoView({ behavior: "auto" });
          });
        }
        setHidden(newHidden);
      }, [getHidden, hidden]);

      React.useEffect(() => {
        thisApplyHidden();
      }, [searchParams, showAllFilters]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setShowFilter(getShowFilter());
      }, [filterCategory?.filter.slug]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (!showFilter) {
          filterInited(index);
        }
      }, [showFilter]); // eslint-disable-line react-hooks/exhaustive-deps

      useImperativeHandle(ref, () => ({
        getValue() {
          if (filterCategory?.filter.custom) {
            return customFilterRef.current?.getValue();
          }
          return filterValueRef.current?.getValue();
        },
        resetCustomRayon() {
          if (customFilterRef.current?.resetCustomRayon) {
            customFilterRef.current?.resetCustomRayon();
          }
        },
        applyHidden() {
          thisApplyHidden();
        },
      }));

      return (
        <>
          {!!filterCategory && showFilter && (
            <Accordion
              ref={accordionRef}
              expanded={expanded}
              onChange={handleChangeAccordion}
              sx={{
                ...(hidden !== false && { display: "none" }),
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    marginTop: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {filterCategory.filter.name}
                  {filterCategory.filter.custom &&
                    filterCategory.filter.name === "Rayon" && (
                      <Typography component="span" sx={{ marginLeft: 1 }}>
                        [{t("word.filter.condition.B")}]
                      </Typography>
                    )}
                  {filterCategory.filter.onlyAdmin && (
                    <Typography
                      component="span"
                      sx={{ color: theme.palette.error.main }}
                    >
                      [admin]
                    </Typography>
                  )}
                  {nbSelected > 0 && (
                    <Typography
                      component="span"
                      className="badge"
                      sx={{ marginLeft: 1 }}
                    >
                      {nbSelected}
                    </Typography>
                  )}
                  {!filterCategory.filter.custom &&
                    filterCategory.filter.type === TYPE_CHECKBOX && (
                      <Typography component="span" sx={{ marginLeft: 1 }}>
                        [
                        {t(
                          "word.filter.condition." +
                            filterCategory.filter.condition
                        )}
                        ]
                      </Typography>
                    )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  ...(filterCategory.filter.type === TYPE_NUMERIC && {
                    paddingX: 0,
                  }),
                  ...(filterCategory.filter.slug === "rayon" && {
                    maxHeight: "500px",
                    overflow: "auto",
                  }),
                }}
              >
                {filterCategory.filter.custom ? (
                  customFilter(filterCategory.filter.slug)
                ) : (
                  <>
                    {filterCategory.filter.type === TYPE_NUMERIC ? (
                      <FilterValueNumericComponent
                        ref={filterValueRef}
                        setNbSelected={setNbSelected}
                        filterCategory={filterCategory}
                        apply={apply}
                        applyHidden={applyHidden}
                        index={index}
                        filterInited={filterInited}
                        hidden={hidden ?? false}
                      />
                    ) : (
                      <FilterValueComponent
                        ref={filterValueRef}
                        setNbSelected={setNbSelected}
                        filterCategory={filterCategory}
                        apply={apply}
                        applyHidden={applyHidden}
                        index={index}
                        filterInited={filterInited}
                        hidden={hidden ?? false}
                        aggregations={aggregations}
                      />
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </>
      );
    }
  )
);

export default FilterCategoryComponent;
