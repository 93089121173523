import React, { ChangeEvent, useImperativeHandle } from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SavInterface } from "../../../interfaces/SavInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import FormLabel from "@mui/material/FormLabel";
import { ALL_PACKAGE_STATE, ALL_REASON } from "../../../utils/SavUtils";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";

interface State {
  sav?: SavInterface | null;
}

interface FormState {
  reason: InputInterface;
  packageState: InputInterface;
}

const SavFormServiceComponent = React.memo(
  React.forwardRef(({ sav }: State, ref) => {
    const { t } = useTranslation();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const getDefaultValue = React.useCallback((): FormState => {
      return {
        reason: {
          value: sav ? sav.reason : "",
          error: "",
        },
        packageState: {
          value: sav ? sav.packageState : "",
          error: "",
        },
      };
    }, [sav]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());

    const handleChangeRadio = React.useCallback(
      (prop: keyof FormState) =>
        (event: ChangeEvent<HTMLInputElement>, value: string) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      const reasonError = notEmptyValidator(values.reason.value);
      const packageStateError = notEmptyValidator(values.packageState.value);
      if (reasonError || packageStateError) {
        const newValue: FormState = { ...values };
        if (reasonError) {
          newValue.reason.error = reasonError;
        }
        if (packageStateError) {
          newValue.packageState.error = packageStateError;
        }
        setValues(newValue);
        return undefined;
      }
      return {
        reason: values.reason.value,
        packageState: values.packageState.value,
      };
    }, [values]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [user, sav]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid item xs={12}>
          <FormControl error={!!values.reason.error}>
            <FormLabel id="reason-radio">{t("field.reason")}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="reason-radio"
              value={values.reason.value}
              onChange={handleChangeRadio("reason")}
            >
              {ALL_REASON.map((reason, indexReason) => (
                <FormControlLabel
                  disabled={!!sav?.id}
                  value={reason}
                  key={indexReason}
                  control={<Radio />}
                  label={t("word.sav.reason." + reason)}
                />
              ))}
            </RadioGroup>
            {!!values.reason.error && (
              <FormHelperText error>
                {t(values.reason.error ?? "")}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={!!values.packageState.error}>
            <FormLabel id="package-state-radio">
              {t("field.packageState")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="package-state-radio"
              value={values.packageState.value}
              onChange={handleChangeRadio("packageState")}
            >
              {ALL_PACKAGE_STATE.map((packageState, indexPackageState) => (
                <FormControlLabel
                  disabled={!!sav?.id}
                  value={packageState}
                  key={indexPackageState}
                  control={<Radio />}
                  label={t("word.sav.packageState." + packageState)}
                />
              ))}
            </RadioGroup>
            {!!values.packageState.error && (
              <FormHelperText error>
                {t(values.packageState.error ?? "")}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </>
    );
  })
);

export default SavFormServiceComponent;
