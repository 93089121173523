import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { FilterValueAssoFilterValueInterface } from "../../../../../../interfaces/FilterInterface";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import { useAppSelector } from "../../../../../../app/hooks";
import { RootState } from "../../../../../../app/store";
import {
  objectToQuery,
  requestApi,
} from "../../../../../../helpers/RequestApi";
import { GET } from "../../../../../../utils/MethodUtils";
import { FILTER_VALUE_URL } from "../../../../../../utils/UrlsUtils";
import getErrorApi from "../../../../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";

interface State {
  filterValueAssoFilters: FilterValueAssoFilterValueInterface[] | undefined;
  onChange?: Function;
}

interface FormState {
  filterValueAssoFilters: InputInterface;
}

const FilterValueAssoFilterValueFormComponent = React.memo(
  React.forwardRef(({ filterValueAssoFilters, onChange }: State, ref) => {
    const { t } = useTranslation();
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        filterValueAssoFilters: {
          value: filterValueAssoFilters ? [...filterValueAssoFilters] : [],
          error: "",
        },
      };
    }, [filterValueAssoFilters]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [filterValues, setFilterValues] = React.useState<
      FilterValueAssoFilterValueInterface[]
    >(values.filterValueAssoFilters.value);
    const [searchFilterValue, setSearchFilterValue] =
      React.useState<string>("");
    const [isTyping, setIsTyping] = React.useState<boolean>(false);
    const [isSearching, setIsSearching] = React.useState<boolean>(false);

    const handleSearchFilterValues = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: string) => {
        setSearchFilterValue(value);
        setFilterValues([]);
        setIsTyping(true);
      },
      []
    );

    const searchFilterValues = React.useCallback(async () => {
      setIsTyping(false);
      setIsSearching(true);
      const search = searchFilterValue.trim();
      if (search !== "") {
        const response = await requestApi({
          method: GET,
          path:
            FILTER_VALUE_URL +
            objectToQuery({
              filterValueAsso: true,
              name: search,
            }),
          allowError: false,
          paginate: false,
          token: token,
        });
        if (response.statusCode === 200) {
          response.content.sort(
            (
              a: FilterValueAssoFilterValueInterface,
              b: FilterValueAssoFilterValueInterface
            ) => a.filter.id - b.filter.id
          );
          setFilterValues(response.content);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }
      setIsSearching(false);
    }, [searchFilterValue, t, token]);

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      if (values.filterValueAssoFilters.value.length === 0) {
        setValues((v) => {
          v.filterValueAssoFilters.error = t("error.field.empty");
          return { ...v };
        });
        return undefined;
      }
      return values.filterValueAssoFilters.value.map(
        (filterValueAssoFilter: FilterValueAssoFilterValueInterface) =>
          FILTER_VALUE_URL + "/" + filterValueAssoFilter.id
      );
    }, [t, values.filterValueAssoFilters.value]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      if (onChange) {
        onChange();
      }
    }, [JSON.stringify(values.filterValueAssoFilters.value)]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [filterValueAssoFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchFilterValues();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [searchFilterValue]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Autocomplete
          multiple={true}
          className="autocomplete-height-adjust"
          loadingText={t("word.loading") + "..."}
          inputValue={searchFilterValue}
          onInputChange={handleSearchFilterValues}
          groupBy={(option: FilterValueAssoFilterValueInterface) =>
            "(" +
            option.filter.filterCategories
              .map(
                (filterCategory) =>
                  "[" +
                  filterCategory.category.id.toString() +
                  "] " +
                  filterCategory.category.name
              )
              .join(" | ") +
            ") | " +
            "[" +
            option.filter.id.toString() +
            "] " +
            option.filter.name
          }
          getOptionLabel={(option: FilterValueAssoFilterValueInterface) => {
            return (
              "(" +
              option.filter.filterCategories
                .map(
                  (filterCategory) =>
                    "[" +
                    filterCategory.category.id.toString() +
                    "] " +
                    filterCategory.category.name
                )
                .join(" | ") +
              ") | " +
              "[" +
              option.filter.id +
              "] " +
              option.filter.name +
              " | [" +
              option.id +
              "] " +
              option.name
            );
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} style={{ display: "block" }}>
                {"[" + option.id + "] " + option.name}
              </li>
            );
          }}
          isOptionEqualToValue={(
            option: FilterValueAssoFilterValueInterface,
            value: FilterValueAssoFilterValueInterface
          ) => option.id === value.id}
          noOptionsText={
            isSearching ? (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Box>
            ) : searchFilterValue !== "" && !isTyping ? (
              t("sentence.no_option")
            ) : (
              t("word.searchFilterValue.label")
            )
          }
          options={filterValues}
          onChange={handleChangeAutocomplete("filterValueAssoFilters")}
          value={values.filterValueAssoFilters.value}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              error={!!values.filterValueAssoFilters.error}
              helperText={t(values.filterValueAssoFilters.error ?? "")}
              label={t("field.filterValue")}
              placeholder={t("field.filterValue")}
            />
          )}
        />
      </>
    );
  })
);

export default FilterValueAssoFilterValueFormComponent;
