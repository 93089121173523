import React, { useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import LoadingScreen from "./screens/LoadingScreen";
import {
  ABOUT_PAGE,
  ACCOUNT_PAGE,
  ACCOUNTING_SITUATION_PAGE,
  ADMIN_BRAND_PAGE,
  ADMIN_CATEGORY_PAGE,
  ADMIN_CONSOLE_PAGE,
  ADMIN_CRON_PAGE,
  ADMIN_EDI_PAGE,
  ADMIN_FEEDBACK_PAGE,
  ADMIN_INVOICE_PAGE,
  ADMIN_MAIL_PAGE,
  ADMIN_PAYMENT_PAGE,
  ADMIN_SAV_PAGE,
  ADMIN_USER_PAGE,
  ARRIVAGE_PAGE,
  BROWSER_FILTER_VALUE_ASSO,
  BROWSER_PRODUCT_FILTER_VALUE_PAGE,
  CATEGORY_PAGE,
  COMPARE_PAGE,
  CONFIGURATION_PAGE,
  CONFIGURATOR_PAGE,
  ENTERING_INVOICE_PAGE,
  EXPEDITION_PAGE,
  FAVORIS_PAGE,
  FEEDBACK_PAGE,
  FILTER_PAGE,
  FISCAL_CODE_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  NEWS_PAGE,
  ORDER_PAGE,
  ORDERS_PAGE,
  PDF_PAGE,
  PRODUCT_DUPLICATE_PAGE,
  PRODUCT_PAGE,
  PRODUCTS_PAGE,
  RAW_HTML_PAGE,
  REGISTER_PAGE,
  RESET_PASSWORD_PAGE,
  SAV_PAGE,
  STATS_PAGE,
  TAG_PAGE,
} from "./utils/RouteUtils";
import LoginScreen from "./screens/LoginScreen";
import HomeScreen from "./screens/HomeScreen";
import RegisterScreen from "./screens/RegisterScreen";
import AccountScreen from "./screens/AccountScreen";
import ConfiguratorScreen from "./screens/ConfiguratorScreen";
import OrderScreen from "./screens/OrderScreen";
import ProductScreen from "./screens/ProductScreen";
import CategoryScreen from "./screens/CategoryScreen";
import ProductsScreen from "./screens/ProductsScreen";
import OrdersScreen from "./screens/OrdersScreen";
import StatsScreen from "./screens/admin/StatsScreen";
import RawHtmlScreen from "./screens/RawHtmlScreen";
import SavScreen from "./screens/SavScreen";
import AccountingSituationScreen from "./screens/AccountingSituationScreen";
import AboutScreen from "./screens/AboutScreen";
import AdminSavScreen from "./screens/admin/AdminSavScreen";
import FilterScreen from "./screens/admin/FilterScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import ExpeditionScreen from "./screens/admin/ExpeditionScreen";
import AdminCategoryScreen from "./screens/admin/AdminCategoryScreen";
import AdminBrandScreen from "./screens/admin/AdminBrandScreen";
import AdminCronScreen from "./screens/admin/AdminCronScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import UserScreen from "./screens/admin/UserScreen";
import AdminConfigurationScreen from "./screens/admin/AdminConfigurationScreen";
import FeedbackScreen from "./screens/FeedbackScreen";
import { EventSourcePolyfill } from "event-source-polyfill";
import {
  MAIL_ACTIVATED,
  TYPE_ARRIVAGE,
  TYPE_ARRIVAGE_DONE,
  TYPE_CONTACT_MESSAGE,
  TYPE_CREDIT_FDOCENTETE,
  TYPE_DELETE_CONTACT_MESSAGE,
  TYPE_MANUAL_CRON,
  TYPE_MESSAGE,
  TYPE_NB_FILTER_ARTICLE,
  TYPE_NOTIFICATION,
  TYPE_UPDATE_CONTACT,
  TYPE_UPDATE_CONTACT_MESSAGE,
} from "./utils/MercureUtils";
import {
  addNotification,
  set,
  triggerMailValidated,
  updateCron,
} from "./app/globalSlice";
import AdminFeedbackScreen from "./screens/admin/AdminFeedbackScreen";
import ConsoleScreen from "./screens/admin/ConsoleScreen";
import {
  STORAGE_EDITING_FDOCENTETE,
  STORAGE_IMPERSONATE,
  STORAGE_STATE,
  STORAGE_ZOOM,
} from "./utils/StorageUtils";
import BrowserFilterAdminProductFilterValueComponent from "./components/common/browser/BrowserFilterAdminProductFilterValueComponent";
import BrowserFilterValueAssoFormComponent from "./components/common/browser/BrowserFilterValueAssoFormComponent";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { objectToQuery, requestApi } from "./helpers/RequestApi";
import { GET } from "./utils/MethodUtils";
import { FARTICLE_SELLING_FARTICLE_URL } from "./utils/UrlsUtils";
import { SELLING_UP } from "./utils/FArticleUtils";
import TagScreen from "./screens/TagScreen";
import AdminMailScreen from "./screens/admin/AdminMailScreen";
import AdminPaymentScreen from "./screens/admin/AdminPaymentScreen";
import FiscalCodeScreen from "./screens/admin/FiscalCodeScreen";
import NewsScreen from "./screens/NewsScreen";
import FavorisScreen from "./screens/admin/FavorisScreen";
import { priceFormat } from "./utils/FormatUtils";
import CompareScreen from "./screens/CompareScreen";
import PdfScreen from "./screens/admin/PdfScreen";
import EntreeStockScreen from "./screens/admin/EntreeStockScreen";
import ArrivageScreen from "./screens/admin/ArrivageScreen";
import ProductDuplicationScreen from "./screens/ProductDuplicationScreen";
import EdiScreen from "./screens/admin/EdiScreen";
import InvoiceScreen from "./screens/admin/InvoiceScreen";
import EnteringInvoiceScreen from "./screens/EnteringInvoiceScreen";

function App() {
  const { t, i18n } = useTranslation();
  const init = useAppSelector((state: RootState) => state.globalState.init);
  const tokenMercure = useAppSelector(
    (state: RootState) => state.globalState.tokenMercure
  );
  const topics = useAppSelector((state: RootState) => state.globalState.topics);
  const newContactMessage = useAppSelector(
    (state: RootState) => state.globalState.newContactMessage
  );
  const impersonate = useAppSelector(
    (state: RootState) => state.globalState.impersonate
  );
  const globalState = useAppSelector((state: RootState) => state.globalState);
  const mailActivatedTrigger = useAppSelector(
    (state: RootState) => state.globalState.mailActivatedTrigger
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const zoom = useAppSelector((state: RootState) => state.globalState.zoom);
  const cart = useAppSelector((state: RootState) => state.globalState.cart);
  const editingFDocentete = useAppSelector(
    (state: RootState) => state.globalState.editingFDocentete
  );
  const dispatch = useAppDispatch();
  const refArrivage: any = useRef();

  const handleOpen = React.useCallback((event: any) => {
    // console.log(event);
  }, []);

  const handleMessage = React.useCallback(
    (event: any) => {
      const data: any = JSON.parse(event.data);
      switch (data.type) {
        case TYPE_MANUAL_CRON:
          dispatch(updateCron(data.data));
          break;
        case TYPE_NB_FILTER_ARTICLE:
          dispatch(set(data.data));
          break;
        case TYPE_CONTACT_MESSAGE:
          dispatch(set({ newContactMessage: data.data }));
          if (data.data.newMessageUser !== undefined) {
            dispatch(set({ newMessageUser: data.data.newMessageUser }));
          }
          if (data.data.newMessageAdmin !== undefined) {
            dispatch(set({ newMessageAdmin: data.data.newMessageAdmin }));
          }
          break;
        case TYPE_DELETE_CONTACT_MESSAGE:
          if (newContactMessage?.contactMessage.id === data.data) {
            dispatch(set({ newContactMessage: undefined }));
          }
          dispatch(set({ contactMessageIdToDelete: data.data }));
          break;
        case TYPE_UPDATE_CONTACT_MESSAGE:
          dispatch(set({ updateContactMessage: data.data }));
          break;
        case TYPE_UPDATE_CONTACT:
          dispatch(set({ updateContact: data.data }));
          break;
        case TYPE_NOTIFICATION:
          dispatch(addNotification(data.data));
          break;
        case MAIL_ACTIVATED:
          dispatch(triggerMailValidated());
          break;
        case TYPE_MESSAGE:
          // @ts-ignore
          toastr[data.data.type](
            t("word." + data.data.type),
            data.data.message
          );
          break;
        case TYPE_CREDIT_FDOCENTETE:
          toastr.info(
            t("word.info"),
            t("sentence.creditFDocenteteUsed")
              .replace("%userIdentifier%", data.data.userIdentifier)
              .replace("%fDocenteteId%", data.data.fDocenteteId)
              .replace(
                "%amount%",
                priceFormat(data.data.amount, i18n.language, "EUR")
              ),
            {
              timeOut: 0,
            }
          );
          break;
        case TYPE_ARRIVAGE:
          console.log(data);
          refArrivage.current.setArrivage(data.data);
          break;
        case TYPE_ARRIVAGE_DONE:
          console.log(data);
          refArrivage.current.arrivageDone(data.data);
          break;
        default:
          console.log("unknown event ", data);
      }
    },
    [dispatch, i18n.language, newContactMessage?.contactMessage?.id, t]
  );

  const handleError = React.useCallback((event: any) => {
    // console.log(event);
  }, []);

  const handleClose = React.useCallback((event: any) => {
    // console.log(event);
  }, []);

  const onChangeGlobalState = React.useCallback(() => {
    if (init) {
      localStorage.setItem(
        STORAGE_STATE,
        JSON.stringify({
          ...globalState,
          postOrders: undefined,
        })
      );
    }
  }, [globalState, init]);

  const onCartUpdate = React.useCallback(async () => {
    if (!init || !token) {
      return;
    }
    if (!cart) {
      dispatch(set({ cartUpsellings: undefined }));
      return;
    }
    const response = await requestApi({
      method: GET,
      path:
        FARTICLE_SELLING_FARTICLE_URL.replace("{type}", SELLING_UP) +
        objectToQuery({
          indexable: 1,
          identifiers: JSON.stringify(
            cart.cartFArticles
              .map((cartFArticle) => cartFArticle.fArticle.arRef)
              .filter((x) => x)
          ),
        }),
      allowError: true,
      token: token,
    });
    if (response.statusCode === 200) {
      dispatch(set({ cartUpsellings: response.content }));
    }
  }, [cart, dispatch, init, token]);

  React.useEffect(() => {
    if (!tokenMercure || !topics || topics.length === 0) {
      return;
    }
    const url = new URL(process.env.REACT_APP_API_URL + "/.well-known/mercure");
    url.searchParams.append("topic", topics[0]);

    const es = new EventSourcePolyfill(url.toString(), {
      headers: {
        Authorization: "Bearer " + tokenMercure,
      },
      heartbeatTimeout: 3600 * 1000, // 1 hour
    });
    es.addEventListener("open", handleOpen);
    es.addEventListener("message", handleMessage);
    es.addEventListener("error", handleError);
    es.addEventListener("close", handleClose);
    return () => {
      es.close();
    };
  }, [tokenMercure, topics]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init) {
      toastr.success(t("word.success"), t("sentence.mailValidated"));
    }
  }, [mailActivatedTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onChangeGlobalState();
  }, [globalState]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onCartUpdate();
  }, [JSON.stringify(cart ?? {})]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    localStorage.setItem(STORAGE_EDITING_FDOCENTETE, editingFDocentete ?? "");
  }, [editingFDocentete]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    localStorage.setItem(STORAGE_IMPERSONATE, impersonate ?? "");
  }, [impersonate]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const thisZoom = zoom ?? "100";
    localStorage.setItem(STORAGE_ZOOM, thisZoom);
    // https://developer.mozilla.org/en-US/docs/Web/CSS/zoom#browser_compatibility
    // https://stackoverflow.com/a/10464073/6824121
    // @ts-ignore
    document.body.style.zoom = thisZoom + "%";
  }, [zoom]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Routes>
      {init ? (
        <>
          <Route path={LOGIN_PAGE} element={<LoginScreen />} />
          <Route path={REGISTER_PAGE} element={<RegisterScreen />} />
          <Route path={HOME_PAGE} element={<HomeScreen />} />
          <Route path={ACCOUNT_PAGE} element={<AccountScreen />} />
          <Route path={SAV_PAGE} element={<SavScreen />} />
          <Route
            path={ACCOUNTING_SITUATION_PAGE}
            element={<AccountingSituationScreen />}
          />
          <Route path={ABOUT_PAGE} element={<AboutScreen />} />
          <Route path={CONFIGURATOR_PAGE} element={<ConfiguratorScreen />} />
          <Route path={ORDERS_PAGE} element={<OrdersScreen />} />
          <Route path={ORDER_PAGE} element={<OrderScreen />} />
          <Route path={FEEDBACK_PAGE} element={<FeedbackScreen />} />
          <Route path={RESET_PASSWORD_PAGE} element={<ResetPasswordScreen />} />
          <Route path={STATS_PAGE} element={<StatsScreen />} />
          <Route path={ADMIN_EDI_PAGE} element={<EdiScreen />} />
          <Route path={ARRIVAGE_PAGE}>
            <Route path="" element={<EntreeStockScreen />} />
            <Route path=":id" element={<ArrivageScreen ref={refArrivage} />} />
          </Route>
          <Route path={PDF_PAGE} element={<PdfScreen />} />
          <Route path={RAW_HTML_PAGE} element={<RawHtmlScreen />} />
          <Route path={ADMIN_SAV_PAGE} element={<AdminSavScreen />} />
          <Route path={ENTERING_INVOICE_PAGE}>
            <Route path=":id" element={<EnteringInvoiceScreen />} />
          </Route>
          <Route path={ADMIN_BRAND_PAGE} element={<AdminBrandScreen />} />
          <Route path={ADMIN_CRON_PAGE} element={<AdminCronScreen />} />
          <Route path={ADMIN_MAIL_PAGE} element={<AdminMailScreen />} />
          <Route path={ADMIN_CONSOLE_PAGE} element={<ConsoleScreen />} />
          <Route path={ADMIN_FEEDBACK_PAGE} element={<AdminFeedbackScreen />} />
          <Route path={ADMIN_PAYMENT_PAGE} element={<AdminPaymentScreen />} />
          <Route path={ADMIN_CATEGORY_PAGE} element={<AdminCategoryScreen />} />
          <Route path={FAVORIS_PAGE} element={<FavorisScreen />} />
          <Route path={FILTER_PAGE} element={<FilterScreen />} />
          <Route path={ADMIN_USER_PAGE} element={<UserScreen />} />
          <Route path={COMPARE_PAGE} element={<CompareScreen />} />
          <Route
            path={CONFIGURATION_PAGE}
            element={<AdminConfigurationScreen />}
          />
          <Route path={EXPEDITION_PAGE} element={<ExpeditionScreen />} />
          <Route path={FISCAL_CODE_PAGE} element={<FiscalCodeScreen />} />
          <Route path={CATEGORY_PAGE}>
            <Route path="" element={<CategoryScreen />} />
            <Route path=":slug" element={<ProductsScreen />} />
          </Route>
          <Route path={PRODUCTS_PAGE} element={<ProductsScreen />} />
          <Route path={TAG_PAGE} element={<TagScreen />} />
          <Route path={ADMIN_INVOICE_PAGE}>
            <Route path=":id" element={<InvoiceScreen />} />
          </Route>
          <Route path={PRODUCT_PAGE}>
            <Route path=":arRef" element={<ProductScreen />} />
          </Route>
          <Route path={PRODUCT_DUPLICATE_PAGE}>
            <Route path=":arRef" element={<ProductDuplicationScreen />} />
          </Route>
          <Route path={BROWSER_PRODUCT_FILTER_VALUE_PAGE}>
            <Route
              path=":arRef"
              element={<BrowserFilterAdminProductFilterValueComponent />}
            />
          </Route>
          <Route path={BROWSER_FILTER_VALUE_ASSO}>
            <Route
              path=":id"
              element={<BrowserFilterValueAssoFormComponent />}
            />
          </Route>
          <Route path={NEWS_PAGE} element={<NewsScreen />} />
          <Route path="*" element={<NotFoundScreen />} />
        </>
      ) : (
        <>
          <Route path="*" element={<LoadingScreen />} />
        </>
      )}
    </Routes>
  );
}

export default App;
