import React from "react";
import { DialogContent, DialogProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import LivraisonFormComponent from "./LivraisonFormComponent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FLivraisonInterface } from "../../../../interfaces/UserInterface";

interface State {
  onAddressChanged?: Function;
  fLivraison?: FLivraisonInterface;
  setFLivraisonForm?: Function;
}

const LivraisonsFormComponent: React.FC<State> = React.memo(
  ({ onAddressChanged, fLivraison, setFLivraisonForm }) => {
    const [openDialogForm, setOpenDialogForm] = React.useState(false);
    const [canCloseDialog, setCanCloseDialog] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();

    const handleCloseDialogForm = React.useCallback(
      (force: boolean = false) => {
        if (!canCloseDialog && !force) {
          return;
        }
        setOpenDialogForm(false);
        if (setFLivraisonForm) {
          setFLivraisonForm(undefined);
        }
      },
      [canCloseDialog, setFLivraisonForm]
    );

    const handleOpenDialogForm = React.useCallback(() => {
      setOpenDialogForm(true);
    }, []);

    React.useEffect(() => {
      if (fLivraison) {
        setOpenDialogForm(true);
      }
    }, [fLivraison]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          fullWidth={true}
          onClose={() => handleCloseDialogForm()}
          open={openDialogForm}
        >
          <DialogContent>
            <Typography sx={{ mb: 2 }} component="h2" variant="h6">
              {t(fLivraison ? "action.edit.livraison" : "action.add.livraison")}
            </Typography>
            <LivraisonFormComponent
              setCanCloseDialog={setCanCloseDialog}
              closeDialog={handleCloseDialogForm}
              onAddressChanged={onAddressChanged}
              fLivraison={fLivraison}
            />
          </DialogContent>
        </Dialog>
        <Box sx={{ textAlign: "center" }}>
          <IconButton size="large" onClick={handleOpenDialogForm}>
            <AddIcon />
          </IconButton>
        </Box>
      </>
    );
  }
);
export default LivraisonsFormComponent;
