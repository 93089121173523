import React from "react";
import { FilterFilterInterface } from "../../../../../../interfaces/FilterInterface";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../app/hooks";
import { RootState } from "../../../../../../app/store";
import { requestApi } from "../../../../../../helpers/RequestApi";
import { DELETE } from "../../../../../../utils/MethodUtils";
import { FILTER_URL } from "../../../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../../../../helpers/GetErrorApi";

interface State {
  filter: FilterFilterInterface | undefined;
  successFunction: Function;
  close: any;
}

const FilterDeleteComponent: React.FC<State> = React.memo(
  ({ filter, successFunction, close }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const save = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: DELETE,
        path: FILTER_URL + "/" + filter?.id,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 204) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.filter_deleted")
        );
        successFunction();
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [filter?.id, successFunction, t, token]);

    return (
      <>
        {filter && (
          <>
            <Typography>
              {t("word.deleteFilter.question")
                .replace("%name%", filter.name)
                .replace("%nbFARticles%", filter.nbFArticles.toString())}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <LoadingButton disabled={loading} onClick={close}>
                {t("word.cancel")}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={save}
                color="error"
              >
                {t("word.delete")}
              </LoadingButton>
            </Box>
          </>
        )}
      </>
    );
  }
);

export default FilterDeleteComponent;
