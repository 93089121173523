import React, { useRef } from "react";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FilterValueAssoInterface,
  FilterValueAssoValueFormInterface,
  FilterValueAssoValueInterface,
} from "../../../../../../interfaces/FilterInterface";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import { LoadingButton } from "@mui/lab";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import notEmptyValidator from "../../../../../../helpers/validator/NotEmptyValidator";
import { requestApi } from "../../../../../../helpers/RequestApi";
import { PATCH, POST } from "../../../../../../utils/MethodUtils";
import { FILTER_VALUE_ASSO_URL } from "../../../../../../utils/UrlsUtils";
import getErrorApi from "../../../../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { useAppSelector } from "../../../../../../app/hooks";
import { RootState } from "../../../../../../app/store";
import CkEditorComponent from "../../../../../content/CkEditorComponent";
import FilterValueAssoValueFormComponent from "./FilterValueAssoValueFormComponent";
import { CONDITION_OR } from "../../../../../../utils/FilterUtils";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import DividerWithContent from "../../../../DividerWithContent";

const clone = require("clone");

interface State {
  filterValueAsso?: FilterValueAssoInterface | null;
  successFunction?: Function;
}

interface FormState {
  name: InputInterface;
  bijection: InputInterface;
  filterValueAssoValues: (FilterValueAssoValueFormInterface | undefined)[];
}

const FilterValueAssoFormComponent: React.FC<State> = React.memo(
  ({ filterValueAsso, successFunction }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const newBrowser = useAppSelector(
      (state: RootState) => state.globalState.newBrowser
    );

    const getDefaultValues = React.useCallback((): FormState => {
      return {
        name: { value: filterValueAsso?.name ?? "", error: "" },
        bijection: { value: filterValueAsso?.bijection ?? false, error: "" },
        filterValueAssoValues: filterValueAsso
          ? clone(filterValueAsso.filterValueAssoValues).map(
              (filterValueAssoValue: FilterValueAssoValueInterface) => {
                return { ...filterValueAssoValue, ref: React.createRef() };
              }
            )
          : [
              {
                fromCondition: CONDITION_OR,
                toCondition: CONDITION_OR,
                filterValuesFrom: [],
                filterValuesTo: [],
                ref: React.createRef(),
              },
            ],
      };
    }, [filterValueAsso]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const fromTextRef: any = useRef();
    const toTextRef: any = useRef();

    const addFilterValueAssoValue = React.useCallback(() => {
      setValues((x) => {
        x.filterValueAssoValues.push({
          fromCondition: CONDITION_OR,
          toCondition: CONDITION_OR,
          filterValuesFrom: [],
          filterValuesTo: [],
          commonFilters: [],
          commonFiltersCondition: CONDITION_OR,
          ref: React.createRef(),
        });
        return { ...x };
      });
    }, []);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const handleChangeCheckbox = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      []
    );

    const deleteFilterValueAssoValue = React.useCallback((index: number) => {
      setValues((x) => {
        x.filterValueAssoValues[index] = undefined;
        return { ...x };
      });
    }, []);

    const save = React.useCallback(async () => {
      setLoading(true);
      const nameError = notEmptyValidator(values.name.value);
      const filterValueAssoValues = values.filterValueAssoValues
        .filter((filterValueAssoValue) => filterValueAssoValue)
        .map((filterValueAssoValue) =>
          filterValueAssoValue?.ref.current.getValue()
        );
      if (
        nameError ||
        filterValueAssoValues.findIndex((x) => x === undefined) >= 0
      ) {
        const newValue: FormState = { ...values };
        if (nameError) {
          newValue.name.error = nameError;
        }
        setValues(newValue);
        setLoading(false);
        return undefined;
      }
      const body: any = {
        name: values.name.value,
        bijection: values.bijection.value,
        textFrom: fromTextRef.current.getValue(),
        textTo: toTextRef?.current?.getValue() ?? null,
        filterValueAssoValues: filterValueAssoValues,
      };
      const response = await requestApi({
        method: filterValueAsso ? PATCH : POST,
        path:
          FILTER_VALUE_ASSO_URL +
          (filterValueAsso ? "/" + filterValueAsso.id : ""),
        allowError: true,
        token: token,
        body: body,
      });
      if (response.statusCode === 200 || response.statusCode === 201) {
        if (response.statusCode === 200) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.filter_value_asso_updated")
          );
        }
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.filter_value_asso_created")
          );
        }
        if (successFunction) {
          successFunction(response.content);
        }
        if (newBrowser) {
          setTimeout(() => {
            window.close();
          }, 500);
        }
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [filterValueAsso, newBrowser, successFunction, t, token, values]);

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [filterValueAsso]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} sx={{ display: "flex" }}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.name.error}
              helperText={t(values.name.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.name.value}
              onChange={handleChange("name")}
              label={t("field.name")}
              placeholder={t("field.name")}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.bijection.value}
                    onChange={handleChangeCheckbox("bijection")}
                  />
                }
                label={t("field.bijection")}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={values.bijection.value ? 6 : 12}>
            {values.bijection.value && (
              <Typography>{t("word.from")}</Typography>
            )}
            <CkEditorComponent
              ref={fromTextRef}
              text={filterValueAsso?.textFrom ?? ""}
              {...(!filterValueAsso && { defaultTitleSelect: 1 })}
            />
          </Grid>
          {values.bijection.value && (
            <Grid item xs={6}>
              <Typography>{t("word.to")}</Typography>
              <CkEditorComponent
                ref={toTextRef}
                text={filterValueAsso?.textTo ?? ""}
                {...(!filterValueAsso && { defaultTitleSelect: 1 })}
              />
            </Grid>
          )}
          {values.filterValueAssoValues.map(
            (filterValueAssoValue, indexFilterValueAssoValue) => (
              <React.Fragment key={indexFilterValueAssoValue}>
                {filterValueAssoValue && (
                  <>
                    <FilterValueAssoValueFormComponent
                      filterValueAssoValue={filterValueAssoValue}
                      index={indexFilterValueAssoValue}
                      deleteFilterValueAssoValue={deleteFilterValueAssoValue}
                      ref={filterValueAssoValue.ref}
                    />
                    {values.filterValueAssoValues.length - 1 !==
                      indexFilterValueAssoValue && (
                      <Grid item xs={12}>
                        <DividerWithContent
                          sxRoot={{ marginTop: 1 }}
                          content={t("word.or")}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </React.Fragment>
            )
          )}
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <IconButton onClick={addFilterValueAssoValue}>
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "right", width: "100%" }}>
            <LoadingButton variant="contained" loading={loading} onClick={save}>
              {t("word.save")}
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    );
  }
);

export default FilterValueAssoFormComponent;
