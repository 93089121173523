export const priceFormat = (
  price: number | undefined,
  locale: string,
  currency: string | undefined,
  currencyDisplay: string = "symbol",
  hideCent: boolean = false
) => {
  if (price !== undefined) {
    const config: any = {
      style: "currency",
      currency: currency,
      currencyDisplay: currencyDisplay,
    };
    if (hideCent) {
      config.minimumFractionDigits = 0;
      config.maximumFractionDigits = 0;
    }
    return new Intl.NumberFormat(locale, config).format(price / 100);
  }
  return "";
};
