import * as React from "react";
import ImportFiscalCodeComponent from "./ImportFiscalCodeComponent";
import GridFiscalCodeComponent from "./GridFiscalCodeComponent";
import Divider from "@mui/material/Divider";

const FiscalCodeComponent: React.FC = React.memo(({}) => {
  return (
    <>
      <ImportFiscalCodeComponent />
      <Divider sx={{ marginY: 2 }} />
      <GridFiscalCodeComponent />
    </>
  );
});

export default FiscalCodeComponent;
