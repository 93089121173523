import React, { forwardRef, SyntheticEvent, useImperativeHandle } from "react";
import { InputInterface } from "../../../../interfaces/InputInterface";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CategoryInterface } from "../../../../interfaces/CategoryInterface";
import { useTheme } from "@mui/material/styles";
import { CATEGORY_URL } from "../../../../utils/UrlsUtils";
import Badge from "@mui/material/Badge";

interface State {
  parent: CategoryInterface | null;
  category: CategoryInterface | undefined;
}

interface FormState {
  parent: InputInterface;
}

const FormParentCategoryComponent = React.memo(
  forwardRef(({ parent, category }: State, ref) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const categories = useAppSelector(
      (state: RootState) => state.globalState.categories
    );
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        parent: { value: parent, error: "" },
      };
    }, [parent]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const getCategories = React.useCallback(():
      | CategoryInterface[]
      | undefined => {
      const result: CategoryInterface[] | undefined = [];
      if (!categories) {
        return undefined;
      }
      for (const c of categories) {
        result.push(c);
        if (!c.children) {
          continue;
        }
        for (const child of c.children) {
          result.push(child);
        }
      }
      return result;
    }, [categories]);
    const [thisCategories, setThisCategories] = React.useState<
      CategoryInterface[] | undefined
    >(getCategories());

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        if (values.parent.value) {
          return CATEGORY_URL + "/" + values.parent.value.id;
        }
        return null;
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [parent]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setThisCategories(getCategories());
    }, [categories]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Autocomplete
        options={thisCategories ?? []}
        getOptionLabel={(c: CategoryInterface) =>
          "[" + c.id + "] " + c.name ?? ""
        }
        getOptionDisabled={(c: CategoryInterface) => c.id === category?.id}
        isOptionEqualToValue={(
          option: CategoryInterface,
          value: CategoryInterface
        ) => option.id === value.id}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={option.id}
              style={{
                display: "block",
                ...(option.parent !== null && { paddingLeft: "32px" }),
              }}
            >
              <span style={{ color: theme.palette.primary.main }}>
                {"[" + option.id + "] "}
              </span>
              <Badge
                color={option.enabled ? "success" : "error"}
                badgeContent=" "
                variant="dot"
              >
                {option.name}
              </Badge>
            </li>
          );
        }}
        onChange={handleChangeAutocomplete("parent")}
        value={values.parent.value}
        defaultValue={values.parent.value}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            error={!!values.parent.error}
            helperText={t(values.parent.error ?? "")}
            label={t("field.parentCategory")}
            placeholder={t("field.parentCategory")}
          />
        )}
      />
    );
  })
);

export default FormParentCategoryComponent;
