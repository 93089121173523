import * as React from "react";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  ORDER_TYPE_GLOBAL,
  ORDER_TYPE_PARTIAL,
} from "../../../../utils/OrderUtils";
import ContentComponent from "../../../content/ContentComponent";
import Button from "@mui/material/Button";

interface State {
  orderType: string;
  setOrderType: Function;
  handleNext: any;
  handleBack: any;
}

const StepperOrderTypeComponent: React.FC<State> = React.memo(
  ({ orderType, setOrderType, handleNext, handleBack }) => {
    const { t } = useTranslation();
    const [thisOrderType, setThisOrderType] = React.useState(orderType);

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrderType((event.target as HTMLInputElement).value);
        setThisOrderType((event.target as HTMLInputElement).value);
      },
      [setOrderType]
    );

    return (
      <>
        <ContentComponent contentName="orderType" saveLocalStorage={true} />
        <FormControl fullWidth={true}>
          <RadioGroup value={thisOrderType} onChange={handleChange}>
            <FormControlLabel
              value={ORDER_TYPE_GLOBAL}
              control={<Radio />}
              label={t("word.orderType." + ORDER_TYPE_GLOBAL.toLowerCase())}
            />
            <FormControlLabel
              value={ORDER_TYPE_PARTIAL}
              control={<Radio />}
              label={t("word.orderType." + ORDER_TYPE_PARTIAL.toLowerCase())}
            />
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ mt: 1, mr: 1 }}
          disabled={thisOrderType === ""}
        >
          {t("word.next")}
        </Button>
        <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          {t("word.back")}
        </Button>
      </>
    );
  }
);

export default StepperOrderTypeComponent;
