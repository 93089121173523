import React, { useImperativeHandle } from "react";
import {
  FilterFilterValueFormInterface,
  FilterFilterValueInterface,
} from "../../../../../../interfaces/FilterInterface";
import { Checkbox } from "@mui/material";
import { FormStateFilterForm } from "./FilterFormComponent";

interface State {
  filterValue: FilterFilterValueInterface;
  setValues: Function;
}

const FilterFormHoverComponent = React.memo(
  React.forwardRef(({ filterValue, setValues }: State, ref) => {
    const [checked, setChecked] = React.useState(filterValue.hover);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((x: FormStateFilterForm) => {
        const thisFilterValue = x.filterValues.value.find(
          (y: FilterFilterValueFormInterface) => y.refHover === ref
        );
        thisFilterValue.hover = event.target.checked;
        return { ...x };
      });
      setChecked(event.target.checked);
    };

    useImperativeHandle(ref, () => ({
      getValue() {
        return checked;
      },
    }));

    return <Checkbox checked={checked} onChange={handleChange} />;
  })
);

export default FilterFormHoverComponent;
