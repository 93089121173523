import * as React from "react";
import { useTranslation } from "react-i18next";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FISCAL_CODES_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { FiscalCodeInterface } from "../../../interfaces/FiscalCodeInterface";
import { useSearchParams } from "react-router-dom";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { Grid } from "@mui/material";
import FilterSearchComponent from "../filter/FilterSearchComponent";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

interface State {
  code: string;
}

const GridFiscalCodeSelectComponent: React.FC<State> = React.memo(
  ({ code }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const onClickCode = React.useCallback(
      (code: string) => {
        const searchParamsObject = searchParamToObject(searchParams);
        searchParamsObject.search = (code ?? "").substring(0, 4);
        searchParamsObject.page = 1;
        setSearchParams(searchParamsObject, {
          replace: true,
        });
      },
      [searchParams, setSearchParams]
    );

    return (
      <>
        <CopyClipboardComponent
          className="RobotoMono"
          component="span"
          text={code ?? ""}
        />
        <IconButton
          onClick={() => {
            onClickCode(code);
          }}
        >
          <SearchIcon />
        </IconButton>
      </>
    );
  }
);

const GridFiscalCodeComponent: React.FC = React.memo(() => {
  const { t, i18n } = useTranslation();
  const [init, setInit] = React.useState(false);
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [fiscalCodes, setFiscalCodes] = React.useState<
    FiscalCodeInterface[] | undefined
  >(undefined);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const [defaultItemsPerPage] = React.useState(50);
  const [totalItems, setTotalItems] = React.useState(0);
  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "code",
        headerName: t("column.code"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <GridFiscalCodeSelectComponent code={params.row.code} />
        ),
      },
      {
        field: "name",
        headerName: t("column.name"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
      },
    ];
  }, [t]);
  const [columns, setColumns] = React.useState<GridColDef[]>(getColumns());

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (fiscalCodes !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (
        !searchParamsObject.hasOwnProperty("page") ||
        searchParamsObject.page === "undefined"
      ) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("userIdentifier")) {
        searchParamsObject.userIdentifier = user?.userIdentifier;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[code]"] = "asc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: FISCAL_CODES_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"].map(
            (o: FiscalCodeInterface) => o.id
          )
        );
        const newFiscalCodes = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setFiscalCodes(newFiscalCodes);
        setTotalItems(response.content["hydra:totalItems"]);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      fiscalCodes,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setFiscalCodes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setFiscalCodes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <Grid sx={{ marginBottom: 1 }} container spacing={1}>
        <Grid item xs={12} md={6}>
          <FilterSearchComponent
            init={init}
            placeholder="word.search"
            updateSearchWithQueryParam={true}
          />
        </Grid>
      </Grid>
      {init && searchParamsObject.itemsPerPage && searchParamsObject.page && (
        // https://mui.com/x/react-data-grid/components/#pagination
        <DataGrid
          initialState={{
            sorting: {
              sortModel: getSortModel(),
            },
          }}
          loading={refreshPage}
          rows={refreshPage ? [] : fiscalCodes ?? []}
          onSortModelChange={onSortModelChange}
          sortingMode="server"
          page={Number(searchParamsObject.page) - 1}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={Number(searchParamsObject.itemsPerPage)}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          rowCount={totalItems}
          columns={columns}
          autoHeight={true}
          disableExtendRowFullWidth={true}
          localeText={getLocaleDataGrid(i18n.language)}
        />
      )}
    </>
  );
});

export default GridFiscalCodeComponent;
