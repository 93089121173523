import React, { ChangeEvent, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  STYLE_COMPACT_INLINE,
  STYLE_STRETCH_INLINE,
  STYLE_SUPER_COMPACT_INLINE,
} from "../../../utils/FArticleUtils";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InfoIcon from "@mui/icons-material/Info";
import { set } from "../../../app/globalSlice";
import CircleIcon from "@mui/icons-material/Circle";
import { grey } from "@mui/material/colors";
import {
  STORAGE_FONT_SIZE,
  STORAGE_PRICE_HT_COLOR,
  STORAGE_PRICE_TTC_COLOR,
  STORAGE_SHOW_ALL_FILTERS,
  STORAGE_SHOW_CONSTRUCTOR_REF,
  STORAGE_SHOW_HT,
  STORAGE_SHOW_KEYWORDS,
  STORAGE_SHOW_NOVE_REF,
  STORAGE_SHOW_TTC,
} from "../../../utils/StorageUtils";

interface State {
  handleInline: Function;
  inline: boolean;
  styleInline: string;
  handleStyleInline: Function;
  handleNbGroup: Function;
  changeShowIcons: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  changeInfinitScroll: Function;
  nbGroup: number;
}

export const defaultFontSize = 10;
const maxFontSize = 20;
const minFontSize = 1;
const maxColumn = 9;
const minColumn = 1;

const ConfigureViewProductsComponent = React.memo(
  React.forwardRef(
    (
      {
        handleInline,
        inline,
        styleInline,
        handleStyleInline,
        handleNbGroup,
        nbGroup,
        changeShowIcons,
        changeInfinitScroll,
      }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const theme = useTheme();
      const dispatch = useAppDispatch();
      const showIcons = useAppSelector(
        (state: RootState) => state.globalState.showIcons
      );
      const infinitScroll = useAppSelector(
        (state: RootState) => state.globalState.infinitScroll
      );
      const fontSize = useAppSelector(
        (state: RootState) => state.globalState.fontSize
      );
      const showKeywords = useAppSelector(
        (state: RootState) => state.globalState.showKeywords
      );
      const showNoveRef = useAppSelector(
        (state: RootState) => state.globalState.showNoveRef
      );
      const showConstructorRef = useAppSelector(
        (state: RootState) => state.globalState.showConstructorRef
      );
      const showTTC = useAppSelector(
        (state: RootState) => state.globalState.showTTC
      );
      const showHT = useAppSelector(
        (state: RootState) => state.globalState.showHT
      );
      const showAllFilters = useAppSelector(
        (state: RootState) => state.globalState.showAllFilters
      );
      const priceTTCColor = useAppSelector(
        (state: RootState) => state.globalState.priceTTCColor
      );
      const priceHTColor = useAppSelector(
        (state: RootState) => state.globalState.priceHTColor
      );
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const [show, setShow] = React.useState(false);

      const changeValue = React.useCallback(
        (key: string, storageKey: string, value: any) => {
          localStorage.setItem(storageKey, value.toString());
          dispatch(set({ [key]: value }));
        },
        [dispatch]
      );

      useImperativeHandle(ref, () => ({
        toggleShow() {
          setShow((x) => !x);
        },
      }));

      React.useEffect(() => {
        if (!nbGroup) {
          return;
        }
        if (isNaN(Number(nbGroup))) {
          handleNbGroup(4);
          return;
        }
        if (Number(nbGroup) < minColumn || Number(nbGroup) > maxColumn) {
          handleNbGroup(4);
        }
      }, [nbGroup]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {show && (
            <Card variant="outlined">
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ marginRight: 2 }}>
                    {t("word.displayMode") + ":"}
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={inline}
                    exclusive
                    onChange={(event, value) => {
                      handleInline(value);
                    }}
                  >
                    <ToggleButton value={true}>{t("word.line")}</ToggleButton>
                    <ToggleButton value={false}>
                      {t("word.column")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {!isSmall && (
                    <>
                      {inline ? (
                        <ToggleButtonGroup
                          color="primary"
                          value={styleInline}
                          sx={{ marginLeft: 2 }}
                          exclusive
                          onChange={(event, value) => {
                            handleStyleInline(value);
                          }}
                        >
                          <ToggleButton value={STYLE_STRETCH_INLINE}>
                            {t("word.spread")}
                          </ToggleButton>
                          <ToggleButton value={STYLE_COMPACT_INLINE}>
                            {t("word.normal")}
                          </ToggleButton>
                          <ToggleButton value={STYLE_SUPER_COMPACT_INLINE}>
                            {t("word.compact")}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      ) : (
                        <>
                          <FormControl
                            variant="outlined"
                            sx={{ marginLeft: 2, maxWidth: 120 }}
                          >
                            <OutlinedInput
                              type="number"
                              className="center-input"
                              readOnly={true}
                              value={nbGroup}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    disabled={nbGroup >= maxColumn}
                                    onClick={(event) => {
                                      handleNbGroup(Number(nbGroup) + 1);
                                    }}
                                    edge="end"
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              startAdornment={
                                <InputAdornment position="start">
                                  <IconButton
                                    disabled={nbGroup <= minColumn}
                                    onClick={(event) => {
                                      handleNbGroup(Number(nbGroup) - 1);
                                    }}
                                    edge="end"
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </>
                      )}
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 1,
                    flexWrap: "wrap",
                  }}
                >
                  <Typography sx={{ marginRight: 2 }}>
                    {t("word.displayType") + ":"}
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={infinitScroll}
                    exclusive
                    onChange={(event, value) => {
                      changeInfinitScroll(value);
                    }}
                  >
                    <ToggleButton value={true}>
                      {t("word.paging.infinite")}
                      <Tooltip title={t("sentence.paging.infinite")}>
                        <InfoIcon />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={false}>
                      {t("word.paging.page")}
                      <Tooltip title={t("sentence.paging.page")}>
                        <InfoIcon />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <FormControl
                  variant="outlined"
                  sx={{ maxWidth: 150, marginTop: 1 }}
                >
                  <InputLabel>{t("field.fontSize")}</InputLabel>
                  <OutlinedInput
                    type="number"
                    className="center-input"
                    readOnly={true}
                    value={fontSize ?? defaultFontSize}
                    label={t("field.fontSize")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={
                            (fontSize ?? defaultFontSize) >= maxFontSize
                          }
                          onClick={(event) => {
                            const newFontSize =
                              (fontSize ?? defaultFontSize) + 1;
                            if (newFontSize > maxFontSize) {
                              return;
                            }
                            changeValue(
                              "fontSize",
                              STORAGE_FONT_SIZE,
                              newFontSize
                            );
                          }}
                          edge="end"
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          disabled={
                            (fontSize ?? defaultFontSize) <= minFontSize
                          }
                          onClick={(event) => {
                            const newFontSize =
                              (fontSize ?? defaultFontSize) - 1;
                            if (newFontSize < minFontSize) {
                              return;
                            }
                            changeValue(
                              "fontSize",
                              STORAGE_FONT_SIZE,
                              newFontSize
                            );
                          }}
                          edge="end"
                        >
                          <RemoveIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showIcons}
                        onChange={changeShowIcons}
                      />
                    }
                    label={t("word.displayIcons")}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showKeywords !== false}
                        onChange={(event, checked) =>
                          changeValue(
                            "showKeywords",
                            STORAGE_SHOW_KEYWORDS,
                            checked
                          )
                        }
                      />
                    }
                    label={t("word.displayKeywords")}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showNoveRef !== false}
                        onChange={(event, checked) =>
                          changeValue(
                            "showNoveRef",
                            STORAGE_SHOW_NOVE_REF,
                            checked
                          )
                        }
                      />
                    }
                    label={t("word.displayNoveRef")}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showConstructorRef !== false}
                        onChange={(event, checked) =>
                          changeValue(
                            "showConstructorRef",
                            STORAGE_SHOW_CONSTRUCTOR_REF,
                            checked
                          )
                        }
                      />
                    }
                    label={t("word.displayConstructorRef")}
                  />
                </FormGroup>
                {/*todo*/}
                {/*<FormGroup>*/}
                {/*  <FormControlLabel*/}
                {/*    control={*/}
                {/*      <Checkbox*/}
                {/*        checked={showStockTerme}*/}
                {/*        onChange={(event, checked) =>*/}
                {/*          changeValue(*/}
                {/*            "showStockTerme",*/}
                {/*            STORAGE_SHOW_STOCK_TERME,*/}
                {/*            checked*/}
                {/*          )*/}
                {/*        }*/}
                {/*      />*/}
                {/*    }*/}
                {/*    label={t("word.stockATermeAncienSite")}*/}
                {/*  />*/}
                {/*</FormGroup>*/}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showTTC !== false}
                          onChange={(event, checked) =>
                            changeValue("showTTC", STORAGE_SHOW_TTC, checked)
                          }
                        />
                      }
                      label={t("word.displayTTC")}
                    />
                  </FormGroup>
                  <ToggleButtonGroup
                    value={priceTTCColor ?? "grey"}
                    exclusive
                    onChange={(event, value) =>
                      changeValue(
                        "priceTTCColor",
                        STORAGE_PRICE_TTC_COLOR,
                        value
                      )
                    }
                  >
                    <ToggleButton value="purple">
                      <CircleIcon
                        sx={{
                          width: "30px",
                          color: theme.palette.primary.main,
                        }}
                      />
                    </ToggleButton>
                    <ToggleButton value="grey">
                      <CircleIcon sx={{ width: "30px", color: grey[500] }} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showHT !== false}
                          onChange={(event, checked) =>
                            changeValue("showHT", STORAGE_SHOW_HT, checked)
                          }
                        />
                      }
                      label={t("word.displayHT")}
                    />
                  </FormGroup>
                  <ToggleButtonGroup
                    value={priceHTColor ?? "purple"}
                    exclusive
                    onChange={(event, value) =>
                      changeValue("priceHTColor", STORAGE_PRICE_HT_COLOR, value)
                    }
                  >
                    <ToggleButton value="purple">
                      <CircleIcon
                        sx={{
                          width: "30px",
                          color: theme.palette.primary.main,
                        }}
                      />
                    </ToggleButton>
                    <ToggleButton value="grey">
                      <CircleIcon sx={{ width: "30px", color: grey[500] }} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <FormGroup sx={{ flexDirection: "row" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showAllFilters === true}
                        onChange={(event, checked) =>
                          changeValue(
                            "showAllFilters",
                            STORAGE_SHOW_ALL_FILTERS,
                            checked
                          )
                        }
                      />
                    }
                    label={t("word.displayAllFilters")}
                  />
                  <Tooltip
                    title={t("sentence.displayAllFilters")}
                    placement="top"
                  >
                    <IconButton size="large">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </FormGroup>
              </CardContent>
            </Card>
          )}
        </>
      );
    }
  )
);

export default ConfigureViewProductsComponent;
