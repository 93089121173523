import React, { forwardRef, useImperativeHandle, useRef } from "react";
import SearchProductBooleanComponent from "./SearchProductBooleanComponent";
import SearchProductPreviewComponent from "./SearchProductPreviewComponent";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputInterface } from "../../../interfaces/InputInterface";
import {
  DEFAULT_NB_PRODUCT_SLIDER,
  ELASTIC_STOCK_ALL,
} from "../../../utils/FArticleUtils";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import Divider from "@mui/material/Divider";
import SearchProductFArticleSellingComponent from "./SearchProductFArticleSellingComponent";
import SearchProductTextComponent from "./SearchProductText/SearchProductTextComponent";
import SearchEntityAutocompleteComponent from "./SearchEntityAutocompleteComponent";
import SearchProductSelectComponent from "./SearchProductSelectComponent";

interface State {
  data: any;
  fArticle: FArticleSmallInterface | undefined;
}

interface FormState {
  nbProductsDisplay: InputInterface;
}

const SearchProductComponent = React.memo(
  forwardRef(({ data, fArticle }: State, ref) => {
    const spotlightRef: any = useRef();
    const optiontRef: any = useRef();
    const tagsRef: any = useRef();
    const stockRef: any = useRef();
    const fArticleSellingTypeRef: any = useRef();
    const textsRef: any = useRef();
    const { t } = useTranslation();

    const [values, setValues] = React.useState<FormState>({
      nbProductsDisplay: {
        value:
          data?.nbProductsDisplay && Number(data.nbProductsDisplay) > 0
            ? data.nbProductsDisplay
            : DEFAULT_NB_PRODUCT_SLIDER,
        error: "",
      },
    });

    const getValue = React.useCallback(() => {
      const result: any = {
        nbProductsDisplay:
          Number(values.nbProductsDisplay.value) ?? DEFAULT_NB_PRODUCT_SLIDER,
        ...tagsRef.current.getValue(),
      };
      const spotlightRefValue = spotlightRef.current.getValue();
      const optiontRefValue = optiontRef.current.getValue();
      const stockRefValue = stockRef.current.getValue();
      const textsRefValue = textsRef.current.getValue();
      const fArticleSellingTypeRefValue =
        fArticleSellingTypeRef.current?.getValue();
      if (textsRefValue !== null) {
        result.texts = textsRefValue;
      }
      if (spotlightRefValue !== null) {
        result.spotlight = spotlightRefValue;
      }
      if (optiontRefValue !== null) {
        result.hasOption = optiontRefValue;
      }
      if (stockRefValue !== null) {
        result.stock = stockRefValue;
      }
      if (typeof fArticleSellingTypeRefValue === "string") {
        if (!result.fArticle) {
          result.fArticle = {};
        }
        result.fArticle.fArticleSellingType = fArticleSellingTypeRefValue;
      }
      return result;
    }, [values.nbProductsDisplay.value]);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    return (
      <Box>
        <Grid sx={{ marginTop: 2 }} container spacing={1}>
          <Grid item xs={12}>
            <Typography component="p" variant="h5" sx={{ textAlign: "center" }}>
              {t("word.display")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel>{t("field.nbProductsDisplay")}</InputLabel>
              <OutlinedInput
                error={!!values.nbProductsDisplay.error}
                type="number"
                className="center-input"
                value={values.nbProductsDisplay.value}
                onChange={handleChange("nbProductsDisplay")}
                label={t("field.nbProductsDisplay")}
              />
              {!!values.nbProductsDisplay.error && (
                <FormHelperText error>
                  {t(values.nbProductsDisplay.error ?? "")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: 2 }} container spacing={1}>
          <Grid item xs={12}>
            <Typography component="p" variant="h5" sx={{ textAlign: "center" }}>
              {t("word.text")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SearchProductTextComponent data={data} ref={textsRef} />
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: 2 }} container spacing={1}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography component="p" variant="h5">
              {t("word.parameter")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <SearchProductBooleanComponent
              ref={spotlightRef}
              name="spotlight"
              data={data}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SearchProductSelectComponent
              ref={stockRef}
              name="stock"
              data={data}
              options={ELASTIC_STOCK_ALL}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SearchProductBooleanComponent
              ref={optiontRef}
              name="hasOption"
              data={data}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <SearchEntityAutocompleteComponent
              name="tags[]"
              data={data}
              ref={tagsRef}
            />
          </Grid>
        </Grid>
        {fArticle && (
          <Grid sx={{ marginTop: 2 }} container spacing={1}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography component="p" variant="h5">
                {t("word.product")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <SearchProductFArticleSellingComponent
                ref={fArticleSellingTypeRef}
                data={data}
              />
            </Grid>
          </Grid>
        )}
        <Grid sx={{ marginTop: 2 }} container spacing={1}>
          <Grid item xs={12}>
            <Divider sx={{ mb: 2 }} />
            <SearchProductPreviewComponent
              getValue={getValue}
              fArticle={fArticle}
            />
          </Grid>
        </Grid>
      </Box>
    );
  })
);

export default SearchProductComponent;
