import React from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import TabPanelComponent from "../components/common/TabPanelComponent";
import ContentComponent from "../components/content/ContentComponent";
import ConfiguratorEnergyComponent from "../components/common/configurator/ConfiguratorEnergyComponent";
import ConfiguratorComestibleComponent from "../components/common/configurator/ConfiguratorComestibleComponent";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getTitle, searchParamToObject } from "../helpers/SearchParamHelper";

const ConfiguratorScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const getValue = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return searchParamsObject.tab ? Number(searchParamsObject.tab) : 0;
  }, [searchParams]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [value, setValue] = React.useState(getValue());
  const { t } = useTranslation();

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      const newSearchParams = searchParamToObject(searchParams);
      newSearchParams.tab = newValue.toString();
      setSearchParams(newSearchParams, {
        replace: true,
      });
    },
    [searchParams, setSearchParams]
  );

  const load = React.useCallback(
    (force: boolean = false) => {
      setInit(true);
      dispatch(set({ refreshPage: false }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    document.title = getTitle("configuration");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <ContentComponent contentName="configurator" saveLocalStorage={true} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          variant={!isSmall ? "standard" : "scrollable"}
          centered={!isSmall}
        >
          <Tab label={t("word.configuratorEnergy")} />
          <Tab label={t("word.configuratorComestible")} />
        </Tabs>
      </Box>
      <TabPanelComponent sx={{ marginTop: 1 }} value={value} index={0}>
        <ConfiguratorEnergyComponent />
      </TabPanelComponent>
      <TabPanelComponent sx={{ marginTop: 1 }} value={value} index={1}>
        <ConfiguratorComestibleComponent />
      </TabPanelComponent>
    </Layout>
  );
});

export default ConfiguratorScreen;
