import * as React from "react";
import { useRef } from "react";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import { getUrlFArticleImage } from "../../../helpers/FileHelper";
import {
  IMAGE_SIZE_FULL,
  IMAGE_SIZE_LOGO,
  IMAGE_SIZE_NORMAL,
  IMAGE_SIZE_PHONE,
} from "../../../utils/FileUtils";
import HideImageIcon from "@mui/icons-material/HideImage";
import Slider from "react-slick";
import { DialogProps, Skeleton, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  dots: boolean;
  singleImage?: boolean;
  dialog?: boolean;
  setEdit?: any;
  removeTransform?: boolean;
}

interface State2 {
  fArticle: FArticleSmallInterface | undefined;
  dots: boolean;
  singleImage?: boolean;
  size: number;
  handleOpen?: Function;
  afterChange?: Function;
  initialSlide?: number;
}

const ThisSliderComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        dots,
        singleImage,
        size,
        handleOpen,
        afterChange,
        initialSlide,
      }: State2,
      ref
    ) => {
      const thisSliderRef: any = useRef();
      const thisHandleOpen = React.useCallback(() => {
        if (handleOpen) {
          handleOpen();
        }
      }, [handleOpen]);

      const thisAfterChange = React.useCallback(
        (index: number) => {
          if (afterChange) {
            afterChange(index);
          }
        },
        [afterChange]
      );

      return (
        <Slider
          ref={thisSliderRef}
          afterChange={thisAfterChange}
          initialSlide={initialSlide ?? 0}
          dots={dots}
          infinite={true}
          speed={100}
          dotsClass="slick-dots slick-dots-image"
          customPaging={(i) =>
            !(
              fArticle?.fArticleProp?.images !== null &&
              fArticle?.fArticleProp?.images !== undefined &&
              fArticle?.fArticleProp?.images.length > 0
            ) || fArticle?.fArticleProp === undefined ? (
              <div>{i}</div>
            ) : (
              <img
                key={i}
                src={
                  (process.env.REACT_APP_API_URL ?? "") +
                  getUrlFArticleImage(
                    fArticle.arRef,
                    fArticle.fArticleProp?.images[i],
                    IMAGE_SIZE_LOGO
                  )
                }
                className={"objectFitCover"}
                style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
                alt={"[" + fArticle.arRef + "] " + fArticle.arDesign}
                loading="lazy"
              />
            )
          }
        >
          {fArticle?.fArticleProp?.images !== null &&
          fArticle?.fArticleProp?.images !== undefined &&
          fArticle?.fArticleProp?.images.length > 0 ? (
            fArticle?.fArticleProp?.images
              ?.filter((fArticleImage, indexFArticleImage) => {
                if (singleImage === true) {
                  return indexFArticleImage === 0;
                }
                return true;
              })
              .map((fArticleImage, indexFArticleImage) => (
                <img
                  key={indexFArticleImage}
                  onClick={thisHandleOpen}
                  src={
                    (process.env.REACT_APP_API_URL ?? "") +
                    getUrlFArticleImage(fArticle.arRef, fArticleImage, size)
                  }
                  className={
                    "objectFitCover " + (handleOpen ? "cursor-pointer" : "")
                  }
                  alt={"[" + fArticle.arRef + "] " + fArticle.arDesign}
                  loading="lazy"
                />
              ))
          ) : (
            <HideImageIcon
              sx={{
                width: "50%",
                height: "50%",
              }}
            />
          )}
        </Slider>
      );
    }
  )
);

const FArticleSliderComponent: React.FC<State> = React.memo(
  ({ fArticle, dots, singleImage, dialog, setEdit, removeTransform }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogInitialSlide, setDialogInitialSlide] = React.useState(0);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const sliderRef = useRef();

    const handleClose = React.useCallback(() => {
      setOpenDialog(false);
    }, []);

    const afterChange = React.useCallback((index: number) => {
      setDialogInitialSlide(index);
    }, []);

    const handleOpen = React.useCallback(() => {
      if (isSmall) {
        return;
      }
      setOpenDialog(true);
    }, [isSmall]);

    return (
      <>
        {dialog && !isSmall && (
          <Dialog
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            fullWidth={true}
            onClose={handleClose}
            open={openDialog}
          >
            <ThisSliderComponent
              initialSlide={dialogInitialSlide}
              fArticle={fArticle}
              dots={dots}
              singleImage={singleImage}
              size={IMAGE_SIZE_FULL}
            />
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <LoadingButton onClick={handleClose}>
                {t("word.close")}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )}
        <Box
          sx={{
            position: "sticky",
            top: 0,
            margin: 0,
            height: "100%",
          }}
        >
          <Box
            sx={{
              position: "relative",
              ...(!removeTransform && {
                top: "50%",
                transform: "translateY(-50%)",
              }),
            }}
          >
            {!fArticle?.fArticleProp ? (
              <Skeleton
                variant="rectangular"
                height={IMAGE_SIZE_PHONE}
                width="100%"
              />
            ) : (
              <>
                {isAdmin && setEdit && (
                  <Box sx={{ textAlign: "center" }}>
                    <IconButton onClick={setEdit}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                )}
                <ThisSliderComponent
                  ref={sliderRef}
                  fArticle={fArticle}
                  dots={dots}
                  singleImage={singleImage}
                  size={IMAGE_SIZE_NORMAL}
                  handleOpen={handleOpen}
                  afterChange={afterChange}
                />
              </>
            )}
          </Box>
        </Box>
      </>
    );
  }
);

export default FArticleSliderComponent;
