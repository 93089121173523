import * as React from "react";
import { useImperativeHandle, useRef } from "react";
import {
  FArticleSellingInterface,
  FArticleSmallInterface,
} from "../../../../interfaces/FArticleInterface";
import { FormHelperText, Grid, TextField, Tooltip } from "@mui/material";
import { InputInterface } from "../../../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  ALL_SELLING,
  ELASTIC_STOCK_AVAILABLE,
  SELLING_FILTER_VALUE,
  SELLING_TEXT,
} from "../../../../utils/FArticleUtils";
import {
  objectToQuery,
  requestApi,
  RequestApiResponseInterface,
} from "../../../../helpers/RequestApi";
import { GET } from "../../../../utils/MethodUtils";
import {
  FARTICLE_SELLING_URL,
  FARTICLE_URL,
} from "../../../../utils/UrlsUtils";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { toastr } from "react-redux-toastr";
import SearchComponent from "../../SearchComponent";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import notEmptyValidator from "../../../../helpers/validator/NotEmptyValidator";
import SliderFArticleComponent from "../../../slider/SliderFArticleComponent";
import { STORAGE_SELLING_DISPLAY } from "../../../../utils/StorageUtils";
import Box from "@mui/material/Box";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ListIcon from "@mui/icons-material/List";
import IconButton from "@mui/material/IconButton";
import FilterValueAssoFilterValueFormComponent from "../../filter/admin/form/filterForm/FilterValueAssoFilterValueFormComponent";
import { CONDITION_OR } from "../../../../utils/FilterUtils";
import sage69Validator from "../../../../helpers/validator/Sage69Validator";

interface State {
  fArticleSelling: FArticleSellingInterface | undefined;
  setHasDuplicateError?: Function;
}

interface FormState {
  identifier: InputInterface;
  type: InputInterface;
  type2: InputInterface;
  fArticlePropFounds: FArticleSmallInterface[] | undefined;
}

const FArticleFormSellingComponent = React.memo(
  React.forwardRef(({ fArticleSelling, setHasDuplicateError }: State, ref) => {
    const { t } = useTranslation();
    const [search, setSearch] = React.useState<string>(
      () => fArticleSelling?.identifier ?? ""
    );
    const refFilterValues: any = useRef();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        identifier: {
          value: fArticleSelling?.identifier ?? "",
          error: "",
        },
        type: { value: fArticleSelling?.type ?? "", error: "" },
        type2: { value: fArticleSelling?.type2 ?? "", error: "" },
        fArticlePropFounds: undefined,
      };
    }, [
      fArticleSelling?.identifier,
      fArticleSelling?.type,
      fArticleSelling?.type2,
    ]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const [inline] = React.useState<boolean>(true);

    const updateIdentifierValue = React.useCallback(() => {
      setValues((v) => {
        return {
          ...v,
          ...{
            identifier: {
              value: search,
              error: "",
            },
          },
        };
      });
    }, [search]);

    const handleChangeText = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
      },
      []
    );

    const updateFArticlePropFound = React.useCallback(async () => {
      setValues((v) => {
        return {
          ...v,
          ...{ fArticlePropFounds: undefined },
        };
      });
      let response: RequestApiResponseInterface = {
        statusCode: 200,
        content: [],
      };
      if (values.type2.value === SELLING_TEXT) {
        const identifier = values.identifier.value.trim();
        if (identifier === "") {
          setValues((v) => {
            return { ...v, ...{ fArticlePropFounds: [] } };
          });
          return;
        }
        response = await requestApi({
          method: GET,
          path: FARTICLE_SELLING_URL.replace("{identifier}", identifier),
          allowError: false,
          paginate: false,
          token: token,
          timeout: 30_000,
        });
      }
      if (values.type2.value === SELLING_FILTER_VALUE) {
        const filterValues = refFilterValues.current.getValue();
        if (!filterValues || filterValues.length === 0) {
          setValues((v) => {
            return { ...v, ...{ fArticlePropFounds: [] } };
          });
          return;
        }
        response = await requestApi({
          method: GET,
          path:
            FARTICLE_URL +
            objectToQuery({
              stock: ELASTIC_STOCK_AVAILABLE,
              indexable: 1,
              itemsPerPage: 20,
              "order[stock]": "asc",
              "filterValues[]": [
                JSON.stringify({
                  condition: CONDITION_OR,
                  values: filterValues.map((x: string) =>
                    x.replace(/[^\d.]/g, "")
                  ),
                }),
              ],
            }),
          allowError: false,
          paginate: false,
          token: token,
          timeout: 30_000,
        });
        if (response.statusCode === 200) {
          response.content = response.content.fArticles;
        }
      }
      if (response.statusCode === 200) {
        setValues((v) => {
          return {
            ...v,
            ...{ fArticlePropFounds: response.content },
          };
        });
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else if (response.statusCode === 404) {
        setValues((v) => {
          return { ...v, ...{ fArticlePropFounds: [] } };
        });
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
    }, [t, token, values.identifier.value, values.type2.value]);

    const changeType2 = React.useCallback((newValue: string) => {
      setValues((v) => {
        v.type2.value = newValue;
        return { ...v };
      });
    }, []);

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      []
    );

    const getValue = React.useCallback(() => {
      const filterValues = refFilterValues.current?.getValue();
      const identifierError = sage69Validator(values.identifier.value);
      const filterValuesError = notEmptyValidator(filterValues);
      const typeError = notEmptyValidator(values.type.value);
      if (
        (values.type2.value === SELLING_TEXT && identifierError) ||
        (values.type2.value === SELLING_FILTER_VALUE && filterValuesError) ||
        typeError
      ) {
        const newValue: FormState = { ...values };
        if (values.type2.value === SELLING_TEXT && identifierError) {
          newValue.identifier.error = identifierError;
        }
        if (typeError) {
          newValue.type.error = typeError;
        }
        setValues(newValue);
        return undefined;
      }
      return {
        ...(values.type2.value === SELLING_TEXT
          ? { identifier: values.identifier.value }
          : { identifier: null }),
        ...(values.type2.value === SELLING_FILTER_VALUE
          ? { filterValues: filterValues }
          : { filterValues: [] }),
        type: values.type.value,
        type2: values.type2.value,
      };
    }, [values]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [fArticleSelling]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        updateIdentifierValue();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      updateFArticlePropFound();
    }, [values.identifier.value, values.type2.value]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (setHasDuplicateError) {
        setHasDuplicateError(false);
      }
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {fArticleSelling && (
          <Grid container spacing={1} sx={{ marginTop: 1 }}>
            <Grid item xs={12} md={inline ? 4 : 8}>
              <Box>
                <Tooltip title={t("field.identifier")} placement="top">
                  <IconButton
                    size="small"
                    onClick={() => changeType2(SELLING_TEXT)}
                  >
                    <TextFieldsIcon sx={{ cursor: "pointer" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("field.filterValue")} placement="top">
                  <IconButton
                    size="small"
                    onClick={() => changeType2(SELLING_FILTER_VALUE)}
                  >
                    <ListIcon sx={{ cursor: "pointer" }} />
                  </IconButton>
                </Tooltip>
              </Box>
              {values.type2.value === SELLING_TEXT && (
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  value={search}
                  onChange={handleChangeText}
                  error={!!values.identifier.error}
                  helperText={t(values.identifier.error ?? "")}
                  sx={{ width: "100%" }}
                  required
                  label={t("field.identifier")}
                />
              )}
              {values.type2.value === SELLING_FILTER_VALUE && (
                <FilterValueAssoFilterValueFormComponent
                  ref={refFilterValues}
                  filterValueAssoFilters={fArticleSelling.filterValues}
                  onChange={updateFArticlePropFound}
                />
              )}
              <FormControl
                fullWidth
                required
                error={!!values.type.error}
                sx={{ marginTop: 1 }}
              >
                <InputLabel id="type-label">{t("field.type")}</InputLabel>
                <Select
                  labelId="type-label"
                  value={values.type.value}
                  label={t("field.type")}
                  onChange={handleChangeSelect("type")}
                >
                  {ALL_SELLING.map((sellingType, indexSellingType) => (
                    <MenuItem value={sellingType} key={indexSellingType}>
                      {t("word.fArticleSelling.type." + sellingType)}
                    </MenuItem>
                  ))}
                </Select>
                {values.type.error !== "" && (
                  <FormHelperText error>
                    {t(values.type.error ?? "")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={inline ? 8 : 4} sx={{ textAlign: "center" }}>
              {values.fArticlePropFounds === undefined ? (
                <SearchComponent nbColumn={1} nbLines={1} height={150} />
              ) : values.fArticlePropFounds.length === 0 ? (
                <SentimentVeryDissatisfiedIcon
                  sx={{ fontSize: "3em" }}
                  color="secondary"
                />
              ) : (
                <SliderFArticleComponent
                  fArticles={values.fArticlePropFounds}
                  initNbProductsDisplay={1}
                  initInline={true}
                  hideDisplayStorage={true}
                  displayStorage={STORAGE_SELLING_DISPLAY}
                />
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  })
);

export default FArticleFormSellingComponent;
