export const STORAGE_TOKEN = "token";
export const STORAGE_CART = "cart";
export const STORAGE_NB_GROUP = "nbGroup";
export const STORAGE_INDEXABLE = "indexable";
export const STORAGE_CONTENT = "content";
export const TIME_RESEND_MAIL = "resendMail";
export const STORAGE_INLINE_FARTICLE_CATEGORY = "inlineFarticleCategory";
export const STYLE_INLINE = "styleInline";
export const COLLAPSED = "collapsed";
export const COOKIE_TOKEN = "cookie";
export const STORAGE_CATEGORY_DISPLAY = "category";
export const STORAGE_SPEED_DIAL_ARTICLE = "speedDialArticle";
export const STORAGE_LOCK = "lock";
export const STORAGE_STATE = "storageState";
export const SUFFIX_DISPLAY = "Display";
export const STORAGE_AFTER_ARTICLE_DISPLAY = "afterArticle" + SUFFIX_DISPLAY;
export const STORAGE_ORDER_DISPLAY = "orderArticle" + SUFFIX_DISPLAY;
export const STORAGE_SELLING_DISPLAY = "sellingArticle" + SUFFIX_DISPLAY;
export const STORAGE_PRODUCT_PREVIEW_DISPLAY =
  "previewArticle" + SUFFIX_DISPLAY;
export const STORAGE_DEFAULT_ARTICLE_DISPLAY =
  "defaultArticle" + SUFFIX_DISPLAY;
export const STORAGE_NEW_BROWSER = "newBrowser";
export const STORAGE_STICKY_EDITOR = "stickyEditor";
export const STORAGE_ICONS = "showIcons";
export const STORAGE_INFINIT_SCROLL = "infinitScroll";
export const STORAGE_SHOW_KEYWORDS = "showKeywords";
export const STORAGE_FONT_SIZE = "fontSize";
export const STORAGE_SHOW_NOVE_REF = "showNoveRef";
export const STORAGE_SHOW_CONSTRUCTOR_REF = "showConstructorRef";
export const STORAGE_SHOW_STOCK_TERME = "showStockTerme";
export const STORAGE_SHOW_TTC = "showTTC";
export const STORAGE_SHOW_HT = "showHT";
export const STORAGE_SHOW_ALL_FILTERS = "showAllFilters";
export const STORAGE_PRICE_TTC_COLOR = "priceTTCColor";
export const STORAGE_PRICE_HT_COLOR = "priceHTColor";
export const STORAGE_EDITING_FDOCENTETE = "editingFdocentete";
export const STORAGE_IMPERSONATE = "storageImpersonate";
export const STORAGE_ZOOM = "storageZoom";
export const STORAGE_DISMISS_CONTENT = "storageDismissContent";
export const STORAGE_INFINIT_SCROLL_ORDERS = "infinitScrollOrders";
export const STORAGE_SHOW_ONLY_DIFF_COMPARE = "showOnlyDiffCompare";
export const STORAGE_GLOBAL_SEARCH_BY_RAYON = "globalSearchByRayon";
export const STORAGE_FAST_TRANSFORM_ARRIVAGE = "fastTransformArrivage";
export const STORAGE_HIDE_FDOCLIGNES = "hideFDoclignes";
export const STORAGE_FARTICLES_COMPARE = "fArticlesToCompare";
export const STORAGE_SAV_COMMENT = "savComment";
