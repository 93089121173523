import React from "react";
import { LoadingButton } from "@mui/lab";
import { InputInterface } from "../../../interfaces/InputInterface";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { DialogProps, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import mailValidator from "../../../helpers/validator/MailValidator";
import { POST } from "../../../utils/MethodUtils";
import { LOST_PASSWORD_URL } from "../../../utils/UrlsUtils";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { requestApi } from "../../../helpers/RequestApi";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";

interface State {
  handleClose: any;
  openDialog: boolean;
}

interface FormState {
  email: InputInterface;
}

const LostPasswordComponent: React.FC<State> = React.memo(
  ({ handleClose, openDialog }) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        email: { value: "", error: "" },
      };
    }, []);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const confirm = React.useCallback(async () => {
      setLoading(true);
      const mailError = mailValidator(values.email.value);
      if (mailError) {
        const newValue: FormState = { ...values };
        if (mailError) {
          newValue.email.error = mailError;
        }
        setValues(newValue);
        setLoading(false);
        return;
      }
      const response = await requestApi({
        method: POST,
        path: LOST_PASSWORD_URL,
        allowError: true,
        token: token,
        body: {
          mail: values.email.value,
        },
      });
      if (response.statusCode === 201) {
        toastr.success(t("word.success"), t("sentence.notification.mail_sent"));
        handleClose();
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [handleClose, t, token, values]);

    return (
      <Dialog
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        onClose={handleClose}
        open={openDialog}
      >
        <DialogContent>
          <Typography sx={{ marginBottom: 2 }}>
            {t("sentence.lostPassword")}
          </Typography>
          <TextField
            autoComplete="off"
            error={!!values.email.error}
            helperText={t(values.email.error ?? "")}
            sx={{ width: "100%" }}
            type="text"
            value={values.email.value}
            onChange={handleChange("email")}
            label={t("field.email")}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <LoadingButton disabled={loading} onClick={handleClose}>
            {t("word.cancel")}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={confirm}
          >
            {t("word.sendMail")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);

export default LostPasswordComponent;
