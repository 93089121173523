import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import { getUrlConstructorImage } from "../../../helpers/FileHelper";
import Box from "@mui/material/Box";
import HideImageIcon from "@mui/icons-material/HideImage";
import { IMAGE_SIZE_LOGO } from "../../../utils/FileUtils";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { DriverInterface } from "../../../interfaces/FArticleInterface";
import { DialogProps, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import BrandFormComponent from "./BrandFormComponent";
import { PRODUCTS_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";
import { objectToQuery } from "../../../helpers/RequestApi";

interface State {
  driver: DriverInterface;
  last: boolean;
}

const BrandComponent: React.FC<State> = React.memo(({ driver, last }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const theme = useTheme();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpen = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(true);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        fullWidth={true}
        fullScreen={fullScreen}
        onClose={handleClose}
        open={open}
      >
        <DialogContent>
          <BrandFormComponent driver={driver} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
      <ListItem sx={{ display: "block" }}>
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to={PRODUCTS_PAGE + objectToQuery({ "brands[]": driver.name })}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ListItemAvatar>
                {driver.images ? (
                  <img
                    src={
                      (process.env.REACT_APP_API_URL ?? "") +
                        getUrlConstructorImage(driver, IMAGE_SIZE_LOGO) ?? ""
                    }
                    style={{
                      maxWidth: "100%",
                      margin: "auto",
                      display: "block",
                      border: "1px dashed gray",
                    }}
                    alt={driver.name}
                    loading="lazy"
                  />
                ) : (
                  <HideImageIcon
                    sx={{
                      width: IMAGE_SIZE_LOGO,
                      height: IMAGE_SIZE_LOGO,
                    }}
                  />
                )}
              </ListItemAvatar>
              <ListItemText primary={driver.name} sx={{ marginLeft: 2 }} />
              <ListItemText
                primary={driver.nbFArticle}
                sx={{
                  marginLeft: 2,
                  color: theme.palette.primary.main,
                }}
              />
            </Box>
            <IconButton onClick={handleOpen}>
              <EditIcon />
            </IconButton>
          </Box>
        </Link>
        {!last && <Divider sx={{ marginTop: 1 }} />}
      </ListItem>
    </>
  );
});

export default BrandComponent;
