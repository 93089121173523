import * as React from "react";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const NotFoundComponent: React.FC = React.memo(() => {
  return (
    <Container
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography sx={{ fontSize: "10em" }} color="secondary">
        404
      </Typography>
      <SentimentVeryDissatisfiedIcon
        sx={{ fontSize: "10em" }}
        color="secondary"
      />
    </Container>
  );
});

export default NotFoundComponent;
