import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

function getWindowDimensions(zoom: string) {
  let { innerWidth: width, innerHeight: height } = window;
  const ratio = parseFloat(zoom) / 100;
  width = width / ratio;
  height = height / ratio;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const zoom = useAppSelector((state: RootState) => state.globalState.zoom);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(zoom ?? "100")
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions(zoom ?? "100"));
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [zoom]);

  useEffect(() => {
    setWindowDimensions(getWindowDimensions(zoom ?? "100"));
  }, [zoom]);

  return windowDimensions;
}
