import React from "react";
import { ConfigurationInterface } from "../../../interfaces/ConfigurationInterface";
import {
  TYPE_BOOL,
  TYPE_CONTENT,
  TYPE_NUM,
  TYPE_TEXT_INPUT,
} from "../../../utils/ConfigurationUtils";
import Typography from "@mui/material/Typography";
import { Switch, useTheme } from "@mui/material";

interface State {
  configuration: ConfigurationInterface;
}

const ConfigurationValueComponent: React.FC<State> = React.memo(
  ({ configuration }) => {
    const theme = useTheme();

    return (
      <>
        {configuration.type === TYPE_TEXT_INPUT ? (
          <Typography
            component="span"
            sx={{ color: theme.palette.primary.main }}
          >
            {configuration.value}
          </Typography>
        ) : configuration.type === TYPE_NUM ? (
          <Typography
            component="pre"
            sx={{ color: theme.palette.primary.main }}
          >
            {configuration.value}
          </Typography>
        ) : configuration.type === TYPE_BOOL ? (
          <Switch size="small" checked={configuration.value === "1"} />
        ) : configuration.type === TYPE_CONTENT ? (
          <pre
            style={{ marginBottom: 8 }}
            dangerouslySetInnerHTML={{ __html: configuration.value }}
          />
        ) : (
          <span>unknown type</span>
        )}
      </>
    );
  }
);

export default ConfigurationValueComponent;
