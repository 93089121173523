const mailValidator = (mail: string | null, canBeEmpty = false) => {
  if (
    canBeEmpty &&
    (mail === null || mail === undefined || mail.trim() === "")
  ) {
    return "";
  }
  const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if (!mail) {
    return "error.email.empty";
  }
  if (mail.length > 69) {
    return "error.sageName.maxLength69";
  }
  if (!re.test(mail)) {
    return "error.email.valid";
  }
  return "";
};
export default mailValidator;
