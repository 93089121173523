import * as React from "react";
import { SyntheticEvent } from "react";
import { Autocomplete, Container, Grid, TextField } from "@mui/material";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  BrandInterface,
  FamilyInterface,
  ModelInterface,
  UniverseInterface,
} from "../../../interfaces/ConfiguratorEnergyInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import {
  GET_BRAND_URL,
  GET_FAMILY_URL,
  GET_MODEL_URL,
} from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import FFamilleMappingComponent from "./FFamilleMappingComponent";

interface FormState {
  universe: InputInterface;
  family: InputInterface;
  brand: InputInterface;
  model: InputInterface;
}

const ConfiguratorEnergyComponent: React.FC = React.memo(() => {
  const universes = useAppSelector(
    (state: RootState) => state.globalState.universes
  );
  const isAdmin = useAppSelector(
    (state: RootState) => state.globalState.isAdmin
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const [values, setValues] = React.useState<FormState>({
    universe: { value: null, error: "" },
    family: { value: null, error: "" },
    brand: { value: null, error: "" },
    model: { value: null, error: "" },
  });
  const [families, setFamilies] = React.useState<FamilyInterface[]>([]);
  const [brands, setBrands] = React.useState<BrandInterface[]>([]);
  const [models, setModels] = React.useState<ModelInterface[]>([]);
  const { t } = useTranslation();

  const handleChangeAutocomplete = React.useCallback(
    (prop: keyof FormState) =>
      (event: SyntheticEvent<Element, Event>, value: any) => {
        setValues((v) => {
          if (prop === "universe") {
            v.family.value = null;
            setFamilies([]);
          }
          if (["family", "universe"].includes(prop)) {
            v.brand.value = null;
            setBrands([]);
          }
          if (["brand", "family", "universe"].includes(prop)) {
            v.model.value = null;
            setModels([]);
          }
          return {
            ...v,
            // @ts-ignore
            [prop]: { ...v[prop], value: value, error: "" },
          };
        });
      },
    []
  );

  const getData = React.useCallback(async () => {
    if (values.universe.value === "" || values.universe.value === null) {
      return;
    }
    let response = null;
    if (values.family.value === "" || values.family.value === null) {
      response = await requestApi({
        method: GET,
        path: GET_FAMILY_URL.replace("{id}", values.universe.value.id),
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setFamilies(response.content);
        return;
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      }
    }
    if (values.brand.value === "" || values.brand.value === null) {
      response = await requestApi({
        method: GET,
        path: GET_BRAND_URL.replace("{id}", values.family.value.id),
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setBrands(response.content);
        return;
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      }
    }
    if (values.model.value === "" || values.model.value === null) {
      response = await requestApi({
        method: GET,
        path: GET_MODEL_URL.replace("{id}", values.brand.value.id),
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setModels(response.content);
        return;
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      }
    } else {
      // const search = {};
      // response = await requestApi({
      //   method: GET,
      //   path: FARTICLE_URL + objectToQuery(search),
      //   allowError: false,
      //   token: token,
      // });
    }
    if (response) {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
  }, [
    t,
    token,
    values.brand.value,
    values.family.value,
    values.model.value,
    values.universe.value,
  ]);

  React.useEffect(() => {
    getData();
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        {isAdmin && (
          <Grid item xs={12}>
            <FFamilleMappingComponent />
          </Grid>
        )}
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={universes ?? []}
            getOptionLabel={(universe: UniverseInterface) =>
              universe.name ?? ""
            }
            isOptionEqualToValue={(
              option: UniverseInterface,
              value: UniverseInterface
            ) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            onChange={handleChangeAutocomplete("universe")}
            value={values.universe.value}
            defaultValue={values.universe.value}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                error={!!values.universe.error}
                helperText={t(values.universe.error ?? "")}
                label={t("field.universe")}
                placeholder={t("field.universe")}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <Autocomplete
            disabled={
              values.universe.value === null || values.universe.value === ""
            }
            loading={true}
            loadingText={t("word.loading") + "..."}
            options={families}
            getOptionLabel={(family: FamilyInterface) => family.name ?? ""}
            isOptionEqualToValue={(
              option: UniverseInterface,
              value: UniverseInterface
            ) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            onChange={handleChangeAutocomplete("family")}
            value={values.family.value}
            defaultValue={values.family.value}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                error={!!values.family.error}
                helperText={t(values.family.error ?? "")}
                label={t("field.family")}
                placeholder={t("field.family")}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <Autocomplete
            disabled={
              values.family.value === null || values.family.value === ""
            }
            loading={true}
            loadingText={t("word.loading") + "..."}
            options={brands}
            getOptionLabel={(brand: BrandInterface) => brand.name ?? ""}
            isOptionEqualToValue={(
              option: UniverseInterface,
              value: UniverseInterface
            ) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            onChange={handleChangeAutocomplete("brand")}
            value={values.brand.value}
            defaultValue={values.brand.value}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                error={!!values.brand.error}
                helperText={t(values.brand.error ?? "")}
                label={t("field.brand")}
                placeholder={t("field.brand")}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          <Autocomplete
            disabled={values.brand.value === null || values.brand.value === ""}
            loading={true}
            loadingText={t("word.loading") + "..."}
            options={models}
            getOptionLabel={(model: ModelInterface) => model.name ?? ""}
            isOptionEqualToValue={(
              option: UniverseInterface,
              value: UniverseInterface
            ) => option.id === value.id}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            onChange={handleChangeAutocomplete("model")}
            value={values.model.value}
            defaultValue={values.model.value}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                error={!!values.model.error}
                helperText={t(values.model.error ?? "")}
                label={t("field.model")}
                placeholder={t("field.model")}
              />
            )}
          />
        </Grid>
      </Grid>
    </Container>
  );
});

export default ConfiguratorEnergyComponent;
