import React, { forwardRef, useImperativeHandle } from "react";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { InputInterface } from "../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { CropperImageInterface } from "../common/fArticle/FArticleImageEditComponent";
import CropperComponent from "../common/file/CropperComponent";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import { swapCard } from "../../helpers/CardFormat";

interface State {
  data: any;
}

interface FormState {
  resolution: InputInterface;
  autoSwap: InputInterface;
  autoSwapTime: InputInterface;
  random: InputInterface;
}

const CarouselContentComponent = React.memo(
  forwardRef(({ data }: State, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const getValues = React.useCallback(() => {
      return {
        resolution: { value: data?.resolution ?? "1.5", error: "" },
        autoSwap: { value: data?.autoSwap ?? false, error: "" },
        autoSwapTime: { value: data?.autoSwapTime ?? "2", error: "" },
        random: { value: data?.random ?? false, error: "" },
      };
    }, [data?.autoSwap, data?.autoSwapTime, data?.random, data?.resolution]);
    const [values, setValues] = React.useState<FormState>(getValues());
    const getImages = React.useCallback(() => {
      return [...(data?.images ?? [])];
    }, [data?.images]);
    const [images, setImages] = React.useState<any[]>(getImages());
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    const [newImages, setNewImages] = React.useState<
      (CropperImageInterface | undefined)[]
    >([{ ref: React.createRef() }]);

    const deleteNewImage = React.useCallback((imageUrl: string) => {
      setNewImages((x) => {
        x = x.map((y) => {
          if (y?.url === imageUrl) {
            return undefined;
          }
          return y;
        });
        return [...x];
      });
    }, []);

    const onDragEnd = React.useCallback((result: DropResult) => {
      if (
        result.destination === null ||
        result.destination === undefined ||
        result.source === null ||
        result.source === undefined
      ) {
        return;
      }
      setImages((thisRowRef) => {
        thisRowRef = swapCard(thisRowRef, result);
        return thisRowRef;
      });
    }, []);

    const getValueImages = React.useCallback(async (): Promise<any> => {
      const files: any[] = [];
      for (const newImage of newImages) {
        if (newImage === undefined || newImage === null) {
          continue;
        }
        // @ts-ignore
        const file = await newImage.ref.current.getValue();
        if (file === undefined || file === null) {
          continue;
        }
        files.push({
          file: file,
        });
      }
      return files;
    }, [newImages]);

    const getValue = React.useCallback(async (): Promise<any> => {
      return {
        resolution: values.resolution.value,
        autoSwap: values.autoSwap.value,
        autoSwapTime: values.autoSwapTime.value,
        random: values.random.value,
        images: [...images, ...(await getValueImages())],
      };
    }, [
      getValueImages,
      images,
      values.autoSwap.value,
      values.autoSwapTime.value,
      values.random.value,
      values.resolution.value,
    ]);

    const newImageAdded = React.useCallback(() => {
      setNewImages((x) => {
        return [...x, { ref: React.createRef() }];
      });
    }, []);

    const handleChangeImage = React.useCallback(
      (prop: any, index: number) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setImages((thisImages) => {
            const thisImage = thisImages[index];
            if (thisImage) {
              thisImage[prop] = event.target.value;
            }
            return [...thisImages];
          });
        },
      []
    );

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const addWithUrl = React.useCallback((url: string) => {
      setNewImages((x) => {
        x.splice(x.length - 1, 0, {
          ref: React.createRef(),
          url: url,
        });
        return [...x];
      });
    }, []);

    const removeImage = React.useCallback(
      (indexImage: number) => () => {
        setImages((x) => {
          x = x.filter((y, indexY) => indexY !== indexImage);
          return [...x];
        });
      },
      []
    );

    const handleChangeCheckbox = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getValues());
      setImages(getImages());
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Grid container spacing={1} sx={{ marginTop: 1 }}>
        <Grid item xs={12} md={3}>
          <TextField
            autoComplete="off"
            error={!!values.resolution.error}
            helperText={t(values.resolution.error ?? "")}
            sx={{ width: "100%" }}
            type="number"
            value={values.resolution.value}
            onChange={handleChange("resolution")}
            label={t("field.resolution")}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.autoSwap.value}
                  onChange={handleChangeCheckbox("autoSwap")}
                />
              }
              label={t("field.autoSwap")}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.random.value}
                  onChange={handleChangeCheckbox("random")}
                />
              }
              label={t("field.random")}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={!values.autoSwap.value}
            autoComplete="off"
            error={!!values.autoSwapTime.error}
            helperText={t(values.autoSwapTime.error ?? "")}
            sx={{ width: "100%" }}
            type="number"
            value={values.autoSwapTime.value}
            onChange={handleChange("autoSwapTime")}
            label={t("field.autoSwapTime")}
          />
        </Grid>
        <Grid item xs={12}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Box sx={{ overflow: "auto" }}>
              <Droppable droppableId="fArticleImage" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "max-content",
                    }}
                  >
                    {images.map((image, indexImage) => (
                      <Draggable
                        key={indexImage}
                        draggableId={indexImage.toString()}
                        index={indexImage}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Box sx={{ textAlign: "center" }}>
                              <span
                                style={{
                                  cursor: "move",
                                  marginRight: 1,
                                }}
                                {...provided.dragHandleProps}
                              >
                                <MenuIcon />
                              </span>
                            </Box>
                            <Box
                              key={indexImage}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: 1,
                              }}
                            >
                              <img
                                src={
                                  process.env.REACT_APP_API_URL +
                                  "/DATA" +
                                  image?.file
                                }
                                style={{
                                  maxWidth: "280px",
                                  border: "1px dashed gray",
                                }}
                                alt={image?.file}
                                loading="lazy"
                              />
                              <Box sx={{ alignItems: "flex-start" }}>
                                <IconButton onClick={removeImage(indexImage)}>
                                  <ClearIcon />
                                </IconButton>
                              </Box>
                            </Box>
                            <Box sx={{ marginRight: 2 }}>
                              <TextField
                                fullWidth={true}
                                autoComplete="off"
                                sx={{ width: "100%" }}
                                type="text"
                                value={image?.link ?? ""}
                                onChange={handleChangeImage("link", indexImage)}
                                label={t("field.link")}
                              />
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Box>
          </DragDropContext>
        </Grid>
        <Grid item xs={12}>
          {newImages.map(
            (newImage, indexNewImage) =>
              newImage !== undefined && (
                <CropperComponent
                  key={indexNewImage}
                  ref={newImage.ref}
                  url={newImage.url}
                  aspectRatio={parseFloat(values.resolution.value)}
                  indexImage={newImage.url}
                  imageAdded={newImageAdded}
                  deleteImage={deleteNewImage}
                  addWithUrl={addWithUrl}
                  xs={isSmall ? 12 : 8}
                  multiple={true}
                />
              )
          )}
        </Grid>
      </Grid>
    );
  })
);

export default CarouselContentComponent;
