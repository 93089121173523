import React from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import ContentComponent from "../components/content/ContentComponent";
import { getTitle } from "../helpers/SearchParamHelper";

const HomeScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      dispatch(set({ refreshPage: false }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    document.title = getTitle("home");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <ContentComponent contentName="accueil" saveLocalStorage={true} />
    </Layout>
  );
});

export default HomeScreen;
