import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleInterface,
  FArticlePropFilterFilterValueFormInterface,
} from "../../../../../../interfaces/FArticleInterface";
import { Checkbox, FormControlLabel } from "@mui/material";
import { InputInterface } from "../../../../../../interfaces/InputInterface";
import { TYPE_RADIO } from "../../../../../../utils/FilterUtils";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

interface State {
  fArticle: FArticleInterface;
  filterValue: FArticlePropFilterFilterValueFormInterface;
  unCheckParent: Function;
  type: string;
  parent: FArticlePropFilterFilterValueFormInterface | undefined;
}

interface FormState {
  checked: InputInterface;
}

const FormFArticlePropFilterValueComponent = React.memo(
  React.forwardRef(
    ({ filterValue, fArticle, unCheckParent, type, parent }: State, ref) => {
      const getDefaultValues = React.useCallback((): FormState => {
        return {
          checked: {
            value:
              fArticle.fArticleProp?.filterValueArticles?.find(
                (x) => x.filterValue?.id === filterValue.id
              ) !== undefined,
            error: "",
          },
        };
      }, [fArticle.fArticleProp?.filterValueArticles, filterValue.id]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());

      const uncheckAllChildren = React.useCallback(
        (children: FArticlePropFilterFilterValueFormInterface[]) => {
          for (const child of children) {
            child.ref.current.unCheck();
            if (child.children.length > 0) {
              uncheckAllChildren(child.children);
            }
          }
        },
        []
      );

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              unCheckParent();
            }
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.checked, error: "" },
              };
            });
          },
        [unCheckParent]
      );

      const unCheck = React.useCallback(() => {
        setValues((v) => {
          v.checked.value = false;
          return { ...v };
        });
      }, []);

      const check = React.useCallback(() => {
        setValues((v) => {
          v.checked.value = true;
          return { ...v };
        });
      }, []);

      useImperativeHandle(ref, () => ({
        unCheck() {
          unCheck();
        },
        check() {
          check();
        },
        getValue() {
          if (!values.checked.value) {
            return undefined;
          }
          return {
            id: filterValue.id,
          };
        },
      }));

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [filterValue]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (values.checked.value) {
          if (parent) {
            parent.ref.current.check();
          }
        } else {
          uncheckAllChildren(filterValue.children);
        }
      }, [values.checked.value]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <FormControlLabel
          sx={{ width: "100%" }}
          control={
            <Checkbox
              sx={{ paddingY: 0 }}
              {...(type === TYPE_RADIO && {
                icon: <RadioButtonUncheckedIcon />,
                checkedIcon: <RadioButtonCheckedIcon />,
              })}
              checked={values.checked.value}
              onChange={handleChange("checked")}
            />
          }
          label={filterValue.name}
        />
      );
    }
  )
);

export default FormFArticlePropFilterValueComponent;
