import * as React from "react";
import { useEffect } from "react";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { isUserBonDeCommande } from "../../../helpers/UserHelper";
import {
  bonDeCommandeIsValidated,
  displayTransform,
  downloadFDocentete,
  getAvoirFdocentete,
  getUrlPdfDoPiece,
  paiementDone,
} from "../../../helpers/FDocenteteHelper";
import FDocentetePayComponent from "./FDocentetePayComponent";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import FDocenteteTransformComponent from "./FDocenteteTransformComponent";
import { RAW_HTML_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import Box from "@mui/material/Box";
import DeleteFDocenteteComponent from "./DeleteFDocenteteComponent";
import { differenceInSeconds } from "date-fns";
import {
  SAGE_DO_TYPE_VENTE_DE,
  SAGE_DO_TYPE_VENTE_FA,
} from "../../../utils/DoceneteteUtils";
import Typography from "@mui/material/Typography";
import { priceFormat } from "../../../utils/FormatUtils";
import FDocenteteOrderAgainComponent from "./FDocenteteOrderAgainComponent";
import { isDesktop } from "react-device-detect";
import { GET } from "../../../utils/MethodUtils";
import { FDOCENTETE_RECALCULATE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { set } from "../../../app/globalSlice";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

interface State {
  fDocentete: FDocenteteInterface;
}

const FDocenteteActionComponent: React.FC<State> = React.memo(
  ({ fDocentete }) => {
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const { t, i18n } = useTranslation();
    const accountingSituation = useAppSelector(
      (state: RootState) => state.globalState.accountingSituation
    );
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [avoir, setAvoir] = React.useState<number>(
      getAvoirFdocentete(fDocentete)
    );
    const [downloading, setDownloading] = React.useState<boolean>(false);
    const [recalulateLoading, setRecalulateLoading] =
      React.useState<boolean>(false);
    const dispatch = useAppDispatch();

    const recalulate = React.useCallback(async () => {
      setRecalulateLoading(true);
      const response = await requestApi({
        method: GET,
        path: FDOCENTETE_RECALCULATE_URL.replace(
          "{doPiece}",
          fDocentete.doPiece
        ),
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        dispatch(set({ refreshPage: true }));
        toastr.success(
          t("word.success"),
          t("sentence.notification.devis_updated")
        );
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setRecalulateLoading(false);
    }, [dispatch, fDocentete.doPiece, t, token]);

    useEffect(() => {
      setAvoir(getAvoirFdocentete(fDocentete));
    }, [accountingSituation?.creditFdocentetes]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Box>
          {fDocentete.nDeTransport && (
            <Box sx={{ marginBottom: 1 }}>
              <Typography>
                {t("word.nDeTransport")}: {fDocentete.nDeTransport}
              </Typography>
              <a
                href={
                  "https://parcelsapp.com/fr/tracking/" +
                  fDocentete.nDeTransport
                }
                target="_blank"
                rel="noreferrer"
              >
                <LoadingButton variant="contained">
                  {t("word.trackOrder")}
                </LoadingButton>
              </a>
            </Box>
          )}
          <FDocentetePayComponent fDocentete={fDocentete} />
          {isUserBonDeCommande(user) ? (
            <FDocenteteTransformComponent
              fDocentete={fDocentete}
              withBonCommande={true}
            />
          ) : (
            <>
              {displayTransform(fDocentete, user) && (
                <FDocenteteTransformComponent
                  fDocentete={fDocentete}
                  withBonCommande={false}
                />
              )}
            </>
          )}
          <Box>
            {!isDesktop ? (
              <LoadingButton
                variant="contained"
                sx={{ mt: 1 }}
                loading={downloading}
                onClick={async () => {
                  setDownloading(true);
                  await downloadFDocentete(fDocentete.doPiece);
                  setDownloading(false);
                }}
              >
                {t(
                  fDocentete.doType === SAGE_DO_TYPE_VENTE_DE
                    ? "word.seeDevis"
                    : fDocentete.doType >= SAGE_DO_TYPE_VENTE_FA
                    ? "word.seePdf"
                    : "word.seeOrder"
                )}
              </LoadingButton>
            ) : (
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={
                  RAW_HTML_PAGE +
                  objectToQuery({
                    url: getUrlPdfDoPiece(fDocentete.doPiece, !isDesktop),
                    filename: fDocentete.doPiece,
                  })
                }
                target="_blank"
              >
                <LoadingButton variant="contained" sx={{ mt: 1 }}>
                  {t(
                    fDocentete.doType === SAGE_DO_TYPE_VENTE_DE
                      ? "word.seeDevis"
                      : fDocentete.doType >= SAGE_DO_TYPE_VENTE_FA
                      ? "word.seePdf"
                      : "word.seeOrder"
                  )}
                </LoadingButton>
              </Link>
            )}
            {avoir > 0 && (
              <Box>
                <Typography variant="caption">
                  {t("sentence.usedAvoir").replace(
                    "%amount%",
                    priceFormat(avoir, i18n.language, "EUR")
                  )}
                </Typography>
              </Box>
            )}
          </Box>
          {fDocentete.doType >= SAGE_DO_TYPE_VENTE_FA && (
            <FDocenteteOrderAgainComponent fDocentete={fDocentete} />
          )}
          {fDocentete.doType === SAGE_DO_TYPE_VENTE_DE && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 1,
              }}
            >
              <LoadingButton
                variant="contained"
                sx={{ mt: 1 }}
                loading={recalulateLoading}
                onClick={recalulate}
              >
                {t("word.recalculateDevis")}
              </LoadingButton>
              <Tooltip title={t("sentence.recalculate")}>
                <InfoIcon />
              </Tooltip>
            </Box>
          )}
        </Box>
        <Box>
          {!paiementDone(fDocentete) &&
            !bonDeCommandeIsValidated(fDocentete, user) &&
            avoir === 0 &&
            fDocentete.doType < SAGE_DO_TYPE_VENTE_FA &&
            (differenceInSeconds(new Date(), new Date(fDocentete.cbcreation)) <
              3600 ||
              fDocentete.doType === SAGE_DO_TYPE_VENTE_DE) && (
              <DeleteFDocenteteComponent fDocentete={fDocentete} />
            )}
        </Box>
      </>
    );
  }
);

export default FDocenteteActionComponent;
