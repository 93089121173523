import React, { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import { InputInterface } from "../../../interfaces/InputInterface";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";

interface State {
  updateSearchParams: Function;
}

interface FormState {
  search: InputInterface;
}

const EnteringInvoiceFilterComponent = React.memo(
  React.forwardRef(({ updateSearchParams }: State, ref) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [search, setSearch] = React.useState<string>(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.search ?? "";
    });
    const [init, setInit] = React.useState<boolean>(false);
    const getDefaultValues = React.useCallback((): FormState => {
      const searchParamsObject = searchParamToObject(searchParams);
      return {
        search: {
          value: searchParamsObject.search ?? "",
          error: "",
        },
      };
    }, [searchParams]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChangeText = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
      },
      []
    );

    const clearSearch = React.useCallback(() => {
      setSearch("");
    }, []);

    const searchFDocentete = React.useCallback(() => {
      setValues((x) => {
        x.search.value = search;
        return { ...x };
      });
    }, [search]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return values.search.value;
      },
    }));

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchFDocentete();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (!init) {
        setValues(getDefaultValues());
        setInit(true);
      }
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      updateSearchParams();
      setSearch(values.search.value);
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          autoComplete="off"
          sx={{ width: "100%" }}
          type="text"
          value={search}
          onChange={handleChangeText}
          label={t("field.searchfDocentete")}
          placeholder={t("field.searchfDocentete")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={clearSearch}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Tooltip title={t("sentence.searchFDocentete")}>
          <InfoIcon />
        </Tooltip>
      </Box>
    );
  })
);

export default EnteringInvoiceFilterComponent;
