import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { InputInterface } from "../../../interfaces/InputInterface";
import IconButton from "@mui/material/IconButton";
import EuroIcon from "@mui/icons-material/Euro";
import Box from "@mui/material/Box";
import ContentComponent from "../../content/ContentComponent";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { CREDIT_PAYMENT_URL } from "../../../utils/UrlsUtils";
import { submitFormPayment } from "../../../helpers/PaymentHelper";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface FormState {
  price: InputInterface;
}

const CreditAccountComponent: React.FC = React.memo(() => {
  const getDefaultValues = React.useCallback((): FormState => {
    return {
      price: {
        value: "",
        error: "",
      },
    };
  }, []);
  const [values, setValues] = React.useState<FormState>(getDefaultValues());
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const save = React.useCallback(async () => {
    setLoading(true);
    const response = await requestApi({
      method: POST,
      path: CREDIT_PAYMENT_URL,
      allowError: true,
      token: token,
      body: {
        amount: parseFloat(values.price.value),
      },
    });
    if (response.statusCode === 200) {
      submitFormPayment(response.content.form);
      return;
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [t, token, values.price.value]);

  const onBlur = React.useCallback(() => {
    setValues((x) => {
      x.price.value = parseFloat(x.price.value).toFixed(2).toString();
      return { ...x };
    });
  }, []);

  // let nbChar = values.price.value.toString().length;
  // if (nbChar < 2) {
  //   nbChar = 2;
  // }
  return (
    <>
      <ContentComponent contentName="creditAccount" saveLocalStorage={true} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <FormControl variant="outlined" required>
          <InputLabel>{t("field.amount")}</InputLabel>
          <OutlinedInput
            error={!!values.price.error}
            type="number"
            className="center-input"
            onBlur={onBlur}
            value={values.price.value}
            onChange={handleChange("price")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <EuroIcon />
                </IconButton>
              </InputAdornment>
            }
            // inputProps={{
            //   sx: {
            //     minWidth: nbChar * 9 + 20,
            //     ...(!isSmall && { maxWidth: nbChar * 9 + 20 }),
            //   },
            // }}
            label={t("field.amount")}
          />
          {!!values.price.error && (
            <FormHelperText error>{t(values.price.error ?? "")}</FormHelperText>
          )}
        </FormControl>
        <LoadingButton
          disabled={
            values.price.value.trim() === "" ||
            isNaN(values.price.value) ||
            parseFloat(values.price.value) <= 0
          }
          variant="contained"
          loading={loading}
          onClick={save}
        >
          {t("word.credit")}
        </LoadingButton>
      </Box>
    </>
  );
});

export default CreditAccountComponent;
