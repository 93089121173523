import React from "react";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface State {
  children?: React.ReactNode;
  index: number;
  value: number;
  alwaysShow?: boolean;
  sx?: SxProps<Theme>;
  removeComponentOnHidden?: boolean;
}

const TabPanelComponent: React.FC<State> = React.memo(
  ({ children, index, value, sx, alwaysShow, removeComponentOnHidden }) => {
    return (
      <Box
        role="tabpanel"
        sx={sx}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {(value === index || alwaysShow) && (
          <>
            {removeComponentOnHidden ? (
              <>{value === index && children}</>
            ) : (
              children
            )}
          </>
        )}
      </Box>
    );
  }
);

export default TabPanelComponent;
