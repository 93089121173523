import * as React from "react";
import { InputAdornment, OutlinedInput } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { set } from "../../app/globalSlice";
import FormControl from "@mui/material/FormControl";
import { isFirefox } from "react-device-detect";

const ZoomComponent: React.FC = React.memo(() => {
  const zoom = useAppSelector((state: RootState) => state.globalState.zoom);
  const dispatch = useAppDispatch();
  return (
    <>
      {!isFirefox && (
        <>
          {/*https://developer.mozilla.org/en-US/docs/Web/CSS/zoom#browser_compatibility*/}
          {/*https://stackoverflow.com/a/10464073/6824121*/}
          <FormControl variant="outlined" required>
            <OutlinedInput
              type="number"
              className="center-input"
              readOnly={true}
              value={parseInt(zoom ?? "100")}
              inputProps={{
                sx: {
                  width: 30,
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={(event) => {
                      const newZoom = parseInt(zoom ?? "100") + 5;
                      if (newZoom > 150) {
                        return;
                      }
                      dispatch(set({ zoom: newZoom.toString() }));
                    }}
                    edge="end"
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    onClick={(event) => {
                      const newZoom = parseInt(zoom ?? "100") - 5;
                      if (newZoom < 50) {
                        return;
                      }
                      dispatch(set({ zoom: newZoom.toString() }));
                    }}
                    edge="end"
                  >
                    <RemoveIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </>
      )}
    </>
  );
});

export default ZoomComponent;
