export const NONE_LITERATE_WRITING = "A";
export const LITERATE_WRITING = "B";
export const ALL_LITERATE_WRITING = "C";
export const ALL_WRITING = [
  LITERATE_WRITING,
  NONE_LITERATE_WRITING,
  ALL_LITERATE_WRITING,
];

export const CURRENT_EXERCISE = "0";
export const PAST_EXERCISE = "-1";
export const ALL_EXERCISE = [CURRENT_EXERCISE, PAST_EXERCISE];

export const EC_SENS_DEBIT = "0";
export const EC_SENS_CREDIT = "1";
