import * as React from "react";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

const ConfiguratorComestibleComponent: React.FC = React.memo(() => {
  return (
    <Container maxWidth="xl">
      <Typography>ConfiguratorComestibleComponent</Typography>
    </Container>
  );
});

export default ConfiguratorComestibleComponent;
