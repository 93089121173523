import React, { useImperativeHandle } from "react";
import RowComponent from "../RowComponent";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { swapCard } from "../../../helpers/CardFormat";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";

interface State {
  index: number;
  data: any;
  fArticle: FArticleSmallInterface | undefined;
  availableModules?: string[];
}

interface RowRefInterface {
  ref: any;
  key: number;
  data: any;
}

const ContentTabRowsComponent = React.memo(
  React.forwardRef(
    ({ index, data, fArticle, availableModules }: State, ref) => {
      const { t } = useTranslation();

      const [rowsRef, setRowsRef] = React.useState<(RowRefInterface | null)[]>(
        []
      );

      const removeRow = React.useCallback((index: number) => {
        setRowsRef((x) => {
          x[index] = null;
          return [...x];
        });
      }, []);

      const addRow = React.useCallback(() => {
        setRowsRef((x) => [
          ...x,
          { ref: React.createRef(), data: undefined, key: x.length },
        ]);
      }, []);

      const initRows = React.useCallback(() => {
        if (data === undefined || data === null) {
          addRow();
          return;
        }
        if (data.rows !== null) {
          setRowsRef(() =>
            data.rows.map((row: any, indexRow: number) => {
              return { ref: React.createRef(), data: row, key: indexRow };
            })
          );
        }
      }, [addRow, data]);

      const onDragEnd = React.useCallback((result: DropResult) => {
        if (
          result.destination === null ||
          result.destination === undefined ||
          result.source === null ||
          result.source === undefined
        ) {
          return;
        }
        setRowsRef((thisRowRef) => {
          thisRowRef = swapCard(thisRowRef, result);
          return thisRowRef;
        });
      }, []);

      useImperativeHandle(ref, () => ({
        async getValue() {
          const value: any[] = [];
          for (const rowRef of rowsRef) {
            if (rowRef === null) {
              continue;
            }
            value.push(await rowRef?.ref.current.getValue());
          }
          if (value.findIndex((x) => x === undefined) >= 0) {
            return undefined;
          }
          return {
            rows: value,
          };
        },
      }));

      React.useEffect(() => {
        initRows();
      }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="row">
              {(provided) => (
                <div ref={provided.innerRef}>
                  {rowsRef.map(
                    (rowRef, indexRowRef) =>
                      rowRef !== null && (
                        <Draggable
                          key={rowRef.key}
                          draggableId={rowRef.key.toString()}
                          index={indexRowRef}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <RowComponent
                                ref={rowRef?.ref}
                                index={indexRowRef}
                                removeRow={removeRow}
                                data={rowRef?.data}
                                provided={provided}
                                fArticle={fArticle}
                                availableModules={availableModules}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Container sx={{ display: "flex", marginY: 1 }}>
            <Box sx={{ textAlign: "center", flex: 1 }}>
              <LoadingButton variant="contained" onClick={addRow}>
                {t("action.add.row")}
              </LoadingButton>
            </Box>
          </Container>
        </Box>
      );
    }
  )
);

export default ContentTabRowsComponent;
