import React from "react";
import Layout from "../../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import LoginComponent from "../../components/common/user/LoginComponent";
import { Container, useMediaQuery } from "@mui/material";
import { requestApi } from "../../helpers/RequestApi";
import { GET } from "../../utils/MethodUtils";
import { EXPEDITION_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import ExpeditionComponent from "../../components/common/expedition/ExpeditionComponent";
import SearchComponent from "../../components/common/SearchComponent";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { ExpeditionInterface } from "../../interfaces/CartInterface";
import Box from "@mui/material/Box";
import { getTitle } from "../../helpers/SearchParamHelper";

const ExpeditionScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const expeditions = useAppSelector(
    (state: RootState) => state.globalState.expeditions
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const getExpeditionForms = React.useCallback(() => {
    return expeditions?.map((expedition) => expedition) ?? [];
  }, [expeditions]);
  const [expeditionForms, setExpeditionForms] = React.useState<
    (ExpeditionInterface | undefined | null)[]
  >(getExpeditionForms());
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (expeditions !== undefined && !force) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const response = await requestApi({
        method: GET,
        path: EXPEDITION_URL,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        dispatch(set({ expeditions: response.content }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [dispatch, expeditions, t, token]
  );

  const addExpedition = React.useCallback(() => {
    setExpeditionForms((x) => {
      x.push(undefined);
      return [...x];
    });
  }, []);

  const removeExpedition = React.useCallback((index: number) => {
    setExpeditionForms((x) => {
      x = x.map((x, indexExpedition) => {
        if (index !== indexExpedition) {
          return x;
        }
        return null;
      });
      return [...x];
    });
  }, []);

  React.useEffect(() => {
    document.title = getTitle("expeditions");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setExpeditionForms(getExpeditionForms());
  }, [expeditions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Container maxWidth="xl" sx={{ marginY: 2 }}>
          {refreshPage ? (
            <SearchComponent nbColumn={isSmall ? 4 : 1} nbLines={4} />
          ) : (
            <>
              {expeditionForms.map((expeditionForm, indexExpeditionForm) =>
                expeditionForm === null ? (
                  <Box key={indexExpeditionForm} />
                ) : (
                  <ExpeditionComponent
                    expedition={expeditionForm}
                    key={indexExpeditionForm}
                    indexExpeditionForm={indexExpeditionForm}
                    removeExpedition={removeExpedition}
                  />
                )
              )}
              <Box sx={{ textAlign: "center" }}>
                <Button variant="contained" onClick={addExpedition}>
                  {t("word.addExpedition")}
                </Button>
              </Box>
            </>
          )}
        </Container>
      </LoginComponent>
    </Layout>
  );
});

export default ExpeditionScreen;
