import React, { SyntheticEvent, useImperativeHandle, useRef } from "react";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SavInterface } from "../../../interfaces/SavInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { UserInterface } from "../../../interfaces/UserInterface";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import mailValidator from "../../../helpers/validator/MailValidator";
import Box from "@mui/material/Box";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FCOMPTET_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import PhoneInputComponent from "../../../components/common/user/PhoneInputComponent";

interface State {
  sav?: SavInterface | null;
  asAdmin: boolean;
  setUserForm: Function;
}

interface FormState {
  user: InputInterface;
  phone: InputInterface;
  company: InputInterface;
  contact: InputInterface;
  mail: InputInterface;
}

const SavFormClientComponent = React.memo(
  React.forwardRef(({ sav, asAdmin, setUserForm }: State, ref) => {
    const refCtTelephone: any = useRef();
    const { t } = useTranslation();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const theme = useTheme();
    const [searchUser, setSearchUser] = React.useState<string>("");
    const [isTyping, setIsTyping] = React.useState<boolean>(false);
    const [isSearching, setIsSearching] = React.useState<boolean>(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultUsers = React.useCallback((): UserInterface[] => {
      const result: UserInterface[] = [];
      if (asAdmin) {
        return result;
      }
      if (sav) {
        // @ts-ignore
        result.push({ userIdentifier: sav.userIdentifier });
      } else if (user) {
        result.push(user);
      }
      return result;
    }, [asAdmin, sav, user]);
    const [users, setUsers] = React.useState<UserInterface[]>(
      getDefaultUsers()
    );
    const getDefaultValue = React.useCallback((): FormState => {
      let thisUser: UserInterface | null | undefined = user;
      let phone: string = thisUser?.ctTelephone ?? "";
      let company: string = thisUser?.ctIntitule ?? "";
      let contact: string = thisUser?.ctIntitule ?? "";
      let mail: string = thisUser?.ctEmail ?? "";
      if (sav) {
        // @ts-ignore
        thisUser = { userIdentifier: sav.userIdentifier };
        phone = sav.phone ?? "";
        company = sav.company ?? "";
        contact = sav.contact ?? "";
        mail = sav.mail ?? "";
      } else if (asAdmin) {
        thisUser = null;
        phone = "";
        company = "";
        contact = "";
        mail = "";
      }
      return {
        user: {
          value: thisUser ?? null,
          error: "",
        },
        phone: {
          value: phone,
          error: "",
        },
        company: {
          value: company ?? "",
          error: "",
        },
        contact: {
          value: contact,
          error: "",
        },
        mail: {
          value: mail,
          error: "",
        },
      };
    }, [asAdmin, sav, user]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            const newValue = {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
            if (prop === "user") {
              newValue.phone.value = value?.ctTelephone ?? "";
              newValue.company.value = value?.ctIntitule ?? "";
              newValue.contact.value = value?.ctIntitule ?? "";
              newValue.mail.value = value?.ctEmail ?? "";

              newValue.phone.error = "";
              newValue.company.error = "";
              newValue.contact.error = "";
              newValue.mail.error = "";
            }
            return newValue;
          });
        },
      []
    );

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      const cliCodeError = notEmptyValidator(values.user.value);
      const mailError = mailValidator(values.mail.value);
      const ctTelephoneValue = refCtTelephone.current.getValue();
      const contactError = notEmptyValidator(values.contact.value);
      if (
        cliCodeError ||
        mailError ||
        ctTelephoneValue === undefined ||
        contactError
      ) {
        const newValue: FormState = { ...values };
        if (cliCodeError) {
          newValue.user.error = cliCodeError;
        }
        if (mailError) {
          newValue.mail.error = mailError;
        }
        if (contactError) {
          newValue.contact.error = contactError;
        }
        setValues(newValue);
        return undefined;
      }
      return {
        userIdentifier: values.user.value.userIdentifier,
        company: values.company.value,
        contact: values.contact.value,
        phone: ctTelephoneValue,
        mail: values.mail.value,
      };
    }, [values]);

    const handleSearchUser = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: string) => {
        setSearchUser(value);
        setUsers([]);
        setIsTyping(true);
      },
      []
    );

    const searchUsers = React.useCallback(async () => {
      setIsTyping(false);
      if (!user || sav?.id || !asAdmin) {
        return;
      }
      setIsSearching(true);
      const param: any = {
        ctNum: searchUser.trim().toUpperCase(),
      };
      const response = await requestApi({
        method: GET,
        path: FCOMPTET_URL + objectToQuery(param),
        allowError: false,
        paginate: false,
        token: token,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        setUsers(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setIsSearching(false);
    }, [asAdmin, sav, searchUser, t, token, user]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setUsers(getDefaultUsers());
      setValues(getDefaultValue());
    }, [user, sav]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setUserForm(values.user.value ?? undefined);
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchUsers();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [searchUsers]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid item xs={12} md={6}>
          <Autocomplete
            fullWidth
            disabled={!(asAdmin && isAdmin && !sav?.id)}
            loadingText={t("word.loading") + "..."}
            inputValue={searchUser}
            onInputChange={handleSearchUser}
            noOptionsText={
              isSearching ? (
                <Box sx={{ textAlign: "center" }}>
                  <CircularProgress />
                </Box>
              ) : searchUser !== "" && !isTyping ? (
                t("sentence.no_option")
              ) : (
                t("word.sav.searchUser.label")
              )
            }
            options={users ?? []}
            getOptionLabel={(user: UserInterface) => {
              return user.userIdentifier ?? "";
            }}
            isOptionEqualToValue={(
              option: UserInterface,
              value: UserInterface
            ) => {
              return option.userIdentifier === value.userIdentifier;
            }}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  key={option.userIdentifier}
                  style={{ display: "block" }}
                >
                  <span style={{ color: theme.palette.primary.main }}>
                    {"[" + option.userIdentifier + "] "}
                  </span>
                  {option.ctIntitule}
                </li>
              );
            }}
            onChange={handleChangeAutocomplete("user")}
            value={values.user.value}
            defaultValue={values.user.value}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                error={!!values.user.error}
                helperText={t(values.user.error ?? "")}
                label={t("word.userIdentifier")}
                placeholder={t("word.userIdentifier")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneInputComponent
            ref={refCtTelephone}
            initCtTelephone={values.phone.value}
            disabled={!!sav?.id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            disabled={!!sav?.id}
            error={!!values.company.error}
            helperText={t(values.company.error ?? "")}
            sx={{ width: "100%" }}
            type="text"
            value={values.company.value}
            onChange={handleChange("company")}
            label={t("field.company")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            disabled={!!sav?.id}
            error={!!values.contact.error}
            helperText={t(values.contact.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="text"
            value={values.contact.value}
            onChange={handleChange("contact")}
            label={t("field.contact")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            disabled={!!sav?.id}
            error={!!values.mail.error}
            helperText={t(values.mail.error ?? "")}
            sx={{ width: "100%" }}
            required
            type="text"
            value={values.mail.value}
            onChange={handleChange("mail")}
            label={t("field.mail")}
          />
        </Grid>
      </>
    );
  })
);

export default SavFormClientComponent;
