import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { LICENSE_MAIL } from "../../../../utils/ConfigurationUtils";

interface Props {
  handleNext: any;
  handleBack: any;
}

const StepperLicenseComponent: React.FC<Props> = React.memo(
  ({ handleBack, handleNext }) => {
    const configurations = useAppSelector(
      (state: RootState) => state.globalState.configurations
    );
    const { t } = useTranslation();

    return (
      <>
        {configurations?.find((c) => c.identifier === LICENSE_MAIL)?.value ??
          ""}
        <Box>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("word.next")}
          </Button>
          <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            {t("word.back")}
          </Button>
        </Box>
      </>
    );
  }
);

export default StepperLicenseComponent;
