import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import {
  CategoryInterface,
  FilterValueInterface,
} from "../../../interfaces/CategoryInterface";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";

const clone = require("clone");

interface State {
  categoryGroups: (CategoryInterface | FilterValueInterface)[][];
}

interface State2 {
  categoryGroup: CategoryInterface[] | undefined;
  removeCategoryGroup: Function;
  index: number;
}

interface CategoryGroupInterface {
  ref: any;
  categoryGroup: CategoryInterface[];
}

const EditSingleFArticleBreadcrumbComponent = React.memo(
  React.forwardRef(
    ({ categoryGroup, removeCategoryGroup, index }: State2, ref) => {
      const categories = useAppSelector(
        (state: RootState) => state.globalState.categories
      );
      const [category1, setCategory1] =
        React.useState<CategoryInterface | null>(
          categoryGroup ? (categoryGroup[0] ? categoryGroup[0] : null) : null
        );
      const [category2, setCategory2] =
        React.useState<CategoryInterface | null>(
          categoryGroup ? (categoryGroup[1] ? categoryGroup[1] : null) : null
        );

      const handleCategory1 = React.useCallback(
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setCategory1(value);
          setCategory2(null);
        },
        []
      );

      const handleCategory2 = React.useCallback(
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setCategory2(value);
        },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return [category1?.id, category2?.id].filter((x) => x);
        },
      }));

      return (
        <>
          {categories && (
            <>
              {categoryGroup && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={categories}
                        getOptionLabel={(category: CategoryInterface) => {
                          return "[" + category.id + "] " + category.name;
                        }}
                        isOptionEqualToValue={(
                          option: CategoryInterface,
                          value: CategoryInterface
                        ) => option.id === value.id}
                        onChange={handleCategory1}
                        value={category1}
                        renderInput={(params) => (
                          <TextField {...params} autoComplete="off" required />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {category1 && (
                        <Autocomplete
                          options={
                            categories.find((c) => c.id === category1?.id)
                              ?.children ?? []
                          }
                          getOptionLabel={(category: CategoryInterface) => {
                            return "[" + category.id + "] " + category.name;
                          }}
                          isOptionEqualToValue={(
                            option: CategoryInterface,
                            value: CategoryInterface
                          ) => option.id === value.id}
                          onChange={handleCategory2}
                          value={category2}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              autoComplete="off"
                              required
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <IconButton onClick={() => removeCategoryGroup(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </>
          )}
        </>
      );
    }
  )
);

const EditFArticleBreadcrumbComponent = React.memo(
  React.forwardRef(({ categoryGroups }: State, ref) => {
    const getThisCategoryGroups =
      React.useCallback((): CategoryGroupInterface[] => {
        return categoryGroups
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) => t[0].id === value[0].id && t[1]?.id === value[1]?.id
              )
          )
          .map((categoryGroup) => {
            const thisCategoryGroup: CategoryInterface[] = clone(categoryGroup);
            if (thisCategoryGroup.length === 3) {
              thisCategoryGroup.pop();
            }
            return {
              ref: React.createRef(),
              categoryGroup: thisCategoryGroup,
            };
          });
      }, [categoryGroups]);

    const [thisCategoryGroups, setThisCategoryGroups] = React.useState<
      (CategoryGroupInterface | undefined)[]
    >(getThisCategoryGroups());

    const addCategoryGroup = React.useCallback(() => {
      setThisCategoryGroups((x) => {
        x.push({
          ref: React.createRef(),
          categoryGroup: [],
        });
        return [...x];
      });
    }, []);

    const removeCategoryGroup = React.useCallback((index: number) => {
      setThisCategoryGroups((x) => {
        x[index] = undefined;
        return [...x];
      });
    }, []);

    React.useEffect(() => {
      setThisCategoryGroups(getThisCategoryGroups());
    }, [categoryGroups]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        let result: number[] = [];
        for (const thisCategoryGroup of thisCategoryGroups) {
          if (thisCategoryGroup) {
            result = [...result, ...thisCategoryGroup.ref.current.getValue()];
          }
        }
        // @ts-ignore
        return [...new Set(result)];
      },
    }));

    return (
      <>
        {thisCategoryGroups.map((categoryGroup, indexCategoryGroup) => (
          <EditSingleFArticleBreadcrumbComponent
            key={indexCategoryGroup}
            ref={categoryGroup?.ref}
            categoryGroup={categoryGroup?.categoryGroup}
            removeCategoryGroup={removeCategoryGroup}
            index={indexCategoryGroup}
          />
        ))}
        <Box sx={{ textAlign: "center" }}>
          <IconButton onClick={addCategoryGroup}>
            <AddIcon />
          </IconButton>
        </Box>
        <Divider />
      </>
    );
  })
);

export default EditFArticleBreadcrumbComponent;
