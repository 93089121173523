import * as React from "react";
import { useRef } from "react";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { InputInterface } from "../../../interfaces/InputInterface";
import { Grid, TextField } from "@mui/material";
import CkEditorComponent from "../../content/CkEditorComponent";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { ICECAT_ASK_PRODUCT_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  closeDialog: Function;
  setFArticle: Function;
}

interface FormState {
  from: InputInterface;
  to: InputInterface;
  subject: InputInterface;
}

export const IcecatAskProductComponent: React.FC<State> = React.memo(
  ({ fArticle, closeDialog, setFArticle }) => {
    const ckEditorRef: any = useRef();
    const { t } = useTranslation();
    const [loadingSave, setLoadingSave] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        from: {
          value: "info@nove.fr",
          error: "",
        },
        to: {
          value: "editor@icecat.biz;achat@nove.fr",
          error: "",
        },
        subject: {
          value: "Création article NOVE",
          error: "",
        },
      };
    }, []);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const save = React.useCallback(async () => {
      setLoadingSave(true);
      const content = ckEditorRef.current.getValue();
      const response = await requestApi({
        method: POST,
        path: ICECAT_ASK_PRODUCT_URL,
        allowError: true,
        token: token,
        body: {
          fArticleId: fArticle?.arRef,
          from: values.from.value,
          to: values.to.value,
          subject: values.subject.value,
          content: content,
        },
      });
      if (response.statusCode === 200) {
        setFArticle(response.content);
        closeDialog();
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingSave(false);
    }, [
      closeDialog,
      fArticle?.arRef,
      setFArticle,
      t,
      token,
      values.from.value,
      values.subject.value,
      values.to.value,
    ]);

    return (
      <>
        {fArticle && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  autoComplete="off"
                  error={!!values.subject.error}
                  helperText={t(values.subject.error ?? "")}
                  sx={{ width: "100%" }}
                  type="text"
                  value={values.subject.value}
                  onChange={handleChange("subject")}
                  label={t("field.subject")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  autoComplete="off"
                  error={!!values.from.error}
                  helperText={t(values.from.error ?? "")}
                  sx={{ width: "100%" }}
                  type="text"
                  value={values.from.value}
                  onChange={handleChange("from")}
                  label={t("field.from")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  autoComplete="off"
                  error={!!values.to.error}
                  helperText={t(values.to.error ?? "")}
                  sx={{ width: "100%" }}
                  type="text"
                  value={values.to.value}
                  onChange={handleChange("to")}
                  label={t("field.to")}
                />
              </Grid>
              <Grid item xs={12}>
                <CkEditorComponent
                  ref={ckEditorRef}
                  text={
                    "<p>Bonjour,</p><p>Nous souhaiterions la création du produit suivant:</p><ul><li>Part number: " +
                    fArticle.constructeurRef +
                    "</li><li>EAN: " +
                    fArticle.arCodebarre +
                    "</li><li>Fabricant: " +
                    fArticle?.fArticleProp?.driver?.name +
                    "</li></ul><p>Merci d'avance,</p><p>NOVE</p>"
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <LoadingButton
                  loading={loadingSave}
                  variant="contained"
                  onClick={save}
                  sx={{ marginRight: 2 }}
                >
                  {t("word.save")}
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
);
