import React, { useRef } from "react";
import {
  FComptetUser,
  MailConfirmationInterface,
  MailHistoryInterface,
} from "../../../../../../interfaces/UserInterface";
import { DialogProps, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  objectToQuery,
  requestApi,
} from "../../../../../../helpers/RequestApi";
import { DELETE, GET, POST } from "../../../../../../utils/MethodUtils";
import {
  MAIL_CONFIRMATION_URL,
  MULTIPLE_MAIL_URL,
  USER_FORM_URL,
  USER_TRANSFORM_URL,
  USER_URL,
} from "../../../../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { RootState } from "../../../../../../app/store";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import getErrorApi from "../../../../../../helpers/GetErrorApi";
import { set } from "../../../../../../app/globalSlice";
import { useTheme } from "@mui/material/styles";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import CkEditorComponent from "../../../../../content/CkEditorComponent";
import UserFormNbDayTransformPlInDeComponent from "./UserFormNbDayTransformPlInDeComponent";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserPercentAcompteComponent from "./UserPercentAcompteComponent";

interface State {
  fComptetUser: FComptetUser | undefined;
  setFComptetUser: Function;
  successFunction: Function;
  closeParent: Function;
}

interface MultipleMailInterface {
  id: number;
  mail: string;
}

const UserFormComponent: React.FC<State> = React.memo(
  ({ fComptetUser, setFComptetUser, successFunction, closeParent }) => {
    const theme = useTheme();
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t, i18n } = useTranslation();
    const [loadingTransform, setLoadingTransform] = React.useState(false);
    const [loadingValidMail, setLoadingValidMail] = React.useState(false);
    const [loadingMultipleMail, setLoadingMultipleMail] = React.useState(false);
    const [loadingResetMail, setLoadingResetMail] = React.useState(false);
    const [multipleMails, setMultipleMails] = React.useState<
      MultipleMailInterface[] | undefined
    >(undefined);
    const [mailConfirmation, setMailConfirmation] = React.useState<
      MailConfirmationInterface | undefined | null
    >(undefined);
    const [mailHistories, setMailHistories] = React.useState<
      MailHistoryInterface[] | undefined
    >(undefined);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();
    const ckEditorRef: any = useRef();
    const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState(false);

    const transform = React.useCallback(async () => {
      setLoadingTransform(true);
      const response = await requestApi({
        method: POST,
        path: USER_TRANSFORM_URL + "/" + fComptetUser?.userIdentifier,
        allowError: true,
        token: token,
        body: {},
      });
      if (response.statusCode === 200) {
        dispatch(
          set({
            nbAdministrationNotYetTransform:
              response.content.nbAdministrationNotYetTransform,
          })
        );
        successFunction(response.content.userIdentifier);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
        setLoadingTransform(false);
      }
    }, [dispatch, fComptetUser?.userIdentifier, successFunction, t, token]);

    const deleteUser = React.useCallback(async () => {
      setLoadingDelete(true);
      const response = await requestApi({
        method: DELETE,
        path: USER_URL + "/" + fComptetUser?.userIdentifier,
        allowError: true,
        token: token,
      });
      if (response.statusCode === 204) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.user_deleted")
        );
        setOpenDialogDelete(false);
        closeParent();
        dispatch(set({ refreshPage: true }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
        setLoadingTransform(false);
      }
      setLoadingDelete(false);
    }, [closeParent, dispatch, fComptetUser?.userIdentifier, t, token]);

    const resetMail = React.useCallback(async () => {
      if (!fComptetUser) {
        return;
      }
      setLoadingResetMail(true);
      const response = await requestApi({
        method: POST,
        path: MAIL_CONFIRMATION_URL,
        allowError: false,
        body: {
          userIdentifier: fComptetUser.userIdentifier,
          newMail: fComptetUser.ctEmail?.split(";")[0],
          required: true,
          message: ckEditorRef.current.getValue(),
          createdByAdmin: true,
        },
        token: token,
      });
      if (response.statusCode === 201) {
        toastr.success(t("word.success"), "");
        successFunction(fComptetUser.userIdentifier);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingResetMail(false);
    }, [fComptetUser, successFunction, t, token]);

    const loadUser = React.useCallback(async () => {
      if (!fComptetUser?.userIdentifier) {
        return;
      }
      requestApi({
        method: GET,
        path: USER_FORM_URL.replace(
          "{userIdentifier}",
          fComptetUser.userIdentifier
        ),
        allowError: true,
        token: token,
      }).then((response) => {
        if (response.statusCode === 200) {
          setMailConfirmation(response.content.mailConfirmation);
          setMailHistories(response.content.mailHistories);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      });
      setMultipleMails(undefined);
      requestApi({
        method: GET,
        path:
          MULTIPLE_MAIL_URL +
          objectToQuery({
            "mail[]": (fComptetUser.ctEmail?.split(";") ?? []).map(
              (email) => email
            ),
          }),
        allowError: true,
        token: token,
      }).then((response) => {
        if (response.statusCode === 200) {
          setMultipleMails(response.content);
        } else {
          setMultipleMails([]);
          if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"));
          } else {
            toastr.error(t("word.error"), t("error.tryAgain"));
          }
        }
      });
    }, [fComptetUser?.ctEmail, fComptetUser?.userIdentifier, t, token]);

    const validateMail = React.useCallback(async () => {
      setLoadingValidMail(true);
      const response = await requestApi({
        method: GET,
        path:
          USER_URL +
          "/" +
          fComptetUser?.userIdentifier +
          "/activate/whatever?redirect=false",
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        toastr.success(t("word.success"), t("sentence.mailValidated"));
        await loadUser();
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setLoadingValidMail(false);
    }, [fComptetUser?.userIdentifier, loadUser, t, token]);

    const handleDialogDelete = React.useCallback(() => {
      if (loadingDelete) {
        return;
      }
      setOpenDialogDelete((x) => !x);
    }, [loadingDelete]);

    const updateMultipleMails = React.useCallback(
      async (mail: string, id: number | null) => {
        setLoadingMultipleMail(true);
        let response;
        if (id === null) {
          response = await requestApi({
            method: POST,
            path: MULTIPLE_MAIL_URL,
            allowError: true,
            token: token,
            body: {
              mail: mail,
            },
          });
        } else {
          response = await requestApi({
            method: DELETE,
            path: MULTIPLE_MAIL_URL + "/" + id,
            allowError: true,
            token: token,
          });
        }
        if (response.statusCode === 201 || response.statusCode === 204) {
          await loadUser();
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
          setLoadingTransform(false);
        }
        setLoadingMultipleMail(false);
      },
      [loadUser, t, token]
    );

    React.useEffect(() => {
      loadUser();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let creationDate = "";
    if (fComptetUser?.created) {
      creationDate = new Date(fComptetUser.created).toLocaleDateString(
        i18n.language,
        {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          timeZone: "Etc/UTC",
        }
      );
    }
    return (
      <>
        {!!fComptetUser && (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{t("word.email") + ": "}</Typography>
              <ul
                style={{
                  margin: 0,
                  paddingLeft: 4,
                  paddingRight: 0,
                  listStyleType: "none",
                }}
              >
                {(fComptetUser.ctEmail?.split(";") ?? []).map(
                  (email, indexEmail) => {
                    const checked = multipleMails?.find(
                      (m) => m.mail === email
                    );
                    return (
                      <li key={indexEmail}>
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {email}
                          {multipleMails === undefined ? (
                            <Skeleton
                              variant="text"
                              sx={{ minWidth: "50px" }}
                            />
                          ) : (
                            <LoadingButton
                              variant="text"
                              loading={loadingMultipleMail}
                              onClick={() =>
                                updateMultipleMails(email, checked?.id ?? null)
                              }
                            >
                              {checked ? t("word.notUnique") : t("word.unique")}
                            </LoadingButton>
                          )}
                        </Typography>
                      </li>
                    );
                  }
                )}
              </ul>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography>
                {t("word.siret") + ": " + fComptetUser.ctSiret}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography>
                {t("word.creationDate") + ": " + creationDate}
              </Typography>
            </Box>
            {mailHistories === undefined ? (
              <Skeleton variant="text" />
            ) : (
              <Box sx={{ display: "flex" }}>
                <Typography>{t("word.emailHistory") + ": "}</Typography>
                <ul
                  style={{
                    margin: 0,
                    paddingLeft: 4,
                    paddingRight: 0,
                    listStyleType: "none",
                  }}
                >
                  {mailHistories.map((mailHistory, indexMailHistory) => (
                    <li key={indexMailHistory}>
                      {mailHistory.emails + " "}
                      <span style={{ color: theme.palette.primary.main }}>
                        [
                        {new Date(mailHistory.created).toLocaleString(
                          i18n.language,
                          {
                            dateStyle: "long",
                            timeStyle: "medium",
                            timeZone: "America/Cuiaba",
                          }
                        )}
                        ]
                      </span>
                    </li>
                  ))}
                </ul>
              </Box>
            )}
            {mailConfirmation === undefined ? (
              <Skeleton variant="text" />
            ) : (
              <Typography sx={{ display: "flex" }}>
                {t("word.emailValidated")}
                {mailConfirmation === null ? (
                  <DoneIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
              </Typography>
            )}
            <Typography>
              {t("word.phoneNumber") + ": " + fComptetUser.ctTelephone}
            </Typography>
            <UserPercentAcompteComponent
              fComptetUser={fComptetUser}
              setFComptetUser={setFComptetUser}
            />
            <UserFormNbDayTransformPlInDeComponent
              fComptetUser={fComptetUser}
              setFComptetUser={setFComptetUser}
            />
            <Box sx={{ textAlign: "center", mt: 2 }}>
              {mailConfirmation !== null && (
                <Box>
                  <Typography color="error">
                    {t("sentence.emailValidated")}
                  </Typography>
                  <LoadingButton
                    variant="contained"
                    disabled={loadingTransform}
                    loading={loadingValidMail}
                    onClick={validateMail}
                    sx={{ mt: 2 }}
                  >
                    {t("word.validMail")}
                  </LoadingButton>
                  {mailConfirmation?.createdByAdmin && (
                    <Typography color="error">
                      {t("sentence.mailConfirmationCreatedByAdmin")}
                    </Typography>
                  )}
                </Box>
              )}
              {!fComptetUser.sage && (
                <Box>
                  <LoadingButton
                    variant="contained"
                    disabled={loadingValidMail}
                    loading={loadingTransform}
                    onClick={transform}
                    sx={{ mt: 2 }}
                  >
                    {t(
                      "word." +
                        (mailConfirmation === null
                          ? "transform"
                          : "transformAndValidMail")
                    )}
                  </LoadingButton>
                </Box>
              )}
            </Box>
            {mailConfirmation === null && (
              <Accordion defaultExpanded={false} sx={{ marginTop: 3 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography sx={{ color: "white" }}>
                    {t("word.forceResetMail")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CkEditorComponent
                    ref={ckEditorRef}
                    text={
                      "<p>Bonjour, nous avons tenté de vous joindre à " +
                      (fComptetUser.ctEmail?.split(";")[0] ?? "") +
                      " sans succès. Merci de bien vouloir mettre à jour votre mail.</p>"
                    }
                  />
                  <Box sx={{ textAlign: "right", marginTop: 2 }}>
                    <LoadingButton
                      variant="contained"
                      loading={loadingResetMail}
                      onClick={resetMail}
                    >
                      {t("word.validate")}
                    </LoadingButton>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
            {!fComptetUser.sage && (
              <Box sx={{ textAlign: "right" }}>
                <Dialog
                  maxWidth={maxWidth}
                  fullScreen={fullScreen}
                  fullWidth={true}
                  onClose={handleDialogDelete}
                  open={openDialogDelete}
                >
                  <DialogContent>
                    <Typography sx={{ textAlign: "center" }}>
                      {t("sentence.askFComptetUser")}
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: "space-between" }}>
                    <LoadingButton
                      disabled={loadingDelete}
                      onClick={handleDialogDelete}
                    >
                      {t("word.no")}
                    </LoadingButton>
                    <LoadingButton
                      variant="contained"
                      loading={loadingDelete}
                      onClick={deleteUser}
                      color="error"
                    >
                      {t("word.yes")}
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
                <LoadingButton
                  variant="contained"
                  onClick={handleDialogDelete}
                  sx={{ mt: 2 }}
                  color="error"
                >
                  {t("word.delete")}
                </LoadingButton>
              </Box>
            )}
          </>
        )}
      </>
    );
  }
);

export default UserFormComponent;
