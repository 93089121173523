export const CONTAINER = "A";
export const CONTAINER_FLUID = "B";

export const CONTENT_CKEDITOR = "A";
export const CONTENT_FARTICLE = "B";
export const CONTENT_MAP = "C";
export const CONTENT_PDF = "D";
export const CONTENT_CAROUSEL = "E";
export const CONTENT_VIDEO = "F";

export const SEARCH_PRODUCT_ALWAYS = "A";
export const SEARCH_PRODUCT_AT_LEAST_ONE = "B";
export const SEARCH_PRODUCT_NO_RESULT = "C";
export const SEARCH_PRODUCT_ONE_RESULT = "D";
export const SEARCH_PRODUCT_MULTIPLE_RESULTS = "E";

export const ALL_SEARCH_PRODUCT = [
  SEARCH_PRODUCT_ALWAYS,
  SEARCH_PRODUCT_AT_LEAST_ONE,
  SEARCH_PRODUCT_NO_RESULT,
  SEARCH_PRODUCT_ONE_RESULT,
  SEARCH_PRODUCT_MULTIPLE_RESULTS,
];
