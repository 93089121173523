import React, { SyntheticEvent, useImperativeHandle } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { toastr } from "react-redux-toastr";
import { SavInterface } from "../../../interfaces/SavInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import { RootState } from "../../../app/store";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import {
  SAGE_DO_TYPE_VENTE_FA,
  SAGE_DO_TYPE_VENTE_FC,
} from "../../../utils/DoceneteteUtils";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FDOCENTETES_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import { UserInterface } from "../../../interfaces/UserInterface";
import { set } from "../../../app/globalSlice";

interface State {
  sav?: SavInterface | null;
  setSav: Function;
  setFDocentete: Function;
  userForm: UserInterface | undefined;
}

interface FormState {
  fDocentete: InputInterface;
}

const itemsPerPage = 50;

const SavFDocenteteNoveFormComponent = React.memo(
  React.forwardRef(({ sav, setSav, setFDocentete, userForm }: State, ref) => {
    const { t, i18n } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultFDocentetes =
      React.useCallback((): FDocenteteInterface[] => {
        return [];
      }, []);
    const [fDocentetes, setFDocenetes] = React.useState<FDocenteteInterface[]>(
      getDefaultFDocentetes()
    );
    const [init, setInit] = React.useState<boolean>(false);
    const [searchFDocentete, setSearchFDocentete] = React.useState<string>("");
    const [isTyping, setIsTyping] = React.useState<boolean>(false);
    const [isSearching, setIsSearching] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();

    const getDefaultValue = React.useCallback((): FormState => {
      return {
        fDocentete: {
          value: sav?.fDocenteteId
            ? { doPiece: sav.fDocenteteId, doDate: sav.fDocenteteDate }
            : null,
          error: "",
        },
      };
    }, [sav]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const handleSearchFDocentete = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: string) => {
        setSearchFDocentete(value);
        setFDocenetes([]);
        setIsTyping(true);
      },
      []
    );

    const searchFDocentetes = React.useCallback(async () => {
      setIsTyping(false);
      if (!userForm || sav?.id) {
        return;
      }
      let thisSearch: string[] | string = searchFDocentete.trim();
      thisSearch = thisSearch.split(" ");
      thisSearch = thisSearch[thisSearch.length - 1];
      setIsSearching(true);
      const param: any = {
        "doType[]": [SAGE_DO_TYPE_VENTE_FA, SAGE_DO_TYPE_VENTE_FC],
        itemsPerPage: itemsPerPage,
        doTiers: userForm?.userIdentifier,
        ...(thisSearch !== "" && { doPiece: thisSearch }),
      };
      if (!values.fDocentete.value && sav && !sav.id && sav.fDoclignePaginate) {
        setSav((x: SavInterface) => {
          return {
            ...x,
            fDocenteteId: "",
            fDocenteteDate: "",
            fDoclignePaginate: undefined,
          };
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: FDOCENTETES_URL + objectToQuery(param),
        allowError: false,
        paginate: false,
        token: token,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        setFDocenetes(response.content.fDocentetes);
        dispatch(
          set({ accountingSituation: response.content.accountingSituation })
        );
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setInit(true);
      setIsSearching(false);
    }, [
      dispatch,
      sav,
      searchFDocentete,
      setSav,
      t,
      token,
      userForm,
      values.fDocentete.value,
    ]);

    const getValue = React.useCallback(() => {
      const fDocenteteError = notEmptyValidator(values.fDocentete.value);
      if (fDocenteteError) {
        const newValue: FormState = { ...values };
        if (fDocenteteError) {
          newValue.fDocentete.error = fDocenteteError;
        }
        setValues(newValue);
        return undefined;
      }
      return {
        fDocenteteId: values.fDocentete.value.doPiece,
      };
    }, [values]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        if (init) {
          searchFDocentetes();
        }
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [searchFDocentete]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValues(getDefaultValue());
      setFDocenetes(getDefaultFDocentetes());
      searchFDocentetes();
    }, [userForm, sav]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setFDocentete(values.fDocentete.value);
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Autocomplete
          options={fDocentetes ?? []}
          disabled={!!sav?.id || !userForm}
          noOptionsText={
            isSearching ? (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Box>
            ) : searchFDocentete !== "" && !isTyping ? (
              t("sentence.no_option")
            ) : (
              t("word.sav.searchFDocentete.label")
            )
          }
          inputValue={searchFDocentete}
          onInputChange={handleSearchFDocentete}
          getOptionLabel={(fDocentete: FDocenteteInterface) => {
            return (
              new Date(fDocentete.doDate).toLocaleDateString(i18n.language, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                timeZone: "Etc/UTC",
              }) +
              " " +
              fDocentete.doPiece
            );
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.doPiece}>
                {new Date(option.doDate).toLocaleDateString(i18n.language, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  timeZone: "Etc/UTC",
                }) +
                  " " +
                  option.doPiece}
              </li>
            );
          }}
          isOptionEqualToValue={(
            option: FDocenteteInterface,
            value: FDocenteteInterface
          ) => option.doPiece === value.doPiece}
          onChange={handleChangeAutocomplete("fDocentete")}
          value={values.fDocentete.value}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              error={!!values.fDocentete.error}
              helperText={t(values.fDocentete.error ?? "")}
              required
              label={t("word.bill")}
            />
          )}
        />
      </>
    );
  })
);

export default SavFDocenteteNoveFormComponent;
