import React from "react";
import Layout from "../../components/common/Layout";
import LoginComponent from "../../components/common/user/LoginComponent";
import { getTitle } from "../../helpers/SearchParamHelper";
import EntreeStockNoArrivageComponent from "../../components/common/entreeStock/EntreeStockNoArrivageComponent";

const EntreeStockScreen: React.FC = React.memo(() => {
  React.useEffect(() => {
    document.title = getTitle("entreeStock");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <EntreeStockNoArrivageComponent />
      </LoginComponent>
    </Layout>
  );
});

export default EntreeStockScreen;
