import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";
import { CATEGORY_URL } from "../../../../utils/UrlsUtils";
import { FilterFilterCategoryInterface } from "../../../../interfaces/FilterInterface";
import { useTheme } from "@mui/material/styles";

interface State {
  label?: string;
  paramName?: string;
}

const AutocompleteCategoryComponent: React.FC<State> = React.memo(
  ({ label, paramName }) => {
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const categories = useAppSelector(
      (state: RootState) => state.globalState.categories
    );

    const [thisCategories] = React.useState<FilterFilterCategoryInterface[]>(
      categories
        ?.map((c) => c.children)
        .reduce((a, b) => {
          // @ts-ignore
          return a.concat(b);
        }, [])
        ?.map((c) => {
          return {
            category: {
              id: c.id,
              name: c.name,
              parent: c.parent?.toString(),
              slug: c.slug,
            },
            sort: 9999,
            opened: false,
          };
        }) ?? []
    );

    const handleChangeAutocompleteCategory = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: any) => {
        const newSearchParams = searchParamToObject(searchParams);
        delete newSearchParams[paramName ?? "category"];
        if (value) {
          newSearchParams[paramName ?? "category"] = value.category.id;
        }
        newSearchParams.page = 1;
        setSearchParams(newSearchParams, {
          replace: true,
        });
      },
      [paramName, searchParams, setSearchParams]
    );

    const searchParamsObject = searchParamToObject(searchParams);
    return (
      <Autocomplete
        fullWidth
        options={thisCategories ?? []}
        getOptionLabel={(filterCategory: FilterFilterCategoryInterface) => {
          return (
            "[" +
              filterCategory.category.id +
              "] " +
              filterCategory.category.name ?? ""
          );
        }}
        isOptionEqualToValue={(
          option: FilterFilterCategoryInterface,
          value: FilterFilterCategoryInterface
        ) => {
          return option.category.id === value.category.id;
        }}
        groupBy={(option) =>
          categories?.find(
            (c) => CATEGORY_URL + "/" + c.id === option.category.parent
          )?.name ?? ""
        }
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={option.category.id}
              style={{ display: "block" }}
            >
              <span style={{ color: theme.palette.primary.main }}>
                {"[" + option.category.id + "] "}
              </span>
              {option.category.name}
            </li>
          );
        }}
        onChange={handleChangeAutocompleteCategory}
        value={
          thisCategories.find((c) => {
            return (
              c.category.id?.toString() ===
              searchParamsObject[paramName ?? "category"]
            );
          }) ?? null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required
            autoComplete="off"
            label={label ?? t("word.category")}
            placeholder={label ?? t("word.category")}
          />
        )}
      />
    );
  }
);

export default AutocompleteCategoryComponent;
