import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import {
  Box,
  Chip,
  DialogContent,
  DialogProps,
  Grid,
  Tooltip,
  useTheme,
} from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { Link, useSearchParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { set } from "../../../../app/globalSlice";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";
import { objectToQuery, requestApi } from "../../../../helpers/RequestApi";
import { GET } from "../../../../utils/MethodUtils";
import { FILTER_VALUE_ASSO_URL } from "../../../../utils/UrlsUtils";
import DrawerComponent from "../../DrawerComponent";
import { getLocaleDataGrid } from "../../../../helpers/GetLanguage";
import EditIcon from "@mui/icons-material/Edit";
import {
  FilterValueAssoFilterInterface,
  FilterValueAssoFilterValueInterface,
  FilterValueAssoInterface,
  FilterValueAssoValueInterface,
} from "../../../../interfaces/FilterInterface";
import FilterValueAssoFormComponent from "./form/filterForm/FilterValueAssoFormComponent";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import FilterValueAssoDeleteComponent from "./form/filterForm/FilterValueAssoDeleteComponent";
import {
  BROWSER_FILTER_VALUE_ASSO,
  CATEGORY_PAGE,
} from "../../../../utils/RouteUtils";
import AutocompleteCategoryComponent from "../../category/form/AutocompleteCategoryComponent";

const minWidth = 1200;

const clone = require("clone");

const FilterAdminAssoComponent: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const theme = useTheme();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const width = window.innerWidth;
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(50);
  const [filterValueAssos, setFilterValueAssos] = React.useState<
    FilterValueAssoInterface[] | undefined
  >(undefined);
  const [open, setOpen] = React.useState(false);
  const [totalItems, setTotalItems] = React.useState(0);
  const [filterValueAsso, setFilterValueAsso] = React.useState<
    FilterValueAssoInterface | null | undefined
  >(undefined);
  const [filterValueAssoDelete, setFilterValueAssoDelete] = React.useState<
    FilterValueAssoInterface | undefined
  >(undefined);
  const [filterValueLink, setFilterValueLink] = React.useState<
    FilterValueAssoFilterValueInterface | undefined
  >(undefined);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const newBrowser = useAppSelector(
    (state: RootState) => state.globalState.newBrowser
  );

  const handleDrawerDeleteOpen = React.useCallback(
    (thisFilterValue: FilterValueAssoInterface) => {
      setFilterValueAssoDelete(thisFilterValue);
    },
    []
  );

  const handleDrawerDeleteClose = React.useCallback(() => {
    setFilterValueAssoDelete(undefined);
  }, []);

  const handleDrawerLinkOpen = React.useCallback(
    (thisFilterValueLink: FilterValueAssoFilterValueInterface) => {
      setFilterValueLink(thisFilterValueLink);
    },
    []
  );

  const handleDrawerLinkClose = React.useCallback(() => {
    setFilterValueLink(undefined);
  }, []);

  const onDeleteSuccess = React.useCallback(() => {
    handleDrawerDeleteClose();
    dispatch(set({ refreshPage: true }));
  }, [dispatch, handleDrawerDeleteClose]);

  const handleDrawerOpen = React.useCallback(
    (thisId: FilterValueAssoInterface | null) => {
      if (!newBrowser) {
        setFilterValueAsso(thisId);
        setOpen(true);
      } else {
        window.open(
          BROWSER_FILTER_VALUE_ASSO + "/" + thisId?.id,
          "filterAsso" + thisId?.id,
          "height=1000,width=1100"
        );
      }
    },
    [newBrowser]
  );
  const getColumns = React.useCallback(() => {
    const result: GridColDef[] = [
      {
        field: "id",
        flex: 0,
        headerName: t("column.id"),
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "name",
        flex: 0,
        headerName: t("column.name"),
        headerClassName: "background-nove",
        filterable: false,
      },
      ...["From", "To"].map((key: string) => {
        return {
          field: "filterValues" + key,
          flex: 1,
          headerName: t("column." + key.toLowerCase()),
          headerClassName: "background-nove",
          filterable: false,
          cellClassName: "flex-wrap-wrap",
          renderCell: (params: GridRenderCellParams) => {
            const prop = "filterValues" + key;
            return (
              <>
                {params.row.filterValueAssoValues?.map(
                  (filterValueAssoValue: FilterValueAssoValueInterface) =>
                    // @ts-ignore
                    filterValueAssoValue[prop]?.map(
                      (
                        filterValue: FilterValueAssoFilterValueInterface,
                        indexFilterValue: number
                      ) => {
                        let link = null;
                        if (filterValue.filter.filterCategories.length === 1) {
                          link =
                            CATEGORY_PAGE +
                            "/" +
                            filterValue.filter.filterCategories[0].category
                              .slug +
                            objectToQuery({
                              "filterValues[]": [
                                JSON.stringify({
                                  condition: filterValue.filter.condition,
                                  values: [filterValue.id],
                                }),
                              ],
                            });
                        }
                        const label =
                          "(" +
                          filterValue.filter.filterCategories
                            ?.map(
                              (filterCategory) =>
                                "[" +
                                filterCategory.category.id.toString() +
                                "] " +
                                filterCategory.category.name
                            )
                            .join(" | ") +
                          ") | " +
                          "[" +
                          filterValue.filter.id +
                          "] " +
                          filterValue.filter.name +
                          " | [" +
                          filterValue.id +
                          "] " +
                          filterValue.name;
                        return (
                          <React.Fragment key={indexFilterValue}>
                            {link ? (
                              <Link
                                className="display-content"
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                                to={link}
                              >
                                <Tooltip title={label} placement="right">
                                  <Chip
                                    label={label}
                                    variant="outlined"
                                    color="primary"
                                    sx={{ marginLeft: 1, cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </Link>
                            ) : (
                              <Tooltip title={label} placement="right">
                                <Chip
                                  label={label}
                                  variant="outlined"
                                  color="primary"
                                  sx={{ marginLeft: 1 }}
                                  onClick={() =>
                                    handleDrawerLinkOpen(filterValue)
                                  }
                                />
                              </Tooltip>
                            )}
                          </React.Fragment>
                        );
                      }
                    )
                )}
              </>
            );
          },
        };
      }),
      {
        field: "commonFilters",
        flex: 1,
        headerName: t("word.commonFilters"),
        headerClassName: "background-nove",
        filterable: false,
        cellClassName: "flex-wrap-wrap",
        renderCell: (params: GridRenderCellParams) => {
          return (
            <>
              {params.row.filterValueAssoValues?.map(
                (filterValueAssoValue: FilterValueAssoValueInterface) =>
                  filterValueAssoValue.commonFilters.map(
                    (
                      commonFilter: FilterValueAssoFilterInterface,
                      indexCommonFilter: number
                    ) => {
                      let link = null;
                      if (commonFilter.filterCategories.length === 1) {
                        link =
                          CATEGORY_PAGE +
                          "/" +
                          commonFilter.filterCategories[0].category.slug;
                      }
                      const label =
                        "(" +
                        commonFilter.filterCategories
                          ?.map(
                            (filterCategory) =>
                              "[" +
                              filterCategory.category.id.toString() +
                              "] " +
                              filterCategory.category.name
                          )
                          .join(" | ") +
                        ") | " +
                        "[" +
                        commonFilter.id +
                        "] " +
                        commonFilter.name;
                      return (
                        <React.Fragment key={indexCommonFilter}>
                          {link ? (
                            <Link
                              className="display-content"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              to={link}
                            >
                              <Tooltip title={label} placement="right">
                                <Chip
                                  label={label}
                                  variant="outlined"
                                  color="primary"
                                  sx={{ marginLeft: 1, cursor: "pointer" }}
                                />
                              </Tooltip>
                            </Link>
                          ) : (
                            <Tooltip title={label} placement="right">
                              <Chip
                                label={label}
                                variant="outlined"
                                color="primary"
                                sx={{ marginLeft: 1 }}
                              />
                            </Tooltip>
                          )}
                        </React.Fragment>
                      );
                    }
                  )
              )}
            </>
          );
        },
      },
      {
        field: "bijection",
        flex: 0,
        headerName: t("column.bijection"),
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.bijection ? (
            <DoneIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          );
        },
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <IconButton onClick={() => handleDrawerOpen(params.row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDrawerDeleteOpen(params.row)}>
              <DeleteIcon />
            </IconButton>
          </>
        ),
      },
    ];
    return result;
  }, [handleDrawerDeleteOpen, handleDrawerLinkOpen, handleDrawerOpen, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());
  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (filterValueAssos !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: FILTER_VALUE_ASSO_URL + objectToQuery({ ...searchParamsObject }),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"]?.map(
            (o: FilterValueAssoInterface) => o.id
          )
        );
        const newFilterValueAssos = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          )?.map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setFilterValueAssos(newFilterValueAssos);
        setTotalItems(response.content["hydra:totalItems"]);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      filterValueAssos,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const handleDrawerClose = React.useCallback(() => {
    setFilterValueAsso(undefined);
    setOpen(false);
  }, []);

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setFilterValueAssos(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setFilterValueAssos(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  const onFilterValueAssoCreatedUpdated = React.useCallback(
    (filterValueAsso: FilterValueAssoInterface) => {
      handleDrawerClose();
      setFilterValueAssos((x) => {
        if (!x) {
          return x;
        }
        const copy: FilterValueAssoInterface[] = clone(x);
        let hasChange = false;
        const index1 = copy.findIndex((y) => y.id === filterValueAsso.id);
        if (index1 >= 0) {
          copy[index1] = filterValueAsso;
          hasChange = true;
        }
        if (hasChange) {
          return [...copy];
        }
        dispatch(set({ refreshPage: true }));
        return x;
      });
    },
    [dispatch, handleDrawerClose]
  );

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        onClose={handleDrawerLinkClose}
        open={!!filterValueLink}
      >
        <DialogContent>
          {filterValueLink &&
            filterValueLink.filter.filterCategories?.map(
              (filterCategory, indexFilterCategory) => (
                <Link
                  key={indexFilterCategory}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  to={
                    CATEGORY_PAGE +
                    "/" +
                    filterCategory.category.slug +
                    objectToQuery({
                      "filterValues[]": [
                        JSON.stringify({
                          condition: filterValueLink.filter.condition,
                          values: [filterValueLink.id],
                        }),
                      ],
                    })
                  }
                >
                  <Chip
                    label={filterCategory.category.name}
                    variant="outlined"
                    color="primary"
                    sx={{ marginLeft: 1, cursor: "pointer" }}
                  />
                </Link>
              )
            )}
        </DialogContent>
      </Dialog>
      <DrawerComponent
        open={open}
        handleDrawerClose={handleDrawerClose}
        onClose={handleDrawerClose}
        drawerwidth={width < minWidth ? width : minWidth}
        content={
          <FilterValueAssoFormComponent
            filterValueAsso={filterValueAsso}
            successFunction={onFilterValueAssoCreatedUpdated}
          />
        }
      />
      <Dialog
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        onClose={handleDrawerDeleteClose}
        open={!!filterValueAssoDelete}
      >
        <DialogContent>
          <FilterValueAssoDeleteComponent
            filterValueAsso={filterValueAssoDelete}
            close={handleDrawerDeleteClose}
            successFunction={onDeleteSuccess}
          />
        </DialogContent>
      </Dialog>
      <Box>
        <Grid container spacing={1} sx={{ marginBottom: 1 }}>
          <Grid item xs={4}>
            <AutocompleteCategoryComponent
              label={t("word.categoryFrom")}
              paramName="categoryFrom"
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteCategoryComponent
              label={t("word.categoryTo")}
              paramName="categoryTo"
            />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              onClick={() => handleDrawerOpen(null)}
            >
              {t("word.link.create")}
            </LoadingButton>
          </Grid>
        </Grid>
        {searchParamsObject.itemsPerPage && searchParamsObject.page && (
          // https://mui.com/x/react-data-grid/components/#pagination
          <DataGrid
            initialState={{
              sorting: {
                sortModel: getSortModel(),
              },
            }}
            getRowHeight={() => "auto"}
            loading={refreshPage}
            rows={refreshPage ? [] : filterValueAssos ?? []}
            onSortModelChange={onSortModelChange}
            sortingMode="server"
            page={Number(searchParamsObject.page) - 1}
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={Number(searchParamsObject.itemsPerPage)}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            rowCount={totalItems}
            columns={columns}
            autoHeight={true}
            disableExtendRowFullWidth={true}
            localeText={getLocaleDataGrid(i18n.language)}
          />
        )}
      </Box>
    </>
  );
});

export default FilterAdminAssoComponent;
