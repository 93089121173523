import React, { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { TextField } from "@mui/material";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { FARTFOURNISSES_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  ArrivageFDocligneInterface,
  ArrivageInterface,
} from "../../../interfaces/ArrivageInterface";
import { FArtfournissInterface } from "../../../interfaces/FArticleInterface";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";

const clone = require("clone");

interface State {
  fArtfourniss: FArtfournissInterface[] | undefined;
  disabled?: boolean;
  arRef: string;
  setArrivage: Function;
  fDoclignes: ArrivageFDocligneInterface[];
  focusMainSearch: Function;
}

interface FArtfournissFormInterface extends FArtfournissInterface {
  ref: any;
}

interface State2 {
  cbMarq: string;
  ctNum: string;
  afColisage: number;
  edit: boolean;
}

interface FormState {
  afColisage: InputInterface;
}

const UpdateColisageComponentForm = React.memo(
  React.forwardRef(({ ctNum, afColisage, edit, cbMarq }: State2, ref) => {
    const { t } = useTranslation();

    const getDefaultValues = React.useCallback((): FormState => {
      return {
        afColisage: { value: afColisage, error: "" },
      };
    }, [afColisage]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          cbMarq: cbMarq,
          ctNum: ctNum,
          afColisage: Number(values.afColisage.value).toString(),
        };
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [ctNum, afColisage, edit]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <TableCell sx={{ paddingY: 0 }}>{ctNum}</TableCell>
        <TableCell sx={{ paddingY: 0 }}>
          {edit ? (
            <TextField
              fullWidth={true}
              autoComplete="off"
              onFocus={(e) => e.target.select()}
              inputProps={{
                sx: {
                  paddingY: 0.5,
                  paddingX: 0.5,
                },
              }}
              error={!!values.afColisage.error}
              helperText={t(values.afColisage.error ?? "")}
              type="number"
              value={values.afColisage.value}
              onChange={handleChange("afColisage")}
            />
          ) : (
            <>{afColisage}</>
          )}
        </TableCell>
      </>
    );
  })
);

const UpdateColisageComponent: React.FC<State> = React.memo(
  ({
    fArtfourniss,
    disabled,
    arRef,
    setArrivage,
    fDoclignes,
    focusMainSearch,
  }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getFArtfournissForm =
      React.useCallback((): FArtfournissFormInterface[] => {
        // @ts-ignore
        const ctNums = [...new Set(fDoclignes.map((f) => f.doTiers))];
        return (
          fArtfourniss
            ?.filter((f) => ctNums.includes(f.ctNum))
            ?.map((fArtfournis) => {
              return {
                ...fArtfournis,
                ref: React.createRef(),
              };
            }) ?? []
        );
      }, [fArtfourniss, fDoclignes]);
    const [fArtfournissForm, setFArtfournissForm] = React.useState<
      FArtfournissFormInterface[]
    >(getFArtfournissForm());

    const handleEdit = React.useCallback(() => {
      setEdit((x) => !x);
    }, []);

    const save = React.useCallback(async () => {
      setLoading(true);
      const values = fArtfournissForm.map((f) => f.ref.current.getValue());
      for (const value of values) {
        const response = await requestApi({
          method: PATCH,
          path: FARTFOURNISSES_URL + "/" + value.cbMarq,
          allowError: true,
          token: token,
          body: {
            afColisage: value.afColisage,
          },
        });
        if (response.statusCode === 200) {
          setArrivage((a: ArrivageInterface) => {
            const fDocenteteLigneArrivage =
              a.data.fDocenteteLigneArrivages?.find((f) => f.arRef === arRef);
            if (fDocenteteLigneArrivage) {
              const fArtfournis = fDocenteteLigneArrivage.fArtfourniss?.find(
                (f) => f.cbMarq === value.cbMarq
              );
              if (fArtfournis) {
                fArtfournis.afColisage = Number(value.afColisage);
                a = clone(a);
              }
            }
            return a;
          });
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }
      setLoading(false);
      setEdit(false);
      focusMainSearch();
    }, [arRef, fArtfournissForm, focusMainSearch, setArrivage, t, token]);

    React.useEffect(() => {
      setFArtfournissForm(getFArtfournissForm());
    }, [fDoclignes]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingY: 0 }}>{t("word.supplier")}</TableCell>
                <TableCell sx={{ paddingY: 0 }}>{t("word.colisage")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fArtfournissForm.map((fArtfournisForm, indexFArtfournisForm) => (
                <TableRow key={indexFArtfournisForm}>
                  <UpdateColisageComponentForm
                    ctNum={fArtfournisForm.ctNum}
                    afColisage={fArtfournisForm.afColisage}
                    cbMarq={fArtfournisForm.cbMarq}
                    ref={fArtfournisForm.ref}
                    edit={edit}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        {edit ? (
          <LoadingButton
            variant="text"
            color="inherit"
            disabled={disabled}
            sx={{
              borderRadius: "50%",
              minWidth: "auto",
              padding: "12px",
            }}
            loading={loading}
            onClick={save}
          >
            <SaveIcon />
          </LoadingButton>
        ) : (
          <>
            {fArtfournissForm.length > 0 && (
              <IconButton
                color="inherit"
                onClick={handleEdit}
                disabled={disabled}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        )}
      </Box>
    );
  }
);

export default UpdateColisageComponent;
