import React, { forwardRef, SyntheticEvent, useImperativeHandle } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import Slider from "@mui/material/Slider";
import { useTranslation } from "react-i18next";
import { CategoryPriceInterface } from "../../../interfaces/CategoryInterface";
import { priceFormat } from "../../../utils/FormatUtils";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface State {
  apply: Function;
  setNbSelected: Function;
  initPrices: CategoryPriceInterface;
  index: number;
  filterInited: Function;
}

const PriceFilterComponent = React.memo(
  forwardRef(
    ({ apply, setNbSelected, initPrices, index, filterInited }: State, ref) => {
      const theme = useTheme();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const [searchParams] = useSearchParams();
      const searchParamsObject = searchParamToObject(searchParams);
      const catTarif = useAppSelector(
        (state: RootState) => state.globalState.catTarif
      );
      const [value, setValue] = React.useState<number[]>(() => {
        // @ts-ignore
        const result = [0, initPrices["price" + catTarif]];
        if (searchParamsObject.fromPrice) {
          result[0] = Number(searchParamsObject.fromPrice);
        }
        if (searchParamsObject.toPrice) {
          result[1] = Number(searchParamsObject.toPrice);
        }
        return result;
      });
      const { t, i18n } = useTranslation();

      const handleChange = React.useCallback(
        (event: Event, newValue: number | number[]) => {
          setValue(newValue as number[]);
        },
        []
      );

      const handleChangeCommitted = React.useCallback(
        (
          event: Event | SyntheticEvent<Element, Event>,
          newValue: number | number[]
        ) => {
          newValue = newValue as number[];
          setNbSelected(
            newValue[0] !== 0 ||
              // @ts-ignore
              newValue[1] !== initPrices["price" + catTarif]
              ? 1
              : 0
          );
          if (!isSmall) {
            apply();
          }
        },
        [apply, catTarif, initPrices, isSmall, setNbSelected]
      );

      React.useEffect(() => {
        setNbSelected(
          value[0] !== 0 ||
            // @ts-ignore
            value[1] !== initPrices["price" + catTarif]
            ? 1
            : 0
        );
        filterInited(index);
      }, []); // eslint-disable-line react-hooks/exhaustive-deps

      useImperativeHandle(ref, () => ({
        getValue() {
          const result: any = {};
          if (value[0] !== 0) {
            result.fromPrice = value[0];
          }
          // @ts-ignore
          if (value[1] !== initPrices["price" + catTarif]) {
            result.toPrice = value[1];
          }
          return result;
        },
      }));

      return (
        <Box sx={{ paddingX: 3, paddingTop: 4 }}>
          <Slider
            getAriaLabel={() => t("word.price")}
            valueLabelFormat={(value, index) =>
              priceFormat(value, i18n.language, "EUR", "symbol", true)
            }
            value={value}
            min={0}
            // @ts-ignore
            max={initPrices["price" + catTarif]}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            valueLabelDisplay="on"
          />
        </Box>
      );
    }
  )
);

export default PriceFilterComponent;
