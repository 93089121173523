import { IResizeImageOptions } from "../interfaces/ImageResizeInterface";

export const dataURItoBlob = (dataURI: string) => {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
      ? atob(dataURI.split(",")[1])
      : unescape(dataURI.split(",")[1]);
  const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};

export const resizeImage = (settings: IResizeImageOptions): Promise<Blob> => {
  const file = settings.file;
  const maxSize = settings.maxSize || 1024;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement("canvas");

  const resize = (): Blob => {
    let width = image.width;
    let height = image.height;

    // Set canvas dimensions to be square (maxSize x maxSize)
    canvas.width = maxSize;
    canvas.height = maxSize;

    // Calculate new dimensions to fit the image within maxSize, maintaining aspect ratio
    let scaledWidth, scaledHeight;

    if (width > height) {
      // Landscape orientation: scale width to maxSize
      scaledWidth = maxSize;
      scaledHeight = (height * maxSize) / width;
    } else {
      // Portrait orientation: scale height to maxSize
      scaledWidth = (width * maxSize) / height;
      scaledHeight = maxSize;
    }

    // Draw the scaled image centered on the square canvas
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, maxSize, maxSize);
      ctx.fillStyle = "rgba(0, 0, 0, 0)"; // Ensure background is transparent
      ctx.fillRect(0, 0, maxSize, maxSize);

      // Center the image on the canvas
      const xOffset = (maxSize - scaledWidth) / 2;
      const yOffset = (maxSize - scaledHeight) / 2;
      ctx.drawImage(image, xOffset, yOffset, scaledWidth, scaledHeight);
    }

    // Convert canvas to PNG to ensure transparency
    const dataUrl = canvas.toDataURL("image/png"); // Force PNG output for transparency
    return dataURItoBlob(dataUrl);
  };

  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error("Not an image"));
      return;
    }

    reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
      image.onload = () => resolve(resize());
      image.src = readerEvent.target?.result as string;
    };
    reader.readAsDataURL(file);
  });
};
