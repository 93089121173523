import * as React from "react";
import { useRef } from "react";
import ContentComponent from "../../content/ContentComponent";
import NewsFormComponent from "./NewsFormComponent";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import NewsListComponent from "./NewsListComponent";

const NewsComponent: React.FC = React.memo(() => {
  const isAdmin = useAppSelector(
    (state: RootState) => state.globalState.isAdmin
  );
  const listRef: any = useRef();

  const onScrollNews = React.useCallback((e: any) => {
    const position = e.target?.getBoundingClientRect();
    if (!position) {
      return;
    }
    const isBottom =
      e.target.offsetHeight + e.target.scrollTop >=
      e.target.scrollHeight - 2000;
    if (isBottom) {
      listRef.current.loadNextPage();
    }
  }, []);

  return (
    <Box sx={{ overflow: "auto", maxHeight: "100%" }} onScroll={onScrollNews}>
      <Box sx={{ marginY: 2 }}>
        <ContentComponent contentName="news" saveLocalStorage={true} />
        <Box sx={{ marginX: 2 }}>
          {isAdmin && (
            <Box sx={{ marginY: 2 }}>
              <NewsFormComponent />
            </Box>
          )}
          <NewsListComponent ref={listRef} />
        </Box>
      </Box>
    </Box>
  );
});

export default NewsComponent;
