import React from "react";
import {
  ConfigurationGroupInterface,
  ConfigurationInterface,
} from "../../../interfaces/ConfigurationInterface";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  DialogContent,
  DialogProps,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import { set } from "../../../app/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { swapCard } from "../../../helpers/CardFormat";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { CONFIGURATION_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useTranslation } from "react-i18next";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Divider from "@mui/material/Divider";
import ConfigurationValueComponent from "./ConfigurationValueComponent";
import Typography from "@mui/material/Typography";
import { TYPE_CONTENT } from "../../../utils/ConfigurationUtils";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConfigurationFormComponent from "./ConfigurationFormComponent";

const clone = require("clone");

interface State {
  configurationGroup: ConfigurationGroupInterface;
}

const ConfigurationsComponent: React.FC<State> = React.memo(
  ({ configurationGroup }) => {
    const configurationGroups = useAppSelector(
      (state: RootState) => state.globalState.configurationGroups
    );
    const dispatch = useAppDispatch();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const { t } = useTranslation();
    const [configurationForm, setConfigurationForm] = React.useState<
      ConfigurationInterface | undefined
    >(undefined);
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const onDragEnd = React.useCallback(
      async (result: DropResult) => {
        if (
          result.destination === null ||
          result.destination === undefined ||
          !configurationGroups
        ) {
          return;
        }
        const newConfigurationGroups: ConfigurationGroupInterface[] =
          clone(configurationGroups);
        const x = result.destination?.index;
        const y = result.source?.index;
        let thisConfiguration = null;
        if (x !== undefined && y !== undefined) {
          const thisConfigurationGroup:
            | ConfigurationGroupInterface
            | undefined = newConfigurationGroups.find(
            (c) => c.id === configurationGroup.id
          );
          if (!thisConfigurationGroup) {
            return;
          }
          thisConfiguration =
            thisConfigurationGroup.configurations[result.source.index];
          thisConfigurationGroup.configurations = swapCard(
            thisConfigurationGroup.configurations,
            result
          );
        }
        if (!thisConfiguration) {
          return;
        }
        dispatch(set({ configurationGroups: newConfigurationGroups }));
        const response = await requestApi({
          method: PATCH,
          path: CONFIGURATION_URL + "/" + thisConfiguration.id,
          allowError: false,
          token: token,
          body: {
            sort: result.destination.index + 1,
          },
        });
        if (response.statusCode === 200) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.configuration_updated")
          );
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      },
      [configurationGroup.id, configurationGroups, dispatch, t, token]
    );

    const handleDialogOpen = React.useCallback(
      (configuration: ConfigurationInterface) => {
        setConfigurationForm(configuration);
      },
      []
    );

    const handleDialogClose = React.useCallback(() => {
      setConfigurationForm(undefined);
    }, []);

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleDialogClose}
          open={!!configurationForm}
        >
          <DialogContent>
            <ConfigurationFormComponent
              configuration={configurationForm}
              closeDialog={handleDialogClose}
            />
          </DialogContent>
        </Dialog>
        {configurationGroup.configurations && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="configurations">
              {(provided) => (
                <List ref={provided.innerRef}>
                  {configurationGroup.configurations.map(
                    (configuration, indexConfiguration) => (
                      <Draggable
                        key={configuration.id}
                        draggableId={configuration.id.toString()}
                        index={indexConfiguration}
                      >
                        {(provided) => (
                          <>
                            <ListItem
                              disablePadding
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              sx={{ justifyContent: "space-between" }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Tooltip
                                  title={configuration.description ?? " "}
                                  placement="top"
                                >
                                  <IconButton size="large">
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                                <span
                                  style={{
                                    cursor: "move",
                                    marginRight: 1,
                                  }}
                                  {...provided.dragHandleProps}
                                >
                                  <MenuIcon />
                                </span>
                                <Typography
                                  variant="caption"
                                  component="span"
                                  sx={{ color: "gray" }}
                                >
                                  [{configuration.identifier}]
                                </Typography>
                                <Badge
                                  color={
                                    configuration.used ? "success" : "error"
                                  }
                                  badgeContent=" "
                                  variant="dot"
                                >
                                  <ListItemText
                                    primary={configuration.title + ":"}
                                  />
                                </Badge>
                                {configuration.type !== TYPE_CONTENT && (
                                  <ConfigurationValueComponent
                                    configuration={configuration}
                                  />
                                )}
                              </Box>
                              <Box>
                                <IconButton
                                  onClick={() =>
                                    handleDialogOpen(configuration)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Box>
                            </ListItem>
                            {configuration.type === TYPE_CONTENT && (
                              <ConfigurationValueComponent
                                configuration={configuration}
                              />
                            )}
                            {indexConfiguration !==
                              configurationGroup.configurations.length - 1 && (
                              <Divider />
                            )}
                          </>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </>
    );
  }
);

export default ConfigurationsComponent;
