import * as React from "react";
import { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Skeleton, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { grey } from "@mui/material/colors";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { generateTechnicalSheet } from "../../../helpers/FArticleHelper";
import { FournisseurArticleInterface } from "../../../interfaces/FArticleInterface";

interface State {
  arRef: string | undefined;
  constructeurRef: string | undefined;
  edit: boolean;
  setFArticle: Function;
  label?: string;
  sx?: SxProps<Theme>;
  temporaryIcecat?: any;
  fournisseurProduct?: FournisseurArticleInterface;
}

interface FormState {
  constructeurRef: InputInterface;
}

const FArticleRefConstructeurComponent = React.memo(
  React.forwardRef(
    (
      {
        arRef,
        constructeurRef,
        edit,
        setFArticle,
        label,
        sx,
        temporaryIcecat,
        fournisseurProduct,
      }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const getDefaultValues = React.useCallback((): FormState => {
        let technicalSheet = undefined;
        if (temporaryIcecat) {
          const temp: any = {
            ficheTechnique: temporaryIcecat,
          };
          technicalSheet = generateTechnicalSheet(temp, 1);
        }
        return {
          constructeurRef: {
            value:
              technicalSheet?.constructeurRef ??
              fournisseurProduct?.refFabricant ??
              constructeurRef ??
              "",
            error: "",
          },
        };
      }, [constructeurRef, fournisseurProduct?.refFabricant, temporaryIcecat]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          constructeurRef: values.constructeurRef.value,
        };
      }, [values.constructeurRef.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        const thisValues = getValue();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: arRef,
            ...thisValues,
            fArticleProp: {
              ...thisValues,
            },
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [arRef, getValue, setFArticle, t, token]);

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: {
                  ...v[prop],
                  value: event.target.value.toUpperCase(),
                  error: "",
                },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [constructeurRef, fournisseurProduct?.refFabricant, temporaryIcecat]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {thisEdit || edit ? (
            <>
              <TextField
                autoComplete="off"
                error={!!values.constructeurRef.error}
                helperText={t(values.constructeurRef.error ?? "")}
                sx={{ width: "100%", marginTop: 1 }}
                required
                type="text"
                value={values.constructeurRef.value}
                onChange={handleChange("constructeurRef")}
                label={t("word.refConstructor")}
              />
              {!edit && (
                <>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={handleThisEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              <Typography sx={{ width: "100%" }}>
                {label !== undefined ? (
                  label
                ) : (
                  <span style={{ color: grey[500] }}>
                    {t("word.refConstructor") + ": "}
                  </span>
                )}
                {constructeurRef === undefined ? (
                  <Skeleton sx={{ flex: 1 }} variant="text" />
                ) : (
                  <CopyClipboardComponent
                    className="RobotoMono"
                    component="span"
                    text={constructeurRef}
                    sx={sx}
                  />
                )}
                {isAdmin && (
                  <IconButton onClick={handleThisEdit}>
                    <EditIcon />
                  </IconButton>
                )}
              </Typography>
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleRefConstructeurComponent;
