import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getUrlFArticleImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_LOGO, IMAGE_SIZE_SMALL } from "../../../utils/FileUtils";
import HideImageIcon from "@mui/icons-material/HideImage";
import ClearIcon from "@mui/icons-material/Clear";
import {
  CartFArticleInterface,
  CartInterface,
} from "../../../interfaces/CartInterface";
import { priceFormat } from "../../../utils/FormatUtils";
import { getCartFArticleAmount } from "../../../helpers/CartHelper";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";
import { getQuantityDispo } from "../../../helpers/FArticleHelper";
import { LoadingButton } from "@mui/lab";
import { set } from "../../../app/globalSlice";
import { useAppDispatch } from "../../../app/hooks";
import CartFArticleStatusComponent from "./CartFArticleStatusComponent";
import { grey } from "@mui/material/colors";
import FArticleQuantityComponent from "../fArticle/FArticleQuantityComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { useTheme } from "@mui/material";

const clone = require("clone");

interface State {
  cartFArticle: CartFArticleInterface;
  setThisCart: Function;
}

const CartFArticleComponent: React.FC<State> = React.memo(
  ({ cartFArticle, setThisCart }) => {
    const [quantity, setQuantity] = React.useState(cartFArticle.quantity);
    const [amount, setAmount] = React.useState(
      getCartFArticleAmount(cartFArticle, false)
    );
    const theme = useTheme();
    const { i18n } = useTranslation();
    const quantityDispo = getQuantityDispo(cartFArticle.fArticle);
    const dispatch = useAppDispatch();
    const closeCart = React.useCallback(() => {
      dispatch(set({ openCart: false }));
    }, [dispatch]);
    const refQuantity: any = useRef(undefined);

    const removeArticle = React.useCallback(() => {
      setThisCart((thisCart: CartInterface) => {
        const result: CartInterface = clone(thisCart);
        result.cartFArticles = result.cartFArticles.filter(
          (x) => x.id !== cartFArticle.id
        );
        return result;
      });
    }, [cartFArticle.id, setThisCart]);

    const changeQuantity = React.useCallback(
      (newQuantity: number) => {
        setQuantity(newQuantity);
        setThisCart((thisCart: CartInterface) => {
          const result: CartInterface = clone(thisCart);
          const thisCartFArticle: CartFArticleInterface | undefined =
            result.cartFArticles.find(
              (x) => x.fArticle.arRef === cartFArticle.fArticle.arRef
            );
          if (thisCartFArticle) {
            thisCartFArticle.quantity = newQuantity;
          }
          return result;
        });
      },
      [cartFArticle.fArticle.arRef, setThisCart]
    );

    React.useEffect(() => {
      setAmount(getCartFArticleAmount(cartFArticle, false));
    }, [cartFArticle]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 0, display: "flex", alignItems: "center" }}>
          {cartFArticle.fArticle.fArticleProp?.images !== null &&
          cartFArticle.fArticle.fArticleProp?.images.length > 0 ? (
            <Link
              onClick={closeCart}
              style={{
                textDecoration: "none",
                color: "inherit",
                textAlign: "center",
              }}
              to={PRODUCT_PAGE + "/" + cartFArticle.fArticle.fArticleProp?.slug}
              state={cartFArticle.fArticle}
            >
              <img
                src={
                  (process.env.REACT_APP_API_URL ?? "") +
                  getUrlFArticleImage(
                    cartFArticle.fArticle.arRef,
                    cartFArticle.fArticle.fArticleProp?.images[0],
                    IMAGE_SIZE_SMALL
                  )
                }
                style={{ margin: "auto", width: IMAGE_SIZE_SMALL / 2 }}
                alt={
                  "[" +
                  cartFArticle.fArticle.arRef +
                  "] " +
                  cartFArticle.fArticle.arDesign
                }
                loading="lazy"
              />
            </Link>
          ) : (
            <HideImageIcon
              sx={{
                width: IMAGE_SIZE_LOGO,
                height: IMAGE_SIZE_LOGO,
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Link
              onClick={closeCart}
              style={{ textDecoration: "none", color: "inherit" }}
              to={PRODUCT_PAGE + "/" + cartFArticle.fArticle.fArticleProp?.slug}
              state={cartFArticle.fArticle}
            >
              <Typography>{cartFArticle.fArticle.arDesign}</Typography>
            </Link>
          </Box>
          <Box sx={{ marginBottom: 1 }}>
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              sx={{
                color: theme.palette.success.main,
              }}
              text={cartFArticle.fArticle.arRef}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 0 }}>
              <FArticleQuantityComponent
                ref={refQuantity}
                fArticle={cartFArticle.fArticle}
                setQuantity={changeQuantity}
                defaultValue={quantity}
                maxWithCart={false}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              {!cartFArticle.fArticle.fArticleProp?.hasOption &&
                quantity > quantityDispo && (
                  <CartFArticleStatusComponent
                    quantity={quantity}
                    quantityDispo={quantityDispo}
                    fArticle={cartFArticle.fArticle}
                  />
                )}
              {cartFArticle.cartFArticleOptions?.length > 0 && (
                <Box>
                  <ul>
                    {cartFArticle.cartFArticleOptions.map(
                      (cartFArticleOption, indexCartFArticleOption) => (
                        <li
                          style={{
                            color: grey[500],
                            fontSize: 13,
                          }}
                          key={indexCartFArticleOption}
                        >
                          {cartFArticleOption.fArticle.arDesign}
                        </li>
                      )
                    )}
                  </ul>
                </Box>
              )}
              <Box sx={{ textAlign: "right" }}>
                <Typography variant="caption" sx={{ color: grey[500] }}>
                  {quantity} x {priceFormat(amount, i18n.language, "EUR")}
                  {" = "}
                  <Typography component="span" sx={{ textTransform: "bold" }}>
                    {priceFormat(amount * quantity, i18n.language, "EUR")}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ alignItems: "flex-start", flex: 0 }}>
          <LoadingButton
            variant="text"
            color="inherit"
            sx={{
              borderRadius: "50%",
              minWidth: "auto",
              padding: "12px",
            }}
            onClick={removeArticle}
          >
            <ClearIcon />
          </LoadingButton>
        </Box>
      </Box>
    );
  }
);

export default CartFArticleComponent;
