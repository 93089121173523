import * as React from "react";
import { useEffect } from "react";
import {
  FArticleSmallInterface,
  ValueConfigurationFArticleOptionInterface,
} from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { canBuyFArticle } from "../../../helpers/FArticleHelper";
import { LoadingButton } from "@mui/lab";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { requestApi } from "../../../helpers/RequestApi";
import { DELETE, POST } from "../../../utils/MethodUtils";
import { CART_FARTICLE_URL, FAVORIS_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { PostCartFArticleInterface } from "../../../interfaces/CartInterface";
import { set, setCart } from "../../../app/globalSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { FavorisInterface } from "../../../interfaces/FavorisInterface";
import FArticleAddCompareComponent from "./FArticleAddCompareComponent";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  quantity?: number | undefined;
  valueOptions?: ValueConfigurationFArticleOptionInterface[];
  fullWidth?: boolean;
  onlyAddCard?: boolean;
}

const FArticleActionButtonComponent: React.FC<State> = React.memo(
  ({ fArticle, quantity, valueOptions, fullWidth, onlyAddCard }) => {
    const [loading, setLoading] = React.useState(false);
    const [loadingFavoris, setLoadingFavoris] = React.useState(false);
    const [thisFavoris, settThisFavoris] = React.useState<
      FavorisInterface | undefined
    >(undefined);
    const cart = useAppSelector((state: RootState) => state.globalState.cart);
    const favoris = useAppSelector(
      (state: RootState) => state.globalState.favoris
    );
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const addedFavoris = useAppSelector(
      (state: RootState) => state.globalState.addedFavoris
    );
    const dispatch = useAppDispatch();

    const handleAddCart = React.useCallback(
      async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        if (fArticle?.fArticleProp === undefined) {
          setLoading(false);
          return;
        }
        let filterValueOptions: ValueConfigurationFArticleOptionInterface[] =
          [];
        if (valueOptions !== undefined) {
          filterValueOptions = valueOptions.filter(
            (valueOption) =>
              valueOption.configurationFArticleValue.fArticle?.arRef !==
              undefined
          );
        }
        let thisQuantity = quantity;
        if (thisQuantity === undefined || thisQuantity === 0) {
          thisQuantity = 1;
        }
        const body: PostCartFArticleInterface = {
          fArticleId: fArticle.arRef,
          cartId: cart?.id ?? null,
          quantity: thisQuantity,
          ...(filterValueOptions.length > 0 && {
            cartFArticleOptions: filterValueOptions.map((valueOption) => {
              return {
                fArticleId:
                  valueOption.configurationFArticleValue.fArticle.arRef,
              };
            }),
          }),
        };
        const response = await requestApi({
          method: POST,
          path: CART_FARTICLE_URL,
          allowError: true,
          token: token,
          body: body,
        });
        if (response.statusCode === 201) {
          dispatch(setCart(response.content));
          dispatch(set({ openCart: true }));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      },
      [cart?.id, dispatch, fArticle, quantity, t, token, valueOptions]
    );

    const handleFavoris = React.useCallback(
      async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (!user) {
          toastr.info(t("word.info"), t("sentence.favorisConnected"));
          return;
        }
        setLoadingFavoris(true);
        if (!thisFavoris) {
          const response = await requestApi({
            method: POST,
            path: FAVORIS_URL,
            allowError: true,
            token: token,
            body: {
              fArticleId: fArticle?.arRef,
            },
          });
          if (response.statusCode === 200) {
            dispatch(set({ favoris: response.content }));
            if (addedFavoris) {
              dispatch(set({ addedFavoris: false }));
            }
            setTimeout(() => {
              dispatch(set({ addedFavoris: true }));
            });
          } else {
            for (let message of getErrorApi(response.content)) {
              toastr.error(t("word.error"), t(message));
            }
          }
        } else {
          const response = await requestApi({
            method: DELETE,
            path: FAVORIS_URL + "/" + thisFavoris.id,
            allowError: true,
            token: token,
          });
          if (response.statusCode === 200) {
            dispatch(set({ favoris: response.content }));
          } else {
            for (let message of getErrorApi(response.content)) {
              toastr.error(t("word.error"), t(message));
            }
          }
        }
        setLoadingFavoris(false);
      },
      [addedFavoris, dispatch, fArticle?.arRef, t, thisFavoris, token, user]
    );

    useEffect(() => {
      settThisFavoris(favoris?.find((x) => x.fArticleId === fArticle?.arRef));
    }, [fArticle?.arRef, favoris]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <LoadingButton
          fullWidth={fullWidth}
          sx={{ wordBreak: "break-word" }}
          disabled={!canBuyFArticle(fArticle, isAdmin, valueOptions)}
          variant="contained"
          loading={loading}
          onClick={handleAddCart}
        >
          <ShoppingCartIcon />
        </LoadingButton>
        {!onlyAddCard && (
          <>
            <LoadingButton
              variant="text"
              // @ts-ignore
              color="orange"
              sx={{
                borderRadius: "50%",
                minWidth: "auto",
                padding: "12px",
              }}
              loading={loadingFavoris}
              onClick={handleFavoris}
            >
              {thisFavoris ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </LoadingButton>
            <FArticleAddCompareComponent fArticle={fArticle} />
          </>
        )}
      </>
    );
  }
);

export default FArticleActionButtonComponent;
