import * as React from "react";
import { useRef } from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { Button, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { FILTER_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";
import FilterAdminProductFilterValueComponent from "../filter/admin/form/productFilterForm/FilterAdminProductFilterValueComponent";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import { getNeedToSendRequest } from "../../../helpers/FilterHelper";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { POST_PUT_FILTER_VALUE_ARTICLE_URL } from "../../../utils/UrlsUtils";
import { set } from "../../../app/globalSlice";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface State {
  fArticle: FArticleInterface | undefined;
  setFArticle: Function;
  setOpenDialogForm?: Function;
  closeDialogFilter?: any;
}

const DialogFilterAdminProductFilterValueComponent: React.FC<State> =
  React.memo(
    ({ fArticle, setFArticle, setOpenDialogForm, closeDialogFilter }) => {
      const { t } = useTranslation();
      const refFormFilter: any = useRef();
      const dispatch = useAppDispatch();
      const [loadingEditFarticle, setLoadingEditFarticle] =
        React.useState(false);
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const newBrowser = useAppSelector(
        (state: RootState) => state.globalState.newBrowser
      );

      const onCancel = React.useCallback(() => {
        if (newBrowser) {
          window.close();
        } else {
          closeDialogFilter();
        }
      }, [closeDialogFilter, newBrowser]);

      const saveFilter = React.useCallback(async () => {
        setLoadingEditFarticle(true);
        const data = refFormFilter.current.getValue();
        const needToSendRequest = getNeedToSendRequest(
          data,
          fArticle,
          undefined
        );
        const fArticleId = fArticle?.arRef;
        if (needToSendRequest) {
          const response = await requestApi({
            method: POST,
            path: POST_PUT_FILTER_VALUE_ARTICLE_URL,
            allowError: false,
            token: token,
            body: {
              fArticleId: fArticleId,
              filters: data,
            },
          });
          if (response.statusCode === 200) {
            toastr.success(
              t("word.success"),
              t("sentence.notification.filter_values_updated")
            );
            setFArticle(response.content.fArticle);
            dispatch(
              set({ nbFilterArticle: response.content.nbFilterArticle })
            );
            if (newBrowser) {
              setTimeout(() => {
                window.close();
              }, 500);
            }
          } else if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"));
          } else {
            for (let message of getErrorApi(response.content)) {
              toastr.error(t("word.error"), t(message));
            }
          }
        } else {
          if (newBrowser) {
            window.close();
          }
        }
        setLoadingEditFarticle(false);
        if (setOpenDialogForm) {
          setOpenDialogForm(false);
        }
      }, [
        dispatch,
        fArticle,
        newBrowser,
        setFArticle,
        setOpenDialogForm,
        t,
        token,
      ]);

      return (
        <>
          <DialogContent>
            <Box sx={{ textAlign: "center" }}>
              <Link
                to={FILTER_PAGE + "?tab=2&search=" + fArticle?.arRef}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button>{t("word.filterPage")}</Button>
              </Link>
            </Box>
            {fArticle && fArticle.loaded ? (
              <FilterAdminProductFilterValueComponent
                ref={refFormFilter}
                fArticle={fArticle}
              />
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress />
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <LoadingButton onClick={onCancel} disabled={loadingEditFarticle}>
              {t("word.cancel")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={saveFilter}
              loading={loadingEditFarticle}
            >
              {t("word.save")}
            </LoadingButton>
          </DialogActions>
        </>
      );
    }
  );

export default DialogFilterAdminProductFilterValueComponent;
