import * as React from "react";
import { useRef } from "react";
import { useTheme } from "@mui/material";
import { ExpeditionInterface } from "../../../interfaces/CartInterface";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import ExpeditionFormComponent from "./ExpeditionFormComponent";

interface State {
  expedition: ExpeditionInterface | undefined;
  removeExpedition: Function;
  indexExpeditionForm: number;
}

const ExpeditionComponent: React.FC<State> = React.memo(
  ({ expedition, removeExpedition, indexExpeditionForm }) => {
    const theme = useTheme();
    const formRef = useRef();

    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              color: "white",
              marginTop: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {expedition?.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ExpeditionFormComponent
            expedition={expedition}
            ref={formRef}
            removeExpedition={removeExpedition}
            indexExpeditionForm={indexExpeditionForm}
          />
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default ExpeditionComponent;
