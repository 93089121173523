import * as React from "react";
import { useImperativeHandle } from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Dialog from "@mui/material/Dialog";
import { DialogProps, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { useTranslation } from "react-i18next";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import { UserInterface } from "../../../interfaces/UserInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH, POST } from "../../../utils/MethodUtils";
import { FOURNISSEUR_PROP_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { set } from "../../../app/globalSlice";

const clone = require("clone");

interface State {
  fArticle: FArticleInterface | undefined;
}

interface State2 {
  fournisseur: UserInterface;
}

interface FormState2 {
  linkSearch: InputInterface;
  linkId: InputInterface;
}

export const FArticleFournisseurLinkComponent = React.memo(
  React.forwardRef(({ fournisseur }: State2, ref) => {
    const { t } = useTranslation();
    const [edit, setEdit] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const fournisseurs = useAppSelector(
      (state: RootState) => state.globalState.fournisseurs
    );
    const getDefaultValue = React.useCallback((): FormState2 => {
      return {
        linkSearch: {
          value: fournisseur.fournisseurProp?.linkSearch ?? "",
          error: "",
        },
        linkId: { value: fournisseur.fournisseurProp?.linkId ?? "", error: "" },
      };
    }, [
      fournisseur.fournisseurProp?.linkId,
      fournisseur.fournisseurProp?.linkSearch,
    ]);
    const [values, setValues] = React.useState<FormState2>(getDefaultValue());
    const [loading, setLoading] = React.useState(false);
    const dispatch = useAppDispatch();

    const handleEdit = React.useCallback(() => {
      setEdit((x) => !x);
    }, []);

    const getValue = React.useCallback((): any => {
      return {
        ctNum: fournisseur.userIdentifier,
        linkId: values.linkId.value,
        linkSearch: values.linkSearch.value,
      };
    }, [
      fournisseur.userIdentifier,
      values.linkId.value,
      values.linkSearch.value,
    ]);

    const handleChange = React.useCallback(
      (prop: keyof FormState2) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const save = React.useCallback(async () => {
      setLoading(true);
      let url = FOURNISSEUR_PROP_URL;
      if (fournisseur.fournisseurProp?.id) {
        url += "/" + fournisseur.fournisseurProp?.id;
      }
      const response = await requestApi({
        method: fournisseur.fournisseurProp?.id ? PATCH : POST,
        path: url,
        allowError: true,
        token: token,
        body: {
          userIdentifier: fournisseur.userIdentifier,
          linkId: values.linkId.value,
          linkSearch: values.linkSearch.value,
        },
      });
      if (response.statusCode === 200 || response.statusCode === 201) {
        let thisFournisseurs: UserInterface[] = clone(fournisseurs);
        const index = thisFournisseurs.findIndex(
          (f) => f.userIdentifier === fournisseur.userIdentifier
        );
        if (index >= 0) {
          thisFournisseurs[index].fournisseurProp = response.content;
        }
        dispatch(set({ fournisseurs: thisFournisseurs }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
      setEdit(false);
    }, [
      dispatch,
      fournisseur.fournisseurProp?.id,
      fournisseur.userIdentifier,
      fournisseurs,
      t,
      token,
      values.linkId.value,
      values.linkSearch.value,
    ]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <TableRow>
        <TableCell>
          <Typography>
            {fournisseur.userIdentifier + ": " + fournisseur.ctIntitule}
            {!edit ? (
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <IconButton onClick={handleEdit}>
                  <CancelIcon />
                </IconButton>
                <LoadingButton
                  variant="text"
                  color="inherit"
                  sx={{
                    borderRadius: "50%",
                    minWidth: "auto",
                    padding: "8px",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                  loading={loading}
                  onClick={save}
                >
                  <SaveIcon />
                </LoadingButton>
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell>
          {edit ? (
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.linkSearch.error}
              helperText={t(values.linkSearch.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.linkSearch.value}
              onChange={handleChange("linkSearch")}
              label={t("word.supplierSearchLink")}
            />
          ) : (
            <Typography>{fournisseur.fournisseurProp?.linkSearch}</Typography>
          )}
        </TableCell>
        <TableCell>
          {edit ? (
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.linkId.error}
              helperText={t(values.linkId.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.linkId.value}
              onChange={handleChange("linkId")}
              label={t("word.supplierIdLink")}
            />
          ) : (
            <Typography>{fournisseur.fournisseurProp?.linkId}</Typography>
          )}
        </TableCell>
      </TableRow>
    );
  })
);

export const FArticleFournisseurLinksComponent = React.memo(
  React.forwardRef(({ fArticle }: State, ref) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xl");
    const theme = useTheme();
    const { t } = useTranslation();
    const fournisseurs = useAppSelector(
      (state: RootState) => state.globalState.fournisseurs
    );
    const getSortedFournisseurs = React.useCallback((): UserInterface[] => {
      let thisSortedFournisseurs = clone(fournisseurs);
      (thisSortedFournisseurs ?? []).sort(
        (a: UserInterface, b: UserInterface) => {
          const findA = fArticle?.fArtfourniss?.find((x) =>
            x.ctNum.includes(a.userIdentifier)
          );
          const findB = fArticle?.fArtfourniss?.find((x) =>
            x.ctNum.includes(b.userIdentifier)
          );
          if (findA && !findB) {
            return -1;
          }
          if (!findA && findB) {
            return 1;
          }
          return a.ctIntitule.localeCompare(b.ctIntitule);
        }
      );
      return thisSortedFournisseurs;
    }, [fArticle?.fArtfourniss, fournisseurs]);
    const [sortedFournisseurs, setSortedFournisseurs] = React.useState<
      UserInterface[]
    >(getSortedFournisseurs());
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const handleCloseDialog = React.useCallback(() => {
      setDialogOpen(false);
    }, []);
    const handleOpenDialog = React.useCallback(() => {
      setDialogOpen(true);
    }, []);

    React.useEffect(() => {
      setSortedFournisseurs(getSortedFournisseurs());
    }, [fArticle?.fArtfourniss, fournisseurs]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          fullWidth={true}
          onClose={handleCloseDialog}
          open={dialogOpen}
        >
          <DialogContent dividers={true}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("word.supplier")}</TableCell>
                    <TableCell>{t("word.supplierSearchLink")}</TableCell>
                    <TableCell>{t("word.supplierIdLink")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedFournisseurs?.map((fournisseur, indexFournisseur) => {
                    return (
                      <FArticleFournisseurLinkComponent
                        fournisseur={fournisseur}
                        key={indexFournisseur}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
        <IconButton onClick={handleOpenDialog}>
          <EditIcon />
        </IconButton>
      </>
    );
  })
);
