import React, { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { CAN_SEARCH_SAGE_DO_TYPE } from "../../../../utils/DoceneteteUtils";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { CountDoTypeInterface } from "../ConsoleComptoirComponent";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";

interface State {
  countDoType: CountDoTypeInterface[] | undefined;
  updateSearchParams: Function;
}

const ConsoleComptoirFilterDoTypeComponent = React.memo(
  React.forwardRef(({ countDoType, updateSearchParams }: State, ref) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const getCheckedDoType = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return CAN_SEARCH_SAGE_DO_TYPE.map((doType) => {
        return (
          searchParamsObject["doType[]"] !== undefined &&
          searchParamsObject["doType[]"].includes(doType.toString())
        );
      });
    }, [searchParams]);

    const [checkedDoType, setCheckedDoType] = React.useState<boolean[]>(
      getCheckedDoType()
    );

    const updateChecked = React.useCallback(() => {
      setCheckedDoType(getCheckedDoType());
    }, [getCheckedDoType]);

    const handleChangeDoType = React.useCallback(
      (
        event: React.ChangeEvent<HTMLInputElement>,
        indexFilterValue: number
      ) => {
        setCheckedDoType((x) => {
          x[indexFilterValue] = event.target.checked;
          return [...x];
        });
      },
      []
    );

    useImperativeHandle(ref, () => ({
      getCheckedDoType() {
        return checkedDoType;
      },
    }));

    React.useEffect(() => {
      updateChecked();
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      updateSearchParams();
    }, [checkedDoType]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormGroup sx={{ flexDirection: "row" }}>
        {CAN_SEARCH_SAGE_DO_TYPE.map((doType, indexDoType) => {
          let label = t("word.fdocentete.doType." + doType);
          if (countDoType) {
            const thisCount = countDoType.find(
              (x) => x.DO_Type === doType.toString()
            )?.count;
            if (thisCount) {
              label +=
                " (" +
                Number(thisCount).toLocaleString(
                  undefined // leave undefined to use the visitor's browser
                  // locale or a string like 'en-US' to override it.
                ) +
                ")";
            }
          }
          return (
            <FormControlLabel
              key={indexDoType}
              control={
                <Checkbox
                  checked={checkedDoType[indexDoType]}
                  onChange={(e) => handleChangeDoType(e, indexDoType)}
                />
              }
              label={label}
            />
          );
        })}
      </FormGroup>
    );
  })
);

export default ConsoleComptoirFilterDoTypeComponent;
