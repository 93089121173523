import * as React from "react";
import { useImperativeHandle } from "react";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Skeleton, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { grey } from "@mui/material/colors";
import { generateTechnicalSheet } from "../../../helpers/FArticleHelper";

interface State {
  fArticle: FArticleInterface | undefined;
  edit: boolean;
  setFArticle: Function;
  temporaryIcecat?: any;
}

interface FormState {
  model: InputInterface;
}

const FArticleModelComponent = React.memo(
  React.forwardRef(
    ({ fArticle, edit, setFArticle, temporaryIcecat }: State, ref) => {
      const { t } = useTranslation();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const getDefaultValues = React.useCallback((): FormState => {
        let model = fArticle?.fArticleProp?.model ?? "";
        if (temporaryIcecat) {
          const temp: any = {
            ficheTechnique: temporaryIcecat,
          };
          const technicalSheet = generateTechnicalSheet(temp, 1);
          if (technicalSheet?.model) {
            model = technicalSheet?.model;
          }
        }
        return {
          model: { value: model, error: "" },
        };
      }, [fArticle?.fArticleProp?.model, temporaryIcecat]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          model: values.model.value,
        };
      }, [values.model.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        const thisValues = getValue();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            fArticleProp: {
              ...thisValues,
            },
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: {
                  ...v[prop],
                  value: event.target.value,
                  error: "",
                },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [fArticle?.fArticleProp?.model, temporaryIcecat]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {thisEdit || edit ? (
            <>
              <TextField
                autoComplete="off"
                error={!!values.model.error}
                helperText={t(values.model.error ?? "")}
                sx={{ width: "100%", marginTop: 1 }}
                type="text"
                value={values.model.value}
                onChange={handleChange("model")}
                label={t("word.model")}
              />
              {!edit && (
                <>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={handleThisEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <>
              {(isAdmin ||
                (fArticle?.fArticleProp?.model &&
                  fArticle.fArticleProp?.model.trim() !== "")) && (
                <>
                  <Typography sx={{ width: "100%" }}>
                    <span style={{ color: grey[500] }}>
                      {t("word.model") + ": "}
                    </span>
                    {fArticle?.fArticleProp === undefined ? (
                      <Skeleton sx={{ flex: 1 }} variant="text" />
                    ) : (
                      fArticle?.fArticleProp?.model ?? ""
                    )}
                    {isAdmin && (
                      <IconButton onClick={handleThisEdit}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </Typography>
                </>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleModelComponent;
