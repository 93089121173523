import * as React from "react";
import { TextField } from "@mui/material";
import { InputInterface } from "../../../interfaces/InputInterface";
import ContentComponent from "../../content/ContentComponent";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FISCAL_CODES_MULTIPLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Box from "@mui/material/Box";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useTheme } from "@mui/material/styles";

interface FormState {
  url: InputInterface;
}

const ImportFiscalCodeComponent: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const [values, setValues] = React.useState<FormState>({
    url: {
      value:
        "https://eur-lex.europa.eu/legal-content/FR/TXT/HTML/?uri=CELEX:32022R1998",
      error: "",
    },
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const theme = useTheme();

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const save = React.useCallback(async () => {
    setLoading(true);
    const response = await requestApi({
      method: POST,
      path: FISCAL_CODES_MULTIPLE_URL,
      allowError: true,
      token: token,
      body: {
        url: values.url.value,
      },
    });
    if (response.statusCode === 200) {
      toastr.info(t("word.info"), t("sentence.fiscalCodeAreUpdating"));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [t, token, values.url.value]);

  return (
    <>
      <Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              color: "white",
              marginTop: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("word.updateFiscalCodes")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContentComponent
            contentName="importCodeFiscal"
            saveLocalStorage={true}
          />
          <Box sx={{ display: "flex" }}>
            <TextField
              fullWidth={true}
              autoComplete="off"
              error={!!values.url.error}
              helperText={t(values.url.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="text"
              value={values.url.value}
              onChange={handleChange("url")}
              label={t("field.url")}
            />
            <LoadingButton variant="contained" loading={loading} onClick={save}>
              {t("word.save")}
            </LoadingButton>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
});

export default ImportFiscalCodeComponent;
