import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import KeywordsFilterComponent from "../filter/KeywordsFilterComponent";

interface State {
  apply: Function;
}

const FilterKeywordsFDocenteteComponent = React.memo(
  forwardRef(({ apply }: State, ref) => {
    const theme = useTheme();
    const keywordRef: any = useRef();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const getNbSelected = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      if (
        searchParamsObject.hasOwnProperty("keywords") &&
        searchParamsObject.keywords !== ""
      ) {
        return 1;
      }
      return 0;
    }, [searchParams]);
    const [nbSelected, setNbSelected] = React.useState<number>(getNbSelected());

    const onChangeSearchParam = React.useCallback(() => {
      setNbSelected(getNbSelected());
    }, [getNbSelected]);

    React.useEffect(() => {
      onChangeSearchParam();
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          ...keywordRef.current?.getValue(),
        };
      },
    }));

    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              color: "white",
              marginTop: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("word.keywords")}
            {nbSelected > 0 && (
              <Typography
                component="span"
                className="badge"
                sx={{ marginLeft: 1 }}
              >
                {nbSelected}
              </Typography>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <KeywordsFilterComponent
            ref={keywordRef}
            apply={apply}
            setNbSelected={setNbSelected}
          />
        </AccordionDetails>
      </Accordion>
    );
  })
);

export default FilterKeywordsFDocenteteComponent;
