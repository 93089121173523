import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { SavCommentInterface } from "../../../interfaces/SavInterface";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { DialogProps, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import CkEditorComponent from "../../../components/content/CkEditorComponent";
import { requestApi } from "../../../helpers/RequestApi";
import { DELETE, PATCH } from "../../../utils/MethodUtils";
import { SAV_COMMENT_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import getErrorApi from "../../../helpers/GetErrorApi";

interface State {
  savComment: SavCommentInterface;
  asAdmin: boolean;
  deleteComment: Function;
}

const SavFormCommentsComponent: React.FC<State> = React.memo(
  ({ savComment, asAdmin, deleteComment }) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const { t, i18n } = useTranslation();
    const [isMe] = React.useState(
      !savComment.admin && savComment.userIdentifier === user?.userIdentifier
    );
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const ckEditorRef: any = useRef();
    const [edit, setEdit] = React.useState(false);
    const [thisSav, setThisSav] =
      React.useState<SavCommentInterface>(savComment);
    const [openDialogForm, setOpenDialogForm] = React.useState(false);

    const handleOpen = React.useCallback(() => {
      setOpenDialogForm(true);
    }, []);

    const handleClose = React.useCallback(() => {
      if (loadingDelete) {
        return;
      }
      setOpenDialogForm(false);
    }, [loadingDelete]);

    const editAction = React.useCallback(() => {
      setEdit((x) => !x);
    }, []);

    const deleteSavComment = React.useCallback(async () => {
      setLoadingDelete(true);
      const response = await requestApi({
        method: DELETE,
        path: SAV_COMMENT_URL + "/" + savComment.id,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 204) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.sav_comment_deleted")
        );
        deleteComment(savComment.id);
        setOpenDialogForm(false);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingDelete(false);
    }, [deleteComment, savComment.id, t, token]);

    const save = React.useCallback(async () => {
      setLoadingSave(true);
      const data = ckEditorRef.current.getValue();
      if (data === "") {
        setEdit(false);
        setLoadingSave(false);
        return;
      }
      const response = await requestApi({
        method: PATCH,
        path: SAV_COMMENT_URL + "/" + thisSav.id,
        allowError: true,
        token: token,
        body: {
          text: data,
        },
      });
      if (response.statusCode === 200) {
        setEdit(false);
        setThisSav(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setLoadingSave(false);
    }, [thisSav.id, token, t]);

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleClose}
          open={openDialogForm}
        >
          <DialogTitle>{t("word.deleteSavComment.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("word.deleteSavComment.question")}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button onClick={handleClose}>{t("word.no")}</Button>
            <LoadingButton
              loading={loadingDelete}
              variant="contained"
              onClick={deleteSavComment}
            >
              {t("word.yes")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Card
          variant="outlined"
          sx={{
            marginBottom: 2,
            maxWidth: "90%",
            ...(((asAdmin && savComment.admin) || (!asAdmin && isMe)) && {
              marginRight: 0,
              marginLeft: "auto",
              backgroundColor: "rgba(96, 92, 168, 0.1)",
            }),
          }}
        >
          <CardContent>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              {asAdmin
                ? savComment.admin
                  ? "NOVE"
                  : thisSav.userIdentifier
                : isMe
                ? t("word.you")
                : "NOVE"}
              <span
                style={{
                  color: "red",
                }}
              >
                {savComment.private && " [" + t("word.private") + "]"}
              </span>
            </Typography>
            {edit ? (
              <CkEditorComponent ref={ckEditorRef} text={thisSav.text} />
            ) : (
              <div
                className="ck-content"
                dangerouslySetInnerHTML={{ __html: thisSav.text }}
              />
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {asAdmin && isAdmin && savComment.admin && (
                  <>
                    {!edit && (
                      <LoadingButton
                        variant="contained"
                        onClick={handleOpen}
                        sx={{ marginRight: 2 }}
                        color="error"
                      >
                        {t("word.delete")}
                      </LoadingButton>
                    )}
                    <LoadingButton
                      variant="contained"
                      onClick={editAction}
                      sx={{ marginRight: 2 }}
                    >
                      {t(edit ? "word.cancel" : "word.edit.word")}
                    </LoadingButton>
                  </>
                )}
                {edit && (
                  <LoadingButton
                    loading={loadingSave}
                    variant="contained"
                    onClick={save}
                    sx={{ marginRight: 2 }}
                  >
                    {t("word.save")}
                  </LoadingButton>
                )}
              </Box>
              <Typography variant="caption">
                {new Date(thisSav.created).toLocaleString(i18n.language, {
                  dateStyle: "long",
                  timeStyle: "medium",
                  timeZone: "America/Cuiaba",
                })}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </>
    );
  }
);

export default SavFormCommentsComponent;
