import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./globalSlice";
import { reducer as toastrReducer } from "react-redux-toastr";

export const store = configureStore({
  reducer: {
    globalState: globalSlice,
    toastr: toastrReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
