import React from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import NotFoundComponent from "../components/common/NotFoundComponent";
import {
  ACCOUNTING_SITUATION_PAGE,
  CATEGORY_PAGE,
  HOME_PAGE,
  ORDERS_PAGE,
  PRODUCT_PAGE,
  SAV_PAGE,
} from "../utils/RouteUtils";
import { useNavigate } from "react-router-dom";

const NotFoundScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const categories = useAppSelector(
    (state: RootState) => state.globalState.categories
  );
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const navigate = useNavigate();

  const load = React.useCallback(
    (force: boolean = false) => {
      setInit(true);
      dispatch(set({ refreshPage: false }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.endsWith(".html")) {
      const arr = pathname.split("_");
      if (arr.length === 2) {
        if (arr[1].startsWith("p-")) {
          const arRef = arr[1].replace("p-", "").replace(".html", "");
          navigate(PRODUCT_PAGE + "/" + arRef);
          return;
        } else if (categories && /c\d+.html/.test(arr[1])) {
          const oldId = arr[1].substring(1).replace(".html", "");
          for (const parentCategory of categories) {
            if (parentCategory.children) {
              for (const childCategory of parentCategory.children) {
                if (
                  childCategory.oldId &&
                  ((parentCategory.oldId &&
                    parentCategory.oldId.toString() === oldId) ||
                    childCategory.oldId.toString() === oldId)
                ) {
                  navigate(CATEGORY_PAGE + "/" + childCategory.slug);
                  return;
                }
              }
            }
          }
        }
      } else if (arr.length === 3) {
        switch (arr[2]) {
          case ".html":
            navigate(HOME_PAGE);
            return;
          case "catalogue.html":
            navigate(CATEGORY_PAGE);
            return;
          case "compte.html":
            navigate(ORDERS_PAGE);
            return;
          case "sav.html":
            navigate(SAV_PAGE);
            return;
          default:
            break;
        }
      } else if (arr.length === 4) {
        if (arr[2] === "situation" && arr[3] === "comptable.html") {
          navigate(ACCOUNTING_SITUATION_PAGE);
          return;
        }
      }
    }
    document.title = "404";
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <NotFoundComponent />
    </Layout>
  );
});

export default NotFoundScreen;
