import { createTheme } from "@mui/material";

// https://mui.com/material-ui/customization/theming/
const theme = createTheme({
  palette: {
    primary: {
      main: "#605ca8",
      light: "#605CA880",
    },
    warning: {
      main: "#f0ad4e",
      contrastText: "#ffffff",
    },
    info: {
      main: "#5bc0de",
      contrastText: "#ffffff",
    },
    success: {
      main: "#5cb85c",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#d3d3d3",
      contrastText: "#000000",
    },
    // @ts-ignore
    orange: {
      main: "#eb6020",
      contrastText: "#ffffff",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default theme;
