import * as React from "react";
import { FC } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { AccordionDetails, CircularProgress } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { DraggableProvided } from "react-beautiful-dnd";
import {
  getCardsCategory,
  getPathFromDroppableId,
  Item,
} from "../../../helpers/CardFormat";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { CATEGORY_CHILDREN_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";

export interface CardProps {
  text: string | React.ReactNode;
  identifier: string;
  path: string;
  depth: number;
  forceHasChildren?: boolean;
  allCards: Item[];
  children?: Item[];
  parentCategory?: CategoryInterface;
  provided: DraggableProvided;
  renderCards: Function;
  setCards: Function;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const CartDndCategoryComponent: FC<CardProps> = ({
  text,
  identifier,
  path,
  depth,
  forceHasChildren,
  allCards,
  children,
  provided,
  renderCards,
  setCards,
  parentCategory,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [hasChildren] = React.useState<boolean>(
    (Array.isArray(children) && children.length > 0) ||
      (forceHasChildren ?? false)
  );
  const [thisChildren, setThisChildren] = React.useState<Item[] | undefined>(
    children
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const { t } = useTranslation();

  const updateChildren = React.useCallback(async () => {
    if (!expanded || (thisChildren && thisChildren.length > 0)) {
      return;
    }
    const response = await requestApi({
      method: GET,
      path: CATEGORY_CHILDREN_URL.replace("{id}", identifier),
      allowError: true,
      token: token,
    });
    if (response.statusCode === 200) {
      const arrayPath = getPathFromDroppableId(path);
      const newCards = [...allCards];
      let tempCards = newCards;
      // @ts-ignore
      for (const [index, cardIndex] of arrayPath.entries()) {
        if (index < arrayPath.length - 1) {
          tempCards = tempCards[cardIndex].children;
        } else {
          // @ts-ignore
          tempCards = tempCards[cardIndex];
        }
      }
      // @ts-ignore
      tempCards.children = getCardsCategory(response.content);
      setCards(newCards);
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
  }, [allCards, expanded, identifier, path, setCards, t, thisChildren, token]);

  // todo on open accordion if has no child then load child
  const changeAccordion = React.useCallback(() => {
    setExpanded((x) => !x);
  }, []);

  React.useEffect(() => {
    updateChildren();
  }, [expanded, thisChildren?.map((c) => c.id).toString()]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setThisChildren(children);
  }, [children]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Accordion expanded={expanded} onChange={changeAccordion}>
      <AccordionSummary
        sx={{ ...(!hasChildren && { cursor: "default!important" }) }}
        expandIcon={
          <>
            {hasChildren && (
              <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
            )}
          </>
        }
        aria-controls={identifier + "-content"}
        id={identifier + "-header"}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <span
            style={{ display: "flex", cursor: "move" }}
            {...provided.dragHandleProps}
          >
            <MenuIcon />
          </span>
          {text}
        </Box>
      </AccordionSummary>
      {hasChildren && expanded && (
        <AccordionDetails>
          {thisChildren && thisChildren.length > 0 ? (
            renderCards(thisChildren, depth + 1, path, parentCategory)
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
