import * as React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { CATEGORY_PAGE } from "../../../utils/RouteUtils";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";
import { useMediaQuery, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import MenuBookIcon from "@mui/icons-material/MenuBook";

interface State {
  category: CategoryInterface | undefined;
}

const CategoryBreadcrumbComponent: React.FC<State> = React.memo(
  ({ category }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));

    return (
      <>
        <Breadcrumbs className="breadcrumbs breadcrumbs-nowrap">
          <Link
            to={CATEGORY_PAGE}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuBookIcon />
          </Link>
          {category !== undefined && (
            <Link
              to={CATEGORY_PAGE + "/" + category.slug}
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "flex",
                alignItems: "center",
              }}
            >
              {category.name}
            </Link>
          )}
        </Breadcrumbs>
        {isSmall && <Divider sx={{ marginY: 2 }} />}
      </>
    );
  }
);

export default CategoryBreadcrumbComponent;
