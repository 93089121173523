import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { green, grey, yellow } from "@mui/material/colors";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import { labelArrival } from "../../../helpers/FArticleHelper";

interface State {
  quantity: number;
  quantityDispo: number;
  fArticle: FArticleSmallInterface;
}

const CartFArticleStatusComponent: React.FC<State> = React.memo(
  ({ quantity, quantityDispo, fArticle }) => {
    const { t } = useTranslation();

    return (
      <Box>
        {quantityDispo > 0 && (
          <Typography
            sx={{
              color: grey[500],
              fontSize: 15,
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircleIcon
              sx={{
                width: "30px",
                color: green[500],
              }}
            />
            <Typography component="span" sx={{ fontWeight: "bold" }}>
              {quantityDispo +
                " " +
                t(
                  "sentence.farticle.canBuyNow." +
                    (quantityDispo === 1 ? "single" : "multiple")
                )}
            </Typography>
          </Typography>
        )}
        <Typography
          sx={{
            color: grey[500],
            fontSize: 15,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircleIcon
            sx={{
              width: "30px",
              color: yellow[500],
            }}
          />
          <Typography component="span" sx={{ fontWeight: "bold" }}>
            {quantity -
              quantityDispo +
              " " +
              t(
                "sentence.farticle.canBuyNotNow." +
                  (quantity - quantityDispo === 1 ? "single" : "multiple")
              )}
          </Typography>
        </Typography>
        {labelArrival(fArticle).map((label, indexLabel) => (
          <Typography
            key={indexLabel}
            sx={{
              paddingInline: 1,
              wordBreak: "break-word",
              color: grey[500],
              fontSize: 15,
            }}
          >
            {label}
          </Typography>
        ))}
      </Box>
    );
  }
);

export default CartFArticleStatusComponent;
