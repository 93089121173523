export const POST_ORDER = "A";
export const FDOCENETE = "B";
export const ACCOUNTING_SITUATION = "C";
export const CREDIT = "D";

export const ALL_PAYMENT_TYPES = [
  POST_ORDER,
  FDOCENETE,
  ACCOUNTING_SITUATION,
  CREDIT,
];
