import * as React from "react";
import { useImperativeHandle, useRef } from "react";
import Typography from "@mui/material/Typography";
import { Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
// @ts-ignore
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
import { urltoFile } from "../../../../helpers/FileHelper";
import { InputInterface } from "../../../../interfaces/InputInterface";
import { LoadingButton } from "@mui/lab";
import sagCtNumValidator from "../../../../helpers/validator/SagCtNumValidator";
import StepperAcompteOrderComponent from "./StepperAcompteOrderComponent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanelComponent from "../../TabPanelComponent";
import { ArrayPostOrderInterface } from "../../../../interfaces/OrderInterface";
// https://github.com/wojtekmaj/react-pdf/issues/680
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Input = styled("input")({
  display: "none",
});

interface State {
  handleNext: any;
  handleBack: any;
  defaultBase64?: string;
  setDefaultBase64?: Function;
  setFile?: Function;
  setReference?: any;
  arrayPostOrder?: ArrayPostOrderInterface;
  setArrayPostOrder?: Function;
  totalOrder?: number;
  setAcompteToPayGlobal?: Function;
}

interface State2 {
  onChange: any;
  inputFileRef: any;
  base64: any;
  onDocumentLoadSuccess: any;
  numPages: number | null;
  referenceRef: any;
  values: any;
  handleChange: any;
  thisHandleNext: any;
  loading: boolean;
  handleBack: any;
}

interface FormState {
  reference: InputInterface;
}

const StepperFDocenteteBonCommandeFileComponent = React.memo(
  React.forwardRef(
    (
      {
        onChange,
        inputFileRef,
        base64,
        onDocumentLoadSuccess,
        numPages,
        referenceRef,
        values,
        handleChange,
        thisHandleNext,
        loading,
        handleBack,
      }: State2,
      ref
    ) => {
      const { t } = useTranslation();

      return (
        <>
          <Typography variant="caption">
            {t("sentence.provideBonDeCommande")}:
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ justifyContent: "center" }}
          >
            <label>
              <Input
                accept="application/pdf"
                type="file"
                onChange={onChange}
                ref={inputFileRef}
              />
              <Button
                variant="contained"
                sx={{ marginBottom: 1 }}
                component="span"
              >
                {base64 ? t("word.change") : <AddIcon />}
              </Button>
            </label>
          </Stack>
          {base64 && (
            <>
              <Typography color="red">
                {t("sentence.cannotChangeBonDeCommande")}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ overflow: "auto", border: "dashed 1px grey" }}>
                  <Document file={base64} onLoadSuccess={onDocumentLoadSuccess}>
                    {numPages &&
                      Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ))}
                  </Document>
                </Box>
              </Box>
              <TextField
                ref={referenceRef}
                fullWidth={true}
                autoComplete="off"
                error={!!values.reference.error}
                helperText={t(values.reference.error ?? "")}
                sx={{ width: "100%", marginTop: 1 }}
                required
                type="text"
                value={values.reference.value}
                onChange={handleChange("reference")}
                label={t("field.reference.label")}
                placeholder={t("field.reference.placeholder")}
              />
              <Typography color="red" variant="h6">
                {t("sentence.checkAmountBdc")}
              </Typography>
            </>
          )}
          <Box>
            <LoadingButton
              variant="contained"
              onClick={thisHandleNext}
              sx={{ mt: 1, mr: 1 }}
              disabled={!numPages}
              loading={loading}
            >
              {t("word.next")}
            </LoadingButton>
            <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
              {t("word.back")}
            </Button>
          </Box>
        </>
      );
    }
  )
);

const StepperFDocenteteBonCommandeComponent = React.memo(
  React.forwardRef(
    (
      {
        handleNext,
        handleBack,
        defaultBase64,
        setDefaultBase64,
        setFile,
        setReference,
        arrayPostOrder,
        setArrayPostOrder,
        totalOrder,
        setAcompteToPayGlobal,
      }: State,
      ref
    ) => {
      const getDefaultValues = React.useCallback((): FormState => {
        return {
          reference: {
            value: "",
            error: "",
          },
        };
      }, []);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const inputFileRef: any = useRef();
      const [base64, setBase64] = React.useState(defaultBase64);
      const [numPages, setNumPages] = React.useState(null);
      const [tabValue, setTabValue] = React.useState(0);
      const [loading, setLoading] = React.useState(false);
      const { t } = useTranslation();
      const referenceRef: any = useRef();

      const onChange = React.useCallback((e: any) => {
        e.preventDefault();
        setNumPages(null);
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setBase64(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
      }, []);

      const onDocumentLoadSuccess = React.useCallback(
        ({ numPages: nextNumPages }: any) => {
          setNumPages(nextNumPages);
          setTimeout(() => {
            referenceRef.current.scrollIntoView({ behavior: "smooth" });
          }, 200);
        },
        []
      );

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      const thisHandleNext = React.useCallback(() => {
        const referenceError = sagCtNumValidator(values.reference.value);
        if (referenceError) {
          const newValue: FormState = { ...values };
          newValue.reference.error = referenceError;
          setValues(newValue);
          return;
        }
        if (setReference) {
          setReference(values.reference.value.substring(0, 17));
        }
        handleNext(values.reference.value.substring(0, 17));
      }, [handleNext, setReference, values]);

      const getFile = React.useCallback(async () => {
        if (!base64) {
          return undefined;
        }
        const file = await urltoFile(
          base64,
          "bonCommande.pdf",
          "application/pdf"
        );
        if (!file) {
          return undefined;
        }
        return file;
      }, [base64]);

      const handleChangeTab = React.useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
          setTabValue(newValue);
        },
        []
      );

      useImperativeHandle(ref, () => ({
        setLoading(v: boolean) {
          setLoading(v);
        },
      }));

      React.useEffect(() => {
        if (setDefaultBase64) {
          setDefaultBase64(base64);
        }
        if (setFile) {
          getFile().then((file) => {
            setFile(file);
          });
        }
      }, [base64]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {totalOrder !== undefined &&
          setArrayPostOrder &&
          arrayPostOrder &&
          setAcompteToPayGlobal ? (
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleChangeTab}
                  scrollButtons="auto"
                  variant={"standard"}
                >
                  <Tab label={t("word.bonCommande.label")} />
                  <Tab label={t("word.pay")} />
                </Tabs>
              </Box>
              <TabPanelComponent
                sx={{ marginTop: 1 }}
                value={tabValue}
                index={0}
              >
                <StepperFDocenteteBonCommandeFileComponent
                  onChange={onChange}
                  inputFileRef={inputFileRef}
                  base64={base64}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  numPages={numPages}
                  referenceRef={referenceRef}
                  values={values}
                  handleChange={handleChange}
                  thisHandleNext={thisHandleNext}
                  loading={loading}
                  handleBack={handleBack}
                />
              </TabPanelComponent>
              <TabPanelComponent
                sx={{ marginTop: 1 }}
                value={tabValue}
                index={1}
              >
                <StepperAcompteOrderComponent
                  acompteOrder={totalOrder}
                  totalOrder={totalOrder}
                  arrayPostOrder={arrayPostOrder}
                  setArrayPostOrder={setArrayPostOrder}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  setAcompteToPayGlobal={setAcompteToPayGlobal}
                />
              </TabPanelComponent>
            </>
          ) : (
            <StepperFDocenteteBonCommandeFileComponent
              onChange={onChange}
              inputFileRef={inputFileRef}
              base64={base64}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              numPages={numPages}
              referenceRef={referenceRef}
              values={values}
              handleChange={handleChange}
              thisHandleNext={thisHandleNext}
              loading={loading}
              handleBack={handleBack}
            />
          )}
        </>
      );
    }
  )
);
export default StepperFDocenteteBonCommandeComponent;
