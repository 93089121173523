import React from "react";
import { TagInterface } from "../../../interfaces/TagInterface";
import { useTranslation } from "react-i18next";
import DialogContentText from "@mui/material/DialogContentText";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import { DialogContent } from "@mui/material";
import { requestApi } from "../../../helpers/RequestApi";
import { DELETE } from "../../../utils/MethodUtils";
import { TAG_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";

interface State {
  tag: TagInterface | null | undefined;
  handleClose: any;
}

const TagDeleteComponent: React.FC<State> = React.memo(
  ({ tag, handleClose }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();

    const confirm = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: DELETE,
        path: TAG_URL + "/" + tag?.id,
        allowError: false,
        token: token,
      });
      if (response.statusCode === 204) {
        dispatch(set({ refreshPage: true }));
        handleClose();
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [dispatch, handleClose, t, tag?.id, token]);

    return (
      <>
        <DialogContent>
          <DialogContentText>{t("word.deleteTag.question")}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <LoadingButton disabled={loading} onClick={handleClose}>
            {t("word.no")}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={confirm}
          >
            {t("word.yes")}
          </LoadingButton>
        </DialogActions>
      </>
    );
  }
);

export default TagDeleteComponent;
