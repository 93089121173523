import { RootState, store } from "../app/store";

export const submitFormPayment = (form: string): boolean => {
  console.log(form);
  const state: RootState = store.getState();
  if (!state.globalState.paymentAllow) {
    alert(
      "Nous rencontrons des problèmes avec le paiement sur le nouveau site, veuillez vous rendre sur l'ancien site pour procéder au paiement."
    );
    return false;
  }
  document.body.innerHTML += form;
  // @ts-ignore
  document.getElementById("form-payment")?.submit();
  return true;
};

export const format2Decimal = (value: string | number | undefined): number => {
  return Math.round(Number(value ?? 0) * 100) / 100;
};
