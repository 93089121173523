import React from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set, setCart } from "../app/globalSlice";
import { requestApi } from "../helpers/RequestApi";
import { GET } from "../utils/MethodUtils";
import { CART_URL } from "../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import OrderComponent from "../components/common/order/OrderComponent";
import { getTitle } from "../helpers/SearchParamHelper";

const OrderScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const cart = useAppSelector((state: RootState) => state.globalState.cart);
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const { t } = useTranslation();

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (cart !== undefined && !force) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const response = await requestApi({
        method: GET,
        path: CART_URL + "/" + cart?.id,
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        dispatch(setCart(response.content));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [cart, dispatch, t, token]
  );

  React.useEffect(() => {
    document.title = getTitle("order");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Container sx={{ marginY: 2 }}>
        <OrderComponent />
      </Container>
    </Layout>
  );
});

export default OrderScreen;
