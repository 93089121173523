import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { toastr } from "react-redux-toastr";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { TAG_URL } from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CONDITION_AND, CONDITIONS_FILTER } from "../../../utils/FilterUtils";
import MenuItem from "@mui/material/MenuItem";

interface State {
  name: string;
  data: any;
}

interface FormState {
  entities: InputInterface;
  condition: InputInterface;
}

const SearchEntityAutocompleteComponent = React.memo(
  React.forwardRef(({ name, data }: State, ref) => {
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultValues = React.useCallback(async (): Promise<FormState> => {
      if (data && data.hasOwnProperty(name) && data[name].values.length > 0) {
        const response = await requestApi({
          method: GET,
          path:
            TAG_URL +
            objectToQuery({
              "id[]": data[name].values,
            }),
          allowError: false,
          paginate: false,
          token: token,
        });
        if (response.statusCode === 200) {
          return {
            entities: {
              value: response.content,
              error: "",
            },
            condition: {
              value: data[name].condition,
              error: "",
            },
          };
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }
      return {
        entities: {
          value: [],
          error: "",
        },
        condition: {
          value: CONDITION_AND,
          error: "",
        },
      };
    }, [data, name, t, token]);
    const getCleanName = React.useCallback((): string => {
      return name.replaceAll("[]", "");
    }, [name]);
    const [cleanName, setCleanName] = React.useState<string>(getCleanName());
    const [values, setValues] = React.useState<FormState | undefined>(
      undefined
    );
    const [entities, setEntities] = React.useState<any[]>([]);
    const [searchEntity, setSearchEntity] = React.useState<string>("");
    const [isTyping, setIsTyping] = React.useState<boolean>(false);
    const [isSearching, setIsSearching] = React.useState<boolean>(false);

    const handleSearchFilterValues = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: string) => {
        setSearchEntity(value);
        setEntities([]);
        setIsTyping(true);
      },
      []
    );

    const searchEntities = React.useCallback(async () => {
      setIsTyping(false);
      setIsSearching(true);
      const search = searchEntity.trim();
      let url = TAG_URL;
      let object: any = {};
      if (search) {
        object.text = search;
      }
      url += objectToQuery(object);
      const response = await requestApi({
        method: GET,
        path: url,
        allowError: false,
        paginate: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setEntities(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setIsSearching(false);
    }, [searchEntity, t, token]);

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          if (v) {
            return {
              ...v,
              [prop]: {
                ...v[prop],
                value: event.target.value as string,
                error: "",
              },
            };
          }
          return v;
        });
      },
      []
    );

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            if (v) {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            }
            return v;
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      if (values) {
        return {
          [name]: {
            values: values.entities.value.map((value: any) => value.id),
            condition: values.condition.value,
          },
        };
      }
      return [];
    }, [name, values]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      getDefaultValues().then((v) => {
        setValues(v);
      });
    }, [name, data?.hasOwnProperty(name) ? data[name] : undefined]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setCleanName(getCleanName());
    }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchEntities();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [searchEntity]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={10}>
          <Autocomplete
            multiple={true}
            className="autocomplete-height-adjust"
            loadingText={t("word.loading") + "..."}
            inputValue={searchEntity}
            onInputChange={handleSearchFilterValues}
            getOptionLabel={(option: any) => {
              return "[" + option.id + "] " + option.text;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} style={{ display: "block" }}>
                  {"[" + option.id + "] " + option.text}
                </li>
              );
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            noOptionsText={
              isSearching ? (
                <Box sx={{ textAlign: "center" }}>
                  <CircularProgress />
                </Box>
              ) : searchEntity !== "" && !isTyping ? (
                t("sentence.no_option")
              ) : (
                t("word.searchEntity." + cleanName + ".search")
              )
            }
            options={entities}
            onChange={handleChangeAutocomplete("entities")}
            value={values?.entities.value ?? []}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                error={!!values?.entities.error ?? ""}
                helperText={t(values?.entities.error ?? "")}
                label={t("word.searchEntity." + cleanName + ".label")}
                placeholder={t("word.searchEntity." + cleanName + ".label")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth required>
            <InputLabel id={"condition-" + cleanName}>
              {t("field.condition")}
            </InputLabel>
            <Select
              labelId={"condition-" + cleanName}
              value={values?.condition.value ?? ""}
              label={t("field.condition")}
              onChange={handleChangeSelect("condition")}
            >
              {CONDITIONS_FILTER.map((condition, indexType) => (
                <MenuItem value={condition} key={indexType}>
                  {t("word.filter.condition." + condition)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  })
);

export default SearchEntityAutocompleteComponent;
