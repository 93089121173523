import React, { useImperativeHandle, useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface State {
  label?: string;
  accept?: string;
  setHasFile?: Function;
}

const FileInput = React.memo(
  React.forwardRef(({ label, accept, setHasFile }: State, ref) => {
    const [attachment, setAttachment] = useState<File | undefined>(undefined);
    const { t } = useTranslation();

    const handleChange = (event: any) => {
      const files = Array.from(event.target.files);
      const [file] = files;
      // @ts-ignore
      setAttachment(file);
    };

    useImperativeHandle(ref, () => ({
      getValue() {
        return attachment;
      },
      resetFile() {
        return setAttachment(undefined);
      },
    }));

    React.useEffect(() => {
      if (setHasFile) {
        setHasFile(!!attachment);
      }
    }, [attachment]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ display: "flex", alignItems: "flex-end", marginBottom: 1 }}>
        <Button variant="contained" component="label">
          {label ?? t("sentence.selectImage")}
          <input
            accept={accept ?? "image/*"}
            type="file"
            hidden
            onChange={handleChange}
          />
        </Button>
        {attachment && (
          <Typography sx={{ marginLeft: 1 }}>{attachment.name}</Typography>
        )}
      </Box>
    );
  })
);

export default FileInput;
