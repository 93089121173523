import React from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface State {
  init: boolean;
  placeholder: string;
  updateSearchWithQueryParam?: boolean;
}

const FilterSearchComponent: React.FC<State> = React.memo(
  ({ init, placeholder, updateSearchWithQueryParam }) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState<string>(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.search ?? "";
    });

    const handleChangeText = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
      },
      []
    );

    const clearSearch = React.useCallback(() => {
      setSearch("");
    }, []);

    const searchFunction = React.useCallback(() => {
      const thisSearch = search.trim();
      const searchParamsObject = searchParamToObject(searchParams);
      if (thisSearch !== "") {
        searchParamsObject.search = thisSearch;
      } else if (searchParamsObject.hasOwnProperty("search")) {
        delete searchParamsObject.search;
      }
      if (init) {
        setSearchParams(searchParamsObject, {
          replace: true,
        });
      }
    }, [init, search, searchParams, setSearchParams]);

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchFunction();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (updateSearchWithQueryParam) {
        const searchParamsObject = searchParamToObject(searchParams);
        setSearch(searchParamsObject.search ?? "");
      }
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <TextField
        autoComplete="off"
        sx={{ width: "100%" }}
        type="text"
        value={search}
        onChange={handleChangeText}
        label={t("word.search")}
        placeholder={t(placeholder)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={clearSearch}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default FilterSearchComponent;
