import * as React from "react";
import { ChangeEvent, useRef } from "react";
import {
  ConfigurationFArticleOptionInterface,
  FArticleInterface,
  FArticleSmallInterface,
  FilterValueAssoLoadedInterface,
  FournisseurArticleInterface,
  TechnicalSheetInterface,
} from "../../../interfaces/FArticleInterface";
import {
  Checkbox,
  Container,
  DialogProps,
  Grid,
  List,
  ListItem,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { InputInterface } from "../../../interfaces/InputInterface";
import FArticleLogoComponent from "./FArticleLogoComponent";
import FArticleStockComponent from "./FArticleStockComponent";
import FArticleActionButtonComponent from "./FArticleActionButtonComponent";
import TabPanelComponent from "../TabPanelComponent";
import FArticleDescriptionComponent from "./FArticleDescriptionComponent";
import FArticleTechnicalSheetComponent from "./FArticleTechnicalSheetComponent";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import FArticleSliderComponent from "./FArticleSliderComponent";
import FArticleImageEditComponent from "./FArticleImageEditComponent";
import { LoadingButton } from "@mui/lab";
import { farticleFormToApi } from "../../../helpers/FarticleFormat";
import { GET, POST } from "../../../utils/MethodUtils";
import {
  jsonToFormData,
  objectToQuery,
  requestApi,
} from "../../../helpers/RequestApi";
import {
  CONFIGURATION_F_ARTICLE_OPTIONS_URL,
  FARTICLE_URL,
} from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import FArticleBreadcrumbComponent from "./FArticleBreadcrumbComponent";
import FArticleOptionComponent from "./FArticleOptionComponent";
import FArticleQuantityComponent from "./FArticleQuantityComponent";
import {
  generateTechnicalSheet,
  getNumberFilterWithNoValue,
  loadFilterValueAsso,
} from "../../../helpers/FArticleHelper";
import NotFoundComponent from "../NotFoundComponent";
import {
  BROWSER_PRODUCT_FILTER_VALUE_PAGE,
  FILTER_PAGE,
  PRODUCT_DUPLICATE_PAGE,
  PRODUCT_PAGE,
} from "../../../utils/RouteUtils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FArticleFormComponent from "./form/FArticleFormComponent";
import FArticleKeywordsComponent from "./FArticleKeywordsComponent";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import ContentComponent from "../../content/ContentComponent";
import FArticlePricesComponent from "./FArticlePricesComponent";
import { STORAGE_SPEED_DIAL_ARTICLE } from "../../../utils/StorageUtils";
import FArticleEmplacementComponent from "./FArticleEmplacementComponent";
import FArticleArDesignComponent from "./FArticleArDesignComponent";
import { InventoryFArticleComponent } from "./InventoryFArticleComponent";
import Dialog from "@mui/material/Dialog";
import Badge from "@mui/material/Badge";
import FArticleInfoFileComponent from "./FArticleInfoFileComponent";
import {
  contentHasContent,
  formatCkEditor,
} from "../../../helpers/CkEditorHelper";
import FArticleStateComponent from "./FArticleStateComponent";
import DialogFilterAdminProductFilterValueComponent from "./DialogFilterAdminProductFilterValueComponent";
import { StockMinMaxFArticleComponent } from "./StockMinMaxFArticleComponent";
import sage69Validator from "../../../helpers/validator/Sage69Validator";
import FArticleCustomTagComponent from "./FArticleCustomTagComponent";
import FArticleRefConstructeurComponent from "./FArticleRefConstructeurComponent";
import FArticleArCodebarreComponent from "./FArticleArCodebarreComponent";
import FArticleModelComponent from "./FArticleModelComponent";
import FArticleCodeFiscalComponent from "./FArticleCodeFiscalComponent";
import { IcecatFArticleComponent } from "./IcecatFArticleComponent";
import {
  ELASTIC_STOCK_AVAILABLE,
  SELLING_CROSS,
  SELLING_DISPLAY_PRODUCT,
} from "../../../utils/FArticleUtils";
import SliderFArticleComponent from "../../slider/SliderFArticleComponent";
import { PercentAcompteFArticleComponent } from "./PercentAcompteFArticleComponent";
import { TARIF_PUBLIC } from "../../../utils/UserUtils";
import AddIcon from "@mui/icons-material/Add";
import FArticleArRefComponent from "./FArticleArRefComponent";
import { FArticleFournisseursComponent } from "./FArticleFournisseursComponent";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import UpdateWeightComponent from "../entreeStock/UpdateWeightComponent";
import FArticleGlossaireComponent from "./FArticleGlossaireComponent";
import FArticleSuiviStockComponent from "./FArticleSuiviStockComponent";

interface State {
  initFArticle: FArticleInterface | undefined;
  scrollBottomTabs?: boolean;
  setFArticleInit?: Function;
  displayAfterContent?: boolean;
  createFrom?: FArticleInterface;
  temporaryIcecat?: any;
  fournisseurProduct?: FournisseurArticleInterface;
}

interface SellingTabInterface {
  type: string;
  fArticles: FArticleSmallInterface[] | undefined;
  filterValueAssosLoaded: FilterValueAssoLoadedInterface[] | undefined;
}

interface FormState {
  arDesign: InputInterface;
  fGlossaires: InputInterface;
  files?: any[];
  images?: any[];
}

export interface FormStateFArticleSub extends FormState {}

const FArticleComponent: React.FC<State> = React.memo(
  ({
    initFArticle,
    scrollBottomTabs,
    setFArticleInit,
    displayAfterContent,
    createFrom,
    temporaryIcecat,
    fournisseurProduct,
  }) => {
    const [quantity, setQuantity] = React.useState<number | undefined>(
      undefined
    );
    const theme = useTheme();
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const expeditions = useAppSelector(
      (state: RootState) => state.globalState.expeditions
    );
    const navigate = useNavigate();
    const location = useLocation();
    const [options, setOptions] = React.useState<
      ConfigurationFArticleOptionInterface[] | undefined
    >(undefined);
    const [fArticle, setFArticle] = React.useState<
      FArticleInterface | undefined
    >(initFArticle);
    const [loadingEditFarticle, setLoadingEditFarticle] = React.useState(false);
    const [loadingHiddenSelling, setLoadingHiddenSelling] =
      React.useState(false);
    const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
    const [forceOpenSpeedDial, setForceOpenSpeedDial] = React.useState(
      localStorage.getItem(STORAGE_SPEED_DIAL_ARTICLE) === "1"
    );
    const [notFound, setNotFound] = React.useState(false);
    const [tab, setTab] = React.useState(0);
    const initSellingTabs = React.useCallback(() => {
      return SELLING_DISPLAY_PRODUCT.map((sellingType) => {
        return {
          type: sellingType,
          fArticles: undefined,
          filterValueAssosLoaded: undefined,
        };
      });
    }, []);
    const [sellingTabs, setSellingTabs] = React.useState<SellingTabInterface[]>(
      initSellingTabs()
    );
    const [edit, setEdit] = React.useState(false);
    const [editImages, setEditImages] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const newBrowser = useAppSelector(
      (state: RootState) => state.globalState.newBrowser
    );
    const getDefaultValue = React.useCallback((): FormState => {
      const thisArticle = createFrom ?? fArticle;
      return {
        arDesign: {
          value: thisArticle?.arDesign ?? "",
          error: "",
        },
        fGlossaires: {
          value:
            thisArticle?.fArtgloss?.map((fArtgloss) => fArtgloss.fGlossaire) ??
            [],
          error: "",
        },
      };
    }, [createFrom, fArticle]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());
    const [openDialogForm, setOpenDialogForm] = React.useState(false);
    const [fullSizeConstructor, setFullSizeConstructor] = React.useState(false);
    const getIsExpedition = React.useCallback(() => {
      return (
        fArticle &&
        expeditions &&
        expeditions.find((e) => e.fArticleId === fArticle.arRef) !== undefined
      );
    }, [expeditions, fArticle]);
    const [isExpedition, setIsExpedition] = React.useState(getIsExpedition());

    const refArRef: any = useRef();
    const refImages: any = useRef();
    const refBottomTabs: any = useRef();
    const refForm: any = useRef();
    const refKeywords: any = useRef();
    const refFournisseurs: any = useRef();
    const refFArticleAfter: any = useRef();
    const refDescription: any = useRef();
    const refCategories: any = useRef();
    const refInfoFiles: any = useRef();
    const refState: any = useRef();
    const refSuiviStock: any = useRef();
    const refStockMinMax: any = useRef();
    // const refSpotlight: any = useRef();
    const refTags: any = useRef();
    const refConstructor: any = useRef();
    const refConstructor2: any = useRef();
    const refArCodebarre: any = useRef();
    const refStock: any = useRef();
    const refConfigurationFArticleOptions: any = useRef();
    const refModel: any = useRef();
    const refCodeFiscal: any = useRef();
    const refPercentAcompte: any = useRef();
    const refWeight: any = useRef();
    const refPrice: any = useRef();
    const refGlossaire: any = useRef();

    const { t } = useTranslation();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const isLg = useMediaQuery(theme.breakpoints.down("lg"));

    const handleOpenSpeedDial = React.useCallback(() => {
      setOpenSpeedDial(true);
    }, []);

    const handleCloseSpeedDial = React.useCallback(() => {
      setOpenSpeedDial(false);
    }, []);

    const handleForceOpenSpeedDial = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (
          // @ts-ignore
          !e.target.classList.contains("MuiFab-primary") &&
          // @ts-ignore
          !e.target.closest(".MuiFab-primary")
        ) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (localStorage.getItem(STORAGE_SPEED_DIAL_ARTICLE) === "1") {
          setForceOpenSpeedDial(false);
          localStorage.setItem(STORAGE_SPEED_DIAL_ARTICLE, "0");
        } else {
          setForceOpenSpeedDial(true);
          localStorage.setItem(STORAGE_SPEED_DIAL_ARTICLE, "1");
        }
      },
      []
    );

    const openDialogFilter = React.useCallback(() => {
      if (!newBrowser) {
        setOpenDialogForm(true);
      } else {
        window.open(
          BROWSER_PRODUCT_FILTER_VALUE_PAGE + "/" + fArticle?.arRef,
          "filter" + fArticle?.arRef,
          "height=1000,width=1100"
        );
      }
    }, [fArticle?.arRef, newBrowser]);

    const closeDialogFilter = React.useCallback(() => {
      if (loadingEditFarticle) {
        return;
      }
      setOpenDialogForm(false);
    }, [loadingEditFarticle]);

    const handleChangeTab = React.useCallback(
      (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
      },
      []
    );

    const loadSelling = React.useCallback(() => {
      if (!fArticle?.arRef || !fArticle?.fArticleProp?.id) {
        return;
      }
      for (const sellingType of SELLING_DISPLAY_PRODUCT) {
        requestApi({
          method: GET,
          path:
            FARTICLE_URL +
            objectToQuery({
              stock: ELASTIC_STOCK_AVAILABLE,
              fArticle: JSON.stringify({
                fArticleSellingType: sellingType,
                arRef: fArticle?.arRef,
                fArticlePropId: fArticle?.fArticleProp?.id,
              }),
              itemsPerPage: 20,
              indexable: 1,
              "order[stock]": "asc",
            }),
          allowError: false,
          paginate: true,
          token: token,
        }).then((response) => {
          if (response.statusCode === 200) {
            setSellingTabs((x) => {
              const sellingTab = x.find((y) => y.type === sellingType);
              if (!sellingTab) {
                return x;
              }
              sellingTab.fArticles = response.content.fArticles;
              return [...x];
            });
          }
        });
      }
    }, [fArticle, token]);

    const loadFilterValueAssos = React.useCallback(
      (thisFArticle: FArticleInterface) => {
        if (!thisFArticle.fArticleProp?.filterValueAssos) {
          return;
        }
        for (const filterValueAsso of thisFArticle.fArticleProp
          .filterValueAssos) {
          loadFilterValueAsso(thisFArticle, filterValueAsso).then(
            (thisFilterValueAssoLoaded) => {
              setSellingTabs((x) => {
                const sellingTab = x.find((y) => y.type === SELLING_CROSS);
                if (!sellingTab) {
                  return x;
                }
                if (!sellingTab?.filterValueAssosLoaded) {
                  sellingTab.filterValueAssosLoaded = [];
                }
                if (
                  sellingTab.filterValueAssosLoaded.find(
                    (y) => y.id === thisFilterValueAssoLoaded.id
                  ) === undefined &&
                  thisFilterValueAssoLoaded.fArticles.length > 0
                ) {
                  sellingTab.filterValueAssosLoaded.push(
                    thisFilterValueAssoLoaded
                  );
                }
                return [...x];
              });
            }
          );
        }
      },
      []
    );

    const afterFarticleFullyLoad = React.useCallback(
      async (reloadOption = false) => {
        if (!fArticle) {
          return;
        }
        setValues(getDefaultValue());
        if (scrollBottomTabs) {
          refBottomTabs.current?.scrollIntoView({ behavior: "auto" });
        }
        // @ts-ignore
        if (!fArticle.loaded || !fArticle["priceDto" + TARIF_PUBLIC]) {
          loadSelling();
          const response = await requestApi({
            method: GET,
            path: FARTICLE_URL + "/" + fArticle.arRef,
            allowError: true,
            token: token,
          });
          if (response.statusCode === 200) {
            setFArticle(response.content);
          } else if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"));
          } else if (response.statusCode === 404) {
            setNotFound(true);
          } else {
            toastr.error(t("word.error"), t("error.tryAgain"));
          }
        } else if (reloadOption) {
          loadSelling();
          loadFilterValueAssos(fArticle);
        }
        if (reloadOption) {
          setOptions(undefined);
          if (fArticle.fArticleProp?.hasOption) {
            const response = await requestApi({
              method: GET,
              path:
                CONFIGURATION_F_ARTICLE_OPTIONS_URL +
                "?fArticleProp=" +
                fArticle.fArticleProp?.id,
              allowError: false,
              token: token,
            });
            if (response.statusCode === 200) {
              setOptions(response.content);
              if (scrollBottomTabs) {
                refBottomTabs.current?.scrollIntoView({ behavior: "auto" });
              }
            } else if (response.statusCode === 401) {
              toastr.info(t("word.info"), t("error.reconnect"));
            } else {
              for (let message of getErrorApi(response.content)) {
                toastr.error(t("word.error"), t(message));
              }
            }
          }
        }
        if (setFArticleInit) {
          setFArticleInit(fArticle);
        }
      },
      [
        fArticle,
        getDefaultValue,
        loadFilterValueAssos,
        loadSelling,
        scrollBottomTabs,
        setFArticleInit,
        t,
        token,
      ]
    );

    const switchEditMode = React.useCallback(() => {
      setEdit((x) => !x);
      afterFarticleFullyLoad();
    }, [afterFarticleFullyLoad]);

    const handleEditImages = React.useCallback(() => {
      setEditImages((x) => !x);
    }, []);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const submitEditFarticle = React.useCallback(async () => {
      const arDesignError = sage69Validator(values.arDesign.value);
      const infoFilesValues = refInfoFiles.current.getValue();
      const fournisseursValues = refFournisseurs.current.getValue();
      let technicalSheet: TechnicalSheetInterface | undefined;
      if (temporaryIcecat) {
        const temp: any = {
          ficheTechnique: temporaryIcecat,
        };
        technicalSheet = generateTechnicalSheet(temp, 1);
      }
      let arRefError = "";
      if (createFrom) {
        arRefError = notEmptyValidator(refArRef.current.getValue());
      }
      const fArticleForm = refForm.current.getValue();
      if (
        arDesignError ||
        !fArticleForm ||
        !infoFilesValues ||
        arRefError ||
        !fournisseursValues
      ) {
        const newValue: FormState = { ...values };
        if (arDesignError) {
          newValue.arDesign.error = arDesignError;
        }
        setValues(newValue);
        return;
      }
      const fArticleApi = new FormData();
      for (const fArticleFile of infoFilesValues.fArticleFiles) {
        if (fArticleFile.file) {
          const name = fArticleFile.file.name
            .replace(/[^A-Za-z0-9]+/g, "")
            .toLowerCase();
          fArticleFile.name = name;
          fArticleApi.append(name, fArticleFile.file);
        }
      }
      setLoadingEditFarticle(true);
      if (fArticle?.fArticleProp === undefined && !createFrom) {
        setLoadingEditFarticle(false);
        return;
      }
      const images = await refImages.current.getValue();
      jsonToFormData(
        farticleFormToApi(fArticle?.arRef ?? "", {
          ...values,
          ...fArticleForm,
          ...images,
          ...(createFrom && {
            ...refArRef.current.getValue(),
          }),
          ...refGlossaire.current.getValue(),
          ...refPrice.current.getValue(),
          ...(technicalSheet?.icecatId && {
            icecatId: technicalSheet.icecatId,
          }),
          ...refKeywords.current.getValue(),
          ...fournisseursValues,
          ...refStock.current.getValue(),
          ...refCategories.current.getValue(),
          ...refState.current.getValue(),
          ...refSuiviStock.current.getValue(),
          ...refStockMinMax.current.getValue(),
          ...refPercentAcompte.current.getValue(),
          ...refWeight.current.getValue(),
          // ...refSpotlight.current.getValue(),
          ...refConstructor.current.getValue(),
          ...refConstructor2.current.getValue(),
          ...refModel.current.getValue(),
          ...refCodeFiscal.current.getValue(),
          ...refArCodebarre.current.getValue(),
          ...infoFilesValues,
          ...(refDescription.current && {
            description: await refDescription.current.getValue(),
          }),
          ...refTags.current.getValue(),
          ...refConfigurationFArticleOptions.current.getValue(),
        }),
        fArticleApi
      );
      if (createFrom) {
        fArticleApi.append("createFrom", createFrom.arRef);
      }
      for (const file of images.files) {
        fArticleApi.append(file.filename, file.file);
      }
      const response = await requestApi({
        method: POST,
        path: FARTICLE_URL,
        allowError: true,
        timeout: 60_000,
        token: token,
        body: fArticleApi,
        formData: true,
      });
      if (response.statusCode === 201) {
        if (createFrom) {
          navigate(PRODUCT_PAGE + "/" + response.content.fArticleProp?.slug);
          return;
        }
        toastr.success(
          t("word.success"),
          t("sentence.notification.farticle_updated")
        );
        setFArticle(response.content);
        refFArticleAfter.current.updateContent();
        if (fArticle) {
          const newUrl =
            PRODUCT_PAGE + "/" + response.content.fArticleProp?.slug;
          if (window.location.pathname !== newUrl) {
            navigate(newUrl);
          }
        }
        switchEditMode();
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingEditFarticle(false);
    }, [
      createFrom,
      fArticle,
      navigate,
      switchEditMode,
      t,
      temporaryIcecat,
      token,
      values,
    ]);

    const editFArticleAfter = React.useCallback(() => {
      refFArticleAfter.current.setEdit();
    }, []);

    const setDescription = React.useCallback(
      (newContent: string | undefined) => {
        setTab(0);
        setTimeout(() => {
          refDescription.current.setDescription(newContent);
        }, 100);
      },
      []
    );

    const canDisplaySellingTab = React.useCallback(
      (sellingTab: SellingTabInterface) => {
        return (
          (!Array.isArray(fArticle?.fArticleProp?.hiddenSelling) ||
            !fArticle?.fArticleProp?.hiddenSelling.includes(sellingTab.type) ||
            isAdmin) &&
          ((sellingTab.fArticles && sellingTab.fArticles.length > 0) ||
            (sellingTab.filterValueAssosLoaded &&
              sellingTab.filterValueAssosLoaded.length > 0))
        );
      },
      [fArticle?.fArticleProp?.hiddenSelling, isAdmin]
    );

    const getCreateFromCodebarreRefCons = React.useCallback(() => {
      return [
        refArCodebarre.current.getValue()?.arCodebarre ?? "",
        refConstructor.current.getValue()?.constructeurRef ?? "",
      ];
    }, []);

    const changeArPrixach = React.useCallback((arPrixach: number) => {
      refPrice.current.changeArPrixach(arPrixach);
    }, []);

    const onHideSellingTab = React.useCallback(
      (type: string) =>
        async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
          if (!fArticle) {
            return;
          }
          setLoadingHiddenSelling(true);
          let newHiddenSelling: string[] | null = fArticle?.fArticleProp
            ?.hiddenSelling
            ? [...fArticle?.fArticleProp?.hiddenSelling]
            : [];
          if (!checked) {
            newHiddenSelling.push(type);
          } else {
            newHiddenSelling = newHiddenSelling.filter((x) => x !== type);
          }
          if (newHiddenSelling.length === 0) {
            newHiddenSelling = null;
          }
          const fArticleApi = new FormData();
          fArticleApi.append(
            "json",
            JSON.stringify({
              arRef: fArticle?.arRef,
              fArticleProp: {
                hiddenSelling: newHiddenSelling,
              },
            })
          );
          const response = await requestApi({
            method: POST,
            path: FARTICLE_URL,
            allowError: true,
            timeout: 30_000,
            token: token,
            body: fArticleApi,
            formData: true,
          });
          if (response.statusCode === 201) {
            setFArticle(response.content);
          } else if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"));
          } else {
            toastr.error(t("word.error"), t("error.tryAgain"));
          }
          setLoadingHiddenSelling(false);
        },
      [fArticle, t, token]
    );

    React.useEffect(() => {
      if (
        initFArticle === undefined ||
        fArticle === undefined ||
        !(
          initFArticle.arRef === fArticle.fArticleProp?.slug ||
          initFArticle.arRef === fArticle.arRef
        )
      ) {
        setFArticle(initFArticle);
        setSellingTabs(initSellingTabs());
      }
    }, [initFArticle]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (fArticle?.fArticleProp?.description !== undefined) {
        setTab(contentHasContent(fArticle?.fArticleProp?.description) ? 0 : 1);
      }
      afterFarticleFullyLoad(true);
      setIsExpedition(getIsExpedition());
    }, [fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (!edit) {
        setEditImages(false);
      }
    }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (createFrom) {
        setValues(getDefaultValue());
        setEdit(true);
      }
    }, [createFrom]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {notFound ? (
          <NotFoundComponent />
        ) : (
          <>
            {fArticle?.loaded && (
              <Dialog
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={true}
                onClose={closeDialogFilter}
                open={openDialogForm}
              >
                <DialogFilterAdminProductFilterValueComponent
                  fArticle={fArticle}
                  setFArticle={setFArticle}
                  setOpenDialogForm={setOpenDialogForm}
                  closeDialogFilter={closeDialogFilter}
                />
              </Dialog>
            )}
            <Container maxWidth={false}>
              <Grid container spacing={1} sx={{ marginTop: 2 }}>
                <Grid item xs={12}>
                  <FArticleBreadcrumbComponent
                    ref={refCategories}
                    fArticle={fArticle}
                    edit={edit}
                    setFArticle={setFArticle}
                    createFrom={createFrom}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <FArticleArDesignComponent
                    values={values}
                    edit={edit}
                    handleChange={handleChange}
                    fArticle={createFrom ?? fArticle}
                    setValues={setValues}
                    setFArticle={setFArticle}
                    temporaryIcecat={temporaryIcecat}
                    fournisseurProduct={fournisseurProduct}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FArticleCustomTagComponent
                    ref={refTags}
                    fArticle={fArticle}
                    edit={edit}
                    setFArticle={setFArticle}
                  />
                </Grid>
                <Grid item xs={12} md={edit || editImages ? 12 : 3}>
                  <FArticleImageEditComponent
                    ref={refImages}
                    fArticle={fArticle}
                    edit={edit}
                    setEdit={handleEditImages}
                    setFArticle={setFArticle}
                    hidden={!(edit || editImages)}
                    temporaryIcecat={temporaryIcecat}
                    createFrom={createFrom}
                    getCreateFromCodebarreRefCons={
                      getCreateFromCodebarreRefCons
                    }
                  />
                  {!(edit || editImages) && (
                    <FArticleSliderComponent
                      fArticle={fArticle}
                      dots={true}
                      dialog={true}
                      setEdit={handleEditImages}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={edit || editImages ? 8 : 5}>
                  <Box sx={{ position: "relative" }}>
                    {isSmall && <Divider />}
                    <List sx={{ paddingTop: 0 }}>
                      <FArticleLogoComponent
                        fArticle={fArticle}
                        onlyConstructor={true}
                        ref={refConstructor2}
                        constructorSize={130}
                        edit={edit}
                        setFullSizeConstructor={setFullSizeConstructor}
                        setFArticle={setFArticle}
                        sx={{
                          ...(!isLg &&
                            !edit &&
                            !fullSizeConstructor && {
                              right: 0,
                              top: 0,
                              width: "auto",
                              float: "right",
                            }),
                        }}
                        createFrom={createFrom}
                        temporaryIcecat={temporaryIcecat}
                        fournisseurProduct={fournisseurProduct}
                      />
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <FArticleArRefComponent
                          fArticle={fArticle}
                          createFrom={createFrom}
                          ref={refArRef}
                        />
                      </ListItem>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <FArticleModelComponent
                          fArticle={fArticle}
                          setFArticle={setFArticle}
                          edit={edit}
                          ref={refModel}
                          temporaryIcecat={temporaryIcecat}
                        />
                      </ListItem>
                      {isAdmin && (
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <FArticleCodeFiscalComponent
                            fArticle={fArticle}
                            setFArticle={setFArticle}
                            edit={edit}
                            ref={refCodeFiscal}
                            createFrom={createFrom}
                          />
                        </ListItem>
                      )}
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <FArticleRefConstructeurComponent
                          arRef={fArticle?.arRef}
                          constructeurRef={fArticle?.constructeurRef}
                          setFArticle={setFArticle}
                          edit={edit}
                          ref={refConstructor}
                          temporaryIcecat={temporaryIcecat}
                          fournisseurProduct={fournisseurProduct}
                        />
                      </ListItem>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <FArticleArCodebarreComponent
                          arRef={fArticle?.arRef}
                          arCodebarre={fArticle?.arCodebarre}
                          setFArticle={setFArticle}
                          edit={edit}
                          ref={refArCodebarre}
                          temporaryIcecat={temporaryIcecat}
                          fournisseurProduct={fournisseurProduct}
                        />
                      </ListItem>
                      <FArticleInfoFileComponent
                        fArticle={fArticle}
                        edit={edit}
                        ref={refInfoFiles}
                        setFArticle={setFArticle}
                      />
                      <FArticleGlossaireComponent
                        edit={edit}
                        setFArticle={setFArticle}
                        fArticle={fArticle}
                        ref={refGlossaire}
                      />
                    </List>
                    {!fArticle?.fArticleProp?.hasOption && (
                      <FArticleQuantityComponent
                        fArticle={fArticle}
                        setQuantity={setQuantity}
                        maxEqualMinWidth={true}
                      />
                    )}
                    <FArticleStockComponent
                      fArticle={fArticle}
                      setFArticle={setFArticle}
                      edit={edit}
                      small={false}
                      ref={refStock}
                      createFrom={createFrom}
                    />
                    {isExpedition && (
                      <Typography>{t("sentence.cantBuyExpedition")}</Typography>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {!fArticle?.fArticleProp?.hasOption && (
                        <FArticleActionButtonComponent
                          fArticle={fArticle}
                          quantity={quantity}
                        />
                      )}
                    </Box>
                    {isAdmin && (
                      <FArticleKeywordsComponent
                        fArticle={fArticle}
                        edit={edit}
                        ref={refKeywords}
                        setFArticle={setFArticle}
                      />
                    )}
                    {isAdmin && (fArticle?.loaded || createFrom) && (
                      <FArticleFournisseursComponent
                        fArticle={fArticle}
                        edit={edit}
                        ref={refFournisseurs}
                        setFArticle={setFArticle}
                        createFrom={createFrom}
                        fournisseurProduct={fournisseurProduct}
                        changeArPrixach={changeArPrixach}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <FArticleEmplacementComponent fArticle={fArticle} />
                    <FArticleSuiviStockComponent
                      fArticle={fArticle}
                      edit={edit}
                      ref={refSuiviStock}
                      setFArticle={setFArticle}
                      createFrom={createFrom}
                    />
                    <FArticleStateComponent
                      fArticle={fArticle}
                      edit={edit}
                      ref={refState}
                      setFArticle={setFArticle}
                      createFrom={createFrom}
                    />
                    <StockMinMaxFArticleComponent
                      fArticle={fArticle}
                      edit={edit}
                      ref={refStockMinMax}
                      setFArticle={setFArticle}
                      createFrom={createFrom}
                    />
                    <PercentAcompteFArticleComponent
                      fArticle={fArticle}
                      edit={edit}
                      ref={refPercentAcompte}
                      setFArticle={setFArticle}
                      createFrom={createFrom}
                    />
                    {/*<SpotlightFArticleComponent*/}
                    {/*  fArticle={fArticle}*/}
                    {/*  edit={edit}*/}
                    {/*  ref={refSpotlight}*/}
                    {/*  setFArticle={setFArticle}*/}
                    {/*  createFrom={createFrom}*/}
                    {/*/>*/}
                    {(fArticle || createFrom) && (
                      <UpdateWeightComponent
                        fArticle={fArticle}
                        arRef={fArticle?.arRef}
                        arPoidsnet={fArticle?.arPoidsnet}
                        createFrom={createFrom}
                        edit={edit}
                        setFArticle={setFArticle}
                        ref={refWeight}
                        temporaryIcecat={temporaryIcecat}
                      />
                    )}
                    <IcecatFArticleComponent
                      fArticle={fArticle}
                      setFArticle={setFArticle}
                      refImages={refImages}
                    />
                    <FArticlePricesComponent
                      fArticle={fArticle}
                      edit={edit}
                      ref={refPrice}
                      setFArticle={setFArticle}
                      createFrom={createFrom}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {isAdmin && (
                    <InventoryFArticleComponent fArticle={fArticle} />
                  )}
                  <FArticleLogoComponent
                    fArticle={fArticle}
                    createFrom={createFrom}
                  />
                </Grid>
                <FArticleFormComponent
                  initFArticle={fArticle}
                  refFArticleAfter={refFArticleAfter}
                  ref={refForm}
                  edit={edit}
                  setFArticle={setFArticle}
                />
                <Grid item xs={12}>
                  <FArticleOptionComponent
                    ref={refConfigurationFArticleOptions}
                    options={options}
                    fArticle={fArticle}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    edit={edit}
                    setFArticle={setFArticle}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box ref={refBottomTabs}>
                    <Tabs
                      value={tab}
                      onChange={handleChangeTab}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      TabScrollButtonProps={{ sx: { flex: 0 } }}
                    >
                      <Tab label={t("word.description")} />
                      <Tab label={t("word.technicalSheet")} />
                      {sellingTabs
                        .filter((sellingTab) =>
                          canDisplaySellingTab(sellingTab)
                        )
                        .map((sellingTab) => {
                          let label = t(
                            "word.fArticleSelling.type." + sellingTab.type
                          );
                          if (isAdmin) {
                            label +=
                              ": [" +
                              (
                                sellingTab?.filterValueAssosLoaded?.map(
                                  (f) => f.id
                                ) ?? []
                              ).join("|") +
                              "]";
                          }
                          return (
                            <Tab
                              key={sellingTab.type}
                              sx={{ flexDirection: "row" }}
                              label={
                                <>
                                  {label}
                                  {isAdmin && (
                                    <Checkbox
                                      disabled={loadingHiddenSelling}
                                      checked={
                                        !Array.isArray(
                                          fArticle?.fArticleProp?.hiddenSelling
                                        ) ||
                                        !fArticle?.fArticleProp?.hiddenSelling.includes(
                                          sellingTab.type
                                        )
                                      }
                                      onChange={onHideSellingTab(
                                        sellingTab.type
                                      )}
                                    />
                                  )}
                                </>
                              }
                            />
                          );
                        })}
                      {/*<Tab label={t("word.reviews")} />*/}
                    </Tabs>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <TabPanelComponent sx={{ marginTop: 1 }} value={tab} index={0}>
              <FArticleDescriptionComponent
                fArticle={fArticle}
                setFArticle={setFArticle}
                edit={edit}
                ref={refDescription}
                createFrom={createFrom}
                temporaryIcecat={temporaryIcecat}
              />
            </TabPanelComponent>
            <TabPanelComponent sx={{ marginTop: 1 }} value={tab} index={1}>
              <Container sx={{ padding: 0 }}>
                <FArticleTechnicalSheetComponent
                  fArticle={fArticle}
                  setDescription={setDescription}
                  temporaryIcecat={temporaryIcecat}
                />
              </Container>
            </TabPanelComponent>
            {sellingTabs
              .filter((sellingTab) => canDisplaySellingTab(sellingTab))
              .map((sellingTab, indexSellingTab) => (
                <TabPanelComponent
                  sx={{ marginTop: 1 }}
                  value={tab}
                  key={indexSellingTab}
                  index={indexSellingTab + 2}
                >
                  <Container sx={{ padding: 0 }}>
                    {sellingTab.fArticles && (
                      <SliderFArticleComponent
                        fArticles={sellingTab.fArticles}
                      />
                    )}
                    {sellingTab.filterValueAssosLoaded &&
                      sellingTab.filterValueAssosLoaded
                        .filter(
                          (filterValueAssoLoaded) =>
                            filterValueAssoLoaded.fArticles &&
                            filterValueAssoLoaded.fArticles.length > 0
                        )
                        .map(
                          (
                            filterValueAssoLoaded,
                            indexFilterValueAssoLoaded
                          ) => (
                            <Box key={indexFilterValueAssoLoaded}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: formatCkEditor(
                                    filterValueAssoLoaded.hasFrom
                                      ? filterValueAssoLoaded.textFrom
                                      : filterValueAssoLoaded.textTo ?? ""
                                  ),
                                }}
                              />
                              <SliderFArticleComponent
                                fArticles={filterValueAssoLoaded.fArticles}
                              />
                            </Box>
                          )
                        )}
                  </Container>
                </TabPanelComponent>
              ))}
            {/*<TabPanelComponent sx={{ marginTop: 1 }} value={tab} index={2}>*/}
            {/*  <Container sx={{ padding: 0 }}>*/}
            {/*    <FArticleReviewsComponent fArticle={fArticle} />*/}
            {/*  </Container>*/}
            {/*</TabPanelComponent>*/}
            {displayAfterContent !== false && fArticle && fArticle?.loaded && (
              <Box
                sx={{
                  paddingBottom: 5,
                }}
              >
                <Grid container spacing={1} sx={{ marginTop: 2 }}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <ContentComponent
                      contentName="fArticleAfter"
                      saveLocalStorage={true}
                      fArticle={fArticle}
                      showEdit={false}
                      ref={refFArticleAfter}
                    />
                  </Grid>
                  {/*<Grid item xs={12}>*/}
                  {/*  <FArticleFilterValueAssoComponent fArticle={fArticle} />*/}
                  {/*</Grid>*/}
                </Grid>
              </Box>
            )}
            {!isAdmin && (
              <Container
                sx={{
                  paddingBottom: 5,
                }}
              >
                <FArticleKeywordsComponent
                  fArticle={fArticle}
                  edit={edit}
                  ref={refKeywords}
                />
              </Container>
            )}
            {edit && (
              <Container sx={{ padding: 0, marginTop: 5 }}>
                <Box
                  sx={{
                    position: "fixed",
                    width: "stretch",
                    maxWidth: "inherit",
                    bottom: 0,
                    paddingBottom: 2,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      {!createFrom && (
                        <LoadingButton
                          variant="contained"
                          component="span"
                          onClick={switchEditMode}
                          disabled={loadingEditFarticle}
                        >
                          {t("word.cancelSaveFArticle")}
                        </LoadingButton>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ ...(!isSmall && { textAlign: "right" }) }}
                    >
                      <LoadingButton
                        variant="contained"
                        component="span"
                        onClick={submitEditFarticle}
                        sx={{ marginRight: 6 }}
                        loading={loadingEditFarticle}
                      >
                        {t("word.saveFArticle")}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            )}
            {isAdmin && !createFrom && (
              <SpeedDial
                ariaLabel="Product menu"
                sx={{ position: "absolute", bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClose={handleCloseSpeedDial}
                onOpen={handleOpenSpeedDial}
                open={openSpeedDial || forceOpenSpeedDial}
                onClick={handleForceOpenSpeedDial}
              >
                <SpeedDialAction
                  icon={<ModeEditIcon />}
                  onClick={switchEditMode}
                  tooltipTitle={t("word.edit.article")}
                />
                {location.pathname !== FILTER_PAGE && (
                  <SpeedDialAction
                    icon={
                      <Badge
                        badgeContent={getNumberFilterWithNoValue(fArticle)}
                        color="primary"
                      >
                        <FilterAltIcon />
                      </Badge>
                    }
                    tooltipTitle={t("word.edit.filterArticle")}
                    onClick={openDialogFilter}
                  />
                )}
                <SpeedDialAction
                  icon={<CallToActionIcon />}
                  onClick={editFArticleAfter}
                  tooltipTitle={t("word.edit.fArticleAfter")}
                />
                {fArticle?.arRef && (
                  <SpeedDialAction
                    icon={
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          display: "flex",
                        }}
                        to={PRODUCT_DUPLICATE_PAGE + "/" + fArticle.arRef}
                      >
                        <AddIcon />
                      </Link>
                    }
                    tooltipTitle={t("word.duplicate.article")}
                  />
                )}
              </SpeedDial>
            )}
          </>
        )}
      </>
    );
  }
);

export default FArticleComponent;
