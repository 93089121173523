import { ExpeditionInterface } from "../interfaces/CartInterface";
import { getPostOrderWeight } from "./CartHelper";
import { PostOrderInterface } from "../interfaces/OrderInterface";
import { FLivraisonInterface } from "../interfaces/UserInterface";
import { COUNTRY_CODE_MAP, GUADELOUPE } from "../utils/UserUtils";
import { FDocenteteInterface } from "../interfaces/FDocenteteInterface";
import { RootState, store } from "../app/store";

export const getPriceExpedition = (
  postOrder: PostOrderInterface,
  expedition: ExpeditionInterface | undefined,
  fLivraison: FLivraisonInterface | undefined
): number | undefined => {
  if (expedition === undefined) {
    return undefined;
  }
  let countryCode = GUADELOUPE;
  if (fLivraison?.liPays) {
    countryCode = fLivraison?.liPays;
  }
  const expeditionGrilles = [...expedition.expeditionGrilles].filter(
    (e) =>
      e.countryCode.toUpperCase() ===
      COUNTRY_CODE_MAP[countryCode]?.toUpperCase()
  );
  expeditionGrilles.sort((a, b) => {
    return a.weight - b.weight;
  });
  if (expeditionGrilles.length === 0) {
    return undefined;
  }
  let weight = getPostOrderWeight(postOrder);
  if (isNaN(weight)) {
    return undefined;
  }
  let price = 0;
  while (weight > 0) {
    // @ts-ignore
    for (const [i, expeditionGrille] of expeditionGrilles.entries()) {
      if (
        weight <= expeditionGrille.weight ||
        i === expeditionGrilles.length - 1
      ) {
        price += expeditionGrille.price;
        weight -= expeditionGrille.weight;
        break;
      }
    }
  }

  return price;
};

export const getMaxWeightFDocentete = (
  fDocentete: FDocenteteInterface
): number => {
  const state: RootState = store.getState();
  const expedition = state.globalState.expeditions?.find(
    (e) => e.fArticleId === fDocentete.arRefLivraison
  );
  if (!expedition) {
    return 0;
  }
  return Math.max(
    ...[...expedition.expeditionGrilles]
      .filter(
        (e) =>
          e.countryCode.toUpperCase() ===
          COUNTRY_CODE_MAP[fDocentete.livraisonPays ?? ""]?.toUpperCase()
      )
      .map((eg) => eg.weight)
  );
};

export const getNbExpeditionFDocentete = (
  fDocentete: FDocenteteInterface
): number => {
  let nbExpedition = 0;
  const state: RootState = store.getState();
  const expedition = state.globalState.expeditions?.find(
    (e) => e.fArticleId === fDocentete.arRefLivraison
  );
  if (expedition && fDocentete.livraisonPays) {
    nbExpedition = Math.ceil(
      fDocentete.arPoidsnet / getMaxWeightFDocentete(fDocentete)
    );
  }
  return nbExpedition;
};
