export const ORIGIN_NOVE = "A";
export const ORIGIN_OUTSIDE = "B";
export const ALL_ORIGIN = [ORIGIN_NOVE, ORIGIN_OUTSIDE];

export const REASON_HS = "A";
export const REASON_UNPACKING = "B";
export const REASON_COMMERCIAL = "C";
export const REASON_CUSTOMER_ORDER = "D";
export const REASON_NON_COMPLIANT = "E";
export const REASON_PACKAGING = "F";
export const REASON_DIAGNOSTIC = "G";
export const ALL_REASON = [
  REASON_HS,
  REASON_UNPACKING,
  REASON_COMMERCIAL,
  REASON_CUSTOMER_ORDER,
  REASON_NON_COMPLIANT,
  REASON_PACKAGING,
  REASON_DIAGNOSTIC,
];

export const PACKAGE_STATE_INTACT = "A";
export const PACKAGE_STATE_OPEN = "B";
export const PACKAGE_STATE_USED = "C";
export const ALL_PACKAGE_STATE = [
  PACKAGE_STATE_INTACT,
  PACKAGE_STATE_OPEN,
  PACKAGE_STATE_USED,
];

export const STATE_PENDING_REQUEST = "A";
export const STATE_BEING_PROCESSED = "B";
export const STATE_WAITING_FOR_CUSTOMER_RESPONSE = "C";
export const STATE_WAITING_NOVE_RESPONSE = "D";
export const STATE_REFUSAL = "E";
export const STATE_EQUIPMENT_EXPECTED_IN_WORKSHOP = "F";
export const STATE_EQUIPMENT_IN_SAV = "G";
export const STATE_MATERIAL_AVAILABLE = "H";
export const STATE_MATERIAL_AT_THE_SUPPLIER = "I";
export const STATE_FENCING = "J";
export const ALL_STATE = [
  STATE_PENDING_REQUEST,
  STATE_BEING_PROCESSED,
  STATE_WAITING_FOR_CUSTOMER_RESPONSE,
  STATE_WAITING_NOVE_RESPONSE,
  STATE_REFUSAL,
  STATE_FENCING,
];
export const ALL_STATE_PRODUCT = [
  STATE_EQUIPMENT_EXPECTED_IN_WORKSHOP,
  STATE_EQUIPMENT_IN_SAV,
  STATE_MATERIAL_AVAILABLE,
  STATE_MATERIAL_AT_THE_SUPPLIER,
];
export const WAITING_SAV = [STATE_PENDING_REQUEST, STATE_WAITING_NOVE_RESPONSE];
