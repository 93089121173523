import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useTranslation } from "react-i18next";
import StepperDoublonOrderComponent from "./step/StepperDoublonOrderComponent";
import {
  getAcompteOrder,
  getCanChooseOrderType,
  getUnavailableArticles,
  hasLicense,
} from "../../../helpers/CartHelper";
import { getMaxQuantityCanBuy } from "../../../helpers/FArticleHelper";
import StepperLivraisonOrderComponent from "./step/StepperLivraisonOrderComponent";
import StepperAcompteOrderComponent from "./step/StepperAcompteOrderComponent";
import {
  ArrayPostOrderInterface,
  OrderInterface,
} from "../../../interfaces/OrderInterface";
import { FArticleSmallComponent } from "../fArticle/FArticleSmallComponent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {
  canChangeExpeditionMode,
  getArrayPostOrder,
} from "../../../helpers/FDocenteteHelper";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import StepperFDocenteteBonCommandeComponent from "./step/StepperFDocenteteBonCommandeComponent";
import StepperOrderTypeComponent from "./step/StepperOrderTypeComponent";
import { ORDER_TYPE_GLOBAL } from "../../../utils/OrderUtils";
import {
  FDocenteteLigneInterface,
  FDocenteteLignePaginateInterface,
} from "../../../interfaces/FDocenteteInterface";
import { isUserEnCompte } from "../../../helpers/UserHelper";
import StepperLicenseComponent from "./step/StepperLicenseComponent";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface StepInterface {
  label: string;
  children: React.ReactNode;
}

interface Props {
  initOrder: OrderInterface;
  onFinish: any;
  withBonCommande: boolean;
  fDocenteteLigneLivraison:
    | FDocenteteLignePaginateInterface
    | FDocenteteLigneInterface
    | null; // todo change to hasLivraison
  devis: boolean;
}

const StepperOrderComponent: React.FC<Props> = React.memo(
  ({
    initOrder,
    onFinish,
    withBonCommande,
    fDocenteteLigneLivraison,
    devis,
  }) => {
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const userProp = useAppSelector(
      (state: RootState) => state.globalState.userProp
    );
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const [activeStep, setActiveStep] = React.useState(0);
    const [file, setFile] = React.useState(undefined);
    const [orderType, setOrderType] = React.useState(
      !getCanChooseOrderType(initOrder, user, devis) ? ORDER_TYPE_GLOBAL : ""
    );
    const getOrderWithOrderType = React.useCallback((): OrderInterface => {
      const newOrder: OrderInterface = { ...initOrder };
      newOrder.orderType = orderType;
      return newOrder;
    }, [initOrder, orderType]);
    const [arrayPostOrder, setArrayPostOrder] =
      React.useState<ArrayPostOrderInterface>(() => {
        const newOrder = getOrderWithOrderType();
        return getArrayPostOrder(newOrder, devis, user);
      });
    const { t } = useTranslation();
    const [unavailableArticles, setUnavailableArticles] = React.useState<
      string[]
    >(getUnavailableArticles(initOrder, isAdmin));
    const [defaultBase64, setDefaultBase64] = React.useState(undefined);
    const [reference, setReference] = React.useState(undefined);
    const [acompteToPayGlobal, setAcompteToPayGlobal] =
      React.useState<number>(0);

    const handleNext = React.useCallback((data: any = null) => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, []);

    const handleBack = React.useCallback(() => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, []);

    const getSteps = React.useCallback((): StepInterface[] => {
      const steps: StepInterface[] = [];
      if (!devis) {
        steps.push({
          label: t("order.step.doublon"),
          children: (
            <StepperDoublonOrderComponent
              order={initOrder}
              handleNext={handleNext}
            />
          ),
        });
      }
      if (hasLicense(initOrder)) {
        steps.push({
          label: t("order.step.license"),
          children: (
            <StepperLicenseComponent
              handleNext={handleNext}
              handleBack={handleBack}
            />
          ),
        });
      }
      if (getCanChooseOrderType(initOrder, user, devis)) {
        steps.push({
          label: t("word.orderType.label"),
          children: (
            <StepperOrderTypeComponent
              handleNext={handleNext}
              handleBack={handleBack}
              orderType={orderType}
              setOrderType={setOrderType}
            />
          ),
        });
      }
      if (canChangeExpeditionMode(user, devis)) {
        for (const postOrder of arrayPostOrder.postOrders) {
          let label = "order.step.expeditionType." + postOrder.doType;
          if (arrayPostOrder.postOrders.length === 1) {
            label = "order.step.expeditionType.global";
          }
          steps.push({
            label: t(label),
            children: (
              <StepperLivraisonOrderComponent
                postOrder={postOrder}
                showFArticles={arrayPostOrder.postOrders.length > 1}
                setArrayPostOrder={setArrayPostOrder}
                handleNext={handleNext}
                handleBack={handleBack}
                hasLivraison={!!fDocenteteLigneLivraison}
              />
            ),
          });
        }
      }
      const acompteOrder = getAcompteOrder(
        initOrder,
        user,
        userProp,
        devis,
        isUserEnCompte(user),
        null,
        withBonCommande
      );
      const totalOrder = getAcompteOrder(
        initOrder,
        user,
        userProp,
        devis,
        orderType === ORDER_TYPE_GLOBAL,
        1,
        true
      );
      if (withBonCommande) {
        steps.push({
          label: t("order.step.bonCommande"),
          children: (
            <StepperFDocenteteBonCommandeComponent
              setFile={setFile}
              handleNext={handleNext}
              handleBack={handleBack}
              defaultBase64={defaultBase64}
              setDefaultBase64={setDefaultBase64}
              setReference={setReference}
              totalOrder={totalOrder}
              setArrayPostOrder={setArrayPostOrder}
              arrayPostOrder={arrayPostOrder}
              setAcompteToPayGlobal={setAcompteToPayGlobal}
            />
          ),
        });
      } else if (acompteOrder > 0) {
        steps.push({
          label: t("order.step.acompte.label"),
          children: (
            <StepperAcompteOrderComponent
              acompteOrder={acompteOrder}
              totalOrder={totalOrder}
              setArrayPostOrder={setArrayPostOrder}
              arrayPostOrder={arrayPostOrder}
              handleNext={handleNext}
              handleBack={handleBack}
              setAcompteToPayGlobal={setAcompteToPayGlobal}
            />
          ),
        });
      }
      return steps;
    }, [
      arrayPostOrder,
      defaultBase64,
      devis,
      fDocenteteLigneLivraison,
      handleBack,
      handleNext,
      initOrder,
      orderType,
      t,
      user,
      userProp,
      withBonCommande,
    ]);
    const [steps, setSteps] = React.useState<StepInterface[]>(getSteps());

    const handleReset = React.useCallback(() => {
      setActiveStep(0);
      setUnavailableArticles(getUnavailableArticles(initOrder, isAdmin));
      setArrayPostOrder(getArrayPostOrder(initOrder, devis, user));
    }, [devis, initOrder, isAdmin, user]);

    const onChangeOrderType = React.useCallback(() => {
      const newOrder = getOrderWithOrderType();
      setArrayPostOrder(getArrayPostOrder(newOrder, devis, user));
    }, [devis, getOrderWithOrderType, user]);

    const finish = React.useCallback(() => {
      arrayPostOrder.bonCommande = file;
      arrayPostOrder.reference = reference;
      arrayPostOrder.toPay = acompteToPayGlobal;
      onFinish(arrayPostOrder);
    }, [acompteToPayGlobal, arrayPostOrder, file, onFinish, reference]);

    React.useEffect(() => {
      handleReset();
    }, [initOrder]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setSteps(getSteps());
    }, [arrayPostOrder]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (activeStep === steps.length) {
        finish();
      }
    }, [activeStep, file]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      onChangeOrderType();
    }, [orderType]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {unavailableArticles.length > 0 ? (
          <>
            <Typography>{t("sentence.cantBuyProducts")}</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {t("word.product")}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {t("word.quantity")}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {t("word.maxQuantity")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unavailableArticles.map((arRef, indexArRef) => {
                    const orderFArticle = initOrder.orderFArticles.find(
                      (orderFArticle) => orderFArticle.fArticle.arRef === arRef
                    );
                    return orderFArticle !== undefined ? (
                      <StyledTableRow key={indexArRef}>
                        <StyledTableCell>
                          <FArticleSmallComponent
                            fArticles={[orderFArticle.fArticle]}
                            nbColumn={1}
                            inline={true}
                            showAddCart={false}
                            slider={false}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          {orderFArticle.quantity}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          {getMaxQuantityCanBuy(
                            orderFArticle.fArticle,
                            isAdmin,
                            undefined,
                            false
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      <Box key={indexArRef} />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography>{t("sentence.modifyCommand")}</Typography>
          </>
        ) : (
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>{step.children}</StepContent>
              </Step>
            ))}
          </Stepper>
        )}
      </>
    );
  }
);

export default StepperOrderComponent;
