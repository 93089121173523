import React, { forwardRef, useImperativeHandle } from "react";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import { ELASTIC_STOCK_AVAILABLE } from "../../../utils/FArticleUtils";

interface State {
  name: string;
  data: any;
  options: number[];
}

const SearchProductSelectComponent = React.memo(
  forwardRef(({ name, data, options }: State, ref) => {
    const getValue = React.useCallback(() => {
      return data !== undefined && data.hasOwnProperty(name)
        ? data[name].toString()
        : ELASTIC_STOCK_AVAILABLE.toString();
    }, [data, name]);
    const [value, setValue] = React.useState<string>(getValue());
    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      getValue() {
        if (value === "") {
          return null;
        }
        return value;
      },
    }));

    const handleChangeSelect = React.useCallback((event: SelectChangeEvent) => {
      setValue(event.target.value as string);
    }, []);

    React.useEffect(() => {
      setValue(getValue());
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormControl fullWidth>
        <InputLabel id={name}>{t("field." + name)}</InputLabel>
        <Select
          labelId={name}
          value={value}
          label={t("field." + name)}
          onChange={handleChangeSelect}
        >
          <MenuItem value="">
            <em>{t("word.none")}</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem value={option} key={option}>
              {t("word.stock." + option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  })
);

export default SearchProductSelectComponent;
