import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  SavCommentInterface,
  SavInterface,
} from "../../../interfaces/SavInterface";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import CkEditorComponent from "../../../components/content/CkEditorComponent";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import {
  requestApi,
  RequestApiResponseInterface,
} from "../../../helpers/RequestApi";
import { GET, PATCH, POST } from "../../../utils/MethodUtils";
import {
  SAV_COMMENT_URL,
  SAV_COMMENTS_URL,
  SAV_URL,
} from "../../../utils/UrlsUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { Checkbox, FormGroup, Skeleton } from "@mui/material";
import SavFormCommentComponent from "./SavFormCommentComponent";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { set } from "../../../app/globalSlice";
import SavFormNoveComponent from "../nove/SavFormNoveComponent";
import { STORAGE_SAV_COMMENT } from "../../../utils/StorageUtils";

interface State {
  sav: SavInterface;
  asAdmin: boolean;
  refFormSav: any;
  setSav: Function;
}

const SavFormCommentsComponent: React.FC<State> = React.memo(
  ({ sav, asAdmin, refFormSav, setSav }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const ckEditorRef: any = useRef();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const reservedNoveRef: any = useRef();
    const [isPrivate, setIsPrivate] = React.useState(false);
    const [savComments, setSavComments] = React.useState<
      SavCommentInterface[] | undefined
    >(undefined);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useAppDispatch();

    const getComments = React.useCallback(async () => {
      if (!sav.id) {
        setSavComments([]);
        return;
      }
      const response = await requestApi({
        method: GET,
        path: SAV_COMMENTS_URL.replace("{id}", sav.id.toString()),
        allowError: false,
        paginate: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setSavComments(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [sav.id, t, token]);

    const deleteComment = React.useCallback(async (savCommentId: number) => {
      setSavComments((comments) =>
        comments?.filter((x) => x.id !== savCommentId)
      );
    }, []);

    const handleChangeIsPrivate = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPrivate(event.target.checked);
      },
      []
    );

    const save = React.useCallback(async () => {
      setLoading(true);
      let response: RequestApiResponseInterface | null = null;
      if (sav.id && asAdmin) {
        const body = reservedNoveRef.current.getValue();
        response = await requestApi({
          method: PATCH,
          path: SAV_URL + "/" + sav.id,
          allowError: true,
          token: token,
          body: body,
        });
        if (response.statusCode === 200) {
          setSav(response.content);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      }
      const data = ckEditorRef.current.getValue();
      if (data !== "" && (response === null || response.statusCode === 200)) {
        response = await requestApi({
          method: POST,
          path: SAV_COMMENT_URL,
          allowError: true,
          token: token,
          body: {
            text: data,
            sav: SAV_URL + "/" + sav.id,
            admin: asAdmin && isAdmin,
            private: asAdmin && isAdmin ? isPrivate : false,
          },
        });
        if (response.statusCode === 201) {
          ckEditorRef.current.reset();
          setIsPrivate(false);
          setSavComments((x) => {
            if (x === undefined) {
              return [response?.content];
            }
            return [...x, response?.content];
          });
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }
      dispatch(set({ refreshPage: true }));
      setLoading(false);
    }, [asAdmin, dispatch, isAdmin, isPrivate, sav.id, setSav, t, token]);

    React.useEffect(() => {
      getComments();
    }, [user, asAdmin, sav]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Typography
          component="p"
          variant="h6"
          sx={{ color: theme.palette.primary.main }}
        >
          {t("word.comments")}
        </Typography>
        {savComments === undefined ? (
          <Skeleton
            variant="rectangular"
            height={150}
            sx={{ marginTop: 2, marginBottom: 2 }}
          />
        ) : (
          <>
            {savComments.map((savComment) => (
              <SavFormCommentComponent
                deleteComment={deleteComment}
                asAdmin={asAdmin}
                key={savComment.id}
                savComment={savComment}
              />
            ))}
          </>
        )}
        <Typography component="p" sx={{ color: theme.palette.primary.main }}>
          {t("word.addComment")}
        </Typography>
        <CkEditorComponent
          ref={ckEditorRef}
          text={""}
          small={true}
          idLocaleStorage={STORAGE_SAV_COMMENT + sav?.id}
        />
        <Box sx={{ textAlign: "right", marginTop: 2 }}>
          {asAdmin && isAdmin && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPrivate}
                    onChange={handleChangeIsPrivate}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                sx={{ justifyContent: "flex-end" }}
                label={t("sentence.isPrivate")}
              />
            </FormGroup>
          )}
        </Box>
        <SavFormNoveComponent
          sav={sav}
          asAdmin={asAdmin}
          ref={reservedNoveRef}
        />
        <Box sx={{ textAlign: "right", marginTop: 2 }}>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={save}
            sx={{ marginRight: 2 }}
          >
            {t("word.save")}
          </LoadingButton>
        </Box>
      </>
    );
  }
);

export default SavFormCommentsComponent;
