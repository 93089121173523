import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { set } from "../../../app/globalSlice";
import {
  getTitle,
  searchParamToObject,
} from "../../../helpers/SearchParamHelper";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { MAIL_SEND_HISTORY_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useSearchParams } from "react-router-dom";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { CreditFdocenteteInterface } from "../../../interfaces/FEcritureRecInterface";
import CopyClipboardComponent from "../CopyClipboardComponent";
import FilterSearchComponent from "../filter/FilterSearchComponent";
import { DialogProps, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RAW_HTML_PAGE } from "../../../utils/RouteUtils";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import { MailSendHistoryInterface } from "../../../interfaces/MailInterface";
import DoneIcon from "@mui/icons-material/Done";

const MailSendedComponent: React.FC = React.memo(() => {
  const { t, i18n } = useTranslation();
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const theme = useTheme();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xl");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const [defaultItemsPerPage] = React.useState(50);
  const [mailSendHistories, setMailSendHistories] = React.useState<
    MailSendHistoryInterface[] | undefined
  >(undefined);
  const [mailSendHistory, setMailSendHistory] = React.useState<
    CreditFdocenteteInterface | undefined
  >(undefined);
  const [totalItems, setTotalItems] = React.useState(0);
  const handleDrawerViewOpen = React.useCallback(
    (thisMailSendHistory: CreditFdocenteteInterface | undefined) => {
      setMailSendHistory(thisMailSendHistory);
    },
    []
  );
  const handleDrawerViewClose = React.useCallback(() => {
    setMailSendHistory(undefined);
  }, []);
  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "userIdentifier",
        headerName: t("column.userIdentifier"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.userIdentifier ?? ""}
          />
        ),
      },
      {
        field: "toMail",
        headerName: t("column.toMail"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.toMail ?? ""}
          />
        ),
      },
      {
        field: "subject",
        headerName: t("column.subject"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
      },
      {
        field: "created",
        headerName: t("column.created"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.row.created).toLocaleString(i18n.language, {
            dateStyle: "long",
            timeStyle: "medium",
            timeZone: "America/Cuiaba",
          }),
      },
      ...["sended", "delivery", "bounce", "complaint"].map((field) => {
        return {
          field: field,
          headerName: t("column." + field),
          flex: 0,
          headerClassName: "background-nove",
          filterable: false,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => (
            <>
              {params.row[field] !== null && (
                <>
                  {params.row[field] ? (
                    <DoneIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )}
                </>
              )}
            </>
          ),
        };
      }),
      {
        field: "actions",
        headerName: t("column.actions"),
        headerClassName: "background-nove",
        filterable: false,
        align: "center",
        flex: 0,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <IconButton
              onClick={() => {
                handleDrawerViewOpen(params.row);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </>
        ),
      },
    ];
  }, [handleDrawerViewOpen, i18n.language, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (mailSendHistories !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (
        !searchParamsObject.hasOwnProperty("page") ||
        searchParamsObject.page === "undefined"
      ) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: MAIL_SEND_HISTORY_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"].map(
            (o: CreditFdocenteteInterface) => o.id
          )
        );
        const thisMailSendHistories = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setTotalItems(response.content["hydra:totalItems"]);
        setMailSendHistories(thisMailSendHistories);
        dispatch(set({ nbErrorPayments: response.content.nbErrorPayments }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      mailSendHistories,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setMailSendHistories(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setMailSendHistories(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  React.useEffect(() => {
    document.title = getTitle("mails");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        fullWidth={true}
        onClose={handleDrawerViewClose}
        open={!!mailSendHistory}
      >
        <>
          <DialogActions sx={{ textAlign: "right" }}>
            <IconButton onClick={handleDrawerViewClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <iframe
            title="document"
            style={{ width: "100%", height: "91.7vh" }}
            src={
              window.origin +
              RAW_HTML_PAGE +
              "?url=" +
              MAIL_SEND_HISTORY_URL +
              "/" +
              mailSendHistory?.id
            }
          />
        </>
      </Dialog>
      <FilterSearchComponent init={init} placeholder="word.search" />
      {init && searchParamsObject.itemsPerPage && searchParamsObject.page && (
        // https://mui.com/x/react-data-grid/components/#pagination
        <DataGrid
          initialState={{
            sorting: {
              sortModel: getSortModel(),
            },
          }}
          loading={refreshPage}
          getRowHeight={() => "auto"}
          rows={refreshPage ? [] : mailSendHistories ?? []}
          onSortModelChange={onSortModelChange}
          sortingMode="server"
          page={Number(searchParamsObject.page) - 1}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={Number(searchParamsObject.itemsPerPage)}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          rowCount={totalItems}
          columns={columns}
          autoHeight={true}
          disableExtendRowFullWidth={true}
          localeText={getLocaleDataGrid(i18n.language)}
        />
      )}
    </>
  );
});

export default MailSendedComponent;
