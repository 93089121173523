import React from "react";
import Layout from "../../components/common/Layout";
import LoginComponent from "../../components/common/user/LoginComponent";
import { Container } from "@mui/material";
import { getTitle } from "../../helpers/SearchParamHelper";
import FiscalCodeComponent from "../../components/common/fiscal-code/FiscalCodeComponent";

const FiscalCodeScreen: React.FC = React.memo(() => {
  React.useEffect(() => {
    document.title = getTitle("fiscalCode");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Container maxWidth="xl" sx={{ marginY: 2 }}>
          <FiscalCodeComponent />
        </Container>
      </LoginComponent>
    </Layout>
  );
});

export default FiscalCodeScreen;
