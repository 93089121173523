export const CAN_CREATE_ACCOUNT = "CAN_CREATE_ACCOUNT";
export const PAIEMENT_ALLOW = "PAIEMENT_ALLOW";
export const DEFILANT_TEXTE = "DEFILANT_TEXTE";
export const ALLOW_USE_AVOIR = "ALLOW_USE_AVOIR";
export const LICENSE_MAIL = "LICENSE_MAIL";

export const TYPE_TEXT_INPUT = "A";
export const TYPE_NUM = "B";
export const TYPE_BOOL = "C";
export const TYPE_CONTENT = "D";

export const TYPE_ALL_CONTENT = [
  TYPE_TEXT_INPUT,
  TYPE_NUM,
  TYPE_BOOL,
  TYPE_CONTENT,
];
