import React, { forwardRef, useImperativeHandle } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  ELASTIC_STOCK_ARRIVAL,
  ELASTIC_STOCK_AVAILABLE,
  ELASTIC_STOCK_AVAILABLE_NOW,
  ELASTIC_STOCK_NOT_AVAILABLE,
} from "../../../utils/FArticleUtils";

interface State {
  apply: Function;
  setNbSelected: Function;
  index: number;
  filterInited: Function;
}

const StockFilterComponent = React.memo(
  forwardRef(({ apply, setNbSelected, index, filterInited }: State, ref) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [searchParams] = useSearchParams();
    const searchParamsObject = searchParamToObject(searchParams);
    const [value, setValue] = React.useState(searchParamsObject.stock ?? "");
    const { t } = useTranslation();
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );

    const onClick = React.useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        const newValue = (event.target as HTMLInputElement).value;
        if (value === newValue) {
          setValue("");
        } else {
          setValue(newValue);
        }
      },
      [value]
    );

    React.useEffect(() => {
      setNbSelected(value === "" ? 0 : 1);
      if (!isSmall) {
        apply();
      }
      filterInited(index);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        if (value !== "") {
          return { stock: value };
        }
        return {};
      },
    }));

    return (
      <FormControl>
        <RadioGroup
          aria-labelledby={"stock-radio-buttons-group-label"}
          name={"stock-radio-buttons-group"}
          value={value}
        >
          <FormControlLabel
            value={ELASTIC_STOCK_AVAILABLE}
            control={<Radio onClick={onClick} sx={{ paddingY: 0 }} />}
            label={t("word.published")}
          />
          <FormControlLabel
            value={ELASTIC_STOCK_ARRIVAL}
            control={<Radio onClick={onClick} sx={{ paddingY: 0 }} />}
            label={t("word.arrival")}
          />
          <FormControlLabel
            value={ELASTIC_STOCK_AVAILABLE_NOW}
            control={<Radio onClick={onClick} sx={{ paddingY: 0 }} />}
            label={t("word.availableNow")}
          />
          {isAdmin && (
            <FormControlLabel
              value={ELASTIC_STOCK_NOT_AVAILABLE}
              control={<Radio onClick={onClick} sx={{ paddingY: 0 }} />}
              label={t("word.notAvailable") + " [ADMIN]"}
            />
          )}
        </RadioGroup>
      </FormControl>
    );
  })
);

export default StockFilterComponent;
