import * as React from "react";
import { MouseEventHandler, ReactNode, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import { ModalProps } from "@mui/material";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Main = styled("div", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
  drawerwidth: number;
}>(({ theme, open, drawerwidth }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerwidth,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

interface State {
  open: boolean;
  drawerwidth: number;
  handleDrawerClose: MouseEventHandler;
  children?: ReactNode;
  content: ReactNode;
  onClose?: ModalProps["onClose"];
  autoScrollBottom?: boolean;
}

export const DrawerContainerId = "drawer-container-id";

const DrawerComponent: React.FC<State> = React.memo(
  ({
    open,
    handleDrawerClose,
    children,
    content,
    drawerwidth,
    onClose,
    autoScrollBottom,
  }) => {
    const theme = useTheme();
    const ref: any = useRef();

    React.useEffect(() => {
      if (open && autoScrollBottom) {
        setTimeout(() => {
          if (ref.current) {
            const dom = ref.current.querySelector("#" + DrawerContainerId);
            dom.scrollTop = dom.scrollHeight;
          }
        });
      }
    }, [open, autoScrollBottom, ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box sx={{ display: "flex" }}>
        <Main open={open} drawerwidth={drawerwidth}>
          {children}
        </Main>
        <Drawer
          ref={ref}
          sx={{
            width: drawerwidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerwidth,
            },
          }}
          variant="temporary"
          anchor="right"
          open={open}
          onClose={onClose}
          PaperProps={{
            id: DrawerContainerId,
          }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Box sx={{ m: 1 }}>{content}</Box>
        </Drawer>
      </Box>
    );
  }
);

export default DrawerComponent;
